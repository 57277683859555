import { IdentityApplicationEnvironmentType, IdentityApplicationPublishType, IdentityApplicationTypeConfig } from "../../PublisherApplicationService";
import { ApplicationConfigurationFormData } from "../../models/application.model";
import { Dialog } from "@material-ui/core";
import React, { Ref, FC, PropsWithRef, forwardRef, useState, useCallback, useImperativeHandle } from "react";
import { useCompactDialog, CompactDialogLayout } from "../../../../../../legacy-components/dialog/UseCompactDialog";
import { FormattedMessage } from "react-intl";
import PropTypes from 'prop-types';
import ApplicationOAuthConfigurationForm from "../ApplicationOAuthConfigurationForm";


export interface ApplicationOAuthConfigurationDialogRef {
    open(data?: ApplicationConfigurationFormData): void
}

interface ApplicationOAuthConfigurationDialogProps {
    ref?: Ref<ApplicationOAuthConfigurationDialogRef | undefined>,
    applicationName: string;
    env: IdentityApplicationEnvironmentType,
    onSubmit: (data: ApplicationConfigurationFormData) => void,
    publishType: IdentityApplicationPublishType,
    typeConfig?: IdentityApplicationTypeConfig
}

interface OAuthConfigurationFormViewerProps {
    applicationName: string;        
    env: IdentityApplicationEnvironmentType;
    onCancel: () => void;
    onSubmit: (data: ApplicationConfigurationFormData) => void,
    initialValues?: ApplicationConfigurationFormData;
    publishType: IdentityApplicationPublishType,
    typeConfig?: IdentityApplicationTypeConfig
}

const OAuthConfigurationFormViewer: FC<OAuthConfigurationFormViewerProps> = ({ applicationName, env, onSubmit, onCancel, initialValues, publishType, typeConfig }) => {
    return (<ApplicationOAuthConfigurationForm publishType={publishType} initialValues={initialValues} applicationName={applicationName} env={env} onCancel={onCancel} onSuccess={onSubmit} typeConfig={typeConfig} />);
}

const ApplicationOAuthConfigurationDialog: FC<PropsWithRef<ApplicationOAuthConfigurationDialogProps>> = forwardRef(({ publishType, applicationName, env, onSubmit, typeConfig }, ref) => {

    const { maxWidth, open, scroll, setOpen } = useCompactDialog();
    const [formValues, setFormValues] = useState<{
        value?: ApplicationConfigurationFormData
    } | undefined>()

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleSubmit = useCallback((data: ApplicationConfigurationFormData) => {
        setOpen(false);
        onSubmit(data);
    }, [onSubmit, setOpen]);

    const handleClickOpen = (value?: ApplicationConfigurationFormData) => {
        setFormValues({
            value
        });
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: (data?: ApplicationConfigurationFormData) => handleClickOpen(data)
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <CompactDialogLayout header={<FormattedMessage defaultMessage="OAuth Configurations" />}>

                <OAuthConfigurationFormViewer publishType={publishType} initialValues={formValues?.value} applicationName={applicationName} env={env} onCancel={handleClose} onSubmit={handleSubmit} typeConfig={typeConfig} />
            </CompactDialogLayout>
        </Dialog>
    );
});

ApplicationOAuthConfigurationDialog.propTypes = {
    applicationName: PropTypes.string.isRequired,
    env: PropTypes.oneOf<IdentityApplicationEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired,
    publishType: PropTypes.oneOf<IdentityApplicationPublishType>(["publish", "update"]).isRequired,
}

export default ApplicationOAuthConfigurationDialog

