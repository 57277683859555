import React from "react";
import {  DialogTitle,  DialogContent } from "@material-ui/core";
import APIHelperImage from "./images/APIHelperImage";

const APIHelper = (props: any) => {


    return (
        <section>
            <DialogTitle>What is API?</DialogTitle>
            <DialogContent>
                <p>An API is an interface that makes it easy for one application to 'consume' capabilities or data from another application. By defining stable, simplified entry points to application logic and data, APIs enable developers to easily access and reuse application logic built by other developers. In the case of 'Web APIs', that logic and data is exposed over the network.</p>
                <APIHelperImage/>
                <p>Since applications that consume APIs are sensitive to changes, APIs also imply a 'contract'. The contract provides some level of assurance that, over time, the API will change in a predictable manner.</p>
                <p>Cloud Console enables you to build APIs and if you have APIs already, expose them directly, while adding a management and visibility layer. If you have HTTP enabled services, such as SOA-based Web services, they can also be exposed as APIs here.</p>
                <p>Cloud Console also enables you to build APIs by implementing applications hosted on the API Services platform--with no backend service involved. You can build these applications in JavaScript, Java, and on Node.js.</p>
            </DialogContent>
        </section>
    );

}
export default APIHelper;