import React, { FC, useContext, useState } from 'react'
import FormContainer from '../../../../legacy-components/form/container/FormContainer';
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import { FormRenderProps, Field } from 'react-final-form';
import { Button, InputAdornment, makeStyles, createStyles } from '@material-ui/core';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { FormApi, FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProxyDeployFormData } from '../models/proxy.model';
import FormTextInput from '../../../../legacy-components/form/input/FormTextInput';
import { ValidatorBuilderContext } from '../../../../legacy-components/form/validator/Validator';
import ProxyService from '../../../../libs/resources/proxy/ProxyService';
import PropTypes from 'prop-types';
import { StatusBadge, StatusBadgeInfo } from '../../../../legacy-components/badges/StatusBadge';
import { useHistory } from 'react-router';

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            padding: spacing(3)
        },
    }),
);

interface ProxyDeployFormProps {
    onCancel: () => void,
    onSuccess: (data: ProxyDeployFormData) => void
    proxyName: string,
    deployment: any,
    env: string,
    prefillValues?: ProxyDeployFormData,
    initialValues?: ProxyDeployFormData,
    isAlreadyDeployed: boolean;
}

const ProxyDeployForm: FC<ProxyDeployFormProps> = ({ onCancel, onSuccess, proxyName, deployment,
     env, prefillValues, initialValues, isAlreadyDeployed }) => {
    const intl = useIntl();
    const styles = useStyles();
    const [dataFields] = useState(["__formResponse"]);
    let history = useHistory();

    const onSubmit = () => (data: ProxyDeployFormData, form: FormApi, prefillValues: any) => {
        return new Promise((resolve) => {
            const formState = form.getState();
            if (formState.pristine) {
                resolve(null);
            } else {
                if(isAlreadyDeployed){
                    ProxyService.updateDeployment(
                        env,
                        proxyName,
                        {
                            backendUrl: data.backendUrl,
                            timeout: data.timeout,
                            threatProtection: deployment ? deployment.threatProtection ? deployment.threatProtection.protectionType : null : null,
                            tls: deployment ? deployment.ssl ? deployment.ssl.tls : null : null,
                            isAdvanced: deployment?.isAdvanced,
                        }).then((response: any) => {
                            form.mutators.setValue("__formResponse", {
                                deploy: response
                            });
                            resolve(null);
                            onSuccess && onSuccess(response);
                            //history.go(0);
                        }, (error: any) => resolve({
                            [FORM_ERROR]: error
                        }))
                }
                else{
                ProxyService.deploy(
                    env,
                    proxyName,
                    {
                        backendUrl: data.backendUrl,
                        timeout: data.timeout,
                        threatProtection: deployment ? deployment.threatProtection ? deployment.threatProtection.protectionType : null : null,
                        tls: deployment ? deployment.ssl ? deployment.ssl.tls : null : null,
                        isAdvanced: deployment?.isAdvanced,
                    }).then((response: any) => {
                        form.mutators.setValue("__formResponse", {
                            deploy: response
                        });
                        resolve(null);
                        onSuccess && onSuccess(response);
                        //history.go(0);
                    }, (error: any) => resolve({
                        [FORM_ERROR]: error
                    }))
                }
            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        const validator = useContext(ValidatorBuilderContext);
        const [deploymentStatus] = useState<StatusBadgeInfo>({
            kind: "access",
            key: env
        });

        return (
            <div className={styles.root}>
                <FormInputContainer>
                    <Field
                        name="backendUrl"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Target Endpoint"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "Target Endpoint Url"
                        })}
                        validate={validator.from({
                            required: true,
                            pattern: ({ HttpUrl }) => HttpUrl
                        })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <StatusBadge status={deploymentStatus} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormInputContainer>
                <FormInputContainer>
                    <Field
                        name="timeout"
                        type="number"
                        defaultValue="30"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Connection Timeout (s)"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "Connection Timeout in seconds"
                        })}
                        validate={validator.from({
                            required: true,
                            min: 1,
                            max: 55
                        })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <StatusBadge status={deploymentStatus} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormInputContainer>

                <ActionInliner padding="inset-top">
                    <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                    <Button disabled={formRenderProps.submitting || formRenderProps.pristine} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Deploy Changes" /></Button>
                </ActionInliner>
            </div>);
    }

    return <FormContainer previewErrors={true} prefillValues={prefillValues} dataFields={dataFields} FormProps={{ onSubmit, initialValues }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

ProxyDeployForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    proxyName: PropTypes.string.isRequired,
    env: PropTypes.string.isRequired
}

export default ProxyDeployForm;