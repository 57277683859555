import type { ReactElement } from "react";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

interface IAlertBasicOptions {
  message?: string | ReactElement;
  isAutoHide?: boolean;
  onClose?: () => void;
  isShowClose?: boolean;
  id: string;
}
export interface IAlertOptions extends IAlertBasicOptions {
  severity: IAlertServerityType;
}
const alertSubject = new Subject<IAlertOptions>();
const defaultId = "default-alert";

export type IAlertServerityType =
  | "error"
  | "info"
  | "secondary"
  | "success"
  | "warning"
  | "loading";

// core alert method
const alert = (options: IAlertOptions) => {
  alertSubject.next(options);
};

const clear = (id = defaultId) => {
  alertSubject.next({ message: "", severity: "info", id });
};

// enable subscribing to alerts observable
const onAlert = (id = defaultId) => {
  return alertSubject.asObservable().pipe(filter((x) => x && x.id === id));
};

// convenience methods
const success = (options: IAlertBasicOptions) => {
  alert({ ...options, ...{ severity: "success" } });
};

const error = (options: IAlertBasicOptions) => {
  alert({ ...options, ...{ severity: "error" } });
};

const info = (options: IAlertBasicOptions) => {
  alert({ ...options, ...{ severity: "info" } });
};

const warn = (options: IAlertBasicOptions) => {
  alert({ ...options, ...{ severity: "warning" } });
};

const load = (options: IAlertBasicOptions) => {
  alert({ ...options, ...{ severity: "loading", isAutoHide: false } });
};

export const AlertService = {
  onAlert,
  success,
  error,
  info,
  warn,
  alert,
  clear,
  load,
};

export default AlertService;
