import { withStyles, Checkbox, StyleRules } from "@material-ui/core";
import { memo } from "react";

const GenericCheckbox = withStyles(theme => {
    const checkedStyle: StyleRules = {
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "& .MuiIconButton-label:after": {
            content: '""',
            left: 4,
            top: 4,
            height: 15,
            width: 15,
            position: "absolute",
            backgroundColor: theme.palette.secondary.contrastText,
            zIndex: -1
        },
        "&.MuiIconButton-colorPrimary .MuiIconButton-label:after": {
            backgroundColor: theme.palette.primary.contrastText,
        }
    };

    return {
        root: {
            "&.Mui-checked": {
                ...checkedStyle
            }
        }
    }
})(Checkbox);

export default memo(GenericCheckbox);


