import { Box } from "@material-ui/core";
import { InputContainer } from "modus/components/form/input-container/InputContainer";
import CopyToClipboard from "modus/components/misc/CopyToClipboard";
import React from "react";

/**
 * Renders a component that displays the consumer key.
 * @param {Object} props - The component props.
 * @param {string} props.clientId - The client ID.
 * @returns {JSX.Element} - The rendered component.
 */
export const ConsumerKey = ({ clientId }: { clientId: string }) => (
    <Box display={"flex"} alignItems={"flex-end"} width={`calc(50% + 65px)`}>
        <Box width="100%">
            <InputContainer label="Client Id" value={clientId} disabled={true} />
        </Box>
        <Box marginBottom={"7px"} marginLeft="15px">
            <CopyToClipboard text={clientId} />
        </Box>
    </Box>
);
