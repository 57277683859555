import { APIResource, APIMethod } from "../../utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../utils/http/client/HttpClient";
import { apiMethodContext } from "../../utils/http/builder/UrlConfig";
import { from } from "rxjs";

interface IDocumentUploadRequest {
    name?: string;
    file: File;
    productName: string
}

interface IDocumentUploadResponse {
    name: string;
    id: string;
    fileName?: string;
}

@APIResource(CloudConsoleClient, "/publisher/documentation")
class SpecDocumentationServiceFactory {

    @APIMethod("/product/:productName")
    public forProduct(productName: string) {
        return from(CloudConsoleClient.get<IDocumentUploadResponse[]>(apiMethodContext(arguments), {
            params: {
                productName
            }
        }).then(response => response.data));
    }

    @APIMethod("/file")
    public uploadDocument(uploadRequest: IDocumentUploadRequest) {
        const context = apiMethodContext(arguments);

        return new Promise<IDocumentUploadResponse>((resolve, reject) => {
            const formData = new FormData();

            if (uploadRequest.name) {
                formData.append('name', `${uploadRequest?.file?.name?.split(".")?.[0]}-${uploadRequest.name}-${Date.now()}`);
            }

            formData.append('file', uploadRequest.file);
            formData.append('fileName', uploadRequest.file?.name);
            formData.append('productName', uploadRequest.productName);

            CloudConsoleClient.post(context, formData).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while uploading documentation")
            );
        });
    }

    @APIMethod("/:specId")
    public delete(specId: string) {
        const context = apiMethodContext(arguments);

        return new Promise<void>((resolve, reject) => {
            CloudConsoleClient.delete(context, {
                params: {
                    specId
                }
            }).then(
                () => resolve(),
                error => reject(error.response?.data?.message ?? "Unknown error occured while deleting documentation")
            );
        });
    }
}

const SpecDocumentationService = new SpecDocumentationServiceFactory();

export default SpecDocumentationService;