import React from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {Chip, createStyles, IconButton, makeStyles, Tooltip} from "@material-ui/core";
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import ProxyDeleteAction from "pages/home/proxy/summary/ProxyDeleteAction";
import ProxyVerifiedView from "pages/home/proxy/summary/ProxyVerifiedView";
import StarTick from "./icons/StarTick";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import {FormattedMessage} from "react-intl";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

const useStyles = makeStyles((theme) =>
  createStyles({
       proxyLogo: {
          fill : "#fff",
          width: "20px"
       }
  })
);
/**
 * @class ProxyCard
 * @description component contains Single card view for API Proxy listing
 */
const ProxyCard = (props: any) => {

     const dispatch = useDispatch();
     const history = useHistory();
     const classes = useStyles();
     let photoUrl = props.proxy.icon;

     /**
      * @function onUserImagNotFound
      * @description When photo url not exists / having errorenous file this function loads icon
      */
     const onUserImagNotFound = (event: any) => {
          event.target.outerHTML = `<CodeRoundedIcon className={classes.proxyLogo}/>`;
     }

     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     const showSummary = () => {
          dispatch(history.push("/home/proxy/" + props.proxy.apiName));
     }


     let photo;
     if (photoUrl) {
          photo = (<img src={photo} onError={onUserImagNotFound} className="proxy-image" alt="proxy-icon" />);
     } else {
          photo = (<CodeRoundedIcon className={classes.proxyLogo} />)
     }
     return (
          <div className="card proxy-card" onClick={showSummary} >
               <div className="image-block">
                    {photo}
               </div>
               <h2 className="name proxy-name">
                    {props.proxy.displayName ?? props.proxy.apiName}
               </h2>
               <p className="description">
                    {props.proxy.description}
               </p>
               <div className="actions">
                    <div className="left">
                         <Chip label={"version " + props.proxy.version} className="version" color="primary" variant="outlined" />
                    </div>
                    <div className="right">
                         <ProxyVerifiedView proxy={props.proxy} />
                         {/* <Tooltip title={<FormattedMessage defaultMessage="Audience claim complaince" />}>
                           <IconButton>
                             {props.proxy.audUsageStatus ? <StarTick/> : <NewReleasesIcon style={{ color: ("#FBAD26")}}/>}
                           </IconButton>
                         </Tooltip> */}
                         {props.isTeamProxy && <ProxyDeleteAction proxy={props.proxy} onSuccess={props.onUpdate}/>}
                    </div>
               </div>
          </div>
     );

}
export default ProxyCard;
