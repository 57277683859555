import clsx from "clsx";
import type { ReactElement } from "react";
import React from "react";

import useBadgeStyles from "./Badge.style";

/* eslint-disable-next-line */
export interface BadgeProps {
  type?: "default" | "text" | "counter";
  size?: "small" | "default" | "large";
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "dark"
    | "success"
    | "warning"
    | "danger";
  label: ReactElement | string;
  className?: string;
  key?: string;
}

export function Badge({
  type,
  size,
  color,
  label,
  className,
  key,
}: BadgeProps) {
  const classes = useBadgeStyles();

  return (
    <div
      className={clsx(
        classes.root,
        classes.badge,
        `${classes.badge}__${type}`,
        `${classes.badge}__${size}`,
        `${classes.badge}__${color}`,
        `${classes.badge}__${type}--${color}`,
        className
      )}
      key={key ?? ""}
    >
      {label}
    </div>
  );
}

Badge.defaultProps = {
  type: "default",
  size: "default",
  color: "primary",
};

export default Badge;
