
import * as actions from "../actions/actions.tsx";

/**
 * @class userManager
 * @description Redirects user actions
 * @param {Object} action - Action from action creator
 * @param {Object} state - Default application state
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action = {}) => {
     switch (action.type) {
          case actions.storeUserDetails: return storeUserDetails(state, action);
          case actions.storeUserRole: return storeUserRole(state, action);
          case actions.clearUserDetails: return clearUserDetails(state, action);
          default: return state;
     }
};

/**
 * @function clearUserDetails
 * @description To clear up user details store by OIDC
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const clearUserDetails = (state, action) => {
     return { user: {} };
}

/**
 * @function storeUserDetails
 * @description To store user firstname, lastname, TRN ID, photo
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const storeUserDetails = (state, action) => {
     return { ...state, ...action.payload };
}
const storeUserRole = (state, action) => {
     return { ...state, ...action.payload };
}

const INITIAL_STATE = {
};
