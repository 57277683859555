import React from "react";
import { DialogTitle, DialogContent } from "@material-ui/core";
import APIProxyHelperImage from "./images/APIProxyHelperImage";

const APIProxyHelper = (props: any) => {

    return (
        <section>
            <DialogTitle>What is API Proxy?</DialogTitle>
            <DialogContent>
                <p>You can expose your APIs  by implementing API proxies. API proxies decouple the app-facing API from your backend services, shielding those apps from backend code changes. As you make backend changes to your services, apps continue to call the same API without any interruption.</p>
                <p>You can visualize API proxies as shown by the graphic below:</p>
                <APIProxyHelperImage/>
                <p>In an API proxy configuration, there are two types of endpoints:</p>

                <ul>
                    <li><strong>Deployed Endpoint / Proxy Endpoint</strong>: Defines the way client apps consume your APIs. You configure the ProxyEndpoint to define the URL of your API proxy. The proxy endpoint also determines whether apps access the API proxy over HTTP or HTTPS. You usually attach policies to the ProxyEndpoint to enforce security, quota checks, and other types of access control and rate-limiting.</li>
                    <li><strong>TargetEndpoint:</strong>Defines the way the API proxy interacts with your backend services. You configure the TargetEndpoint to forward requests to the proper backend service, including defining any security settings, HTTP or HTTPS protocol, and other connection information. You can attach policies to the TargetEndpoint to ensure that response messages are properly formatted for the app that made the initial request.</li>
                </ul>
            </DialogContent>
        </section>
    );

}
export default APIProxyHelper;