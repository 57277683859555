import React, { ReactElement } from 'react';
import { useState, PropsWithChildren, FC } from "react";
import { DialogProps, makeStyles, createStyles, Divider, DialogTitle } from "@material-ui/core";

const useCompactDialogStyles = makeStyles(() => {
    return createStyles({
        header: {
            position: "relative"
        },
        layout: {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",

            "& &__dialog-inner-content": {
                overflow: "auto",
                display: "flex",
            }
        }
    })
});

export function useCompactDialog() {
    const [open, setOpen] = useState(false);
    const [maxWidth] = useState<DialogProps['maxWidth']>('sm');
    const [scroll] = useState<DialogProps['scroll']>('paper');

    return {
        open,
        setOpen,
        maxWidth,
        scroll
    }
}

export const CompactDialogLayout: FC<PropsWithChildren<{
    header: ReactElement
}>> = ({ children, header }) => {
    const styles = useCompactDialogStyles();

    return (
        <div className={styles.layout}>
            <div className={styles.header}>
                <DialogTitle>
                    {header}
                </DialogTitle>
                <Divider />
            </div>
            <div className={`${styles.layout}__dialog-inner-content`}>
                {children}
            </div>
        </div>
    )
}

