import React, { FC, memo, forwardRef, useImperativeHandle, PropsWithRef, Ref, ReactElement, useState } from 'react';
import { useReducer, useRef, useEffect, Reducer } from "react";
import { Paper, makeStyles, createStyles, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemAvatar, Avatar, Badge } from "@material-ui/core";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import clsx from 'clsx';
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { ProductEnvironmentType } from '../../../../../libs/resources/product/ProductService';
import StarIcon from '@material-ui/icons/Star';
import NAWrap from '../../../../../legacy-components/form/action/NAWrap';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            textAlign: "left"
        },
        uploadStatus: {
            marginLeft: theme.spacing(1)
        },
        uploadBadge: {
            padding: "4px 6px",
            borderRadius: 4,
            fontSize: theme.typography.pxToRem(11),
            textTransform: "uppercase",
            fontWeight: theme.typography.fontWeightMedium,
            letterSpacing: 1,
            lineHeight: theme.typography.pxToRem(11),

            "&__status": {
                "&--pending": {
                    background: theme.palette.grey[600],
                    color: theme.palette.getContrastText(theme.palette.grey[600])
                },
                "&--loading": {
                    background: theme.palette.success.light,
                    color: theme.palette.success.contrastText
                },
                "&--loaded": {
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                },
                "&--error": {
                    background: theme.palette.warning.main,
                    color: theme.palette.warning.contrastText
                }
            }
        },
        secondaryActions: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
        },
        controls: {
            paddingLeft: theme.spacing(2)
        },
        primaryStar: {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.success.contrastText,
        }
    }),
);

interface IDocumentFieldPayload {
    progress?: number
}

interface InitAction {
    type: "init"
}

interface LoadingAction {
    type: "loading",
    payload: IDocumentFieldPayload
}

interface ProgressAction {
    type: "progress",
    payload: IDocumentFieldPayload
}

interface LoadedAction {
    type: "loaded"
}

interface ErrorAction {
    type: "error"
}

type DocumentFieldAction = ErrorAction | LoadedAction | ProgressAction | LoadingAction | InitAction;

interface IDocumentFieldState {
    status?: "pending" | "loading" | "loaded" | "error",
    progress?: number
}

export interface ProductDocumentFieldRef {
    progress(value: number): void,
    error(): void,
    isPending(): boolean
}

export interface AbstractFileInfo {
    filename: string;
    type: string;
}

export interface ProductDocumentFieldFileInfo {
    id: string;
    fileInfo: AbstractFileInfo,
    loaded: boolean
    file?: File,
    openApiSpecId?: string;
    isNew?: boolean;
    publishedGateways?: ProductEnvironmentType[]
}

const documentFieldReducer: Reducer<IDocumentFieldState, DocumentFieldAction> = (state, action) => {
    switch (action.type) {
        case 'error':
            return {
                ...state,
                status: "error",
                progress: 0
            };
        case 'loading':
            return {
                ...state,
                status: "loading"
            };
        case 'loaded':
            return {
                ...state,
                status: "loaded",
                progress: 100
            };
        case 'progress':
            {
                const progress = Math.min(100, action?.payload?.progress ?? 0);

                return {
                    ...state,
                    status: progress >= 100 ? "loaded" : "loading",
                    progress
                }
            }
        case 'init':
        default:
            return {
                status: "pending",
                progress: 0
            }
    }
}

interface ProductDocumentFieldProps {
    docInfo?: ProductDocumentFieldFileInfo,
    ref?: Ref<ProductDocumentFieldRef | undefined>,
    addWrap?: boolean;
    controls?: ReactElement;
    showPublishStatus?: boolean;
}

const ProductDocumentField: FC<PropsWithRef<ProductDocumentFieldProps>> = forwardRef(({ docInfo, addWrap, controls, showPublishStatus }, ref) => {
    const styles = useStyles();

    const [state, dispatch] = useReducer(documentFieldReducer, docInfo?.loaded ? {
        status: "loaded",
        progress: 100
    } : {
            status: "pending",
            progress: 0
        }
    );

    useImperativeHandle(ref, () => ({
        progress: (progress: number) => dispatch({
            type: "progress",
            payload: {
                progress
            }
        }),
        error: () => dispatch({ type: "error" }),
        loaded: () => dispatch({ type: "loaded" }),
        isPending: () => state.status === "pending"
    }));

    const isMount = useRef(true);

    useEffect(() => {
        if (isMount.current) {
            isMount.current = false;
            return;
        }

        dispatch({
            type: "init"
        });
    }, [dispatch]);

    const [statusMessages] = useState({
        loading: <div><FormattedMessage defaultMessage="Uploading" /></div>,
        error: <div><FormattedMessage defaultMessage="Upload Failed" /></div>,
        loaded: <div><FormattedMessage defaultMessage="Upload Complete" /></div>,
        pending: <div><FormattedMessage defaultMessage="Upload Pending" /></div>,
        default: <div>...</div>
    });

    const StatusInfo = () => (statusMessages[state.status ?? "default"] || <div>...</div>)

    const FileListItem = () => {
        return (
            <ListItem>
                <ListItemAvatar>
                    <Badge overlap="circle" invisible={!(docInfo?.isNew ?? false)} color="secondary" badgeContent={<FormattedMessage defaultMessage="New" />}>
                        <NAWrap
                            value={docInfo?.publishedGateways}
                            showMessage={true}
                            fallback={(<Avatar>
                                <DescriptionOutlinedIcon />
                            </Avatar >)}
                        >
                            <Avatar className={styles.primaryStar}>
                                <StarIcon />
                            </Avatar>
                        </NAWrap>
                    </Badge>
                </ListItemAvatar>
                <ListItemText
                    primary={docInfo?.fileInfo?.filename}
                    secondary={showPublishStatus && (
                        <NAWrap
                            value={docInfo?.publishedGateways}
                            fallback={<FormattedMessage defaultMessage="Not shown in any environments" />}>
                            <>
                                {
                                    (docInfo?.publishedGateways?.length ?? 0) > 1 ? <FormattedMessage defaultMessage="Shown in pre-prod and prod environments" /> : (
                                        <>
                                            {
                                                docInfo?.publishedGateways?.[0] === "trimble-pre-prod" ? <FormattedMessage defaultMessage="Shown only in pre-prod environment" /> : <FormattedMessage defaultMessage="Shown only in prod environments" />
                                            }
                                        </>
                                    )
                                }
                            </>
                        </NAWrap>
                    )}
                />
                <ListItemSecondaryAction>
                    <div className={styles.secondaryActions}>
                        {(!controls || (state.status !== "loaded" && controls)) && <div className={styles.uploadStatus}>
                            <div className={clsx(styles.uploadBadge, `${styles.uploadBadge}__status--${state.status}`)}>
                                <StatusInfo />
                            </div>
                        </div>}
                        {(state.status !== "loading" && controls) && <div className={styles.controls}>
                            {controls}
                        </div>
                        }
                    </div>
                </ListItemSecondaryAction>
            </ListItem>
        )
    }

    return (
        addWrap ? (
            <Paper variant="outlined" className={styles.root}>
                <List dense>
                    <FileListItem />
                </List>
            </Paper>
        ) : <FileListItem />
    )
});

ProductDocumentField.propTypes = {
    docInfo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        fileInfo: PropTypes.shape({
            filename: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired
        }).isRequired,
        loaded: PropTypes.bool.isRequired
    })
};

export default memo(ProductDocumentField);