import React,{ useEffect } from 'react';
import { connect,useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AuthService from '../../services/authService';
import Loader from '../../legacy-components/loader';
import { useHistory } from "react-router";


export const excludedPaths = ["/landing", "/login", "/callback", "/error"];
/**
 * @class Login
 * @description This page appears when Application redirects to Identity Page for adding Loader
 */

const Login = (props) => {

     const history = useHistory();
     /**
      * componentDidMount - Lifecycle Hook
      * For redirecting to sigin page when this component is mounted
      */
      let isAuthenticated = useSelector((state) =>
      state.auth?.user?.access_token ? true : false
    );

     useEffect(() => {
          if (isAuthenticated) {
            history.push("/home");
          } else {
            if(!(excludedPaths.some(str => history.location.pathname.includes(str))))  {
              localStorage.setItem("LoadedUrl", history.location.pathname);
            }
            AuthService.signInRedirect(props);
          }
        }, [isAuthenticated, history, props]);
     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
      return <Loader showBackdrop />;
};

/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state) => {

     return {
          isAuthenticated: AuthService.isAuthenticated(state)
     }
};

export default withRouter(connect(mapStateToProps)(Login));
