import React, { FC, useContext, useState } from 'react'
import { FormRenderProps, Field } from 'react-final-form';
import { Button, TextField } from '@material-ui/core';
import { FormApi, FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useRole } from 'libs/security/authorization/Permission';
import { ValidatorBuilderContext } from 'legacy-components/form/validator/Validator';
import FormInputContainer from 'legacy-components/form/container/FormInputContainer';
import ActionInliner from 'legacy-components/form/action/ActionInliner';
import FormContainer from 'legacy-components/form/container/FormContainer';
import UnifiedAPIService, { UnifiedAPI } from 'services/UnifiedAPIService';
import FormTextInput from 'legacy-components/form/input/FormTextInput';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sanitize } from 'dompurify';

export interface TeamCreationFormData {
    teamName?: string;
    businessUnit: string;
}

interface TeamCreationFormProps {
    onCancel: () => void,
    onSuccess: () => void,
    divisions?: Array<{
        key?: string,
        value?: string
    }> ,
}
const TeamCreationForm: FC<TeamCreationFormProps> = ({ onCancel, onSuccess , divisions}) => {
    const intl = useIntl();
    const [role] = useRole();
    const [businessUnit, setBusinessUnit] = useState<string>();
    const [otherOptionChoosen, setOtherOptionChoosen] = useState<boolean>(false);

    const onSubmit = () => (data: any, form: FormApi) => {
        let divisionName: string | undefined;
        let baCode: string | undefined;
        if(otherOptionChoosen == true) {
            divisionName = data.divisionName;
            baCode = data.baCode;
        } 
        else {
             divisionName = businessUnit && businessUnit.split(",")[0];
             baCode = businessUnit && businessUnit.split(",")[1];
        }
        
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve(null);
            } else {
                UnifiedAPIService.post(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.add_new_team, {
                    }),
                    {
                        teamName: data.teamName,
                        divisionRequest: {
                            division : divisionName,
                            baCode : baCode,
                        },                      
                        sector: role?.sector,
                        email: sanitize(data.email),
                        website : sanitize(data.website),
                        description : sanitize(data.description),
                        support : sanitize(data.support)
                    }
                ).then(() => resolve(null), error => {
                    resolve({
                        [FORM_ERROR]: error.response?.data?.message ?? " Cannot Create Team"
                    });
                });
            }
        });
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        const validator = useContext(ValidatorBuilderContext);
        return (<>
            <FormInputContainer
                title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Team Name *" />} />}
            >
                <Field
                    name="teamName"
                    type="text"
                    component={FormTextInput}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Team Name"
                    })}
                    validate={validator.from({
                        required: true
                    })}
                />
            </FormInputContainer>
            <FormInputContainer  title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Description" />} />}>
                
            <Field
                            name="description"
                            type="text"
                            multiline
                            rows={5}
                            component={FormTextInput}
                            placeholder={intl.formatMessage({
                                defaultMessage: "Tell us a little bit about your team"
                            })}
                        />
                </FormInputContainer>
            

                {divisions && (
                    <FormInputContainer title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Business Unit *" />} />}>
                    <Autocomplete
                id="autocomplete"
                aria-required
                options={divisions}
                onInputChange={(event, newValue) => {
                   setBusinessUnit(newValue);
                   if(newValue == "MY DIVISION IS MISSING,000") {
                       setOtherOptionChoosen(true);
                   }
                   else {
                    setOtherOptionChoosen(false);

                   }
                  }}
                getOptionLabel = {(option)=> option.key as string }
                
                renderInput={(params) => <TextField {...params} 
                label="Business Unit" variant="outlined" />}
                />
               
                </FormInputContainer>
                )}
            {otherOptionChoosen && (
                <>
               <FormInputContainer>
                    <Field
                        name="divisionName"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Division Name"
                        })}

                        validate={validator.from({
                            required: true,
                        })}
                    />
                   </FormInputContainer>
                   <FormInputContainer>

                    <Field
                        name="baCode"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "BA Code"
                        })}

                        validate={validator.from({
                            required: true,
                            maxLength : 3,
                            pattern: ({ Numeric }) => Numeric,

                        })}
                    />
                </FormInputContainer>
                </>
                )}

                <FormInputContainer title={<Typography variant="subtitle2" children={
                <FormattedMessage defaultMessage="Contact Information" />} />}>
                    <Field
                        name="website"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Website"
                        })}
                        placeholder = {intl.formatMessage({
                            defaultMessage: "http(s)://example.com"
                        })}

                        validate={validator.from({
                            pattern: ({ HttpUrl }) => HttpUrl
                        })}
                    />
                </FormInputContainer>

                <FormInputContainer>
                    <Field
                        name="email"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Email"
                        })}

                        validate={validator.from({
                            pattern: ({ GenericEmail }) => GenericEmail
                        })}
                    />
                </FormInputContainer>
                <FormInputContainer title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Support" />} />}>
                    <Field
                        name="support"
                        type="text"
                        multiline
                        rows={5}
                        component={FormTextInput}

                        placeholder={intl.formatMessage({
                            defaultMessage: "Provide support methods for API consumers"
                        })}
                    />
                </FormInputContainer>
            <ActionInliner>
                <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                <Button disabled={formRenderProps.submitting || !businessUnit} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Create Team" /></Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

TeamCreationForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
}

export default TeamCreationForm;