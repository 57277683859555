import { from, Observable } from "rxjs";
import { APIResource, APIMethod } from "../../../utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../../utils/http/client/HttpClient";
import { apiMethodContext } from "../../../utils/http/builder/UrlConfig";
import { delay } from "rxjs/operators";
export type IdentityApplicationEnvironmentType = "trimble-pre-prod" | "trimble-prod";

export interface IdentityApplicationPublishedGateway {
    consumerKey: string
    consumerSecret: string[]
    grantTypes?: string[]
    logoutUri?: string[]
    organisationName: IdentityApplicationEnvironmentType
    redirectUri?: string[]
    enforceScopeValidation?: boolean
}

export interface IdentityApplicationSPMetadata {
    assertionConsumerUrl: string;
    x509RequestSigningCert?: string;
}

export interface IdentityApplicationType {
    id: string;
    name: IdentityApplicationTypes
}

export interface IdentityApplication {
    applicationId: string,
    applicationName: string,
    applicationType?: IdentityApplicationType;
    displayName?: string,
    description?: string,
    organisationName: IdentityApplicationEnvironmentType;
    applicationGatewayResponses?: IdentityApplicationPublishedGateway[];
    samlSpMetadata?: IdentityApplicationSPMetadata;
    userId?: string;
    isOwnedByUser?: Boolean,
    createdBy?: string;
    consumerKey: string;
}

export interface IdentityApplicationCreateRequest {
    name?: string,
    displayName?: string,
    description?: string,
    applicationType: IdentityApplicationTypes;
}

export interface IdentityApplicationUpdateRequest {
    name: string;
    displayName?: string,
    description?: string
}

export interface IdentityApplicationPublishRequest {
    grantTypes?: string[],
    redirectUri?: string[],
    logoutUri?: string[],
    enforceScopeValidation?: true
}

interface IdentityApplicationSAMLRequest {
    metadata: string;
}

export type IdentityApplicationTypes = "application" | "service-application" | "resource" | "resource-server";

export interface IdentityApplicationTypeConfig {
    type: IdentityApplicationTypes,
    grants?: string[],
    protocols?: string[]
}

export const IdentityApplicationEnvironmentAuthority: {
    [key in IdentityApplicationEnvironmentType]: string
} = {
    "trimble-pre-prod": "https://stage.id.trimblecloud.com",
    "trimble-prod": "https://id.trimble.com"
};

const formatError = (error: any, defaultMessage: string) => error?.response?.data?.code !== undefined && error.response.data.message ? error.response.data.message : defaultMessage;

export type IdentityApplicationPublishType = "publish" | "update";
@APIResource(CloudConsoleClient, "publisher/applications")
class PublisherApplicationServiceFactory {

    public environments: {
        [key in IdentityApplicationEnvironmentType]: {
            publish: (type: IdentityApplicationPublishType, applicationName: string, request?: IdentityApplicationPublishRequest) => Promise<IdentityApplication>,
            create: (request: IdentityApplicationCreateRequest) => Promise<IdentityApplication>,
            update: (applicationName: string, request: IdentityApplicationUpdateRequest) => Promise<IdentityApplication>,
            get: (applicationName: string) => Observable<IdentityApplication>,
            delete: (applicationName: string) => Promise<void>,
            configureSAML: (applicationName: string, request: IdentityApplicationSAMLRequest) => Promise<IdentityApplication>
        }
    } = {
            "trimble-pre-prod": {
                publish: (type, applicationName, request) => this.publish(type, "trimble-pre-prod", applicationName, request),
                create: request => this.create("trimble-pre-prod", request),
                update: (applicationName, request) => this.update("trimble-pre-prod", applicationName, request),
                get: applicationName => this.get("trimble-pre-prod", applicationName),
                delete: applicationName => this.delete("trimble-pre-prod", applicationName),
                configureSAML: (applicationName, request) => this.configureSAML("trimble-pre-prod", applicationName, request)
            },
            "trimble-prod": {
                publish: (type, applicationName, request) => this.publish(type, "trimble-prod", applicationName, request),
                create: request => this.create("trimble-prod", request),
                update: (applicationName, request) => this.update("trimble-prod", applicationName, request),
                get: applicationName => this.get("trimble-prod", applicationName),
                delete: applicationName => this.delete("trimble-prod", applicationName),
                configureSAML: (applicationName, request) => this.configureSAML("trimble-prod", applicationName, request)
            }
        };

    @APIMethod()
    public getList() {
        return from(CloudConsoleClient.get<IdentityApplication[]>(apiMethodContext(arguments)).then(value => value.data));
    }


    @APIMethod("/gateway/:gateway")
    public forApplications(gateway: string) {
        return from(CloudConsoleClient.get<IdentityApplication[]>(apiMethodContext(arguments), {
            params: {
                gateway,
                includeEmailAddress: true
            }
        }).then(response => response.data));
    }

    @APIMethod("/user")
    public forUser() {
        return from(CloudConsoleClient.get<IdentityApplication[]>(apiMethodContext(arguments)).then(value => value.data));
    }

    @APIMethod("/gateway/:gateway/user")
    public forApplicationsUser(gateway: string) {
        return from(CloudConsoleClient.get<IdentityApplication[]>(apiMethodContext(arguments), {
            params: {
                gateway
            }
        }).then(response => response.data));
    }

    @APIMethod("/:applicationName/gateway/:environmentName")
    public get(environmentName: IdentityApplicationEnvironmentType, applicationName: string) {
        return from(CloudConsoleClient.get<IdentityApplication>(apiMethodContext(arguments), {
            params: {
                applicationName,
                environmentName,
                includeEmailAddress: true
            }
        }).then(value => value.data));
    }

    @APIMethod("/types", {
        options: {
            noTrailingSlash: true
        }
    })
    public getTypes() {
        const context = apiMethodContext(arguments);

        return from(new Promise<IdentityApplicationTypeConfig[]>((resolve, reject) => {
            CloudConsoleClient.get<IdentityApplicationTypeConfig[]>(context).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while fetching application types")));
        }));
    }

    @APIMethod("/:applicationName/gateway/:environmentName")
    private create(environmentName: IdentityApplicationEnvironmentType, request?: IdentityApplicationCreateRequest) {
        const context = apiMethodContext(arguments);

        return new Promise<IdentityApplication>((resolve, reject) => {
            CloudConsoleClient.post(context, request, {
                params: {
                    applicationName: request?.name,
                    environmentName
                }
            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while creating the application"))
            );
        });
    }

    @APIMethod("/:applicationName/gateway/:environmentName")
    private update(environmentName: IdentityApplicationEnvironmentType, applicationName: string, request?: IdentityApplicationUpdateRequest) {
        const context = apiMethodContext(arguments);

        return new Promise<IdentityApplication>((resolve, reject) => {
            CloudConsoleClient.put(context, request, {
                params: {
                    applicationName,
                    environmentName
                }
            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while updating the application"))
            );
        });
    }

    @APIMethod("/:applicationName/gateway/:environmentName")
    public delete(environmentName: IdentityApplicationEnvironmentType, applicationName: string) {
        const context = apiMethodContext(arguments);

        return new Promise<void>((resolve, reject) => {
            CloudConsoleClient.delete<void>(context, {
                params: {
                    applicationName,
                    environmentName
                }
            }).then(
                () => resolve(),
                error => reject(formatError(error, "Unknown error occured while deleting the application"))
            );
        });
    }

    @APIMethod("/:applicationName/gateway/:environmentName/:publishType")
    private publish(publishType: IdentityApplicationPublishType, environmentName: IdentityApplicationEnvironmentType, applicationName: string, request?: IdentityApplicationPublishRequest) {
        const context = apiMethodContext(arguments);
        const method = publishType === "update" ? CloudConsoleClient.put : CloudConsoleClient.post;

        return new Promise<IdentityApplication>((resolve, reject) => {
            method(context, request, {
                params: {
                    applicationName,
                    environmentName,
                    publishType
                }
            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while publishing the application"))
            );
        });
    }

    @APIMethod("/:applicationName/gateway/:environmentName/saml", {
        options: {
            noTrailingSlash: true
        }
    })
    private configureSAML(environmentName: IdentityApplicationEnvironmentType, applicationName: string, request: IdentityApplicationSAMLRequest) {
        const context = apiMethodContext(arguments);

        return new Promise<IdentityApplication>((resolve, reject) => {
            CloudConsoleClient.put(context, request.metadata, {
                params: {
                    applicationName,
                    environmentName
                },
                headers: {
                    "content-type": "application/xml"
                }
            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while configuring the SAML request"))
            );
        });
    }
}

const PublisherApplicationService = new PublisherApplicationServiceFactory();

export default PublisherApplicationService;