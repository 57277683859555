import { makeStyles, createStyles } from "@material-ui/core";

export const useCreateFormStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: "1038px",
            marginLeft: "auto",
            marginRight: "auto"
        }
    }),
);