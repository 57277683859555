import React, { FC, memo, forwardRef, PropsWithRef, Ref, useImperativeHandle, useCallback } from 'react';
import { Dialog, DialogTitle, makeStyles, createStyles, Divider, IconButton } from "@material-ui/core";
import { FormattedMessage } from 'react-intl';
import CertificateUploadForm from './forms/CertificateUploadForm';
import CertificateUpdateForm from './forms/CertificateUpdateForm';
import CloseIcon from '@material-ui/icons/Close';
import { useCompactDialog } from '../../../legacy-components/dialog/UseCompactDialog';
import { ICertificate } from './CertificateService';
import { CertificateGridRef } from "./CertificateGrid";

import { CertificateFormData } from "./models/certificate.model";

const useStyles = makeStyles(theme => {
    return createStyles({
        header: {
            position: "relative"
        },
        headerFloatControl: {
            position: "absolute",
            top: -24,
            right: theme.spacing(3)
        },
        listItemIcon: {
            minWidth: theme.spacing(3)
        },
        checkbox: {
            padding: theme.spacing(0)
        },
        floatControl: {
            boxShadow: theme.shadows[2],
            zIndex: 1
        },
        list: {
            minHeight: 420,
            padding: theme.spacing(0)
        },
        dialogContentLayout: {
            width: "100%",
            height: "100%",
            position: "relative"
        },
        dialogContent: {
            padding: theme.spacing(0)
        },
        listRenderer: {
            "& .ReactVirtualized__Grid__innerScrollContainer": {
                margin: theme.spacing(2)
            }
        },
        listView: {
            width: "100%",
            height: "100%",
            position: "relative"
        },
        listCenterView: {
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            "&__content": {
                color: theme.palette.text.secondary,
                textAlign: "center"
            }
        },
        listErrorView: {
            "&__icon": {
                color: theme.palette.text.secondary,
                "&-placeholder": {
                    paddingBottom: theme.spacing(1),
                    textAlign: "center"
                }
            }
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1)
        },
        createForm: {
            padding: theme.spacing(3)
        },
        dialog: {
            overflowX: "hidden"
        }
    })
});

export interface CertificateUploadDialogRef {
    open(): void,
    close(): void
}

interface CertificateUploadDialogProps {
    certificate?: any,
    ref?: Ref<CertificateUploadDialogRef | undefined>,
    gridRef?: Ref<CertificateGridRef | undefined>,
    onSuccess: () => void
}

interface CertificateUploadProps {
    certificate?: ICertificate,
    onClose: () => void
    onSubmit: (data?: CertificateFormData) => void
}

export const CertificateUploadContent: FC<CertificateUploadProps> = ({ certificate, onClose, onSubmit }) => {
    const style = useStyles();
       
    return (
        <div>
            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Certificate Upload" />
                </DialogTitle>

                <IconButton className={style.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>

                <Divider />
            </div>
            <div className={style.createForm}>
                {certificate ?
                    <CertificateUpdateForm certificate={certificate} onCancel={onClose} onSuccess={onSubmit} />
                    :
                    <CertificateUploadForm onCancel={onClose} onSuccess={onSubmit} />
                }
            </div>
        </div>
    )
}

const CertificateUploadDialog: FC<PropsWithRef<CertificateUploadDialogProps>> = forwardRef(({ certificate, onSuccess }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();
    const onClose = useCallback(() => {
        setOpen(false);
    }, []);
   
    const onSubmit = useCallback(() => {
        setOpen(false);
        onSuccess();
    }, []);

    const handleClickOpen = (info?: void) => {
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen(),
        close: () => onClose()
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={onClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            classes={{
                paper: style.dialog
            }}>

            <CertificateUploadContent certificate={certificate} onClose={onClose} onSubmit={onSubmit} />
        </Dialog>
    )
});

CertificateUploadDialog.propTypes = {
};

export default memo(CertificateUploadDialog);