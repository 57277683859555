import React, { useContext } from 'react'
import FormContainer from '../../../../legacy-components/form/container/FormContainer';
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import { FormRenderProps, Field } from 'react-final-form';
import { Button } from '@material-ui/core';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { FormApi, FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import FormTextInput from '../../../../legacy-components/form/input/FormTextInput';
import { ValidatorBuilderContext } from '../../../../legacy-components/form/validator/Validator';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { withRouter } from 'react-router';
import TeamServices from '../TeamService';

export interface UserInviteFormData {
    emailAddress?: string;
    role?: string;
    team?: string;
}


const UserInviteForm = (props: any) => {
    const intl = useIntl();
    const [options] = useState([
        {
            "key": "Trimble Developer",
            "value": "Trimble Developer"
        },
        {
            "key": "Trimble Business User",
            "value": "Trimble Business User"
        },
        {
            "key": "Team Admin",
            "value": "Team Admin"
        },
        {
            "key": "Consumer",
            "value": "Consumer"
        },
        {
            "key": "Restricted Trimble Developer",
            "value": "Restricted Trimble Developer"
        }

    ]);

    const [] = useState<Array<any>>([]);


    const onSubmit = () => (data: UserInviteFormData, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve(null);
            } else {

                const gateway = data.role === "Restricted Trimble Developer" ? "trimble-pre-prod" : null;
                TeamServices.forInviteUser(gateway, {
                    "name": data.emailAddress,
                    "emailAddress": data.emailAddress,
                    "role": data.role,
                    "team": props.match.params.teamName
                }).then(() => {
                    resolve();
                }).catch((error) => {
                    resolve({
                        [FORM_ERROR]: error ?? "Unknown error occured while inviting the user"
                    });
                })
            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        const validator = useContext(ValidatorBuilderContext);
        return (<>

            <FormInputContainer>
                <Field
                    name="emailAddress"
                    type="text"
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "EmailAddress"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "EmailAddress"
                    })}
                    validate={validator.from({
                        required: true,
                        pattern: ({ GenericEmail }) => GenericEmail
                    })}
                />
            </FormInputContainer>

            <FormInputContainer>
                <Field
                    name="role"
                    type="text"
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Role"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Role"
                    })}
                    validate={validator.from({
                        required: true
                    })}
                    options={options}
                />
            </FormInputContainer>

            <ActionInliner>
                <Button onClick={props.onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Send Invite" /></Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={props.onSuccess} children={FormComponents} />;
}

UserInviteForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
}

export default withRouter(UserInviteForm);