import React, { memo } from "react";
import { SvgIconProps, SvgIcon} from "@material-ui/core";



function ApplicationIcon(props: SvgIconProps) {

        return (
          <SvgIcon {...props} viewBox="0 0 28 24" fill="none">
            <path
              d="M12.9847 9.87595C11.6686 8.99903 10.0659 8.61277 8.47567 8.78925C6.8854 8.96573 5.41619 9.69288 4.34215 10.835C2.60831 12.6497 2.11136 15.3813 3.17267 17.6523C3.56872 18.5243 4.1628 19.2999 4.91128 19.922C5.65975 20.544 6.54358 20.9968 7.49781 21.247C8.45204 21.4973 9.45242 21.5386 10.4254 21.3679C11.3984 21.1973 12.3193 20.819 13.1204 20.2609C15.3837 18.6787 16.3617 15.899 15.5565 13.318L19.4415 10.602L20.7363 12.3168C21.4485 13.2599 22.8331 13.4709 23.8133 12.7857C24.7935 12.1005 25.0128 10.7682 24.3006 9.82505L23.0058 8.11026C23.986 7.42504 24.2053 6.09274 23.4932 5.14961C22.781 4.20648 21.3964 3.99546 20.4162 4.68069L12.9847 9.87595ZM10.5486 16.8189C9.56844 17.5041 8.18381 17.2931 7.47166 16.3499C6.75952 15.4068 6.97882 14.0745 7.95901 13.3893C8.93919 12.7041 10.3238 12.9151 11.036 13.8582C11.7481 14.8013 11.5288 16.1336 10.5486 16.8189Z"
              fill="inherit"
            />
          </SvgIcon>
        );
      };
      
export default memo(ApplicationIcon);