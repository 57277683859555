import React, { useCallback, useContext } from "react";
import {
  IconButton,
  Tooltip,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Dialog,
  Box,
  Typography,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import AlertService from "legacy-components/alert/AlertService";
import { DialogTitle, useDialogProps } from "legacy-components/dialog/Dialog";
import ConsumerSubscriptionService from "../../services/ConsumerSubscriptionService";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";

export interface UnsubscriberProps {
  subscriptionId: string;
  applicationName: string;
  isIcon: boolean;
  isPrimaryColor?: boolean;
  reloadData: () => void;
}

export const Unsubscriber: React.FC<UnsubscriberProps> = ({
  subscriptionId,
  applicationName,
  isIcon,
  isPrimaryColor,
  reloadData,
}) => {
  const { open, toggle } = useDialogProps();
  const history = useHistory();

  const removeSubscription = useCallback(() => {
    ConsumerSubscriptionService.deleteSubscription(subscriptionId)
      .toPromise()
      .then(
        (output) => {
          AlertService.success({
            message: `Subscription is removed for ${applicationName} Application`,
            isAutoHide: true,
            id: "UNSUBSCRIBER",
          });
          toggle();
          reloadData();
        },
        (error) => {
          AlertService.error({
            message: "Failed to revoke Subscription ",
            isAutoHide: true,
            id: "UNSUBSCRIBER",
          });
          toggle();
        }
      );
  }, [subscriptionId, toggle, applicationName, reloadData]);

  return (
    <>
      <Tooltip title={"Delete Subscription"}>
        {isIcon ? (
          <span>
            <IconButton
              onClick={toggle}
              size="small"
              data-testid={`${applicationName}-delete-btn`}
            >
              {isPrimaryColor ? <DeleteIcon color="primary" /> : <DeleteIcon/>}
            </IconButton>
          </span>
        ) : (
          <Button
            onClick={toggle}
            variant="contained"
            size="medium"
            color="primary"
            style={{ textTransform: "none" }}
          >
            <FormattedMessage defaultMessage="Unsubscribe" />
          </Button>
        )}
      </Tooltip>
      <Dialog maxWidth={"md"} open={open} title="Remove Subscription" onClose={toggle} style={{padding: "20px"}}>

        <DialogTitle title={"Remove Subscription"} handleClose={toggle}/>
          <Box padding="0 20px" ><Divider/></Box>
          <DialogContent>
            <Box padding="30px" >
            <Typography variant="body1" align="center" style={{fontSize: "18px", lineHeight:"44px"}}>
              <b>Are you sure you want to remove this subscription?</b>
              <br />
              Removing a subscription may affect API traffic in your
              environments.
              <br />
              You should expect a 403 error when a subscription is not active.
            </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{padding:"20px"}}>
            <Button variant="outlined" color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button
              onClick={removeSubscription}
              color="primary"
              variant="contained"
            >
              Remove Subscription{" "}
            </Button>
          </DialogActions>
      </Dialog>
    </>
  );
};

export default Unsubscriber;
