import '@formatjs/intl-pluralrules/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import * as serviceWorker from './serviceWorker';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'react-virtualized/styles.css';
import './index.scss';

import { bootstapApplication } from './app';
import store from './store/store';
import authenticationManager from './utils/authenticationManager';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './themes/mui';

const init = (App: any) => (
     <React.Fragment>
          <CssBaseline />
          <Provider store={store}>
               <OidcProvider store={store} userManager={authenticationManager}>
                    <ThemeProvider theme={theme}>
                         <BrowserRouter>
                              {App}
                         </BrowserRouter>
                         <CssBaseline />
                    </ThemeProvider>
               </OidcProvider>
          </Provider>
     </React.Fragment>
);


//ReactDOM.render(app, document.getElementById('root'));

bootstapApplication({ locale: window.navigator.language }).then(app => {
     ReactDOM.render(init(app), document.getElementById('root'))
});

document.addEventListener("load", () => serviceWorker.register());


/* If you want your app to work offline and load faster, you can change
 unregister() to register() below. Note this comes with some pitfalls.
 Learn more about service workers: https://bit.ly/CRA-PWA
*/