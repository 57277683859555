import React, { FC, useContext, useState, useEffect } from 'react'
import FormContainer, { FormContainerOptions, FormContainerSubmitHandler } from '../../../../legacy-components/form/container/FormContainer';
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import { Field, FormRenderProps } from 'react-final-form';
import FormTextInput from '../../../../legacy-components/form/input/FormTextInput';
import { Button, InputLabel, Select, Input, Chip, MenuItem, makeStyles, Theme, createStyles, Checkbox, ListItemText, FormControl } from '@material-ui/core';
import { ValidatorBuilderContext } from '../../../../legacy-components/form/validator/Validator';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import UnifiedAPIService, { UnifiedAPI } from '../../../../services/UnifiedAPIService';
import PropTypes from "prop-types";
import { theme } from 'themes/mui';
import Alert from '@material-ui/lab/Alert';
import ProductService from 'libs/resources/product/ProductService';
import { AccordionContent } from 'legacy-components/form/components/accordion-form/AccordionForm';

interface ProductApiTypeFormProps {
    stepperId: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: "100%",
            maxWidth: "auto",
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);

const ProductApiTypeForm: FC<ProductApiTypeFormProps> = ({ stepperId }) => {
    const validator = useContext(ValidatorBuilderContext);
    const [dataFields] = useState(["__productInfo"]);
    const [apiType, setapiType] = React.useState<string[]>([]);
    const onSubmit: FormContainerSubmitHandler<any> = ({ stepperOptions }) => {
        return (data, form) => {
            return new Promise((resolve) => {

                UnifiedAPIService.put(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.edit_product_api, {
                        id: stepperOptions?.formData?.about?.__productInfo.name
                    }), {
                    apiTypeMapping: apiType ?? undefined,
                }).then(() => {
                    form.mutators.setValue("apiType", apiType);
                    resolve();
                }
                    , error => {
                        resolve({
                            [FORM_ERROR]: error.message
                        });
                    });
            })
        }
    }


    const FormComponents = (formRenderProps: FormRenderProps<any, {}>, containerOptions: FormContainerOptions) => {
        const values = formRenderProps.values;
        const classes = useStyles();
        const [names, setNames] = useState<Array<string> | null>(null);

        useEffect(() => {
            setapiType(formRenderProps.values.apiType ? formRenderProps.values.apiType : apiType);
            ProductService.getApiTypes().toPromise()
                .then(
                    (output) => {
                        setNames(output);
                    },
                    (error) => {
                    });
        }, [])


        const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
            setapiType(event.target.value as string[]);
        };

        const handleDelete = (chipToDelete: string) => () => {
            setapiType((chips) => chips.filter((chip) => chip !== chipToDelete));
          };

        return (<>
            <AccordionContent>
                <Alert severity="info" className="mt-2">
                    Please choose API Types for your product which will be used to filtering products based on API Type in the developer marketplace.
            </Alert>
            </AccordionContent>
            <FormInputContainer padding="inset">
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-chip-label">API Type</InputLabel>
                    <Select

                        id="apiTypes"
                        multiple
                        onChange={handleChange}
                        renderValue={() => <div className={classes.chips}>
                            {   
                                <Chip key="no" label={`${apiType.length} selected`} className={classes.chip} />
                            }
                        </div>}
                        value={apiType}
                    >
                        {names?.map((name) => (
                            <MenuItem key={name} value={name} >
                                <Checkbox checked={apiType.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                    <div className={classes.chips}>
                        {(apiType as string[]).map((value) => (
                            <Chip key={value} label={value} className={classes.chip} onDelete= {handleDelete(value)}/>
                        ))}
                    </div>
                </FormControl>
            </FormInputContainer>
            <ActionInliner padding="inset">
                {containerOptions.stepperOptions.navigateBack && <Button onClick={containerOptions.stepperOptions.navigateBack} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Back" /></Button>}

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation>
                    <FormattedMessage defaultMessage="Continue" />
                </Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer previewErrors={true} stepperId={stepperId} dataFields={dataFields} FormProps={{ onSubmit }} children={FormComponents} />;
}

ProductApiTypeForm.propTypes = {
    stepperId: PropTypes.string.isRequired
}

export default ProductApiTypeForm;