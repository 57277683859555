import authenticationManager from "../utils/authenticationManager";

/**
 * @class AuthService
 * @type Service Layer
 * @description For Identity Access Management
 */
class AuthService {

     static signInRedirect = () => {
          authenticationManager.signinRedirect();
     };

     static  signinRedirectCallback = () => {
          return authenticationManager.signinRedirectCallback();
     };

     
     static signOutRedirect = (token) => {
          authenticationManager.revokeAccessToken();
          authenticationManager.removeUser();
          authenticationManager.clearStaleState();
          sessionStorage.removeItem('isProductFirstHovered');
          setTimeout(() => {
               authenticationManager.signoutRedirect({ id_token_hint: token });
             }, 500);
     };

     static isAuthenticated = (props) => {
          return (props && props.auth && props.auth?.user?.access_token) ? true : false;
     }
}

export default AuthService;