import React, { FC, useRef, useEffect, useContext, useCallback } from 'react'
import FormContainer, { FormContainerOptions, FormContainerSubmitHandler } from '../../../../legacy-components/form/container/FormContainer';
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import { FormRenderProps, Field, useFormState } from 'react-final-form';
import { Button } from '@material-ui/core';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { FORM_ERROR } from 'final-form';
import { FormattedMessage } from 'react-intl';
import { ProductProxyFormData, ProductAboutFormData } from '../models/products.model';
import FormSelectionGrid from '../../../../legacy-components/form/input/FormSelectionGrid';
import { FormSelectionGridDataValue } from '../../../../legacy-components/form/input/FormSelection';
import ProductProxyDialog from './ProductProxyDialog';
import { AssignableCollectionItem } from '../../../../legacy-components/form/collection/AssignableCollection';
import PropTypes from "prop-types";
import UnifiedAPIService, { UnifiedAPI } from '../../../../services/UnifiedAPIService';
import { ProductProxyTitle, ProductProxyControls, useProductProxyGrid } from './ProductProxyInfo';
import { SnackNotificationContext } from '../../../../legacy-components/notifications/GenericSnackNotification';

interface ProductProxyFormProps {
    stepperId: string
}

interface ProxyGridProps {
    productName?: string
}


const ProductProxyForm: FC<ProductProxyFormProps> = ({ stepperId }) => {
    const proxyFormRef = useRef({
        formProxyFieldMount: true
    });
    const NotificationMessage = (props: any) => {
        return (
            <span>{props.error}</span>
        )
    }
    const notificationContext = useContext(SnackNotificationContext);
    const onSubmit: FormContainerSubmitHandler<ProductProxyFormData, {
        about?: ProductAboutFormData
    }> = ({ stepperOptions }) => (data, form) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                if (data.proxies?.hasChanges) {
                    UnifiedAPIService.post(
                        UnifiedAPI.parseRequestURL(UnifiedAPI.map_proxy_product, {
                            productName: stepperOptions?.formData?.about?.name
                        }), {
                        addApis: data.proxies.toAdd.map(proxy => proxy.value.name).filter(value => !!value),
                        removeApis: data.proxies.toRemove.map(proxy => proxy.value.name).filter(value => !!value)
                    }).then(() => resolve(), error => {
                        if (error.response?.data?.message) {
                            notificationContext.show({
                                timeout: 6000,
                                id: "not-verified",
                                message: <NotificationMessage error={error.response?.data?.message} />
                            });
                        }
                        resolve({
                            [FORM_ERROR]: error.response?.data?.message ?? "Unknown error occured while mapping the proxy"
                        });
                    });
                } else {
                    resolve();
                }
            }
        })
    }

    const ProxyGrid: FC<ProxyGridProps> = ({ productName }) => {
        const { gridData, gridOptions, onChange, proxyDialogRef, disabled, openDialog } = useProductProxyGrid(productName);

        const formState = useFormState<ProductProxyFormData>();

        useEffect(() => {
            if (proxyFormRef.current.formProxyFieldMount) {
                if (formState.values?.proxies && Array.isArray(formState.values?.proxies?.list)) {
                    onChange(formState.values.proxies.list
                        .filter(value => value.assign)
                        .map((value: AssignableCollectionItem<FormSelectionGridDataValue>) => ({
                            key: value.key,
                            value: value.value.proxy,
                            assign: true,
                            assigned: true
                        })));
                }

                proxyFormRef.current.formProxyFieldMount = false;
            }
        }, [formState, onChange]);

        const handleOpenDialog = useCallback(() => openDialog(() => formState.values?.proxies?.list), [formState, openDialog])

        return (
            <>
                <ProductProxyDialog onChange={onChange} ref={proxyDialogRef} />
                <FormInputContainer padding="inset-title" headerStyle="tinted" title={<ProductProxyTitle count={formState.values["proxies"]?.count} />} controls={<ProductProxyControls disabled={disabled} onClick={handleOpenDialog} />}>

                    <Field
                        name="proxies"
                        component={FormSelectionGrid}
                        gridOptions={gridOptions}
                        gridData={gridData}
                    />
                </FormInputContainer>
            </>
        )
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>, containerOptions: FormContainerOptions<ProductProxyFormData, {
        about?: ProductAboutFormData
    }>) => {
        return (<>
            <ProxyGrid productName={containerOptions?.stepperOptions?.formData?.about?.__productInfo?.name} />

            <ActionInliner padding="inset">
                {containerOptions.stepperOptions.navigateBack && <Button onClick={containerOptions.stepperOptions.navigateBack} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Back" /></Button>}

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Continue" /></Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer stepperId={stepperId} FormProps={{ onSubmit }} children={FormComponents} />;
}

ProductProxyForm.propTypes = {
    stepperId: PropTypes.string.isRequired
}

export default ProductProxyForm;
