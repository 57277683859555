import { useReducer, Reducer, useEffect, useState } from "react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

export interface AssignableCollectionItem<T = unknown> {
    key: string,
    value: T,
    assign?: boolean
    assigned?: boolean
}

export interface AssignableCollectionHandlers {
    toggleHandler: () => void
}

export type AssignableCollectionFinder<T> = AssignableCollectionItem<T> & AssignableCollectionHandlers;

interface AssignableCollectionState<T = unknown> {
    keys: string[];
    list: Map<string, AssignableCollectionItem<T>>;
    toAdd: Map<string, AssignableCollectionItem<T>>;
    toRemove: Map<string, AssignableCollectionItem<T>>;
    isEmpty?: boolean
}

interface InitAction {
    type: "init",
    payload: AssignableCollectionItem[]
}

interface AddAction {
    type: "add",
    payload: AssignableCollectionItem
}

interface RemoveAction {
    type: "remove",
    payload: string
}

interface AssignAction {
    type: "assign",
    payload: string
}

interface UnassignAction {
    type: "unassign",
    payload: string
}

interface ToggleAction {
    type: "toggle",
    payload: string
}

interface ClearAction {
    type: "clear"
}

type AssignableCollectionAction = InitAction | AddAction | RemoveAction | ToggleAction | ClearAction | AssignAction | UnassignAction;

const createSource = ({ key, value }: AssignableCollectionItem, assign: boolean = false, assigned: boolean = false): AssignableCollectionItem => {
    return {
        key,
        value,
        assign,
        assigned
    }
}

const addItem = (item: AssignableCollectionItem, { list, toAdd, toRemove }: AssignableCollectionState, assign?: boolean) => {
    const source = createSource(item, assign ?? item.assign, item.assigned);

    list.set(source.key, source);

    if (source.assign && !source.assigned) {
        toAdd.set(source.key, source);
        toRemove.delete(source.key);
    } else if (!source.assign && source.assigned) {
        toAdd.delete(source.key);
        toRemove.set(source.key, source);
    } else {
        toAdd.delete(source.key);
        toRemove.delete(source.key);
    }
}

const removeItem = (key: string, { list, toAdd, toRemove }: AssignableCollectionState) => {
    if (list.has(key)) {
        const source = list.get(key);

        if (source !== undefined) {
            if (source.assigned) {
                toAdd.delete(source.key);
                toRemove.set(source.key, source);
            } else {
                list.delete(key);
                toAdd.delete(source.key);
                toRemove.delete(source.key);
            }
        }
    }
}

const createState = (state: AssignableCollectionState): AssignableCollectionState => {
    const keys = Array.from(state.list.keys());
    return {
        ...state,
        keys,
        isEmpty: keys.length < 1
    }
}

const assignableCollectionReducer: Reducer<AssignableCollectionState, AssignableCollectionAction> = (state, action) => {
    switch (action.type) {
        case 'add':
            {
                if (action.payload && typeof action.payload === "object" && !Array.isArray(action.payload)) {
                    addItem(action.payload, state);
                    return createState(state);
                }

                return state;
            }
        case 'remove':
            {
                if (action.payload && !Array.isArray(action.payload)) {
                    removeItem(action.payload, state);
                    return createState(state);
                }
                return state;
            }

        case 'clear':
        case 'init': {
            state.list.clear();
            state.toAdd.clear();
            state.toRemove.clear();

            if (action.type === "init" && Array.isArray(action.payload)) {
                action.payload.forEach(item => addItem(item, state))
            }

            return createState(state);
        }
        case 'toggle':
        case 'unassign':
        case 'assign':
            {
                const source = state.list.get(action.payload);

                if (source !== undefined) {
                    addItem(source, state, action.type === "toggle" ? !source.assign : action.type === "assign");
                }

                return { ...state };
            }
        default:
            return state;
    }
}

export interface AssignableCollectionApi<T> {
    toArray: () => AssignableCollectionItem<T>[],
    withHandlers<U>(mapFn?: (value: AssignableCollectionFinder<T>) => U): U[] | AssignableCollectionFinder<T>[],
    findIndex: (index: number) => AssignableCollectionFinder<T> | undefined,
    assign: (key: string) => void,
    unassign: (key: string) => void,
    add: (item: AssignableCollectionItem<T>) => void,
    remove: (key: string) => void,
    toggle: (key: string) => void,
    clear: () => void,
    init: (items: AssignableCollectionItem<T>[]) => void,
}

export function useAssignableCollection<T = unknown>(items?: AssignableCollectionItem<T>[] | {
    value: AssignableCollectionItem<T>[]
}): [AssignableCollectionState<T>, AssignableCollectionApi<T>] {
    const [toggle$] = useState(new Subject<string>());

    const [state, dispatch] = useReducer(assignableCollectionReducer, {
        keys: [],
        list: new Map(),
        toAdd: new Map(),
        toRemove: new Map()
    });

    useEffect(() => {
        dispatch({
            type: "init",
            payload: Array.isArray(items) ? items : items?.value ?? []
        })
    }, [items]);

    useEffect(() => {
        const subs = toggle$.pipe(debounceTime(200)).subscribe(key => dispatch({
            type: "toggle",
            payload: key
        }));
        return () => subs.unsubscribe();
    }, [toggle$])

    function* filterCollection<T>(iterable: IterableIterator<T>, predicate: (value: T, index: number) => boolean) {
        var i = 0;
        for (var item of iterable)
            if (predicate(item, i++))
                yield item;
    }

    const toArray = (): AssignableCollectionItem<T>[] => {
        const collection = filterCollection(state.list.values(), value => !!(value.assign || value.assigned));

        return Array.from(collection) as AssignableCollectionItem<T>[];
    }

    function withHandlers<U>(mapFn?: (value: AssignableCollectionFinder<T>) => U): U[] | AssignableCollectionFinder<T>[] {
        const collection = filterCollection(state.list.values(), value => !!(value.assign || value.assigned));

        return Array.from(collection, value => {
            const item = {
                ...value,
                toggleHandler: () => toggle(value.key)
            } as AssignableCollectionFinder<T>;


            return mapFn?.(item) ?? item;
        }) as U[];
    }

    const findIndex = (index: number): AssignableCollectionFinder<T> | undefined => {
        const source = state.list.get(state.keys[index]) as AssignableCollectionItem<T>;

        return source ? {
            ...source,
            toggleHandler: () => toggle(source.key)
        } : undefined;
    }

    const assign = (key: string) => dispatch({
        type: "assign",
        payload: key
    });

    const unassign = (key: string) => dispatch({
        type: "unassign",
        payload: key
    });

    const add = (item: AssignableCollectionItem<T>) => dispatch({
        type: "add",
        payload: item
    });

    const remove = (key: string) => dispatch({
        type: "remove",
        payload: key
    });

    const toggle = (key: string) => toggle$.next(key);

    const clear = () => dispatch({
        type: "clear"
    });

    const init = (items?: AssignableCollectionItem<T>[]) => dispatch({
        type: "init",
        payload: items ?? []
    })

    const collectionApi: AssignableCollectionApi<T> = {
        toArray,
        withHandlers,
        findIndex,
        assign,
        unassign,
        add,
        remove,
        toggle,
        clear,
        init
    }

    return [state as AssignableCollectionState<T>, collectionApi]
}