import { resolveAPIErrorCode } from "modus/components/error-message/ErrorMessage";

export const ErrorMessages = { 
    "TCP815400" :  "This Product is not published to the Production" 
};

export type ErrorCodes = "TCP815400";


export const getAPICodeMessge = (error: any) => {
    const code = resolveAPIErrorCode(error);
    if(!code) {
        return "This subscription is failing due to some unknown error";
    }
    return (ErrorMessages?.[code as ErrorCodes] as any);
}