import React from "react";
import type { PropsWithChildren } from "react";

import "./ResponsiveGrid.scss";
import "./Box.scss";
import clsx from "clsx";

export type sideType = "l" | "r" | "t" | "b" | "x" | "y";
export type sizeType = "0" | "1" | "2" | "3";
export type marginType = string; // sizeType | `${sideType}${sizeType}`;
export type paddingType = string; // sizeType | `${sideType}${sizeType}`;
export type colType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface BoxProps {
  margin?: marginType;
  padding?: paddingType;
  align?: "start" | "center" | "end";
  className?: string;
  md?: colType;
  sm?: colType;
  lg?: colType;
  xl?: colType;
}

export interface ResponsiveGridProps extends BoxProps {
  type?: "row" | "column";
}

export const ResponsiveGrid: React.FC<PropsWithChildren<ResponsiveGridProps>> =
  ({
    children,
    margin = 0,
    padding = 0,
    align = "start",
    type = "row",
    md,
    sm,
    lg,
    xl = 12,
    className,
  }) => {
    lg = lg ?? xl;
    md = md ?? lg;
    sm = sm ?? md;

    return (
      <div
        className={clsx([
          type,
          `col-xs-12`,
          `col-sm-${sm}`,
          `col-md-${md}`,
          `col-lg-${lg}`,
          `col-xl-${xl}`,
          `m-${margin}`,
          `p-${padding}`,
          `flex-${align}`,
          `m-${margin}`,
          `p-${padding}`,
          `flex-${align}`,
          className,
        ])}
      >
        {children}
      </div>
    );
  };

export const Row: React.FC<PropsWithChildren<BoxProps>> = (props) => (
  <ResponsiveGrid type="row" {...props} />
);
export const Column: React.FC<PropsWithChildren<BoxProps>> = (props) => (
  <ResponsiveGrid type="column" {...props} />
);
export const Box: React.FC<PropsWithChildren<BoxProps>> = ({
  margin = 0,
  padding = 0,
  className,
  children,
}) => (
  <span
    className={clsx([
      `m-${margin}`,
      `p-${padding}`,
      `m-${margin}`,
      `p-${padding}`,
      className,
    ])}
  >
    {children}
  </span>
);

export default ResponsiveGrid;
