import React from "react";
import { DialogContent, DialogTitle } from "@material-ui/core";


const EnvironmentHelper = (props: any) => {
    return (
        <section>
            <DialogTitle>What does environment mean here?</DialogTitle>
            <DialogContent>
                <p> A software development environment (SDE) is the collection of hardware and software tools a system developer uses to build software systems. Currently we are classifying these enviornments into two types </p>
                <ul>
                    <li><strong>Lower Environments</strong>
                        <p>Environments which are used for API / Application development, Testing and User Acceptance comes in this category.
                        Current we have the following list of Environments for Proxies.</p>
                        <ul>
                            <li><i>Development</i> </li> 
                            <li><i>Quality Assurance</i> </li>
                            <li><i>Staging</i> </li>
                        </ul>
                        <br/>
                    </li>
                    <li>
                        <strong>Production Environment</strong>
                        <p> Currently we have Production stack in a separate gateway for security and avaialbility purpose.</p>
                    </li>
                </ul>
            </DialogContent>
        </section>
    );
}
export default EnvironmentHelper;