import React, { useCallback, useEffect, useRef, useState } from "react";

import MuiTextField from "@material-ui/core/TextField";
import type { StandardTextFieldProps } from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
//import "./TextInput.module.scss";
import clsx from "clsx";
import { InputAdornment } from "@material-ui/core";
import { notBlank } from "legacy-components/Helper";

export interface TextInputProps extends StandardTextFieldProps {
  label?: string;
  id: string;
  initialValue?: string;
  type?: string;
  disabled?: boolean;
  helperMessage?: string;
  placeHolder?: string;
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  multiline?: boolean;
}

interface IAdornment {
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
}

/**
 * @component TextInput
 * @param {*} props
 */

export const TextInput: React.FC<TextInputProps> = ({
  label,
  id,
  initialValue,
  type = "text",
  disabled = false,
  placeHolder,
  endIcon,
  startIcon,
  multiline = false,
  ...textFieldProps
}) => {
  const [error, setError] = useState<any>();
  const handleChange = useCallback((event, controller) => {
    controller.field.onChange(event.target.value);
  }, []);

  const [adornment, setAdornment]  = useState<IAdornment>({});

  useEffect(() => {
    if (notBlank(endIcon)) {
      const end = <InputAdornment position="end">{endIcon}</InputAdornment>;
      setAdornment ({
        ...adornment,
        endAdornment: end,
      });
    }
  }, [endIcon]);
  useEffect(() => {
    if (notBlank(startIcon)) {
      const start = (
        <InputAdornment position="start">{startIcon}</InputAdornment>
      );
      setAdornment ({
        ...adornment,
        startAdornment: start,
      });
    }
  }, [startIcon]);

 
  return (
    
          <MuiTextField
            type={type}
            id={id}
            disabled={disabled}
            placeholder={placeHolder}
            aria-invalid={error ? true : false}
            multiline={multiline}
            InputProps={adornment}
            label={label}
            {...textFieldProps}
          />
  );
};

export default React.memo(TextInput);
