import React, { memo, FC, useRef, useEffect, useCallback, MutableRefObject, useState } from 'react';
import ProductDocumentField, { ProductDocumentFieldFileInfo, ProductDocumentFieldRef } from './ProductDocumentField';
import FormContainer, { FormContainerChild, FormContainerSubmitHandler } from '../../../../../legacy-components/form/container/FormContainer';
import { ProductPublishFormData, ProductDocumentPublishedData } from '../../models/products.model';
import ActionInliner from '../../../../../legacy-components/form/action/ActionInliner';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormattedMessage } from 'react-intl';
import ProductDocumentFormConfirmDialog, { ProductDocumentFormConfirmDialogRef } from './ProductDocumentFormConfirmDialog';
import SpecDocumentationService from '../../../../../libs/resources/documentation/DocumentationService';
import { FORM_ERROR } from 'final-form';
import ClearIcon from '@material-ui/icons/Clear';
import ProductDocumentPublishAction from './ProductDocumentPublishAction';
import { sanitizeText } from '../FileXSSRemover';

interface ProductDocumentFormProps {
    docInfo: ProductDocumentFieldFileInfo,
    onDelete: () => void;
    onChange: (data: ProductDocumentPublishedData) => void;
    onCreate: (data: ProductDocumentFieldFileInfo) => void;
    productName: string;
}

interface ProductDocumentSyncFormProps {
    onCreate: (data: ProductDocumentFieldFileInfo) => void;
    docInfo?: ProductDocumentFieldFileInfo;
    docFieldRef: MutableRefObject<ProductDocumentFieldRef | undefined>;
    productName: string;
}

const DocumentFieldControls: FC<{
    onDelete: () => void,
    onClear: () => void,
    onChange: (data: ProductDocumentPublishedData) => void,
    docInfo: ProductDocumentFieldFileInfo,
    productName: string;
}> = ({ onDelete, docInfo, onClear, productName, onChange }) => {
    return (
        docInfo.loaded ? (
            <ActionInliner>
                <ProductDocumentPublishAction docInfo={docInfo} productName={productName} onChange={onChange} />
                <Tooltip enterDelay={1000} title={<FormattedMessage defaultMessage="Delete Document" />}>
                    <IconButton onClick={onDelete} size="small">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </ActionInliner>
        ) : (
                <ActionInliner>
                    <Tooltip enterDelay={1000} title={<FormattedMessage defaultMessage="Clear" />}>
                        <IconButton onClick={onClear} size="small">
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </ActionInliner>
            )
    );
}
let fileReader:FileReader = new FileReader();
const ProductDocumentSyncForm: FC<ProductDocumentSyncFormProps> = ({ docInfo, docFieldRef, productName, onCreate }) => {
  const [sanitizedFile, setSanitizedFile] = useState<File>();

  useEffect(() => {
    if(!docInfo?.file) {
        return;
    }
    fileReader.onload = (e) => {
      const sanitizedContent = sanitizeText(e?.target?.result as string) as string;
      // Create a new file with the sanitized content using the original file type and name
      const blob = new Blob([sanitizedContent], { type: docInfo?.file?.type });
      const newFile = new File([blob], docInfo?.file?.name as string, { type: docInfo?.file?.type });
      setSanitizedFile(newFile);
    };

   fileReader.readAsText(docInfo.file);
  }, [docInfo?.file]);

  const triggerUpload = useCallback(async (resolve: (value: unknown) => void) => {
    if (docInfo?.file && sanitizedFile) {
        try {
            docFieldRef?.current?.progress(0);
            const response = await SpecDocumentationService.uploadDocument({
                file: sanitizedFile as File,
                productName,
                name: productName
            });

            docFieldRef?.current?.progress(100);

            onCreate({
                id: response.id,
                fileInfo: docInfo.fileInfo,
                openApiSpecId: response.id,
                loaded: true,
                isNew: docInfo.isNew
            });

            resolve(null);
        } catch (error) {
            docFieldRef.current?.error();
            resolve({
                [FORM_ERROR]: {
                    documentation: error ?? "Unknown error occured while uploading..."
                }
            });
        }
    } else {
        setTimeout(() => {
            triggerUpload(resolve);
        }, 1000);
    }
},[docFieldRef, docInfo, onCreate, productName, sanitizedFile]);
  useEffect(() => {
    if(!sanitizedFile || !triggerUpload) {
        return;
    }
    triggerUpload(async () => {});
  },[sanitizedFile, triggerUpload]);


    
    const onSubmit: FormContainerSubmitHandler<ProductPublishFormData> = () => () => {
        return triggerUpload(async () => {});
    }

    const FormComponents: FormContainerChild<ProductPublishFormData> = (_formRenderProps, _containerOptions, triggerSubmit) => {
        const onSubmit = useRef(() => triggerSubmit());

        useEffect(() => {
            if (!docInfo?.loaded) {
                onSubmit.current();
            }
        }, [onSubmit]);

        return (<></>);
    }

    return <FormContainer previewErrors={true} FormProps={{ onSubmit }} children={FormComponents} />;
}

function ProductDocumentForm({ docInfo, onDelete, productName, onCreate, onChange }: ProductDocumentFormProps) {
    const confirmDialogRef = useRef<ProductDocumentFormConfirmDialogRef>();

    const onDeleteHandler = useCallback(() => confirmDialogRef?.current?.open(), []);
    const docFieldRef = useRef<ProductDocumentFieldRef>();

    return (
        <>
            <ProductDocumentFormConfirmDialog productName={productName} ref={confirmDialogRef} docInfo={docInfo} onSuccess={onDelete} />
            <ProductDocumentSyncForm onCreate={onCreate} productName={productName} docFieldRef={docFieldRef} docInfo={docInfo} />

            {docInfo && <ProductDocumentField showPublishStatus={true} ref={docFieldRef} docInfo={docInfo} controls={<DocumentFieldControls productName={productName} onChange={onChange} onDelete={onDeleteHandler} onClear={onDelete} docInfo={docInfo} />} />}
        </>
    )
}

ProductDocumentForm.propTypes = {

}

export default memo(ProductDocumentForm);

