import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import ApplicationService from "../../../../libs/resources/identity/applications/ApplicationService";

export const useResourceAppVersionProvider = (resourceAppId : string) => {

    const [state, { subscribe }] = useAsyncAction(() => ApplicationService.getAllVersions(resourceAppId));

    const getVersions = () => {
        subscribe();
    }

    return {versionState: state, getVersions}

}