import { Button, Paper, RadioGroup, Slide, DialogContent, DialogActions, Divider, FormControlLabel, Radio, FormControl, Input } from "@material-ui/core";
import Alert from "legacy-components/alert/Alert";
import AlertService from "legacy-components/alert/AlertService";
import GenericPageTitle from "legacy-components/typography/generic-page-title/GenericPageTitle";

import React, { useCallback, useContext, useState, useRef } from "react";
import type { IApplication } from "../../../service/ProductService";
import SubscriptionService from "../../../service/SubscriptionService";
import ApplicationList from "../../applications/application-list/ApplicationList";
import { ProductContext } from "../../products/product/Product";
import useSubscriberStyles from "./Subscriber.style";
import Dialog, { useDialogProps } from "legacy-components/dialog/Dialog";
import Typography from "legacy-components/typography/Typography";
import { useHistory, withRouter, RouteComponentProps } from "react-router";
import { connect, ConnectedProps } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import AlertMUI from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import { sanitize } from "dompurify";

export interface SubscriberProps {
  onSubscriptionSuccess: () => void;
}
const mapState = () => ({

});
type PropsFromRedux = ConnectedProps<typeof connector>

const connector = connect(mapState);
export const Subscriber: React.FC<SubscriberProps & PropsFromRedux  & RouteComponentProps> = ({
  onSubscriptionSuccess,
}) => {
  
  const [showSubscriber, setShowSubscriber] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [enableSubscriber, setEnableSubscriber] = useState<boolean>(false);
  const [
    selectedApplication,
    setSelectedApplication,
  ] = useState<IApplication | null>(null);
  const classes = useSubscriberStyles();
  const context = useContext(ProductContext);
  const { open, toggle } = useDialogProps();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const businessContext = useRef<string>("");
  const [appUsecaseDialogOpen, setAppUsecaseDialogOpen] = useState<boolean>(false);
  const onSubscribeClick = useCallback(() => {
    setDialogOpen(true);
    setEnableSubscriber(false);
  }, []);

  const onToggleSubscription = useCallback(() => {
    setShowSubscriber(!showSubscriber);
  }, [showSubscriber]);

  const onSuccess = useCallback(() => {
    onToggleSubscription();
    onSubscriptionSuccess();
    context?.refresh();
  }, [context, onSubscriptionSuccess, onToggleSubscription]);

  const onSubscribe = useCallback(() => {
    setEnableSubscriber(false)
    if (selectedApplication && context?.product) {
      void SubscriptionService.createSubscription(context?.product?.productName, {
        applicationName: selectedApplication?.applicationName,
        gatewayName: selectedApplication?.environment,
        businessContext: businessContext.current
      })
        .toPromise()
        .then(
          (response) => {            
            var message: string = ""
            if(response.data.status === "SUBSCRIBED")
              message = "The application has been subscribed to the API Product successfully."
            else if(response.data.status === "PENDING_REVIEW")
              message = "Your subscription request has been submitted to the owner for review and approval."
            
            AlertService.success({
              message: message,
              isAutoHide: true,
              onClose: onSuccess,
              id: "SUBSCRIBER",
            });
          },
          (error) => {

            AlertService.error({
              message: error?.response?.data?.message,
              isAutoHide: true,
              id: "SUBSCRIBER",
            });
            setEnableSubscriber(true)
          }
        );
    }
  }, [context?.product, onSuccess, selectedApplication]);

  const Controls = () => (
    <section>
      <Button
        className="m-r1"
        color="primary"
        variant="outlined"
        onClick={onToggleSubscription}
      >
        Cancel
      </Button>
      <Button disabled={!enableSubscriber} color="primary"  variant="contained" onClick={onSubscribeApplicationClick}>
        Subscribe
      </Button>
    </section>
  );

  const onApplicationSelected = useCallback((selectedApp: IApplication) => {
    setSelectedApplication(selectedApp);
  }, []);

  const selectedSubscriptionFlow = useRef<String | undefined>();
  const history = useHistory();

  const onNextClick =  useCallback(() => {
    if (selectedSubscriptionFlow.current === "existing") {
      setDialogOpen(false);
      setShowSubscriber(true);
    }
    else if (selectedSubscriptionFlow.current === "new" && context?.product?.productName){
        history.push("/home/applications/create", {productName: context?.product?.productName});
    }
  },[]);

  const onDialogClose = () => {
    setDialogOpen(false);
  }
  const onAppUsecaseDialogClose = () => {
    setAppUsecaseDialogOpen(false);
  }

  const onSubscribeApplicationClick = useCallback(() => {
    const publishedInfo = context?.product?.publishedInfo.filter((info) => {
      if(selectedApplication?.environment === info.gateway.toLocaleLowerCase()) {
        return true;
      }
    })[0];
    if(publishedInfo) {
      if(publishedInfo.approvalType === "MANUAL") {
       setAppUsecaseDialogOpen(true);
      } else {
        onSubscribe();
      }
    }    
  }, [context?.product, selectedApplication, businessContext]);


  const onAppUsecaseSubmitClick =  useCallback(() => {
    if(businessContext.current === ""){
      AlertService.error({
        message: "Please describe your use case(s) before submitting",
        isAutoHide: true,
        id: "APPUSECASE",
      });
    } else {
      onAppUsecaseDialogClose();
      onSubscribe();
    }
   },[selectedApplication]);

   
  const GetApplicationUsecase = () =>{
      return (
      
        <Dialog open={appUsecaseDialogOpen} title={`Thank you for your interest in API Product ${context?.product?.productName}`} onClose={onAppUsecaseDialogClose}>
            <Divider></Divider>
            <>
              <DialogContent>
              <Alert id="APPUSECASE" />
                <Typography type="body">
                  Please describe your use case(s) for our consideration as we review your request.
                </Typography>
                <br></br>
                {/* <FormControl>
                <TextInput
                  id="businessContext"
                  required = {true}     
                  rows={8}            
                  placeHolder = "Describe your use case(s)"
                  multiline
                  variant = "standard"
                  onChange={(event) => {
                    addedBusinessContext(event.target.value)
                  }}
                />
              </FormControl> */}
              
                <TextField
                  variant = "outlined"
                  autoFocus
                  margin="dense"
                  id="businessContext"
                  placeholder="Describe your use case(s)"
                  type="businessContext"
                  fullWidth
                  multiline
                  required = {true}     
                  rows={5}
                  onChange={(event) => {
                    businessContext.current = sanitize(event.target.value??"")
                  }}
              />
              </DialogContent>
              
              <DialogActions>
                <Button type="submit" variant="contained" color="primary" onClick={onAppUsecaseSubmitClick}>
                  Submit
                </Button>
              </DialogActions>
            </>
          </Dialog>
          
      )
  }
  const SubscriptionMethodSelector = () => {
    return (
      <Dialog open={dialogOpen} title={`Subscribe to ${context?.product?.productName}`} onClose={onDialogClose}>
          <Divider></Divider>
          <>
            <DialogContent>
              <Typography type="body">
                Please select one:
              </Typography>
              <br></br>
              <RadioGroup name="selected flow" >
                <FormControlLabel
                  label="Use Existing Application"
                  value="Use Existing Application"
                  onChange={() => {
                    selectedSubscriptionFlow.current = "existing";
                  }}
                  control={<Radio color="primary" size="small" />}
                />
                <Typography type="body">Choose from an existing list of User and Service Applications</Typography>
                <br />
                <FormControlLabel
                  label="Create New Application &amp; Subscribe"
                  value="Create New Application"
                  onChange={() => {
                    selectedSubscriptionFlow.current = "new";
                  }}
                  control={<Radio color="primary" size="small" />}
                />
                <Typography type="body">Create a new User or Service Application</Typography>
              </RadioGroup>
            </DialogContent>
  
            <DialogActions>
              <Button variant="contained" color="primary" onClick={onNextClick}>
                Next
              </Button>
            </DialogActions>
          </>
        </Dialog>
    )
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={onSubscribeClick} startIcon={<AddIcon />}>Add Subscription</Button>
      <SubscriptionMethodSelector/>
      <GetApplicationUsecase />
      <Slide direction="left" in={showSubscriber} mountOnEnter unmountOnExit>
        <Paper className={classes.root}>
          <GenericPageTitle
            title="Select an existing application"
            controls={<Controls />}
            className="m-b3"
          />
          <Alert id="SUBSCRIBER" />
          <RadioGroup name="selected-application" onChange={() => {setEnableSubscriber(true)}}>
            {context?.product && (
              <ApplicationList
                product={context.product}
                onApplicationSelected={onApplicationSelected}
              />
            )}
          </RadioGroup>
          <br />
          <AlertMUI severity="info">
                <b>Note:</b> Applications are environment-specific. That means when you subscribe, the subscription is limited to only the specific environment, Pre-Prod or Prod.
A single subscription will not provide you access to both Pre-Prod and Prod environments.
          </AlertMUI>
        </Paper>
      </Slide>
    </>
  );
};
Subscriber.propTypes = {

}

export default withRouter(connector(Subscriber));
