import { APIResource, APIMethod } from "libs/utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "libs/utils/http/client/HttpClient";
import { from } from "rxjs";
import { Approval } from "pages/home/approval/model/approval.model";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";

const formatError = (error: any, defaultMessage: string) => error?.response?.data?.code !== undefined && error.response.data.message ? error.response.data.message : defaultMessage;

export interface Division {
    name: string;
    baCode: number;
    divisionGroup: string;
    divisionGroupCode : number
}
@APIResource(CloudConsoleClient, "/publisher/division")
class DivisionServiceFactory {
    @APIMethod()
    public getDivisions() {
        return from(CloudConsoleClient.get<Division[]>(apiMethodContext(arguments), {

        }).then(value => value.data));
    }
}
const DivisionService = new DivisionServiceFactory();

export default DivisionService;
