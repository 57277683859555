import React, { FC, useContext, useState, ReactElement } from 'react'
import FormContainer from '../../../../legacy-components/form/container/FormContainer';
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import { FormRenderProps, Field } from 'react-final-form';
import { Button, InputAdornment, makeStyles, createStyles } from '@material-ui/core';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { FormApi, FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProxyUndeployFormData } from '../models/proxy.model';
import FormTextInput from '../../../../legacy-components/form/input/FormTextInput';
import { ValidatorBuilderContext } from '../../../../legacy-components/form/validator/Validator';
import ProxyService from '../../../../libs/resources/proxy/ProxyService';
import PropTypes from 'prop-types';
import { StatusBadge, StatusBadgeInfo } from '../../../../legacy-components/badges/StatusBadge';
import { WarnButton } from '../../../../legacy-components/form/action/WarnButton';
import { useHistory } from 'react-router';

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            padding: spacing(3)
        },
    }),
);

interface ProxyUndeployFormProps {
    onCancel: () => void,
    onSuccess: (data: ProxyUndeployFormData) => void
    proxyName: string,
    env: any,
    formContent?: ReactElement
}

const ProxyUndeployForm: FC<ProxyUndeployFormProps> = ({ onCancel, onSuccess, proxyName, env, formContent }) => {
    const intl = useIntl();
    const styles = useStyles();
    const history = useHistory();

    const onSubmit = () => (data: ProxyUndeployFormData, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                if (data.proxyName === proxyName) {

                    ProxyService.environments[env]?.undeploy(proxyName)
                        .then(
                            (data: any) => {
                                //history.go(0);
                                resolve()
                                onSuccess && onSuccess(data)
                            },
                            (error: any) => resolve({
                                [FORM_ERROR]: error
                            }));
                } else {
                    resolve({
                        proxyName: intl.formatMessage({
                            defaultMessage: "Proxy name didn't match"
                        })
                    })
                }
            }
        })
    }


    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        const validator = useContext(ValidatorBuilderContext);
        const [deploymentStatus] = useState<StatusBadgeInfo>({
            kind: "access",
            key: env,
            access: false
        });

        return (
            <div className={styles.root}>
                {formContent}

                <FormInputContainer>
                    <Field
                        name="proxyName"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Proxy Name"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "Type the proxy name"
                        })}
                        validate={validator.from({
                            required: true
                        })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <StatusBadge status={deploymentStatus} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormInputContainer>

                <ActionInliner padding="inset-top">
                    <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                    <WarnButton disabled={formRenderProps.submitting || formRenderProps.pristine} type="submit" variant="contained" color="primary" disableElevation>
                        <FormattedMessage defaultMessage="Undeploy" />
                    </WarnButton>
                </ActionInliner>
            </div>);
    }

    return <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

ProxyUndeployForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    proxyName: PropTypes.string.isRequired,
    env: PropTypes.string.isRequired
}

export default ProxyUndeployForm;