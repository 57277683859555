import { createStyles, makeStyles } from "@material-ui/core/styles";
import type { Theme } from "@material-ui/core/styles";
import { TrimbleColors } from "legacy-components/TrimbleColors";

const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      display: "flex",
      flexDirection: "column",
      wordWrap: "break-word",
      backgroundClip: "border-box",
      border: `1px solid ${TrimbleColors.White}`,
      borderRadius: "2px",
      margin: "15px",
      marginLeft: "0",
      boxShadow: "none",

      "&:hover": {
        borderColor: TrimbleColors.Brand.BlueLight,
        backgroundColor: `${TrimbleColors.White}`,
        boxShadow: `0px 0px 8px rgba(174, 174, 182, 0.4)`,
      },
    },
    shadow: {
      boxShadow: `0 0 4px rgba(0,95,158,.3)!important`,
    },
    cardBackground: {
      backgroundColor: TrimbleColors.White,
    },
    media: {
      minHeight: "150px",
    },
    header: {
      "& .MuiCardHeader-avatar": {
        fill: TrimbleColors.BlueDark,
      },
    },
    content: {
      minHeight: "73px",
      padding: "0 !important",
      margin: "16px",
      marginTop: "0",
    },
    actions: {
      borderTop: `1px solid ${TrimbleColors.Gray1}`,
    },
    cardsContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    },
    cardMediaSkeleton: {
      height: "300px",
    },
    title: {
      wordWrap: "break-word",
      maxWidth: "200px"
    },
    subTitle: {
      textOverflow: "ellipsis",
      overflowWrap: "initial",
    },
  })
);

export default useCardStyles;
