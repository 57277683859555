import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProxyCard from "../../../legacy-components/proxyCard";
import UnifiedAPIService, {
  UnifiedAPI,
} from "../../../services/UnifiedAPIService";
import { ResourceAbility } from "libs/security/authorization";
import ProxyTab from "./proxyTab";
import AddIcon from "@material-ui/icons/Add";
import { Button, Dialog, Snackbar } from "@material-ui/core";

import { FormattedMessage } from "react-intl";
import { ProxyCreateContent } from "../products/product-forms/ProductProxyDialog";
import Grow from "@material-ui/core/Grow";
import MuiAlert from "@material-ui/lab/Alert";

/*
const transitionDuration = {
  enter: theme.transitions.duration.enteringScreen,
  exit: theme.transitions.duration.leavingScreen,
};*/

/**
 * @class ProxyList
 * @description for listing all Team specific APIs
 */
class ProxyList extends React.PureComponent<any, any> {
  /**
   * @function constructor
   * @param {*} props - Having properties passed by parent element
   *  * used for state initialization
   */
  constructor(props: any) {
    super(props);
    this.state = {
      isTeamApiLoaded: false,
      isSharedApiLoaded: false,
      teamapis: null,
      sharedapis: null,
      breadcrumb: { title: "API", link: "/home/proxy" },
      message: null,
      sharedApiMessage: null,
      apiMessage: "",
      apiMessageType: "",
    };
  }
  componentDidMount() {
    this.loadTeamAPIs();
    this.loadSharedAPIs();
  }

  /**
   * @function loadAllAPIs
   * @description to call Unified API Service for storing all API Proxy details
   */
  loadTeamAPIs = () => {
    let apiCall = UnifiedAPI.list_team_apis;

    let request = {};
    UnifiedAPIService.request({
      url: UnifiedAPI.parseRequestURL(apiCall, request),
      method: "GET",
    })
      .then((response) => {
        this.setState({ teamapis: response.data });
        this.setState({ isTeamApiLoaded: true });
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 403) {
          this.setState({
            message:
              "You do not have permission to access this feature currently. Please contact your Team owner.",
          });
        }
        this.setState({ isTeamApiLoaded: true });
      });
  };

  /**
   * @function loadAllAPIs
   * @description to call Unified API Service for storing all API Proxy details
   */
  loadSharedAPIs = () => {
    let sharedapiCall = UnifiedAPI.list_shared_apis;
    let request = {};
    UnifiedAPIService.request({
      url: UnifiedAPI.parseRequestURL(sharedapiCall, request),
      method: "GET",
    })
      .then((response) => {
        this.setState({ sharedapis: response.data });
        this.setState({ isSharedApiLoaded: true });
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 403) {
          this.setState({
            sharedApiMessage:
              "You do not have permission to access this feature currently. Please contact your Team owner.",
          });
        }
        this.setState({ isSharedApiLoaded: true });
      });
  };

  showProxyCreationPage = () => {
    this.setState({ isShowProxyCreate: true });
  };
  showSuccessMessage = (response: any) => {
    const api = response?.apiName || response?.proxyName;
    if (api) {
      this.props.history.push("/home/proxy/" + api);
    }
  };

  onSnackBarClose = () => {
    this.setState({ apiMessage: "", apiMessageType: "" });
  };

  /**
   * @function render
   * @description repaints the component on each change of state and props value
   */
  render() {
    let teamproxies;
    let sharedproxies;
    let header;
    if (this.state.isTeamApiLoaded) {
      if (this.state.message) {
        teamproxies = <div className="message info">{this.state.message}</div>;
      } else {
        header = (
          <h1>
            <ResourceAbility
              can="create"
              resource={({ Proxy }) => Proxy.create}
            >
              <Button
                startIcon={<AddIcon />}
                onClick={this.showProxyCreationPage}
                variant="contained"
                color="primary"
              >
                <FormattedMessage defaultMessage="Add Proxy" />
              </Button>
            </ResourceAbility>
          </h1>
        );
        if (this.state.teamapis !== null && this.state.teamapis.length > 0) {
          teamproxies = [];
          this.state.teamapis.forEach((proxy: any, index: number) => {
            teamproxies.push(
              <ProxyCard
                proxy={proxy}
                key={proxy.apiId + "-" + index}
                isTeamProxy={true}
                onUpdate={this.loadTeamAPIs}
              />
            );
          });
        } else {
          teamproxies = (
            <div className="message info">
              <FormattedMessage defaultMessage="No APIs created yet. Click Add API to get started." />
            </div>
          );
        }
      }
    } else {
      teamproxies = null;
    }

    if (this.state.isSharedApiLoaded) {
      if (this.state.sharedApiMessage) {
        sharedproxies = (
          <div className="message info">{this.state.sharedApiMessage}</div>
        );
      } else {
        if (
          this.state.sharedapis !== null &&
          this.state.sharedapis.length > 0
        ) {
          sharedproxies = [];
          this.state.sharedapis.forEach((proxy: any, index: number) => {
            sharedproxies.push(
              <ProxyCard
                proxy={proxy}
                key={proxy.apiId + "-" + index}
                onUpdate={this.loadSharedAPIs}
              />
            );
          });
        } else {
          sharedproxies = (
            <div className="message info">
              No shared APIs available at the moment.
            </div>
          );
        }
      }
    } else {
      sharedproxies = null;
    }

    return (
      <>
        <Dialog
          fullWidth={true}
          open={this.state.isShowProxyCreate ? true : false}
          onClose={() => {
            this.setState({ isShowProxyCreate: false });
          }}
        >
          <ProxyCreateContent
            onClose={() => {
              this.setState({ isShowProxyCreate: false });
            }}
            onSuccess={this.showSuccessMessage}
          />
        </Dialog>
        <ResourceAbility can="read" resource={({ Proxy }) => Proxy.list}>
          <ProxyTab
            teamProxies={teamproxies}
            sharedProxies={sharedproxies}
            header={header}
          />
        </ResourceAbility>
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={this.state.apiMessage !== "" ? true : false}
          //disabled={(this.state.message !== "")? false: true}
          onClose={this.onSnackBarClose}
          TransitionComponent={(props) => {
            return <Grow {...props} />;
          }}
          message={this.state.apiMessage}
          autoHideDuration={6000}
          className="snackbar"
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={this.state.apiMessageType}
          >
            {this.state.apiMessage}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state
 */
const mapStateToProps = (state: any) => {
  return {
    isGrid: state.common.isGrid,
    features: state.common.features,
  };
};
export default withRouter(connect(mapStateToProps)(ProxyList));
