import React, { FC, useContext, useState } from "react";
import { FormRenderProps, Field } from "react-final-form";
import {
  Button,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { FormApi, FORM_ERROR } from "final-form";
import { FormattedMessage, useIntl } from "react-intl";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import FormContainer from "legacy-components/form/container/FormContainer";
import { ValidatorBuilderContext } from "legacy-components/form/validator/Validator";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import FormTextInput from "legacy-components/form/input/FormTextInput";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import FormAlert from "legacy-components/form/container/FormAlert";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      padding: spacing(3),
    },
  })
);

interface ResourceScopeFormProps {
  onCancel?: () => void;
  onSuccess?: (data: any) => void;
  prefillValues?: any;
  initialValues?: any;
  action: string;
  apiName: string;
  gatewayName: string;
  resourceName?: string;
  isInitialScope?: boolean;
}

const ResourceScopeForm: FC<ResourceScopeFormProps> = ({
  onCancel,
  onSuccess,
  action,
  prefillValues,
  initialValues,
  apiName,
  gatewayName,
  resourceName,
  isInitialScope,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const [dataFields] = useState(["__formResponse"]);

  const [message, setMessage] = useState<any>(null);

  const onSubmit = () => (data: any, form: FormApi) => {
    return new Promise((resolve) => {
      const formState = form.getState();

      if (formState.pristine) {
        resolve(null);
      } else if (isInitialScope) {
        UnifiedAPIService.post(
          UnifiedAPI.parseRequestURL(UnifiedAPI.add_resource_scopes, {
            apiName: apiName,
            gatewayName: gatewayName,
            resourceServerName: resourceName,
          }),
          {
            scope: data.scopeName,
            description: data.description,
          }
        ).then(
          (response) => {
            setMessage({
              severity: "success",
              detail: "Given scope is added",
            });
            setTimeout(() => {
              onSuccess &&
                onSuccess({
                  scope: data.scopeName,
                  description: data.description,
                });
            }, 3000);
            resolve(null);
          },
          (error) => {
            setMessage({
              severity: "error",
              detail:
                error.response?.data?.message ??
                "Unknown error occured while create the scope",
            });
            resolve({
              [FORM_ERROR]:
                error.response?.data?.message ??
                "Unknown error occured while create the scope",
            });
          }
        );
      } else {
        UnifiedAPIService.put(
          UnifiedAPI.parseRequestURL(UnifiedAPI.add_resource_scopes, {
            apiName: apiName,
            gatewayName: gatewayName,
            resourceServerName: resourceName,
          }),
          {
            scope: data.scopeName,
            description: data.description,
          }
        ).then(
          (response) => {
            setMessage({
              severity: "success",
              detail: "Given scope is added",
            });
            setTimeout(() => {
              onSuccess &&
                onSuccess({
                  scope: data.scopeName,
                  description: data.description,
                });
            }, 3000);

            resolve(null);
          },
          (error) => {
            setMessage({
              severity: "error",
              detail:
                error.response?.data?.message ??
                "Unknown error occured while create the scope",
            });
            resolve({
              [FORM_ERROR]:
                error.response?.data?.message ??
                "Unknown error occured while create the scope",
            });
          }
        );
      }
    });
  };

  const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
    const validator = useContext(ValidatorBuilderContext);

    return (
      <>
        {message && (
          <FormAlert severity={message.severity}>
            <div>{message.detail}</div>
          </FormAlert>
        )}
        <div className={styles.root}>
          <div>
            <h4>Guidelines</h4>
            <ul>
              <li>
                Scope Name should be in the format of{" "}
                <i>
                  RESOURCE_NAME<b>.</b>ACTION
                </i>
              </li>
            </ul>
          </div>
          <FormInputContainer>
            <Field
              name="scopeName"
              type="text"
              component={FormTextInput}
              label={intl.formatMessage({
                defaultMessage: "Scope Name",
              })}
              placeholder={intl.formatMessage({
                defaultMessage: "Scope Name",
              })}
              validate={validator.from({
                required: true,
              })}
            />
          </FormInputContainer>
          <FormInputContainer>
            <Field
              name="description"
              type="text"
              multiline
              rows={2}
              component={FormTextInput}
              label={intl.formatMessage({
                defaultMessage: "Description",
              })}
              placeholder={intl.formatMessage({
                defaultMessage: "Description",
              })}
              validate={validator.from({
                maxLength: 500,
              })}
            />
          </FormInputContainer>

          <ActionInliner padding="inset-top">
            <Button
              onClick={onCancel}
              disabled={formRenderProps.submitting}
              type="button"
              variant="outlined"
              disableElevation
            >
              <FormattedMessage defaultMessage="Cancel" />
            </Button>

            {action === "CREATE" && (
              <Button
                disabled={
                  formRenderProps.submitting || formRenderProps.pristine
                }
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
              >
                <FormattedMessage defaultMessage="ADD SCOPE" />
              </Button>
            )}
            {action === "EDIT" && (
              <Button
                disabled={
                  formRenderProps.submitting || formRenderProps.pristine
                }
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
              >
                <FormattedMessage defaultMessage="Change" />
              </Button>
            )}
          </ActionInliner>
        </div>
      </>
    );
  };

  return (
    <FormContainer
      prefillValues={prefillValues}
      dataFields={dataFields}
      FormProps={{ onSubmit, initialValues }}
      children={FormComponents}
    />
  );
};

export default ResourceScopeForm;
