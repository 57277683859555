import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { loadUser } from "redux-oidc";
import authenticationManager from "../utils/authenticationManager";
import { routerMiddleware, browserHistory } from "react-router-redux";
import { composeWithDevTools } from 'redux-devtools-extension';

import { syncHistoryWithStore } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { loadState } from "./sessionStorage";
import ReactGA from 'react-ga';

/**
 * Logger Middleware - To log the set of Actions and Payload
 * @param {Object} store 
 */
const loggerMiddleware = store => next => action => {
     /** Enable the below code for debugging */
     if (action) {
          next(action);
     }
};


/**
 * Dev tools Initialization for Local testing 
 * Logger 
 * Updating Browser History in store
 */

const persistedState = loadState();

const createStoreWithMiddleware = compose(
     composeWithDevTools(applyMiddleware(loggerMiddleware, routerMiddleware(browserHistory)))
)(createStore);

const store = createStoreWithMiddleware(rootReducer, persistedState);

loadUser(store, authenticationManager);

const browseHistory = createBrowserHistory();

 browseHistory.listen(location => {
     if(!process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
          return;
     }
     let gaParam = { page: location.pathname };

     if(store.getState().auth?.profile?.email) {
          gaParam['user'] = store.getState().auth?.profile?.email
     }
     ReactGA.set(gaParam); // Update the user's current page
     ReactGA.pageview(location.pathname); // Record a pageview for the given page
   });
export const history = syncHistoryWithStore(browseHistory, store);

export default store;