import React, { FC, useState, useContext } from "react";
import { FormRenderProps, Field } from "react-final-form";
import { Button } from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { ProxyVerificationApiQualityInfo } from "../models/verification.model";
import { AccordionContent } from "legacy-components/form/components/accordion-form/AccordionForm";
import FormContainer from "../../../../../../legacy-components/form/container/FormContainer";
import FormInputContainer from "../../../../../../legacy-components/form/container/FormInputContainer";
import { ValidatorBuilderContext } from "../../../../../../legacy-components/form/validator/Validator";
import ActionInliner from "../../../../../../legacy-components/form/action/ActionInliner";
import FormTextInput from "../../../../../../legacy-components/form/input/FormTextInput";
import InlineIconFormatter from "legacy-components/formatters/InlineIconFormatter";
import Helper from "pages/home/helper/Helper";
import JustificationHelper from "pages/home/helper/JustificationHelper";
import { FormApi, FORM_ERROR } from "final-form";
import { SnackNotificationContext } from "../../../../../../legacy-components/notifications/GenericSnackNotification";
import FormSelectionGroup from "legacy-components/form/input/FormSelectionGroup";
import ProxyVerificationService from "../ProxyVerificationService";

interface ApiQualityFormProps {
  onCancel?: () => void;
  onSuccess?: (
    proxyVerificationApiQualityInfo?: ProxyVerificationApiQualityInfo
  ) => void;
  proxyVerificationApiQualityInfo?: ProxyVerificationApiQualityInfo;
  proxy: string;
}

const NotificationMessage = () => {
  return <FormattedMessage defaultMessage="API Quality Saved Successfully" />;
};

const ApiQualityForm: FC<ApiQualityFormProps> = ({
  proxy,
  proxyVerificationApiQualityInfo,
  onSuccess,
}) => {
  const notificationContext = useContext(SnackNotificationContext);
  const onSubmit =
    () => (data: ProxyVerificationApiQualityInfo, form: FormApi) => {
      return new Promise((resolve) => {
        const formState = form.getState();
        if (formState.pristine) {
          resolve();
        } else {
          const apiQualityInfo: ProxyVerificationApiQualityInfo = {
            apiQuality: data.apiQuality,
            additionalInput: data.additionalInput,
          };
          ProxyVerificationService.forApiQuality(
            proxy,
            apiQualityInfo
          ).subscribe(
            () => {
              notificationContext.show({
                id: "api-quality",
                message: <NotificationMessage />,
              });
              resolve();
            },
            (error) => {
              resolve({
                [FORM_ERROR]: error,
              });
            }
          );
        }
      });
    };
  const intl = useIntl();
  const validator = useContext(ValidatorBuilderContext);
  const [apiQualities] = useState([
    {
      value: "ENTERPRISE",
      title: intl.formatMessage({
        defaultMessage: "Enterprise",
      }),
      helperText: intl.formatMessage({
        defaultMessage:
          "Your API Proxy is ready for the big show. It’s a priority for your team and you are working to offer the highest quality, the peak level of security and reliability, and optimal support.",
      }),
    },
    {
      value: "PROFESSIONAL",
      title: intl.formatMessage({
        defaultMessage: "Professional",
      }),
      helperText: intl.formatMessage({
        defaultMessage:
          "Your API Proxy is mature. Your team is working to meet the needs of your consumers with professionalism and overcoming challenges as they appear.",
      }),
    },
    {
      value: "GREENFIELD",
      title: intl.formatMessage({
        defaultMessage: "Greenfield",
      }),
      helperText: intl.formatMessage({
        defaultMessage:
          "Your API Proxy is up and coming. It’s in a Beta or exploratory stage and your team is looking for opportunities for collaboration.",
      }),
    },
  ]);
  const [isShowJustificationHelper, toggleJustificationHelper] =
    useState(false);
  const FormComponents = (
    formRenderProps: FormRenderProps<ProxyVerificationApiQualityInfo, {}>
  ) => {
    return (
      <>
        <FormInputContainer
          title={
            <Typography
              variant="subtitle2"
              children={
                <FormattedMessage defaultMessage="Please identify the maturity of your API Proxy as you make it accessible for other Trimble developers to use. *" />
              }
            />
          }
        >
          <Field
            name="apiQuality"
            children={FormSelectionGroup}
            options={apiQualities}
            validate={validator.from({
              required: true,
            })}
            helperText="api quality"
          />
        </FormInputContainer>

        <FormInputContainer
          title={
            <Typography
              variant="subtitle2"
              children={<FormattedMessage defaultMessage="Additional input" />}
            />
          }
        >
          <Field
            name="additionalInput"
            type="text"
            multiline
            rows={5}
            component={FormTextInput}
            placeholder={intl.formatMessage({
              defaultMessage: "Elaborate on your selections above",
            })}
            validate={validator.from({
              maxLength: 400
            })}
          />
        </FormInputContainer>

        <ActionInliner>
          <Button
            disabled={formRenderProps.submitting || formRenderProps.pristine}
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
          >
            {" "}
            <FormattedMessage defaultMessage="Save" />
          </Button>
        </ActionInliner>
      </>
    );
  };
  return (
    <AccordionContent
      size="small"
      header={
        <div>
          <InlineIconFormatter
            icon={
              <Typography
                variant="caption"
                color="error"
                children={
                  <FormattedMessage defaultMessage="* required fields" />
                }
              />
            }
          >
            <FormattedMessage defaultMessage="API Quality" />
          </InlineIconFormatter>

          <Helper
            open={isShowJustificationHelper}
            handleClose={() => {
              toggleJustificationHelper(false);
            }}
            children={<JustificationHelper />}
          ></Helper>
        </div>
      }
    >
      <FormContainer
        previewErrors={true}
        FormProps={{ onSubmit }}
        onSubmitSuccess={onSuccess}
        children={FormComponents}
        prefillValues={proxyVerificationApiQualityInfo}
      />
    </AccordionContent>
  );
};

ApiQualityForm.propTypes = {
  proxy: PropTypes.string.isRequired,
};

export default ApiQualityForm;
