import { Field } from "react-final-form";
import React, { memo, FC, PropsWithChildren } from "react";

interface FormConditionalFieldProps {
    observe: string;
    condition: (value: any) => boolean
}

const FormConditionalField: FC<PropsWithChildren<FormConditionalFieldProps>> = ({ observe, condition, children }) => (
    <Field name={observe} subscription={{ value: true }}>
        {({ input: { value } }) => (condition(value) ? children : null)}
    </Field>
);

export default memo(FormConditionalField);