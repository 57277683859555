import React, { FC, PropsWithChildren, memo, ReactElement, ReactNode } from 'react'
import { Typography, makeStyles, createStyles } from '@material-ui/core';
import ActionInliner from '../form/action/ActionInliner';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';

const useStyles = makeStyles(({ spacing, palette }) =>
    createStyles({
        root: {
            width: '100%',
            padding: spacing(1, 3, 2),
            marginBottom: spacing(2),
        },
        section: {
            width: '100%',
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center"
        },
        header: {
            paddingBottom: spacing(1),
            color: palette.text.secondary
        },
        title: {
            display: "flex",
            alignContent: "center",
            textTransform: "capitalize"
        },
        badge: {
            marginLeft: spacing(2)
        },
        insetHeader: {
            paddingTop: spacing(2),
        }
    }),
);

interface PageSectionTitleProps {

}

interface PageHeaderTitleProps {

}

interface GenericPageHeaderProps {
    header?: ReactElement;
    title?: ReactNode;
    controls?: ReactElement;
    badge?: ReactElement | null;
}

export const GenericPageHeaderSkeleton = () => {
    const PageHeaderControls = () => {
        return (
            <ActionInliner>
                <Skeleton variant="circle" width={32} height={32} />
                <Skeleton variant="circle" width={32} height={32} />
            </ActionInliner>
        );
    }
    return (
        <GenericPageHeader controls={<PageHeaderControls />} header={<PageHeaderTitle children={<Skeleton width={120} />} />} title={<PageSectionTitle children={<Skeleton width={200} />} />} />
    )
}

export const PageSectionTitle: FC<PropsWithChildren<PageSectionTitleProps>> = memo(({ children }) => {
    return (
        <div>
            <Typography variant="h4" component="div" className="no-overflow">
                {children}
            </Typography>
        </div>
    )
});


export const PageHeaderTitle: FC<PropsWithChildren<PageHeaderTitleProps>> = memo(({ children }) => {
    return (
        <div>
            <Typography variant="body1"  component="div">
                {children}
            </Typography>
        </div>
    )
});

function GenericPageHeader({ title, controls, header, badge }: GenericPageHeaderProps) {
    const styles = useStyles();

    return (
        <div className={clsx(styles.root, {
            [styles.insetHeader]: !header
        })}>
            {
                header && <div className={styles.header}>
                    <PageHeaderTitle>
                        {header}
                    </PageHeaderTitle>
                </div>
            }
            <div className={styles.section}>
                <div className={styles.title}>
                    <div>
                        <PageSectionTitle>
                            {title}
                        </PageSectionTitle>
                    </div>
                    <div className={styles.badge}>
                        {badge}
                    </div>
                </div>
                <div>
                    {controls}
                </div>
            </div>
        </div>
    )
}

GenericPageHeader.propTypes = {

}

export default memo(GenericPageHeader);

