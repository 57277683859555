import React, { FC, useContext } from 'react'
import FormContainer from '../../../../legacy-components/form/container/FormContainer';
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import { FormRenderProps, Field } from 'react-final-form';
import { Button, InputAdornment } from '@material-ui/core';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { FormApi, FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import FormTextInput from '../../../../legacy-components/form/input/FormTextInput';
import { ValidatorBuilderContext } from '../../../../legacy-components/form/validator/Validator';
import UnifiedAPIService, { UnifiedAPI } from '../../../../services/UnifiedAPIService';
import PropTypes from 'prop-types';
import { useState } from 'react';


export interface ShareApiFormData {
    emailAddress?: string;
    role?: string;

}

interface ShareApiFormProps {
    onCancel: () => void,
    onSuccess: (data:any, type?: string) => void,
    onError: (data?:any, type?: string) => void,
    proxyName: string
}

const ProxyShareForm: FC<ShareApiFormProps> = ({ proxyName, onCancel, onSuccess, onError }) => {
    const intl = useIntl();

    const [options] = useState([
        {
            key: "VIEWER",
            value: intl.formatMessage({
                defaultMessage: "Can view"
            })
        },
        {
            key: "EDITOR",
            value: intl.formatMessage({
                defaultMessage: "Can edit"
            })
        }
    ]);

    const onSubmit = () => (data: ShareApiFormData, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                UnifiedAPIService.post(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.share_api, {
                        apiName: proxyName,
                        emailAddress: data.emailAddress,
                        role: data.role
                    }),
                    {

                    }
                ).then((response) => {
                    response.data = {
                        message : "Assigned " + data.role + " access to "+ data.emailAddress
                    }
                    onSuccess(response, "success");
                    resolve()
                }, error => {
                    onError(error?.response, "error");
                    resolve({
                        [FORM_ERROR]: error.response?.data?.message ?? "Unknown error occured while sharing the proxy"
                    });
                });
            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        const validator = useContext(ValidatorBuilderContext);
        return (<>
            <FormInputContainer>
                <Field
                    name="emailAddress"
                    type="text"
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Email"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Email"
                    })}
                    validate={validator.from({
                        required: true,
                        minLength: 3,
                        pattern: ({ GenericEmail }) => GenericEmail
                    })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Field
                                    name="role"
                                    type="text"
                                    defaultValue={options[0].key}
                                    component={FormTextInput}
                                    placeholder={intl.formatMessage({
                                        defaultMessage: "Role"
                                    })}
                                    validate={validator.from({
                                        required: true
                                    })}
                                    options={options}
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            </FormInputContainer>

            {/* <FormInputContainer>
                <Field
                    name="role"
                    type="text"
                    defaultValue="Viewer"
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Role"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Role"
                    })}
                    validate={validator.from({
                        required: true
                    })}
                    options={options}
                />
            </FormInputContainer> */}

            <ActionInliner>
                <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Share" /></Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

ProxyShareForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

export default ProxyShareForm;