import { Box, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CheckCircleRounded from "@material-ui/icons/CheckCircleRounded";
import { NavLink } from "react-router-dom";


export const SubscriptionNavigation: React.FC = () => {
    const { push } =  useHistory();
    return (
        <>
        <NavLink
                className="navigation-tab"
                key={"subscriptions"}
                activeClassName="active"
                to={'/home/subscriptions'}
            >
            <ListItem
            button
            onClick={() => push("/home/subscriptions")}
            >
            <ListItemIcon>
                <CheckCircleRounded style={{ color: "#fff" }} />,
            </ListItemIcon>
            <ListItemText
                className="sub-menu-text"
                primary={<Box display="flex"><Box flexGrow={1}>Subscriptions</Box>
                        </Box>}
            />
            </ListItem>
            </NavLink>
        </>
        
    )
}
