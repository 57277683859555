import React, { FC, useState, useContext } from 'react'
import { FormRenderProps, Field } from 'react-final-form';
import { Button, Typography } from '@material-ui/core';
import { FormattedMessage } from "react-intl";
import { ApiFeedback, ApprovalAction } from '../../model/approval.model';
import FormContainer from "legacy-components/form/container/FormContainer";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import FormTextInput from "legacy-components/form/input/FormTextInput";
import ApprovalService from "../../ApprovalService";
import { FormApi, FORM_ERROR } from 'final-form';
import { SnackNotificationContext } from 'legacy-components/notifications/GenericSnackNotification';

interface PerfDocFormProps {
    onCancel?: () => void,
    onSuccess?: (apiFeedback: ApiFeedback) => void
    apiFeedback: ApiFeedback,
    canReview?: boolean,
    approvalDecisionInfo?: ApprovalAction
}

const PerfDocForm: FC<PerfDocFormProps> = ({ canReview, apiFeedback, approvalDecisionInfo, onSuccess }) => {
    const notificationContext = useContext(SnackNotificationContext);
    const NotificationMessage = () => {
        return (
            <FormattedMessage defaultMessage="Performance and Documentation feedback added Successfully" />
        )
    }
    const onSubmit = () => (data: any, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();
            if (formState.pristine) {
                resolve();
            } else {
                const requestId = apiFeedback?.requestId ? apiFeedback?.requestId : "";

                ApprovalService.forPerformanceDocumentationFeedback(requestId, data.feedback).subscribe(() => {
                    notificationContext.show({
                        id: "perf-feedback",
                        message: <NotificationMessage />
                    });
                    resolve();
                }, error => {
                    resolve({
                        [FORM_ERROR]: error
                    });
                });
            }

        })
    }

    const [] = useState(false);
    const FormComponents = (formRenderProps: FormRenderProps<ApiFeedback, {}>) => {
        return (
            <>
                <FormInputContainer title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Reviewer Feedback (optional)" />} />}>

                    <Field
                        name="feedback"
                        type="text"
                        multiline
                        rows={5}
                        component={FormTextInput}
                        disabled={approvalDecisionInfo?.reason ? true : false || !canReview}

                    />
                </FormInputContainer>

                {(canReview &&
                    <ActionInliner>
                        <Button disabled={(approvalDecisionInfo?.reason ? true : false) || formRenderProps.invalid || formRenderProps.submitting || formRenderProps.pristine} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Done" /></Button>
                    </ActionInliner>
                )}
            </>
        );
    }
    return (

        <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} prefillValues={apiFeedback} />

    )
}

export default PerfDocForm;