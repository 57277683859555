

import { Box } from "@material-ui/core";
import React from "react";

export const EnvStatus = {
    "global" : {
        value: "Global"
    },
    "eu-west" : {
        value: "Ireland"
    },
    "australia": {
        value: "Australia"
    },
    "asia-pacific": {   
        value: "Asia Pacific"
    },
    /** TODO: Added temporarily for validation. 
    * Need to be removed when Create flow is completed.
    * */
    "prod": {
        value: "Production"
    },
    "ap": {
        value: "South-east Asia(Singapore)"
    },
    "au": {
        value: "Australia East"
    }
};

export type EnvKeyType = keyof typeof EnvStatus;

interface EnvBadgeProps {
    env: EnvKeyType;
}

export const getResolvedEnv = (env: EnvKeyType) => {
    return  EnvStatus[env]?.value;
    
}
export const EnvBadge:React.FC<EnvBadgeProps> = ({env}) => {
    return (
        <Box style={{textTransform: 'capitalize' }}>
            {EnvStatus[env]?.value}
        </Box>
    );
}