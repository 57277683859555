import React, { FC, useCallback, useRef, useContext } from "react";
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTheme } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import GenericPageHeader from "../../../legacy-components/page-headers/GenericPageHeader";
import { useGridViewStyles } from "../../../legacy-components/grid-view/GridView";
import { CertificateGridRef } from "./CertificateGrid";
import CertificateUploadDialog, { CertificateUploadDialogRef } from './CertificateUploadDialog';
import { SnackNotificationContext } from "../../../legacy-components/notifications/GenericSnackNotification";
import SwipeableViews from "react-swipeable-views";
import { theme } from "themes/mui";
import CertificateExpiryGrid from "./CertificateExpiryGrid";
import { Alert } from "@material-ui/lab";

const mapState = () => ({

});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface CertificateExpiryListProps {

}



const NotificationMessage = () => {
    return (
        <FormattedMessage defaultMessage="Certificate Uploaded Successfully" />
    )
}


const CertificateExpiryList: FC<CertificateExpiryListProps & PropsFromRedux & RouteComponentProps> = () => {
    const gridViewStyles = useGridViewStyles();

    const certificateDialogRef = useRef<CertificateUploadDialogRef>();
    const certificateGridRef = useRef<CertificateGridRef>();


    const notificationContext = useContext(SnackNotificationContext);
    const onSuccess = useCallback(() => {
        notificationContext.show({
            id: "certificate-upload-success",
            message: <NotificationMessage />
        });

        certificateGridRef.current?.refreshStream();
    }, []);
    let gateways = useSelector((state: any) => state?.common?.metadata?.gateways);

    const [value, setValue] = React.useState(0);


    const handleChangeIndex = (index: number) => {
        setValue(index);
    };


    return (
        <div className={gridViewStyles.root}>
            <CertificateUploadDialog ref={certificateDialogRef} onSuccess={onSuccess} />
            <GenericPageHeader title={<FormattedMessage defaultMessage="Certificates Expiring/Expired" />} />
            <Alert severity="info">
            The following certificates are nearing expiry or already expired.  Go to API <strong>Management &gt; Certificates</strong> to manage your certificates. 
            </Alert>
            {(gateways) && (
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    {gateways && gateways.map(() => (
                       
                            <div className={gridViewStyles.content}>
                                <CertificateExpiryGrid ref={certificateGridRef} />
                            </div>
                       
                    ))
                    }

                </SwipeableViews>
            )}
        </div >
    );
}

export default withTheme(withRouter(connector(CertificateExpiryList)));