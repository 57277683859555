import React, { useState, useEffect } from "react";
import { IconButton, Tooltip, makeStyles, createStyles } from "@material-ui/core";

import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ApplicationService from "../../../service/ApplicationService";
import type { IApplicationKey } from "../../../service/ApplicationService";
import AlertService from "legacy-components/alert/AlertService";
import Alert from "legacy-components/alert/Alert";
import CopyToClipboard from "legacy-components/form/action/CopyToClipboard";
import Dialog, { useDialogProps } from "legacy-components/dialog/Dialog";
import { Field } from "react-final-form";
import TextInput from "legacy-components/form/container/TextInput";

export interface ApplicationKeysProps {
  applicationName: string;
  gatewayName: string;
}

export const ApplicationKeys: React.FC<ApplicationKeysProps> = ({
  applicationName,
  gatewayName,
}) => {
  const { open, toggle } = useDialogProps();
  const [keyDetail, setKeyDetail] = useState<IApplicationKey | null>(null);
  const useStyles = makeStyles((theme) =>
  createStyles({
       keys: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "30px",
        "&>*": {
          padding: "15px"
        }
       }
  })
);

const classes = useStyles();
  useEffect(() => {
    if (open) {
      void ApplicationService.getApplicationKeys(applicationName, gatewayName)
        .toPromise()
        .then(
          (keys) => {
            setKeyDetail(keys.data);
          },
          (error) => {
            AlertService.error({
              message: "Error in receiving Keys ",
              isAutoHide: false,
              id: "APPLICATION_KEYS",
            });
          }
        );
    }
  }, [applicationName, gatewayName, open]);

  return (
    <>
      <Tooltip title={"View Application Keys"}>
        <span>
        <IconButton onClick={toggle} size="small" data-testid={`${applicationName}-app-keys`}>
            <VpnKeyIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} title="View Application Keys" onClose={toggle}>
        <Alert id="APPLICATION_KEYS" />
        {keyDetail && (
          <div className={classes.keys}>
            
            <TextInput
                    id="appName"
                    disabled={true}
                    label= "Application Name"
                    value = {keyDetail?.applicationName}
                    endIcon={
                      <CopyToClipboard edge="end" text={keyDetail?.applicationName} />
                    }
                    
                />
                
            <TextInput
                    type="text"
                    disabled={true}
                    id="consumerKey"
                    label= "Consumer Key"
                    value={keyDetail?.consumerKey}
                    endIcon={
                      <CopyToClipboard edge="end" text={keyDetail?.consumerKey} />
                    }
                    
                />
           
            {keyDetail?.consumerSecret.map((secretKey, index) => (
              <TextInput
              key={index}
              id={`consumerSecret${index}`}
              type="text"
              disabled={true}
              label={`Consumer Secret ${
                keyDetail?.consumerSecret?.length > 1 ? index + 1 : ""
              }`}
              value={secretKey}
              endIcon={
                <CopyToClipboard edge="end" text={secretKey} />
              }
              
          />
    
            ))}
          </div>
        )}
      </Dialog>
    </>
  );
};

export default ApplicationKeys;
