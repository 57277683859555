import { createContext } from "react";

export interface StepperFormContentState<T = {}> {
    values: T,
    meta: {
        submitted?: boolean,
        submitting?: boolean
        error?: boolean,
        errorValue?: any,
        successMessage?: string
    }
}

class StepperFormContextState {
    private contentStateMap = new Map<string, StepperFormContentState>();

    constructor(private id: string) {

    }

    public factory = {
        contentState: {
            id: this.id,
            get: (key: string) => this.contentStateMap.get(key),
            set: (key: string, contentState: StepperFormContentState) => this.contentStateMap.set(key, contentState),
            entries: () => Array.from(this.contentStateMap.entries()).reduce((agg: {
                [key: string]: {}
            }, val) => {
                agg[val[0]] = val[1].values;
                return agg;
            }, {})
        }
    }
};


export const createStepperFormContext: (id: string) => StepperFormContextState = id => new StepperFormContextState(id)
export const StepperFormContext = createContext<StepperFormContextState | null>(null);

