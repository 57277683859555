import { FC, useContext } from "react";
import { FormRenderProps, Field } from "react-final-form";
import FormContainer from "legacy-components/form/container/FormContainer";
import { useIntl, FormattedMessage } from "react-intl";
import { ValidatorBuilderContext } from "legacy-components/form/validator/Validator";
import React from "react";
import { Button } from "@material-ui/core";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import FormTextInput from "legacy-components/form/input/FormTextInput";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import { ApprovalDelegateRequest } from "pages/home/approval/model/approval.model";
import { FormApi, FORM_ERROR } from 'final-form';
import { SnackNotificationContext } from 'legacy-components/notifications/GenericSnackNotification';
import ApprovalService from "../../ApprovalService";

interface DelegationFormProps {
    onSuccess?: () => void;
    onCancel?: () => void;
    delegators?: Array<{
        key?: string,
        value?: string
    }>,
    requestId?: string,
    delegateRequest?: ApprovalDelegateRequest,
    subscription: string,
    product?: string,
    application?: string
}

const DelegateMessage = (props: any) => {
    const message = `You have delegated the subscription request for ${props.product} to ${props.application}. They have been notified of the transfer.`
    return (
        <span>{message}</span>
    )
}

export const DelegationForm: FC<DelegationFormProps> = ({ subscription, product, application, requestId, delegators, onSuccess, onCancel }) => {
    const intl = useIntl();
    const notificationContext = useContext(SnackNotificationContext);
    const onSubmit = () => (data: any, form: FormApi) => {
        return new Promise((resolve) => {

            const formState = form.getState();
            if (formState.pristine) {
                resolve(null);
            } else {
                const deletegateRequest: ApprovalDelegateRequest = {
                    resourceName: subscription,
                    delegatorEmails: [data.delegatorEmails],
                    requestId: requestId
                }
                ApprovalService.forDelegateApproval("SUBSCRIPTION", deletegateRequest).subscribe(() => {
                    notificationContext.show({
                        id: "delegate-request",
                        message: <DelegateMessage product={product} application={application} name={data.delegatorEmails} />
                    });
                    resolve();
                }, error => {
                    resolve({
                        [FORM_ERROR]: error
                    });
                });
            }
        });
    }


    const FormComponents = (formRenderProps: FormRenderProps<ApprovalDelegateRequest, {}>) => {
        const validator = useContext(ValidatorBuilderContext);
        return (<>
            <FormInputContainer title={
                <FormattedMessage defaultMessage="Please select someone to delegate your request" />
            }>

                <Field
                    name="delegatorEmails"
                    type="text"
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Name, Email Address"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Name, Email Address"
                    })}
                    options={delegators}
                    validate={validator.from({
                        required: true
                    })}
                />
            </FormInputContainer>


            <ActionInliner>
                <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Send" /></Button>
            </ActionInliner>
        </>);
    }


    if (delegators?.length == 0) {
        return (
            <span>There is no user available to delegate at the moment.</span>
        )
    } else {

        return <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
    }
}
DelegationForm.propTypes = {
}

export default DelegationForm;