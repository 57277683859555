import React, { memo } from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import { FormattedMessage } from 'react-intl';
import { IAPIProxy } from '../../../../libs/resources/proxy/ProxyService';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import FeatureAbility, { Feature } from 'libs/security/authorization/FeatureAbility';

interface ProxyVerifiedViewProps {
    proxy: IAPIProxy
}
function ProxyVerifiedView({ proxy }: ProxyVerifiedViewProps) {
    let verified;
    if (proxy.apiName && proxy.isVerified === 1) {
        verified = (
            <>
                <Tooltip title={<FormattedMessage defaultMessage="Verified" />}>
                    <IconButton>
                        <VerifiedUserIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    } else {
        verified = (
            <>
                <Tooltip title={<FormattedMessage defaultMessage="Unverified" />}>
                    <IconButton>
                        <VerifiedUserOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    }
    return (
        <>
            <FeatureAbility id={Feature.ApiVerification}>
                {verified}
            </FeatureAbility>
        </>
    )
}

export default memo(ProxyVerifiedView);
