import { UrlParamsConfig, _getResourceConfig, _updateResourceConfig, _createResourceConfig } from "../builder/UrlConfig";
import { mapToArray } from "../../shared/common";
import { AxiosInstance } from "axios";

const __$apiMethod: "$__url__apiMethod" = "$__url__apiMethod";
export const __$urlRequestIdPrefixer = "resource::console::apicloud://";

export type HttpClientInstance = AxiosInstance & {
    __getName?: () => string;
    useToken?: (accessToken: string) => void;
    useTeam?: (team: string | undefined) => void;
    __customHeaders?: {
        [key: string]: string
    }
};

interface PropFunction extends Function {
    [__$apiMethod]?: {
        [key: string]: {
            requestId: string;
        }
    }
}

export function APIResource(client: HttpClientInstance, path?: string | string[], params?: UrlParamsConfig): ClassDecorator {
    return (target: PropFunction) => {
        const apiMethod = target[__$apiMethod];

        if (apiMethod) {
            Object.keys(apiMethod).forEach(key => {
                const requestId = target?.[__$apiMethod]?.[key].requestId;

                if (requestId) {
                    const config = _getResourceConfig(requestId);

                    if (config) {
                        config.resourceInfo = {
                            name: client.__getName?.() ?? "--"
                        };

                        config.methodInfo = config.methodInfo || {};

                        if (path) {
                            if (config.methodInfo.path) {
                                const methodPath = mapToArray(config.methodInfo.path);
                                const endpointPath = mapToArray(path);

                                config.methodInfo.path = config?.methodInfo?.params?.options?.absolute ? methodPath : endpointPath.concat(methodPath);
                            } else {
                                config.methodInfo.path = path;
                            }
                        }

                        config.methodInfo = {
                            ...config.methodInfo,
                            params: {
                                ...(params || {}),
                                ...(config.methodInfo.params || {}),
                            }
                        }
                        _updateResourceConfig(requestId, config);
                    }
                }
            });

        }
    };
}

export function APIMethod(path?: string | string[], params?: UrlParamsConfig) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        selectTargetPath(target, propertyKey, descriptor, {
            path,
            params
        });
    }
}

const selectTargetPath = (target: any, propertyKey: string, descriptor: PropertyDescriptor, value: {
    path?: string | string[],
    params?: Partial<UrlParamsConfig>
}) => {
    if (descriptor) {
        const isMethod = descriptor.value instanceof Function;

        if (isMethod) {
            const originalMethod: Function = descriptor.value;
            const requestId = _createResourceConfig({
                resourceInfo: {},
                methodInfo: {
                    path: value.path,
                    params: value.params,
                }
            })

            descriptor.value = function (...args: any[]) {
                args = args || [];

                args.push({
                    requestId: `${__$urlRequestIdPrefixer}${requestId}`
                });

                return originalMethod.apply(this, args);
            };

            Object.defineProperty(target, propertyKey, descriptor);

            if (target.constructor) {
                target.constructor[__$apiMethod] = target.constructor[__$apiMethod] || {};

                target.constructor[__$apiMethod][propertyKey] = {
                    propertyKey,
                    requestId
                }
            }
        }
    }
};