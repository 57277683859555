import { Box, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";
import ProxyService, { IAPIProxyDeployment } from "../services/ProxyService";
import React, { useEffect, useState } from "react";
import GenericPageTitle from "modus/components/generic-page-title/GenericPageTitle";
import { useSelector } from "react-redux";
import { DeploymentDetails } from "./DeploymentDetails";
import { getResolvedEnv } from "./EnvBadge";
import { ErrorMessage } from "modus/components/error-message/ErrorMessage";
import { Skeleton } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DeploymentCreator } from "../pages/application-summary-page/DeploymentCreator";

interface DeploymentListProps {
  version: string;
  applicationName: string;
  resourceAppId: string
}

export const DeploymentList:React.FC<DeploymentListProps> = ({version, applicationName, resourceAppId}) => {

  const { state, subscribe } = useAsyncAction(() =>
    ProxyService.getApiProxy(`${applicationName}-${version}`)
  );
  const [filteredDeployments, setFilteredDeployments] = useState<IAPIProxyDeployment[]>();
  const [selectedDeployment, setSelectedDeployment] =
    useState<IAPIProxyDeployment>();
  const identityEnvironment = useSelector(
    (state: any) => state?.common?.identityEnvironment
  );

  const [deployedRegions, setDeployedRegions] = useState<string[]>();

  const changeDeployment = (deployment: IAPIProxyDeployment) => {
    setSelectedDeployment(deployment);
  };

  useEffect(() => {
    if(!state.loading) {
      return;
    }
    setSelectedDeployment(undefined);
  },[state?.loading]);

  useEffect(() => {
    if(!applicationName || !version ) {
      return;
    }
    subscribe();
  }, [applicationName, version]);
  
  useEffect(() => {
    if(!state?.value) {
      return;
    }
    if(state?.value?.apiDeployments && state?.value?.apiDeployments?.length > 0) {
      const filteredApiDeployments = state?.value?.apiDeployments?.filter(
        (deployment: IAPIProxyDeployment) =>
          deployment?.organisation === identityEnvironment
      );
      if(filteredApiDeployments?.length > 0) {
        setFilteredDeployments(filteredApiDeployments);
        changeDeployment(filteredApiDeployments[0]);
        setDeployedRegions(
          filteredApiDeployments
            ?.map((deployment) => deployment?.deploymentLocation)
            .filter((location): location is string => location !== undefined)
        );  
      } else {
        setFilteredDeployments([]);
      }
      
    } else {
      setFilteredDeployments([]);
    }
  },[state?.value?.apiDeployments]);

  return (
    <Paper style={{width : "100%"}}>
      <Box padding={"20px"}>
        <GenericPageTitle
          title={<Typography variant="body1" style={{fontWeight: 600}}>Deployment</Typography>}
          noSkeleton
          controls={selectedDeployment?.deploymentLocation && 
            filteredDeployments && 
            filteredDeployments?.length > 0 ? (
              <Box
              display="flex"
              flexDirection="row"
              justifyContent={"space-between"}
              >
                <Select id="region" 
                    value={selectedDeployment?.deploymentLocation}
                    IconComponent={ExpandMoreIcon}
                    variant="outlined"
                    className="header-selector resource-version-select"
                >
                {filteredDeployments &&
                  filteredDeployments
                    ?.map((deployment: IAPIProxyDeployment, index: number) => (
                      <MenuItem
                        value={deployment.deploymentLocation}
                        key={index}
                        onClick={() => changeDeployment(deployment)}
                      >
                        {getResolvedEnv(deployment.deploymentLocation as any)}
                      </MenuItem>
                    ))}
              </Select>
              <Box ml="15px"/>

               {/** Create New deployment - component need to be added here */}
               <DeploymentCreator resourceAppName={applicationName} version={version} resourceAppId={resourceAppId} refreshDeploymentList={subscribe} deployedRegions={deployedRegions}/>

              </Box>
              
            ): undefined
          }
        />
        {selectedDeployment && filteredDeployments && (
          <DeploymentDetails
            timeout={selectedDeployment?.timeout}
            deployedEndpoint={selectedDeployment?.deployedUrl}
            deploymentStatus={selectedDeployment?.deployStatus}
            region={selectedDeployment?.deploymentLocation}
            targetEndpoint={selectedDeployment?.backendUrl}
          />
        )}
        {
          state?.value && filteredDeployments?.length === 0 && (
            <ErrorMessage message={
              <Box display="flex" flexDirection="column">
                Add a deployment 
                <Box mt={1} />
                {/** Create New deployment - component need to be added here */}
                <DeploymentCreator resourceAppName={applicationName} version={version} resourceAppId={resourceAppId} refreshDeploymentList={subscribe} deployedRegions={deployedRegions}/>

                </Box>
            } />
          )
        }
        {state?.loading && (
            <Skeleton animation="wave" width="100%" height="200px" />
        )}
      </Box>
    </Paper>
  );
};