import { useSelector } from "react-redux";
import { IEvent } from "../../pages/subscription-summary-page/SubscriptionSummaryPage";
import { IDENTITY_ENVIRONMENT_TYPE } from "header/environment-selector/ENV_CONSTANTS";
import { useEffect, useState } from "react";
import { TransformedDeployment } from "pages/home/products/components/ProductServices";


const prePrdoDeploymentList = ['Dev', 'Qa', 'Stage'];
const prodDeploymentList = ['Prod'];
const prodEventList = ["prd", "prod", "production"];
const prodEnvironmentList = ["eu-west", "prod"];

export const useIdentityEnvironmentFilter = () => {
    const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment);

    const [eventConfiglist, setEventConfigList] = useState<string[]>([]);

    const filterEventEnvironmentList = (environments: string[]) => {
        if(!environments || environments.length === 0) {
            return environments
        }

        const isProduction = identityEnvironment === IDENTITY_ENVIRONMENT_TYPE.PRODUCTION;
        return environments.filter((environment: string) => 
            isProduction === prodEventList.includes(environment?.toLocaleLowerCase()));

    }
    const filterEventDeployments = (events: IEvent[] | undefined    ) => {
        if(!events || events?.length === 0) {
            return events;
        }
        const isProduction = identityEnvironment === IDENTITY_ENVIRONMENT_TYPE.PRODUCTION;
        return events.filter((event: IEvent) => 
            isProduction === prodEventList.includes(event?.serviceDeployment?.toLocaleLowerCase() ?? ""));
    }

    const filterEndpointDeployments = (endpoints: TransformedDeployment[] | undefined) => {
        if(!endpoints || endpoints?.length === 0) {
            return endpoints;
        }

        const isProduction = identityEnvironment === IDENTITY_ENVIRONMENT_TYPE.PRODUCTION;
        return endpoints.filter((endpoint: TransformedDeployment) => 
            isProduction === prodEnvironmentList.includes(endpoint?.environment?.toLocaleLowerCase() ?? ""));

    }

    useEffect(() => {
        if(identityEnvironment === IDENTITY_ENVIRONMENT_TYPE.PRODUCTION) {
            setEventConfigList(prodDeploymentList);
        } else {
            setEventConfigList(prePrdoDeploymentList);
        }
        
    },[]);
    return {
        filterEventDeployments,
        eventConfiglist,
        filterEventEnvironmentList,
        filterEndpointDeployments
    }
}