import { IdentityApplicationViewState } from "libs/resources/identity/applications/application-views/IdentityApplicationInfo";
import React, { useCallback, useContext, useEffect } from "react";
import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import { Container, Box, Typography, Grid } from "@material-ui/core";
import { EnvironmentType } from "header/environment-selector/ENV_CONSTANTS";
import { mapToArray } from "libs/utils/shared/common";
import { useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CallbackLogoutField from "./CallbackLogoutField";
import ApplicationService from "../services/ApplicationService";
import Submit from "modus/components/form/submit/Submit";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import { FormattedMessage } from "react-intl";

interface CallbackLogoutConfigurationProps {
  state: IdentityApplicationViewState;
}

const authUrlValidator = (value: string | undefined) => {
    if (typeof value === "string") {
      const values = mapToArray(value.split(","));
      if (values.length > 250) {
        return "The number of urls exceed the max limit i.e. 250 ";
      }
      if (values.map((item) => String(item).trim()).filter((item) => item.length > 0).every((item) => {
            try {
              const url = new URL(item);

              if (!url.protocol || !url.origin) {
                return false;
              }

              return true;
            } catch {}

            return false;
          })
      ) {
        return "";
      }
    }

    return "Invalid input";
  };

const CallbackLogoutConfiguration: React.FC<
  CallbackLogoutConfigurationProps
> = ({ state }) => {
  const identityEnvironment = useSelector(
    (state: any) => state?.common?.identityEnvironment
  );
  const notification = useContext(SnackNotificationContext);

  const [updateState, { subscribe }] = useAsyncAction(() =>
    ApplicationService.updateApplication(
      identityEnvironment as EnvironmentType,
      state.application?.applicationName as string,
      {
        logoutUri: makeUrls(formValues.logout),
        redirectUri: makeUrls(formValues.callback),
        grantTypes: [
          "authorization_code",
          "client_credentials",
          "refresh_token",
          "urn:ietf:params:oauth:grant-type:token-exchange",
          "urn:ietf:params:oauth:grant-type:device_code",
        ],
      }
    )
  );

  const [formValues, setFormValues] = React.useState({
    callback:
      state.publishStatus?.[identityEnvironment as EnvironmentType]
        ?.redirectUri === undefined
        ? ""
        : state.publishStatus?.[identityEnvironment as EnvironmentType]?.redirectUri?.join(", "),

    logout:
      state.publishStatus?.[identityEnvironment as EnvironmentType]
        ?.logoutUri === undefined
        ? ""
        : state.publishStatus?.[identityEnvironment as EnvironmentType]?.logoutUri?.join(", "),
  });

  const [AlertClose, setAlertClose] = React.useState({ info: false, error: true });

  const callback_length = formValues.callback
    ?.split(",")
    .map((str) => str.trim())
    .filter((str) => str.length > 0).length;

  const logout_length = formValues.logout
    ?.split(",")
    .map((str) => str.trim())
    .filter((str) => str.length > 0).length;

  const [counter, setCounter] = React.useState({
    callback: callback_length === undefined ? 0 : callback_length,
    logout: formValues.logout === undefined ? 0 : logout_length,
  });

  const [error, setError] = React.useState({
    callback: { value: false },
    logout: { value: false },
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(()=>{

    const callback_length = formValues.callback
      ?.split(",")
      .map((str) => str.trim())
      .filter((str) => str.length > 0).length;
    const logout_length = formValues.logout
      ?.split(",")
      .map((str) => str.trim())
      .filter((str) => str.length > 0).length;

    setCounter({
      callback: callback_length === undefined ? 0 : callback_length,
      logout: formValues.logout === undefined ? 0 : logout_length,
    });

    setError({
        callback: { value: authUrlValidator(formValues.callback) !== "" },
        logout: { value: authUrlValidator(formValues.logout) !== "" },
      });
  }, [formValues]);

  const makeUrls = useCallback((urlString?: string) =>
      urlString?.split(",").map((value) => String(value).trim()).filter((value) => value.length > 0) ?? []
  ,[]);

  

  useEffect(() => {
    if (!updateState.error) {
      return;
    }
    setAlertClose({ info: AlertClose.info, error: false });
  }, [AlertClose.info, notification, updateState.error]);

  useEffect(() => {
    if (!updateState.loaded || !notification) {
      return;
    }
    notification.show({
      id: "updatestate-success",
      message: <FormattedMessage defaultMessage="Updated Successfully." />,
      timeout: 4000,
    });
  }, [notification, updateState.loaded]);

  return (
    <Box style={{ backgroundColor: "white" }}>
      <Container maxWidth={false} style={{ padding: "20px 20px" }}>
        <Typography style={{fontSize: "14px", fontWeight: 600 }}>
          Callback and Logout URLs
        </Typography>
        <form noValidate autoComplete="off">
          <Box style={{ paddingRight: "15px" }}>
            {!AlertClose.error && (
              <Alert variant="outlined" severity="error"
                style={{ color: "#c81922",borderColor: "#c81922",borderLeft: "12px solid #c81922",margin: "16px 0px" }}
                icon={<InfoOutlinedIcon style={{ color: "#c81922" }} />}
                action={
                  <CloseIcon onClick={() => {
                      setAlertClose({ info: AlertClose.info, error: true });
                    }}
                    style={{ color: "#c81922", width: "16px" }}
                  />
                }
              >
                <Typography style={{ fontWeight: 700 }}>
                  Error!{" "}
                  <span style={{ fontWeight: 400 }}>Something went wrong.</span>
                </Typography>
              </Alert>
            )}
            {!AlertClose.info && (
              <Alert variant="outlined" severity="info"
              style={{ color: "#0063A3", borderColor: "#0063A3", borderLeft: "12px solid #0063A3", margin: "16px 0px" }}
                action={
                  <CloseIcon
                    onClick={() => {
                      setAlertClose({ info: true, error: AlertClose.error });
                    }}
                    style={{ color: "#0063A3", width: "16px" }}
                  />
                }
                icon={<InfoOutlinedIcon style={{ color: "#0063A3" }} />}
              >
                <Typography style={{ fontWeight: 700 }}>
                  You are allowed to register a total of 250 callback URLs and
                  250 logout URLs.
                </Typography>
              </Alert>
            )}
            <Container maxWidth={false} style={{ padding: "0px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <CallbackLogoutField
                    counter={counter.callback}
                    label={"Allowed Callback URLs"}
                    placeholder={"Add comma separated URLs to be allowed"}
                    value={formValues.callback}
                    name={"callback"}
                    onChange={handleChange}
                    setError={() => {}}
                    customError={
                      authUrlValidator(formValues.callback) === ""
                        ? { valid: false, message: "" }
                        : { valid: true, message: authUrlValidator(formValues.callback)}
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CallbackLogoutField
                    counter={counter.logout}
                    label={"Allowed Logout URLs"}
                    placeholder={"Add comma separated URLs to be allowed"}
                    value={formValues.logout}
                    name={"logout"}
                    onChange={handleChange}
                    setError={() => {}}
                    customError={
                      authUrlValidator(formValues.logout) === ""
                        ? { valid: false, message: "" }
                        : { valid: true, message: authUrlValidator(formValues.logout) }
                    }
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box display="flex" justifyContent="flex-end" pt={2} pr={1}>
            <Submit
              variant="outlined"
              color="primary"
              state={updateState as any}
              onClick={() => subscribe()}
              disabled={error.callback.value || error.logout.value || formValues.callback==="" || formValues.logout===""}
              style={{ width: "127px", height: "32px" }}
            >
              {" "}
              Submit{" "}
            </Submit>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default CallbackLogoutConfiguration;
