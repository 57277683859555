import type { } from '@material-ui/lab/themeAugmentation/';
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

const paletteTheme = createMuiTheme({
    typography: {
        "fontFamily": `'Open Sans', sans-serif !important`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    },
    palette: {
        primary: {
            main: "#0063A3"
        },
        secondary: {
            main: "#252a2e"
        },
        warning: {
            main: "#c81922"
        },
        success: {
            main: "#4a821f",
            contrastText: "#ffffff"
        },
        background: {
            default: "#EAEAEF"
        }
    }
})

export const theme = responsiveFontSizes(createMuiTheme({
    ...paletteTheme,
    overrides: {
        MuiFab: {
            extended: {
                "& .MuiFab-label": {
                    "& > svg": {
                        marginRight: 8
                    },

                    marginRight: 8
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: 4,
                height: "32px",
                textTransform: "none",
                whiteSpace: "nowrap"
            },

            sizeSmall: {
                borderRadius: 24,
                fontSize: paletteTheme.typography.pxToRem(12),
                lineHeight: paletteTheme.typography.pxToRem(24)
            },
            contained: {
                boxShadow: 'none',
                "&:hover": {
                    boxShadow: 'none'
                }
            },
            containedPrimary: {
                // Modus color for button inclusion
                backgroundColor: '#0063a3',
                "&:hover": {
                    backgroundColor: '#0082d6'
                }
            }
        },

        MuiPaper: {
            rounded: {
                borderRadius: 2
            },
            elevation1: {
                boxShadow: 'none'
            },
            elevation2: {
                boxShadow: 'none'
            },
            elevation4: {
                boxShadow: 'none'
            }
            
        },
        MuiSkeleton: {
            text: {
                transform: "none"
            }
        },
        MuiLink: {
            root: {
                color: paletteTheme.palette.primary.light,
                "&:hover": {
                    color: paletteTheme.palette.primary.main
                }
            }
        },
        MuiIconButton: {
            root: {
                borderRadius: '2px',
                "&:hover": {
                    backgroundColor: "#CFE1EE",
                    color: "#005F9E",
                    fill: "#005F9E"
                }
            }
        },
        MuiAccordion: {
            root: {
                "&$expanded": {
                    margin: paletteTheme.spacing(3, 0)
                }
            },
            rounded: {
                transition: paletteTheme.transitions.create(['margin', 'border-radius'], {
                    easing: paletteTheme.transitions.easing.sharp
                }),
                "&:first-child": {
                    borderTopLeftRadius: paletteTheme.spacing(1),
                    borderTopRightRadius: paletteTheme.spacing(1)
                },

                "&:last-child": {
                    borderBottomLeftRadius: paletteTheme.spacing(1),
                    borderBottomRightRadius: paletteTheme.spacing(1)
                },

                "&$expanded": {
                    borderRadius: paletteTheme.spacing(2)
                }
            },


        },
        MuiAccordionSummary: {
            root: {
                padding: paletteTheme.spacing(0, 3)
            },
            content: {
                padding: "0",
                margin: paletteTheme.spacing(1, 0),

                transition: paletteTheme.transitions.create(['margin'], {
                    easing: paletteTheme.transitions.easing.sharp
                }),

                "&$expanded": {
                    margin: paletteTheme.spacing(3, 0)
                }
            }
        },
        MuiStepper: {
            root: {
                background: "transparent"
            }
        },
        MuiTabs: {
            root: {
                borderBottom: `1px solid ${paletteTheme.palette.divider}`
            },
        },
        MuiDialog: {
            paper: {
                overflowX: "hidden"
            }
        },
        MuiTypography: {
            subtitle2: {
                "&.highlight-subtitle": {
                    color: "#476282"
                }
            }
        },
        MuiBadge: {
            colorPrimary: {
                backgroundColor: "#0063A3",
                borderRadius: "4px",
            }
        }
    }
}));
