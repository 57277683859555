import React, { ReactElement, useCallback } from 'react';
import { useForm, FieldRenderProps } from 'react-final-form';
import { useDropzone } from 'react-dropzone';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import Backup from '@material-ui/icons/Backup';

const useStyles = makeStyles(theme => {
    const borderRadius = theme.spacing(1);

    return createStyles({
        root: {
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
            borderRadius,
            borderWidth: 2,
            borderStyle: "dashed",
            transition: theme.transitions.create("all", {
                duration: 400,
                easing: theme.transitions.easing.easeInOut
            }),
            borderColor: theme.palette.divider,
            padding: theme.spacing(2),
            textAlign: "center",
            outline: "none"
        },
        activeContent: {
            borderColor: theme.palette.primary.main
        },
        contentIcon: {
            fontSize: theme.typography.h1.fontSize,
            color: theme.palette.text.secondary,
            pointerEvents: "none"
        },
        activeContentIcon: {
            color: theme.palette.primary.main,
        },
        contentLayout: {
            display: "inline-block",
        },
        activeContentLayout: {
            pointerEvents: "none"
        }
    })
}
);

export interface FormFileInputContentProps {
    openFileDialog: () => void;
    clear: () => void;
}

export interface FormFileInputProps extends FieldRenderProps<string, any> {
    accept?: string[];
    multiple?: boolean;
    boxContent?: (props: FormFileInputContentProps) => ReactElement
}

interface ViewContentProps {
    active: boolean;
}

const FormFileInput = ({ input, accept, multiple, boxContent, ...rest }: FormFileInputProps) => {
    const form = useForm();
    const styles = useStyles();

    const setSelected = useCallback((files: File[]) => {
        form.change(input.name, files);
        form.mutators?.setFieldTouched?.(input.name, true);

    }, [input, form]);

    const { getRootProps, getInputProps, isDragActive, open: openFileDialog } = useDropzone({
        onDropAccepted: files => setSelected(files),
        accept,
        multiple,
        preventDropOnDocument: true,
        noKeyboard: true,
        noClick: !!boxContent
    });

    const openFileDialogCallback = useCallback(() => openFileDialog(), [openFileDialog]);
    const clearCallback = useCallback(() => setSelected([]), [setSelected]);

    const DropContentBox = ({ active }: ViewContentProps) => {
        return (<div className={clsx(styles.contentLayout, {
            [styles.activeContentLayout]: active
        })}>
            <div>
                {
                    active ?
                        <Backup className={clsx(styles.contentIcon, styles.activeContentIcon)} /> : <BackupOutlinedIcon className={styles.contentIcon} />
                }
            </div>

            {
                boxContent &&
                <div>
                    {boxContent({
                        openFileDialog: openFileDialogCallback,
                        clear: clearCallback
                    })}
                </div>
            }
        </div>);
    }

    return (
        <>
            <div {...getRootProps()} className={clsx(styles.root, {
                [styles.activeContent]: isDragActive
            })}>
                <input {...getInputProps()} />
                <DropContentBox active={isDragActive} />
            </div>
        </>
    )
};

export default FormFileInput;
