import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Typography, Link } from "@material-ui/core";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import NAWrap from "legacy-components/form/action/NAWrap";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import { ProxyVerificationSupportInfo } from "pages/home/proxy/summary/verification/models/verification.model";

interface ProxyVerificationSupportViewProps {
  proxyVerificationSupportInfo?: ProxyVerificationSupportInfo | null;
  onSuccess?: () => void;
}

const ProxyVerificationSupportView: FC<ProxyVerificationSupportViewProps> = ({
  proxyVerificationSupportInfo,
}) => {
  function capitalizeName(name: string | undefined) {
    return name
      ?.toLowerCase()
      .replaceAll(/_/g, " ")
      .replace(/\b(\w)/g, (s) => s.toUpperCase());
  }
  return (
    <FormInputContainer
      title={
        <Typography
          variant="subtitle2"
          children={
            <FormattedMessage defaultMessage="Please indicate the level of support your team will be providing for this API Proxy. *" />
          }
        />
      }
    >
      <FormViewInliner
        variant="inline"
        gutter="gutter-bottom"
        title={<FormattedMessage defaultMessage="Support Type" />}
      >
        <Typography variant="body1">
          <NAWrap
            value={proxyVerificationSupportInfo?.supportType}
            showMessage={true}
          >
            {capitalizeName(proxyVerificationSupportInfo?.supportType)}
          </NAWrap>
        </Typography>
      </FormViewInliner>
      {(proxyVerificationSupportInfo?.supportType === "STANDARD" ||
        proxyVerificationSupportInfo?.supportType === "PREMINUM") && (
        <>
          <FormViewInliner
            variant="inline"
            gutter="gutter-bottom"
            title={<FormattedMessage defaultMessage="Support Website" />}
          >
            <Typography variant="body1">
              <NAWrap
                value={proxyVerificationSupportInfo?.supportWebsite}
                showMessage={true}
              >
                <Link
                  color="primary"
                  href={proxyVerificationSupportInfo?.supportWebsite}
                  target="_blank"
                >
                  {proxyVerificationSupportInfo?.supportWebsite}
                </Link>
              </NAWrap>
            </Typography>
          </FormViewInliner>
          <FormViewInliner
            variant="inline"
            gutter="gutter-bottom"
            title={<FormattedMessage defaultMessage="Support Email" />}
          >
            <Typography variant="body1">
              <NAWrap
                value={proxyVerificationSupportInfo?.supportEmail}
                showMessage={true}
              >
                <Link color="primary">
                  {proxyVerificationSupportInfo?.supportEmail}
                </Link>
              </NAWrap>
            </Typography>
          </FormViewInliner>
          <FormViewInliner
            variant="inline"
            gutter="gutter-bottom"
            title={<FormattedMessage defaultMessage="Process Descirption" />}
          >
            <Typography variant="body1">
              <NAWrap
                value={proxyVerificationSupportInfo?.supportProcessDesc}
                showMessage={true}
              >
                {proxyVerificationSupportInfo?.supportProcessDesc}
              </NAWrap>
            </Typography>
          </FormViewInliner>
        </>
      )}
      {proxyVerificationSupportInfo?.supportType === "PREMINUM" && (
        <FormViewInliner
          variant="inline"
          gutter="gutter-bottom"
          title={<FormattedMessage defaultMessage="Road Map" />}
        >
          <Typography variant="body1">
            <NAWrap
              value={proxyVerificationSupportInfo?.supportRoadMap}
              showMessage={true}
            >
              {proxyVerificationSupportInfo?.supportRoadMap}
            </NAWrap>
          </Typography>
        </FormViewInliner>
      )}
    </FormInputContainer>
  );
};

ProxyVerificationSupportView.propTypes = {};

export default ProxyVerificationSupportView;
