import React, { ReactNode, memo, ReactElement } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            width: "100%",
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',

            '&:hover $controls': {
                opacity: 1,
                pointerEvents: "unset"
            },

            height: "auto",
            minHeight: "24px",

            "&&__block-view": {
                height: "auto",
                alignItems: "start",

                "& $container": {
                    alignItems: 'flex-start',
                }
            },

            "&&__list-view": {
                height: "auto",
                alignItems: "start",
                flexDirection: 'column',

                "& $formView": {
                    marginTop: theme.spacing(1)
                },

                "& $gutterBottom": {
                    marginBottom: theme.spacing(3)
                },
                "& $container": {
                    width: "100%",
                    flexBasis: "100%",
                }
            }
        },

        container: {
            width: "calc(100% - 200px)",
            flexBasis: "calc(100% - 200px)",
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
                marginLeft: theme.spacing(2)
            },

            '&&--has-controls': {
                transition: theme.transitions.create(['background']),

                '&:hover': {
                    background: "linear-gradient(21deg, rgba(255,255,255,0) 0%, rgba(249,249,249,1) 80%)"
                },
            }
        },
        formView: {
            flex: 1,
            overflow: "hidden",


            '& > p': {
                width: "100%",
                overflow: "hidden"
            }
        },
        controls: {
            opacity: 0,
            pointerEvents: "none",
            position: "relative",

            transition: theme.transitions.create('opacity', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            }),
        },
        gutterBottom: {
            marginBottom: theme.spacing(2)
        },
        title: {
            fontWeight: theme.typography.fontWeightRegular
        },
        label: {
            width: "200px",
            minWidth: "200px",

            [theme.breakpoints.down('sm')]: {
                width: "120px",
                minWidth: "120px",
            },
        }
    }),
);

export type FormViewInlinerVariant = "block" | "inline" | "list";

interface FormViewInlinerProps {
    children: ReactNode,
    title?: ReactElement,
    controls?: ReactElement,
    gutter?: "gutter-bottom",
    variant?: FormViewInlinerVariant
}

const FormViewInliner = ({ children, title, controls, gutter, variant }: FormViewInlinerProps) => {
    const style = useStyles();

    return (
        <div className={clsx(style.root, {
            [style.gutterBottom]: gutter === "gutter-bottom",
            [`${style.root}__block-view`]: variant === "block",
            [`${style.root}__list-view`]: variant === "list"
        })}>
            <div className={style.label}>
                <Typography className={clsx(style.title)} variant="subtitle2" color="textSecondary">
                    {title}
                </Typography>
            </div>
            <div className={clsx(style.container, {
                [`${style.container}--has-controls`]: !!controls,
            })}>
                <div className={style.formView}>
                    {children}
                </div>
                <div className={style.controls}>
                    {controls}
                </div>
            </div>
        </div>
    );
};

FormViewInliner.propTypes = {
    children: PropTypes.any.isRequired,
    gutter: PropTypes.oneOf(["gutter-bottom"]),
    variant: PropTypes.oneOf<FormViewInlinerVariant>(["block", "inline", "list"]),
};

export default memo(FormViewInliner);