import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import FormViewInliner from 'legacy-components/form/container/FormViewInliner';
import NAWrap from 'legacy-components/form/action/NAWrap';
import FormInputContainer from 'legacy-components/form/container/FormInputContainer';
import { SubscriptionVerificationInfo } from '../model/subscription.model';

interface ApplicationUsecaseViewProps {
    subscriptionVerificationInfo?: SubscriptionVerificationInfo | null,
    onSuccess?: () => void
}

const ApplicationUsecaseView: FC<ApplicationUsecaseViewProps> = ({ subscriptionVerificationInfo }) => {
    return (
        <React.Fragment>
            <FormInputContainer title={
                <FormattedMessage defaultMessage="Please provide supporting use case information for your API Product subscription request." />
            }>
                <FormViewInliner variant="list" gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Application" />} >
                    <Typography variant="subtitle1" color="primary">
                        <NAWrap value={subscriptionVerificationInfo?.applicationName} showMessage={true}>
                            {subscriptionVerificationInfo?.applicationName}
                        </NAWrap>
                    </Typography>
                </FormViewInliner>
                <FormViewInliner variant="list" gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Team" />} >
                    <Typography variant="subtitle1" color="primary">
                        <NAWrap value={subscriptionVerificationInfo?.teamName} showMessage={true}>
                            {subscriptionVerificationInfo?.teamName}
                        </NAWrap>
                    </Typography>
                </FormViewInliner>
            </FormInputContainer>
            <FormInputContainer title={
                <React.Fragment>
                    <Typography variant="subtitle2" children={<FormattedMessage defaultMessage="What is the use case or business context for this API Product Subscription request?*" />} />

                </React.Fragment>
            }>
                <FormViewInliner variant="list">
                    <Typography variant="body1" >
                        <NAWrap value={subscriptionVerificationInfo?.businessContext} showMessage={true}>
                            {subscriptionVerificationInfo?.businessContext}
                        </NAWrap>
                    </Typography>
                </FormViewInliner>
            </FormInputContainer>
        </React.Fragment>

    );
}

ApplicationUsecaseView.propTypes = {
}

export default ApplicationUsecaseView;