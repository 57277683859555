import React, { useEffect, useState } from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PropTypes from "prop-types";
import * as actions from "../../../../store/actions/actions.tsx";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { FormattedMessage } from "react-intl";
import GenericPageHeader from "legacy-components/page-headers/GenericPageHeader";
import InlineIconFormatter from "legacy-components/formatters/InlineIconFormatter";
import {Button, Link, Paper, Typography, Accordion, ButtonGroup, Tooltip} from "@material-ui/core";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import ProxyEnvironment from "./proxyEnvironment";
import Helper from "pages/home/helper/Helper";
import EnvironmentHelper from "pages/home/helper/EnvironmentHelper";
import ResourceAuthorizer from "./authorizer/ResourceAuthorizer";
import ProxyVerification from "./verification/ProxyVerification";
import {
  useResourceAbility,
  useFeatureAbility,
} from "libs/security/authorization/Permission";
import { Feature } from "libs/security/authorization/FeatureAbility";
import ProxyService from "libs/resources/proxy/ProxyService";
import {
  AsyncLoader,
  useAsyncAction,
} from "legacy-components/form/action/AsyncAction";
import {
  AccordionSummaryTitle,
  AccordionContent,
} from "legacy-components/form/components/accordion-form/AccordionForm";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import NAWrap from "legacy-components/form/action/NAWrap";
import { StatusBadge } from "legacy-components/badges/StatusBadge";
import CopyToClipboard from "legacy-components/form/action/CopyToClipboard";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router";
import AlertMUI from "@material-ui/lab/Alert";
import StarTick from "../../../../legacy-components/icons/StarTick";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import AudiencesView from "./AudiencesView";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      marginBottom: "15px",
    },
    tabs: {
      marginBottom: "30px",
    },
    container: {
      marginBottom: "10px",
    },
    icon: {
      color: palette.text.primary,
    },
    accordionSummary: {
      padding: "10px 15px",
      margin: 0,
      "&.Mui-expanded": {
        margin: " 0 !important",
        padding: "0 15px",
      },
    },
    accordionDetails: {
      padding: 0,
    },
  })
);

ProxyEnvironments.propTypes = {
  teamProxies: PropTypes.any,
  sharedProxies: PropTypes.any,
  header: PropTypes.any,
};

export default function ProxyEnvironments(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [isShowEnvironmentHelper, toggleEnvironmentHelper] = useState(false);
  const [canShowResourceAuthorizer, setShowResourceAuthorizer] = React.useState(
    {}
  );
  const history = useHistory();
  let gateways = useSelector((state) => state?.common?.metadata?.gateways);
  const [response, { subscribe }] = useAsyncAction(
    () => ProxyService.forApi(props.proxy?.apiName),
  );
  const onSuccess = (data) => {
    subscribe();
    props.onUpdate && props.onUpdate(data)
  };

  const api = useSelector((state) => state?.proxyManager?.proxy);


  useEffect(() => {
    if(response.value && dispatch) {
      dispatch({ type: actions.storeProxy, payload: response.value });
    }
  },[response.value, dispatch]);
  
  const [hasInvite] = useResourceAbility({
    resource: ({ TeamMembers }) => TeamMembers.invite,
  });

  useEffect(() => {
    gateways &&
      gateways.forEach((gateway) => {
        canShowResourceAuthorizer[gateway.name] =
          api?.apiDeployments.filter((deployment) => {
            return (
              deployment?.organisation?.toLowerCase() ===
                gateway?.name?.toLowerCase() &&
              deployment.deployStatus === "DEPLOY_COMPLETED"
            );
          }).length > 0;
      });
    setShowResourceAuthorizer({ ...canShowResourceAuthorizer });
  }, [api, gateways, hasInvite?.create()]);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [can, state] = useResourceAbility({
    resource: ({ Proxy }) => Proxy.verification.getStarted,
  });

  const hasFeature = useFeatureAbility({
    id: Feature.ApiVerification,
  });

  const CopyAPIName = (
    <CopyToClipboard size="small" edge="end" text={props.proxy?.apiName} />
  );

  return (
    api && (
        <div className={classes.root}>
          {gateways && (
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tabs}
              indicatorColor="primary"
            >
              <Tab label="Summary" key="summary"     
              />
              {gateways.map((gateway) => (
                <Tab
                  label={
                    <div>
                      {
                        gateway?.name?.toLowerCase() === "trimble-pre-prod"
                          ? "Pre Prod"
                          : "Prod"
                      }
                      {/* <Tooltip title={<FormattedMessage defaultMessage="Audience claim complaince" />}>
                        <IconButton href={"#" + gateway.name}>
                          {props.proxy.audUsageStatus ? <StarTick/> : <NewReleasesIcon style={{ color: ("#FBAD26")}}/>}
                        </IconButton>
                      </Tooltip> */}
                    </div>
                  }
                  key={gateway.name}
                />
              ))}

              {state.loaded ||
                (state.intermediate && can.create() && hasFeature && (
                  <Tab label="Verification" key="proxy-verification" />
                ))}
            </Tabs>
          )}
          {gateways && api && (
            <div>
              {gateways &&
                gateways.map((gateway, index) => (
                  <TabPanel
                    value={value}
                    index={index + 1}
                    dir={theme.direction}
                    key={index}
                  >
                    <Container maxWidth="md" className={classes.container}>
                      <GenericPageHeader
                        title={
                          <Typography
                            variant="subtitle1"
                            className={classes.title}
                          >
                            <InlineIconFormatter
                              icon={
                                <HelpOutlineOutlinedIcon
                                  onClick={() => {
                                    toggleEnvironmentHelper(true);
                                  }}
                                />
                              }
                            >
                              <FormattedMessage defaultMessage="Environment" />
                            </InlineIconFormatter>
                          </Typography>
                        }
                      />
                      <Helper
                        open={isShowEnvironmentHelper}
                        handleClose={() => {
                          toggleEnvironmentHelper(false);
                        }}
                        children={<EnvironmentHelper />}
                      ></Helper>
                      <div>
                        {
                          api &&
                          gateway?.environments?.map((environment, index) => {
                            let deployments =
                              api?.apiDeployments.filter(
                                (apiDeployment) =>
                                  apiDeployment?.environmentName ===
                                  environment?.environmentName
                              );
                            let deployment = deployments.length > 1 ? deployments.filter(
                              (apiDeployment) => 
                              apiDeployment.provider === 'azure')?.[0] : deployments.length === 1 ? deployments[0] : null;
                            return (
                              api && (
                                <ProxyEnvironment
                                  onSuccess={onSuccess}
                                  isShared={api?.isShared}
                                  env={environment}
                                  deployment={deployment}
                                  key={index}
                                  onUpdate={props.onUpdate}
                                />
                              )
                            );
                          })}
                      </div>
                    </Container>

                    <Container maxWidth="md" className={classes.container}>
                      <div>
                        {
                          <ResourceAuthorizer
                            onSuccess={onSuccess}
                            apiName={api?.apiName}
                            gatewayName={gateway?.name?.toLowerCase()}
                            resourceServer={
                              api?.resourceServer.filter(
                                (resourceServer) =>
                                  resourceServer.gateway_name === gateway.name
                              )[0] ?? null
                            }
                          />
                        }
                    </div>
                    </Container>
                    {/* <Container maxWidth="md" className={classes.container} id={gateway.name}>
                      <div>
                        {
                          <Accordion defaultExpanded={true}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <AccordionSummaryTitle>
                                <FormattedMessage defaultMessage="Audience Compliance" />
                              </AccordionSummaryTitle>
                            </AccordionSummary>
                            <br />
                            <AlertMUI severity={"warning"}>
                              <b>Note:</b> Below listed applications are subscribed with your Proxy. Warning status indicates the application is not using proper scopes. Intimate the team to add valid scopes. Enabling audience claims will affect these applications.
                            </AlertMUI>
                            <AccordionDetails className="accordionDetails">
                              <AudiencesView apiName={props.proxy.apiName} gatewayName={gateway.name}/>
                            </AccordionDetails>
                          </Accordion>
                        }
                      </div>
                    </Container> */}
                  </TabPanel>
                ))}
              {state.loaded ||
                (state.intermediate && can.create() && hasFeature && (
                  <TabPanel value={value} index={3}>
                    <ProxyVerification proxy={api?.apiName} onSuccess={onSuccess}/>
                  </TabPanel>
                ))}
              <TabPanel value={value} index={0}>
                <Container maxWidth="md" className={classes.container}>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className={classes.accordionSummary}
                    >
                      <AccordionSummaryTitle>Details</AccordionSummaryTitle>
                    </AccordionSummary>

                    <AccordionDetails className={classes.accordionDetails}>
                      <AccordionContent>
                        <FormViewInliner
                          gutter="gutter-bottom"
                          title={
                            <FormattedMessage defaultMessage="API Proxy" />
                          }
                          controls={CopyAPIName}
                        >
                          <Typography variant="body1" className="no-overflow">
                            <NAWrap
                              value={api?.apiName}
                              showMessage={true}
                            >
                              {api?.apiName}
                            </NAWrap>
                          </Typography>
                        </FormViewInliner>
                        <FormViewInliner
                          gutter="gutter-bottom"
                          title={<FormattedMessage defaultMessage="Version" />}
                        >
                          <Typography variant="body1" className="no-overflow">
                            <NAWrap
                              value={api?.version}
                              showMessage={true}
                            >
                              {api?.version}
                            </NAWrap>
                          </Typography>
                        </FormViewInliner>
                        <FormViewInliner
                          gutter="gutter-bottom"
                          title={
                            <FormattedMessage defaultMessage="Description" />
                          }
                        >
                          <Typography variant="body1" className="no-overflow">
                            <NAWrap
                              value={api?.description}
                              showMessage={true}
                            >
                              {api?.description}
                            </NAWrap>
                          </Typography>
                        </FormViewInliner>
                        {hasInvite?.create() && (
                          <>
                            <FormViewInliner
                              gutter="gutter-bottom"
                              title={
                                <FormattedMessage defaultMessage="Created By" />
                              }
                            >
                              <Typography
                                variant="body1"
                                className="no-overflow"
                              >
                                <NAWrap
                                  value={api?.createdBy}
                                  showMessage={true}
                                >
                                  {api?.createdBy}
                                </NAWrap>
                              </Typography>
                            </FormViewInliner>
                            <FormViewInliner
                              gutter="gutter-bottom"
                              title={
                                <FormattedMessage defaultMessage="Updated By" />
                              }
                            >
                              <Typography
                                variant="body1"
                                className="no-overflow"
                              >
                                <NAWrap
                                  value={api?.updatedBy}
                                  showMessage={true}
                                >
                                  {api?.updatedBy}
                                </NAWrap>
                              </Typography>
                            </FormViewInliner>
                          </>
                        )}
                        <FormViewInliner
                          gutter="gutter-bottom"
                          title={<FormattedMessage defaultMessage="Status" />}
                        >
                          <Typography variant="body1" variantMapping={"div"} className="no-overflow">
                            <StatusBadge
                              align="right"
                              status={{
                                kind: "access",
                                key: api?.verified
                                  ? "VERIFIED"
                                  : "UNVERIFIED",
                              }}
                            />
                          </Typography>
                        </FormViewInliner>
                      </AccordionContent>
                    </AccordionDetails>
                  </Accordion>
                </Container>
              </TabPanel>
            </div>
          )}
        </div>
    )
  );
}
