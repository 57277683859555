import React from 'react';
import { ProductNavigation } from './ProductNavigation';
import { ApplicationNavigation } from './ApplicationNavigation';
import { SubscriptionNavigation } from './SubscriptionNavigation';
import { Box } from '@material-ui/core';

export const NavigationPanel = () => {
    return (
        <Box mt={1}>
            <ApplicationNavigation/>
            <ProductNavigation/>
            <SubscriptionNavigation/>
        </Box>
    )
}