import {
  Badge,
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import { NavLink } from "react-router-dom";

import NotificationsIcon from "@material-ui/icons/Notifications";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import MarketplaceIcon from "legacy-components/icons/MarketplaceIcon";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import { Alert } from "@material-ui/lab";
import { FormattedMessage } from "react-intl";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { useHistory } from "react-router";
import CertificateExpiryService, {
  CertExpiryInfo,
} from "pages/home/truststores/CertificateExpiryService";
import { noop } from "rxjs";

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      margin: "0 !important",
      padding: "15px",
      backgroundColor: "#F9F9FB",
    },
    capitalize: {
      textTransform: "capitalize",
    },
    list: {
      maxHeight: "300px",
      overflowY: "auto",
    },
    active: {
      color: "#ccc",
    },
    root: {
      maxWidth: "300px",
    },
    audienceComplaince: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
interface NotificationProps {}
export const Notification: React.FC<NotificationProps> = ({}) => {
  const classes = useStyles();

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);
  const [, setProxy] = React.useState([]);
  const [count, setCount] = React.useState({
    proxy: 0,
    product: 0,
    subscription: 0,
  });
  const [certExpiry, setCertExpiry] = React.useState<Array<CertExpiryInfo>>([]);
  const history = useHistory();

  useEffect(() => {
    UnifiedAPIService.get(UnifiedAPI.approval).then((response: any) => {
      let pendingApprovals = response?.data?.filter(
        (approval: any) =>
          approval.action.statusType === "PENDING_APPROVAL" &&
          approval.canReview
      );
      setCount({
        ...count,
        proxy: pendingApprovals.length,
      });

      setProxy(response.data);
    }).catch(noop);

    CertificateExpiryService.forCertificateExpiry().subscribe(
      (response) => {
        setCertExpiry(response);
      },
      (error) => {}
    );
  }, []);
  const handleToggle = () => {
    setOpen((isPrevOpen) => !isPrevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const gotoProxy = () => {
    history.push("/home/proxy/list");
  };

  const gotoCert = () => {
    history.push("/home/certificate/expiry");
  };

  /*  if (!count || count?.proxy == 0) {
    return (<span></span>);
  }*/

  return (
    <React.Fragment>
      {(certExpiry.length > 0 || count.proxy > 0 || count.subscription > 0) && (
        <IconButton
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Badge variant="dot" color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      )}
      {certExpiry.length === 0 &&
        count.proxy === 0 &&
        count.subscription === 0 && (
          <IconButton
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
          >
            <NotificationsIcon />
          </IconButton>
        )}

      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={classes.root}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <Typography variant="h6" className={classes.header}>
                    Notifications
                  </Typography>
                  <List className={classes.list}>
                    {count.proxy > 0 && (
                      <ListItem button>
                        <ListItemAvatar>
                          <Avatar>
                            <CodeRoundedIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="API Management Notification"
                          secondary={
                            <NavLink
                              activeClassName={classes.active}
                              to="/home/approvals/proxy/"
                            >
                              View
                            </NavLink>
                          }
                        />
                      </ListItem>
                    )}
                    {count.subscription > 0 && (
                      <ListItem button>
                        <ListItemAvatar>
                          <Avatar>
                            <MarketplaceIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="API Marketplace Notification"
                          secondary={
                            <NavLink
                              activeClassName={classes.active}
                              to="/home/approvals/proxy/"
                            >
                              View
                            </NavLink>
                          }
                        />
                      </ListItem>
                    )}

                    {certExpiry.length > 0 && (
                      <ListItem>
                        <div>
                          <Alert severity={"warning"}>
                            <div className={classes.audienceComplaince}>
                              You've certificates that are expiring/expired.
                              Please replace or renew your certificates
                              immediately to prevent disruption to your service
                              and If you wish to discontinue using the
                              certificates, please delete them. Click view for
                              more details.
                              <Tooltip
                                enterNextDelay={500}
                                title={
                                  <FormattedMessage defaultMessage="View Certificates" />
                                }
                              >
                                <span>
                                  <IconButton size={"small"} onClick={gotoCert}>
                                    {<VisibilityOutlinedIcon />}
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </div>
                          </Alert>
                        </div>
                      </ListItem>
                    )}
                    {/* <ListItem>
                      <div>
                        <Alert severity={"warning"}>
                          <div className={classes.audienceComplaince}>
                            Your proxies(s)  are being accessed with an audience claim that is different from your proxie's id . Please refer to the Proxy summary for more details.
                            <Tooltip enterNextDelay={1000} title={<FormattedMessage defaultMessage="list proxies" />}>
                            <span>
                              <IconButton size={"small"} onClick={gotoProxy}>
                                {<VisibilityOutlinedIcon/>}
                              </IconButton>
                            </span>
                            </Tooltip>
                          </div>
                        </Alert>
                      </div>
                    </ListItem> */}
                  </List>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default Notification;
