import React from 'react';
import { FunctionComponent } from 'react';
import { useRolePermissions } from './Permission';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
interface RoleNameProps {
}

export const RoleName: FunctionComponent<RoleNameProps> = () => {
    const [role] = useRolePermissions();
    return ( <Typography variant="subtitle2" gutterBottom>
      {role}
      </Typography>);
};

export const UserNotExistsCheck: FunctionComponent<any> = () => {
  const [role, state] = useRolePermissions();

  if(!role && state.status === "error") {
    return (
      <Alert severity="warning">User not yet added.. Please contact your Team administrator</Alert>
    );
  }
  return null;
}