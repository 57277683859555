import React, { PropsWithChildren, createContext, memo, useContext } from 'react';
import PropTypes from "prop-types";
import { Paper, createStyles, makeStyles } from '@material-ui/core';
import { StepperFormContentState, StepperFormContext } from './StepperFormContext';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            padding: theme.spacing(0, 0, 3, 0),
            margin: theme.spacing(2),
            borderRadius: theme.spacing(2),
            overflow: "hidden"
        },
    }),
);

export interface FormStepperContextValue<FormValue extends Record<string, any>> {
    stepperId: string;
    next: () => void,
    prev?: () => void,
    updateFormState: (contentState: StepperFormContentState) => void,
    isValid: boolean,
    initilizeContent?: StepperFormContentState<FormValue>,
    formData?: FormValue
}

export const FormStepperContext = createContext<FormStepperContextValue<{}>>({
    stepperId: "default",
    next: () => { },
    updateFormState: () => { },
    isValid: false,
    formData: {}
})

interface StepperFormContentProps {
    nextStepIndex: number,
    prevStepIndex: number,
    onStep: (value: number) => void,
    initilizeContent?: StepperFormContentState,
    stepId: string;
}

const StepperFormContent = (props: PropsWithChildren<StepperFormContentProps>) => {
    const style = useStyles();
    const stepperFormContext = useContext(StepperFormContext);

    const updateFormState = (contentState: StepperFormContentState) => {
        stepperFormContext?.factory.contentState.set(props.stepId, contentState)
    }

    const contextState: FormStepperContextValue<{}> = {
        stepperId: stepperFormContext?.factory.contentState.id ?? "default",
        next: () => props.onStep(props.nextStepIndex),
        prev: props.prevStepIndex > -1 ? () => props.onStep(props.prevStepIndex ?? 0) : undefined,
        updateFormState,
        isValid: true,
        initilizeContent: stepperFormContext?.factory.contentState.get(props.stepId),
        formData: stepperFormContext?.factory.contentState.entries()
    };

    return (
        <FormStepperContext.Provider value={contextState}>
            <Paper className={style.root} elevation={2}>
                {props.children}
            </Paper>
        </FormStepperContext.Provider>
    )
};

StepperFormContent.propTypes = {
    children: PropTypes.any.isRequired,
    stepId: PropTypes.string.isRequired,
    nextStepIndex: PropTypes.number.isRequired,
    prevStepIndex: PropTypes.number.isRequired
};

export default memo(StepperFormContent);