import React, { FC, useState, useContext } from "react";
import { FormRenderProps, Field } from "react-final-form";
import { Button, makeStyles, createStyles } from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import { ProxyVerificationSupportInfo } from "../models/verification.model";
import { AccordionContent } from "legacy-components/form/components/accordion-form/AccordionForm";
import FormContainer from "../../../../../../legacy-components/form/container/FormContainer";
import FormInputContainer from "../../../../../../legacy-components/form/container/FormInputContainer";
import { ValidatorBuilderContext } from "../../../../../../legacy-components/form/validator/Validator";
import ActionInliner from "../../../../../../legacy-components/form/action/ActionInliner";
import FormTextInput from "../../../../../../legacy-components/form/input/FormTextInput";
import InlineIconFormatter from "legacy-components/formatters/InlineIconFormatter";
import Helper from "pages/home/helper/Helper";
import JustificationHelper from "pages/home/helper/JustificationHelper";
import { FormApi, FORM_ERROR } from "final-form";
import { SnackNotificationContext } from "../../../../../../legacy-components/notifications/GenericSnackNotification";
import ProxyVerificationService from "../ProxyVerificationService";
import FormSelectionGroup from "legacy-components/form/input/FormSelectionGroup";

interface SupportFormProps {
  onCancel?: () => void;
  onSuccess?: (
    proxyVerificationSupportInfo?: ProxyVerificationSupportInfo
  ) => void;
  proxyVerificationSupportInfo?: ProxyVerificationSupportInfo;
  proxy: string;
}

const NotificationMessage = () => {
  return <FormattedMessage defaultMessage="Support Saved Successfully" />;
};

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    textField1: {
      marginBottom: spacing(4),
      width: "100%",
    },
  })
);

const SupportForm: FC<SupportFormProps> = ({
  proxy,
  proxyVerificationSupportInfo,
  onSuccess,
}) => {
  const notificationContext = useContext(SnackNotificationContext);
  const classes = useStyles();
  const onSubmit =
    () => (data: ProxyVerificationSupportInfo, form: FormApi) => {
      return new Promise((resolve) => {
        const formState = form.getState();

        if (formState.pristine) {
          resolve();
        } else {
          const supportInfo: ProxyVerificationSupportInfo = {
            supportWebsite: data.supportWebsite,
            supportEmail: data.supportEmail,
            supportAdditionalInput: data.supportAdditionalInput,
            supportType: data.supportType,
            supportRoadMap: data.supportRoadMap,
            supportProcessDesc: data.supportProcessDesc,
          };
          ProxyVerificationService.forSupport(proxy, supportInfo).subscribe(
            () => {
              notificationContext.show({
                id: "support",
                message: <NotificationMessage />,
              });
              resolve();
            },
            (error) => {
              resolve({
                [FORM_ERROR]: error,
              });
            }
          );
        }
      });
    };
  const intl = useIntl();
  const validator = useContext(ValidatorBuilderContext);
  const [supports] = useState([
    {
      value: "UNSUPPORTED",
      title: intl.formatMessage({
        defaultMessage: "Unsupported",
      }),
      helperText: intl.formatMessage({
        defaultMessage:
          "This API Proxy is experimental or is being shared for collaboration purposes. No support structure is currently defined.",
      }),
    },
    {
      value: "OPENSOURCE",
      title: intl.formatMessage({
        defaultMessage: "Open Source",
      }),
      helperText: intl.formatMessage({
        defaultMessage:
          "Your API Proxy is mature. Your team is working to meet the needs of your consumers with professionalism and overcoming challenges as they appear.",
      }),
    },
    {
      value: "STANDARD",
      title: intl.formatMessage({
        defaultMessage: "Standard Support",
      }),
      helperText: intl.formatMessage({
        defaultMessage:
          "A website and/or support email address has been established and the team follows a defined process for supporting the API Proxy.",
      }),
    },
    {
      value: "PREMINUM",
      title: intl.formatMessage({
        defaultMessage: "Premium Support",
      }),
      helperText: intl.formatMessage({
        defaultMessage:
          "A website and/or support email address has been established and the team follows a defined process for supporting the API Proxy." +
          "\n L1/L2/L3 Support is established and runbooks are developed and maintained. " +
          "\n Support requests adhere to an SLA." +
          "\n A roadmap is displayed for users to understand any plans for the service.",
      }),
    },
  ]);
  const [isShowJustificationHelper, toggleJustificationHelper] =
    useState(false);

  const FormComponents = (
    formRenderProps: FormRenderProps<ProxyVerificationSupportInfo, {}>
  ) => {
    let values = formRenderProps.form.getState().values;
    return (
      <>
        <FormInputContainer
          title={
            <Typography
              variant="subtitle2"
              children={
                <FormattedMessage defaultMessage="Please indicate the level of support your team will be providing for this API Proxy: *" />
              }
            />
          }
        >
          <>
            <Field
              name="supportType"
              children={FormSelectionGroup}
              options={supports}
              validate={validator.from({
                required: true,
              })}
            />
          </>
        </FormInputContainer>
        {values &&
          (values.supportType === "STANDARD" ||
            values.supportType === "PREMINUM") && (
            <>
              <Typography
                variant="subtitle2"
                children={
                  <FormattedMessage defaultMessage="Support Website *" />
                }
              />

              <Field
                className={classes.textField1}
                name="supportWebsite"
                type="text"
                component={FormTextInput}
                placeholder={intl.formatMessage({
                  defaultMessage: "http(s)://www.your-support-website.com/",
                })}
                validate={validator.from({
                  pattern: ({ HttpUrl }) => HttpUrl,
                })}
              />
              <Typography
                variant="subtitle2"
                children={<FormattedMessage defaultMessage="Support Email *" />}
              />
              <Field
                className={classes.textField1}
                name="supportEmail"
                type="text"
                component={FormTextInput}
                placeholder={intl.formatMessage({
                  defaultMessage: "example@domain.com",
                })}
                validate={validator.from({
                  required: true,
                  pattern: ({ GenericEmail }) => GenericEmail,
                })}
              />
              <Typography
                variant="subtitle2"
                children={
                  <FormattedMessage defaultMessage="Support Process Description *" />
                }
              />
              <Field
                className={classes.textField1}
                name="supportProcessDesc"
                type="text"
                multiline
                rows={5}
                component={FormTextInput}
                placeholder={intl.formatMessage({
                  defaultMessage: "Elobarate on your support process",
                })}
                validate={validator.from({
                  required: true,
                  maxLength: 900
                })}
              />
            </>
          )}
        {values.supportType === "PREMINUM" && (
          <>
            <Typography
              variant="subtitle2"
              children={
                <FormattedMessage defaultMessage="Support Road Map *" />
              }
            />
            <Field
              className={classes.textField1}
              name="supportRoadMap"
              type="text"
              component={FormTextInput}
              placeholder={intl.formatMessage({
                defaultMessage: "http(s)://www.your-support-roadmap.com/",
              })}
              validate={validator.from({
                maxLength: 600,
                pattern: ({ HttpUrl }) => HttpUrl,
              })}
            />
          </>
        )}

        <ActionInliner>
          <Button
            disabled={formRenderProps.submitting || formRenderProps.pristine}
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
          >
            {" "}
            <FormattedMessage defaultMessage="Save" />
          </Button>
        </ActionInliner>
      </>
    );
  };
  return (
    <AccordionContent
      size="small"
      header={
        <div>
          <InlineIconFormatter
            icon={
              <Typography
                variant="caption"
                color="error"
                children={
                  <FormattedMessage defaultMessage="* required fields" />
                }
              />
            }
          >
            <FormattedMessage defaultMessage="Support" />
          </InlineIconFormatter>
          <Helper
            open={isShowJustificationHelper}
            handleClose={() => {
              toggleJustificationHelper(false);
            }}
            children={<JustificationHelper />}
          ></Helper>
        </div>
      }
    >
      <FormContainer
        previewErrors={true}
        FormProps={{ onSubmit }}
        onSubmitSuccess={onSuccess}
        children={FormComponents}
        prefillValues={proxyVerificationSupportInfo}
      />
    </AccordionContent>
  );
};

SupportForm.propTypes = {};

export default SupportForm;
