import React, { useEffect } from 'react';
import authenticationManager from '../../utils/authenticationManager';
import Loader from '../../legacy-components/loader';


import { useHistory } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';
import { useDispatch } from 'react-redux';
import { excludedPaths } from './login';

const Callback2 = () => {
     const history = useHistory();
   
     useEffect(() => {
       async function handleSigninRedirectCallback() {
         const urlParams = new URLSearchParams(window.location.search);
         const code = urlParams.get('code');
   
         if (code) {
           try {
             await authenticationManager.signinSilent();
             const user = await authenticationManager.getUser();
   
             if (user) {
               let loadedUrl = localStorage.getItem('LoadedUrl') || '/home';
               localStorage.removeItem('LoadedUrl'); 
               if((excludedPaths.some(str => loadedUrl.includes(str))))  {
                loadedUrl = '/home';
              }
               history.push(loadedUrl);
             } else {
               // Redirect to login if no user found
               history.push('/login'); 
             }
           } catch (error) {
             console.error('Error handling sign-in callback:', error);
              // Redirect to login on error
             history.push('/login');
           }
         } else {
          // Redirect to login if no code found
           history.push('/login'); 
         }
       }
       if(!history) {
        return;
       }
       handleSigninRedirectCallback();
       
     }, [history]);
   
     return <Loader showBackdrop />
   };


const Callback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    // handle redirection after login
    if (window.location.hash.includes('code=')) {
      authenticationManager.signinRedirectCallback().then(user => {
        dispatch({ type: 'USER_LOADED', payload: user });
      }).catch(err => {
        console.error(err);
      });
    }
  }, [dispatch]);

  const onSuccess = (user) => {
    let loadedUrl = localStorage.getItem('LoadedUrl') || '/home';
      localStorage.removeItem('LoadedUrl'); 
      // Redirect to the previously stored path
      if((excludedPaths.some(str => loadedUrl.includes(str))))  {
      loadedUrl = '/home';
    }
      history.push(loadedUrl);
  };

  const onError = (error) => {
    console.error('Authentication error', error);
    history.push('/login');
  };

  return (
    <CallbackComponent userManager={authenticationManager}
    successCallback={onSuccess}
    errorCallback={onError}

    >
      <Loader showBackdrop />
    </CallbackComponent>
  );
};
export default Callback;
