import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
    margin: "7px 0px",
  },
  labelContainer: {
    display: "flex",
    padding: "7px 0",
    gap: "8px",
  },
  label: {
    fontSize: "large",
    lineHeight: "21px",
    color: "#464B52",
  },
  required: {
    color: "#DA212C",
    height: "16px",
  },
  textAreaContainer: {
    display: "flex",
    "& div": {
      padding: "0px",
      borderRadius: "4px",
    },
  },
  textArea: {
    border: "1px solid #E0E1E9",
    height: "91px",
    font: "inherit",
    width: "100%",
    padding: "12px",
    borderBottom: "solid 1px #0063a3",
    borderRadius: "4px",
  },
});

interface TextAreaContainerProps {
  placeholder: string;
  label?: string;
  required?: boolean;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  validate?: ValidationRules;
  setError: (error: boolean) => void; // Error callback
}

interface ValidationRules {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  custom?: {
    valid: boolean,
    message: string,
  }
}

export const TextAreaContainer: React.FC<TextAreaContainerProps> = ({
  placeholder,
  required,
  label,
  value,
  name,
  onChange,
  validate = {},
  setError,
}) => {
  const [inputValue, setInputValue] = React.useState(value);

  const classes = useStyles();
  const [isError, setIsError] = React.useState("");
  const [touched, setTouched] = React.useState<boolean>(false); // Track whether input is touched

  const validateInput = (inputValue: any) => {
    if (validate.required && !inputValue) {
      return "This field is required";
    }

    if (validate.pattern && !new RegExp(validate.pattern).test(inputValue)) {
      return "Invalid format";
    }

    if (validate.minLength && inputValue.length < validate.minLength) {
      return `The input must be at least ${validate.minLength} characters in length.`;
    }

    if (validate.maxLength && inputValue.length > validate.maxLength) {
      return `The input exceeds the ${validate.maxLength} character limit.`;
    }

    if (validate.custom?.valid) {
      return validate.custom.message;
    }

    return ""; // No errors
  };

  const handleBlur = () => {
    setTouched(true); // Mark field as touched when user leaves the field
    const validationError = validateInput(value);
    setError(validationError !== "");
    setIsError(validationError);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (touched) {
      const validationError = validateInput(inputValue);
      setIsError(validationError);
      setError(validationError !== "");
    }
    onChange(e);
  };

  return (
    <div style={{ fontWeight: 400 }} className={classes.root}>
       {label && <div className={classes.labelContainer}>
        <label className={classes.label}>{label}</label>
        {required && (
          <div style={{ fontWeight: 600 }} className={classes.required}>
            *
          </div>
        )}
      </div>}
      <MuiTextField
        id="textarea_id"
        className={classes.textAreaContainer}
        placeholder={placeholder}
        value={value}
        inputProps={{ className: classes.textArea }}
        multiline
        rows={4}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={isError === "" ? false : true}
        variant="standard"
        helperText={isError}
      />
    </div>
  );
};
