import { ApplicationScopeValidationFormData, ApplicationConfigurationFormData } from "../models/application.model";
import PublisherApplicationService, { IdentityApplication } from "../PublisherApplicationService";
import { FC, useState, useContext, ReactElement } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { FormApi, FORM_ERROR } from "final-form";
import { FormRenderProps, Field } from "react-final-form";
import { ValidatorBuilderContext } from "../../../../../legacy-components/form/validator/Validator";
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Button, InputAdornment } from "@material-ui/core";
import FormContainer from "../../../../../legacy-components/form/container/FormContainer";
import React from "react";
import FormInputContainer from "../../../../../legacy-components/form/container/FormInputContainer";
import FormTextInput from "../../../../../legacy-components/form/input/FormTextInput";
import ActionInliner from "../../../../../legacy-components/form/action/ActionInliner";
import { StatusBadgeInfo, StatusBadge } from "../../../../../legacy-components/badges/StatusBadge";
import { createIdentityApplicationPublishStatus } from "../application-views/IdentityApplicationInfo";

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            padding: spacing(3)
        },
    }),
);
interface ApplicationScopeValidationFormProps {
    onCancel: () => void,
    onSuccess: (data: ApplicationConfigurationFormData) => void
    application?: IdentityApplication;
    formContent?: ReactElement
}

const ApplicationScopeValidationForm: FC<ApplicationScopeValidationFormProps> = ({ onCancel, onSuccess, application, formContent }) => {
    const intl = useIntl();
    const styles = useStyles();
    const [dataFields] = useState(["__formResponse"]);

    const onSubmit = () => (data: ApplicationScopeValidationFormData, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                if (application && data.applicationName === application.applicationName) {
                    const publishStatus = createIdentityApplicationPublishStatus(application.applicationGatewayResponses ?? []);

                    PublisherApplicationService.environments[application.organisationName].publish("update", application.applicationName, {
                        enforceScopeValidation : true,
                        logoutUri: publishStatus[application.organisationName]?.logoutUri ?? [],
                        redirectUri: publishStatus[application.organisationName]?.redirectUri ?? [],
                        grantTypes: publishStatus[application.organisationName]?.grantTypes ?? []
                    }).then((result) => {
                        form.mutators.setValue("__applicationPublishInfo", {
                            application: result
                        });

                        resolve();
                    }, error => {
                        resolve({
                            [FORM_ERROR]: error
                        });
                    });
                } else {
                    resolve({
                        applicationName: intl.formatMessage({
                            defaultMessage: "Application name didn't match"
                        })
                    })
                }
            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        const validator = useContext(ValidatorBuilderContext);
        const [deploymentStatus] = useState<StatusBadgeInfo>({
            kind: "access",
            key: application?.organisationName,
            access: true
        });

        return (
            <div className={styles.root}>
                {formContent}

                <FormInputContainer>
                    <Field
                        name="applicationName"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Application Name"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "Type the application name"
                        })}
                        validate={validator.from({
                            required: true
                        })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <StatusBadge status={deploymentStatus} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormInputContainer>

                <ActionInliner padding="inset-top">
                    <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                    <Button disabled={formRenderProps.submitting || formRenderProps.pristine} type="submit" variant="contained" color="primary" disableElevation>
                        <FormattedMessage defaultMessage="Confirm" />
                    </Button>
                </ActionInliner>
            </div>);
    }

    return <FormContainer previewErrors={true} dataFields={dataFields} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

ApplicationScopeValidationForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
}

export default ApplicationScopeValidationForm;