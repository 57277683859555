import { ApplicationSummaryFormData } from "../models/application.model";
import PublisherApplicationService, { IdentityApplicationEnvironmentType } from "../PublisherApplicationService";
import { FC, useState, useContext } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { FormApi, FORM_ERROR } from "final-form";
import { FormRenderProps, Field } from "react-final-form";
import { ValidatorBuilderContext } from "../../../../../legacy-components/form/validator/Validator";
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Button } from "@material-ui/core";
import FormContainer from "../../../../../legacy-components/form/container/FormContainer";
import React from "react";
import FormInputContainer from "../../../../../legacy-components/form/container/FormInputContainer";
import FormTextInput from "../../../../../legacy-components/form/input/FormTextInput";
import ActionInliner from "../../../../../legacy-components/form/action/ActionInliner";
import { sanitize } from "dompurify";

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        actions: {
            padding: spacing(3),
        },
        content: {
            padding: spacing(3),
            overflow: "auto"
        }
    }),
);

interface ApplicationSummaryFormProps {
    onCancel: () => void,
    onSuccess: (data: ApplicationSummaryFormData) => void
    applicationName: string,
    env: IdentityApplicationEnvironmentType,
    initialValues?: ApplicationSummaryFormData
}

const ApplicationSummaryForm: FC<ApplicationSummaryFormProps> = ({ onCancel, onSuccess, initialValues, applicationName, env }) => {
    const validator = useContext(ValidatorBuilderContext);

    const intl = useIntl();
    const styles = useStyles();


    const [dataFields] = useState(["__formResponse"]);

    const onSubmit = () => (data: ApplicationSummaryFormData, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                PublisherApplicationService.environments[env].update(applicationName, {
                    description: sanitize(data.description ?? ""),
                    displayName: sanitize(data.displayName ?? ""),
                    name: applicationName
                }).then(() => resolve(), error => {
                    resolve({
                        [FORM_ERROR]: error
                    });
                });
            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<ApplicationSummaryFormData, {}>) => {

        return (
            <>
                <div className={styles.content}>
                    <FormInputContainer padding="inset-content">
                        <Field
                            name="displayName"
                            type="text"
                            component={FormTextInput}
                            label={intl.formatMessage({
                                defaultMessage: "Display Name"
                            })}
                            placeholder={intl.formatMessage({
                                defaultMessage: "Display Name"
                            })}
                            validate={validator.from({
                                required: true,
                                minLength: 3,
                                maxLength: 64,
                                pattern: ({ GenericDisplayName }) => GenericDisplayName
                            })}
                        />

                    </FormInputContainer>

                    <FormInputContainer padding="inset-content">
                        <Field
                            name="description"
                            type="text"
                            multiline
                            rows={2}
                            component={FormTextInput}
                            label={intl.formatMessage({
                                defaultMessage: "Description"
                            })}
                            placeholder={intl.formatMessage({
                                defaultMessage: "Description"
                            })}
                            validate={validator.from({
                                required: true,
                                maxLength: 500
                            })}
                        />
                    </FormInputContainer>
                </div>

                <div className={styles.actions}>
                    <ActionInliner>
                        <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                        <Button disabled={formRenderProps.submitting || formRenderProps.pristine} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Save Changes" /></Button>
                    </ActionInliner>
                </div>
            </>);
    }

    return <FormContainer previewErrors={true} dataFields={dataFields} FormProps={{ onSubmit, initialValues }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

ApplicationSummaryForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    applicationName: PropTypes.string.isRequired,
    env: PropTypes.oneOf<IdentityApplicationEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired
}

export default ApplicationSummaryForm;