import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';

import { IconButton } from '@material-ui/core';
import GuestService from 'services/guestService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
        zIndex: 100,
        width: '20vw'
    },
    paper: {
      marginRight: theme.spacing(2),
    },
    accountButton: {
        margin: 'auto'
    },
    userName: {
        textAlign: "center"
    },
    menuList: {
        borderTop: '1px solid #e8eaed',
        overflowY: "auto",
        maxHeight: "335px"
    },
    selectLangInfo: {
      padding: '10px'
    },
    userPanelRoot: {
        display: "flex",
        flexDirection: "column" 
    }
  }),
);

const LanguageMenu = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [languageList, setLanguageList] = React.useState([]);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  // Languages
  
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }


  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

    let languageMenuItems:Array<any> = [];
    languageList.forEach((language: any, index: number) => {
      languageMenuItems.push(<MenuItem value={language.code} key={index}>{language.name} {(language.name !== language.native) ? "(" + language.native + ")" : ""}</MenuItem>);
  });

  const loadLanguageList = () => {
    GuestService.request(
      {
            url: process.env.REACT_APP_LANGUAGES,
            method: 'GET'
      }
    ).then(
        (response) => {
          setLanguageList(response.data);
        }
    ).catch(
        () => {
          setLanguageList([]);
        }
    );
  }

  if(languageList.length === 0) {
    loadLanguageList();
  }
  return (
        <>
          <IconButton
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          ><LanguageIcon /></IconButton>
        <Popper open={open} anchorEl={anchorRef.current} className={classes.popper} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <div className={classes.userPanelRoot}>
                      <div className={classes.selectLangInfo}> Select your Language</div>
                        {
                          (languageMenuItems?.length > 0) && 
                          (
                            <MenuList autoFocusItem={open} id="menu-list-grow" className={classes.menuList} onKeyDown={handleListKeyDown}>
                              {languageMenuItems}
                            </MenuList>
                          )
                        }
                    </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </>
  );
}

export  default LanguageMenu;