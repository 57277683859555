import React, { FC, useContext } from 'react'
import FormContainer from '../../../../legacy-components/form/container/FormContainer';
import { FormRenderProps, Field } from 'react-final-form';
import { Button } from '@material-ui/core';
import { FORM_ERROR, FormApi } from 'final-form';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ProductService, { ProductEnvironmentType } from '../../../../libs/resources/product/ProductService';
import { ResourceAbility } from 'libs/security/authorization';
import { SnackNotificationContext } from '../../../../legacy-components/notifications/GenericSnackNotification';
import FormInputContainer from 'legacy-components/form/container/FormInputContainer';
import FormSelectionGroup from 'legacy-components/form/input/FormSelectionGroup';
import InlineIconFormatter from 'legacy-components/formatters/InlineIconFormatter';
import { useProductDetailInfo } from './ProductDetailInfo';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ActionInliner from 'legacy-components/form/action/ActionInliner';
import FeatureAbility, { Feature } from 'libs/security/authorization/FeatureAbility';

interface ProductPublishActionProps {
    onSuccess: () => void
    productName: string,
    env: ProductEnvironmentType
}

const ProductPublishAction: FC<ProductPublishActionProps> = ({ env, productName, onSuccess }) => {
    const { approvalTypes } = useProductDetailInfo();
    const notificationContext = useContext(SnackNotificationContext);
    const NotificationMessage = (props: any) => {
        return (
            <span>{props.error}</span>
        )
    }
    const onSubmit = () => (data: any, form: FormApi) => {
        return new Promise((resolve) => {
            ProductService.environments[env].publish(productName, data.approvalType).then(() => resolve(), error => {
                notificationContext.show({
                    id: "product-verified-publish",
                    message: <NotificationMessage error={error} />
                });
                resolve({
                    [FORM_ERROR]: error
                })
            });

        })
    }
    let approval = (<>
    {/* <Field
        name="backendUrl"
        type="hidden"
        component="input"
    /> */}
    <FormInputContainer title={
        <div>
            <InlineIconFormatter icon={<HelpOutlineOutlinedIcon onClick={() => { }} />}>
                <FormattedMessage defaultMessage="Select the request approval option for your product" />
            </InlineIconFormatter>
        </div>
    }>

        <Field
            name="approvalType"
            children={FormSelectionGroup}
            options={approvalTypes}
            defaultSelectionIndex={0}
        />
    </FormInputContainer>
    </>);

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        return (
            <>
                {approval}                
                <ResourceAbility can="create" resource={({ Product }) => Product.publish.environment[env]}>
                    <ActionInliner padding="inset">
                        <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Publish" /></Button>
                    </ActionInliner>
                </ResourceAbility>
            </>
        );
    }

    return <FormContainer FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

ProductPublishAction.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    productName: PropTypes.string.isRequired,
    env: PropTypes.oneOf<ProductEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired
}

export default ProductPublishAction;
