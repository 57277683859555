import React, { FC, useContext, useState, useMemo } from 'react'
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import FormContainer from '../../../../legacy-components/form/container/FormContainer';
import { FormRenderProps, Field } from 'react-final-form';
import { Button } from '@material-ui/core';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { FormApi, FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import FormTextInput from '../../../../legacy-components/form/input/FormTextInput';
import { ValidatorBuilderContext } from '../../../../legacy-components/form/validator/Validator';
import FormFileInput, { FormFileInputContentProps } from '../../../../legacy-components/form/input/FormFileInput';
import CertificateFieldInput from './CertificateFieldInput';
import CertificateService, { ICertificate } from '../CertificateService';
import PropTypes from 'prop-types';
import CertificateInfo, { CertificateFileInfo } from "./CertificateInfo";
import { CertificateFormData } from "../models/certificate.model";


interface CertificateFormProps {
    certificate: ICertificate,
    onCancel: () => void,
    onSuccess: (data?: any) => void,
}

const CertificateUploadForm: FC<CertificateFormProps> = ({ certificate, onCancel, onSuccess }) => {
    const intl = useIntl();
    const onSubmit = () => (data: any, form: FormApi) => {
        return new Promise(async (resolve) => {
            const formState = form.getState();
            if (formState.pristine) {
                resolve();
            } else {
                CertificateService.updateCert({
                    certId: certificate?.certId,
                    description: data.description ? data.description : certificate.description,
                    certFile: data.certFile[0],
                    env: data.environment
                }).then((response) => {
                    resolve();
                }).catch((error) => {
                    resolve({
                        [FORM_ERROR]: error ?? "Unknown error occured while uploading the certificate"
                    });
                });
            }
        })
    }


    const FormComponents = (formRenderProps: FormRenderProps<CertificateFormData, {}>) => {
        const validator = useContext(ValidatorBuilderContext);
        const MIME_MAP = new Map([
            [".crt", "Cert file"],
            [".pem", "PEM file"],
            [".cer", "Cert File"]
        ]);

        const formState = formRenderProps.form.getState();
        const certInfo: CertificateFileInfo = useMemo(() => {
            return {
                "name": formState.values.certFile?.[0]?.name
            }
        }, [formState.values])
        const [accept] = useState(Array.from(MIME_MAP.keys()));
        return (<>
            <FormInputContainer>
                <Field
                    name="environment"
                    type="text"
                    disabled={true}
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Environment"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Select the Environment"
                    })}
                    validate={validator.from({
                        required: true
                    })}
                />
            </FormInputContainer>
            <FormInputContainer>
                <Field
                    name="description"
                    type="text"
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Name"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Name"
                    })}
                    validate={validator.from({
                        required: true,
                        minLength: 10
                    })}
                />
            </FormInputContainer>

            <FormInputContainer>

                <CertificateInfo certFile={certInfo}>
                    <>
                        <Field
                            name="certFile"
                            children={FormFileInput}
                            accept={accept}
                            boxContent={(props: FormFileInputContentProps) => <CertificateFieldInput {...props} certFile={certInfo} />}
                            validate={validator.from({
                                required: true
                            })}
                        />
                    </>
                </CertificateInfo>
            </FormInputContainer>

            <ActionInliner padding="inset-top">
                <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Save" /></Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} prefillValues={certificate} />;

}

CertificateUploadForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
}

export default CertificateUploadForm;