import {Box, Grid, Paper, Typography, makeStyles, createStyles, IconButton, Tooltip} from "@material-ui/core";
import { IdentityApplication } from "libs/resources/identity/applications/PublisherApplicationService";
import React, { useState, useContext, useEffect } from "react";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Submit from "modus/components/form/submit/Submit";
import AddIcon from "@material-ui/icons/Add";
import GenericPageTitle from "modus/components/generic-page-title/GenericPageTitle";
import { ErrorMessage } from "modus/components/error-message/ErrorMessage";
import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import ApplicationService from "../../../../libs/resources/identity/applications/ApplicationService";
import { InputContainer } from "modus/components/form/input-container/InputContainer";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

interface ScopeConfigurationProps {
  application: IdentityApplication;
}

/**
 * Renders the configuration details of an application.
 *
 * @param {Object} props - The component props.
 * @param {IdentityApplication} props.application - The application object.
 * @returns {JSX.Element} - The rendered component.
 * @description  this component dynamically generates a list of configuration details
 * based on the application's responses and the current identity environment,
 * displaying them in a structured and styled manner.
 */
export const ScopeConfiguration: React.FC<ScopeConfigurationProps> = ({
  application,
}) => {
  const identityEnvironment = useSelector(
    (state: any) => state?.common?.identityEnvironment
  );

  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
  });

  const notification = useContext(SnackNotificationContext);
  const [displayAddScope, setDisplayAddScope] = useState(false);

  const [hasFieldError, setHasFieldError] = useState(false); 
  const [hasFieldError2, setHasFieldError2] = useState(false); 

  const handleSetError = (value: boolean) => {
    setHasFieldError(value); 
  };

  const handleSetError2 = (value: boolean) => {
    setHasFieldError2(value); 
  };


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const [state, { subscribe }] = useAsyncAction(() =>
    ApplicationService.addScope(
      consumerKey,
      formValues.name,
      formValues.description
    )
  );

  const consumerKey: string = (() => {
    const gatewayResponses = application.applicationGatewayResponses;
    if (!gatewayResponses || gatewayResponses.length === 0) {
      return "";
    }

    if (gatewayResponses[0]?.organisationName === identityEnvironment) {
      return gatewayResponses[0]?.consumerKey || "";
    } else {
      return gatewayResponses[1]?.consumerKey || "";
    }
  })();

  const displayAddScopeInput = () => {
    setDisplayAddScope(true);
  };

  const addScope = () => {
    if (consumerKey !== "" && !hasFieldError && !hasFieldError2 && formValues.name !== "" && formValues.description !== "") {
      subscribe();
    }
    else {
        notification.show({
            id: "addscopestate-error",
            message: <FormattedMessage defaultMessage="Invalid Input." />,
            timeout: 4000
        })
    }
  };

  useEffect(() => {
    if (!state) {
      return
    }
    if (state.error) {
      notification.show({
        id: "addscopestate-error",
        message: <FormattedMessage defaultMessage="Unknown error occured while creating the scope." />,
        timeout: 4000
    })

    }
    if (state.loaded ) {
      notification.show({
        id: "addscopestate-success",
        message: <FormattedMessage defaultMessage="Scope Added successfully." />,
        timeout: 4000
    })

    // Close the edit component
    setDisplayAddScope(false);

    }
  },[state, notification]);

  const data: any[] = [];

  return (
    <Paper style={{ marginTop: "30px" }}>
      <Box p={"20px"}>
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <GenericPageTitle title={ <Typography variant="body1" style={{ fontWeight: 600 }}>Scopes </Typography>} noSkeleton noDescription />
          <Submit size="medium" variant="contained" color="primary" style={{ marginLeft: "auto" }}
            startIcon={<AddIcon />}
            onClick={() => displayAddScopeInput()}
            disabled={displayAddScope}>
            Add New Scope
          </Submit>
        </Box>

        {displayAddScope && (
          <Box>
            <Grid container spacing={4} style={{maxWidth: "80%"}}>
              <Grid item xs={6}>
                Scope Name
                <Box display="flex" flexDirection="row">
                  <InputContainer value={application.applicationName + ":"} disabled name={"application_name"} inputStyle={{ textAlign: "right" }} />
                  <InputContainer value={formValues.name} onChange={handleChange} name={"name"} placeholder="scope" setError={handleSetError}
                    validate={{ required: true, minLength: 3, maxLength: 100, pattern: "^[a-z]+\\.[a-z]+$" }}
                    required />
                </Box>
              </Grid>
              <Grid item xs={6}>
                Description
                <Box display="flex" flexDirection="row" style={{gap: "24px"}}>
                  <InputContainer value={formValues.description} onChange={handleChange} name={"description"} placeholder="Description" setError={handleSetError2}
                    validate={{ required: true, minLength: 3, maxLength: 100 }}
                    required />
                  <Tooltip enterDelay={1000} title={<FormattedMessage defaultMessage="Add Scope" />}>
                  <IconButton style={{borderColor: '#0063A3', borderStyle: 'solid', height: "50px", borderWidth: '1px', borderRadius: '4px', margin: '7px 0px'}}
                    onClick={addScope}>
                    <AddOutlinedIcon color={"primary"}/>
                    </IconButton>
                    </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box>
          {data.length === 0 && (
            <ErrorMessage message={<Box display="flex" flexDirection="column">Add a Scope</Box>}/>
          )}

          {/* {data.length === 0 &&
            data.map((row) => (
              <Box style={{ border: "0.5px solid #B7B9C3" }}>
                <Grid container spacing={0} key={row.id}>
                  <Grid item xs={6} style={{ border: "0.5px solid #B7B9C3", backgroundColor: "#F1F1F6" }} >
                    <Box padding={"30px 12px"}>
                      <Typography> <b>{row.id}</b> </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} style={{ border: "0.5px solid #B7B9C3" }}>
                  <Box padding={"30px 12px"}>
                      <Typography>{row.name}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))} */}
        </Box>
      </Box>
    </Paper>
  );
};
