import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import { useSelector } from "react-redux";
import ApplicationService from "../../../../libs/resources/identity/applications/ApplicationService";

export const useResourceAppVersionCreator = (applicationId: string) => {

    const [state, { subscribe }] = useAsyncAction(() => ApplicationService.createNewVersion(applicationId));

    const createVersion = () => {
        subscribe();
    }

    return {createVersionState: state, createVersion}
}


