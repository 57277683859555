import { createStyles, makeStyles } from "@material-ui/core/styles";
import type { Theme } from "@material-ui/core/styles";

const useProductStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      width: "100%"
    },
  })
);

export default useProductStyles;
