import React, { FC, useState, useContext } from 'react'
import { FormRenderProps, Field } from 'react-final-form';
import { Button } from '@material-ui/core';
import { useIntl, FormattedMessage } from "react-intl";
import Typography from '@material-ui/core/Typography';
import { ApprovalDecisionInfo, ApprovalApproveRequest, ApprovalDenyRequest } from '../../model/approval.model';
import { AccordionContent } from 'legacy-components/form/components/accordion-form/AccordionForm';
import FormContainer from "legacy-components/form/container/FormContainer";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import { ValidatorBuilderContext } from "legacy-components/form/validator/Validator";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import FormTextInput from "legacy-components/form/input/FormTextInput";
import ApprovalService from "../../ApprovalService";
import { FormApi, FORM_ERROR } from 'final-form';
import { SnackNotificationContext } from 'legacy-components/notifications/GenericSnackNotification';
import FormSelectionGroup from 'legacy-components/form/input/FormSelectionGroup';

interface ApprovalDecisionFormProps {
    onCancel?: () => void,
    onSuccess?: (approvalDecisionInfo?: ApprovalDecisionInfo) => void
    approvalDecisionInfo?: ApprovalDecisionInfo,
    subscription?: string,
    product?: string,
    application?: string,
    canReview?: boolean
}

const ApproveMessage = (props: any) => {
    const message = `You have approved the subscription request for ${props?.product} with application ${props.application}. The subscription provider has been notified.`
    return (
        <span>{message}</span>
    )
}


const DeinedMessage = (props: any) => {
    const message = `You have denied the subscription request for ${props?.product} with application ${props.application}. The subscription provider has been notified.`
    return (
        <span>{message}</span>
    )
}

const ApprovalDecisionForm: FC<ApprovalDecisionFormProps> = ({ subscription, product, application, canReview, approvalDecisionInfo, onSuccess }) => {
    const notificationContext = useContext(SnackNotificationContext);
    const onSubmit = () => (data: ApprovalDecisionInfo, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                if (data.statusType == "APPROVED") {
                    const approveRequest: ApprovalApproveRequest = {
                        reason: data?.reason,
                        resourceName: subscription
                    }
                    ApprovalService.forApproveApproval("SUBSCRIPTION", approveRequest).subscribe(() => {
                        notificationContext.show({
                            id: "approve",
                            message: <ApproveMessage product={product} application={application} />
                        });
                        resolve();
                    }, error => {
                        resolve({
                            [FORM_ERROR]: error
                        });
                    });
                } else {
                    const denyRequest: ApprovalDenyRequest = {
                        reason: data?.reason,
                        resourceName: subscription
                    }
                    ApprovalService.forDenyApproval("SUBSCRIPTION", denyRequest).subscribe(() => {
                        notificationContext.show({
                            id: "deny",
                            message: <DeinedMessage product={product} application={application} />
                        });
                        resolve();
                    }, error => {
                        resolve({
                            [FORM_ERROR]: error
                        });
                    });
                }
            }

        })
    }
    const intl = useIntl();
    const validator = useContext(ValidatorBuilderContext);
    const [decisions] = useState([
        {
            value: "APPROVED",
            title: intl.formatMessage({
                defaultMessage: "Approved"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Indicates that the submitted verification request is approved"
            })
        },
        {
            value: "DENIED",
            title: intl.formatMessage({
                defaultMessage: "Denied"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Indicates that the submitted verification request is denied"
            })
        }
    ]);
    const [] = useState(false);
    const FormComponents = (formRenderProps: FormRenderProps<ApprovalDecisionInfo, {}>) => {
        return (<>
            {(canReview &&
                <FormInputContainer title={
                    <div>
                        <FormattedMessage defaultMessage="Select the decision for this submission request" />
                    </div>
                }>

                    <Field

                        name="statusType"
                        children={FormSelectionGroup}
                        options={decisions}
                        validate={validator.from({
                            required: true
                        })}
                        disabled={approvalDecisionInfo?.reason ? true : false || !canReview}

                    />

                </FormInputContainer>)}

            <FormInputContainer title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Comments / Feedback" />} />}>
                <Field
                    name="reason"
                    type="text"
                    multiline
                    rows={5}
                    component={FormTextInput}
                    disabled={approvalDecisionInfo?.reason ? true : false || !canReview}
                    validate={validator.from({
                        required: true
                    })}
                />
            </FormInputContainer>

            {canReview &&
                <ActionInliner>
                    <Button disabled={(approvalDecisionInfo?.reason ? true : false) || formRenderProps.invalid || formRenderProps.submitting || formRenderProps.pristine} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Submit Decision" /></Button>
                </ActionInliner>
            }

        </>);
    }
    return (
        <AccordionContent size="small">

            <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} prefillValues={approvalDecisionInfo} />
        </AccordionContent>

    )
}


export default ApprovalDecisionForm;