import React from 'react';

import { Route, Redirect, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';

import ProxyList from './proxyList.tsx';
import ProxySummary from './summary/proxySummary';

/**
 * @class ProxyRoutes
 * @description For storing internal page redirection inside API Summary Module
 */
class ProxyRoutes extends React.Component {

     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          return (
               <div className="proxy"  >
                    <Switch>
                         <Route exact path="/home/proxy" render={() => (<Redirect to="/home/proxy/list" />)} />
                         <Route path="/home/proxy/list" render={() => (<ProxyList />)} />
                        
                         <Route path="/home/proxy/:apiName" render={() => (<ProxySummary />)} />
                    </Switch>

               </div>
          );
     }
}

export default withRouter(ProxyRoutes);

/*

 <Route path="/home/proxy/create" render={() => (
                              <ResourceAbility can="create" resource={({ Proxy }) => Proxy.create } fallBack = {fallBackdiv} >
                              <ProxyCreator />
                              </ResourceAbility>
                         )} />

                         */