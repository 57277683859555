import { Chip } from "@material-ui/core";
import React from "react";

export const DeploymentStatus = {
    DEPLOY_COMPLETED :{
        value: "Deployed",
        color: "#4a821f"
    },
    BUILD_INITIATED : {
        value: "Deploying",
        color: "#252a2e"
    },
    BUILD_FAILED : {
        value: "Build Failed",
        color: "#c81922"
    },
    DEPLOY_INITIATED : {
        value: "Deploying",
        color: "#252a2e"
    },
    DEPLOY_FAILED : {
        value: "Deployment Failed",
        color: "#c81922"
    }, 
    UPLOAD_FILE_FAILED : { 
        value: "Upload Failed",
        color: "#c81922"
    },
    PENDING_APPROVAL : {
        value: "Pending Review",
        color: "#0063A3"
    },
    INITIATED:  {
        value: "Initiated",
        color: "#0063A3"
    }
};


export type DeploymentStatusKeyType = keyof typeof DeploymentStatus;

interface DeploymentStatusBadgeProps {
    status: DeploymentStatusKeyType;
}
export const DeploymentStatusBadge:React.FC<DeploymentStatusBadgeProps> = ({status}) => {
    return (
        <Chip label={DeploymentStatus[status]?.value} style={{
            color : "#fff",
            backgroundColor: DeploymentStatus[status]?.color ,
            textTransform: 'uppercase'
        }}/>
    );
}