import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { ConsumerSecret } from "./ConsumerSecretViewer";
import ApplicationConfigurationService, {
  IdentityApplicationSecretInfo,
} from "../services/ApplicationConfigurationService";
import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import { useSelector } from "react-redux";
import Submit from "modus/components/form/submit/Submit";
import AddIcon from "@material-ui/icons/Add";
import { ConsumerSecretCreator } from "./ConsumerSecretCreator";
import { Skeleton } from "@material-ui/lab";
import { ErrorMessage, resolveAPIErrorMessage } from "modus/components/error-message/ErrorMessage";

interface ConsumerSecretListProps {
  appName: string;
}

export const ConsumerSecretList: React.FC<ConsumerSecretListProps> = ({
  appName,
}) => {
  const identityEnvironment = useSelector(
    (state: any) => state?.common?.identityEnvironment
  );
  const [secretMetaList, setSecretMetaList] = useState<IdentityApplicationSecretInfo[]>();

  const [state, { subscribe }] = useAsyncAction(() =>
    ApplicationConfigurationService.getApplicationSecretMetadata(
      identityEnvironment,
      appName
    )
  );

  useEffect(() => {
    if(!state?.value || (secretMetaList && secretMetaList?.length > 0)) {
        return;
    }
    setSecretMetaList(state?.value);
  },[state?.value]);

  useEffect(() => {
    if(!subscribe) {
        return;
    }
    subscribe();
  }, [subscribe]);

  const onCreate = useCallback((secretMeta: IdentityApplicationSecretInfo) => {
    if(!secretMetaList) {
        setSecretMetaList([secretMeta]);
        return;
    }
    const isExists = secretMetaList.filter((secret) => secret.id === secretMeta.id)?.length > 0 ?? false;
    if(isExists) {
        return;
    }
    setSecretMetaList([...secretMetaList, secretMeta]); 
  },[secretMetaList,setSecretMetaList]);
  
  const onDelete = useCallback((secretId : string) => {
    setSecretMetaList(secretMetaList?.filter((secret) => secret.id !== secretId));
  },[setSecretMetaList, secretMetaList]);

  if(state?.loading) {
    return (
      <Box width={`calc(50% + 165px)`} display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column" gridGap={"10px"} marginTop={"30px"}>
        <Skeleton variant="rect" width={"100%"} height={50} />
        <Skeleton variant="rect" width={"100%"} height={50} />
        <Skeleton variant="rect" width={"100%"} height={50} />
      </Box>
    )
  }

  if(state?.error) {
    return (<ErrorMessage message={
      <Box>
        Secret Keys not available for this application
      </Box>} />);
  }
  return (
    <Box display={"flex"} marginTop={"15px"}>
      <Box  width={`calc(50% + 165px)`}>
        <label> Client Secret</label>
        {secretMetaList?.map((secret: IdentityApplicationSecretInfo) => (
          <ConsumerSecret key={secret.id} secretNumber={secret.id} name={appName} hideDelete={secretMetaList?.length <= 1} onDelete={() => onDelete(secret?.id)}/>
        ))}
      </Box>
      <Box display="flex" alignItems="end" marginLeft="15px" marginBottom="8px">
        <ConsumerSecretCreator appName={appName} onCreate={onCreate} disabled={(secretMetaList && secretMetaList?.length >= 5) ?? false}/>
      </Box>
    </Box>
  );
};
