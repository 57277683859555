import React, { memo } from "react";
import { SvgIconProps, SvgIcon, makeStyles, createStyles } from "@material-ui/core";


const useStyles = makeStyles(({ spacing, palette, typography }) =>
    createStyles({
        companyFullLogo : {
            fill: 'inherit',
            width: '125px',
            height: '35px',
            margin: '10px'
        }
    })
);

function CompanyFullLogo(props: SvgIconProps) {

    const classes = useStyles();

    return (
        <SvgIcon {...props}  className={classes.companyFullLogo} viewBox="0 0 95 24">
            <g id="Trimble-Full-logo" stroke="none" strokeWidth="1" fill="inherit" fillRule="evenodd">
                <g id="media-/-logo-/-Trimble-/-blue" transform="translate(1.000000, 1.000000)" fill="inherit">
                    <g id="Group" strokeWidth="1" fillRule="evenodd">
                        <path d="M32.328972,7.53866667 L36.1358255,7.53866667 L36.1358255,9.35733333 L36.1943925,9.35733333 C36.6629283,7.86133333 38.3906542,7.24533333 39.9426791,7.24533333 L39.9426791,11.176 C39.4155763,10.9706667 38.917757,10.8533333 38.3613707,10.8533333 C37.0728972,10.8533333 36.6629283,11.176 36.223676,11.792 L36.223676,19.5946667 L32.3582555,19.5946667 L32.3582555,7.53866667 L32.328972,7.53866667 Z" id="Shape" fillRule="nonzero"></path>
                        <polygon id="Shape" fillRule="nonzero" points="40.9383178 7.53866667 44.8037383 7.53866667 44.8037383 19.5653333 40.9383178 19.5653333"></polygon>
                        <path d="M46.1214953,7.53866667 L49.9869159,7.53866667 L49.9869159,9.152 L50.0454829,9.152 C50.8654206,7.92 52.0660436,7.24533333 53.4716511,7.24533333 C54.6429907,7.24533333 55.8143302,7.97866667 55.9021807,9.152 L55.9607477,9.152 C56.7806854,7.92 57.9813084,7.24533333 59.3869159,7.24533333 C60.9389408,7.24533333 62.0809969,8.096 62.0809969,9.79733333 L62.0809969,19.5653333 L58.2155763,19.5653333 L58.2155763,11.0586667 C58.2155763,10.4133333 58.0105919,9.97333333 57.2785047,9.97333333 C56.8685358,9.97333333 56.5464174,10.1493333 56.282866,10.472 L56.0778816,10.7653333 L56.0778816,19.5653333 L52.2124611,19.5653333 L52.2124611,11.0586667 C52.2124611,10.4133333 52.0074766,9.97333333 51.2753894,9.97333333 C50.8654206,9.97333333 50.5433022,10.1493333 50.2797508,10.472 L50.0747664,10.7653333 L50.0747664,19.5653333 L46.2093458,19.5653333 L46.2093458,7.53866667 L46.1214953,7.53866667 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M63.3694704,2.49333333 L67.234891,2.49333333 L67.234891,8.21333333 L67.2934579,8.21333333 C67.8498442,7.62666667 68.8454829,7.216 69.6361371,7.216 C72.3302181,7.216 73.765109,9.91466667 73.765109,13.376 C73.765109,16.1333333 72.623053,19.6826667 69.3725857,19.6826667 C67.8498442,19.6826667 66.8834891,18.9786667 66.1221184,17.8053333 L65.3900312,19.536 L63.3694704,19.536 L63.3694704,2.49333333 Z M67.234891,15.6933333 C67.176324,16.6906667 67.7619938,17.248 68.494081,17.248 C69.5775701,17.248 69.7239875,16.1626667 69.7239875,13.9333333 L69.7239875,13.024 C69.7239875,10.6186667 69.7239875,9.38666667 68.5233645,9.38666667 C68.0548287,9.38666667 67.6741433,9.68 67.4398754,9.856 L67.234891,10.0613333 L67.234891,15.6933333 Z" id="Shape" fillRule="nonzero"></path>
                        <polygon id="Shape" fillRule="nonzero" points="74.7607477 2.49333333 78.6261682 2.49333333 78.6261682 19.5653333 74.7607477 19.5653333"></polygon>
                        <path d="M83.5457944,14.0506667 C83.5457944,15.1066667 83.7507788,16.896 85.3028037,16.896 C86.2398754,16.896 87.0305296,16.2213333 87.2647975,15.2533333 L89.871028,16.2213333 C89.6953271,16.7786667 88.494704,19.5066667 85.0685358,19.5066667 C81.3495327,19.5066667 79.6803738,16.5146667 79.6803738,13.1706667 C79.6803738,9.768 81.4373832,6.89333333 85.0099688,6.89333333 C88.7582555,6.89333333 90.046729,10.12 90.046729,13.2586667 L90.046729,14.08 L83.5457944,14.08 L83.5457944,14.0506667 Z M86.4155763,11.7333333 C86.3862928,10.6186667 86.2984424,9.29866667 84.9514019,9.29866667 C83.8386293,9.29866667 83.5165109,10.5893333 83.5165109,11.7333333 L86.4155763,11.7333333 Z" id="Shape" fillRule="nonzero"></path>
                        <polygon id="Rectangle-path" fillRule="nonzero" points="40.9090343 2.40533333 44.8037383 2.40533333 44.8037383 5.86666667 40.9090343 5.86666667"></polygon>
                        <polygon id="Shape" fillRule="nonzero" points="21.9919003 2.43466667 33.9981308 2.43466667 33.9981308 5.74933333 30.1619938 5.74933333 30.1619938 19.5653333 25.8573209 19.5653333 25.8573209 5.74933333 21.9919003 5.74933333"></polygon>
                        <path d="M14.2024922,15.2826667 C14.1439252,15.5466667 14.0853583,15.7813333 13.9682243,15.9866667 C13.9389408,16.0746667 13.9096573,16.1333333 13.9096573,16.2213333 C13.7339564,16.3973333 13.6168224,16.4853333 13.5875389,16.5146667 C12.6797508,17.2773333 11.976947,17.6586667 10.8934579,18.0106667 C10.4834891,18.128 9.6635514,18.3626667 8.58006231,18.3333333 L6.91090343,19.2426667 C8.93146417,19.6826667 10.5713396,19.272 11.2448598,19.0666667 C12.4747664,18.656 13.294704,18.216 14.2903427,17.3653333 C14.4953271,17.1893333 15.5495327,16.28 16.2816199,14.8426667 C16.4280374,14.5786667 16.5451713,14.2853333 16.6623053,13.9626667 L14.2024922,15.2826667 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M8.37507788,3.54933333 C8.43364486,3.54933333 8.49221184,3.54933333 8.55077882,3.54933333 C8.66791277,3.54933333 8.78504673,3.54933333 8.8728972,3.54933333 C8.66791277,3.608 8.49221184,3.66666667 8.3165109,3.72533333 L9.80996885,4.51733333 C10.9813084,4.488 12.152648,4.98666667 12.9433022,5.83733333 C12.7676012,5.86666667 12.5626168,5.86666667 12.3869159,5.896 L14.905296,7.24533333 C14.905296,7.216 14.905296,7.18666667 14.905296,7.15733333 C15.0224299,7.216 15.1102804,7.304 15.1688474,7.392 L16.8380062,8.30133333 C16.8087227,8.24266667 16.8087227,8.184 16.7794393,8.12533333 C16.7794393,8.096 16.7501558,8.06666667 16.7208723,8.008 C16.6915888,7.94933333 16.6915888,7.89066667 16.6623053,7.86133333 C16.1937695,6.48266667 15.2566978,5.28 14.0853583,4.34133333 C14.0560748,4.28266667 14.0267913,4.25333333 13.9682243,4.224 C12.1819315,2.816 10.3663551,2.52266667 9.45856698,2.43466667 C9.4,2.43466667 9.37071651,2.43466667 9.31214953,2.43466667 C8.75576324,2.40533333 8.19937695,2.40533333 7.64299065,2.49333333 C7.61370717,2.49333333 7.58442368,2.49333333 7.55514019,2.52266667 C7.32087227,2.552 7.11588785,2.58133333 6.94018692,2.64 C6.79376947,2.66933333 6.64735202,2.69866667 6.53021807,2.75733333 L8.08224299,3.608 C8.17009346,3.54933333 8.25794393,3.54933333 8.37507788,3.54933333 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M1.8741433,13.6986667 C1.63987539,13.112 1.46417445,12.3786667 1.43489097,12.0853333 C1.3470405,11.4106667 1.3470405,11.4106667 1.3470405,10.736 C1.3470405,10.5306667 1.37632399,10.208 1.40560748,9.97333333 C1.49345794,9.44533333 1.58130841,8.85866667 1.93271028,8.06666667 L1.93271028,5.86666667 C1.66915888,6.21866667 1.40560748,6.62933333 1.20062305,7.06933333 C0.585669782,8.30133333 0.439252336,9.18133333 0.32211838,9.82666667 C0.263551402,10.12 0.234267913,10.5013333 0.234267913,10.7653333 C0.234267913,11.4986667 0.234267913,11.4986667 0.32211838,12.232 C0.380685358,12.672 0.614953271,13.6693333 0.937071651,14.3733333 C1.20062305,14.9306667 1.43489097,15.3413333 1.69844237,15.752 C1.72772586,15.8106667 1.75700935,15.84 1.78629283,15.8693333 C1.81557632,15.928 1.8741433,15.9573333 1.90342679,16.016 L1.90342679,13.5813333 C1.90342679,13.6106667 1.8741433,13.64 1.8741433,13.6986667 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M14.4953271,9.70933333 C14.3781931,9.88533333 14.2610592,10.032 14.1439252,10.1786667 C14.5538941,10.9413333 14.8760125,11.704 15.0809969,12.4373333 C15.1688474,12.32 15.2566978,12.2026667 15.3152648,12.0853333 C15.988785,11 16.105919,9.65066667 15.6666667,8.30133333 C15.6373832,8.272 15.6080997,8.21333333 15.5788162,8.15466667 C15.5495327,8.03733333 15.4909657,7.92 15.4323988,7.832 C15.3738318,7.77333333 15.3445483,7.71466667 15.3152648,7.656 C15.2859813,7.568 15.2274143,7.48 15.1395639,7.392 L14.8760125,7.24533333 C15.0517134,8.12533333 14.9345794,8.976 14.4953271,9.70933333 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M7.81869159,3.57866667 C7.7894081,3.57866667 7.76012461,3.57866667 7.73084112,3.57866667 C7.35015576,3.63733333 7.02803738,3.72533333 6.67663551,3.84266667 C6.9694704,3.90133333 7.2623053,3.98933333 7.55514019,4.10666667 C7.7894081,3.96 8.0529595,3.81333333 8.3165109,3.696 L8.0529595,3.54933333 C7.99439252,3.57866667 7.90654206,3.57866667 7.81869159,3.57866667 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M16.6623053,13.9333333 L21.9333333,11.088 L16.8087227,8.30133333 C17.54081,10.56 17.2186916,12.5253333 16.6623053,13.9333333 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M9.42928349,4.54666667 C9.42928349,4.54666667 9.42928349,4.54666667 9.42928349,4.54666667 C9.22429907,4.576 9.01931464,4.63466667 8.81433022,4.69333333 C9.51713396,5.104 10.2492212,5.60266667 10.9520249,6.248 C11.4498442,6.10133333 11.9183801,5.984 12.3576324,5.92533333 L9.78068536,4.54666667 C9.69283489,4.51733333 9.54641745,4.51733333 9.42928349,4.54666667 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M11.9183801,15.3706667 C8.99003115,15.7813333 5.88598131,14.4026667 4.09968847,12.144 C3.42616822,12.9653333 2.9576324,13.7866667 2.78193146,14.52 C2.72336449,14.8133333 2.694081,15.048 2.72336449,15.2826667 C2.84049844,15.4586667 2.9576324,15.6053333 3.13333333,15.7813333 C3.54330218,16.28 4.71464174,17.512 6.67663551,18.0693333 C7.35015576,18.2746667 8.02367601,18.3626667 8.58006231,18.3626667 L14.2024922,15.312 C14.2317757,15.0773333 14.2610592,14.8426667 14.2610592,14.608 C13.5875389,14.96 12.7968847,15.224 11.9183801,15.3706667 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M4.24610592,10.3253333 C4.91962617,9.68 5.68099688,9.03466667 6.50093458,8.47733333 C6.4423676,8.36 6.38380062,8.24266667 6.35451713,8.12533333 C6.35451713,8.12533333 6.35451713,8.12533333 6.35451713,8.12533333 C5.97383178,7.06933333 6.09096573,6.01333333 6.61806854,5.13333333 C6.64735202,5.07466667 6.67663551,5.04533333 6.73520249,4.98666667 C6.12024922,4.81066667 5.56386293,4.752 5.06604361,4.81066667 C4.77320872,5.07466667 4.48037383,5.39733333 4.27538941,5.74933333 C3.86542056,6.424 3.66043614,7.15733333 3.66043614,7.94933333 C3.66043614,8.50666667 3.7482866,9.064 3.95327103,9.62133333 C4.01183801,9.856 4.12897196,10.0906667 4.24610592,10.3253333 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M7.37943925,7.744 C7.40872274,7.77333333 7.40872274,7.832 7.43800623,7.86133333 C8.19937695,7.42133333 8.99003115,7.01066667 9.78068536,6.65866667 C9.10716511,6.13066667 8.43364486,5.69066667 7.7894081,5.368 C7.70155763,5.456 7.61370717,5.57333333 7.55514019,5.66133333 C7.17445483,6.27733333 7.11588785,7.01066667 7.37943925,7.744 C7.37943925,7.744 7.37943925,7.744 7.37943925,7.744 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M11.6548287,10.2666667 C12.035514,10.208 12.3869159,10.0906667 12.6797508,9.944 C12.1233645,9.09333333 11.4498442,8.24266667 10.6884735,7.50933333 C10.5713396,7.568 10.4249221,7.59733333 10.3077882,7.656 C9.51713396,7.97866667 8.75576324,8.36 7.99439252,8.82933333 C8.8728972,9.856 10.3077882,10.472 11.6548287,10.2666667 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M4.39252336,3.63733333 C4.97819315,3.31466667 5.53457944,3.02133333 6.53021807,2.728 L1.93271028,0.234666667 L1.93271028,5.83733333 C2.98691589,4.42933333 4.21682243,3.72533333 4.39252336,3.63733333 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M13.5582555,9.12266667 C13.9096573,8.536 13.9682243,7.80266667 13.7046729,7.06933333 C13.7046729,7.06933333 13.7046729,7.06933333 13.7046729,7.06933333 C13.6753894,7.01066667 13.6461059,6.952 13.6168224,6.89333333 C13.1190031,6.89333333 12.5333333,6.952 11.8598131,7.128 C12.4747664,7.77333333 13.0311526,8.47733333 13.4996885,9.18133333 C13.528972,9.152 13.528972,9.152 13.5582555,9.12266667 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M11.7719626,14.256 C12.6797508,14.1386667 13.4996885,13.816 14.1439252,13.3466667 C13.9682243,12.5546667 13.6753894,11.7333333 13.2361371,10.8826667 C12.8261682,11.1173333 12.3283489,11.2933333 11.8305296,11.352 C10.0735202,11.5866667 8.19937695,10.7653333 7.11588785,9.416 C6.29595016,10.0026667 5.50529595,10.648 4.86105919,11.2933333 C6.38380062,13.376 9.1364486,14.6373333 11.7719626,14.256 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M3.45545171,11.176 C3.25046729,10.7946667 3.04548287,10.4133333 2.89906542,10.0026667 L2.89906542,10.0026667 C2.48909657,8.85866667 2.4305296,7.71466667 2.694081,6.65866667 C2.48909657,6.92266667 2.31339564,7.216 2.16697819,7.568 C2.16697819,7.568 2.16697819,7.568 2.16697819,7.568 C2.07912773,7.744 1.99127726,7.92 1.93271028,8.096 L1.93271028,13.6106667 C2.22554517,12.7893333 2.75264798,11.968 3.45545171,11.176 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M15.6959502,13.4346667 C15.5788162,13.552 15.4616822,13.6986667 15.3445483,13.816 C15.3738318,13.9626667 15.3738318,14.08 15.3738318,14.2266667 C15.4909657,13.9626667 15.5788162,13.6986667 15.6959502,13.4346667 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M2.28411215,16.456 C2.1376947,16.3093333 2.04984424,16.1333333 1.93271028,15.9866667 L1.93271028,21.912 L6.91090343,19.2133333 C6.73520249,19.184 6.53021807,19.1253333 6.35451713,19.0666667 C4.12897196,18.4506667 2.75264798,17.0426667 2.28411215,16.456 Z" id="Shape" fillRule="nonzero"></path>
                    </g>
                    <path d="M89.988162,18.3626667 C89.988162,17.6586667 90.6031153,17.072 91.3352025,17.072 C92.0672897,17.072 92.682243,17.6586667 92.682243,18.3626667 C92.682243,19.096 92.0965732,19.6826667 91.3352025,19.6826667 C90.6031153,19.6826667 89.988162,19.096 89.988162,18.3626667 Z M90.2517134,18.3626667 C90.2517134,18.9493333 90.7202492,19.4186667 91.3352025,19.4186667 C91.9208723,19.4186667 92.4186916,18.9493333 92.4186916,18.3626667 C92.4186916,17.776 91.9208723,17.3066667 91.3352025,17.3066667 C90.7202492,17.3066667 90.2517134,17.776 90.2517134,18.3626667 Z M92.0087227,19.096 L91.7451713,19.096 L91.305919,18.4213333 L91.0423676,18.4213333 L91.0423676,19.096 L90.8080997,19.096 L90.8080997,17.6293333 L91.4523364,17.6293333 C91.5694704,17.6293333 91.7158879,17.6293333 91.8037383,17.688 C91.9208723,17.7466667 91.9794393,17.8933333 91.9794393,18.0106667 C91.9794393,18.304 91.7744548,18.4213333 91.5109034,18.4213333 L92.0087227,19.096 Z M91.305919,18.2453333 C91.5109034,18.2453333 91.8037383,18.2746667 91.8037383,18.0106667 C91.8037383,17.8346667 91.6280374,17.8053333 91.4523364,17.8053333 L91.0716511,17.8053333 L91.0716511,18.2453333 L91.305919,18.2453333 Z" id="Shape" fillRule="nonzero"></path>
                </g>
            </g>
        </SvgIcon>
    );
}

export default memo(CompanyFullLogo);