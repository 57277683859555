import { ReactElement, memo, useMemo } from 'react'
import PropTypes from 'prop-types';
import { ResourceAbiltiyAction, useResourceAbility, ResourceAbilityProps } from './Permission';

interface ResourceAbilityComponentProps {
    can: ResourceAbiltiyAction,
    resource: ResourceAbilityProps["resource"],
    children: ReactElement,
    fallBack?: ReactElement,
    role?: string
}

function ResourceAbility(props: ResourceAbilityComponentProps) {
    const [can, { loaded, intermediate }, ability] = useResourceAbility({
        resource: props.resource,
        role: props.role
    });

    const capability = useMemo(() => can[props.can](), [can, props.can]);

    if ((loaded || intermediate) && ability.valid) {
        return capability ? props.children : (props.fallBack ?? null);
    } else {
        return null;
    }
}

ResourceAbility.propTypes = {
    can: PropTypes.oneOf(["read", "create", "update", "delete"]).isRequired,
    resource: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired
}

export default memo(ResourceAbility)

