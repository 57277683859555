import React, { FC, useState, useEffect, useRef, useCallback } from 'react'
import FormContainer, { FormContainerOptions, FormContainerSubmitHandler } from '../../../../legacy-components/form/container/FormContainer';
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import { FormRenderProps, Field, useFormState, useForm } from 'react-final-form';
import { Button } from '@material-ui/core';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { FORM_ERROR } from 'final-form';
import { FormattedMessage } from 'react-intl';
import { ProductPublishFormData, ProductAboutFormData } from '../models/products.model';
import FormFileInput, { FormFileInputContentProps } from '../../../../legacy-components/form/input/FormFileInput';
import { OnChange } from 'react-final-form-listeners';
import { ProductDocumentFieldRef, AbstractFileInfo } from './document-configs/ProductDocumentField';
import { FieldArray } from 'react-final-form-arrays';
import FormSelectionList from '../../../../legacy-components/form/input/FormSelectionList';
import UnifiedAPIService, { UnifiedAPI } from '../../../../services/UnifiedAPIService';
import PropTypes from "prop-types";
import SpecDocumentationService from '../../../../libs/resources/documentation/DocumentationService';
import ProductDocumentationInfo from './document-configs/ProductDocumentationInfo';
import ProductDocumentFieldInput from './document-configs/ProductDocumentFieldInput';
import { useProductPublishInfo, useProductDocumentationInfo } from './ProductPublishInfo';
import FormConditionalField from '../../../../legacy-components/form/input/FormConditionalField';
import ProductService from '../../../../libs/resources/product/ProductService';
import InlineIconFormatter from 'legacy-components/formatters/InlineIconFormatter';
import FormSelectionGroup from 'legacy-components/form/input/FormSelectionGroup';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useProductDetailInfo } from './ProductDetailInfo';
import { AccordionContent } from 'legacy-components/form/components/accordion-form/AccordionForm';
import FeatureAbility, { Feature } from 'libs/security/authorization/FeatureAbility';
import { sanitizeText } from './FileXSSRemover';
import { useHistory } from 'react-router';

interface ProductPublishFormProps {
    stepperId: string;
}


let productDocFileReader:FileReader = new FileReader();
const ProductPublishForm: FC<ProductPublishFormProps> = ({ stepperId }) => {
    const history = useHistory();
    const [dataFields] = useState(["__documentationInfo", "__publishInfo"]);

    const publishFormRef = useRef({
        formDocumentationFieldMount: true
    });

    const docFieldRef = useRef<ProductDocumentFieldRef>();
    const [sanitizedFile, setSanitizedFile] = useState<File>();
    const [userFile, setUserFile] = useState<File>();
    const [postDocData, setPostDocData] = useState<any>();

    const [docForm, setDocForm] = useState<any>();
    const [scanStepperOption, setScanStepperOption] = useState<any>();
    useEffect(() => {
      if(!userFile) {
          return;
      }
      productDocFileReader.onload = (e) => {
        const sanitizedContent = sanitizeText(e?.target?.result as string) as string;
        // Create a new file with the sanitized content using the original file type and name
        const blob = new Blob([sanitizedContent], { type: userFile?.type });
        const newFile = new File([blob], userFile?.name as string, { type: userFile?.type });
        setSanitizedFile(newFile);
      };
  
      productDocFileReader.readAsText(userFile);
    }, [userFile]);

    
const postDocumentationUpload = async (errorInfo: any, form: any, data: any, resolve: any) => {

    if (!errorInfo[FORM_ERROR].documentation) {
        try {
            const documentationInfo = form.getFieldState("__documentationInfo")?.value;

            if (!documentationInfo?.attached) {
                await ProductService.environments["trimble-pre-prod"].updateSpec({
                    productName: scanStepperOption?.formData?.about?.__productInfo?.name ?? "",
                    openApiSpecId: documentationInfo?.specId ?? ""
                })

                form.mutators.setValue("__documentationInfo", {
                    ...documentationInfo,
                    attached: true
                });
            }

        } catch (error) {
            errorInfo[FORM_ERROR].documentation = (error as any)?.message;
        }
    }

    if (!errorInfo[FORM_ERROR].documentation) {
        try {
            const publishInfo = form.getFieldState("__publishInfo")?.value;

            const canPublish = data.publishToNonProd?.some((value: string) => value === "publishToNonProd");
            const isPublished = publishInfo?.published;
            let approval = data.approvalType == undefined? 'AUTO' : data.approvalType;
            if (canPublish && !isPublished) {
                await UnifiedAPIService.post(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.publish_product_api, {
                        id: scanStepperOption?.formData?.about?.__productInfo?.name,
                        environmentName: "trimble-pre-prod",
                        approvalType: approval
                    }), {
                });

                form.mutators.setValue("__publishInfo", {
                    published: true
                });
            } else if (!canPublish && isPublished) {
                await UnifiedAPIService.delete(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.publish_product_api, {
                        id: scanStepperOption?.formData?.about?.__productInfo?.name,
                        environmentName: "trimble-pre-prod"
                    }), {
                });

                form.mutators.setValue("__publishInfo", {
                    published: false
                });
            }
        } catch (error) {
            errorInfo[FORM_ERROR].publish = (error as any).message;
        }
    }

    if (errorInfo[FORM_ERROR].documentation || errorInfo[FORM_ERROR].publish) {
        resolve(errorInfo);
    } else {
        resolve(true);
    }
};
  const uploadRefinedDocument = useCallback(async () => {
    if(!sanitizedFile || !scanStepperOption ||  !docForm) {
        return;
    }
    docFieldRef.current?.progress(0);

    const response = await SpecDocumentationService.uploadDocument({
        name: scanStepperOption?.formData?.about?.name,
        file: sanitizedFile as File,
        productName: scanStepperOption?.formData?.about?.__productInfo?.name ?? ""
    });

    docFieldRef.current?.progress(100);

    docForm.mutators.setValue("__documentationInfo", {
        created: true,
        loaded: true,
        specId: response.id,
        file: {
            filename: sanitizedFile.name,
            type: sanitizedFile.type
        }
    });

    postDocumentationUpload(postDocData.errorInfo, postDocData.form, postDocData.data, postDocData.resolve);
  },[sanitizedFile, scanStepperOption, docForm, postDocumentationUpload, postDocData]);
  const initiateScanning = (stepperOptions: any, userGivenFile: File, form: any) => {
    setScanStepperOption(stepperOptions);
    setDocForm(form)
    setUserFile(userGivenFile);
};
useEffect(() => {
    if(!sanitizedFile || !uploadRefinedDocument) {
        return;
    }
    uploadRefinedDocument();
},[sanitizedFile, uploadRefinedDocument]);


    const onSubmit: FormContainerSubmitHandler<ProductPublishFormData, {
        about?: ProductAboutFormData
    }> = ({ stepperOptions }) => (data, form) => {
        const errorInfo: {
            [FORM_ERROR]: {
                documentation?: string,
                publish?: string
            }
        } = {
            [FORM_ERROR]: {

            }
        };

        return new Promise(async (resolve) => {
            const formState = form.getState();
            if (formState.pristine) {
                resolve(false);
            } else {
                try {
                    const documentationInfo = form.getFieldState("__documentationInfo")?.value;

                    if (data.documentation?.[0] && (docFieldRef.current?.isPending() || !documentationInfo?.created)) {
                        const fileInfo: AbstractFileInfo = {
                            filename: data.documentation[0].name,
                            type: data.documentation[0].type
                        };

                        form.mutators.setValue("__documentationInfo", {
                            created: false,
                            loaded: false,
                            file: fileInfo
                        });
                        setPostDocData({errorInfo, form, data, resolve: () => {

                            resolve(true);
                            history.push("/home/products");
                        }})
                        initiateScanning(stepperOptions,data.documentation[0], form);
                    }
                } catch (error) {
                    errorInfo[FORM_ERROR].documentation = (error as any).message;
                    docFieldRef.current?.error();
                }
                if(form.getFieldState("__documentationInfo")?.value?.file?.filename) {
                    return;
                }

                if (!errorInfo[FORM_ERROR].documentation) {
                    try {
                        const documentationInfo = form.getFieldState("__documentationInfo")?.value;

                        if (!documentationInfo?.attached) {
                            await ProductService.environments["trimble-pre-prod"].updateSpec({
                                productName: stepperOptions?.formData?.about?.__productInfo?.name ?? "",
                                openApiSpecId: documentationInfo?.specId ?? ""
                            })

                            form.mutators.setValue("__documentationInfo", {
                                ...documentationInfo,
                                attached: true
                            });
                        }

                    } catch (error) {
                        errorInfo[FORM_ERROR].documentation = (error as any).message;
                    }
                }


                if (!errorInfo[FORM_ERROR].documentation) {
                    try {
                        const publishInfo = form.getFieldState("__publishInfo")?.value;

                        const canPublish = data.publishToNonProd?.some(value => value === "publishToNonProd");
                        const isPublished = publishInfo?.published;
                        let approval = data.approvalType === undefined? 'AUTO' : data.approvalType;
                        if (canPublish && !isPublished) {
                            await UnifiedAPIService.post(
                                UnifiedAPI.parseRequestURL(UnifiedAPI.publish_product_api, {
                                    id: stepperOptions?.formData?.about?.__productInfo?.name,
                                    environmentName: "trimble-pre-prod",
                                    approvalType: approval
                                }), {
                            });

                            form.mutators.setValue("__publishInfo", {
                                published: true
                            });
                        } else if (!canPublish && isPublished) {
                            await UnifiedAPIService.delete(
                                UnifiedAPI.parseRequestURL(UnifiedAPI.publish_product_api, {
                                    id: stepperOptions?.formData?.about?.__productInfo?.name,
                                    environmentName: "trimble-pre-prod"
                                }), {
                            });

                            form.mutators.setValue("__publishInfo", {
                                published: false
                            });
                        }
                    } catch (error) {
                        errorInfo[FORM_ERROR].publish = (error as any).message;
                    }
                }

                if (errorInfo[FORM_ERROR].documentation || errorInfo[FORM_ERROR].publish) {
                    resolve(errorInfo);
                } else {
                    resolve(false);
                }
            }
        })
    }

    const FormDocumentationField = () => {
        const formState = useFormState<ProductPublishFormData>();
        const form = useForm<ProductPublishFormData>();
        const { accept, docInfoState: { docInfo }, docInfoDispatch, getMIMEType } = useProductDocumentationInfo();
        const canShowDocs = useCallback((values: string[]) => {
            return Array.isArray(values) ? values?.some(value => value === "publishToNonProd") : false;
        }, []);

        useEffect(() => {
            if (publishFormRef.current.formDocumentationFieldMount) {
                if (formState.initialValues?.["__documentationInfo"]?.specId) {
                    const file = formState.initialValues["__documentationInfo"].file;

                    docInfoDispatch({
                        type: "add",
                        payload: {
                            value: {
                                id: formState.initialValues["__documentationInfo"].specId,
                                fileInfo: file,
                                loaded: formState.initialValues?.["__documentationInfo"].loaded ?? false
                            }
                        }
                    })
                }

                publishFormRef.current.formDocumentationFieldMount = false;
            }
        }, [formState, docInfoDispatch]);

        const { approvalTypes } = useProductDetailInfo();
        let approval = (<>
         {/* <div style={{display: 'none' }}> */}
                <AccordionContent>
                    <FormInputContainer title={
                        <div>
                            <InlineIconFormatter icon={<HelpOutlineOutlinedIcon onClick={() => { }} />}>
                                <FormattedMessage defaultMessage="Select the request approval option for your product" />
                            </InlineIconFormatter>
                        </div>
                    }>

                        <Field
                            name="approvalType"
                            children={FormSelectionGroup}
                            options={approvalTypes}
                            defaultSelectionIndex={0}
                        />
                    </FormInputContainer>
                </AccordionContent>
        </>)

        return (
            <FormConditionalField observe="publishToNonProd" condition={canShowDocs}>
                {approval}
                <FormInputContainer padding="inset">
                    <ProductDocumentationInfo docFieldRef={docFieldRef} docInfo={docInfo} noDocMessage={<FormattedMessage defaultMessage="Add a documentation to publish with this product" />}>
                        <>
                            <Field
                                name="documentation"
                                children={FormFileInput}
                                accept={accept}
                                boxContent={(props: FormFileInputContentProps) => <ProductDocumentFieldInput {...props} docInfo={docInfo[0]} />}
                            />
                            <OnChange name="documentation">
                                {(value: File[]) => {
                                    form.mutators.setValue("__documentationInfo", {
                                        created: false,
                                        loaded: false,
                                        attached: false
                                    });

                                    docInfoDispatch({
                                        type: "set",
                                        payload: {
                                            value: value?.[0] ? [{
                                                id: value[0].name,
                                                fileInfo: {
                                                    filename: value[0].name,
                                                    type: getMIMEType(value[0].name) ?? "",
                                                },
                                                loaded: false
                                            }] : undefined
                                        }
                                    });
                                }}
                            </OnChange>
                        </>
                    </ProductDocumentationInfo>
                </FormInputContainer>
            </FormConditionalField>
        )
    }


    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>, containerOptions: FormContainerOptions) => {
        const { preProdPublishOptions } = useProductPublishInfo();

        return (<>

            <FormInputContainer padding="inset" >
                <FieldArray
                    name="publishToNonProd"
                    children={FormSelectionList}
                    options={preProdPublishOptions}
                    selectControl="switch"
                />
            </FormInputContainer>
            <FormDocumentationField />
            <ActionInliner padding="inset">
                {containerOptions.stepperOptions.navigateBack && <Button onClick={containerOptions.stepperOptions.navigateBack} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Back" /></Button>}

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Complete" /></Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer stepperId={stepperId} dataFields={dataFields} FormProps={{ onSubmit }} children={FormComponents} />;
}

ProductPublishForm.propTypes = {
    stepperId: PropTypes.string.isRequired
}

export default ProductPublishForm;