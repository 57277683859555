import React from 'react';


export type Controller<InputProps, OutputModel> = (props?: InputProps) =>  OutputModel;

export function withViewController<ViewerProps, ControllerPropType>(
    ViewerComponent: React.FC<ViewerProps>,
    controllerHook: (props: ControllerPropType) => ViewerProps
  ) {
    return (props: ControllerPropType): JSX.Element => {
      const viewerProps = controllerHook(props);
      return <ViewerComponent key={1} {...viewerProps} />;
    };
  }