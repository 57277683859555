import React, { FC, useContext, useState } from 'react';
import FormContainer, { FormContainerOptions } from 'legacy-components/form/container/FormContainer';
import FormInputContainer from 'legacy-components/form/container/FormInputContainer';
import { Button, Snackbar } from '@material-ui/core';
import ActionInliner from 'legacy-components/form/action/ActionInliner';
import FormSelectionGroup from 'legacy-components/form/input/FormSelectionGroup';
import { Field, FormRenderProps } from 'react-final-form';
import { useProductDetailInfo } from './ProductDetailInfo';
import { FormattedMessage } from 'react-intl';
import Alert from '@material-ui/lab/Alert';
import ProductService, { IAPIProductPublishedGatway, ProductEnvironmentType } from 'libs/resources/product/ProductService';
import { FORM_ERROR } from 'final-form';
import { SnackNotificationContext } from '../../../../legacy-components/notifications/GenericSnackNotification';
import FeatureAbility, { Feature } from 'libs/security/authorization/FeatureAbility';

interface ProductUpdateFormProps {
    productName: string
    initialValues: any
    stepperId: string
    onSuccess: (data?: any) => void
    published?: IAPIProductPublishedGatway;
}


const ProductUpdateForm: FC<ProductUpdateFormProps> = ({ stepperId, productName, published, initialValues, onSuccess }) => {

    type alertType = "success" | "error" | "warning" | undefined;
    const [apiMessage, setApiMessage] = useState<string | undefined>();
    const [apiMessageType, setApiMessageType] = useState<alertType>();

    const notificationContext = useContext(SnackNotificationContext);
    const NotificationMessage = () => {
        return (
            <FormattedMessage defaultMessage="Product Approval Option Updated" />
        )
    }
    const onSubmit = () => (data: any) => {
        const env: ProductEnvironmentType = published ? published.gatewayName : "trimble-pre-prod";
        return new Promise((resolve) => {
            ProductService.environments[env].updateApprovalType(productName, data.approvalType).then((response) => {
                notificationContext.show({
                    id: "product-approvaltype-update",
                    message: <NotificationMessage />
                });
                onSuccess(response)
            }, error => {
                resolve({
                    [FORM_ERROR]: error
                })
            });

        })
    }
    const { approvalTypes } = useProductDetailInfo();


    let approval = (<>
        <FormInputContainer title={

            <FormattedMessage defaultMessage="Select the request approval option for your product" />

        }>

            <Field
                name="approvalType"
                children={FormSelectionGroup}
                options={approvalTypes}
                defaultSelectionIndex={published?.approvalType === "AUTO" ? 0 : 1}
            />
        </FormInputContainer>
        </>)

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>, containerOptions: FormContainerOptions) => {
        const onSnackBarClose = () => {
            setApiMessage(undefined);
            setApiMessageType(undefined);
        }
        return (<>
            <Snackbar autoHideDuration={5000} open={apiMessage ? true : false} onClose={onSnackBarClose}>
                <Alert severity={apiMessageType}>{apiMessage}</Alert>
            </Snackbar>
            {approval}
            <ActionInliner padding="inset">
                {containerOptions.stepperOptions.navigateBack && <Button onClick={containerOptions.stepperOptions.navigateBack} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Back" /></Button>}

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Update" /></Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer prefillValues={initialValues} stepperId={stepperId} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} previewErrors={true} />;
}


export default ProductUpdateForm;