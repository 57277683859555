import { createStyles, makeStyles } from "@material-ui/core/styles";
import type { Theme } from "@material-ui/core/styles";

const useTypographyStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transition: "all .5s ease-in-out",
    },
    h1: {
      fontWeight: 400, // Regular
      fontSize: "24px",
      textTransform: "capitalize",
      letterSpacing: 0.5,
      lineHeight: "36px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        lineHeight: "30px",
      },
    },
    h2: {
      fontWeight: 400, // Regular
      fontSize: "20px",
      textTransform: "capitalize",
      letterSpacing: 0.15,
      lineHeight: "30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
    h3: {
      fontWeight: 600, // Semibold
      fontSize: "18px",
      textTransform: "capitalize",
      letterSpacing: 0.15,
      lineHeight: "27px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    h4: {
      fontWeight: 600, // Semibold
      fontSize: "16px",
      textTransform: "capitalize",
      letterSpacing: 0.15,
      lineHeight: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "21px",
      },
    },
    h5: {
      fontWeight: 700, // Bold
      fontSize: "14px",
      textTransform: "capitalize",
      letterSpacing: 0.15,
      lineHeight: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "18px",
      },
    },
    h6: {
      fontWeight: 700,
      fontSize: "12px",
      textTransform: "capitalize",
      letterSpacing: 0.15,
      lineHeight: "18px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
        lineHeight: "15px",
      },
    },
    lead: {
      fontWeight: 400,
      fontSize: "20px",
      textTransform: "capitalize",
      letterSpacing: 0.15,
      lineHeight: "40px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
    body: {
      fontWeight: 400,
      fontSize: "14px",
      letterSpacing: 0.15,
      lineHeight: "21px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "18px",
      },
    },
    small: {
      fontWeight: 400,
      fontSize: "12px",
      textTransform: "capitalize",
      letterSpacing: 0.25,
      lineHeight: "18px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "18px",
      },
    },
    mini: {
      fontWeight: 400,
      fontSize: "10px",
      letterSpacing: 0.5,
      lineHeight: "15px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
        lineHeight: "15px",
      },
    },
    regular: {
      fontWeight: 400,
    },
    semiBold: {
      fontWeight: 600,
    },
    bold: {
      fontWeight: 700,
    },
    weight: {},
    left: {
      justifyContent: "flex-start",
    },
    right: {
      justifyContent: "flex-end",
    },
    center: {
      justifyContent: "center",
    },
    upper: {
      textTransform: "uppercase",
    },

    lower: {
      textTransform: "lowercase",
    },
    capitalize: {
      textTransform: "capitalize",
    },
    none: {
      textTransform: "none",
    },
  })
);

export default useTypographyStyle;
