import { APIResource, APIMethod } from "../../../libs/utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../../libs/utils/http/client/HttpClient";
import { apiMethodContext } from "../../../libs/utils/http/builder/UrlConfig";
import { from } from "rxjs";
import { ApprovalSubmitRequest, UserInfo, Approval, ApprovalDenyRequest, ApprovalApproveRequest, ApprovalDelegateRequest } from "./model/approval.model";

const formatError = (error: any, defaultMessage: string) => error?.response?.data?.code !== undefined && error.response.data.message ? error.response.data.message : defaultMessage;

@APIResource(CloudConsoleClient, "/publisher/approvals")
class ApprovalServiceFactory {

    @APIMethod("/submit-request")
    public forSubmitApproval(resourceType: string, submitRequest: ApprovalSubmitRequest) {
        const context = apiMethodContext(arguments);

        return from(new Promise<void>((resolve, reject) => {
            CloudConsoleClient.post<void>(context, {
                "reviewerEmail": submitRequest.reviewerEmail,
                "resourceName": submitRequest.resourceName
            }, {
                params: {
                    type: resourceType
                }
            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while sending verification request")));
        }));
    }

    @APIMethod("/delegate")
    public forDelegateApproval(resourceType: string, delegateRequest: ApprovalDelegateRequest) {
        const context = apiMethodContext(arguments);

        return from(new Promise<void>((resolve, reject) => {
            CloudConsoleClient.put<void>(context, {
                "delegatorEmails": delegateRequest.delegatorEmails,
                "resourceName": delegateRequest.resourceName,
                "requestId": delegateRequest.requestId
            }, {
                params: {
                    type: resourceType
                }
            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while delegating the approval request")));
        }));
    }

    @APIMethod("/deny")
    public forDenyApproval(resourceType: string, denyRequest: ApprovalDenyRequest) {
        const context = apiMethodContext(arguments);

        return from(new Promise<void>((resolve, reject) => {
            CloudConsoleClient.put<void>(context, {
                "reason": denyRequest.reason,
                "additionalFeedback": denyRequest.additionalFeedback,
                "resourceName": denyRequest.resourceName
            }, {
                params: {
                    type: resourceType
                }
            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while denying verification request")));
        }));
    }

    @APIMethod("/approve")
    public forApproveApproval(resourceType: string, approveRequest: ApprovalApproveRequest) {
        const context = apiMethodContext(arguments);

        return from(new Promise<void>((resolve, reject) => {
            CloudConsoleClient.put<void>(context, {
                "reason": approveRequest.reason,
                "additionalFeedback": approveRequest.additionalFeedback,
                "resourceName": approveRequest.resourceName
            }, {
                params: {
                    type: resourceType
                }
            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while approving verification request")));
        }));
    }

    @APIMethod("/reviewers")
    public forReviewers() {
        return from(CloudConsoleClient.get<UserInfo[]>(apiMethodContext(arguments)).then(value => value.data));
    }

    @APIMethod("/requests/:requestId/delegators")
    public forDelegators(requestId: string) {
        return from(CloudConsoleClient.get<UserInfo[]>(apiMethodContext(arguments), {
            params: {
                requestId
            }
        }).then(value => value.data));
    }

    @APIMethod()
    public forApproval(resourceType: string, requestType: string) {
        return from(CloudConsoleClient.get<Approval[]>(apiMethodContext(arguments), {
            params: {
                type: resourceType,
                request: requestType
            }
        }).then(value => value.data));
    }

    @APIMethod("/:proxy")
    public forCurrentApproval(resourceType: string, proxy: string) {
        return from(CloudConsoleClient.get<Approval>(apiMethodContext(arguments), {
            params: {
                type: resourceType,
                proxy
            }
        }).then(value => value.data));
    }

    @APIMethod("/requests/:requestId")
    public forApprovalByRequest(requestId: string, requestType: string) {
        return from(CloudConsoleClient.get<Approval>(apiMethodContext(arguments), {
            params: {
                requestId,
                request: requestType
            }
        }).then(value => value.data));
    }

    @APIMethod("/api/requests/:requestId/business-value/feedback")
    public forBusinessValueFeedback(requestId: string, feedback: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<void>((resolve, reject) => {
            const formData = new FormData();

            if (feedback) {
                formData.append('feedback', feedback);
            }

            CloudConsoleClient.post(context, formData, {
                params: {
                    requestId
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while adding business value feedback")
            );
        }));
    }

    @APIMethod("/api/requests/:requestId/perf-doc/feedback")
    public forPerformanceDocumentationFeedback(requestId: string, feedback: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<void>((resolve, reject) => {
            const formData = new FormData();

            if (feedback) {
                formData.append('feedback', feedback);
            }

            CloudConsoleClient.post(context, formData, {
                params: {
                    requestId
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while adding perf documentation feedback")
            );
        }));
    }

    @APIMethod("/api/requests/:requestId/security-compliance/feedback")
    public forSecurityComplianceFeedback(requestId: string, feedback: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<void>((resolve, reject) => {
            const formData = new FormData();

            if (feedback) {
                formData.append('feedback', feedback);
            }

            CloudConsoleClient.post(context, formData, {
                params: {
                    requestId
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while adding security compliance feedback")
            );
        }));
    }

    @APIMethod("/api/requests/:requestId/support/feedback")
    public forSupportFeedback(requestId: string, feedback: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<void>((resolve, reject) => {
            const formData = new FormData();

            if (feedback) {
                formData.append('feedback', feedback);
            }

            CloudConsoleClient.post(context, formData, {
                params: {
                    requestId
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while adding support feedback")
            );
        }));
    }

    @APIMethod("/subscription/requests/:requestId/app-usecase/feedback")
    public forApplicationUsecaseFeedback(requestId: string, feedback: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<void>((resolve, reject) => {
            const formData = new FormData();

            if (feedback) {
                formData.append('feedback', feedback);
            }

            CloudConsoleClient.post(context, formData, {
                params: {
                    requestId
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while adding app usecase feedback")
            );
        }));
    }

}
const ApprovalService = new ApprovalServiceFactory();

export default ApprovalService;
