import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

interface InlineWrapProps {
    expand: boolean;
    content?: string;
    className?: string
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            fontSize: "inherit",
            whiteSpace: "nowrap",
            lineBreak: "normal",
            wordBreak: "normal",
            display: "inline-block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",

            "&&--expanded": {
                whiteSpace: "normal",
                lineBreak: "anywhere",
                wordBreak: "inherit",
                overflow: "inherit",
                textOverflow: "inherit"
            }
        }
    }),
);

function InlineWrap({ expand, content, className }: InlineWrapProps) {
    const styles = useStyles();

    return (
        <span className={clsx(styles.root, className, {
            [`${styles.root}--expanded`]: expand
        })}>{content}</span>
    )
}

InlineWrap.propTypes = {
    expand: PropTypes.bool.isRequired,
    content: PropTypes.string
}

export default memo(InlineWrap);

