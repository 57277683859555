
import { from } from "rxjs";
import ConsumerClient from "./ConsumerClient";
import "reflect-metadata";
import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { IdentityApplication } from "pages/home/applications/services/ApplicationService";
import { CloudConsoleClient } from "libs/utils/http/client/HttpClient";

export interface IApplicationKey {
  applicationName: string;
  consumerKey: string;
  consumerSecret: Array<string>;
}

@APIResource(CloudConsoleClient, "/publisher/applications")
class PublisherApplicationServiceImpl {
  @APIMethod("/gateway/:gatewayName")
  public getList(gatewayName: string) {
      return from(CloudConsoleClient.get<IdentityApplication[]>(apiMethodContext(arguments),{
        params: {
          gatewayName,
          includeEmailAddress: true
        }
      }).then(value => value.data));
  }

}
export const PublisherApplicationService = new PublisherApplicationServiceImpl();


@APIResource(ConsumerClient, "/applications")
class ApplicationServiceImpl {



  @APIMethod("/:applicationName/gateway/:gatewayName/keys")
  public getApplicationKeys(applicationName: string, gatewayName: string) {
    return from(
      ConsumerClient.get<IApplicationKey>(apiMethodContext(arguments), {
        params: {
          applicationName,
          gatewayName,
        },
      })
    );
  }
}
const ApplicationService = new ApplicationServiceImpl();

export default ApplicationService;
