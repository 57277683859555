import React, { memo, useState } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { useExtendedFieldState } from './FormViewControls';

interface FormTextInputOption {
    key: string,
    value: string
}

interface FormTextInputProps {
    label?: string,
    type?: string,
    placeholder?: string,
    disabled?:boolean;
    input: FieldInputProps<any>,
    meta: FieldMetaState<any>,
    helperText?: string,
    options?: Array<FormTextInputOption>;
    value?: string;
    onChange?:(event?:any)=>void;
}

const FormTextInput = ({ label, type, input, helperText, options, meta,disabled=false, ...custom }: FormTextInputProps) => {
    const { canShowError, fieldHelperText } = useExtendedFieldState(meta, helperText);
    const [isSelect] = useState(Array.isArray(options));

    return (
        <TextField
            className="FormTextInput--root"
            fullWidth
            variant="outlined"
            label={label}
            select={isSelect}
            type={type}
            error={canShowError}
            disabled={disabled}
            helperText={fieldHelperText}
            FormHelperTextProps={{
                className: 'FormTextInput--helperText'
            }}
            {...input}
            {...custom}
        >
            {
                options?.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.value}
                    </MenuItem>
                ))
            }
        </TextField>
    );
};

export default memo(FormTextInput);
