import React, { FC, useState, useCallback, useRef } from "react";
import { Typography, Button } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import ActionInliner from "../../../../legacy-components/form/action/ActionInliner";
import { AssignableCollectionItem } from "../../../../legacy-components/form/collection/AssignableCollection";
import { FormSelectionGridData, FormSelectionGridOptions, FormSelectionGridColumn } from "../../../../legacy-components/form/input/FormSelection";
import { ProductSubscriptionFormGridDataValue } from "../models/products.model";
import { ProductProxyDialogRef, ProxyCollectionType } from "./ProductProxyDialog";
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ResourceAbility from "libs/security/authorization/ResourceAbility";
import { IAPIProductSubscription } from "libs/resources/subscription/SubscriptionService";
import FeatureAbility, { Feature } from "libs/security/authorization/FeatureAbility";

export const ProductSubscriptionTitle: FC<{
    count?: number
}> = ({ count }) => {
    return (
        <Typography variant="h6" children={<FormattedMessage  defaultMessage="Recent Subscriptions"/>} />
    );
};

export const ProductSubscriptionsControls: FC<{
    onViewClick: () => void,
    onExportClick: () => void,

    disabled?: boolean
}> = ({ onViewClick, onExportClick, disabled }) => (
    <ActionInliner>
         <ResourceAbility can="create" resource={({ Product }) => Product.mapping}>
        <Button startIcon={<VisibilityIcon />} disabled={disabled} disableElevation variant="contained" size="small" onClick={onViewClick} color="secondary">
            <FormattedMessage defaultMessage="View All Subscriptions" />
        </Button>
        </ResourceAbility>
        <ResourceAbility can="create" resource={({ Product }) => Product.mapping}>
             <Button startIcon={<GetAppIcon />} disabled={disabled} disableElevation variant="contained" size="small" onClick={onExportClick} color="secondary">
            <FormattedMessage defaultMessage="Export As CSV" />
            </Button>
        </ResourceAbility>
    </ActionInliner>
);


export const createProductSubscriptionGridData = (items: AssignableCollectionItem<IAPIProductSubscription>[]): FormSelectionGridData<ProductSubscriptionFormGridDataValue>[] => {
    return items.map(item => {
        return {
            value: {
                id: item.key,
                applicationName: item.value.applicationName,
                subscriberEmail: item.value.subscriberEmail,
                subscriptionRequestedTime : item.value.subscriptionRequestedTime,
                subscription : item.value,
                subscriptionId : item.value.subscriptionId,
                status : item.value.status? item.value.status : "SUBSCRIBED",
                teamName: item.value.teamName,
                gatewayName: item.value.gatewayName,
                sectorName: item.value.sectorName,
                consumerKey: item.value.consumerKey,
            },
            selection: {
                assign: item.assign,
                assigned: item.assigned
            }
        }
    });
}

interface UseProductProxyGridOptions {
    headerStyle?: FormSelectionGridOptions["headerStyle"]
}

export const useProductSubscriptionGrid = (productName?: string, options?: UseProductProxyGridOptions) => {
    const intl = useIntl();

    const proxyDialogRef = useRef<ProductProxyDialogRef>();

    const [columnsForApigeeSubscription] = useState<FormSelectionGridColumn[]>([
    {
        key: "applicationName",
        title: intl.formatMessage({
            defaultMessage: "Application Name"
        }),
       width : 250
    },
    {
        key: "consumerKey",
        title: intl.formatMessage({
            defaultMessage: "Consumer Key"
        }),
       width : 250
    }, 
    {
        key: "status",
        title: intl.formatMessage({
            defaultMessage: "Status"
        }),
        width : 150,
    },
    {
        key: "teamName",
        title: intl.formatMessage({
            defaultMessage: "Team"
        }),
        width : 150,
    },
    {
        key: "subscriberEmail",
        title: intl.formatMessage({
            defaultMessage: "Subscriber Email"
        }),
        width : 600,
    }]);

    const createGridOptions = (): FormSelectionGridOptions => {
        return {
            headerStyle: options?.headerStyle ?? "tinted",
            columns : columnsForApigeeSubscription,
            messages: {
                nodata: <FormattedMessage defaultMessage="No Subscription available to the product" />
            }
        }
    }

    const [gridOptions] = useState(createGridOptions());
    const [prevGridData, setPrevGridData] = useState<FormSelectionGridData<ProductSubscriptionFormGridDataValue>[]>([]);
    const [gridData, setGridData] = useState<FormSelectionGridData<ProductSubscriptionFormGridDataValue>[]>([]);
    const [disabled] = useState(!productName);

    const onChange = useCallback((items: AssignableCollectionItem<IAPIProductSubscription>[]) => {
        setPrevGridData(gridData);
        setGridData(createProductSubscriptionGridData(items));
    }, [gridData]);

    const revertChanges = useCallback(() => {
        setGridData(prevGridData);
    }, [prevGridData]);

    const commitChanges = useCallback(() => {
        setGridData((values) => {
            return values.filter(value => value.selection.assign).map(value => {
                return {
                    ...value,
                    selection: {
                        assign: true,
                        assigned: true
                    }
                }
            });
        })
    }, []);

    return {
        gridOptions,
        gridData,
        prevGridData,
        onChange,
        proxyDialogRef,
        disabled,
        revertChanges,
        commitChanges
    };
}

