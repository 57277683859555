import React, { FC, memo, forwardRef, PropsWithRef, Ref, useImperativeHandle, useState, useRef, useEffect, useCallback, useReducer, Reducer } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List as MuiList, ListItem, ListItemIcon, ListItemText, makeStyles, createStyles, Divider, Fab, Tooltip, Zoom, useTheme, Typography, IconButton, Slide, Paper, Box, ListItemSecondaryAction } from "@material-ui/core";
import { FormattedMessage } from 'react-intl';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import GenericCheckbox from '../../../../legacy-components/form/input/GenericCheckbox';
import AddIcon from '@material-ui/icons/Add';
import { useAssignableCollection, AssignableCollectionItem } from '../../../../legacy-components/form/collection/AssignableCollection';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import PropTypes from "prop-types";
import { useAsyncAction } from '../../../../legacy-components/form/action/AsyncAction';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import ProxyService, { IAPIProxy } from 'libs/resources/proxy/ProxyService';
import ProxyDetailsForm from '../../proxy/proxy-forms/ProxyDetailsForm';
import CloseIcon from '@material-ui/icons/Close';
import { NEVER } from 'rxjs';
import Skeleton from '@material-ui/lab/Skeleton';
import { useCompactDialog } from '../../../../legacy-components/dialog/UseCompactDialog';
import ProxyVerifiedView from 'pages/home/proxy/summary/ProxyVerifiedView';

export type ProxyCollectionType = AssignableCollectionItem<IAPIProxy>;

interface ProxyCollectionSelectionInfo {
    initialList?: ProxyCollectionType[];
    productName: string;
}

export const useStyles = makeStyles(theme => {
    return createStyles({
        header: {
            position: "relative"
        },
        headerFloatControl: {
            position: "absolute",
            top: -24,
            right: theme.spacing(3)
        },
        listItemIcon: {
            minWidth: theme.spacing(3)
        },
        checkbox: {
            padding: theme.spacing(0)
        },
        floatControl: {
            boxShadow: theme.shadows[2],
            zIndex: 1
        },
        list: {
            minHeight: 420,
            padding: theme.spacing(0)
        },
        dialogContentLayout: {
            width: "100%",
            height: "100%",
            position: "relative"
        },
        dialogContent: {
            padding: theme.spacing(0)
        },
        listRenderer: {
            "& .ReactVirtualized__Grid__innerScrollContainer": {
                margin: theme.spacing(2)
            }
        },
        listView: {
            width: "100%",
            height: "100%",
            position: "relative"
        },
        listCenterView: {
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            "&__content": {
                color: theme.palette.text.secondary,
                textAlign: "center"
            }
        },
        listErrorView: {
            "&__icon": {
                color: theme.palette.text.secondary,
                "&-placeholder": {
                    paddingBottom: theme.spacing(1),
                    textAlign: "center"
                }
            }
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1)
        },
        createForm: {
            padding: theme.spacing(3)
        },
        dialog: {
            overflowX: "hidden"
        },
        bottompad: {
            paddingBottom: theme.spacing(3)
        }
    })
});

export interface ProductProxyDialogRef {
    open(selectionInfo?: ProxyCollectionSelectionInfo): void
}

export interface ProductProxyListRef {
    collection: {
        values: () => ProxyCollectionType[]
    }
}

interface ProductProxyDialogProps {
    onChange?: (items: ProxyCollectionType[]) => void
    ref?: Ref<ProductProxyDialogRef | undefined>
}

interface ProductProxyContentProps {
    onChange?: (items: ProxyCollectionType[]) => void,
    onClose: (cancelled: boolean) => void,
    selectionInfo?: ProxyCollectionSelectionInfo
}

interface ProductProxyListContentProps {
    onChange?: (items: ProxyCollectionType[]) => void,
    onClose: (cancelled: boolean) => void,
    selectionInfo?: ProxyCollectionSelectionInfo,
    onCreateProxy: () => void
}

interface ProductProxyCreateProps {
    onClose: () => void
    onSuccess: (data?: any) => void
    onError?: (error?: any) => void
}

interface ProductProxyListProps {
    ref?: Ref<ProductProxyListRef | undefined>
    selectionInfo?: ProxyCollectionSelectionInfo;
    onCreateProxy: () => void
}

interface ProxyListState {
    collection: ProxyCollectionType[],
    rowCount: number;
    status: "init" | "loading" | "loaded" | "nodata" | "error" | "showList" | null;
    noData?: boolean;
    errorValue?: unknown
}

interface InitAction {
    type: "init",
    payload: {
        status: "init"
    }
}

interface LoadingAction {
    type: "loading",
    payload: {
        status: "loading"
    }
}

interface LoadedAction {
    type: "loaded",
    payload: {
        collection: ProxyCollectionType[],
        status: "loaded"
    }
}

interface NoDataAction {
    type: "nodata",
    payload: {
        status: "nodata"
    }
}

interface ErrorAction {
    type: "error",
    payload: {
        error: unknown,
        status: "error"
    }
}

interface ShowListAction {
    type: "showList",
    payload: {
        status: "showList"
    }
}

type ProxyListAction = InitAction | LoadingAction | LoadedAction | ErrorAction | NoDataAction | ShowListAction;

const initialState: ProxyListState = {
    collection: [],
    rowCount: 0,
    status: null
};

const defaultState: Partial<ProxyListState> = {
    noData: false
};

function proxyListReducer(state: ProxyListState, action: ProxyListAction): ProxyListState {
    switch (action.type) {
        case "showList":
            return {
                ...state,
                ...defaultState,
                status: action.payload.status,
                rowCount: state.collection.length
            }
        case "loaded":
            return {
                ...state,
                ...defaultState,
                status: action.payload.status,
                collection: action.payload.collection
            }
        case "loading": {
            return {
                ...state,
                ...defaultState,
                status: action.payload.status
            }
        }
        case "nodata": {
            return {
                ...state,
                ...defaultState,
                status: action.payload.status,
                noData: true
            }
        }
        case "error": {
            return {
                ...state,
                ...defaultState,
                status: action.payload.status,
                errorValue: action.payload.error
            }
        }
        case "init":
        default: return state;
    }
}

const ProxyList: FC<ProductProxyListProps> = forwardRef(({ selectionInfo, onCreateProxy }, ref) => {
    const theme = useTheme();
    const style = useStyles();

    const [loaderSkeletons] = useState(Array.from(Array(10).keys()));

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const [state, dispatch] = useReducer<Reducer<ProxyListState, ProxyListAction>>(proxyListReducer, {
        ...initialState
    });

    const [proxyList, { subscribe }] = useAsyncAction(() => {
        if (selectionInfo?.productName) {
            return ProxyService.withAssociation(selectionInfo?.productName)
        }

        return NEVER;
    }, {
        mapFn: proxies => {
            return proxies?.map(proxyMap => {
                const compareItem = selectionInfo?.initialList?.find(item => item.key === proxyMap.id);

                return ({
                    key: proxyMap.id,
                    value: proxyMap.value,
                    assign: compareItem?.assign ?? proxyMap.assigned,
                    assigned: proxyMap.assigned
                });
            }) ?? [];
        }
    });

    const { 1: { findIndex: findInCollection, toArray: collectionArray } } = useAssignableCollection(state.collection);

    useImperativeHandle(ref, () => ({
        collection: {
            values: () => collectionArray()
        }
    }));

    useEffect(() => {
        const unsubscriber = subscribe();

        return () => unsubscriber();
    }, [subscribe]);

    useEffect(() => {
        if (proxyList.status === "loading") {
            dispatch({
                type: "loading",
                payload: {
                    status: "loading"
                }
            });
        } else if (proxyList.status === "error") {
            dispatch({
                type: "error",
                payload: {
                    error: proxyList.errorValue,
                    status: "error"
                }
            });
        } else if (proxyList.status === "loaded") {
            dispatch({
                type: "loaded",
                payload: {
                    collection: proxyList.value ?? [],
                    status: "loaded"
                }
            });
        }
    }, [proxyList, proxyList.errorValue]);

    useEffect(() => {
        if (state.status === "loaded") {
            if (state.collection.length < 1) {
                dispatch({
                    type: "nodata",
                    payload: {
                        status: "nodata"
                    }
                });
            } else {
                dispatch({
                    type: "showList",
                    payload: {
                        status: "showList"
                    }
                });
            }
        }
    }, [state]);

    const rowRenderer = ({ style: listStyle, key, index }: ListRowProps) => {
        const source = findInCollection(index);

        return (
            <div key={key} style={listStyle}>
                {source ? (<ListItem dense button onClick={source.toggleHandler}>
                    <ListItemIcon className={style.listItemIcon}>
                        <GenericCheckbox
                            edge="start"
                            checked={source.assign}
                            tabIndex={-1}
                            className={style.checkbox}
                        />
                    </ListItemIcon>
                    <ListItemText primary={source.value.apiName} />
                    <ListItemSecondaryAction>
                        <ProxyVerifiedView proxy={source.value} />
                    </ListItemSecondaryAction>
                </ListItem>) : <div>...</div>}
            </div>
        )
    }

    const noRowsRenderer = () => {
        switch (state.status) {
            case 'error':
                return (
                    <div className={style.listCenterView}>
                        <div className={style.listErrorView}>
                            <div className={`${style.listErrorView}__icon-placeholder`}>
                                <CloudOffIcon fontSize="large" className={`${style.listErrorView}__icon`} />
                            </div>
                            <div className={`${style.listCenterView}__content`}>
                                <Typography>
                                    <FormattedMessage defaultMessage="No Proxy Available" />
                                </Typography>
                            </div>
                        </div>
                    </div>
                );
            case 'nodata':
                return (
                    <div className={style.listView}>
                        <div className={style.listCenterView}>
                            <div className={`${style.listCenterView}__content`}>
                                <Typography>
                                    <FormattedMessage defaultMessage="No proxies available" />
                                </Typography>
                            </div>
                        </div>
                    </div>
                );
            case 'loading':
                return (
                    <div className={style.listView}>
                        <Box width="100%" height="100%" padding="12px">
                            {
                                loaderSkeletons.map(value => {
                                    return (
                                        <Box padding="4px 8px" key={value} height="36px" display="flex" alignContent="center">
                                            <Box>
                                                <Skeleton width={20} height={32} />
                                            </Box>

                                            <Box marginLeft="16px" marginTop="4px">
                                                <Skeleton width={280} height={24} />
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </div>
                );

            default:
                return <></>;
        }
    };

    const renderList = ({ width, height }: {
        width: number,
        height: number
    }) => {
        return <List
            ref="List"
            height={height}
            rowCount={state.rowCount}
            noRowsRenderer={noRowsRenderer}
            rowHeight={36}
            rowRenderer={rowRenderer}
            width={width}
            className={style.listRenderer}
        />
    }

    return (
        <div className={style.dialogContentLayout}>
            <div className={style.headerFloatControl}>
                <Zoom
                    in={true}
                    timeout={transitionDuration}
                    style={{
                        transitionDelay: `${transitionDuration.enter}ms`,
                        transitionTimingFunction: theme.transitions.easing.easeInOut
                    }}
                    unmountOnExit
                >
                    <Tooltip title={<FormattedMessage defaultMessage="Create new Proxy" />}>
                        <span>
                            <Fab onClick={onCreateProxy} variant={state.noData ? "extended" : "round"} className={style.floatControl} size={state.noData ? "large" : "medium"} color="secondary">
                                <AddIcon />
                                {(state.noData) && <FormattedMessage defaultMessage="Create Proxy" />}
                            </Fab>
                        </span>
                    </Tooltip>
                </Zoom>
            </div>
            <DialogContent className={style.dialogContent}>
                <MuiList dense className={style.list}>
                    <AutoSizer>
                        {renderList}
                    </AutoSizer>
                </MuiList>
            </DialogContent>
        </div>
    );
});

const ProxyListContent: FC<ProductProxyListContentProps> = ({ onChange, selectionInfo, onClose, onCreateProxy }) => {
    const style = useStyles();
    const proxyListRef = useRef<ProductProxyListRef>();

    const handleClose = () => {
        if (proxyListRef.current) {
            onChange?.(proxyListRef.current.collection.values());
        }
        onClose(false);
    };

    const handleCancel = () => {
        onClose(true);
    };

    return (
        <div>
            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Add Proxy" />
                </DialogTitle>

                <Divider />
            </div>

            <ProxyList ref={proxyListRef} selectionInfo={selectionInfo} onCreateProxy={onCreateProxy} />
            <DialogActions>
                <ActionInliner>
                    <Button onClick={handleCancel}>
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                    <Button disableElevation onClick={handleClose} color="primary" >
                        <FormattedMessage defaultMessage="Add" />
                    </Button>
                </ActionInliner>
            </DialogActions>
        </div>
    );
}

export const ProxyCreateContent: FC<ProductProxyCreateProps> = ({ onClose, onSuccess, onError }) => {
    const style = useStyles();

    return (
        <div>
            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Create Proxy" />
                </DialogTitle>

                <IconButton className={style.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>

                <Divider />
            </div>
            <div className={style.createForm}>
                <ProxyDetailsForm onCancel={onClose} onSuccess={onSuccess} onError={onError} />
            </div>
        </div>
    )
}

const ProxyDialogContent: FC<ProductProxyContentProps> = ({ onChange, selectionInfo, onClose }) => {
    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const [showProxyCreate, setShowProxyCreate] = useState(false);

    const handleCreateClose = useCallback(() => {
        setShowProxyCreate(false);
    }, []);

    const handleCreateOpen = useCallback(() => {
        setShowProxyCreate(true);
    }, []);

    return showProxyCreate ?
        (<Slide direction="left" timeout={transitionDuration} style={{
            transitionTimingFunction: theme.transitions.easing.easeInOut
        }} in={showProxyCreate} mountOnEnter unmountOnExit>
            <Paper>
                <ProxyCreateContent onClose={handleCreateClose} onSuccess={handleCreateClose} />
            </Paper>
        </Slide>
        ) :
        (
            <ProxyListContent onCreateProxy={handleCreateOpen} onChange={onChange} selectionInfo={selectionInfo} onClose={onClose} />
        );
}

const ProductProxyDialog: FC<PropsWithRef<ProductProxyDialogProps>> = forwardRef(({ onChange }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();
    const [selectionInfo, setSelectionInfo] = useState<ProxyCollectionSelectionInfo | undefined>();

    const onClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleClickOpen = (info?: ProxyCollectionSelectionInfo) => {
        setSelectionInfo(info);
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: info => handleClickOpen(info)
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={onClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            classes={{
                paper: style.dialog
            }}>


            <ProxyDialogContent onChange={onChange} onClose={onClose} selectionInfo={selectionInfo} />
        </Dialog>
    )
});

ProductProxyDialog.propTypes = {
    onChange: PropTypes.func
};

export default memo(ProductProxyDialog);
