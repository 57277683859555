import Axios from "axios";
import { from } from "rxjs";

export interface IAPIShare {
    id: string,
    name: string,
    email: string,
    team: string,
    role: string
}

class ShareServiceFactory {
  
    private get_shared_user_list = "/publisher/api/sharedapi/{proxyName}/users";
    private parsed_url ="";

    httpClient = Axios.create();

    public getSharedUserList(proxyName: string | undefined) {
        this.parsed_url = this.parseRequestURL(this.get_shared_user_list, 
            {proxyName: proxyName});
        return from(this.httpClient.get<IAPIShare[]>(`${this.parsed_url}`).then(value => value.data));
    }

    

    public useToken(token: string) {
        this.httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        this.httpClient.defaults.baseURL = process.env.REACT_APP_UNIFIED_API_URL;
        this.httpClient.defaults.headers.post['Content-Type'] = 'application/json';
    }

    public parseRequestURL = (requestURL: string, request: {
        [key: string]: string | undefined
   }) => {
        Object.keys(request).forEach(key => {
             const value = request[key];
             if (value) {
                  requestURL = requestURL.replace(new RegExp('{' + key + '}', 'g'), value);
             }
        });
        return requestURL;
   }
}

const ShareService = new ShareServiceFactory();

export default ShareService;