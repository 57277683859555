import React, { useCallback, memo, useRef } from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import { FormattedMessage } from 'react-intl';
import { IAPIProxy } from '../../../../libs/resources/proxy/ProxyService';
import ProxyShareDialog, { ProxyShareDialogRef } from './ProxyShareDialog';
import ShareIcon from '@material-ui/icons/Share';
import { ResourceAbility } from 'libs/security/authorization';

interface ProxyShareViewActionProps {
    proxy: IAPIProxy
}

function ProxyShareViewAction({ proxy }: ProxyShareViewActionProps) {
    const shareDialogRef = useRef<ProxyShareDialogRef>();
    const handleClickOpen = useCallback(() => shareDialogRef.current?.open(), []);

    let shareButton;
    if (proxy.apiName && !proxy.isShared) {
        shareButton = (
            <ResourceAbility can="create" resource={({ Proxy }) => Proxy.share}>
                <>
                    <ProxyShareDialog ref={shareDialogRef} proxyName={proxy.apiName} />
                    <Tooltip title={<FormattedMessage defaultMessage="Share" />}>
                        <IconButton onClick={handleClickOpen}>
                            <ShareIcon />
                        </IconButton>
                    </Tooltip>
                </>
            </ResourceAbility>
        );
    }
    return (
        <>
            {shareButton}
        </>
    )
}

export default memo(ProxyShareViewAction);
