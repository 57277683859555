import React from 'react';

import { Route, Redirect, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';

import ProductList from './products';
import ProductCreate from './ProductCreate';
import { ResourceAbility } from 'libs/security/authorization';
import ProductView from './ProductView';
import ProductSubscriptions from './ProductSubscriptions';

const fallBackdiv = <div className="message info">You do not have permission to access the page. Please contact your Team owner.</div>

/**
 * @class ProductRoutes
 * @description For storing internal page redirection inside API Summary Module
 */
class ProductRoutes extends React.Component<any, any> {

    /**
     * @function render
     * @description repaints the component on each change of state and props value
     */
    render() {
        return (
            <Switch>
                <Route exact path="/home/products" render={() => (<Redirect to="/home/products/list" />)} />
                <Route path="/home/products/list" render={() => (
                    <ResourceAbility  can="read" resource={({ Product }) => Product.list} fallBack={fallBackdiv} >
                    <ProductList />
                    </ResourceAbility>
                )} />
                <Route path="/home/products/create" render={() => (
                    <ResourceAbility can="create" resource={({ Product }) => Product.create} fallBack={fallBackdiv} >
                    <ProductCreate />
                    </ResourceAbility>
                )} />
                <Route path="/home/products/:productName/summary" render={() => (
                    <ResourceAbility  can="read" resource={({ Product }) => Product.list} fallBack={fallBackdiv} >
                    <ProductView />
                    </ResourceAbility>
                )} />

                <Route path="/home/products/:productName/subscriptions" render={() => (
                         <ProductSubscriptions />
                )} />
                <Route path="*"  render={() => <Redirect to ="/home/products/list" />}/>
            </Switch>
        );
    }
}

export default withRouter(ProductRoutes);