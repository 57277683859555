import React, { useCallback, useState } from "react";
import type { ReactElement, PropsWithChildren } from "react";
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  IconButton,
  DialogContent,
  useTheme,
  Slide,
  Divider,
  Typography,
} from "@material-ui/core";
import type { DialogProps as MuiDialogProps } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import useDialogStyles from "./Dialog.style";
import { Row } from "../responsive-grid/ResponsiveGrid";

/* eslint-disable-next-line */
export interface DialogProps {
  open: boolean;
  onClose?: () => void;
  title?: string | ReactElement;
  titleSeparator?:boolean;
}

interface DialogTitleProps {
  title: string | JSX.Element;
  handleClose: () => void;
}
export const DialogTitle:React.FC<DialogTitleProps> = ({title, handleClose}) => {
  return (
    <MuiDialogTitle>
            <Row>
              <span style={{ flexGrow: 1, display: "flex" , alignSelf: 'center' }}>
                <Typography variant="h4" style={{fontSize:"16px", fontWeight:500}} >{title}</Typography>
              </span>
              <IconButton size="small" aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Row>
          </MuiDialogTitle>
  );
}
export const Dialog: React.FC<PropsWithChildren<DialogProps>> = ({
  open,
  children,
  title,
  onClose,
  titleSeparator = false
}) => {
  const style = useDialogStyles();
  const [maxWidth] = useState<MuiDialogProps["maxWidth"]>("sm");
  const [scroll] = useState<MuiDialogProps["scroll"]>("paper");

  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);
  return (
    <MuiDialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={open as boolean}
      scroll={scroll}
      onClose={handleClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      classes={{
        paper: style.dialog,
      }}
    >
      <Slide
        direction="left"
        timeout={transitionDuration}
        style={{
          transitionTimingFunction: theme.transitions.easing.easeInOut,
        }}
        in={open}
        mountOnEnter
        unmountOnExit
      >
        <section>
          <MuiDialogTitle>
            <Row>
              <span style={{ flexGrow: 1 , display: "flex" , alignSelf: 'center'}}>
                <Typography variant="h4" style={{fontSize:"16px", fontWeight:500}}>{title}</Typography>
              </span>
              <IconButton size="small" aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Row>
          </MuiDialogTitle>
          {titleSeparator && (<Divider />)}
          <DialogContent>{children}</DialogContent>
        </section>
      </Slide>
    </MuiDialog>
  );
};

export const useDialogProps = () => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  return {
    open,
    toggle,
  };
};

export default Dialog;
