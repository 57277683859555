import { useCallback } from "react";
import { useLocation } from "react-router";

const legacyUrls = [
    // Consumer Flow
    'home/marketplace',

    // Provider Flow
    'home/proxy', 
    'home/products', 
    'home/certificates',
    
    // Team Flow
    'home/teams', 
    'home/approvals'

];
export const newUrls = [
    // Consumer Flow
    'home/applications',  
    'home/consumer',
    'home/subscriptions' 
];

export const useFeatureToggler = () => {
  const location = useLocation();

  const isLegacyFeatureUrl = useCallback(() => {
    return legacyUrls.some(legacyUrl => location.pathname.includes(legacyUrl));
  },[location.pathname]);

  return {
    isLegacyFeatureUrl
  }
}