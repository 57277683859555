import React, { useCallback, useState, FC } from 'react'
import { useTheme, Dialog, Slide, Paper, Tooltip, IconButton } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ProductUpdate } from './ProductUpdateApprovalTypeDialog';
import EditIcon from '@material-ui/icons/Edit'
import { IAPIProductPublishedGatway, IAPIProduct } from 'libs/resources/product/ProductService';

interface ProductApprovalTypeEditProps {
    published?: IAPIProductPublishedGatway;
    product: IAPIProduct,
    onSuccess: (data?: any) => void,
}
export const ProductApprovalTypeEdit: FC<ProductApprovalTypeEditProps> = ({ published, product, onSuccess }) => {

    const [showProductEdit, setProductEdit] = useState(false);


    const handleEditClose = useCallback(() => {
        setProductEdit(false);
    }, []);

    const handleEditUpdate = useCallback((data) => {
        setProductEdit(false);
        onSuccess(data);
    }, []);

    const handleEditOpen = useCallback(() => {

        setProductEdit(true);
    }, []);

    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    return (
        <>
            {published?.status === "SUCCEEDED" &&
                <Tooltip title={<FormattedMessage defaultMessage="Edit configuration" />}>
                    <span>
                        <IconButton onClick={handleEditOpen} size="small" edge="end" disabled={published?.status === "SUCCEEDED" ? false : true}>
                            <EditIcon />
                        </IconButton>
                    </span>
                </Tooltip>}

            <Dialog
                fullWidth={true}
                open={showProductEdit}
                onClose={handleEditClose}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >


                <Slide direction="left" timeout={transitionDuration} style={{
                    transitionTimingFunction: theme.transitions.easing.easeInOut
                }} in={showProductEdit} mountOnEnter unmountOnExit>
                    <Paper>
                        <ProductUpdate product={product} published={published} onClose={handleEditClose} onSuccess={handleEditUpdate} />
                    </Paper>
                </Slide>
            </Dialog>
        </>
    );
}