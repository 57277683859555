import { FormFileInputContentProps } from "../../../../legacy-components/form/input/FormFileInput"
import { Typography, Button, makeStyles, createStyles } from "@material-ui/core"
import NAWrap from "../../../../legacy-components/form/action/NAWrap"
import { FormattedMessage } from "react-intl"

import ActionInliner from "../../../../legacy-components/form/action/ActionInliner"
import React, { memo } from "react";

import { CertificateFileInfo } from "./CertificateInfo";

const useStyles = makeStyles(theme =>
    createStyles({
        documentBoxContent: {
            paddingTop: theme.spacing(1),
            margin: theme.spacing(0, 2),

            "&__action": {
                padding: theme.spacing(3, 2, 0, 2)
            }
        }
    }),
);

interface CertificateFieldInputProps {
    certFile: CertificateFileInfo;
}

const CertificateFieldInput = ({ certFile, clear, openFileDialog }: CertificateFieldInputProps & FormFileInputContentProps) => {
    const styles = useStyles();
    return (

        <div className={styles.documentBoxContent}>
            <Typography variant="body2">
                {

                    <NAWrap value={certFile.name} fallback={<FormattedMessage defaultMessage="Drag a file here or click Add Certificate to add" />}>
                        <FormattedMessage defaultMessage="Drag a file here or click Update Certificate to update" />
                    </NAWrap>
                }
            </Typography>
            <div className={`${styles.documentBoxContent}__action`}>

                <ActionInliner align="center">
                    <Button disableElevation variant="contained" size="small" onClick={openFileDialog} color="secondary">
                        {
                            <NAWrap value={certFile.name} fallback={<FormattedMessage defaultMessage="Add Certificate" />}>
                                <FormattedMessage defaultMessage="Update Certificate" />
                            </NAWrap>
                        }
                    </Button>

                    {
                        <NAWrap value={certFile.name}>
                            <Button disableElevation variant="outlined" size="small" onClick={clear}>
                                <FormattedMessage defaultMessage="Clear" />
                            </Button>
                        </NAWrap>
                    }
                </ActionInliner>
            </div>
        </div>
    )
}

export default memo(CertificateFieldInput);