import React, { memo } from "react";
import { SvgIconProps, SvgIcon} from "@material-ui/core";

function StarTick(props: SvgIconProps) {

    return (
        <SvgIcon {...props}  width="22" height="21" viewBox="0 0 22 21" fill="none" style={{ color: ("#006638")}}>
            <path d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z" fill="inherit"/>
        </SvgIcon>
    );
}

export default memo(StarTick);