
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const ServiceApplicationIcon: React.FC<SvgIconProps> = (props) => {
    return (
      <SvgIcon {...props} viewBox="0 0 27 24" fill="none" data-testid="service-application-icon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.16979 9.95456L8.12395 4.25969L13.0727 1.4025L17.9794 4.291L18.028 9.98529L13.1209 7.09601L8.16979 9.95456Z"
          fill="inherit"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.47712 19.7088L2.43128 14.0139L7.38086 11.1562L7.42906 16.8498L2.47712 19.7088Z"
          fill="inherit"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.6724 11.1056L23.579 13.9941L23.6276 19.6884L18.7206 16.7991L18.6724 11.1056Z"
          fill="inherit"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3368 19.7363L7.43017 16.8478L2.47823 19.7068L7.50542 22.5247L12.3368 19.7363Z"
          fill="inherit"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6283 19.6902L18.7212 16.8009L13.77 19.6595L18.7964 22.4778L23.6283 19.6902Z"
          fill="inherit"
        />
        <path
          d="M13.3578 12.3675C12.8797 12.0364 12.2975 11.8906 11.7198 11.9572C11.142 12.0238 10.6083 12.2984 10.2181 12.7296C9.5882 13.4148 9.40766 14.4461 9.79323 15.3036C9.93711 15.6328 10.1529 15.9256 10.4249 16.1605C10.6968 16.3954 11.0179 16.5663 11.3645 16.6608C11.7112 16.7553 12.0746 16.7709 12.4281 16.7064C12.7816 16.642 13.1161 16.4992 13.4072 16.2885C14.2294 15.6911 14.5847 14.6416 14.2922 13.6671L15.7036 12.6416L16.174 13.2891C16.4327 13.6452 16.9357 13.7248 17.2918 13.4661C17.6479 13.2074 17.7276 12.7044 17.4688 12.3483L16.9984 11.7008C17.3545 11.4421 17.4342 10.9391 17.1755 10.583C16.9168 10.2269 16.4138 10.1473 16.0577 10.406L13.3578 12.3675ZM12.4728 14.9889C12.1168 15.2476 11.6137 15.1679 11.355 14.8118C11.0963 14.4557 11.176 13.9527 11.5321 13.694C11.8882 13.4353 12.3912 13.515 12.6499 13.8711C12.9086 14.2271 12.8289 14.7302 12.4728 14.9889Z"
          fill="inherit"
        />
      </SvgIcon>
    );
  };

  export default ServiceApplicationIcon;