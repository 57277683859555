import React, { ReactNode, memo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import FormInputInliner from './FormInputInliner';
import PropTypes from "prop-types";
import clsx from 'clsx';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme =>
    createStyles({
        content: {
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(1),
            marginBottom: theme.spacing(1.5),
            position: 'relative',
            "& .FormTextInput--helperText": {
                position: "absolute",
                bottom: -1 * theme.spacing(3),
                whiteSpace: "nowrap",
                width: "calc(100% - 14px)",
                textOverflow: "ellipsis",
                overflow: "hidden"
            },

            "& .FormTextInput--root .MuiInputAdornment-root": {           
                "& .MuiSelect-select": {
                    padding: "10px 32px 10px 10px",
                    "&:focus": {
                        backgroundColor: "transparent"
                    }
                }
            },

            "&--has-header": {
                paddingTop: 0
            }
        },
        title: {
            paddingBottom: theme.spacing(1),

            "&--tinted": {
                background: theme.palette.background.default
            }
        },
        insetPadding: {
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        },
        insetPaddingTop: {
            paddingTop: theme.spacing(3),
        },
        insetMarginLeft: {
            marginLeft: theme.spacing(2)
        }
    }),
);

interface InputContainerProps {
    children: ReactNode,
    inline?: boolean,
    padding?: "none" | "inset" | "inset-title" | "inset-content" | "inset-top",
    title?: React.ReactElement,
    controls?: React.ReactElement,
    headerStyle?: "none" | "tinted",
    margin?: "none" | "inset-left",
}

/**
 * @function FormInputContainer
 * @description FormInputContainer provides a consistent look and feels across all the forms.
 */
const FormInputContainer = (props: InputContainerProps) => {
    const style = useStyles();

    return (
        <div className={clsx({
            [style.insetPadding]: props.padding === "inset",
            [style.insetMarginLeft]: props.margin === "inset-left",
        })}>
            {(props.title || props.controls) && <div className={clsx(style.title, {
                [style.insetPadding]: props.padding === "inset-title",
                [style.insetPaddingTop]: props.padding === "inset-top",              
                [`${style.title}--tinted`]: props.headerStyle === "tinted"
            })}>
                <Box display="flex" justifyContent="space-between" alignContent="center">
                    <div>
                        {props.title}
                    </div>

                    <div>
                        {props.controls}
                    </div>
                </Box>
            </div>
            }
            <div className={clsx(style.content, {
                [style.insetPadding]: props.padding === "inset-content",
                [`${style.content}--has-header`]: props.title || props.controls
            })}>{
                    props.inline
                        ? (
                            <FormInputInliner>
                                {props.children}
                            </FormInputInliner>
                        )
                        : props.children
                }
            </div>
        </div>
    );
};

FormInputContainer.propTypes = {
    children: PropTypes.any.isRequired,
    padding: PropTypes.oneOf(["none", "inset", "inset-title", "inset-content", "inset-top"]),
    headerStyle: PropTypes.oneOf(["none", "tinted"]),
    inline: PropTypes.bool,
    margin: PropTypes.oneOf(["none", "inset-left"]),
};


export default memo(FormInputContainer);