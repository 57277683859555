import { Box, IconButton, ListItem, ListItemIcon, ListItemText, Snackbar, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloudRoundedIcon from "@material-ui/icons/CloudRounded";
import { NavLink } from "react-router-dom";
import ProductIcon from "legacy-components/icons/productIcon";
import { Alert } from "@material-ui/lab";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';


export const ProductNavigation: React.FC = () => {
    const canShowNewProducts = process.env.REACT_APP_NEW_PRODUCT_DISCOVERY === 'true';
    const [showNewChangeInfo, setNewChangeInfo] = useState<boolean>(canShowNewProducts?true: false);
    const [isProductFirstHovered, setIsProductFirstHovered] = React.useState(sessionStorage.getItem('isProductFirstHovered') === 'true');

        useEffect(() => {
            if(!setNewChangeInfo) {
                return;
            }
            if(canShowNewProducts && !isProductFirstHovered) {
                setNewChangeInfo(true);
            } else {
                setNewChangeInfo(false);
            }

        },[canShowNewProducts, setNewChangeInfo]);

    const onSnackBarClose = () => {
        setNewChangeInfo(false);
        setIsProductFirstHovered(true);
        sessionStorage.setItem('isProductFirstHovered', 'true'); 
    }
    return (
        <>
            <NavLink
                className="navigation-tab"
                key={"developer-marketplace"}
                activeClassName="active"
                to={'/home/marketplace/developer-portal'}
            >
            <ListItem
            button
            >
            <ListItemIcon>
                <CloudRoundedIcon style={{ color: "#fff" }} />,
            </ListItemIcon>
            <ListItemText
                className="sub-menu-text"
                primary={<Box display="flex"><Box flexGrow={1}>Developer Marketplace</Box>
                        </Box>}
            />
            </ListItem>
            </NavLink>
            <ProductNavLink />
            {canShowNewProducts && (
                <Snackbar style={{zIndex:200, marginTop: "40px"}} anchorOrigin={
                    { vertical: 'top', horizontal: 'right' }
                } autoHideDuration={8000} open={showNewChangeInfo} onClose={onSnackBarClose}>
                    <Alert 
                        icon={<CheckCircleIcon style={{color:"#fff"}}/>}
                        severity={"success"}
                        style={{backgroundColor: "#006638", color: "#fff"}}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={onSnackBarClose}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >Developer Marketplace has moved to <br/> Products</Alert>
                </Snackbar>
            )}
        </>
        
    )
}


const ProductNavLink = () => {
    return (
        <NavLink
                className="navigation-tab"
                key={"consumer-products"}
                activeClassName="active"
                to={'/home/consumer/products'}
            >
                <ListItem
                    button
                    >
                    <ListItemIcon>
                        <ProductIcon style={{ color: "#fff" }} />,
                    </ListItemIcon>
                    <ListItemText
                        className="sub-menu-text"
                        primary={<Box display="flex"><Box flexGrow={1}>Products</Box>
                                </Box>}
                    />
                    </ListItem>
            </NavLink>
    );
}