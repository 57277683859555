import React from "react";
import {  DialogTitle,  DialogContent } from "@material-ui/core";

const ThreatConfigHelper = (props: any) => {

    return (
        <section>
            <DialogTitle>Basic Threat detection</DialogTitle>
            <DialogContent>
                <p>We are currently supporting following threat detection policies</p>
                <ul>
                    <li>
                        <h4>JSON Threat Protection</h4>
                        <p>Minimizes the risk posed by content-level attacks by enabling you to specify limits on various JSON structures, such as arrays and strings.</p>
                        <p>Default JSON Size limit: 50 array elements</p>
                    </li>
                    
                    <li>
                        <h4>XML Threat Protection</h4>
                        <p>Address XML vulnerabilities and minimize attacks on your API. Optionally, detect XML payload attacks based on configured limits. Screen against XML threats using the following approaches:</p>
                        <ul>
                            <li>Validate messages against an XML schema (.xsd)</li>
                            <li>Evaluate message content for specific keywords or patterns to exclude</li>
                            <li>Detect corrupt or malformed messages before those messages are parsed</li>
                        </ul>
                        <p></p>
                    </li>

                    <li>
                        <h4>Regex Threat Protection</h4>
                        <p>Regex patterns filters following set of vulnerabilities</p>
                        <ul>
                            <li>Server side intrusion</li>
                            <li>SQL Injection</li>
                            <li>Javascript injection</li>
                        </ul>
                    </li>
                </ul>
            </DialogContent>
        </section>
    );

}
export default ThreatConfigHelper;


/*











*/