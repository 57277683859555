import React, { memo, useEffect, useState, useCallback, ReactElement } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import clsx from 'clsx';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import LockOpenTwoTone from '@material-ui/icons/LockOpenTwoTone';

interface StatusBadgeStyleOptions {
    align?: "left" | "right" | "center"
}

const useStyles = (options: StatusBadgeStyleOptions) => makeStyles(theme =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: options.align === 'right' ? 'flex-start' : options.align === 'center' ? 'center' : 'flex-end',
            borderRadius: 4,
            overflow: "hidden",
            width: "100%",
            height: "100%",
            minHeight: "22px"
        },
        badge: {
            color: "#fff",
            padding: "4px 6px",
            fontSize: theme.typography.pxToRem(11),
            textTransform: "uppercase",
            fontWeight: theme.typography.fontWeightMedium,
            letterSpacing: 1,
            lineHeight: theme.typography.pxToRem(11),
            border: "transparent 1px solid",

            "&:first-of-type": {
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px"
            },

            "&:last-of-type": {
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px"
            },

            "&__access--0": {
                background: "#99C776"
            },
            "&__access--1": {
                background: "#7BAB56"
            },
            "&__access--2": {
                background: "#689248"
            },
            "&__access--3": {
                background: "#4E6B37"
            },
            "&__access--4": {
                background: "#2c4717"
            },
            "&__no-access--0": {
                background: "#DE777B"
            },
            "&__no-access--1": {
                background: "#D03C43"
            },
            "&__no-access--2": {
                background: "#b40e14"
            },
            "&__no-access--3": {
                background: "#8C0406"
            },
            "&__default--0": {
                background: theme.palette.divider,
                color: theme.palette.text.primary
            },
            "&__info--0": {
                background: theme.palette.info.light,
                color: theme.palette.info.contrastText
            },
            "&__success--0": {
                background: theme.palette.success.light,
                color: theme.palette.success.contrastText
            },
            "&__warn--0": {
                background: theme.palette.warning.light,
                color: theme.palette.warning.contrastText
            },
            "&__primary--0": {
                background: theme.palette.primary.light,
                color: theme.palette.primary.contrastText
            },
            "&__secondary--0": {
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.contrastText
            }
        }
    }),
);

export type StatusBadgeInfo = {
    kind: "default",
    key?: string,
    icon?: ReactElement
} | {
    kind: "access",
    key?: string,
    access?: boolean,
    icon?: ReactElement
}

type StatusInfoView = {
    key: string,
    title: string,
    theme: string,
    variant: number,
    order: number,
    icon?: ReactElement
}

interface StatusBadgeProps {
    align?: "left" | "right" | "center",
    status: string | StatusBadgeInfo | StatusBadgeInfo[];   
}

type StatusBadgeValuesType = {
    kind: "access",
    key: string,
    value: string,
    weight: number
} | {
    kind: "deploy",
    key: string,
    value: string,
    status: "default" | "info" | "primary" | "warn" | "success" | "secondary"
}

const statusBadgeDictonary = new Map<string, StatusBadgeValuesType>([
    ["dev", {
        kind: "access",
        key: "dev",
        value: "DEV",
        weight: 0
    }],
    ["qa", {
        kind: "access",
        key: "qa",
        value: "QA",
        weight: 1
    }],
    ["stage", {
        kind: "access",
        key: "stage",
        value: "STG",
        weight: 2
    }],
    ["trimble-pre-prod", {
        kind: "access",
        key: "trimble-pre-prod",
        value: "PRE-PROD",
        weight: 2
    }],
    ["not-published", {
        kind: "deploy",
        key: "not-published",
        value: "NOT PUBLISHED",
        status: "secondary",
    }],
    ["Yes", {
        kind: "deploy",
        key: "Yes",
        value: "ACTIVE",
        status: "success"
    }],
    ["No", {
        kind: "deploy",
        key: "No",
        value: "INACTIVE",
        status: "warn"
    }],
    ["prod", {
        kind: "access",
        key: "prod",
        value: "PROD",
        weight: 3
    }],
    ["eu-west", {
        kind: "access",
        key: "eu-west",
        value: "IRELAND",
        weight: 4
    }],
    ["trimble-prod", {
        kind: "access",
        key: "trimble-prod",
        value: "PROD",
        weight: 3
    }],
    ["DEPLOY_COMPLETED", {
        kind: "deploy",
        key: "DEPLOY_COMPLETED",
        value: "Deployed",
        status: "success"
    }],
    ["BUILD_INITIATED", {
        kind: "deploy",
        key: "BUILD_INITIATED",
        value: "Deploying",
        status: "secondary"
    }],
    ["BUILD_FAILED", {
        kind: "deploy",
        key: "BUILD_FAILED",
        value: "Build Failed",
        status: "warn"
    }],
    ["DEPLOY_INITIATED", {
        kind: "deploy",
        key: "DEPLOY_INITIATED",
        value: "Deploying",
        status: "secondary"
    }],
    ["DEPLOY_FAILED", {
        kind: "deploy",
        key: "DEPLOY_FAILED",
        value: "Deployment Failed",
        status: "warn"
    }],
    ["UPLOAD_FILE_FAILED", {
        kind: "deploy",
        key: "UPLOAD_FILE_FAILED",
        value: "Upload Failed",
        status: "warn"
    }],
    ["PENDING_APPROVAL", {
        kind: "deploy",
        key: "PENDING_APPROVAL",
        value: "Pending Review",
        status: "default"
    }],
    ["INITIATED", {
        kind: "deploy",
        key: "INITIATED",
        value: "Initiated",
        status: "default"
    }],
    ["SECTOR ADMIN", {
        kind: "deploy",
        key: "SECTOR ADMIN",
        value: "Sector Admin",
        status: "success"
    }],
    ["TEAM ADMIN", {
        kind: "deploy",
        key: "TEAM ADMIN",
        value: "Team Admin",
        status: "success"
    }],
    ["TRIMBLE DEVELOPER", {
        kind: "deploy",
        key: "TRIMBLE DEVELOPER",
        value: "Trimble Developer",
        status: "default"
    }],
    ["RESTRICTED TRIMBLE DEVELOPER", {
        kind: "deploy",
        key: "RESTRICTED TRIMBLE DEVELOPER",
        value: "Restricted Trimble Developer",
        status: "warn"
    }],
    ["TRIMBLE BUSINESS USER", {
        kind: "deploy",
        key: "TRIMBLE BUSINESS USER",
        value: "Trimble Business User",
        status: "info"
    }],
    ["CONSUMER", {
        kind: "deploy",
        key: "CONSUMER",
        value: "Consumer",
        status: "secondary"
    }],
    ["SUPERADMIN", {
        kind: "deploy",
        key: "SUPERADMIN",
        value: "Super Admin",
        status: "primary"
    }],
    ["APPROVED", {
        kind: "deploy",
        key: "APPROVED",
        value: "Approved",
        status: "success"
    }],
    ["DENIED", {
        kind: "deploy",
        key: "DENIED",
        value: "Denied",
        status: "warn"
    }],
    ["AUTO", {
        kind: "deploy",
        key: "AUTO",
        value: "Open",
        status: "success"
    }],
    ["MANUAL", {
        kind: "deploy",
        key: "MANUAL",
        value: "Closed",
        status: "secondary"
    }],
    ["SUBSCRIBED", {
        kind: "deploy",
        key: "SUBSCRIBED",
        value: "SUBSCRIBED",
        status: "success"
    }],
    ["REVOKED", {
        kind: "deploy",
        key: "REVOKED",
        value: "REVOKED",
        status: "warn"
    }],
    ["VERIFIED", {
        kind: "deploy",
        key: "VERIFIED",
        value: "VERIFIED",
        status: "success"
    }],
    ["UNVERIFIED", {
        kind: "deploy",
        key: "UNVERIFIED",
        value: "UNVERIFIED",
        status: "warn"
    }],
    ["PENDING_REVIEW", {
        kind: "deploy",
        key: "PENDING_REVIEW",
        value: "Pending Review",
        status: "default"
    }],
    ["expiring", {
        kind: "deploy",
        key: "PENDING_REVIEW",
        value: "Pending Review",
        status: "default"
    }],
    ["EXPIRED", {
        kind: "deploy",
        key: "EXPIRED",
        value: "Expired",
        status: "warn"
    }],
    ["EXPIRING", {
        kind: "deploy",
        key: "EXPIRING",
        value: "Expiring In",
        status: "default"
    }],
    ["EXPIRING_TOMO", {
        kind: "deploy",
        key: "EXPIRING_TOMO",
        value: "Expiring Tomorrow",
        status: "primary"
    }],
    ["EXPIRING_TODAY", {
        kind: "deploy",
        key: "EXPIRING",
        value: "Expiring Today",
        status: "secondary"
    }],
    ["EXPIRED_TODAY", {
        kind: "deploy",
        key: "EXPIRING",
        value: "Expired Today",
        status: "secondary"
    }]
]);

const StatusBadgeComponent = (props: StatusBadgeProps) => {
    const style = useStyles({
        align: props.align
    })();

    const [status, setStatus] = useState<StatusInfoView[]>([]);

    const buildBadge = useCallback((badge: StatusBadgeValuesType, badgeInfo: StatusBadgeInfo) => {
        if (badge.kind === "access" && badgeInfo.kind === "access") {
            return {
                key: badge.key,
                theme: badgeInfo.access ?? true ? "access" : "no-access",
                title: badge.value,
                variant: badge.weight,
                order: badge.weight,
                icon: badgeInfo.icon
            }
        } else {
            return {
                key: badge.key,
                theme: badge.kind === "deploy" ? badge.status : "default",
                title: badge.value,
                variant: 0,
                order: 0,
                icon: badgeInfo.icon
            }
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(props.status)) {
            const status = props.status.reduce((agg: StatusInfoView[], value) => {
                const badge = value.key ? statusBadgeDictonary.get(value.key) : null;

                if (badge) {
                    agg.push(buildBadge(badge, value))
                }

                return agg;
            }, []);

            setStatus(status.sort((comparer, value) => comparer.order - value.order));

        } else if (typeof props.status === "object") {
            const badge = props.status.key ? statusBadgeDictonary.get(props.status.key) : null;

            setStatus(badge ? [buildBadge(badge, props.status)] : []);
        } else if (typeof props.status === "string") {
            const badge = statusBadgeDictonary.get(props.status);

            setStatus(badge ? [buildBadge(badge, {
                kind: "default",
                key: props.status
            })] : []);
        }
    }, [props.status, buildBadge])

    return (
        <div className={clsx(style.root)}>{
            status.map(info => {
                return <div key={info.key} className={clsx(style.badge, `${style.badge}__${info.theme}--${info.variant}`)}>
                    {info.title}
                    {info.icon}
                </div>
            })
        }</div>
    );
};

const statusInfoShape = PropTypes.oneOfType([PropTypes.shape({
    key: PropTypes.string.isRequired,
    access: PropTypes.bool
})]);

StatusBadgeComponent.propTypes = {
    align: PropTypes.oneOf(["left", "right", "center"]),
    status: PropTypes.oneOfType([PropTypes.string, statusInfoShape, PropTypes.arrayOf(statusInfoShape)]).isRequired
};

export const StatusBadge = memo(StatusBadgeComponent);
