import React, { FC, useState, useContext } from "react";
import { FormRenderProps, Field } from "react-final-form";
import { Button, InputAdornment, IconButton } from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  ProxyVerificationSecuritySovereigntyInfo,
  ProxyVerificationSecurityInfo,
} from "../models/verification.model";
import FormContainer from "../../../../../../legacy-components/form/container/FormContainer";
import FormInputContainer from "../../../../../../legacy-components/form/container/FormInputContainer";
import { ValidatorBuilderContext } from "../../../../../../legacy-components/form/validator/Validator";
import ActionInliner from "../../../../../../legacy-components/form/action/ActionInliner";
import ProxyVerificationService from "../ProxyVerificationService";
import { FormApi, FORM_ERROR } from "final-form";
import { SnackNotificationContext } from "../../../../../../legacy-components/notifications/GenericSnackNotification";
import FormSelectionGroup from "legacy-components/form/input/FormSelectionGroup";
import AlertService from "legacy-components/alert/AlertService";
import Alert from "legacy-components/alert/Alert";
import FormTextInput from "legacy-components/form/input/FormTextInput";
import { FieldArray } from "react-final-form-arrays";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

interface SecurityComplianceSovereigntyFormProps {
  onCancel?: () => void;
  onSuccess?: (
    proxyVerificationSecuritySovereigntyInfo: ProxyVerificationSecuritySovereigntyInfo
  ) => void;
  proxyVerificationSecuritySovereigntyInfo?: ProxyVerificationSecuritySovereigntyInfo;
  proxy: string;
}

const NotificationMessage = () => {
  return (
    <FormattedMessage defaultMessage="Security Compliance Data Sovereignty Saved Successfully" />
  );
};

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    accordionSummary: {
      padding: "10px 15px",
      margin: 0,
      "&.Mui-expanded": {
        margin: " 0 !important",
        padding: "0 15px",
      },
    },
    accordionDetails: {
      padding: 0,
    },
    root: {
      padding: spacing(2),
      textAlign: "center",
      width: "100%",
    },
    noConfigMessage: {
      color: palette.text.secondary,
    },
    noConfigAction: {
      padding: spacing(3, 0),
    },
    actions: {
      padding: spacing(0, 0, 3, 0),
    },
    content: {
      padding: spacing(3),
      overflow: "auto",
    },
    divider: {
      marginLeft: spacing(2),
    },
    textField1: {
      marginBottom: spacing(4),
      width: "100%",
    },
    button1: {
      float: "left",
    },
  })
);

const SecurityComplianceSovereigntyForm: FC<SecurityComplianceSovereigntyFormProps> =
  ({ proxy, proxyVerificationSecuritySovereigntyInfo, onSuccess }) => {
    const notificationContext = useContext(SnackNotificationContext);

    const onSubmit =
      () => (data: ProxyVerificationSecuritySovereigntyInfo, form: FormApi) => {
        return new Promise((resolve) => {
          const formState = form.getState();
          if (formState.pristine) {
            resolve();
          } else {
            const securityComplianceInfo: ProxyVerificationSecurityInfo = {
              securityDataSovereignty:
                data?.securityDataSovereignty === undefined
                  ? proxyVerificationSecuritySovereigntyInfo?.securityDataSovereignty
                  : data?.securityDataSovereignty,
              dataSovereigntyRegion:
                data?.dataSovereigntyRegion === undefined
                  ? proxyVerificationSecuritySovereigntyInfo?.dataSovereigntyRegion
                  : data?.dataSovereigntyRegion,
            };
            ProxyVerificationService.forSecurityCompliance(
              proxy,
              "sovereignty",
              securityComplianceInfo
            ).subscribe(
              () => {
                notificationContext.show({
                  id: "security-compliance",
                  message: <NotificationMessage />,
                });
                resolve();
              },
              (error) => {
                resolve({
                  [FORM_ERROR]: error,
                });
              }
            );
          }
        });
      };
    const intl = useIntl();
    const validator = useContext(ValidatorBuilderContext);
    const classes = useStyles();
    const [choices] = useState([
      {
        value: true,
        title: intl.formatMessage({
          defaultMessage: "Yes",
        }),
      },
      {
        value: false,
        title: intl.formatMessage({
          defaultMessage: "No",
        }),
      },
    ]);

    const validate = (values: ProxyVerificationSecuritySovereigntyInfo) => {
      if (values.securityDataSovereignty === null)
        AlertService.error({
          message:
            "Please fill out the mandatory fields. \n The following fields are required",
          isAutoHide: false,
          id: "security-compliance",
        });
    };
    const FormComponents = (
      formRenderProps: FormRenderProps<
        ProxyVerificationSecuritySovereigntyInfo,
        {}
      >
    ) => {
      let formState = formRenderProps.form.getState();
      if (formState.submitFailed) {
        validate(formState.values);
      }
      return (
        <>
          <div>
            <Alert id="security-compliance" />
          </div>

          <FormInputContainer
            title={
              <React.Fragment>
                <Typography
                  variant="subtitle2"
                  children={
                    <FormattedMessage defaultMessage="Does your API Proxy have the ability to transfer or replicate data to more than one region? *" />
                  }
                />
              </React.Fragment>
            }
          >
            <>
              <Field
                name="securityDataSovereignty"
                children={FormSelectionGroup}
                options={choices}
              />
            </>
          </FormInputContainer>

          {formState.values &&
            formState.values.securityDataSovereignty === true && (
              <FormInputContainer
                title={
                  <Typography
                    variant="subtitle2"
                    children={
                      <FormattedMessage defaultMessage="Which regions do you support? *" />
                    }
                  />
                }
              >
                <FieldArray name="dataSovereigntyRegion">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div key={name}>
                        <Field
                          type="text"
                          component={FormTextInput}
                          className={classes.textField1}
                          name={name}
                          validate={validator.from({
                            required: true,
                            maxLength: 100
                          })}
                          placeholder={intl.formatMessage({
                            defaultMessage: "e.g. US West or us-west",
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="medium"
                                  onClick={() => fields.remove(index)}
                                >
                                  <HighlightOffIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    ))
                  }
                </FieldArray>
                <Button
                  onClick={() =>
                    formRenderProps.form.mutators.push(
                      "dataSovereigntyRegion",
                      undefined
                    )
                  }
                  className={classes.button1}
                  disabled={formRenderProps.submitting}
                  type="button"
                  variant="outlined"
                  disableElevation
                >
                  {" "}
                  <FormattedMessage defaultMessage="Add More Regions" />
                </Button>
              </FormInputContainer>
            )}

          <ActionInliner>
            <Button
              disabled={formRenderProps.submitting || formRenderProps.pristine}
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
            >
              {" "}
              <FormattedMessage defaultMessage="Save" />
            </Button>
          </ActionInliner>
        </>
      );
    };
    return (
      <FormContainer
        previewErrors={true}
        FormProps={{
          onSubmit,
          initialValues: {
            securityDataSovereignty:
              proxyVerificationSecuritySovereigntyInfo?.securityDataSovereignty,
            dataSovereigntyRegion:
              proxyVerificationSecuritySovereigntyInfo?.dataSovereigntyRegion
                ? proxyVerificationSecuritySovereigntyInfo.dataSovereigntyRegion
                    .length > 0
                  ? proxyVerificationSecuritySovereigntyInfo.dataSovereigntyRegion
                  : [""]
                : [""],
          },
        }}
        onSubmitSuccess={onSuccess}
        children={FormComponents}
        prefillValues={proxyVerificationSecuritySovereigntyInfo}
      />
    );
  };

export default SecurityComplianceSovereigntyForm;
