import React, { FC, memo, ReactElement } from 'react'
import FormContainer from '../../../../../legacy-components/form/container/FormContainer';
import { FormRenderProps, Field } from 'react-final-form';
import { Button, makeStyles, createStyles } from '@material-ui/core';
import ActionInliner from '../../../../../legacy-components/form/action/ActionInliner';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { WarnButton } from '../../../../../legacy-components/form/action/WarnButton';
import { ProductDocumentDeleteFormData } from '../../models/products.model';
import { ProductDocumentFieldFileInfo } from './ProductDocumentField';
import { FORM_ERROR } from 'final-form';
import SpecDocumentationService from '../../../../../libs/resources/documentation/DocumentationService';

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            padding: spacing(3)
        },
    }),
);

interface ProductDocumentDeleteFormProps {
    onCancel: () => void,
    onSuccess: (data: ProductDocumentDeleteFormData) => void
    docInfo: ProductDocumentFieldFileInfo,
    formContent?: ReactElement,
    productName: string;
}

const ProductDocumentDeleteForm: FC<ProductDocumentDeleteFormProps> = ({ onCancel, onSuccess, docInfo, formContent }) => {
    const styles = useStyles();

    const onSubmit = () => () => {
        return new Promise(async (resolve) => {
            if (docInfo?.openApiSpecId) {
                try {
                    await SpecDocumentationService.delete(docInfo.openApiSpecId);
                    resolve();
                } catch (error) {
                    resolve({
                        [FORM_ERROR]: error
                    });
                }
            }
        });
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        return (
            <div className={styles.root}>
                {formContent}

                <Field
                    name="fileId"
                    type="hidden"
                    component="input"
                />

                <ActionInliner padding="inset-top">
                    <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                    <WarnButton disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation>
                        <FormattedMessage defaultMessage="Delete" />
                    </WarnButton>
                </ActionInliner>
            </div>);
    }

    return <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

ProductDocumentDeleteForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    docInfo: PropTypes.any.isRequired
}

export default memo(ProductDocumentDeleteForm);