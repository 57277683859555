import React, { useEffect, useContext } from "react";
import { IdentityApplicationViewState } from "libs/resources/identity/applications/application-views/IdentityApplicationInfo";
import { Container, Box, Typography } from "@material-ui/core";
import CallbackLogoutConfiguration from "../../components/CallbackLogoutConfiguration";
import { AppConfiguration } from '../../components/AppConfiguration';
import DataRemover from "modus/components/misc/DataRemover";
import { Alert } from "@material-ui/lab";
import ApplicationService from "../../services/ApplicationService";
import { useHistory } from "react-router";
import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import { EnvironmentType } from "header/environment-selector/ENV_CONSTANTS";
import { useSelector } from "react-redux";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import { FormattedMessage } from "react-intl";
import { ApplicationRemover } from "./ApplicationRemover";

interface ApplicationOverviewTabProps {
  state: IdentityApplicationViewState; 
}

const ApplicationOverviewTab: React.FC<ApplicationOverviewTabProps> = ({ state }) => {


  
  return (
    <Container maxWidth={false} style={{ padding: "0px" }}>
      <Box>
      {state.application && (<AppConfiguration application={state.application}/>)}
      </Box>
      <Box style={{ padding: "20px 0px" }}>
        <Typography
          style={{ fontSize: "16px", fontWeight: 600, lineHeight: "24px" }}
        >
          Sign in and Sign Out Configuration
        </Typography>
        <Typography style={{ fontSize: "14px", fontWeight: 400, lineHeight: "24px"  }}>
          These settings only apply to the Authorization Code Grant workflow.
        </Typography>
      </Box>
      <CallbackLogoutConfiguration state={state} />
      <Box style={{paddingTop: "20px", display: 'flex', justifyContent: 'flex-end'}}>
      {state?.application && (<ApplicationRemover application={state.application}/>)}
      </Box>
    </Container>
  );
};

export default ApplicationOverviewTab;
