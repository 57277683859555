import React from "react";
import { IdentityApplicationViewState } from "libs/resources/identity/applications/application-views/IdentityApplicationInfo";
import { Container, Box, } from "@material-ui/core";
import { ScopeConfiguration } from "../../components/ScopeConfiguration";

interface ResourceServerConfigProps {
  state: IdentityApplicationViewState; 
}

const ResourceServerConfig: React.FC<ResourceServerConfigProps> = ({ state }) => {

  return (
    <Container maxWidth={false} style={{ padding: "0px" }}>
      <Box>
      {state.application && (<ScopeConfiguration application={state.application}/>)}
      </Box>
    </Container>
  );
};

export default ResourceServerConfig;
