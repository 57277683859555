import React, { memo } from "react";
import { SvgIconProps, SvgIcon, makeStyles, createStyles } from "@material-ui/core";


const useStyles = makeStyles(({ spacing, palette, typography }) =>
    createStyles({
        productIcon: {
            width: '24px',
            height: '24px'
        }
    })
);

function ProductIcon(props: SvgIconProps) {

    //const classes = useStyles();

    return (
<SvgIcon width="21" height="24" viewBox="0 0 21 24" style={{fill:"inherit"}} >
    <path fillRule="evenodd" clipRule="evenodd" d="M5.49414 2.76828L10.3841 0L15.2749 2.76828L10.3841 5.53656L5.49414 2.76828Z" style={{fill:"inherit"}}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.6665 12.2193L15.8257 15.0725L15.9241 20.7148L20.7617 17.7237L20.6665 12.2193Z" style={{fill:"inherit"}}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.91924 20.806L0.0292969 18.035V12.3945L4.91924 15.1628V20.806Z" style={{fill:"inherit"}}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.88994 14.378L0 11.6071V5.9675L4.88994 3.19922L9.78078 5.9675L4.88994 8.73578V14.378Z" style={{fill:"inherit"}}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.0566 5.91281L15.9466 3.14453L20.8374 5.91281L15.9466 8.68109L11.0566 5.91281Z" style={{fill:"inherit"}}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4192 17.5069L5.5293 14.736V9.09551L10.4192 6.32812L15.3092 9.09551L10.4192 11.8638V17.5069Z" style={{fill:"inherit"}}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.3079 9.09375L10.418 11.862V17.5052L15.307 14.599L15.3079 9.09375Z" style={{fill:"inherit"}}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4192 24.0013L5.5293 21.2303V15.5898L10.4192 18.3581V24.0013Z" style={{fill:"inherit"}}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.3099 15.5898L10.4199 18.3581V24.0013L15.309 21.0951L15.3099 15.5898Z" style={{fill:"inherit"}}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.8361 5.91016L15.9453 8.67844V14.3207L20.8344 11.4154L20.8361 5.91016Z" style={{fill:"inherit"}}/>
 </SvgIcon>

    );
}

export default memo(ProductIcon);

/*<SvgIcon {...props} viewBox="0 0 24 24" className={classes.productIcon}>
            <path fill="currentColor" d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z" />
        </SvgIcon>*/