

import React, { memo } from "react";
import { SvgIconProps, SvgIcon, makeStyles, createStyles } from "@material-ui/core";


const useStyles = makeStyles(({ spacing, palette, typography }) =>
    createStyles({
        landingCurve : {
            width: 'inherit',
            height: 'auto',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 5
        }
    })
);

function LandingCurve(props: SvgIconProps) {

    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 612 96" className={classes.landingCurve}>
            <path fill="#f5f5f5" d="M-2.5 46.5C100.25 79.83 203 96.495 305.75 96.495S511.25 79.83 614 46.5V97H-2.5V46.5z"/>
        </SvgIcon>
    );
}

export default memo(LandingCurve);
