import { FormFileInputContentProps } from "../../../../../legacy-components/form/input/FormFileInput"
import { Typography, Button, makeStyles, createStyles } from "@material-ui/core"
import NAWrap from "../../../../../legacy-components/form/action/NAWrap"
import { FormattedMessage } from "react-intl"
import ActionInliner from "../../../../../legacy-components/form/action/ActionInliner"
import React, { memo } from "react";
import { ProductDocumentFieldFileInfo } from "./ProductDocumentField"

const useStyles = makeStyles(theme =>
    createStyles({
        documentBoxContent: {
            paddingTop: theme.spacing(1),
            margin: theme.spacing(0, 2),

            "&__action": {
                padding: theme.spacing(3, 2, 0, 2)
            }
        }
    }),
);

interface ProductDocumentFieldInputProps {
    docInfo?: ProductDocumentFieldFileInfo,
    multiple?: boolean
}

const ProductDocumentFieldInput = ({ docInfo, clear, openFileDialog, multiple }: ProductDocumentFieldInputProps & FormFileInputContentProps) => {
    const styles = useStyles();

    return (
        <div className={styles.documentBoxContent}>
            <Typography variant="body2">
                {
                    multiple ? <FormattedMessage defaultMessage="Drag files here or click Add Documents to add" /> : <NAWrap value={docInfo?.fileInfo} fallback={<FormattedMessage defaultMessage="Drag a file here or click Add Document to add" />}>
                        <FormattedMessage defaultMessage="Drag a file here or click Update Document to update" />
                    </NAWrap>
                }
            </Typography>
            <div className={`${styles.documentBoxContent}__action`}>

                <ActionInliner align="center">
                    <Button disableElevation variant="contained" size="small" onClick={openFileDialog} color="secondary">
                        {
                            multiple ? <FormattedMessage defaultMessage="Add Documents" /> : <NAWrap value={docInfo?.fileInfo} fallback={<FormattedMessage defaultMessage="Add Document" />}>
                                <FormattedMessage defaultMessage="Update Document" />
                            </NAWrap>
                        }
                    </Button>

                    {
                        !multiple && <NAWrap value={docInfo?.fileInfo}>
                            <Button disableElevation variant="outlined" size="small" onClick={clear}>
                                <FormattedMessage defaultMessage="Clear" />
                            </Button>
                        </NAWrap>
                    }
                </ActionInliner>
            </div>
        </div>
    )
}

export default memo(ProductDocumentFieldInput);