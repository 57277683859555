import React, { memo } from "react";
import { SvgIconProps, SvgIcon, makeStyles, createStyles } from "@material-ui/core";


const useStyles = makeStyles(({ spacing, palette, typography }) =>
    createStyles({
        companyLogo: {
            fill: '#005F9E',
            width: '35px',
            height: '35px',
            margin: '10px'
        }
    })
);

function CompanyLogo(props: SvgIconProps) {

    const classes = useStyles();

    return (
        <SvgIcon {...props} className={classes.companyLogo} viewBox="0 0 24 24">
            <g id="Trimble-Logo-without-word" stroke="none" strokeWidth="1" fill="inherit" fillRule="evenodd">
                <g id="Group" transform="translate(1.000000, 1.000000)" fill="inherit" fillRule="nonzero">
                    <path d="M13.992,15.048 C13.9333333,15.312 13.8746667,15.5466667 13.7573333,15.752 C13.728,15.84 13.6986667,15.8986667 13.6986667,15.9866667 C13.5226667,16.1626667 13.4053333,16.2506667 13.376,16.28 C12.4666667,17.0426667 11.7626667,17.424 10.6773333,17.776 C10.2666667,17.8933333 9.44533333,18.128 8.36,18.0986667 L6.688,19.008 C8.712,19.448 10.3546667,19.0373333 11.0293333,18.832 C12.2613333,18.4213333 13.0826667,17.9813333 14.08,17.1306667 C14.2853333,16.9546667 15.3413333,16.0453333 16.0746667,14.608 C16.2213333,14.344 16.3386667,14.0506667 16.456,13.728 L13.992,15.048 Z" id="Shape"></path>
                    <path d="M8.15466667,3.31466667 C8.21333333,3.31466667 8.272,3.31466667 8.33066667,3.31466667 C8.448,3.31466667 8.56533333,3.31466667 8.65333333,3.31466667 C8.448,3.37333333 8.272,3.432 8.096,3.49066667 L9.592,4.28266667 C10.7653333,4.25333333 11.9386667,4.752 12.7306667,5.60266667 C12.5546667,5.632 12.3493333,5.632 12.1733333,5.66133333 L14.696,7.01066667 C14.696,6.98133333 14.696,6.952 14.696,6.92266667 C14.8133333,6.98133333 14.9013333,7.06933333 14.96,7.15733333 L16.632,8.06666667 C16.6026667,8.008 16.6026667,7.94933333 16.5733333,7.89066667 C16.5733333,7.86133333 16.544,7.832 16.5146667,7.77333333 C16.4853333,7.71466667 16.4853333,7.656 16.456,7.62666667 C15.9866667,6.248 15.048,5.04533333 13.8746667,4.10666667 C13.8453333,4.048 13.816,4.01866667 13.7573333,3.98933333 C11.968,2.58133333 10.1493333,2.288 9.24,2.2 C9.18133333,2.2 9.152,2.2 9.09333333,2.2 C8.536,2.17066667 7.97866667,2.17066667 7.42133333,2.25866667 C7.392,2.25866667 7.36266667,2.25866667 7.33333333,2.288 C7.09866667,2.31733333 6.89333333,2.34666667 6.71733333,2.40533333 C6.57066667,2.43466667 6.424,2.464 6.30666667,2.52266667 L7.86133333,3.37333333 C7.94933333,3.31466667 8.03733333,3.31466667 8.15466667,3.31466667 Z" id="Shape"></path>
                    <path d="M1.64266667,13.464 C1.408,12.8773333 1.232,12.144 1.20266667,11.8506667 C1.11466667,11.176 1.11466667,11.176 1.11466667,10.5013333 C1.11466667,10.296 1.144,9.97333333 1.17333333,9.73866667 C1.26133333,9.21066667 1.34933333,8.624 1.70133333,7.832 L1.70133333,5.632 C1.43733333,5.984 1.17333333,6.39466667 0.968,6.83466667 C0.352,8.06666667 0.205333333,8.94666667 0.088,9.592 C0.0293333333,9.88533333 2.85327317e-14,10.2666667 2.85327317e-14,10.5306667 C2.85327317e-14,11.264 2.85327317e-14,11.264 0.088,11.9973333 C0.146666667,12.4373333 0.381333333,13.4346667 0.704,14.1386667 C0.968,14.696 1.20266667,15.1066667 1.46666667,15.5173333 C1.496,15.576 1.52533333,15.6053333 1.55466667,15.6346667 C1.584,15.6933333 1.64266667,15.7226667 1.672,15.7813333 L1.672,13.3466667 C1.672,13.376 1.64266667,13.4053333 1.64266667,13.464 Z" id="Shape"></path>
                    <path d="M14.2853333,9.47466667 C14.168,9.65066667 14.0506667,9.79733333 13.9333333,9.944 C14.344,10.7066667 14.6666667,11.4693333 14.872,12.2026667 C14.96,12.0853333 15.048,11.968 15.1066667,11.8506667 C15.7813333,10.7653333 15.8986667,9.416 15.4586667,8.06666667 C15.4293333,8.03733333 15.4,7.97866667 15.3706667,7.92 C15.3413333,7.80266667 15.2826667,7.68533333 15.224,7.59733333 C15.1653333,7.53866667 15.136,7.48 15.1066667,7.42133333 C15.0773333,7.33333333 15.0186667,7.24533333 14.9306667,7.15733333 L14.6666667,7.01066667 C14.8426667,7.89066667 14.7253333,8.74133333 14.2853333,9.47466667 Z" id="Shape"></path>
                    <path d="M7.59733333,3.344 C7.568,3.344 7.53866667,3.344 7.50933333,3.344 C7.128,3.40266667 6.80533333,3.49066667 6.45333333,3.608 C6.74666667,3.66666667 7.04,3.75466667 7.33333333,3.872 C7.568,3.72533333 7.832,3.57866667 8.096,3.46133333 L7.832,3.31466667 C7.77333333,3.344 7.68533333,3.344 7.59733333,3.344 Z" id="Shape"></path>
                    <path d="M16.456,13.6986667 L21.736,10.8533333 L16.6026667,8.06666667 C17.336,10.3253333 17.0133333,12.2906667 16.456,13.6986667 Z" id="Shape"></path>
                    <path d="M9.21066667,4.312 C9.21066667,4.312 9.21066667,4.312 9.21066667,4.312 C9.00533333,4.34133333 8.8,4.4 8.59466667,4.45866667 C9.29866667,4.86933333 10.032,5.368 10.736,6.01333333 C11.2346667,5.86666667 11.704,5.74933333 12.144,5.69066667 L9.56266667,4.312 C9.47466667,4.28266667 9.328,4.28266667 9.21066667,4.312 Z" id="Shape"></path>
                    <path d="M11.704,15.136 C8.77066667,15.5466667 5.66133333,14.168 3.872,11.9093333 C3.19733333,12.7306667 2.728,13.552 2.552,14.2853333 C2.49333333,14.5786667 2.464,14.8133333 2.49333333,15.048 C2.61066667,15.224 2.728,15.3706667 2.904,15.5466667 C3.31466667,16.0453333 4.488,17.2773333 6.45333333,17.8346667 C7.128,18.04 7.80266667,18.128 8.36,18.128 L13.992,15.0773333 C14.0213333,14.8426667 14.0506667,14.608 14.0506667,14.3733333 C13.376,14.7253333 12.584,14.9893333 11.704,15.136 Z" id="Shape"></path>
                    <path d="M4.01866667,10.0906667 C4.69333333,9.44533333 5.456,8.8 6.27733333,8.24266667 C6.21866667,8.12533333 6.16,8.008 6.13066667,7.89066667 C6.13066667,7.89066667 6.13066667,7.89066667 6.13066667,7.89066667 C5.74933333,6.83466667 5.86666667,5.77866667 6.39466667,4.89866667 C6.424,4.84 6.45333333,4.81066667 6.512,4.752 C5.896,4.576 5.33866667,4.51733333 4.84,4.576 C4.54666667,4.84 4.25333333,5.16266667 4.048,5.51466667 C3.63733333,6.18933333 3.432,6.92266667 3.432,7.71466667 C3.432,8.272 3.52,8.82933333 3.72533333,9.38666667 C3.784,9.62133333 3.90133333,9.856 4.01866667,10.0906667 Z" id="Shape"></path>
                    <path d="M7.15733333,7.50933333 C7.18666667,7.53866667 7.18666667,7.59733333 7.216,7.62666667 C7.97866667,7.18666667 8.77066667,6.776 9.56266667,6.424 C8.888,5.896 8.21333333,5.456 7.568,5.13333333 C7.48,5.22133333 7.392,5.33866667 7.33333333,5.42666667 C6.952,6.04266667 6.89333333,6.776 7.15733333,7.50933333 C7.15733333,7.50933333 7.15733333,7.50933333 7.15733333,7.50933333 Z" id="Shape"></path>
                    <path d="M11.44,10.032 C11.8213333,9.97333333 12.1733333,9.856 12.4666667,9.70933333 C11.9093333,8.85866667 11.2346667,8.008 10.472,7.27466667 C10.3546667,7.33333333 10.208,7.36266667 10.0906667,7.42133333 C9.29866667,7.744 8.536,8.12533333 7.77333333,8.59466667 C8.65333333,9.62133333 10.0906667,10.2373333 11.44,10.032 Z" id="Shape"></path>
                    <path d="M4.16533333,3.40266667 C4.752,3.08 5.30933333,2.78666667 6.30666667,2.49333333 L1.70133333,-4.4408921e-16 L1.70133333,5.60266667 C2.75733333,4.19466667 3.98933333,3.49066667 4.16533333,3.40266667 Z" id="Shape"></path>
                    <path d="M13.3466667,8.888 C13.6986667,8.30133333 13.7573333,7.568 13.4933333,6.83466667 C13.4933333,6.83466667 13.4933333,6.83466667 13.4933333,6.83466667 C13.464,6.776 13.4346667,6.71733333 13.4053333,6.65866667 C12.9066667,6.65866667 12.32,6.71733333 11.6453333,6.89333333 C12.2613333,7.53866667 12.8186667,8.24266667 13.288,8.94666667 C13.3173333,8.91733333 13.3173333,8.91733333 13.3466667,8.888 Z" id="Shape"></path>
                    <path d="M11.5573333,14.0213333 C12.4666667,13.904 13.288,13.5813333 13.9333333,13.112 C13.7573333,12.32 13.464,11.4986667 13.024,10.648 C12.6133333,10.8826667 12.1146667,11.0586667 11.616,11.1173333 C9.856,11.352 7.97866667,10.5306667 6.89333333,9.18133333 C6.072,9.768 5.28,10.4133333 4.63466667,11.0586667 C6.16,13.1413333 8.91733333,14.4026667 11.5573333,14.0213333 Z" id="Shape"></path>
                    <path d="M3.22666667,10.9413333 C3.02133333,10.56 2.816,10.1786667 2.66933333,9.768 L2.66933333,9.768 C2.25866667,8.624 2.2,7.48 2.464,6.424 C2.25866667,6.688 2.08266667,6.98133333 1.936,7.33333333 C1.936,7.33333333 1.936,7.33333333 1.936,7.33333333 C1.848,7.50933333 1.76,7.68533333 1.70133333,7.86133333 L1.70133333,13.376 C1.99466667,12.5546667 2.52266667,11.7333333 3.22666667,10.9413333 Z" id="Shape"></path>
                    <path d="M15.488,13.2 C15.3706667,13.3173333 15.2533333,13.464 15.136,13.5813333 C15.1653333,13.728 15.1653333,13.8453333 15.1653333,13.992 C15.2826667,13.728 15.3706667,13.464 15.488,13.2 Z" id="Shape"></path>
                    <path d="M2.05333333,16.2213333 C1.90666667,16.0746667 1.81866667,15.8986667 1.70133333,15.752 L1.70133333,21.6773333 L6.688,18.9786667 C6.512,18.9493333 6.30666667,18.8906667 6.13066667,18.832 C3.90133333,18.216 2.52266667,16.808 2.05333333,16.2213333 Z" id="Shape"></path>
                </g>
            </g>
        </SvgIcon>
    );
}

export default memo(CompanyLogo);
