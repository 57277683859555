import React, { useCallback, memo, useState } from 'react'
import { makeStyles, createStyles, Tooltip, IconButton, Snackbar } from '@material-ui/core'
import { FormattedMessage } from 'react-intl';
import { ResourceAbility } from 'libs/security/authorization';
import DeleteIcon from '@material-ui/icons/Delete';
import { useConfirm } from 'material-ui-confirm';
import UnifiedAPIService, { UnifiedAPI } from 'services/UnifiedAPIService';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        deleteButton: {
            padding: '7px'
        },
    }),
)
function ProxyDeleteAction(props: any) {
    const confirm = useConfirm();
    const [messageType, setMessageType] = useState<"success" | "info" | "warning" | "error" | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    const showMessage = useCallback((message: string, type?: string) => {
        if (!type) {
            type = "success";
        }
        if (message) {
            setMessage(message);
            setMessageType((type as ("success" | "info" | "warning" | "error" | null)));
        }
    }, [setMessageType, setMessage]);

    const hideMessage = useCallback((event) => {
        event?.preventDefault();
        event?.stopPropagation();
        setMessageType(null);
        setMessage(null);
    }, [setMessageType, setMessage]);


    const handleDelete = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        confirm({ description: 'Do you want to delete ' + props.proxy.apiName + ' proxy permanently?' })
            .then(() => {
                UnifiedAPIService.delete(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.delete_proxy, {
                        apiName: props.proxy.apiName
                    })
                ).then(
                    (response: any) => {
                        response.data = {
                            message: props.proxy.apiName + " Proxy is removed. "
                        }
                        showMessage("Proxy has been removed ", "success");
                        props.onSuccess && props.onSuccess();

                    },
                    (error: any) => {
                        if (error.response.status === 403) {
                            showMessage("You are not having permission to delete proxy", "error");
                        } else {
                            showMessage(error.response?.data?.message, "error");
                        }
                    });
            });
    }, []);
    const classes = useStyles();

    let deleteButton;
    if (props.proxy.apiName && !props.proxy.isShared) {
        deleteButton = (
            <ResourceAbility can="create" resource={({ Proxy }) => Proxy.create}>
                <>
                    {messageType && message && (
                        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} open={message ? true : false} onClose={hideMessage}>
                            <Alert severity={messageType} onClose={hideMessage}>{message}</Alert>
                        </Snackbar>)}
                    <Tooltip title={<FormattedMessage defaultMessage="Delete this Proxy" />}>
                        <IconButton className={classes.deleteButton} onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            </ResourceAbility>
        );
    }
    return (
        <>{deleteButton}
        </>
    )
}

export default memo(ProxyDeleteAction);

