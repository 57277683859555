import React, { FC, useState, useCallback, useRef } from "react";
import { Typography, Button } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import ActionInliner from "../../../../legacy-components/form/action/ActionInliner";
import AddIcon from '@material-ui/icons/Add';
import { AssignableCollectionItem } from "../../../../legacy-components/form/collection/AssignableCollection";
import { IAPIProxy } from "../../../../libs/resources/proxy/ProxyService";
import { FormSelectionGridData, FormSelectionGridOptions, FormSelectionGridColumn } from "../../../../legacy-components/form/input/FormSelection";
import { ProductProxyFormGridDataValue } from "../models/products.model";
import { ProductProxyDialogRef, ProxyCollectionType } from "./ProductProxyDialog";
import { ResourceAbility } from 'libs/security/authorization';
import { GridViewStatusBadgeCellRenderer } from "../../../../legacy-components/grid-view/GridView";

export const ProductProxyTitle: FC<{
    count?: number
}> = ({ count }) => {
    return (
        <Typography variant="h6" children={<FormattedMessage values={{ count: count ?? 0 }} defaultMessage="{count, plural, =0 {No Proxies} one {# Proxy} other {# Proxies}}" />} />
    );
};

export const ProductProxyControls: FC<{
    onClick: () => void,
    disabled?: boolean
}> = ({ onClick, disabled }) => (
    <ActionInliner>
        <ResourceAbility can="create" resource={({ Product }) => Product.mapping}>
        <Button startIcon={<AddIcon />} disabled={disabled} disableElevation variant="contained" size="small" onClick={onClick} color="secondary">
            <FormattedMessage defaultMessage="Add Proxy" />
        </Button>
        </ResourceAbility>
    </ActionInliner>
);


export const createProductProxyGridData = (items: AssignableCollectionItem<IAPIProxy>[]): FormSelectionGridData<ProductProxyFormGridDataValue>[] => {
    return items.map(item => {
        return {
            value: {
                id: item.key,
                name: item.value.apiName,
                status: item.value.apiDeployments?.filter(
                    (value, index) => value.deployStatus === 'DEPLOY_COMPLETED' &&
                     index === item.value.apiDeployments?.findIndex(deployment => 
                            deployment.environmentName === value.environmentName
                        )
                    )
                    .map(value => {
                   
                    return {
                        kind: "access",
                        key: value.environmentName
                    
                }
                }),
                proxy: item.value
            },
            selection: {
                assign: item.assign,
                assigned: item.assigned
            }
        }
    });
}

interface UseProductProxyGridOptions {
    headerStyle?: FormSelectionGridOptions["headerStyle"]
}

export const useProductProxyGrid = (productName?: string, options?: UseProductProxyGridOptions) => {
    const intl = useIntl();

    const proxyDialogRef = useRef<ProductProxyDialogRef>();

    const [columns] = useState<FormSelectionGridColumn[]>([{
        key: "name",
        title: intl.formatMessage({
            defaultMessage: "Name"
        })
    }, {
        key: "status",
        title: intl.formatMessage({
            defaultMessage: "Status"
        }),
        align: "right",
        component: GridViewStatusBadgeCellRenderer ,
        minWidth: 350
    }]);

    const createGridOptions = (): FormSelectionGridOptions => {
        return {
            headerStyle: options?.headerStyle ?? "tinted",
            columns,
            messages: {
                nodata: <FormattedMessage defaultMessage="No proxies added to the product" />
            }
        }
    }

    const [gridOptions] = useState(createGridOptions());
    const [prevGridData, setPrevGridData] = useState<FormSelectionGridData<ProductProxyFormGridDataValue>[]>([]);
    const [gridData, setGridData] = useState<FormSelectionGridData<ProductProxyFormGridDataValue>[]>([]);
    const [disabled] = useState(!productName);

    const onChange = useCallback((items: AssignableCollectionItem<IAPIProxy>[]) => {
        setPrevGridData(gridData);
        setGridData(createProductProxyGridData(items));
    }, [gridData]);

    const openDialog = useCallback((handler: () => ProxyCollectionType[] | undefined) => {
        if (productName) {
            proxyDialogRef.current?.open({
                initialList: handler(),
                productName
            });
        }
    }, [productName]);

    const revertChanges = useCallback(() => {
        setGridData(prevGridData);
    }, [prevGridData]);

    const commitChanges = useCallback(() => {
        setGridData((values) => {
            return values.filter(value => value.selection.assign).map(value => {
                return {
                    ...value,
                    selection: {
                        assign: true,
                        assigned: true
                    }
                }
            });
        })
    }, []);

    return {
        gridOptions,
        gridData,
        prevGridData,
        onChange,
        proxyDialogRef,
        disabled,
        openDialog,
        revertChanges,
        commitChanges
    };
}

