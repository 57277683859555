import React, { PropsWithChildren, ReactElement, useContext, memo, useState, useEffect, useRef } from 'react';
import { ValidatorBuilderContext, builder } from '../validator/Validator';
import PropTypes from "prop-types";
import { Form, FormProps, FormRenderProps } from 'react-final-form';
import { SubmissionErrors, FormApi } from 'final-form';
import { FormStepperContext, FormStepperContextValue } from '../components/stepper-form/StepperFormContent';
import { StepperFormContentState } from '../components/stepper-form/StepperFormContext';
import arrayMutators from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import FormAlert from './FormAlert';
import { Box, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        formLayout: {
            display: "flex",
            width: "100%"
        },
        formContainer: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            width: "100%"
        },
        submitLoader: {
            position: "absolute",
            zIndex: 2,
            width: '100%',
            height: '100%',
            backgroundColor: "rgb(255, 255, 255, 0.32)"
        }
    }),
);

export interface FormContainerOptions<T extends Record<string, any> = Record<string, any>, U = {
    [key: string]: Record<string, any>
}> {
    stepperOptions: {
        navigateBack?: () => void,
        initilizeContent?: StepperFormContentState<T>,
        formData?: FormStepperContextValue<U>["formData"]
    }
}

export interface FormContainerSubmitOptions<T extends Record<string, any> = Record<string, any>, U = {
    [key: string]: Record<string, any>
}> {
    stepperOptions?: {
        initilizeContent?: StepperFormContentState<T>,
        formData?: FormStepperContextValue<U>["formData"]
    }
}

export type FormContainerSubmitHandler<T extends Record<string, any> = Record<string, any>, U = {
    [key: string]: Record<string, any>
}> = (submitOptions: FormContainerSubmitOptions<T, U>) => (...params: Parameters<FormProps<T>["onSubmit"]>) => Promise<SubmissionErrors | undefined | unknown>;

export type FormContainerChild<T extends Record<string, any> = Record<string, any>> = (formProps: FormRenderProps<T, T>, containerOptions: FormContainerOptions, submitHandler: () => void) => ReactElement<any, any>;

interface FormContainerProps {
    FormProps: FormProps<any, any> & {
        onSubmit: FormContainerSubmitHandler
    };
    onSubmitSuccess?: <T = unknown>(data: T, type?: string) => void,
    onSubmitFailed?: (errors?: any) => void,
    children: FormContainerChild,
    dataFields?: string[],
    stepperId?: string;
    prefillValues?: Record<string, any>,
    previewErrors?: boolean
    successMessage?: string
}

const FormErrorViewer = ({ errors }: { errors?: string[] }) => {
    return (
        <>
            {errors?.map((error, index) => <div key={index}>{error}</div>)}
        </>
    )
}

/**
 * @function FormContainer
 * @description FormContainer abstracts the complex implementation of react-final-form and provides generic ways to implement forms.
 */
const FormContainer = (props: PropsWithChildren<FormContainerProps>) => {
    const styles = useStyles();
    const stepperContext = useContext(FormStepperContext);

    const [hasStepper] = useState(stepperContext.isValid && stepperContext.stepperId === props.stepperId);

    const [containerOptions] = useState({
        stepperOptions: {
            navigateBack: hasStepper && stepperContext.prev ? () => stepperContext.prev?.() : undefined,
            initilizeContent: stepperContext.initilizeContent,
            formData: hasStepper ? stepperContext.formData : undefined
        }
    });

    const [stepperFormState, setStepperFormState] = useState<StepperFormContentState>({
        meta: {},
        values: {}
    })

    const isMount = useRef(true);

    useEffect(() => {
        if (isMount.current) {
            isMount.current = false;
            return;
        }

        if (hasStepper) {
            stepperContext.updateFormState(stepperFormState);
        }

    }, [stepperFormState, stepperContext, hasStepper])

    const formStateAction: {
        [key: string]: (form?: FormApi) => void
    } = {
        submit: form => {
            const formState = form?.getState();

            setStepperFormState({
                values: {
                    ...formState?.values ?? {}
                },
                meta: {
                    ...stepperFormState.meta,
                    submitted: true,
                    submitting: false,
                    error: false,
                    errorValue: undefined
                }
            });
        },

        submitting: () => setStepperFormState({
            ...stepperFormState,
            meta: {
                ...stepperFormState.meta,
                submitting: true,
                error: false,
                errorValue: undefined
            }
        }),

        error: form => {
            const formState = form?.getState();
            let errorValue: string[] | null = null;

            if (typeof formState?.submitError === "string") {
                errorValue = [formState?.submitError]
            } else {
                const keys = Object.keys(formState?.submitError ?? {});

                if (keys.length > 0) {
                    errorValue = keys.map(key => formState?.submitError[key])
                }
            }


            setStepperFormState({
                ...stepperFormState,
                meta: {
                    ...stepperFormState.meta,
                    submitting: false,
                    error: true,
                    errorValue
                }
            })
        }
    }

    const dispatchFormState = (action: "submit" | "submitting" | "error", form?: FormApi) => {
        formStateAction[action]?.(form);
    }

    const render = (formProps: FormRenderProps) => {
        type HandleSubmitType = typeof formProps.handleSubmit;

        const submitHandler: (...params: Parameters<HandleSubmitType>) => void = event => {
            const handler = formProps.handleSubmit(event);

            if (handler?.then) {
                dispatchFormState("submitting");

                handler.then(errors => {
                    if (!errors) {
                        props.onSubmitSuccess?.(formProps.form.getState().values);
                        dispatchFormState("submit", formProps.form);

                        if (hasStepper) {
                            stepperContext.next();
                        }
                    } else {
                        props.onSubmitFailed?.(errors);
                        dispatchFormState("error", formProps.form);
                    }
                }, error => {
                    props.onSubmitFailed?.(error);
                    dispatchFormState("error", formProps.form);
                });
            }
        };

        if (isMount.current) {
            props.dataFields?.forEach(field => {
                formProps.form.registerField(field, () => { }, {}, {
                    silent: true
                });
            });

            Object.keys(props.prefillValues ?? {}).forEach(key => {
                if (props.prefillValues?.[key] !== null || props.prefillValues?.[key] !== undefined) {
                    formProps.form.mutators.setValue(key, props.prefillValues?.[key])
                }
            });
        }
        return (
            <form className={styles.formLayout} noValidate autoComplete="off" onSubmit={submitHandler}>
                <Box className={styles.formContainer}>
                    {formProps.submitting && <Box className={styles.submitLoader}></Box>}
                    {
                        props.previewErrors && stepperFormState.meta?.errorValue && (
                            <FormAlert severity="error">
                                <FormErrorViewer errors={stepperFormState.meta?.errorValue} />
                            </FormAlert>)
                    }
                    {
                        props.successMessage &&
                        formProps.submitSucceeded && 
                        !formProps.error && 
                        !formProps.hasSubmitErrors &&
                        (
                            <FormAlert severity="success">
                               {props.successMessage}
                            </FormAlert>
                        )
                    }

                    <ValidatorBuilderContext.Provider value={builder}>
                        {props.children?.(formProps, containerOptions, submitHandler)}
                    </ValidatorBuilderContext.Provider>
                </Box>
            </form>
        )
    }

    return <Form {...props.FormProps} onSubmit={props.FormProps.onSubmit({
        stepperOptions: {
            initilizeContent: stepperContext.initilizeContent,
            formData: stepperContext.formData
        }
    })} render={render} mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
        },
        setFieldTouched
    }} initialValues={props.FormProps.initialValues ?? stepperContext.initilizeContent?.values} />;
};

FormContainer.propTypes = {
    children: PropTypes.any.isRequired,
    FormProps: PropTypes.object,
    stepperId: PropTypes.string
};

export default memo(FormContainer);
