
import { from } from "rxjs";
import "reflect-metadata";
import ConsumerClient from "./ConsumerClient";
import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { map } from "rxjs/operators";

export interface IProduct {
  id: string;
  name: string;
  displayName: string;
  shortDescription: string;
  longDescription: string;
  visibility: string;
  created: string;
  updated: string;
  teamName: string;
  teamDisplayName: string;
  sectorName: string;
  apiTypeMapping: string[];
  specAvailability: boolean;
}

export interface IApplication {
  subscriptionId: string;
  applicationName: string;
  status: string;
  type: string;
  environment: string;
  createdAt: string;
  description: string;
}

export interface ISpecDetails {
  preProd?: {
    fileName: string;
  };
  prod?: {
    fileName: string;
  };
}
export interface IProductDetail {
  displayName: string;
  productName: string;
  teamName: string;
  teamDisplayName: string;
  apiTypes: Array<string>;
  summary: ISummary;
  subscribers: number;
  specDetails: ISpecDetails;
  publishedInfo: IProductPublishedInfo[];

}


export interface IProductPublishedInfo{
  gateway: string,
  approvalType: string
}

export interface ISummary {
  description: string;
  publishedDate: string;
  lastUpdated: string;
  apiEndPoints: Array<IApiEndpoint>;
}

export interface IApiEndpoint {
  apiName: string;
  deployment: Array<IDeployments>;
}

export interface IDeployments {
  environment: string;
  deploymentInfoList : Array<IDeploymentDetails>;
  resourceName: string;
}

export interface IDeploymentDetails {
  provider: string;
  option: string;
  optionValue: string;
  endpoint: string;
  isDeprecated: boolean;
}

export interface IProductFilter {
  teamFilters: Array<ITeamFilter>;
  apiTypes: Array<string>; 
}

export interface ITeamFilter {
  sectorName: string;
  teams: Array<string>;
}

@APIResource(ConsumerClient, "/products")
class ProductServiceImpl {
  @APIMethod("")
  public getProductList(type?: string) {
    return from (
      ConsumerClient.get<IProduct[]>(apiMethodContext(arguments), {
        params: {
          type,
        },
      }));
  }

  @APIMethod("/:productName/subscriptions")
  public getApplications(productName: string, type: string) {
    return from(
      ConsumerClient.get<IApplication[]>(apiMethodContext(arguments), {
        params: {
          type,
          productName,
        },
      })
    )
  }

  @APIMethod("/:productName")
  public getProductDetail(productName: string) {
    return from(
      ConsumerClient.get<IProductDetail>(apiMethodContext(arguments), {
        params: {
          productName,
        },
      })
    );
  }

  @APIMethod("/filters")
  public getProductFilters() {
    return from(
      ConsumerClient.get<IProductFilter>(apiMethodContext(arguments), {
        params: {
        },
      })
    );
  }
}

export const ProductService = new ProductServiceImpl();
