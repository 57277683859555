import React, { FC, useState, useContext } from "react";
import { FormRenderProps, Field } from "react-final-form";
import { Button, InputAdornment } from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ProxyVerificationPerformanceInfo } from "../models/verification.model";
import { AccordionContent } from "legacy-components/form/components/accordion-form/AccordionForm";
import FormContainer from "../../../../../../legacy-components/form/container/FormContainer";
import FormInputContainer from "../../../../../../legacy-components/form/container/FormInputContainer";
import { FieldArray } from "react-final-form-arrays";
import { ValidatorBuilderContext } from "../../../../../../legacy-components/form/validator/Validator";
import ActionInliner from "../../../../../../legacy-components/form/action/ActionInliner";
import FormTextInput from "../../../../../../legacy-components/form/input/FormTextInput";
import InlineIconFormatter from "legacy-components/formatters/InlineIconFormatter";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import Helper from "pages/home/helper/Helper";
import JustificationHelper from "pages/home/helper/JustificationHelper";
import { FormApi, FORM_ERROR } from "final-form";
import { SnackNotificationContext } from "../../../../../../legacy-components/notifications/GenericSnackNotification";
import ProxyVerificationService from "../ProxyVerificationService";
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

interface PerformanceDocumentationFormProps {
  onCancel?: () => void;
  onSuccess?: (
    proxyVerificationPerformanceInfo?: ProxyVerificationPerformanceInfo
  ) => void;
  proxyVerificationPerformanceInfo?: ProxyVerificationPerformanceInfo;
  proxy: string;
}

const NotificationMessage = () => {
  return (
    <FormattedMessage defaultMessage="Performance and Documentation Saved Successfully" />
  );
};

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    accordionSummary: {
      padding: "10px 15px",
      margin: 0,
      "&.Mui-expanded": {
        margin: " 0 !important",
        padding: "0 15px",
      },
    },
    accordionDetails: {
      padding: 0,
    },
    root: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
    },
    noConfigMessage: {
      color: palette.text.secondary,
    },
    noConfigAction: {
      padding: spacing(3, 0),
    },
    actions: {
      padding: spacing(3),
    },
    content: {
      padding: spacing(3),
      overflow: "auto",
    },
    title: {
      padding: spacing(0, 0, 3, 0),
    },
    textField: {
      width: "54ch",
    },
    textField2: {
      marginLeft: spacing(1.45),
      width: "54ch",
    },
    textField1: {
      marginBottom: spacing(4),
      width: "100%",
    },
  })
);

const PerformanceDocumentationForm: FC<PerformanceDocumentationFormProps> = ({
  proxy,
  proxyVerificationPerformanceInfo,
  onCancel,
  onSuccess,
}) => {
  const notificationContext = useContext(SnackNotificationContext);
  const onSubmit =
    () => (data: ProxyVerificationPerformanceInfo, form: FormApi) => {
      return new Promise((resolve) => {
        const formState = form.getState();
        if (formState.pristine) {
          resolve();
        } else {
          const performanceMetricsUrl = data?.performanceMetricsUrl?.filter(
            function (v) {
              return v !== "";
            }
          );

          const performanceMetricsInfo: ProxyVerificationPerformanceInfo = {
            performanceUptime: data?.performanceUptime,
            performanceCapacity: data?.performanceCapacity,
            performanceErrorRate: data?.performanceErrorRate,
            performanceAverageLatency: data?.performanceAverageLatency,
            performanceMetricsUrl: performanceMetricsUrl,
            performanceStatusPage: data?.performanceStatusPage,
            performanceAdditionalInput: data?.performanceAdditionalInput,
          };
          ProxyVerificationService.forPerformanceMetrics(
            proxy,
            performanceMetricsInfo
          ).subscribe(
            () => {
              notificationContext.show({
                id: "performance-metrics",
                message: <NotificationMessage />,
              });
              resolve();
            },
            (error) => {
              resolve({
                [FORM_ERROR]: error,
              });
            }
          );
        }
      });
    };
  const intl = useIntl();
  const validator = useContext(ValidatorBuilderContext);
  const [isShowJustificationHelper, toggleJustificationHelper] =
    useState(false);
  const classes = useStyles();
  const FormComponents = (
    formRenderProps: FormRenderProps<ProxyVerificationPerformanceInfo, {}>
  ) => {
    return (
      <>
        <div className={classes.root}>
          <FormInputContainer
            title={
              <Typography
                variant="subtitle2"
                children={
                  <FormattedMessage defaultMessage="Uptime(%) - past 6 months *" />
                }
              />
            }
          >
            <Field
              className={classes.textField}
              name="performanceUptime"
              type="text"
              component={FormTextInput}
              placeholder={intl.formatMessage({
                defaultMessage: "99.99%",
              })}
              validate={validator.from({
                required: true,
                pattern: ({ Percentage }) => Percentage,
              })}
            />
          </FormInputContainer>
          <FormInputContainer
            title={
              <Typography
                variant="subtitle2"
                children={
                  <FormattedMessage defaultMessage="Avg. Latency (P95) - past month" />
                }
              />
            }
          >
            <Field
              className={classes.textField2}
              name="performanceAverageLatency"
              type="text"
              component={FormTextInput}
              placeholder={intl.formatMessage({
                defaultMessage: "20",
              })}
              validate={validator.from({
                required: true,
                pattern: ({ Numeric }) => Numeric,
              })}
            />
          </FormInputContainer>
        </div>
        <div>
          <FormInputContainer>
            <Field
              className={classes.textField1}
              name="performanceStatusPage"
              type="text"
              component={FormTextInput}
              label={intl.formatMessage({
                defaultMessage: "Status Page",
              })}
              placeholder={intl.formatMessage({
                defaultMessage: "Status Page",
              })}
              validate={validator.from({
                required: true,
                maxLength: 200,
                pattern: ({ HttpUrl }) => HttpUrl,
              })}
            />
          </FormInputContainer>
          <FormInputContainer
            title={
              <FormattedMessage defaultMessage="Please include supporting documentation for your API proxy performance metrics. Ex. APM dashboard, Performance test reports, etc." />
            }
          >
            <FieldArray name="performanceMetricsUrl">
              {({ fields }) =>
                fields.map((name, index) => (
                  <div key={name}>
                    <Field
                      type="text"
                      component={FormTextInput}
                      className={classes.textField1}
                      name={name}
                      placeholder="Url"
                      label={intl.formatMessage({
                        defaultMessage: "Url",
                      })}
                      validate={validator.from({
                        pattern: ({ HttpUrl }) => HttpUrl,
                      })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="medium"
                              onClick={() => fields.remove(index)}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                ))
              }
            </FieldArray>
            <Button
              onClick={() =>
                formRenderProps.form.mutators.push(
                  "performanceMetricsUrl",
                  undefined
                )
              }
              disabled={formRenderProps.submitting}
              type="button"
              variant="outlined"
              disableElevation
            >
              {" "}
              <FormattedMessage defaultMessage="Add More Urls" />
            </Button>
          </FormInputContainer>

          <FormInputContainer
            title={
              <Typography
                variant="subtitle2"
                children={
                  <FormattedMessage defaultMessage="Additional input" />
                }
              />
            }
          >
            <Field
              className={classes.textField1}
              name="performanceAdditionalInput"
              type="text"
              multiline
              rows={5}
              component={FormTextInput}
              placeholder={intl.formatMessage({
                defaultMessage: "How well documented are your APIs?",
              })}
              validate={validator.from({
                maxLength: 400
              })}
            />
          </FormInputContainer>
          <ActionInliner>
            <Button
              disabled={formRenderProps.submitting || formRenderProps.pristine}
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
            >
              {" "}
              <FormattedMessage defaultMessage="Save" />
            </Button>
          </ActionInliner>
        </div>
      </>
    );
  };
  return (
    <AccordionContent
      size="small"
      header={
        <div>
          <InlineIconFormatter icon={<React.Fragment></React.Fragment>}>
            <FormattedMessage defaultMessage="Performance & Documentation" />
          </InlineIconFormatter>
          <Helper
            open={isShowJustificationHelper}
            handleClose={() => {
              toggleJustificationHelper(false);
            }}
            children={<JustificationHelper />}
          ></Helper>
        </div>
      }
    >
      <div className={classes.title}>
        <FormattedMessage defaultMessage="Please provide the following perfmormace metrics for your API Proxy." />
      </div>
      <FormContainer
        previewErrors={true}
        FormProps={{
          onSubmit,
          initialValues: {
            performanceMetricsUrl:
              proxyVerificationPerformanceInfo?.performanceMetricsUrl
                ? proxyVerificationPerformanceInfo.performanceMetricsUrl
                    .length > 0
                  ? proxyVerificationPerformanceInfo.performanceMetricsUrl
                  : [""]
                : [""],
            performanceUptime:
              proxyVerificationPerformanceInfo?.performanceUptime,
            performanceCapacity:
              proxyVerificationPerformanceInfo?.performanceCapacity,
            performanceErrorRate:
              proxyVerificationPerformanceInfo?.performanceErrorRate,
            performanceAverageLatency:
              proxyVerificationPerformanceInfo?.performanceAverageLatency,
            performanceStatusPage:
              proxyVerificationPerformanceInfo?.performanceStatusPage,
            performanceAdditionalInput:
              proxyVerificationPerformanceInfo?.performanceAdditionalInput,
          },
        }}
        onSubmitSuccess={onSuccess}
        children={FormComponents}
        prefillValues={proxyVerificationPerformanceInfo}
      />
    </AccordionContent>
  );
};


export default PerformanceDocumentationForm;
