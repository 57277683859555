import React, { useCallback, memo, FC, forwardRef, useImperativeHandle, PropsWithRef, Ref, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, createStyles, ListItemSecondaryAction, Menu, MenuItem } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import ProxyShareForm from "../proxy-forms/ProxyShareForm";
import { useCompactDialog } from "../../../../legacy-components/dialog/UseCompactDialog";
import ShareService from "libs/resources/proxy/ShareService";
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useConfirm } from 'material-ui-confirm';

import { IconButton, List, ListItem, ListItemText } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        content: {
            padding: spacing(3),
            paddingTop: 0
        },
        proxySharerView: {
            marginTop: '30px',
            maxHeight: 'calc( 100vh - 450px)',
            overflowY: 'auto'
        },
        proxySharerList: {
            borderTop: '1px solid #ccc'
        },
        disabled: {
            pointerEvents: 'none',
            color: '#005F9E'
        },
        teamName: {
            textTransform: 'capitalize'
        },
        message: {
            marginTop: '15px'
        }
    }),
);

export interface ProxyShareDialogRef {
    open: () => void
}

interface ProxyShareDialogProps {
    proxyName: string,
    ref: Ref<ProxyShareDialogRef | undefined>
}

interface IProxySharerList {
    proxyName: string,
    handleClose: () => void
}
const ProxySharerList: FC<IProxySharerList> = ({ proxyName, handleClose }) => {
    const styles = useStyles();
    const confirm = useConfirm();
    const [shareHolderListView, setShareHolderListView] = useState((<div>Loading...</div>));
    const [shareHolders, setShareHolders] = useState<any>(null);
    const [messageType, setMessageType] = useState<"success" | "info" | "warning" | "error" | null>(null);
    const [message, setMessage] = useState<string | null>(null);


    const showMessage = useCallback((response: any, type?: string) => {
        if (!type) {
            type = "success";
        }
        if (response?.data?.message) {
            setMessage(response?.data?.message);
            setMessageType((type as ("success" | "info" | "warning" | "error" | null)));
        }
    }, [setMessageType, setMessage]);

    const getSharedUserList = useCallback(() => {
        ShareService.getSharedUserList(proxyName).toPromise().then(
            (data: any) => {
                setShareHolders(data);
            }
        );
    }, [proxyName]);

    useEffect(() => {
        getSharedUserList();
    }, [getSharedUserList]);

    const onSharedUserListChange = (response: any, type?: string) => {
        getSharedUserList();
        showMessage(response, type);
    }

    const hideMessage = useCallback(() => {
        setMessageType(null);
        setMessage(null);
    }, [setMessageType, setMessage]);

    const [anchorEl, setAnchorEl] = React.useState<Array<any>>([]);

    if ((anchorEl === null || anchorEl?.length === 0) && (shareHolders && shareHolders.length > 0)) {
        let anchors: Array<any> = [];
        shareHolders.forEach(() => {
            anchors.push(null);
        });
        setAnchorEl(anchors);
    }

    const toggleRoleList = (index: number, value: any) => {
        let anchorElements = { ...anchorEl };
        anchorElements[index] = value;
        setAnchorEl(anchorElements);
    };

    const switchRole = (shareHolder: any, role: string, index: number) => {
        UnifiedAPIService.put(
            UnifiedAPI.parseRequestURL(UnifiedAPI.share_api, {
                apiName: proxyName,
                emailAddress: shareHolder.email,
                role: role.toUpperCase()
            }), {}
        ).then(
            (response: any) => {
                getSharedUserList();
                response.data = {
                    message: "Changed Sharing permission as " + role
                }
                showMessage(response, "success");
            },
            (error: any) => {
                let errorMessage = (error?.response?.status === 403) ? error?.response?.statusText : error?.response?.data;
                showMessage(errorMessage, "error");
            }
        );
        toggleRoleList(index, null);
    }

    const handleRoleChangeClose = (index: number) => {
        toggleRoleList(index, null);

    };
    const openRoleList = (event: any, index: number) => {
        toggleRoleList(index, event.currentTarget);
    }
    useEffect(() => {

        const handleDelete = (shareHolder: any) => {
            confirm({ description: 'Do you want to remove proxy acccess to this user?' })
                .then(() => {
                    UnifiedAPIService.delete(
                        UnifiedAPI.parseRequestURL(UnifiedAPI.unshare_api, {
                            apiName: proxyName,
                            emailAddress: shareHolder.email
                        })
                    ).then(
                        (response: any) => {
                            response.data = {
                                message: "Revoked access to " + shareHolder.email
                            }
                            showMessage(response, "success");
                            getSharedUserList();
                        },
                        (error: any) => {
                            showMessage(error.data, "error");
                        });
                });
        }
        if (shareHolders && anchorEl) {
            if (shareHolders && shareHolders?.length > 0) {
                let shareHolderList: Array<any> = [];
                shareHolders?.forEach((shareHolder: any, index: number) => {

                    shareHolderList.push(
                        <ListItem button key={shareHolder.email}>
                            <ListItemText primary={shareHolder.email} secondary={<span className={styles.teamName}>{shareHolder.team}</span>} />
                            <ListItemSecondaryAction className={"" + anchorEl[index]}>
                                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => { openRoleList(event, index) }}>
                                    {(shareHolder.role?.toLowerCase() === "editor") ? <EditOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                </IconButton>
                                <Menu
                                    id={shareHolder.email}
                                    anchorEl={anchorEl[index]}
                                    keepMounted

                                    open={(anchorEl && anchorEl[index]) ? true : false}
                                    onClose={() => { handleRoleChangeClose(index) }}
                                >
                                    <MenuItem onClick={() => { switchRole(shareHolder, 'Viewer', index) }} className={shareHolder.role?.toLowerCase() === "viewer" ? styles.disabled : ""}>Can View</MenuItem>
                                    <MenuItem onClick={() => { switchRole(shareHolder, 'Editor', index) }} className={shareHolder.role?.toLowerCase() === "editor" ? styles.disabled : ""}>Can Edit</MenuItem>
                                </Menu>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(shareHolder)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                });
                setShareHolderListView(
                    <div className={styles.proxySharerList}>
                        <List>
                            {shareHolderList}
                        </List>
                    </div>
                );

            } else {
                setShareHolderListView(
                    <Alert severity="info">Your team members already have access for this Proxy. You can still share it with other team members now...</Alert>
                );
            }
        } else {
            setShareHolderListView(
                <Alert severity="info">Loading details...</Alert>
            );
        }

    }, [shareHolders, anchorEl, styles, confirm, setAnchorEl, anchorEl, showMessage]);

    return (
        <>
            {messageType && message && (<Alert severity={messageType} onClose={hideMessage}>{message}</Alert>)}
            <DialogContentText className="message">
                <FormattedMessage defaultMessage="To share proxy, the user should be onboarded in the Identity
            system." />
            </DialogContentText>
            <ProxyShareForm proxyName={proxyName} onCancel={handleClose} onSuccess={onSharedUserListChange} onError={showMessage} />
            <div className={styles.proxySharerView}>
                {shareHolderListView}
            </div>

        </>
    );
};
const ProxyShareDialog: FC<PropsWithRef<ProxyShareDialogProps>> = forwardRef(({ proxyName }, ref) => {
    const styles = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={maxWidth}
                open={open}
                scroll={scroll}
                onClose={handleClose}
            >
                <DialogTitle id="form-dialog-title"><FormattedMessage defaultMessage="Share with People" /></DialogTitle>
                <DialogContent className={styles.content}>
                    <ProxySharerList proxyName={proxyName} handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </>
    );
});

export default memo(ProxyShareDialog);