import React, { FC, createContext, ReactElement, useState, useEffect, SyntheticEvent, memo } from 'react'
import { Snackbar, IconButton, SnackbarCloseReason } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

export interface SnackNotification {
    id: string,
    message: ReactElement,
    timeout?: number
}

export interface ISnackNotificationContext {
    show: (value: SnackNotification) => void
}

export const SnackNotificationContext = createContext<ISnackNotificationContext>({
    show: () => {}
});

interface GenericSnackNotificationProps {
    notification?: SnackNotification;
}

const GenericSnackNotification: FC<GenericSnackNotificationProps> = props => {
    const [showSnack, setShowSnack] = useState(false);

    useEffect(() => {
        setShowSnack(!!props.notification);
    }, [props.notification]);

    const handleClose = (_event: SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        if (reason === "clickaway") {
            return;
        }

        setShowSnack(false);
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            key={props.notification?.id}
            open={showSnack}
            autoHideDuration={props.notification?.timeout ?? 6000}
            onClose={handleClose}
            message={props.notification?.message}
            action={
                <IconButton onClick={() => setShowSnack(false)} size="small" aria-label="close" color="inherit">
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        />
    )
}

GenericSnackNotification.propTypes = {

};

export default memo(GenericSnackNotification);
