import { useState } from "react";
import { useIntl, FormattedList } from "react-intl";
import React from "react";
import { IAPIProduct } from "../../../../libs/resources/product/ProductService";
import NAWrap from "../../../../legacy-components/form/action/NAWrap";
import OpenInNewTabLink from "../../../../legacy-components/form/action/OpenInNewTabLink";

export const useProductDetailInfo = () => {
    const intl = useIntl();
    const [approvalTypes] = useState([
        {
            value: "AUTO",
            title: intl.formatMessage({
                defaultMessage: "Open"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Indicates the API Product is open for access by any user who has access to the Trimble Developer Marketplace"
            })
        },
        {
            value: "MANUAL",
            title: intl.formatMessage({
                defaultMessage: "Closed"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Indicates that the API Product is published/visible, but it requires approval by the API Provider before granting the consumer access."
            })
        },
    ]);
    const [visibilityOptions] = useState([
        {
            value: "team",
            title: intl.formatMessage({
                defaultMessage: "Team"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Visible to all the members of the team"
            })
        },
        {
            value: "sector",
            title: intl.formatMessage({
                defaultMessage: "Sector"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Visible to all the members of the sector"
            })
        },
        {
            value: "trimble",
            title: intl.formatMessage({
                defaultMessage: "Everyone at Trimble"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Visible to all the people with in Trimble"
            })
        }

    ]);

    const [compilanceOptions] = useState([
        {
            value: "iso",
            title: intl.formatMessage({
                defaultMessage: "ISO"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "This product adhering to the requirements of ISO standards"
            })
        },
        {
            value: "soc2",
            title: intl.formatMessage({
                defaultMessage: "SOC 2"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "This product meets the criteria, that comprise a SOC 2 report"
            })
        },
        {
            value: "soc3",
            title: intl.formatMessage({
                defaultMessage: "SOC 3"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "This product meets the criteria, that comprise a SOC 3 report"
            })
        },
        {
            value: "tsdcl",
            title: "TSDLC",
            helperText: intl.formatMessage({
                defaultMessage: "This product adhering to the requirements of <tsdlcLink>Trimble Secure Development Life Cycle</tsdlcLink>"
            }, {
                tsdlcLink: (chunks: string) => {
                    return (
                        <OpenInNewTabLink href="https://sites.google.com/trimble.com/cyber-defense/tsdlc">
                            {chunks}
                        </OpenInNewTabLink>
                    )
                }
            })
        }
    ]);

    return {
        approvalTypes,
        visibilityOptions,
        compilanceOptions
    }
}

export const ProductVisibilityViewer = ({ product }: {
    product: IAPIProduct
}) => {
    const { visibilityOptions } = useProductDetailInfo();
    const [visibilityOption] = useState(visibilityOptions.find(option => option.value === (product.visibility ?? "").toLowerCase()))

    return (
        <NAWrap value={visibilityOption?.title} showMessage={true}>
            {visibilityOption?.title}
        </NAWrap>
    );
}

export const ProductComplianceViewer = ({ product }: {
    product: IAPIProduct
}) => {
    const { compilanceOptions } = useProductDetailInfo();
    const [titles] = useState(() => {
        return compilanceOptions.reduce((agg: string[], option) => {
            const hasOption = (product as any)[option.value] as boolean;

            if (hasOption) {
                agg.push(option.title);
            }
            return agg;
        }, []);
    })

    return (
        <NAWrap value={titles} showMessage={true}>
            <FormattedList value={titles} type="conjunction" />
        </NAWrap>
    );
}

/*
{
            value: "partners",
            title: intl.formatMessage({
                defaultMessage: "Partners"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Visible to everyone included in the partner program"
            })
        }
        */