import React from "react";
import { Grid } from "@material-ui/core";
import { TextAreaContainer } from "modus/components/form/textarea-container/TextAreaContainer";

interface CallbackLogoutFieldProps {
  counter: number | undefined;
  label: string;
  placeholder: string;
  value: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  setError: (error: boolean) => void;
  customError: { valid: boolean; message: string };
}

const CallbackLogoutField: React.FC<CallbackLogoutFieldProps> = ({
  counter,
  label,
  placeholder,
  value,
  onChange,
  name,
  setError,
  customError,
}) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item xs={4} style={{ textAlign: "left" }}>
          {label}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "right" }}>
          <strong>{counter}</strong> / 250
        </Grid>
      </Grid>
      <TextAreaContainer
        placeholder={placeholder}
        value={value}
        name={name}
        setError={setError}
        onChange={onChange}
        validate={{
          required: true,
          custom: customError,
        }}
      ></TextAreaContainer>
    </>
  );
};

export default CallbackLogoutField;
