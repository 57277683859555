import React, {
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import {
  Typography,
} from "@material-ui/core";
import {
  StatusBadge,
  StatusBadgeInfo,
} from "legacy-components/badges/StatusBadge";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import { ISubscriptionSummary } from "../pages/subscription-summary-page/SubscriptionSummaryPage";
import { resolveDay } from 'legacy-components/Helper';

interface ProductViewProps {
  subscription: ISubscriptionSummary;
}

const ProductView: React.FC<ProductViewProps> = ({ subscription }) => {
  const [publishStatus] = useState<StatusBadgeInfo>({
    kind: "access",
    key: subscription?.status,
    access: false,
  });

  return (
    <>
      <FormViewInliner
        gutter="gutter-bottom"
        title={<FormattedMessage defaultMessage="Product Name" />}
      >
        <Typography variant="body1">{subscription?.productName}</Typography>
      </FormViewInliner>

      <FormViewInliner
        gutter="gutter-bottom"
        title={<FormattedMessage defaultMessage="Subscribed Date" />}
      >
        <Typography variant="body1">
          {resolveDay(subscription?.subscriptionRequestedTime)}
        </Typography>
      </FormViewInliner>

      <FormViewInliner
        gutter="gutter-bottom"
        title={<FormattedMessage defaultMessage="Subscribed By" />}
      >
        <Typography variant="body1">{subscription?.subscriberEmail}</Typography>
      </FormViewInliner>

      <FormViewInliner
        gutter="gutter-bottom"
        title={<FormattedMessage defaultMessage="Status" />}
      >
        <Typography variant="body1">
          <StatusBadge align="right" status={publishStatus} />
        </Typography>
      </FormViewInliner>
    </>
  );
};


export default ProductView;
