import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import FormViewInliner from 'legacy-components/form/container/FormViewInliner';
import NAWrap from 'legacy-components/form/action/NAWrap';
import FormInputContainer from 'legacy-components/form/container/FormInputContainer';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { ProxyVerificationBusinessValueInfo } from 'pages/home/proxy/summary/verification/models/verification.model';

interface ProxyVerificationBusinessValueViewProps {
    proxyVerificationBusinessValueInfo?: ProxyVerificationBusinessValueInfo | null,
    onSuccess?: () => void
}

const ProxyVerificationBusinessValueView: FC<ProxyVerificationBusinessValueViewProps> = ({ proxyVerificationBusinessValueInfo }) => {
    const businessValues = ["REUSABILITY", "MATURITY", "GO_TO_MARKET"]
    function capitalizeName(name: string) {
        return name.toLowerCase().replaceAll(/_/g, ' ').replace(/\b(\w)/g, s => s.toUpperCase());
    }
    return (

        <FormInputContainer title={
            <FormattedMessage defaultMessage="Describe how your API Proxy can be established as a Core API to be reused across Sectors and Divisions in Trimble." />
        }>
            {businessValues?.map((businessValue, index) => {
                let capitalised = capitalizeName(businessValue);
                if (proxyVerificationBusinessValueInfo?.businessValue?.includes(businessValue)) {
                    return (<FormViewInliner key={index} variant="inline" gutter="gutter-bottom" title={<span>{capitalised}</span>} >
                        <Typography variant="body1">
                            <NAWrap value={businessValue} showMessage={true}>
                                {<DoneIcon color="primary" />}
                            </NAWrap>
                        </Typography>
                    </FormViewInliner>
                    );
                } else {
                    return (<FormViewInliner key={index} variant="inline" gutter="gutter-bottom" title={<span>{capitalised}</span>} >
                        <Typography variant="body1">
                            <NAWrap value={businessValue} showMessage={true}>
                                {<CloseIcon color="primary" />}
                            </NAWrap>
                        </Typography>
                    </FormViewInliner>
                    );
                }
            })}
            <FormViewInliner variant="inline" gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Additional Input" />} >
                <Typography variant="body1">
                    <NAWrap value={proxyVerificationBusinessValueInfo?.businessValueAdditionalInput} showMessage={true}>
                        {proxyVerificationBusinessValueInfo?.businessValueAdditionalInput}
                    </NAWrap>
                </Typography>
            </FormViewInliner>
        </FormInputContainer>

    );
}

ProxyVerificationBusinessValueView.propTypes = {
}

export default ProxyVerificationBusinessValueView;