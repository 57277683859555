import React, { useCallback, memo, FC, useState } from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import { FormattedMessage } from 'react-intl';
import DeleteIcon from '@material-ui/icons/Delete';
import { useConfirm } from 'material-ui-confirm';
import UnifiedAPIService, { UnifiedAPI } from 'services/UnifiedAPIService';
import { useHistory } from 'react-router';

interface IMessageProp {
    message: string,
    messageType: string
}
interface ProductDeleteActionProps {
    productName: string
    onDeleteTriggered?: (test: IMessageProp) => void
    isProxiesMapped : boolean
    hasSubscriptions : boolean
}
const ProductDeleteAction: FC<ProductDeleteActionProps> = ({ productName, isProxiesMapped, hasSubscriptions, onDeleteTriggered }) => {
    const confirm = useConfirm();

    let history = useHistory();
    const [defaultMessage, setDefaultMessage] = useState(isProxiesMapped ? "Deleting the product will unmap the proxies associated with it." : ""); 
    const [toolTipMessage, setToolTipMessage] = useState(hasSubscriptions ? "Product with subscriptions cannot be deleted" : "Delete this Product");
  

    const handleDelete = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        confirm({ 
            description:  defaultMessage  + 'Do you want to delete ' + productName + ' product permanently?' })
            .then(() => {
                UnifiedAPIService.delete(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.delete_product_api, {
                        productName: productName
                    })
                ).then(
                    (response: any) => {
                        response.data = {
                            message: productName + " Product is deleted. "
                        }
                        onDeleteTriggered && onDeleteTriggered({
                            message: "Product has been deleted",
                            messageType: "success"
                        });
                        setTimeout(() => {
                            history.push("/home/products/list");
                        }, 6000);
                    },
                    (error: any) => {
                        if (error.response.status === 500) {
                            onDeleteTriggered && onDeleteTriggered({
                                message: "Error occured while deleting product",
                                messageType: "error"
                            });
                        } else {
                            onDeleteTriggered && onDeleteTriggered({
                                message: error.response?.data?.message,
                                messageType: "error"
                            });
                        }
                    });
            });
    }, [history]);


    const deleteButton = (
        <Tooltip title={toolTipMessage} >
            <span>
            <IconButton onClick={handleDelete} size="small" disabled = {hasSubscriptions}>
                <DeleteIcon />
            </IconButton>
            </span>
        </Tooltip>
    );
    return (
        <>{deleteButton}
        </>
    )
}

export default memo(ProductDeleteAction);