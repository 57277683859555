import React, { useState } from 'react';
import {
    ListItem,
    ListItemText,
    List,
    ListItemSecondaryAction,
    Divider,
    Radio,
    ListItemIcon,
    Switch,
    Tooltip
} from '@material-ui/core';
import { FormSelectionGroupProps, useFieldSelectionStyles } from './FormSelection';
import { useForm } from 'react-final-form';
import useIsMounted from '../action/UseIsMounted';

const FormSelectionGroup = ({ options, defaultSelectionIndex, input, disabled, isSwitch = false }: FormSelectionGroupProps) => {
    const fieldSelectionStyle = useFieldSelectionStyles();
    const form = useForm();
    const isMounted = useIsMounted();

    const [selectedItem, setSelectedItem] = useState<string | undefined>(input.value ?? "");

    const setSelected = (value: string) => {
        if (isMounted) {
            form.change(input.name, value);
            form.mutators?.setFieldTouched?.(input.name, true);

            setSelectedItem(value);
        }
    }

    if (selectedItem === "" && defaultSelectionIndex?.toFixed && options[defaultSelectionIndex]) {
        setSelected(options[defaultSelectionIndex].value)
    }

    return <List>
        {options.map(({ title, helperText, value, icon, disabled: optionDisabled}) => {
            return (
                <React.Fragment key={value}>
                    <ListItem disabled={disabled ?? optionDisabled} dense button onClick={() => optionDisabled || setSelected(value)}>
                        {
                            icon && <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                        }
                        <ListItemText primary={title} secondary={helperText} />
                        <ListItemSecondaryAction>
                            {!isSwitch && (
                            <>
                            {optionDisabled &&
                            <Tooltip title={"This feature is deprecated now"} placement="left" arrow>
                                <Radio
                              edge="end"
                              {...input}
                              onChange={() => optionDisabled || setSelected(value)}
                              checked={selectedItem === value}
                              value
                              disabled={disabled ?? optionDisabled}
                              color="primary"
                            />
                            </Tooltip>
                           }
                           {!optionDisabled &&
                                <Radio
                              edge="end"
                              {...input}
                              onChange={() => optionDisabled || setSelected(value)}
                              checked={selectedItem === value}
                              value
                              disabled={disabled ?? optionDisabled}
                              color="primary"
                            />
                           }
                            </>) }
                            {isSwitch && (<Switch
                              edge="end"
                              {...input}
                              onChange={() => optionDisabled || setSelected(value)}
                              checked={selectedItem === value}
                              value
                              disabled={disabled ?? optionDisabled}
                              color="primary"
                            />) }
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider className={fieldSelectionStyle.divider} component="li" />
                </React.Fragment>
            );
        })}
    </List>
};

export default FormSelectionGroup;