import React, { FC, useCallback } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ResourceAbility } from "libs/security/authorization";
import ProductGrid from "./ProductGrid";
import { FormattedMessage } from "react-intl";
import GenericPageHeader from "../../../legacy-components/page-headers/GenericPageHeader";
import { useGridViewStyles } from "../../../legacy-components/grid-view/GridView";

const Controls = ({ toProductCreate }: { toProductCreate: () => void }) => {
     return (
          <ResourceAbility can="create" resource={({ Product }) => Product.create}>
               <Button startIcon={<AddIcon />} onClick={toProductCreate} variant="contained" color="primary">
                    <FormattedMessage defaultMessage="Add Product" />
               </Button>
          </ResourceAbility>
     )
}

/**
 * @class ProductList
 * @description component contains API Product details
 */
const ProductList: FC<any> = props => {
     const gridViewStyles = useGridViewStyles();

     const toProductCreate = useCallback(() => {
          props.dispatch(props.history.push("/home/products/create"));
     }, [props]);

     return (
          <div className={gridViewStyles.root}>
               <GenericPageHeader title={<FormattedMessage defaultMessage="API Products" />} controls={<Controls toProductCreate={toProductCreate} />} />
               <div className={gridViewStyles.content}>
                    <ProductGrid />
               </div>
          </div >
     );
}



/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state: any) => {
     return {
          isGrid: state.common.isGrid
     }
};
export default withTheme(withRouter(connect(mapStateToProps)(ProductList)));