import React from "react";
import { createStyles, Dialog, makeStyles } from "@material-ui/core";

import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Carousel from 'react-material-ui-carousel';
import APIProxyHelper from "./APIProxyHelper";
import APIHelper from "./APIHelper";
import APIProductHelper from "./APIProductHelper";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            maxHeight: '74vh',
            overflowY: 'auto',
            minHeight: '74vh'
        }
    })
);

const MainHelper = (props: any) => {
    const classes = useStyles();

    const helperContents: Array<any> = [
        <APIHelper/>,
        <APIProxyHelper/>,  
        <APIProductHelper/>
    ]
    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.handleClose}
            disableBackdropClick={false}
            fullWidth={true}
            maxWidth="md"
            className="helper"
        >
            {props.open && (
                <Carousel interval={60000}>
                {helperContents.map((content, index) => (
                    <section className={classes.container} key={index}>
                        {content}
                    </section>
                ))}
                </Carousel>
            )}
        </Dialog>
    );

}
export default MainHelper;