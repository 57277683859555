import React, { memo } from "react";
import {
  SvgIconProps,
  SvgIcon,
  makeStyles,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles(({ spacing, palette, typography }) =>
  createStyles({
    MarketplaceIcon: {
      width: "1rem",
      height: "auto",
    },
  })
);

function MarketplaceIcon(props: SvgIconProps) {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      fill="none"
      className={classes.MarketplaceIcon}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4H4V6H20V4ZM21 14V12L20 7H4L3 12V14H4V20H14V14H18V20H20V14H21ZM6 18H12V14H6V18Z"
        fill="inherit"
      />
    </SvgIcon>
  );
}

export default memo(MarketplaceIcon);
