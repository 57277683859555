import React, { FC, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { InputContainer } from "modus/components/form/input-container/InputContainer";
import { TextAreaContainer } from "modus/components/form/textarea-container/TextAreaContainer";
import { Checkbox } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { FormControlLabel } from "@material-ui/core";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import { makeStyles, createStyles } from "@material-ui/core";
import FormAlert from "legacy-components/form/container/FormAlert";
import PublisherApplicationService from "../PublisherApplicationService";
import ApplicationService from "../ApplicationService";
import { useSelector } from "react-redux";
import { EnvironmentType } from "header/environment-selector/ENV_CONSTANTS";

export interface AppResponse {
  applicationName: string;
  environment: string;
}

interface ResourceAppBasicFormProps {
  onCancel: () => void;
  onSuccess?: (application: AppResponse) => void;
}
const useStyles = makeStyles(() =>
  createStyles({
    formLayout: {
      display: "flex",
      width: "100%",
    },
    formContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    submitLoader: {
      position: "absolute",
      zIndex: 2,
      width: "100%",
      height: "100%",
      backgroundColor: "rgb(255, 255, 255, 0.32)",
    },
  })
);

const ResourceAppBasicForm: FC<ResourceAppBasicFormProps> = ({
  onCancel,
  onSuccess,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const identityEnvironment = useSelector(
    (state: any) => state?.common?.identityEnvironment
  );

  const canShowCreateResourceApp =
    process.env.REACT_APP_NEW_RESOURCE_APP_CREATION === "true";
  const [error, setError] = useState({
    is_error: false,
    message: "",
  });
  const [formValues, setFormValues] = useState({
    name: "",
    displayName: "",
    description: "",
    type: false,
  });
  const [hasFieldError, setHasFieldError] = useState(false); // New state to track child component validation error
  const [hasFieldError2, setHasFieldError2] = useState(false); // New state to track child component validation error
  const [hasFieldError3, setHasFieldError3] = useState(false); // New state to track child component validation error

  const handleSetError = (value: boolean) => {
    setHasFieldError(value); // Track error from child component
  };

  const handleSetError2 = (value: boolean) => {
    setHasFieldError2(value); // Track error from child component
  };
  const handleSetError3 = (value: boolean) => {
    setHasFieldError3(value); // Track error from child component
  };

  const FormErrorViewer = ({ errors }: { errors?: string[] }) => {
    return (
      <>
        {errors?.map((error, index) => (
          <div key={index}>{error}</div>
        ))}
      </>
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoader(true);

    if (!hasFieldError || !hasFieldError2 || !hasFieldError3) {
      PublisherApplicationService.environments[
        identityEnvironment as EnvironmentType
      ]
        .create({
          name: formValues.name,
          description: formValues.description,
          displayName: formValues.displayName,
          applicationType: formValues.type ? "resource-server" : "application",
        })
        .then(
          (result: any) => {
            ApplicationService.publish(
              "publish",
              identityEnvironment,
              formValues.name,
              {
                logoutUri: [],
                redirectUri: [],
                grantTypes: [
                  "client_credentials",
                  "refresh_token",
                  "urn:ietf:params:oauth:grant-type:token-exchange",
                  "urn:ietf:params:oauth:grant-type:device_code",
                ],
              }
            ).then(
              (result: any) => {
                onSuccess &&
                  onSuccess({
                    applicationName: formValues.name,
                    environment: identityEnvironment,
                  });
                onCancel();
                setLoader(false);
              },
              (error: any) => {
                if (error) {
                  setSuccess(false);
                  setError({ is_error: true, message: error });
                }
                setLoader(false);
              }
            );
          },
          (error: any) => {
            if (error) {
              setSuccess(false);
              setError({ is_error: true, message: error });
            } else {
              setSuccess(true);
            }
            setLoader(false);
          }
        );
    }
  };
  return (
    <form
      className={styles.formLayout}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Box className={styles.formContainer}>
        {loader && <Box className={styles.submitLoader}></Box>}
        {error.is_error && (
          <FormAlert severity="error">
            <FormErrorViewer errors={[error.message]} />
          </FormAlert>
        )}
        {success && (
          <FormAlert severity="success">
            {"Application created Successfully"}
          </FormAlert>
        )}

        <InputContainer
          label={intl.formatMessage({
            defaultMessage: "Name",
          })}
          placeholder={intl.formatMessage({
            defaultMessage: "Enter the Application Name",
          })}
          value={formValues.name}
          onChange={handleChange}
          name={"name"}
          setError={handleSetError}
          validate={{
            required: true,
            minLength: 3,
            maxLength: 64,
            pattern: "^[a-zA-Z0-9-_]+$",
          }}
          required
        ></InputContainer>

        <InputContainer
          label={intl.formatMessage({
            defaultMessage: "Display Name",
          })}
          placeholder={intl.formatMessage({
            defaultMessage: "Enter the Application Name",
          })}
          value={formValues.displayName}
          onChange={handleChange}
          name={"displayName"}
          validate={{
            required: true,
            minLength: 3,
            maxLength: 64,
            allowSpace: true,
            pattern: "^[a-zA-Z0-9-_ ]+$",
          }}
          setError={handleSetError2}
          required
        ></InputContainer>

        <TextAreaContainer
          label={"Description"}
          placeholder={"Enter the details about application for reference"}
          value={formValues.description}
          name={"description"}
          onChange={handleChange}
          validate={{
            maxLength: 500,
          }}
          setError={handleSetError3}
        ></TextAreaContainer>

        {canShowCreateResourceApp && (
          <Box>
            <FormControlLabel
              style={{ margin: "0px" }}
              control={
                <Checkbox
                  style={{ color: "#217CBB" }}
                  checked={formValues.type}
                  name="type"
                  onChange={handleChange}
                />
              }
              label="Also a resource Server"
            />
            <Tooltip
              title="Check this box if you intend to configure API or Event services associated with this Application."
              placement="right"
              arrow
            >
              <IconButton
                style={{
                  color: "#252A2E",
                  width: "24px",
                  height: "24px",
                  marginLeft: "5px",
                }}
              >
                <InfoIcon fontSize="default" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <ActionInliner padding="inset-top">
          <Button
            onClick={onCancel}
            type="button"
            variant="outlined"
            disableElevation
          >
            {" "}
            <FormattedMessage defaultMessage="Cancel" />
          </Button>

          <Button
            onSubmit={handleSubmit}
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            disabled={
              hasFieldError ||
              hasFieldError2 ||
              !formValues.name ||
              !formValues.displayName
            }
          >
            <FormattedMessage defaultMessage="Create" />
          </Button>
        </ActionInliner>
        <Box pb={2} />
      </Box>
    </form>
  );
};

export default ResourceAppBasicForm;
