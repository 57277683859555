import { ReactElement, memo, useMemo } from 'react'
import PropTypes from 'prop-types';
import { useAuthResolver } from './Permission';

export const Feature = {
    Approval: 'approvals',
    ApiVerification: 'api_verification',
    PartnerMarketplace : 'partner_marketplace',
    DirectEndpoint: 'direct_endpoint'
} as const

type Feature = typeof Feature[keyof typeof Feature]

export interface FeatureAbiltiyAction {
    Feature: Feature
}

interface FeatureAbilityComponentProps {
    id: Feature,
    children: ReactElement,
    fallBack?: ReactElement,
    role?: string
}

function FeatureAbility(props: FeatureAbilityComponentProps) {
    const [rolePermission] = useAuthResolver();

    const feature = useMemo(() => rolePermission?.features, [rolePermission?.features]);
    const features: Array<string> = [];
    if (feature) {
        rolePermission?.features?.map((item) => {

            features.push(item.name);
        });
        return features.includes(props.id) ? props.children : (props.fallBack ?? null);
    } else {
        return null;
    }
}

FeatureAbility.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired
}

export default memo(FeatureAbility)

