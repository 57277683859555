import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, useCallback } from 'react';
import { FormSelectionGridProps, FormSelectionGridData, FormSelectionGridDataValue, FormSelectionGridFieldValue } from './FormSelection';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent, GridApi, RowNode } from 'ag-grid-community';
import { CircularProgress, Typography, IconButton, makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import UndoIcon from '@material-ui/icons/Undo';
import { AssignableCollectionItem, useAssignableCollection, AssignableCollectionFinder } from '../collection/AssignableCollection';
import { useForm } from 'react-final-form';
import { useGridStyles } from '../../grid/GridStyle';

const useStyles = makeStyles(() => {
    return createStyles({
        grid: {
            height: "420px"
        }
    })
});

enum FrameworkComponentsType {
    LoadingOverlay = "LOADING_OVERLAY",
    NoRowsOverlay = "NO_ROWS_OVERLAY",
    ActionCellRenderer = "ACTION_CELL_RENDERER"
}

type FrameworkComponentState = { [K in FrameworkComponentsType]: (...params: any) => JSX.Element };

const LoadingComponent = () => <CircularProgress size={32} />;
const NoRowsComponent = (props: FormSelectionGridProps["gridOptions"]) => (
    <Typography variant="body2">
        {props?.messages?.nodata ?? <div></div>}
    </Typography>
);

const ActionCellComponent = ({ value }: {
    value: AssignableCollectionFinder<FormSelectionGridDataValue>
}) => {
    return value.assigned && !value.assign ?
        (
            <IconButton onClick={value.toggleHandler} size="small" edge="end">
                <UndoIcon />
            </IconButton>
        ) : (
            <IconButton onClick={value.toggleHandler} size="small" edge="end">
                <RemoveCircleOutlineIcon />
            </IconButton>
        );
}

const getRowNodeId = (data: FormSelectionGridDataValue) => data.id;

export function createGridDataCollection(gridData?: FormSelectionGridData[]): AssignableCollectionItem<FormSelectionGridDataValue>[] | undefined {
    return gridData?.map(item => ({
        key: item.value.id,
        value: item.value,
        assign: item.selection.assign,
        assigned: item.selection.assigned
    }))
}

export interface FormSelectionGridRef {
    revertChanges: () => void,
    commitChanges: () => void
}

const FormSelectionGrid = forwardRef<FormSelectionGridRef, FormSelectionGridProps>(({ gridOptions, onCellClicked, name, input, gridData, readOnly }, ref) => {
    const gridStyle = useGridStyles();
    const styles = useStyles();
    const form = useForm();

    const [columnDefs] = useState<ColDef[]>(() => {
        const columns: ColDef[] = gridOptions?.columns?.map(({ title: headerName, key: field, align, width, hidden, component, minWidth}) => {
            return {
                field,
                headerName,
                maxWidth: width,
                minWidth: minWidth,
                ...(align === "right" ? {
                    pinned: "right",
                    type: 'rightAligned'
                } : {}),
                hide: hidden,
                flex: 1,
                cellRendererFramework: component
            }
        }) ?? [];

        if (!readOnly && columns.length) {
            columns.push({
                field: "source",
                headerName: "",
                pinned: "right",
                type: 'rightAligned',
                width: 56,
                suppressSizeToFit: true,
                headerClass: "form-selection-grid__action-cell",
                cellClass: "form-selection-grid__action-cell",
                cellRenderer: FrameworkComponentsType.ActionCellRenderer
            });
        }

        return columns;
    });

    const [frameworkComponents] = useState<FrameworkComponentState>({
        [FrameworkComponentsType.LoadingOverlay]: LoadingComponent,
        [FrameworkComponentsType.NoRowsOverlay]: NoRowsComponent,
        [FrameworkComponentsType.ActionCellRenderer]: ActionCellComponent
    });

    const [list, setList] = useState<{
        value: AssignableCollectionItem<FormSelectionGridDataValue>[],
        prevValue: AssignableCollectionItem<FormSelectionGridDataValue>[]
    }>({
        value: [],
        prevValue: []
    });

    const [gridApi, setGridApi] = useState<GridApi | undefined>();

    const [collection, { withHandlers, toArray }] = useAssignableCollection(list);
    const formGridRef = useRef({
        withHandlers,
        toArray,
        updateValue: (value: FormSelectionGridFieldValue) => {
            input?.onChange(value);

            form.mutators?.setFieldTouched?.(name, true);
        },
        initList: false
    });

    const revertChanges = useCallback(() => {
        setList(state => {
            return {
                value: state.prevValue,
                prevValue: state.prevValue
            }
        })
    }, []);

    const commitChanges = useCallback(() => {
        setList(() => {
            const value = formGridRef.current.toArray().filter(value => value.assign).map(item => {
                return {
                    ...item,
                    assign: true,
                    assigned: true
                }
            });

            return {
                value,
                prevValue: value
            }
        })

    }, []);

    useImperativeHandle(ref, () => ({
        revertChanges: () => revertChanges(),
        commitChanges: () => commitChanges()
    }), [revertChanges, commitChanges]);

    useEffect(() => {
        if (Array.isArray(gridData)) {
            const collectionList = createGridDataCollection(gridData);

            if (collectionList) {
                if (!formGridRef.current?.initList) {
                    formGridRef.current.initList = collectionList.length > 0;
                    setList({
                        value: collectionList,
                        prevValue: collectionList
                    });
                } else {
                    setList((state) => {
                        return {
                            value: collectionList,
                            prevValue: state.value
                        }
                    });
                }
            }
        }
    }, [gridData]);

    useEffect(() => {
        if (gridApi) {
            const selectionMap = new Map(formGridRef.current.withHandlers(source => {
                return [
                    source.key,
                    {
                        source,
                        ...source.value
                    }
                ]
            }) as [string, unknown][]);

            const toRemove: unknown[] = [];
            const toUpdate: unknown[] = [];

            gridApi.forEachNode(node => {
                if (selectionMap.has(node.id)) {
                    toUpdate.push(selectionMap.get(node.id))
                } else {
                    toRemove.push({ id: node.id })
                }

                selectionMap.delete(node.id);
            });

            const toAdd = Array.from(selectionMap.values());

            gridApi.applyTransactionAsync({
                add: toAdd,
                update: toUpdate,
                remove: toRemove
            });
        }

    }, [collection, gridApi, formGridRef]);

    useEffect(() => {
        const toAdd = Array.from(collection.toAdd.values());
        const toRemove = Array.from(collection.toRemove.values());
        const list = formGridRef.current.toArray()

        formGridRef.current.updateValue({
            list,
            toAdd,
            toRemove,
            hasChanges: toAdd.length > 0 || toRemove.length > 0,
            count: list.filter(value => value.assign).length
        });

    }, [collection, formGridRef]);

    const onGridReady = (event: GridReadyEvent) => setGridApi(event.api);

    const isExternalFilterPresent = () => true;

    const doesExternalFilterPass = ({ data }: RowNode) => {
        const source: AssignableCollectionFinder<FormSelectionGridDataValue> = data.source;

        return !!(source?.assign);
    }

    return (
        <div className={clsx("ag-theme-alpine", gridStyle.root, styles.grid, {
            [`${gridStyle.header}--tinted`]: gridOptions?.headerStyle === "tinted"
        })}>
            <AgGridReact
                columnDefs={columnDefs}
                frameworkComponents={frameworkComponents}
                loadingOverlayComponent={FrameworkComponentsType.LoadingOverlay}
                noRowsOverlayComponent={FrameworkComponentsType.NoRowsOverlay}
                noRowsOverlayComponentParams={gridOptions}
                suppressRowClickSelection={true}
                suppressCellSelection={true}
                suppressRowHoverHighlight={true}
                getRowNodeId={getRowNodeId}
                onGridReady={onGridReady}
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
                rowData={[]}
                onCellClicked = {onCellClicked}
            />
        </div>);
});

export default FormSelectionGrid;
