
import { Row } from "legacy-components/responsive-grid/ResponsiveGrid";
import React, { useEffect } from "react";
import { useCallback, useState } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router";
import * as Product from "./Product";

interface ProductPageProps {
    productName: string;
}
export const ProductPage: React.FC<RouteComponentProps<ProductPageProps>> = ({match}) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const switchTab = useCallback((tabId: number) => {
    setCurrentTab(tabId);
  }, []);
  const location = useLocation();

  useEffect(() => {
    if (location?.state as any && (location?.state as any).isApplicationCreated) {
      switchTab(2)
    }
  },[location, location.state]);
  return (
    <main>
      <Row margin="2">
      <Product.Data name={match.params.productName}>
        <Product.Basics switchTab={switchTab} />
        <Product.TabMenu
          initialTab={currentTab}
          specUrl={process.env.REACT_APP_SPEC_URL as string}
        />
      </Product.Data>
    </Row>
    </main>
  );
};

export default withRouter(ProductPage);
