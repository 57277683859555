import React from "react";
import { Divider, Paper } from "@material-ui/core";
import { ProductContext } from "../Product";
import { APIEndpoints } from "./APIEndpoints";
import { ProductBasics } from "./Basics";
import { SupportInfo } from "./SupportInfo";
import { Column } from "legacy-components/responsive-grid/ResponsiveGrid";

// API Summary Details
export const Summary: React.FC = () => (
  <Paper>
    <ProductContext.Consumer>
      {(context) => (
        <Column data-testid="product-summary" padding="3">
          <ProductBasics />
          <Divider />
          {context?.product?.summary?.apiEndPoints && (
            <APIEndpoints endpoints={context?.product.summary.apiEndPoints} />
          )}
          <Divider />
          <SupportInfo teamDetail = {context?.team}/>
        </Column>
      )}
    </ProductContext.Consumer>
  </Paper>
);
