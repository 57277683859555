import { Box, FormControl, MenuItem, Select, useTheme } from "@material-ui/core";
import ModusIcon from "legacy-components/icons/ModusIcon";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions/actions";
import { DEFAULT_IDENTITY_ENVIRONMENT, IDENTITY_ENVIRONMENT_TYPE } from "./ENV_CONSTANTS";


/**
 * @component EnvironmentSelector
 * @description Renders a selector component for choosing the environment.
 * @returns {JSX.Element} - The rendered component.
 */
export const EnvironmentSelector = () => {
    // Define the dispatch function to dispatch actions
    const dispatch = useDispatch();

    // Get the current theme from the Material-UI theme provider
    const theme = useTheme();
    
    // Get the selected identity environment from the Redux store
    const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment ?? DEFAULT_IDENTITY_ENVIRONMENT);

    useEffect(() => {
        if(!dispatch) {
            return
        }
        dispatch({ type: actions.saveIdentityEnvironment, request: { identityEnvironment: DEFAULT_IDENTITY_ENVIRONMENT } });
    },[dispatch]);
    
    /**
     * Handles the change event of the environment selector.
     * Dispatches an action to save the selected identity environment.
     * 
     * @param {React.ChangeEvent<any>} event - The change event object.
     */
    const onChange = (event: React.ChangeEvent<any>) => {
        dispatch({ type: actions.saveIdentityEnvironment, request: { identityEnvironment: event.target.value } });
    }

    return (
        <Box style={{position: "relative"}}>
        <FormControl>
            <Select 
                id="environment-selector"
                value={identityEnvironment ?? DEFAULT_IDENTITY_ENVIRONMENT}
                variant="outlined"
                className="header-selector"
                style={{marginLeft: "15px" }}
                onChange={onChange}

                IconComponent={( props: any ) => <Box style={{
                    color: `${theme.palette.grey['800']} !important`,
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                    zIndex: -1
                }}
                
                >
                <ModusIcon
                  name={'expand_more'}
                />
                </Box>
                }
            >
                <MenuItem value={IDENTITY_ENVIRONMENT_TYPE.PRE_PRODUCTION}>Pre-production</MenuItem>
                <MenuItem value={IDENTITY_ENVIRONMENT_TYPE.PRODUCTION}>Production</MenuItem>
            </Select>
        </FormControl>
        </Box>
    );
}