import { createStyles, makeStyles } from "@material-ui/core/styles";
import type { Theme } from "@material-ui/core";

const useGenericPageTitleStyle = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      width: "inherit",
    },
    controlSkeleton: {
      margin: "auto",
      marginRight: 0,
      transform: "none !important",
    },
  })
);
export default useGenericPageTitleStyle;
