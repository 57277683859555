import React, { FC } from 'react'
import { FormRenderProps, Field } from 'react-final-form';
import { Button } from '@material-ui/core';
import { FormApi, FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import UnifiedAPIService, { UnifiedAPI } from 'services/UnifiedAPIService';
import FormInputContainer from 'legacy-components/form/container/FormInputContainer';
import ActionInliner from 'legacy-components/form/action/ActionInliner';
import FormContainer from 'legacy-components/form/container/FormContainer';
import FormTextInput from 'legacy-components/form/input/FormTextInput';


export interface SoftwareProductFormData {
    Namespace?: string;

}

interface AddNameSpaceFormProps {
    onCancel: () => void,
    onSuccess: (data: any, type?: string) => void,
    onError: (data?: any, type?: string) => void,
    sectorName: string
}

const AddNameSpaceForm: FC<AddNameSpaceFormProps> = ({ sectorName, onCancel, onSuccess, onError }) => {
    const intl = useIntl();

    const onSubmit = () => (data: SoftwareProductFormData, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve(null);
            } else {
                UnifiedAPIService.post(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.add_team_namespaces, {
                        sectorName: sectorName,
                    }),
                    [{ name: data.Namespace }],
                ).then((response) => {
                    response.data = {
                        message: "Added "
                    }
                    onSuccess(response, "success");
                    resolve(null)
                }, error => {
                    onError(error?.response, "error");
                    resolve({
                        [FORM_ERROR]: error.response?.data?.message ?? "Unknown error occured while sharing the proxy"
                    });
                });
            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        return (<>
            <FormInputContainer>
                <Field
                    name="Namespace"
                    type="text"
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Namespace"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Namespace"
                    })}
                />
            </FormInputContainer>
            <div>
                <h4>Guidelines</h4>
                <ul>
                    <li>Use Trimble's official product names only. Please refrain from using project names.</li>
                    <li> Namespaces must follow proper casing and spacing as defined in their official name.</li>
                    <li>For consistency, Trimble Cloud Console will follow internal naming convention standards for proxy URL. For example, a namespace will be in lowercase and spacing will be replaced by a dash.</li>
                </ul>
            </div>
            <ActionInliner>
                <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Add" /></Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

export default AddNameSpaceForm;