import { AccordionContent } from "legacy-components/form/components/accordion-form/AccordionForm";
import { IdentityApplicationSubscriptionGridView } from "libs/resources/subscription/IdentityApplicationSubscriptionGridView";
import React, {  } from "react";
import { FormattedMessage } from "react-intl";
import { IdentityApplication } from "../PublisherApplicationService";

interface IdentityApplicationSubscriptionViewProps {
    application: IdentityApplication
}

export const IdentityApplicationSubscriptionsView: React.FC<IdentityApplicationSubscriptionViewProps> = 
    ({application}) => {
    
    return (
        <>
            <AccordionContent size="small">
               <IdentityApplicationSubscriptionGridView applicationName={application.applicationName} gatewayName={application.organisationName} />
            </AccordionContent>
        </>
    )
}

export default IdentityApplicationSubscriptionsView;