import React, { FC } from "react";
import { DialogTitle, IconButton, Divider } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from "react-intl";
import { useStyles } from "./ProductProxyDialog";
import ProductUpdateForm from "./ProductUpdateForm";
import { ProductUpdateFormData } from "../models/products.model";

interface ProductUpdateProps {

    productName: string
    product: ProductUpdateFormData
    onClose: () => void
    onSuccess: (data?: any) => void
    onError?: (error?: any) => void
}

export const ProductUpdate: FC<ProductUpdateProps> = ({ productName, product, onClose, onSuccess }) => {
    const style = useStyles();

    return (
        <div>
            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Update Product" />
                </DialogTitle>

                <IconButton className={style.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>

                <Divider />
            </div>
            <div className={style.createForm}>
                <ProductUpdateForm productName = {productName} initialValues={product} stepperId="product-updation" onSuccess={onSuccess} />
            </div>
        </div>
    )
}

