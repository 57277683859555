import React, { useCallback, memo, FC, forwardRef, useImperativeHandle, PropsWithRef, Ref, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, createStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useConfirm } from 'material-ui-confirm';
import { List, ListItem, ListItemText } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import { useCompactDialog } from "legacy-components/dialog/UseCompactDialog";
import AddNameSpaceForm from "./AddNameSpaceDialog";

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        content: {
            padding: spacing(3),
            paddingTop: 0
        },
        proxySharerView: {
            marginTop: '30px',
            maxHeight: 'calc( 80vh - 450px)',
            overflowY: 'auto'
        },
        proxySharerList: {
            borderTop: '1px solid #ccc'
        },
        disabled: {
            pointerEvents: 'none',
            color: '#005F9E'
        },
        message: {
            marginTop: '15px'
        }
    }),
);

export interface NameSpaceDialogRef {
    open: () => void
}

interface NameSpaceDialogProps {
    sectorName: string,
    ref: Ref<NameSpaceDialogRef | undefined>
}

interface ISoftwareProductList {
    sectorName: string,
    handleClose: () => void
}
const SoftwareProductList: FC<ISoftwareProductList> = ({ sectorName, handleClose }) => {
    const styles = useStyles();
    const confirm = useConfirm();
    const [nameSpacesListView, setNameSpacesListView] = useState((<div>Loading...</div>));
    const [nameSpaces, setNamespaces] = useState<any>(null);
    const [messageType, setMessageType] = useState<"success" | "info" | "warning" | "error" | null>(null);
    const [message, setMessage] = useState<string | null>(null);


    const showMessage = useCallback((response: any, type?: string) => {
        if (!type) {
            type = "success";
        }
        if (response?.data?.message) {
            setMessage(response?.data?.message);
            setMessageType((type as ("success" | "info" | "warning" | "error" | null)));
        }
    }, [setMessageType, setMessage]);

    const getSoftwareProductList = useCallback(() => {
        UnifiedAPIService.get(
            UnifiedAPI.parseRequestURL(UnifiedAPI.get_sector_namespaces, {
                sectorName : sectorName
            })
        ).then(
            (response: any) => {
                setNamespaces(response.data.nameSpaces);
        }).catch(
            () => {
                setNamespaces([]);
            }
        );
            
    }, [sectorName]);

    useEffect(() => {
        getSoftwareProductList();
    }, [getSoftwareProductList]);

    const onSharedUserListChange = (response: any, type?: string) => {
        getSoftwareProductList();
        showMessage(response, type);
    }

    const hideMessage = useCallback(() => {
        setMessageType(null);
        setMessage(null);
    }, [setMessageType, setMessage]);

    const [anchorEl, setAnchorEl] = React.useState<Array<any>>([]);

    if ((anchorEl === null || anchorEl?.length === 0) && (nameSpaces && nameSpaces.length > 0)) {
        let anchors: Array<any> = [];
        nameSpaces.forEach(() => {
            anchors.push(null);
        });
        setAnchorEl(anchors);
    }
    useEffect(() => {

        if (nameSpaces && anchorEl) {
            if (nameSpaces && nameSpaces?.length > 0) {
                let softwareProductList: Array<any> = [];
                nameSpaces?.forEach((product: any, index: number) => {

                    softwareProductList.push(
                        <ListItem button key={product + " " + index}>
                            <ListItemText  primary={<span>{product}</span>} />
                        </ListItem>
                    );
                });
                setNameSpacesListView(
                    <div className={styles.proxySharerList}>
                        <List>
                            {softwareProductList}
                        </List>
                    </div>
                );

            } else {
                setNameSpacesListView(
                    <Alert severity="info">You haven't associated this team with Namespaces yet. Hence, you won't be able to add a proxy. </Alert>
                );
            }
        } else {
            setNameSpacesListView(
                <Alert severity="info">Loading details ...</Alert>
            );
        }

    }, [getSoftwareProductList, sectorName, nameSpaces, anchorEl, styles, confirm, setAnchorEl, showMessage]);

    return (
        <>
            {messageType && message && (<Alert severity={messageType} onClose={hideMessage}>{message}</Alert>)}
            <DialogContentText className="message">
                <FormattedMessage defaultMessage="Please add Namespaces to be associated with this Sector. " />
            </DialogContentText>
            <AddNameSpaceForm sectorName={sectorName} onCancel={handleClose} onSuccess={onSharedUserListChange} onError={showMessage} />
            <div className={styles.proxySharerView}>
                {nameSpacesListView}
            </div>

        </>
    );
};
const NameSpaceDialog: FC<PropsWithRef<NameSpaceDialogProps>> = forwardRef(({ sectorName }, ref) => {
    const styles = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={maxWidth}
                open={open}
                scroll={scroll}
                onClose={handleClose}
            >
                <DialogTitle id="form-dialog-title"><FormattedMessage defaultMessage="Namespaces" /></DialogTitle>
                <DialogContent className={styles.content}>
                    <SoftwareProductList sectorName={sectorName} handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </>
    );
});

export default memo(NameSpaceDialog);