import { CardContent as MuiCardContent } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import type { PropsWithChildren } from "react";
import clsx from "clsx";
import useCardStyles from "./Card.style";

export interface CardContentProps {
  className?: string;
  isShowSkeleton?: boolean;
}
export const CardContent: React.FC<PropsWithChildren<CardContentProps>> = ({
  className,
  children,
  isShowSkeleton,
}) => {
  const classes = useCardStyles();
  return (
    <MuiCardContent className={clsx(classes.content, className)}>
      {children ??
        (isShowSkeleton ? (
          <>
            <Skeleton
              animation="wave"
              height={10}
              width="100%"
              style={{ marginBottom: 6 }}
            />
            <Skeleton
              animation="wave"
              height={10}
              width="100%"
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={10} width="80%" />
          </>
        ) : (
          <span></span>
        ))}
    </MuiCardContent>
  );
};

CardContent.defaultProps = {
  isShowSkeleton: false,
};
export default CardContent;
