import React from 'react';
import { useSelector } from 'react-redux';
import AuthService from '../../services/authService';
import { AppBar, Avatar, Button, Card, CardActionArea, CardActions, CardContent, createStyles, Link, List, ListItem, ListItemText, ListSubheader, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import LandingCurve from 'legacy-components/icons/LandingCurve';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import TrimbleCloudLogo from 'legacy-components/icons/TrimbleCloudLogo';
import CompanyFullLogo from 'legacy-components/icons/CompanyFullLogo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            fill: '#fff',
            width: 'inherit',
            height: "200vh"
        },
        title: {
            flexGrow: 1,
            [theme.breakpoints.down('md')]: {
                visibility: 'hidden'
            }
        },
        companyLogo: {
            fill: '#fff',
            color: '#fff'
        },
        toolbar: {
            minHeight: '56px',
            width: 'inherit'
        },
        banner1: {
            backgroundImage: 'url(/assets/images/header-01.jpg)',
            width: '100vw',
            height: '94vh',
            position: 'relative',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            display: 'flex',
            margin: 'auto',
            color: '#fff'
        },
        bannerHeader: {
            textAlign: "center",
            fontWeight: 700,
            fontSize: theme.typography.pxToRem(60),
        },

        bannerHeader2: {
            textAlign: "center",
            padding: '30px',
            fontWeight: 600
        },
        layer: {
            content: "''",
            background: 'linear-gradient(75deg,  #00437B, #005F9E)',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0.8,
            zIndex: 2

        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            zIndex: 10,
            margin: 'auto',
            textAlign: 'center',
            marginTop: '26vh'
        },
        banner : {
            paddig: '20px 0'
        },
        banner3: {
            padding: '100px 10vh',
            textAlign: 'center',
            borderRadius: '5px',
            backgroundColor: '#009AD9',
            margin: '30px 0',
            color: '#fff'
        },
        highlights: {
            maxWidth: '1024px',
        },
        highlight : {
            margin: '15px',
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '45%',
                '&:nth-child(odd)': {
                    marginLeft: 'auto'
                },
                '&:nth-child(even)': {
                    marginRight: 'auto'
                },
                '&:last-child:nth-child(odd)': {
                    margin: '15px auto'
                },
            }
        },
        avatar: {
            margin: '15px'
        },
        accessList: {
            maxWidth: '100vw',
            
            [theme.breakpoints.up('md')]: {
                width: '60vw'
            }
        },
        footer: {
            backgroundColor: '#002D5B',
            padding: '2vw',
            color: '#fff',
            marginTop: '50px'
        },
        footerTitle: {
            color: '#fff'
        },
        [theme.breakpoints.up('md')]: {
            topic: {
                width: '45%',
                '&:nth-child(odd), &:nth-child(odd)>.MuiListItemText-root.list>.MuiListItemText-primary': {
                    float: 'left',
                    textAlign: 'left',
                    marginLeft: 'auto'
                },
                '&:nth-child(even),  &:nth-child(even)>.MuiListItemText-root.list>.MuiListItemText-primary': {
                    float: 'right',
                    textAlign: 'right',
                    marginRight:  'auto'
                }
            }
        },
        [theme.breakpoints.down('md')]: {
            topic: {
                textAlign: 'center',
                margin: 'auto',
                width: '100%'
            }

        },
        list: {
            textAlign: 'inherit'
        },
        linkList: {
            borderBottom: '1px solid #fff'
        },
        trimbleLinks: {
            margin: 'auto'
        },
        trimbleLink: {
            width : 'auto',
            color: '#fff',
            [theme.breakpoints.down('md')]: {
                width : '100%',
                textAlign: "center"
            },
            '&:hover,&:focus, &:visited' : {
                color: '#ccc'
            }
        }
    }),
    
);

export const Header = (props: any) => {
        
    const classes = useStyles();
    
    let isLoggedIn = useSelector((state: any) => state.auth?.user?.access_token? true: false);
    const onSignInClicked = () => {
        AuthService.signInRedirect();
    }
    return (
        <AppBar position="static" >
            <Toolbar className={classes.toolbar}>
                <CompanyFullLogo className={classes.companyLogo} />
                <Typography variant="h6" className={classes.title}>
                    Trimble Cloud Console
                </Typography>
                {isLoggedIn && <Button color="inherit" onClick={onSignInClicked}>Home</Button>}
                {!isLoggedIn && <Button color="inherit" onClick={onSignInClicked} data-testid = "login-btn">Login</Button>}
                
            </Toolbar>
        </AppBar>

    )
}

const Landing = (props: any) => {
    const classes = useStyles();
    
let isLoggedIn = useSelector((state: any) => state.auth?.user?.access_token? true: false);


    const onSignInClicked = () => {
        AuthService.signInRedirect();
    }
   
    const HighLights = () => {
        let highLightList: Array<any> = [
            {
                title: 'Easy Proxy Creation',
                description: " Easily create API proxies and visually configure or code API policies as steps in the API flow. Customize API behavior using code. Plus, transform from or to any protocol."
            },{
                title: 'Collaborate ',
                description: " Can share API Proxy and Products across teams "
            },{
                title: 'Secure your APIs ',
                description: " Enforce consistent security best practices and governance policies across all APIs. Protect your data at rest against OWASP threats with 0Auth 2.0, SAML, two-way TLS, and encryption. "
            },
            {
                title: 'Publish API Products',
                description: 'Provide reference documentation, manage the audience for your API'
            },
            {
                title: 'Analyze APIs',
                description: 'Drill down into API traffic data, investigate traffic spikes, and trace live API calls with real-time insights from your data. Track active developers and apps and the associated revenue metrics.'
            },
            {
                title: 'Monitor APIs',
                description: 'Ensure API availability to maintain seamless experiences for application developers, customers, and partners. Reduce the mean time to diagnosis (MTTD) by quickly investigating issues.'
            },

        ];
        return (
            <section className="flex-col-center">
                <Typography variant="h4" align="center" gutterBottom className={classes.bannerHeader2}>
                    Highlights
                </Typography>
                <div className={"flex-row-center " + classes.highlights}>
                    {highLightList.map(highLight => (
                        <Card className={classes.highlight} key={highLight.title}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {highLight.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                    {highLight.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                </div>
            </section>
        );
    }

    const AccessProcess = () => {
        return (
            <section className="flex-col-center">
                <Typography variant="h4" align="center" gutterBottom className={classes.bannerHeader2}>
                    How to Access?
                </Typography>
                <div className={"flex-row-center " + classes.accessList}>
                    <Card className={classes.highlight}>
                        <CardActionArea>
                            <CardContent>
                                <Avatar className={classes.avatar}>
                                    <PersonOutlineIcon />
                                </Avatar>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Trimble Employees
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                {isLoggedIn && (<>Access the Trimble Cloud services by clicking home</>)}
                                {!isLoggedIn && (<>Sign in to access the Trimble Cloud services...</>)}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {isLoggedIn && <Button color="primary" onClick={onSignInClicked}>Home</Button>}
                                {!isLoggedIn && <Button color="primary" onClick={onSignInClicked}>Sign In</Button>}
                            </CardActions>
                        </CardActionArea>
                    </Card>
                    <Card className={classes.highlight}>
                        <CardActionArea>
                            <CardContent>
                                <Avatar className={classes.avatar}>
                                    <LocalLibraryIcon />
                                </Avatar> 
                                <Typography gutterBottom variant="h5" component="h2">
                                    Not a Trimble Employee?
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Learn about our current developer programs...
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button color="primary"  component={Link} rel="noopener noreferrer" href="https://developer.trimble.com/" target="_blank" >Learn More</Button>
                            </CardActions>
                        </CardActionArea>
                    </Card>
                </div>
            </section>
        )
    }
    const Footer = () => {
        const topics: Array<any> = [
            {
                title: "Services",
                links: [
                    {
                        title: "API Management"
                    },
                    {
                        title: "Identity Management"
                    },
                    {
                        title: "API Marketplace"
                    }
                ]
            },
            {
                title: "Support",
                links: [
                    {
                        title: "About Us",
                        link: "https://sites.google.com/trimble.com/trimblecloud"
                    },
                    {
                        title: "Documentation",
                        link: "https://docs.trimblecloud.com/"
                    },
                    {
                        title: "Contact Us",
                        link: "https://sites.google.com/trimble.com/trimblecloudplatform/contact-us"
                    }
                ]
            }
        ];
        const trimbleLinks = [
            {
                title: (<> &copy; Copyright 2021, Trimble Inc.</>),
                link: "http://www.trimble.com/copyrights.aspx"
            },
            {
                title: "Terms of Use",
                link: "https://www.trimble.com/Corporate/Terms_of_Use.aspx"
            },
            {
                title: "Privacy Statement",
                link: "https://www.trimble.com/Corporate/Privacy.aspx"
            },
            {
                title: "Do Not Sell My Personal Information",
                link: "https://www.trimble.com/Corporate/Privacy.aspx?tab=contact_us"
            }
        ]
        return (
            <footer className={classes.footer}>
                <Typography align="center"><TrimbleCloudLogo/></Typography>
                <section className={"flex-row-center "+ classes.linkList}>
                    {topics.map( (topic: any) => (
                        <List key={topic.title} className={classes.topic} subheader={<ListSubheader className={classes.footerTitle}>{topic.title}</ListSubheader>}>
                            {topic.links.map((link: any) => (
                                <ListItem key={link.title} className={"list "+ classes.list} >
                                    <ListItemText  className={"list "+ classes.list} 
                                        primary={
                                            link.link? (
                                                <Link color="primary"  className={classes.trimbleLink} href={link.link} target="_blank">
                                                    {link.title}
                                                </Link>
                                            ) : link.title
                                        }
                                    />
                                </ListItem>
                            )) }                       
                        </List>
                    ))}
                </section>
                <section className={"flex-row-center"}>
                    <List className={"flex-row-center " + classes.trimbleLinks}>
                        {trimbleLinks.map((trimbleLink: any) => (
                            <ListItem key={trimbleLink.title} className={classes.trimbleLink}>
                                <ListItemText
                                primary={<Link color="primary"  className={classes.trimbleLink}
                                href={trimbleLink.link} target="_blank" rel="noopener noreferrer">{trimbleLink.title}</Link>}
                                />
                            </ListItem>
                            )
                            )
                        }
                    </List>
                </section>
               
            </footer>
        );
    }
    return (
        <div className={classes.root}>
            <Header />
            <section className={classes.banner1}>
                <span className={classes.layer}></span>
                <div className={classes.content}>
                    <Typography variant="h2" gutterBottom className={classes.bannerHeader}>
                        Trimble Cloud Console
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        A one-stop portal for accessing all Trimble Cloud Services.
                    </Typography>
                </div>
                <LandingCurve />
            </section>
            <section className={classes.banner}>
                <HighLights />
            </section>
            <section className={`${classes.banner} ${classes.banner3}`}>
                <Typography variant="h5" gutterBottom>
                    One app that connects all the services and other developer tools that Trimble Cloud provides for the Trimble Developer Community.
                </Typography>
            </section>
            <section className={classes.banner}>
                <AccessProcess/>
            </section>
            <Footer/>
        </div>
    );

}

export default Landing;
