import React from "react";
import { DialogContent, DialogTitle } from "@material-ui/core";


const JustificationHelper = (props: any) => {
    return (
        <section>
            <DialogTitle>What is the verification process?</DialogTitle>
            <DialogContent>
                <p> Yet to add the helper text </p>
            </DialogContent>
        </section>
    );
}
export default JustificationHelper;