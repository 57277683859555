import React from "react";
import type { PropsWithChildren } from "react";

import { Box } from "@material-ui/core";

import "./TabPanel.scss";

interface TabPanelProps {
  value?: number;
  index: number;
}
export const TabPanel: React.FC<PropsWithChildren<TabPanelProps>> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;
