import { Box, Grid, MenuItem, Select,  } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useResourceAppVersionProvider } from "./ResourceAppVersionProvider";
import { useResourceAppVersionCreator } from "./ResourceAppVersionCreator";
import Submit from "modus/components/form/submit/Submit";
import AddIcon from "@material-ui/icons/Add";
import { DeploymentList } from "./DeploymentList";
import { ErrorMessage, resolveAPIErrorMessage } from "modus/components/error-message/ErrorMessage";
import { Skeleton } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface ResourceVersionProps {
    applicationName: string;
    applicationId: string;
}
export const ResourceVersion:React.FC<ResourceVersionProps> = ({applicationName, applicationId}) => {
    const [version, setVersion] = useState<string>();

    const handleVersionChange = (version: string) => {
      setVersion(version);
    };
    const { versionState, getVersions } = useResourceAppVersionProvider(
      applicationId as string, 
    );
    const { createVersionState, createVersion } = useResourceAppVersionCreator(
      applicationId as string
    );
    const createNewVersion = () => createVersion();
  
    useEffect(() => {
      if (applicationId) {
        getVersions();
      }
    }, [applicationId, createVersionState?.loaded]);

    useEffect(() => {
      if(versionState?.value) {
        setVersion(versionState?.value[0]);
      }

    },[versionState?.value]);
  
    const CreateNewVersion = () => (
      <Submit
                size="large"
                variant="contained"
                color="primary"
                style={{ padding: "24px 21px" }}
                startIcon={<AddIcon />}
                state={createVersionState as any}
                onClick={() => createNewVersion()}
                onSuccess={getVersions as any}
              >
                Add New Version
              </Submit>
    );
  return (
    <>
    {
      versionState?.value?.length >= 1  && version && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"space-between"}
          marginTop={"20px"}
        >
        <Select 
          id="versions" 
          IconComponent={ExpandMoreIcon}
          variant="outlined"
          style={{ minWidth: "15%" }} 
          className="header-selector resource-version-select"
           value={version} >
          {versionState?.loaded &&
            versionState?.value.map((version: any) => {
              return (
                <MenuItem value={version} key={version} onClick={() => handleVersionChange(version)}>
                  {version}
                </MenuItem>
              );
            })}
        </Select>
      <CreateNewVersion/>
    </Box>
    )}
    <Box mt={2}>
        {version && versionState?.loaded && (<DeploymentList version={version} applicationName={applicationName} resourceAppId={applicationId}/>) }
        {versionState?.loading && (
          <Skeleton animation="wave" width="100%" height="200px" />
        )}
        {versionState?.loaded && versionState?.value?.length  === 0 && (
          <ErrorMessage message={
            <Box display="flex" flexDirection="column">
              Create a new Version 
              <Box mt={1} />
              <CreateNewVersion/>
              </Box>
          } />
        )}
        {versionState?.error && (
          <ErrorMessage message={
            (versionState?.errorValue as any)?.response?.status === 403 ? "You are not having access to view or edit Resource versions":
            resolveAPIErrorMessage(versionState?.errorValue)
          } />
        )}
    </Box>
    </>
  );
};
