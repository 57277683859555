import { FormControl, InputLabel, MenuItem, Select, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core";
import type { ISpecDetails } from "../../../../service/ProductService";
import React, { useState } from "react";
import { Row } from "legacy-components/responsive-grid/ResponsiveGrid";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export interface APIReferenceProps {
  redocServerUrl: string;
  specDetails: ISpecDetails;
  productName: string;
  specUrl: string;
}

const usIframeStyle = makeStyles((theme: Theme) =>
  createStyles({
    iframe: {
      minHeight: "calc(60vh - 20px)",
    },
  })
);

export const APIReference: React.FC<APIReferenceProps> = ({
  specUrl,
  specDetails,
  productName,
}) => {
  const classes = usIframeStyle();
  const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment ?? "trimble-pre-prod");

  const [specLoadingUrl, setSpecLoadingUrl] = useState<string>("");

  const [specEnv, setSpecEnv] = React.useState(identityEnvironment);

  useEffect(() => {
    if(!identityEnvironment || identityEnvironment === specEnv) {
      return;
    }
    setSpecEnv(identityEnvironment);
  },[identityEnvironment]);

  useEffect(() => {
    if(specDetails.preProd || specDetails.prod) {
      let url = "";

      if(specEnv === "trimble-pre-prod") {
        if(specDetails?.preProd) {
          url += `${specUrl}/specs/${productName}-Specs/`;
          url += specDetails?.preProd?.fileName;
        }
      } else {
        if(specDetails?.prod) {
          url += `${specUrl}/specs/${productName}-Specs/`;
          url += specDetails?.prod?.fileName;
        }
      }
     
      url = encodeURI(url);
      setSpecLoadingUrl("?specUrl=" + url)
    }
  }, [specEnv])

 

  return (
    <Row>
      <iframe
        frameBorder="0"
        title="Swagger API Reference"
        height="60vh"
        width="100%"
        className={classes.iframe}
        src={`${specUrl}${specLoadingUrl}`}
      ></iframe>
    </Row>
  );
};

export default React.memo(APIReference);
