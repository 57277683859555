import Skeleton from "@material-ui/lab/Skeleton";
import { Column } from "legacy-components/responsive-grid/ResponsiveGrid";
import React from "react";

export interface ParagraphSkeletonProps {
    count?: number;
  }
  export const ParagraphSkeleton: React.FC<ParagraphSkeletonProps> = ({
    count = 2,
  }) => (
    <Column padding="1">
      {[...Array(count)].map((x, index) => (
        <Skeleton
          animation="wave"
          key={index}
          height={10}
          width="100%"
          style={{ marginBottom: 6 }}
        />
      ))}
      <Skeleton animation="wave" height={10} key="x" width="70%" />
    </Column>
  );
  