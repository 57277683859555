
export const useValidateFilterCondition = () => {
    const validateFilterCondition = (filterCondition: string): boolean => {
        filterCondition = filterCondition?.replace(/\\=/g, '{ESCAPEEQUAL}');
        const splitByAND = filterCondition?.replace(/ /g, '')?.split('&&');
        let valid = true;
        let errorMsg = '';

        for (let i = 0; i < splitByAND?.length; i++) {
            if (splitByAND[i]?.includes('||')) {
                const splitOR = splitByAND[i]?.split('||');
                for (let j = 0; j < splitOR?.length; j++) {
                    const splittByEqualTo = splitOR[j]
                        ?.split('=')
                        ?.map((key) => key?.replace(/{ESCAPEEQUAL}/g, '='));
                    if (splittByEqualTo?.length !== 2 || !splittByEqualTo[0] || !splittByEqualTo[1]) {
                        valid = false;
                        errorMsg = 'Invalid filter condition: Each condition must have a key and a value.';
                        break;
                    } else if (!validRegex(splittByEqualTo[1])) {
                        valid = false;
                        errorMsg = 'Invalid regex pattern in filter condition.';
                        break;
                    }
                }
            } else {
                const splittByEqualTo = splitByAND[i]
                    ?.split('=')
                    ?.map((key) => key?.replace(/{ESCAPEEQUAL}/g, '='));
                if (splittByEqualTo.length !== 2 || !splittByEqualTo[0] || !splittByEqualTo[1]) {
                    valid = false;
                    errorMsg = 'Invalid filter condition: Each condition must have a key and a value.';
                    break;
                } else if (!validRegex(splittByEqualTo[1])) {
                    valid = false;
                    errorMsg = 'Invalid regex pattern in filter condition.';
                    break;
                }
            }
        }

        if (!valid) {
            console.error(errorMsg);
        }

        return valid;
    };

    return { validateFilterCondition };
};

const validRegex = (regex: string): boolean => {
    try {
        new RegExp(regex);
        return true;
    } catch {
        return false;
    }
};
