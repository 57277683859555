import { FieldMetaState } from "react-final-form";
import { useState, useEffect, useCallback } from "react";

export const useExtendedFieldState = ({ dirtySinceLastSubmit, modified, touched, active, error, submitError }: FieldMetaState<any>, helperText?: string) => {
    const formErrorHandler = useCallback(() => !dirtySinceLastSubmit && (submitError || error), [dirtySinceLastSubmit, error, submitError]);

    const [formError, setFormError] = useState(formErrorHandler());

    const canShowErrorHandler = useCallback(() => (touched || modified) && formError && !active, [active, formError, modified, touched]);

    const [canShowError, setCanShowError] = useState(canShowErrorHandler());

    const hasErrorHandler = useCallback(() => touched && formError && !active, [active, formError, touched]);

    const [hasError, setHasError] = useState(hasErrorHandler());

    const fieldHelperTextHandler = useCallback(() => (hasError ? formError : (active ? helperText : null)), [active, formError, hasError, helperText]);    

    const [fieldHelperText, setFieldHelperText] = useState(fieldHelperTextHandler());

    useEffect(() => setFormError(formErrorHandler()), [formErrorHandler]);
    useEffect(() => setCanShowError(canShowErrorHandler()), [canShowErrorHandler]);
    useEffect(() => setHasError(hasErrorHandler()), [hasErrorHandler]);
    useEffect(() => setFieldHelperText(fieldHelperTextHandler()), [fieldHelperTextHandler]);   

    return {
        formError,
        canShowError,
        hasError,
        active,
        dirtySinceLastSubmit, 
        modified, 
        touched, 
        error, 
        submitError,
        fieldHelperText
    }
}