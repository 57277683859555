import axios, { AxiosInstance } from 'axios';

/**
 * @class UserProfileAPI
 * @type API Service Layer
 * @description For API Detail reference and replication
 *  * setting basic headers for all APIs
 */
export class UserProfileAPI {
     static get_my_details = "/users/me";
     static get_my_photo = "/users/{userId}/images";
     static put_my_photo = "/users/{userId}/image";

     /**
      * @function parseRequestURL
      * @description to replace the valu to be passed to above string variables
      * @example /api/{apiName}/create - /api/myAPIName/create
      * @param {*} requestURL 
      * @param {*} request 
      */
     static parseRequestURL = (requestURL: string, request: {
          [key: string]: string
     }) => {
          Object.keys(request).forEach(key => {
               requestURL = requestURL.replace(new RegExp('{' + key + '}', 'g'), request[key]);
          });
          return requestURL;
     }
}

const ProfileService: AxiosInstance & { setToken?: (accessToken: string) => void } = axios.create();
ProfileService.defaults.baseURL = process.env.REACT_APP_USER_PROFILE_API_URL;
ProfileService.defaults.headers.post['Content-Type'] = 'application/json';

ProfileService.setToken = (accessToken: string) => {
     ProfileService.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
};

export default ProfileService;