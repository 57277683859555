import { APIResource, APIMethod } from "../../../libs/utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../../libs/utils/http/client/HttpClient";
import { apiMethodContext } from "../../../libs/utils/http/builder/UrlConfig";
import { from } from "rxjs";

export interface ICertificate {
    Id: string,
    certId: string;
    description: string;
    expirationDate: string;
    status: string;
    created?: string;
    updated?: string;
    environment: string;
    certInfo: CertInfo[];
    action?: string
}

export interface CertInfo {
    issuer: string;
    subject: string;
    expiry: string;
    commonName: string;
    isValid: string;
}

export interface CertExpiryInfo{
    certId: string,
     certName: string,
    expiryDuration: number,
     certOwnerName: string,
     certOwnerEmail: string
}

@APIResource(CloudConsoleClient, "/publisher/certexpiry")
class CertificateExpiryServiceFactory {

    @APIMethod("", {
        options: {
            noTrailingSlash: true
        }
    })
    public forCertificateExpiry() {
        return from(CloudConsoleClient.get<CertExpiryInfo[]>(apiMethodContext(arguments), {
            params: {
                
            }
        }).then(response => {
            
            return response.data.filter( x => x.expiryDuration <= 30
              );
            
            
        }));
    }
}
const CertificateExpiryService = new CertificateExpiryServiceFactory();

export default CertificateExpiryService;