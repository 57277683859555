import Axios, { AxiosRequestConfig } from "axios";
import { HttpClientInstance, __$urlRequestIdPrefixer } from "../decorator/UrlDecorator";
import { UrlFactory } from "../builder/UrlFactory";
import { UrlConfigInfo, _getResourceConfig } from "../builder/UrlConfig";

function configureUrl(clientName: string, requestConfig: AxiosRequestConfig, urlConfig: UrlConfigInfo): AxiosRequestConfig {
    if (urlConfig?.resourceInfo?.name === clientName) {
        const url = requestConfig.url;

        if (url?.startsWith(__$urlRequestIdPrefixer)) {
            const urlFactory = new UrlFactory(urlConfig, requestConfig.params);

            requestConfig.url = urlFactory.url;
            requestConfig.params = urlFactory.params;
        }

        return requestConfig;
    }

    throw new Error("Source of the http client not same, use the same client provided to the @APIResource");
}

export const createClient = (name: string, options?: {
    baseURL?: string
}): HttpClientInstance => {
    const client: HttpClientInstance = Axios.create();

    client.interceptors.request.use(config => {

        if (config.url?.startsWith(__$urlRequestIdPrefixer)) {
            const requestIdRef = config.url.split(__$urlRequestIdPrefixer);

            if (requestIdRef?.[1]) {
                const endpointConfig = _getResourceConfig(requestIdRef[1]);

                if (endpointConfig?.resourceInfo?.name) {
                    const noSkipAuth = !endpointConfig?.methodInfo?.params?.options?.noAuthorization;

                    if (noSkipAuth && client.__customHeaders?.["Authorization"]) {
                        config.headers['Authorization'] = client.__customHeaders?.["Authorization"];
                    }

                    configureUrl(name, config, endpointConfig);
                }
            }
        }

        return config;
    });

    client.defaults.baseURL = options?.baseURL;
    client.defaults.headers.post['Content-Type'] = 'application/json';

    client.__getName = () => name;

    client.useToken = accessToken => {
        client.__customHeaders = {
            Authorization: 'Bearer ' + accessToken
        }
    };

    client.useTeam = team => {
        client.defaults.params = {
            "teamId": team
        }
    }

    return client;
}

export const CloudConsoleClient = createClient("APICloudConsole", {
    baseURL: process.env.REACT_APP_UNIFIED_API_URL
});

