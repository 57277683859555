import React, { memo } from "react";
import { SvgIconProps, SvgIcon, makeStyles, createStyles } from "@material-ui/core";


const useStyles = makeStyles(({ spacing, palette, typography }) =>
    createStyles({
        doNotSellIcon : {
            width: '1rem',
            height: 'auto'
        }
    })
);

function DoNotSellIcon(props: SvgIconProps) {

    const classes = useStyles();

    return (
        
        <SvgIcon {...props} viewBox="0 0 28 32" fill="none"  className={classes.doNotSellIcon}>
            <path d="M12.5 12.5C15.9531 12.5 18.75 9.70313 18.75 6.25C18.75 2.79687 15.9531 0 12.5 0C9.04687 0 6.25 2.79687 6.25 6.25C6.25 9.70313 9.04687 12.5 12.5 12.5ZM12.5 15.625C8.32812 15.625 0 17.7187 0 21.875V25H25V21.875C25 17.7187 16.6719 15.625 12.5 15.625Z" fill="black"/>
            <rect x="12" y="16" width="14" height="10" fill="white"/>
            <path d="M19 10L10 14V20C10 25.55 13.84 30.74 19 32C24.16 30.74 28 25.55 28 20V14L19 10ZM17 26L13 22L14.41 20.59L17 23.17L23.59 16.58L25 18L17 26Z" fill="#4D6F33"/>
        </SvgIcon>
        
    );
}

export default memo(DoNotSellIcon);