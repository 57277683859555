import { Column, Row } from "legacy-components/responsive-grid/ResponsiveGrid";
import { ParagraphSkeleton } from "legacy-components/typography/ParaSkeleton";
import Typography from "legacy-components/typography/Typography";
import React from "react";

import { ProductContext } from "../Product";
import { Chip } from "@material-ui/core";
import { useStyles } from "pages/home/products/product-views/ProductDetailsView";

export const ProductBasics: React.FC = () =>{
  const classes = useStyles();

  return (
    <ProductContext.Consumer>
      {(context) => (
        <Row padding="b1">
          <Column xl={9} md={8}>
            <Typography type="h5">Description</Typography>
            {context?.product?.summary && (
              <Typography type="body">
                {context?.product?.summary?.description?.split('\n').map(str => <p>{str}</p>)}
              </Typography>
            )}
            {(!context?.product || !context?.product?.summary) && (
              <ParagraphSkeleton />
            )}
          </Column>
          <Column xl={3} md={4}>
            {context?.product?.summary.publishedDate && (
              <Column margin="b3">
                <Typography type="h6">Published Date</Typography>
                <Typography type="body">
                  {context?.product?.summary.publishedDate}
                </Typography>
              </Column>
            )}
  
            {context?.product?.summary.lastUpdated && (
              <Column margin="b3">
                <Typography type="h6">Last Updated</Typography>
                <Typography type="body">
                  {context?.product?.summary.lastUpdated}
                </Typography>
              </Column>
            )}
            {context?.product?.subscribers !== undefined &&
              context?.product?.subscribers > 0 && (
                <Column margin="b3">
                  <Typography type="h6">Subscribers</Typography>
                  <Typography type="body">
                    {context?.product?.subscribers}
                  </Typography>
                </Column>
              )}
              
            {context?.product?.apiTypes !== undefined &&
              context?.product?.apiTypes.length > 0 && (
                <Column margin="b3">
                  <Typography type="h6">API Types</Typography>
                  <Typography type="body">
                  <div className={classes.chips}>
                  {context?.product?.apiTypes.map((value)=> {
                   return <Chip key={value} label={value} className={classes.chip}/>
  
                  })}
                  </div>
                  </Typography>
                </Column>
              )}
  
  
  {/* <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="API Type" />}>
                  <Typography variant="body1">
                  <NAWrap value={product.apiTypeMapping} showMessage={true}>
                  <div className={classes.chips}>
                          {product.apiTypeMapping?.map((value) => (
                              <Chip key={value} label={value} className={classes.chip} />
                          ))}
                      </div>
                      </NAWrap>
                  </Typography>
              </FormViewInliner> */}
         
          </Column>
        </Row>
      )}
    </ProductContext.Consumer>
  );
  
}
