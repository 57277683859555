import React, { useCallback, useState, useContext, FC, useEffect } from "react";
import {
  useTheme,
  Dialog,
  Slide,
  Paper,
  Tooltip,
  IconButton,
  Button,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import FormContainer, {
  FormContainerSubmitHandler,
  FormContainerOptions,
} from "legacy-components/form/container/FormContainer";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import { Field, FormRenderProps } from "react-final-form";
import FormTextInput from "legacy-components/form/input/FormTextInput";
import { ValidatorBuilderContext } from "legacy-components/form/validator/Validator";
import { useStyles } from "pages/home/products/product-forms/ProductProxyDialog";
import { FORM_ERROR } from "final-form";
import teamManager from "store/reducers/teamManager";

interface UserRoleEditProps {
  user: any;
  team?: string,
  onSuccess: () => void;
}
export const UserRoleEdit: FC<UserRoleEditProps> = ({ user, team, onSuccess }) => {
  const [showUserRoleEdit, setUserRoleEdit] = useState(false);

  const handleEditClose = useCallback(() => {
    setUserRoleEdit(false);
  }, []);

  const handleEditOpen = useCallback(() => {
    setUserRoleEdit(true);
  }, []);

  const handleRefresh = useCallback(() => {
    setUserRoleEdit(false);
    setTimeout(onSuccess, 1000);
  }, []);

  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const userRoleEdit = (
    <>
      <Tooltip title={<FormattedMessage defaultMessage="Edit configuration" />}>
        <span>
          <IconButton onClick={handleEditOpen} size="small">
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        fullWidth={true}
        open={showUserRoleEdit}
        onClose={handleEditClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <Slide
          direction="left"
          timeout={transitionDuration}
          style={{
            transitionTimingFunction: theme.transitions.easing.easeInOut,
          }}
          in={showUserRoleEdit}
          mountOnEnter
          unmountOnExit
        >
          <Paper>
            <UserRoleUpdate
              user={user}
              onClose={handleEditClose}
              onSuccess={handleRefresh}
            />
          </Paper>
        </Slide>
      </Dialog>
    </>
  );
  if (
    user?.role !== "SUPERADMIN" &&
    user?.gateways[0]?.approval !== "INITIATED"
  ) {
    return userRoleEdit;
  } else {
    let toolTipMessage =
      "You cannot change the user role until the user is Approved. If you need the user role to get updated immediately, please email to our support ";
    if (user?.role === "SUPERADMIN") {
      toolTipMessage = " You cannot revoke Super admin Role.";
    }
    return (
      <Tooltip title={toolTipMessage}>
        <span>
          <IconButton onClick={handleEditOpen} size="small" disabled>
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
    );
  }
};

interface UserRoleUpdateProps {
  user: any;
  onClose: () => void;
  onSuccess: () => void;
}

export const UserRoleUpdate: FC<UserRoleUpdateProps> = ({
  user,
  onClose,
  onSuccess,
}) => {
  const style = useStyles();

  const camelCase = (input: string) => {
    return input
      ?.toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };

  return (
    <div>
      <div className={style.header}>
        <DialogTitle>
          <FormattedMessage defaultMessage="Edit Role" />
        </DialogTitle>

        <IconButton className={style.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <Divider />
      </div>
      <div className={style.bottompad}>
        <UserRoleUpdateForm
          initialValues={{ email: user?.email, team: user?.team, role: camelCase(user?.role) }}
          onSuccess={onSuccess}
        />
      </div>
    </div>
  );
};

interface UserRoleUpdateFormProps {
  initialValues: {
    team: string;
    email: string;
    role: string;
  };
  onSuccess: () => void;
}

const UserRoleUpdateForm: FC<UserRoleUpdateFormProps> = ({
  initialValues,
  onSuccess,
}) => {
  const onSubmit: FormContainerSubmitHandler<any> = () => (data, form) => {
    return new Promise((resolve) => {
      const formState = form.getState();

      if (formState.pristine) {
        resolve(true);
      } else {
        const formData = new FormData();
        formData.append("role", data.role);
        formData.append("teamName", initialValues.team);
        UnifiedAPIService.put(
          UnifiedAPI.parseRequestURL(UnifiedAPI.update_user, {
            email: data.email,
          }),
          formData
        ).then(
          () => {
            resolve(null);
            setTimeout(onSuccess, 3000);
          },
          (error) => {
            resolve({
              [FORM_ERROR]: {
                role: error.response.data.message,
              },
            });
          }
        );
      }
    });
  };

  const FormComponents = (
    formRenderProps: FormRenderProps<{}, {}>,
    containerOptions: FormContainerOptions
  ) => {
    const intl = useIntl();
    const validator = useContext(ValidatorBuilderContext);
    /*
    const onSnackBarClose = () => {
      setApiMessage(undefined);
      setApiMessageType(undefined);
    };
  */
    const [userRoles, setRoles] = useState<
      Array<{
        key: string;
        value: string;
      }>
    >([
      {
        key: "Trimble Developer",
        value: intl.formatMessage({
          defaultMessage: "Trimble Developer",
        }),
      },
      {
        key: "Trimble Business User",
        value: intl.formatMessage({
          defaultMessage: "Trimble Business User",
        }),
      },
      {
        key: "Team Admin",
        value: intl.formatMessage({
          defaultMessage: "Team Admin",
        }),
      },
      {
        key: "Consumer",
        value: intl.formatMessage({
          defaultMessage: "Consumer",
        }),
      },
      {
        key: "Restricted Trimble Developer",
        value: intl.formatMessage({
          defaultMessage: "Restricted Trimble Developer",
        }),
      }
    ]);

    useEffect(() => {
      if (initialValues.role === "Sector Admin") {
        let roles = userRoles;
        roles.push({
          key: "Sector Admin",
          value: intl.formatMessage({
            defaultMessage: "Sector Admin",
          }),
        });
        setRoles(roles);
      }
    }, []);

    return (
      <>
        <FormInputContainer padding="inset">
          <Field
            name="email"
            type="text"
            component={FormTextInput}
            label={intl.formatMessage({
              defaultMessage: "Email",
            })}
            disabled
            placeholder={intl.formatMessage({
              defaultMessage: "Email",
            })}
            validate={validator.from({
              minLength: 3,
            })}
          />
        </FormInputContainer>
        <FormInputContainer padding="inset">
          <Field
            name="role"
            type="text"
            component={FormTextInput}
            label={intl.formatMessage({
              defaultMessage: "Role",
            })}
            placeholder={intl.formatMessage({
              defaultMessage: "Role",
            })}
            options={userRoles}
            validate={validator.from({
              required: true,
            })}
          />
        </FormInputContainer>
        <ActionInliner padding="inset">
          <Button
            disabled={formRenderProps.submitting || formRenderProps.pristine}
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
          >
            <FormattedMessage defaultMessage="Update" />
          </Button>
        </ActionInliner>
      </>
    );
  };

  return (
    <FormContainer
      prefillValues={initialValues}
      stepperId={"1"}
      FormProps={{ onSubmit, initialValues }}
      children={FormComponents}
      previewErrors={true}
      successMessage={"Current user role is modified"}
    />
  );
};
