import React, { useState } from "react";
import { Box, Button, Chip} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { AccordionContent } from "legacy-components/form/components/accordion-form/AccordionForm";
import InlineIconFormatter from "legacy-components/formatters/InlineIconFormatter";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Helper from "pages/home/helper/Helper";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import { Field, FormRenderProps } from "react-final-form";
import FormSelectionGroup from "legacy-components/form/input/FormSelectionGroup";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import FormContainer, { FormContainerOptions, FormContainerSubmitHandler } from "legacy-components/form/container/FormContainer";
import SSLConfigHelper from "pages/home/helper/SSLConfigHelper";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import { useSelector } from "react-redux";
import { FORM_ERROR } from "final-form";
import { useHistory } from "react-router";
import { useConfirm } from "material-ui-confirm";

const SSLConfig = (props: any) => {

    const history = useHistory();

    const [isShowSSLConfigHelper, toggleSSLConfigHelper] = useState(false);

    let proxyDetails = useSelector((state: any) => state.proxyManager.proxy);
    const confirm = useConfirm();
    const onSubmit: FormContainerSubmitHandler<any> = ({ stepperOptions }) => (data, form) => {
        return new Promise((resolve) => {
            const formState = form.getState();
            if (formState.pristine) {
                resolve(1);
            } else {
                if (data.ssl == "NONE") {
                    UnifiedAPIService.post(
                        UnifiedAPI.parseRequestURL(UnifiedAPI.deploy_proxy, {
                            apiName: proxyDetails?.apiName,
                            environmentName: props.env?.environmentName
                        }), {
                        backendUrl: props?.deployment?.backendUrl,
                        tls: data.ssl,
                        threatProtection: props?.deployment?.threatProtection ? props?.deployment?.threatProtection.protectionType : null,
                        isAdvanced: props?.deployment?.isAdvanced,
                    }).then(() => {
                        resolve && resolve(0);
                        props.onUpdateSuccess && props.onUpdateSuccess();
                    }, error => {
                        resolve && resolve({
                            [FORM_ERROR]: error.response?.data?.message ?? "Unknown error occured"
                        });
                    });
                } else {
                    confirm({ title: "Warning", description: 'Please make sure you have uploaded the certificate for your target before enabling TLS options. Click help icon to know more on managing Certificates. Do you want to continue?', dialogProps: { open: true, disableBackdropClick: true } })
                        .then(() => {
                            UnifiedAPIService.post(
                                UnifiedAPI.parseRequestURL(UnifiedAPI.deploy_proxy, {
                                    apiName: proxyDetails?.apiName,
                                    environmentName: props.env?.environmentName
                                }), {
                                backendUrl: props?.deployment?.backendUrl,
                                tls: data.ssl,
                                threatProtection: props?.deployment?.threatProtection ? props?.deployment?.threatProtection.protectionType : null,
                                isAdvanced: props?.deployment?.isAdvanced,
                            }).then(() => {
                                resolve && resolve(0);
                                //history.go(0);
                            }, error => {
                                resolve && resolve({
                                    [FORM_ERROR]: error.response?.data?.message ?? "Unknown error occured"
                                });
                            });
                        }).catch(() => {
                            resolve && resolve(0);
                        });
                }
            }
        })
    }


    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>, containerOptions: FormContainerOptions) => {
        const intl = useIntl();

        const [sslOptions] = useState([
            {
                value: "NONE",
                title: intl.formatMessage({
                    defaultMessage: "None"
                }),
                helperText: intl.formatMessage({
                    defaultMessage: "Disable the SSL configuration"
                })
            },
            {
                value: "ONE_WAY_TLS",
                title: intl.formatMessage({
                    defaultMessage: "One Way TLS"
                }),
                helperText: intl.formatMessage({
                    defaultMessage: "One Way TLS authentication creates a truststore on the API Cloud and a keystore on the server"
                }),
                disabled: true
            },
            {
                value: "TWO_WAY_TLS",
                title: intl.formatMessage({
                    defaultMessage: "Mutual TLS"
                }),
                helperText: intl.formatMessage({
                    defaultMessage: "Mutual / Two Way authentication creates a truststore and a keystore on both the API Cloud and the server."
                }),
                disabled: true
            }

        ]);
        return (<>
            <FormInputContainer title={
                <div>
                    <InlineIconFormatter icon={<HelpOutlineOutlinedIcon onClick={() => { toggleSSLConfigHelper(true) }} />}>
                        <FormattedMessage defaultMessage="Select the TLS option to protect your target" />
                    </InlineIconFormatter>
                    <Helper open={isShowSSLConfigHelper} handleClose={() => { toggleSSLConfigHelper(false) }} children={<SSLConfigHelper />}></Helper>
                </div>
            }>
                {props.deployment && (
                    <Field
                        name="ssl"
                        children={FormSelectionGroup}
                        options={sslOptions}
                        disabled={props.deployment.isAdvanced}
                        defaultSelectionIndex={props.deployment?.ssl?.tls === "TWO_WAY_TLS" ? 2 : props.deployment?.ssl?.tls === "ONE_WAY_TLS" ? 1 : 0}
                    />)}
            </FormInputContainer>

            <ActionInliner>
                <Button
                    disabled={
                        props.deployment?.isAdvanced ||
                        formRenderProps.submitting ||
                        (!(props.status === "DEPLOY_COMPLETED" || props.status === "unpublish"))

                    }
                    type="submit" variant="contained" color="primary" disableElevation
                >
                    <FormattedMessage defaultMessage="Save" />
                </Button>
            </ActionInliner>
        </>);
    }

    return (
        <AccordionContent size="small" header={<><FormattedMessage defaultMessage="SSL Configuration" /><Box component={"span"} mr={1}/><Chip label="deprecated" size="small"/></>} >
            <FormContainer stepperId={"ssl"} FormProps={{ onSubmit }} children={FormComponents} />
        </AccordionContent>
    )
};

export default SSLConfig;