import React from "react";
import type { PropsWithChildren } from "react";
import clsx from "clsx";
import useCardStyles from "./Card.style";
import Skeleton from "@material-ui/lab/Skeleton";

export interface SkeletonProps {
  height?: number;
  width?: number;
}
export interface CardsContainerProps {
  className?: string;
  customSkeleton?: JSX.Element;
  skeleton?: SkeletonProps;
  skeletonCount?: number;
}

const DefaultCardSkeleton: React.FC<SkeletonProps> = ({
  height = 300,
  width = 300,
}) => {
  const classes = useCardStyles();
  return (
    <Skeleton
      animation="wave"
      height={height}
      width={width}
      className={classes.cardRoot}
    />
  );
};

export const CardsContainer: React.FC<
  PropsWithChildren<CardsContainerProps>
> = ({ children, className, customSkeleton, skeleton, skeletonCount = 3 }) => {
  const classes = useCardStyles();
  return (
    <section className={clsx([classes.cardsContainer, className])}>
      {children ??
        [...Array(skeletonCount)].map((element, index) => (
          <React.Fragment key={index}>
            {customSkeleton ?? (
              <DefaultCardSkeleton
                height={skeleton?.height}
                width={skeleton?.width}
              />
            )}
          </React.Fragment>
        ))}
    </section>
  );
};

export default CardsContainer;
