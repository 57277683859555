import { Card as MuiCard, CardActionArea } from "@material-ui/core";
import type { PropsWithChildren } from "react";
import React from "react";
import clsx from "clsx";

import useCardStyles from "./Card.style";

export interface ICardProps {
  className?: string;
  width?: string;
  onClick?: () => void;
  enableShadow?: boolean;
}

export const Card: React.FC<PropsWithChildren<ICardProps>> = ({
  width = "300px",
  enableShadow,
  className,
  onClick,
  ...props
}) => {
  const classes = useCardStyles();
  const border = "none";
  return (
    <MuiCard
      className={clsx([
        classes.cardRoot,
        classes.cardBackground,
        { [classes.shadow]: enableShadow },
        className,
      ])}
      style={{ width, border }}
      onClick={onClick}
      {...props}
    >
      <CardActionArea>{props.children}</CardActionArea>
    </MuiCard>
  );
};

Card.defaultProps = {
  width: "300px",
  enableShadow: false,
};

export default React.memo(Card);
