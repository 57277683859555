
import * as actions from "../actions/actions.tsx";
import { updateObject } from "../../utils";

/**
 *  Mapping set of Reducers for each Authentication Actions
 * @param {Object} action - Action from action creator
 * @param {Object} state - Default application state
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action = {}) => {
     switch (action.type) {
          case actions.storeValue: return storeValue(state, action);
          case actions.clearForm: return clearForm(state, action);
          default: return state;
     }
};


/**
 * To Update Breadcrumb value on change of links
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const storeValue = (state, action) => {
     if (state.form == null) {
          state.form = {};
     }
     state.form[action.payload.id] = action.payload.value
     return updateObject(state);
}


/**
 * To Update Breadcrumb value on change of links
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const clearForm = (state, action) => {
     state = { form: null };
     return state;
}

const INITIAL_STATE = {
     form: {}
};

