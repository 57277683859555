import { createStyles, makeStyles } from "@material-ui/core/styles";
import type { Theme } from "@material-ui/core/styles";
import { TrimbleColors } from "legacy-components/TrimbleColors";

const useSubscriberStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: TrimbleColors.GrayLight,
      zIndex: 1,
    },
  })
);

export default useSubscriberStyles;
