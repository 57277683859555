
import { Box, ButtonGroup } from "@material-ui/core";
import { EnvironmentType } from "header/environment-selector/ENV_CONSTANTS";
import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import IdentityApplicationService from "libs/resources/identity/applications/IdentityApplicationService";
import { InputContainer } from "modus/components/form/input-container/InputContainer";
import CopyToClipboard from "modus/components/misc/CopyToClipboard";
import DataRemover from "modus/components/misc/DataRemover";
import { ViewButton } from "modus/components/misc/ViewButton";
import React from "react";
import { useSelector } from "react-redux";
import ApplicationConfigurationService from "../services/ApplicationConfigurationService";
import { ConsumerSecretRemover } from "./ConsumerSecretRemover";


interface ConsumerSecretProps {
     secretNumber: string ;
     name: string;
     hideDelete?: boolean;
     onDelete?: () => void;
}
/**
 * Renders a component that displays a consumer secret.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.secretNumber - The consumer secret number.
 * @returns {JSX.Element} - The rendered component.
 */
export const ConsumerSecret:React.FC<ConsumerSecretProps> = ({ secretNumber, name, onDelete, hideDelete = false }) => {
    
    const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment);

    const [ state, {subscribe}] = useAsyncAction(() => ApplicationConfigurationService.getSecretValue(identityEnvironment, name, secretNumber));
    
    const [open, setOpen] = React.useState<boolean>(false);
    const getSecret = () => {
        if(state?.loading) {
            return;
        }
        subscribe();
    }

    const toggleView = () => {
        if(state?.value) {    
            setOpen(!open);
            return;
        } 
        setOpen(true);
        getSecret();
    }

    return (
    <Box 
        display={"flex"} 
        alignItems={"flex-end"} 
        style={{transition: `all 0.02s ease-in-out`}}>
        <Box width="100%">
            <InputContainer
                type={state?.value?.secret && open ? "text" : "password"}
                value={state?.value?.secret ?? secretNumber}
                disabled={true}
            />
        </Box>
        <Box marginBottom={"7px"} display="flex" flexDirection={"row"}>
            <Box marginLeft="15px">
                <ButtonGroup className="consumer-secret-action-group">
                    <CopyToClipboard text={state?.value?.secret} subscribe={getSecret} state={state} />
                    <ViewButton state={state} open={open} onClick={toggleView}/>
                    <ConsumerSecretRemover appName={name} secretNumber={secretNumber} onDeleteSuccess={onDelete} disabled={hideDelete}/>
                </ButtonGroup>
            </Box>
        </Box>
    </Box>
);
}
  