import { FieldArrayRenderProps } from "react-final-form-arrays";
import { FieldRenderProps } from "react-final-form";
import { makeStyles, createStyles } from "@material-ui/core";
import { ReactElement, FC } from "react";
import { AssignableCollectionItem } from "../collection/AssignableCollection";

export interface FormSelectionListItemOption {
    value: string,
    title: string,
    helperText: string,
    icon?: ReactElement,
    disabled?: boolean
}

export interface FormSelectionGroupProps extends FieldRenderProps<string, HTMLElement> {
    options: FormSelectionListItemOption[];
    defaultSelectionIndex?: number;
    disabled?: boolean;
    isSwitch?: boolean;
}

type FieldType = FieldArrayRenderProps<string, HTMLElement> & FieldRenderProps<string, HTMLElement>;

export interface FormSelectionListProps extends FieldType {
    helperText?: string;
    options: FormSelectionListItemOption[],
    selectControl?: "checkbox" | "switch",
    disabled?: boolean
}

export interface FormSelectionGridColumn {
    key: string,
    title: string,
    align?: "right",
    width?: number,
    hidden?: boolean,
    component?: FC<any>,
    minWidth?: number
}

export interface FormSelectionGridOptions {
    headerStyle?: "none" | "tinted"
    columns: FormSelectionGridColumn[];
    messages?: {
        nodata?: ReactElement
    }
}

export type FormSelectionGridDataValue = {
    id: string,
    [key: string]: any
};

export interface FormSelectionGridData<T extends FormSelectionGridDataValue = FormSelectionGridDataValue> {
    value: T;
    selection: {
        assign?: boolean
        assigned?: boolean;
    };
}

export interface FormSelectionGridFieldValue<T extends FormSelectionGridDataValue = FormSelectionGridDataValue> {
    list: AssignableCollectionItem<T>[];
    toAdd: AssignableCollectionItem<T>[];
    toRemove: AssignableCollectionItem<T>[];
    hasChanges: boolean;
    count: number
}

export interface FormSelectionGridProps extends FieldRenderProps<FormSelectionGridFieldValue, HTMLElement> {
    gridOptions?: FormSelectionGridOptions,
    gridData: FormSelectionGridData[],
    readOnly?: boolean
    onCellClicked ?: ()=> void;
}

export const useFieldSelectionStyles = makeStyles(theme =>
    createStyles({
        divider: {
            marginLeft: theme.spacing(2)  
        },
    }),
);