import { createStyles, makeStyles } from "@material-ui/core/styles";
import type { Theme } from "@material-ui/core/styles";
import { TrimbleColors } from "legacy-components/TrimbleColors";

const useAlertStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertRoot: {
      display: "flex",
      padding: theme.spacing(1, 2),
      backgroundColor: TrimbleColors.White,
      borderLeft: ".5rem solid",
      borderRadius: ".125rem",
      border: "1px solid transparent",
      wordBreak: "break-all",
      position: "relative",
      marginBottom: "1rem",
      transition: "padding 0.5s ease-in-out",
    },
    noBorder: {
      borderWidth: 0,
    },
    hide: {
      height: 0,
      padding: 0,
      marginBottom: 0,
      border: "none",
    },
    icon: {
      display: "flex",
      opacity: 0.9,
      padding: "7px 0",
      fontSize: "22px",
      marginRight: "12px",
      flexDirection: "column",
      justifyContent: "center",
    },
    hideIcon: {
      display: "none",
    },
    message: {
      padding: "8px 0",
      textTransform: "lowercase",
      "&:first-letter": {
        textTransform: "uppercase",
      },
    },
    rightSpace: {
      paddingRight: "25px",
    },

    error: {
      borderColor: TrimbleColors.Red,
      color: TrimbleColors.Red,
    },
    success: {
      borderColor: TrimbleColors.GreenDark,
      color: TrimbleColors.GreenDark,
    },
    warning: {
      borderColor: TrimbleColors.Orange,
      color: TrimbleColors.Orange,
    },
    info: {
      borderColor: TrimbleColors.Brand.BlueMid,
      color: TrimbleColors.Brand.BlueMid,
    },
    loading: {
      borderColor: TrimbleColors.Brand.BlueMid,
      color: TrimbleColors.Brand.BlueMid,
    },
    secondary: {
      borderColor: TrimbleColors.Gray8,
      color: TrimbleColors.Gray8,
    },
    closeButton: {
      color: TrimbleColors.Gray6,
      fontSize: "1.5rem",
      opacity: ".5",
      fontWeight: 700,
      lineHeight: 1,
      textShadow: `0 1px 0 ${TrimbleColors.White}`,
    },
    right: {
      position: "absolute",
      right: 0,
      top: 0,
    },
  })
);

export default useAlertStyles;
