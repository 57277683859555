import { useState, useCallback, useReducer, Reducer, Dispatch } from "react";
import { useIntl } from "react-intl";
import { ProductEnvironmentType } from "../../../../libs/resources/product/ProductService";
import { ProductDocumentFieldFileInfo } from "./document-configs/ProductDocumentField";
import { mapToArray } from "../../../../libs/utils/shared/common";
import { ProductDocumentPublishedData } from "../models/products.model";
import { useResourceAbility } from "libs/security/authorization/Permission";

export interface ProductPublishOption {
    value: string;
    title: string;
    helperText: string;
    env: ProductEnvironmentType
}

interface UseProductPublishInfoOptions {
    useShortMessage?: boolean
}

const EXTENSTION_GROUP_REGEX = /\.[0-9a-z]+$/i;

const MIME_MAP = new Map([
    ["application/json", "JSON Document"],
    [".json", "JSON Document"],
    [".yaml", "YAML Document"]
])

interface AddDocInfoAction {
    type: "add",
    payload: {
        value: ProductDocumentFieldFileInfo | ProductDocumentFieldFileInfo[] | null | undefined
    }
}

interface SetDocInfoAction {
    type: "set",
    payload: {
        value: ProductDocumentFieldFileInfo[] | null | undefined
    }
}

interface RemoveDocInfoAction {
    type: "remove",
    payload: {
        id: string,
    }
}

interface UpdateDocInfoAction {
    type: "update",
    payload: {
        id: string,
        value: ProductDocumentFieldFileInfo
    }
}

interface UpdatePublishedDocInfoAction {
    type: "updatePublished",
    payload: {
        id: string,
        value: ProductDocumentPublishedData
    }
}

interface ProductDocumentationInfoState {
    docInfo: ProductDocumentFieldFileInfo[]
}

type ProductDocumentationInfoAction = AddDocInfoAction | RemoveDocInfoAction | UpdateDocInfoAction | SetDocInfoAction | UpdatePublishedDocInfoAction;

export type ProductDocumentationInfoDispatcher = Dispatch<ProductDocumentationInfoAction>

const productDocumentationInfoReducer: Reducer<ProductDocumentationInfoState, ProductDocumentationInfoAction> = (state, action) => {
    switch (action.type) {
        case "add": {
            const docs = mapToArray(action.payload.value).filter((selection): selection is ProductDocumentFieldFileInfo => !!selection && !state.docInfo.some(doc => doc.id === selection.id));

            if (docs.length) {
                return {
                    ...state,
                    docInfo: [
                        ...state.docInfo,
                        ...docs
                    ]
                };
            }

            return state;
        }
        case "set": {
            return {
                ...state,
                docInfo: action.payload.value ?? []
            }
        }
        case "remove": {
            return {
                ...state,
                docInfo: state.docInfo.filter(value => value.id !== action.payload.id)
            };
        }
        case "update": {
            const index = state.docInfo.findIndex(doc => doc.id === action.payload.id);

            if (index > -1) {
                const docInfo = state.docInfo;
                docInfo[index] = action.payload.value;

                return {
                    ...state,
                    docInfo: [...docInfo]
                }
            }

            return state;
        }
        case "updatePublished": {
            if (action.payload.value.publishedInfo) {
                return {
                    ...state,
                    docInfo: state.docInfo.map(value => {
                        if (value.id === action.payload.id) {
                            return {
                                ...value,
                                publishedGateways: action.payload.value.publishedInfo.reduce((agg: ProductEnvironmentType[], info) => {
                                    if (info.status === "published" && !agg.some(value => value === info.env)) {
                                        agg.push(info.env);
                                    } else {
                                        return agg.filter(value => value !== info.env)
                                    }
                                    return agg
                                }, [...(value.publishedGateways ?? [])])
                            }
                        } else {
                            return {
                                ...value,
                                publishedGateways: value.publishedGateways?.filter(env => !action.payload.value.publishedInfo.some(info => info.env === env && info.status === "published"))
                            }
                        }
                    })
                }
            }

            return state;

        }
        default: return state;
    }
}

export const useProductDocumentationInfo = () => {

    const [accept] = useState(Array.from(MIME_MAP.keys()));

    const getMIMEType = useCallback((name?: string) => {
        const ext = (name ?? "").match(EXTENSTION_GROUP_REGEX)?.[0];

        return ext ? MIME_MAP.get(ext) : undefined;
    }, []);

    const [docInfoState, docInfoDispatch] = useReducer(productDocumentationInfoReducer, {
        docInfo: []
    });

    return {
        accept,
        getMIMEType,
        docInfoState,
        docInfoDispatch
    }
}

export const useProductDocumentPublishOptions = () => {
    const intl = useIntl();

    const [can, state] = useResourceAbility({
        resource: ({ Product }) =>
            Product.publish.environment["trimble-prod"]
    });

    const [defaultPublishOptions] = useState<ProductPublishOption[]>([
        {
            value: "publishToNonProd",
            title: intl.formatMessage({
                defaultMessage: "Pre-Prod Environment"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Show this document in pre-prod environment"
            }),
            env: "trimble-pre-prod"
        }
    ]);
    if ((state.loaded || state.intermediate) && can.create()) {
        if(defaultPublishOptions.length == 1) {
            defaultPublishOptions.push({
                value: "publishToProd",
                title: intl.formatMessage({
                    defaultMessage: "Prod Environment"
                }),
                helperText: intl.formatMessage({
                    defaultMessage: "Show this document in prod environment"
                }),
                env: "trimble-prod"
            });
        }
    }

    return {
        defaultPublishOptions
    }
}

export const useProductPublishInfo = (options?: UseProductPublishInfoOptions) => {
    const intl = useIntl();
    const [can, state] = useResourceAbility({
        resource: ({ Product }) =>
            Product.publish.environment["trimble-prod"]
    });
    const [defaultPublishOptions] = useState<ProductPublishOption[]>([
        {
            value: "publishToNonProd",
            title: options?.useShortMessage ? intl.formatMessage({
                defaultMessage: "Pre-Prod Environment"
            }) : intl.formatMessage({
                defaultMessage: "Publish this product to Pre-Prod Environment"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Provides the environment for development and testing your APIs."
            }),
            env: "trimble-pre-prod"
        }
    ]);

    if ((state.loaded || state.intermediate) && can.create()) {
        if(defaultPublishOptions.length == 1) {
            defaultPublishOptions.push({
                value: "publishToProd",
                title: options?.useShortMessage ? intl.formatMessage({
                    defaultMessage: "Prod Environment"
                }) : intl.formatMessage({
                    defaultMessage: "Publish this product to Prod Environment"
                }),
                helperText: intl.formatMessage({
                    defaultMessage: "Production environment where you can deploy stable APIs for consumers to use in their production systems."
                }),
                env: "trimble-prod"
            })
        }
    }

    const [preProdPublishOptions] = useState([defaultPublishOptions[0]]);

    return {
        defaultPublishOptions,
        preProdPublishOptions
    }
}