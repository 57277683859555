import React, { useState } from "react";
import { Button, makeStyles, ListSubheader, createStyles, ListItem, ListItemText, List, ListItemSecondaryAction, Divider, Tooltip, ButtonGroup, Box, Chip } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { AccordionContent } from "legacy-components/form/components/accordion-form/AccordionForm";
import InlineIconFormatter from "legacy-components/formatters/InlineIconFormatter";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Helper from "pages/home/helper/Helper";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import { Field, FormRenderProps } from "react-final-form";
import FormSelectionGroup from "legacy-components/form/input/FormSelectionGroup";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import FormContainer, { FormContainerOptions, FormContainerSubmitHandler } from "legacy-components/form/container/FormContainer";
import ThreatConfigHelper from "pages/home/helper/ThreatConfigHelper";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import { useSelector } from "react-redux";
import { FORM_ERROR } from "final-form";
import { useHistory } from "react-router";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const useStyles = makeStyles(theme =>
    createStyles({
        divider: {
            marginLeft: theme.spacing(2)
        },
        list_font: {
            ffontSize: "inherit"
        }
    }));

const SecurityConfig = (props: any) => {

    const protectionTypes = () => {
        const protectionTypeOptions = [];
        let types = props.deployment?.threatProtection?.types;
        if (types) {
            for (let type of types) {
                protectionTypeOptions.push(type.type);
            }
        }
        return protectionTypeOptions;
    };

    const history = useHistory();

    const [isShowThreatConfigHelper, toggleThreatConfigHelper] = useState(false);

    let proxyDetails = useSelector((state: any) => state.proxyManager.proxy);

    const onSubmit: FormContainerSubmitHandler<any> = ({ stepperOptions }) => (data, form) => {
        return new Promise((resolve) => {
            const formState = form.getState();
            if (formState.pristine) {
                resolve(1);
            } else {
                UnifiedAPIService.post(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.deploy_proxy, {
                        apiName: proxyDetails?.apiName,
                        environmentName: props.env?.environmentName
                    }), {
                    backendUrl: props?.deployment.backendUrl,
                    threatProtection: data.security,
                    tls: props?.deployment?.ssl ? props?.deployment?.ssl.tls : null,
                    isAdvanced: props?.deployment?.isAdvanced,
                }).then(() => {
                    resolve && resolve(0);
                    props.onUpdateSuccess && props.onUpdateSuccess();
                }, error => {
                    resolve && resolve({
                        [FORM_ERROR]: error.response?.data?.message ?? "Unknown error occured"
                    });
                });

            }
        })
    }
    const intl = useIntl();
    const classes = useStyles();
    const protectionTypeOptions = protectionTypes();
    const [customSecurityOptions] = useState([
        {
            value: "JSON_LIMIT",
            title: intl.formatMessage({
                defaultMessage: "JSON Limit detection"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Allows to set limits on the JSON request payload"
            })
        },
        {
            value: "XML_LIMIT",
            title: intl.formatMessage({
                defaultMessage: "XML Limit detection"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Allows to set limits on the XML request payload"
            })
        },
        {
            value: "REGEX",
            title: intl.formatMessage({
                defaultMessage: "Regex Threat detection"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Allows to add regex patterns to filter / match the malicious contents"
            })
        }

    ]);

    const Controls = () => {
        return props.deployment?.isAdvanced && props.deployment?.threatProtection?.protectionType === "CUSTOM" &&
            <List subheader={<ListSubheader>The tick mark (✓) shows that those policies are customized and enabled via Advance User API</ListSubheader>}>
                {customSecurityOptions.map(({ title, helperText, value }) => {
                    return (
                        <React.Fragment key={value}>
                            <ListItem dense button>

                                <ListItemText inset={true} primary={title} secondary={helperText} />
                                {(protectionTypeOptions.includes(value) &&
                                    <ListItemSecondaryAction>
                                        <CheckCircleRoundedIcon color="secondary" titleAccess="enabled" />
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                            <Divider className={classes.divider} component="li" />
                        </React.Fragment>
                    );
                })}
            </List>
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>, containerOptions: FormContainerOptions) => {
        const [securityOptions1] = useState([
            {
                value: "NONE",
                title: intl.formatMessage({
                    defaultMessage: "Without Threat detection"
                }),
                helperText: intl.formatMessage({
                    defaultMessage: "Allows all the requests without any filters"
                })
            },
            {
                value: "BASIC",
                title: intl.formatMessage({
                    defaultMessage: "Basic threat detection and request filtering"
                }),
                helperText: intl.formatMessage({
                    defaultMessage: "We support basic threat detection and filters the request based on standard values.Click help icon to know more on threat detection policies"
                })
            }

        ]);

        const [securityOptions2] = useState([
            {
                value: "CUSTOM",
                title: intl.formatMessage({
                    defaultMessage: "Custom threat detection and request filtering"
                }),
                helperText: intl.formatMessage({
                    defaultMessage: "Adding Custom Payload Limits and Regex are supported. Currently, This feature is only available via Advance User API"
                })
            }

        ]);

        return (
            <>
                <FormInputContainer title={
                    <div>
                        <InlineIconFormatter icon={<HelpOutlineOutlinedIcon onClick={() => { toggleThreatConfigHelper(true) }} />}>
                            <FormattedMessage defaultMessage="Select the type of proxy level security" />
                        </InlineIconFormatter>
                        <Helper open={isShowThreatConfigHelper} handleClose={() => { toggleThreatConfigHelper(false) }} children={<ThreatConfigHelper />}></Helper>
                    </div>
                }>
                    <Field
                        name="security"
                        children={FormSelectionGroup}
                        options={securityOptions1}
                        disabled={props.deployment?.isAdvanced}
                        defaultSelectionIndex={props.deployment?.threatProtection?.protectionType == "CUSTOM" ? 2 : props.deployment?.threatProtection?.protectionType == "BASIC" ? 1 : 0}
                    />
                    {(props.deployment?.isAdvanced &&
                        <Field
                            name="security1"
                            children={FormSelectionGroup}
                            options={securityOptions2}
                            disabled={props.deployment?.threatProtection == null ? true : false}
                            defaultSelectionIndex={props.deployment?.threatProtection?.protectionType === "CUSTOM" ? 0 : ""}
                        />)}
                    {props.deployment?.isAdvanced && props.deployment?.threatProtection?.protectionType === "CUSTOM" &&
                        <Field
                            name="security"
                            children={Controls}
                        />
                    }

                </FormInputContainer>

                <ActionInliner>
                <ButtonGroup>
                <Tooltip title={"This feature is deprecated now"} placement="left" arrow>
                    <span>
                    <Button
                        disabled={props.deployment?.isAdvanced ||
                            formRenderProps.submitting ||
                            (!(props.status === "DEPLOY_COMPLETED" || props.status === "unpublish")) ||
                            props.status

                        }
                        type="submit" variant="contained" color="primary" disableElevation
                    >
                        <FormattedMessage defaultMessage="Save" />
                    </Button>
                    </span>
                </Tooltip>
                </ButtonGroup>
                </ActionInliner>
            </>

        );
    }




    return (
        <AccordionContent size="small" header={<><FormattedMessage defaultMessage="Security Configuration" /><Box component={"span"} mr={1}/><Chip label="deprecated" size="small"/></>} >
            <FormContainer stepperId={"security"} FormProps={{ onSubmit }} children={FormComponents} />
        </AccordionContent>
    )
};

export default SecurityConfig;