import { Box, Tab, Tabs, useTheme, Select, MenuItem } from "@material-ui/core";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import { APIDeployment, EventDeployment, Version } from "../products/components/Product.model";
import { TabContext, TabPanel } from "@material-ui/lab";
import { APIServiceEndpoints, EventServiceEndpoints } from "../products/components/ProductServices";
import APIReference from "../consumer/components/products/product/apireference/APIReference";
import EventsSpecReference from "../consumer/components/products/product/apireference/EventsSpecReference";

interface ServiceVersionProps {
    productName: string
    version: Version;
    type: string;
}
const tabMapping = {
    SUMMARY: 0,
    SPECIFICATION: 1
}
export const ServiceVersionDetails:React.FC<ServiceVersionProps> = ({productName, version, type}) => {
    const [value, setValue] = React.useState(0);

    const handleChangeIndex = (value: any) => {
        setValue(value);
    };

    const theme = useTheme();

    return (
        <TabContext value={value as any}>
            <Tabs
                textColor="primary" indicatorColor="primary" value={value} >
                <Tab style={{fontSize: "14px", fontWeight: 600}} label="SUMMARY" value={0} onClick={() => handleChangeIndex(0)} />
                <Tab style={{fontSize: "14px", fontWeight: 600}} label="SPECIFICATION" value={1} onClick={() => handleChangeIndex(1)} />
            </Tabs>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={0 as any} tabIndex={0} key={"SUMMARY"} style={{padding:"0px"}}>
                    {type === "api" ? (
                        <APIDeployments apiDeployments={version?.apiDeployments} />
                    ) : (
                        <EventDeployments eventDeployments={version?.eventDeployments} />
                    )}
                </TabPanel>
                <TabPanel value={1 as any} tabIndex={1} key={"SPECIFICATION"} style={{padding:"0px"}}>
                {type === "api" ? (
                        <APISpecification productName={productName} prodSpecUri={version?.apiSpecProdUri ?? ''} preprodSpecUri={version?.apiSpecPreprodUri ?? ''} />
                    ) : (
                        <EventSpecification productName={productName} specUri={version?.eventSpecUri ?? ''} />
                    )}
                </TabPanel>
            </SwipeableViews>
        </TabContext>
    );
};

interface APIDeploymentProps {
    apiDeployments?: APIDeployment[];
}
export const APIDeployments: React.FC<APIDeploymentProps> = ({ apiDeployments }) => {
    const instancesLength = apiDeployments?.reduce((acc, deployment) => acc + (deployment.instances?.length || 0), 0) || 0;
    const isDeploymentsUnavailable = !apiDeployments || apiDeployments.length === 0 || instancesLength === 0;

    return (
        <>
            {!isDeploymentsUnavailable && apiDeployments && <APIServiceEndpoints apiDeployments={apiDeployments} />}
            {isDeploymentsUnavailable && (
                <Box m={1} p={3} border="1px solid #000">
                    Service not available for the selected configuration
                </Box>
            )}
        </>
    );
};

interface EventDeploymentProps {
    eventDeployments?: EventDeployment[];
}
export const EventDeployments:React.FC<EventDeploymentProps> = ({eventDeployments}) => {
    return (
        <>
            {(eventDeployments && eventDeployments?.length > 0 )&& (<EventServiceEndpoints eventDeployments={eventDeployments} />)}
            {(!eventDeployments || eventDeployments?.length === 0) && (<Box m={1} p={3} border="1px solid #000">
                No Deployments Available
            </Box>)}
        </>
    );
}

interface EventSpecProps {
    productName: string;
    specUri: string;
}

interface APISpecProps {
    productName: string;
    prodSpecUri: string;
    preprodSpecUri: string;
}

export const APISpecification:React.FC<APISpecProps> = ({productName, prodSpecUri, preprodSpecUri}) => {
    const specDetails: any = {};

    if (preprodSpecUri) {
        specDetails.preProd = { fileName: preprodSpecUri };
    }

    if (prodSpecUri) {
        specDetails.prod = { fileName: prodSpecUri };
    }

    return (
        <Box>
            <APIReference
                specDetails={specDetails}
                redocServerUrl=" "
                specUrl={process.env.REACT_APP_SPEC_URL as string}
                productName={productName} />
        </Box>
    );
}

export const EventSpecification:React.FC<EventSpecProps> = ({productName, specUri}) => {
    const specDetails: any = {};

    if (specUri) {
        specDetails.prod = { fileName: specUri };
    }

    return (
        <Box>
            { <EventsSpecReference
                specDetails={specDetails}
                redocServerUrl=" "
                specUrl={process.env.REACT_APP_EVENT_SPEC_URL as string}
                productName={productName} /> }
        </Box>
    );
}