import React, { FC, memo, ReactElement, useState, useCallback, useEffect } from 'react'
import FormContainer, { FormContainerChild, FormContainerSubmitHandler } from '../../../../../legacy-components/form/container/FormContainer';
import { Button, makeStyles, createStyles, Typography } from '@material-ui/core';
import ActionInliner from '../../../../../legacy-components/form/action/ActionInliner';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ProductDocumentPublishFormData, ProductDocumentPublishedData } from '../../models/products.model';
import { ProductDocumentFieldFileInfo } from './ProductDocumentField';
import { FORM_ERROR } from 'final-form';
import FormInputContainer from '../../../../../legacy-components/form/container/FormInputContainer';
import FormSelectionList from '../../../../../legacy-components/form/input/FormSelectionList';
import { FieldArray } from 'react-final-form-arrays';
import { useProductDocumentPublishOptions } from '../ProductPublishInfo';
import ProductService, { ProductEnvironmentType } from '../../../../../libs/resources/product/ProductService';
import { forkJoin, from } from 'rxjs';
import { map } from 'rxjs/operators';

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            padding: spacing(3)
        },
    }),
);

interface ProductDocumentPublishFormProps {
    onChange: (data: ProductDocumentPublishedData) => void,
    docInfo: ProductDocumentFieldFileInfo,
    formContent?: ReactElement,
    productName: string;
}

const ProductDocumentPublishForm: FC<ProductDocumentPublishFormProps> = ({ onChange, docInfo, formContent, productName }) => {
    const styles = useStyles();
    const { defaultPublishOptions } = useProductDocumentPublishOptions();
    const [publishMap] = useState(new Map<ProductEnvironmentType, "published" | "unpublished">());
    const [initialState, setInitialState] = useState(defaultPublishOptions.filter(option => docInfo?.publishedGateways?.some(env => env === option.env)).map(option => option.value));

    const [initialValues, setInitialValues] = useState(() => ({
        publishOptions: [...initialState]
    }))

    useEffect(() => {
        setInitialState(defaultPublishOptions.filter(option => docInfo?.publishedGateways?.some(env => env === option.env)).map(option => option.value));
        setInitialValues({
            publishOptions: defaultPublishOptions.filter(option => docInfo?.publishedGateways?.some(env => env === option.env)).map(option => option.value)
        })
    }, [defaultPublishOptions?.length]);

    const closeHanlder = useCallback(() => {
        onChange({
            publishedInfo: Array.from(publishMap.entries()).map(value => {
                return {
                    env: value[0],
                    status: value[1]
                }
            })
        });
    }, [onChange, publishMap]);

    const onSubmit: FormContainerSubmitHandler<ProductDocumentPublishFormData> = () => (data, form) => {
        const formState = form.getState();

        const createPromise = (openApiSpecId: string, env: ProductEnvironmentType) => {
            return ProductService.environments[env].updateSpec({
                openApiSpecId,
                productName
            })
        }

        return new Promise(async (resolve) => {
            if (formState.pristine) {
                resolve();
            } else {
                const collection = defaultPublishOptions.reduce((agg: Array<{
                    key: string
                    openApiSpecId: string,
                    env: ProductEnvironmentType,
                    action: "publish" | "unpublish"
                }>, option) => {
                    const hasInitialValue = publishMap.has(option.env) ? false : initialState.some(value => value === option.value);

                    const isPublished = hasInitialValue || publishMap.get(option.env) === "published";
                    const hasOption = data.publishOptions?.some(value => value === option.value);

                    if (!isPublished && hasOption && !hasInitialValue) {
                        if (docInfo.openApiSpecId) {
                            agg.push({
                                key: option.value,
                                openApiSpecId: docInfo.openApiSpecId,
                                env: option.env,
                                action: "publish"
                            });
                        }
                    } else if (isPublished && !hasOption) {
                        agg.push({
                            key: option.value,
                            openApiSpecId: "",
                            env: option.env,
                            action: "unpublish"
                        });
                    }

                    return agg;
                }, []);

                if (collection.length > 0) {
                    forkJoin(...collection.map(option => from(createPromise(option.openApiSpecId, option.env)).pipe(map(value => {
                        if (option.action === "publish") {
                            publishMap.set(option.env, "published");
                        } else {
                            publishMap.set(option.env, "unpublished");
                        }

                        return {
                            value,
                            env: option.env
                        };
                    })))).subscribe(() => resolve(), (error) => resolve({
                        [FORM_ERROR]: error
                    }));
                } else {
                    resolve();
                }
            }
        });
    }

    const FormComponents: FormContainerChild<ProductDocumentPublishFormData> = (formRenderProps) => {
        return (
            <div className={styles.root}>
                {formContent}

                <FormInputContainer padding="inset-top" title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Environments" />} />}>
                    <FieldArray
                        name="publishOptions"
                        children={FormSelectionList}
                        options={defaultPublishOptions}
                        selectControl="switch"
                    />
                </FormInputContainer>

                <ActionInliner padding="inset-top">
                    <Button onClick={closeHanlder} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                    <Button disabled={formRenderProps.submitting || formRenderProps.pristine} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Save Changes" /></Button>
                </ActionInliner>
            </div>);
    }

    return <FormContainer previewErrors={true} FormProps={{ onSubmit, initialValues }} onSubmitSuccess={closeHanlder} children={FormComponents} />;
}

ProductDocumentPublishForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    docInfo: PropTypes.any.isRequired
}

export default memo(ProductDocumentPublishForm);