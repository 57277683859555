import React, { useEffect, useState } from "react";
import type { IProduct, ITeamFilter } from "../../../service/ProductService";
import {ProductService} from "../../../service/ProductService";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useProductListStyles from "./ProductList.style";
import { Collapse, Divider, Link, FormControl, Input, InputAdornment, InputLabel, Button, FormControlLabel, Switch, Avatar, Tooltip } from "@material-ui/core";
import AlertService from "legacy-components/alert/AlertService";
import CardsContainer from "legacy-components/card/CardContainer";
import Card from "legacy-components/card/Card";
import CardContent from "legacy-components/card/CardContent";
import CardHeader from "legacy-components/card/CardHeader";
import Typography from "legacy-components/typography/Typography";
import CompanyLogo from "legacy-components/icons/CompanyLogo";
import Alert from "legacy-components/alert/Alert";
import GenericPageTitle from "legacy-components/typography/generic-page-title/GenericPageTitle";
import { Column, Row } from "legacy-components/responsive-grid/ResponsiveGrid";
import { useHistory } from "react-router";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { FormattedMessage } from "react-intl";
import { useRef } from "react";
import { sanitize } from "dompurify";


export type ProductSortOptions = "newest" | "oldest" | "asc" | "desc" 

/* eslint-disable-next-line */
export interface ProductListProps {
  type?: string;
  onProductClick?: (productName: string) => void;
  searchText?: string;
  selectedSectors?: string[];
  selectedTeams?: string[];
  selectedTypes?: string[];
  selectedVisibilities?: string[];
  applyFilter?: string;
  enableCollapse?: boolean;
  sortBy?: ProductSortOptions;
  includeNoSpecApi?:boolean;
  clearAll?:boolean;
}

export const ProductList: React.FC<ProductListProps> = ({
  type = "GLOBAL",
  onProductClick,
  searchText,
  selectedSectors,
  selectedTeams,
  selectedTypes,
  selectedVisibilities,
  applyFilter,
  enableCollapse = false,
  sortBy,
  includeNoSpecApi,
  clearAll,
}) => {
  const [productList, setProductList] = useState<Array<IProduct> | null>(null);

  const [filteredProductList, setFilteredProductList] = useState<
    Array<IProduct> | undefined
  >(undefined);

  const [expanded, setExpanded] = React.useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const classes = useProductListStyles();

  useEffect(() => {
    enableCollapse ? setExpanded(false) : setExpanded(true);
  }, [enableCollapse]);
  useEffect(() => {
    ProductService.getProductList(type).toPromise().then(
      (output) => {
        setProductList(output.data);
      },
      (error) => {
        setProductList([]);
      }
    );
  }, [type]);
  useEffect(() => {
    if(type == "GLOBAL") {
      var text:string = searchText ? searchText.toLowerCase() : "" 
      var list:IProduct[] | undefined = productList
        ?.filter(
          (product) =>  ((product.displayName.toLowerCase().indexOf(text) >= 0) ||
                (product.shortDescription?.toLowerCase().indexOf(text) >= 0))
        ).filter(
          (product) => {
            if(selectedSectors && selectedSectors.length>0)
              return selectedSectors.includes(product.sectorName)
            else
              return true   
          }
        ).filter(
          (product) => {
            if(selectedTeams && selectedTeams.length>0)
              return selectedTeams.includes(product.teamDisplayName)
            else
              return true   
          }
        ).filter(
          (product) => {
            if(selectedTypes && selectedTypes.length>0)
              return selectedTypes.some(r=> product.apiTypeMapping.includes(r))
            else
              return true   
          }
        ).filter(
          (product) => {
            if(selectedVisibilities && selectedVisibilities.length>0)
              return selectedVisibilities.some(r=> product.visibility.includes(r.toLowerCase()))
            else
              return true   
          }
        ).filter(
          (product) => {
            if(!includeNoSpecApi)
              return product.specAvailability == true
            else
              return true
          }
        );
    list = sortProductList(list);  
    setFilteredProductList(list);
    } else {
      setFilteredProductList(productList ?? undefined)
    }
  }, [searchText, productList, applyFilter, sortBy, includeNoSpecApi, clearAll]);

  const sortProductList = (products: Array<IProduct> | undefined) => {
    switch(sortBy) {
      case "newest":
        return products?.sort((a, b) => (new Date(a.created) > new Date(b.created)) ? -1 : 1)
      case "oldest":
        return products?.sort((a, b) => (new Date(a.created) > new Date(b.created)) ? 1 : -1)
      case "asc":
        return products?.sort((a, b) => (a.displayName > b.displayName) ? 1 : -1)
      case "desc":
        return products?.sort((a, b) => (a.displayName > b.displayName) ? -1 : 1)
    }
  }

  useEffect(() => {
    if (productList?.length === 0) {
      AlertService.info({
        message: "No Products Found",
        isAutoHide: false,
        id: "PRODUCT_LIST",
      });
    } else {
      AlertService.clear("PRODUCT_LIST");
    }
  }, [productList]);

  return (
    <>
      <Collapse
        in={expanded}
        timeout="auto"
        collapsedHeight="200px"
        data-testid="products">
        <CardsContainer
          skeletonCount={3}
          customSkeleton={
            <Card data-testid="card">
              <CardHeader data-testid = "card-header"></CardHeader>
              <CardContent isShowSkeleton></CardContent>
            </Card>
          }>
          {filteredProductList?.map((product: IProduct) => (
            <Card
              onClick={() => onProductClick && onProductClick(product.name)}
              key={product.id}
              data-testid = {`${product.id}-card`}
              >
              <CardHeader
                avatar={<CompanyLogo color="primary" />}
                title={product.displayName}
                subTitle={product.teamDisplayName}
              ></CardHeader>
              <CardContent>
                <Typography type="small" className={classes.description} data-testid = "description">
                  {sanitize(product?.shortDescription ?? "")}
                   {!product?.shortDescription && 
                  product?.longDescription?.split('\n').map(str => <p>{sanitize(str)}</p>)}
                </Typography>
              </CardContent>
            </Card>
          ))}
          {filteredProductList && filteredProductList.length == 0 && !includeNoSpecApi && (
            <Typography type="h4" color="primary" className={classes.noProductMsg}>
                No API Product match  found. Please change the filter criteria or Enable the 'API Without API Spec toggle' option
            </Typography>
          )}
          {filteredProductList && filteredProductList.length == 0 && includeNoSpecApi && (
            <Typography type="h4" color="primary" className={classes.noProductMsg}>
                No API Product match  found. Please change the filter criteria
            </Typography>
          )}
        </CardsContainer>
        <Alert id="PRODUCT_LIST" />
      </Collapse>
      {enableCollapse &&
        filteredProductList &&
        filteredProductList?.length > 3 && (
          <Link onClick={handleExpandClick}>
            {expanded ? (
              <Typography weight="semiBold" type="body">
                Show Less <ArrowDropUpIcon className={classes.collapseIcon} />{" "}
              </Typography>
            ) : (
              <Typography weight="semiBold" type="body">
                Show More <ArrowDropDownIcon className={classes.collapseIcon} />{" "}
              </Typography>
            )}
          </Link>
        )}
    </>
  );
};

export interface ProductTypeProps {
  onProductClick: (productName: string) => void;
}

export interface GlobalProductsTypeProps extends ProductTypeProps {
  apiTypesFromBackend: string[]
  teamFiltersFromBackend: ITeamFilter[]
}

export interface ProductFilterProps {
  filterLabel: string;
  initialValues: string[];
  clearSelectedTeams?: boolean;
  toDelete?: string;
  defaultSelectedValues?: string[];
  onSuccess: (data: string[]) => void;
  clearAll?: boolean;
}

export interface ProductSortProps {
  onSortChange : (data: ProductSortOptions) => void
}

export const GlobalProducts: React.FC<GlobalProductsTypeProps> = ({
  onProductClick, apiTypesFromBackend: sectorNamesFromBackend, teamFiltersFromBackend
}) => { 

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedSectors, setSelectedSectors] = useState<string[]|any>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]|any>([]);
  const [selectedApiTypes, setSelectedApiTypes] = useState<string[]|any>([]);
  const [selectedVisibilities, setSelectedVisibilities] = useState<string[]|any>([]);
  const [applyFilter, setApplyFilter] = useState<string>("");
  const [sectorNames, setSectorNames] =useState<string[]>([]);
  const [teamNames, setTeamNames] =useState<string[]>([]);

  // Product List should include APIs without specification by default.
  const [includeNoSpecApi, setIncludeNoSpecApi] = useState<boolean>(true);

  const [chipToDeleteSector, setChipToDeleteSector] = useState<string>("");
  const [chipToDeleteTeam, setChipToDeleteTeam] = useState<string>("");
  const [chipToDeleteApiType, setChipToDeleteApiType] = useState<string>("");
  const [chipToDeleteVisibility, setChipToDeleteVisibiity] = useState<string>("");

  const [clearSelectedTeams, setClearSelectedTeams] = useState<boolean>(false);

  const [sortBy, setSortBy] = useState<ProductSortOptions>("asc");
  const [clearAll, setClearAll] = useState<boolean>(false);

  const classes = useProductListStyles();

  const Controls = () => (
    <FormControl>
      <Input
        autoFocus
        className={classes.searchterm}
        id="searchTerm"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        onChange={(event) => {
          setSearchTerm(event.target.value)
        }}
        placeholder={"Search for an API"}
        value={searchTerm}
        autoComplete="off"
      />
    </FormControl>
  );


  const SpecFilter = () => (
    <FormControl>
      <FormControlLabel
        control={
          <Switch
          size="small"
            color="primary"
            onChange={(event) => {
              setIncludeNoSpecApi(event.target.checked)
            }}
            
            checked={includeNoSpecApi}
          />
        }
        label="Include API without Spec"
      />
    </FormControl>
  )

  const onSectorSelection = (data: string[]) => {
    setSelectedSectors(data);

    var possibleTeamForSector: string[] = [];

    if(data && data.length > 0) {
      teamFiltersFromBackend.map((fromBackend) => {
        if(data.includes(fromBackend.sectorName))
          possibleTeamForSector.push.apply(possibleTeamForSector, fromBackend.teams);
      })
    } else {
      teamFiltersFromBackend.map((data) => {
        possibleTeamForSector.push.apply(possibleTeamForSector, data.teams);
      })
    }
    setClearSelectedTeams(true);
    setTeamNames(possibleTeamForSector)
    setSelectedTeams([])
    setClearAll(false);
  };

  const onTeamSelection = (data: string[]) => {
    setSelectedTeams(data);
    setClearSelectedTeams(false);
    setClearAll(false);
  };

  const onTypeSelection = (data: string[]) => {
    setSelectedApiTypes(data);
    setClearAll(false);
  };

  const onVisibilitySelection = (data: string[]) => {
    setSelectedVisibilities(data);
    setClearAll(false);
  };

  const onSortChange = (data: ProductSortOptions) => {
    setSortBy(data)
 }

  const applyFilters = () => {
    let random = Math.random().toString()
    setApplyFilter(random);
  }

  const defaultsSelectedVisibilities: string[] = ["Sector", "Trimble"];

  useEffect(() => {
    teamFiltersFromBackend.map((data) => {
      sectorNames.push(data.sectorName)
      teamNames.push.apply(teamNames, data.teams);
    })
  }, [teamFiltersFromBackend])

  const visibilities = [
    'Team',
    'Sector',
    'Trimble',   
  ];

  const handleSectorDelete = (chipToDelete: string) => () => {
    setChipToDeleteSector(chipToDelete);
    setSelectedSectors((chips: string[]) => chips.filter((chip: string) => chip !== chipToDelete));
  };

  const handleTeamDelete = (chipToDelete: string) => () => {
    setChipToDeleteTeam(chipToDelete);
    setSelectedTeams((chips: string[]) => chips.filter((chip: string) => chip !== chipToDelete));
  };

  const handleApiTypeDelete = (chipToDelete: string) => () => {
    setChipToDeleteApiType(chipToDelete);
    setSelectedApiTypes((chips: string[]) => chips.filter((chip: string) => chip !== chipToDelete));
  };

  const handleVisibilityDelete = (chipToDelete: string) => () => {
    setChipToDeleteVisibiity(chipToDelete);
    setSelectedVisibilities((chips: string[]) => chips.filter((chip: string) => chip !== chipToDelete));
  };

  const handleClearAll = () => {
    setSelectedSectors([])
    setSelectedTeams([])
    setSelectedApiTypes([])
    setSelectedVisibilities([])
    setClearAll(true)
  }
    
  return (
    <section data-testid="global-products">
      <GenericPageTitle title="Explore Trimble API Library" controls={<Controls/>} />
      <Row align={"end"}>
        <Column >
          <div style={{display: "flex"}}>
              <ProductFilters filterLabel={"Sector"} initialValues={sectorNames} toDelete={chipToDeleteSector} onSuccess={onSectorSelection} clearAll={clearAll}/>
              <ProductFilters filterLabel={"Team"} initialValues={teamNames} toDelete={chipToDeleteTeam} clearSelectedTeams={clearSelectedTeams} onSuccess={onTeamSelection} clearAll={clearAll}/>
              <ProductFilters filterLabel={"API Type"} initialValues={sectorNamesFromBackend} toDelete={chipToDeleteApiType} onSuccess={onTypeSelection} clearAll={clearAll}/>
              <ProductFilters filterLabel={"Visibility"} initialValues={visibilities} defaultSelectedValues={defaultsSelectedVisibilities} toDelete={chipToDeleteVisibility} onSuccess={onVisibilitySelection} clearAll={clearAll}/>
             
              <Button variant="contained" color="primary" size="medium"
                className={classes.applyFilterButton} onClick={applyFilters}>Apply Filters</Button>
                          
              <div  style={{  marginLeft: "auto"}} >
                <ProductSort onSortChange={onSortChange}/>
              </div>
          </div>
        </Column>
      </Row>
      <Row>
        <div className={classes.chips}>

              {(selectedSectors as string[]).map((value) => (
                <Tooltip placement="left-start" enterDelay={100} title={<FormattedMessage defaultMessage="Sector" />}>
                  <Chip  key={value} avatar={<Avatar>S</Avatar>} label={value} className={classes.chip}  variant="outlined" onDelete= {handleSectorDelete(value)}/>
                </Tooltip>
              ))}
              {(selectedTeams as string[]).map((value) => (
                  <Tooltip placement="left-start" enterDelay={100} title={<FormattedMessage defaultMessage="Team" />}>
                    <Chip  key={value} avatar={<Avatar>T</Avatar>} label={value} className={classes.chip}  variant="outlined"  onDelete= {handleTeamDelete(value)}/>
                  </Tooltip>
              ))}
              {(selectedApiTypes as string[]).map((value) => (
                 <Tooltip placement="left-start" enterDelay={100} title={<FormattedMessage defaultMessage="API Type" />}>
                  <Chip  key={value} avatar={<Avatar>AT</Avatar>} label={value} className={classes.chip}  variant="outlined" onDelete= {handleApiTypeDelete(value)}/>
                  </Tooltip>
              ))}
              {(selectedVisibilities as string[]).map((value) => (
                <Tooltip placement="left-start" enterDelay={100} title={<FormattedMessage defaultMessage="Visibility" />}>
                  <Chip  key={value} avatar={<Avatar>V</Avatar>} label={value} className={classes.chip}  variant="outlined" onDelete= {handleVisibilityDelete(value)}/>
                </Tooltip>
              ))}
              {(((selectedSectors as string[]).length > 0) || ((selectedTeams as string[]).length > 0) || 
                  ((selectedApiTypes as string[]).length > 0) || ((selectedVisibilities as string[]).length > 0)) && (
                    <Chip  key="Clear All" label="Clear All" className={classes.chip} onClick={handleClearAll}/>
              )}
        </div>
        <div className={classes.toggleFilter}>
            <SpecFilter/>
        </div>
      </Row>
      <ProductList
        type="GLOBAL"
        onProductClick={onProductClick}
        searchText={searchTerm}
        selectedSectors={selectedSectors}
        selectedTeams={selectedTeams}
        selectedTypes={selectedApiTypes}
        selectedVisibilities={selectedVisibilities}
        applyFilter={applyFilter}
        sortBy={sortBy}
        includeNoSpecApi={includeNoSpecApi}
        clearAll={clearAll}></ProductList>
    </section>
  );
};

export const FeaturedProducts: React.FC<ProductTypeProps> = ({
  onProductClick,
}) => {
  return (
    <section data-testid="featured-products">
      <GenericPageTitle title="Featured Trimble Cloud API Products" />
      <ProductList
        type="FEATURED"
        onProductClick={onProductClick}
        enableCollapse
      />
    </section>
  );
};

export const ProductFilters: React.FC<ProductFilterProps> = ({
  filterLabel, initialValues, onSuccess, clearSelectedTeams, toDelete, defaultSelectedValues, clearAll
}) => {

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
  const classes = useProductListStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedValues(event.target.value as string[]);
  };

  const handleDelete = (chipToDelete: string) => () => {
    setSelectedValues((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  useEffect(() => {
    onSuccess(selectedValues)
  }, [selectedValues])

  useEffect(() => {
    setSelectedValues(defaultSelectedValues ? defaultSelectedValues : initialValues)
  }, [])

  useEffect(() => {
    if(clearSelectedTeams || clearAll) {
      setSelectedValues([]);
    }
  },[clearSelectedTeams, clearAll])
  
  useEffect(() => {
    if(toDelete != undefined) {
      setSelectedValues((chips) => chips.filter((chip) => chip !== toDelete));
    }
  }, [toDelete])

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id={filterLabel}>{filterLabel}</InputLabel>
        <Select
          labelId={filterLabel}
          id="demo-mutiple-checkbox"
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => (
            <div className={classes.chips}>
                <Chip
                  key={(selected as string[]).length}
                  label={(selected as string[]).length + " selected"}
                  className={classes.chip}
                 ></Chip>
            </div>
          )}
          MenuProps={MenuProps}
          style={{paddingTop: "0", height: "40px"}}
        >
          {initialValues.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedValues.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {/* <Row>
        <div className={classes.chips}>
              {(selectedValues as string[]).map((value) => (
                <Chip  key={value} label={value} className={classes.chip}  onDelete= {handleDelete(value)}/>
              ))}
        </div>
        </Row> */}
      </FormControl>
    </>
  );
};

export interface SortType {
  key: ProductSortOptions
  value: string
}

export const ProductSort: React.FC<ProductSortProps> = ({
    onSortChange
}) => {

  const classes = useProductListStyles();

  const [selectedValues, setSelectedValues] = React.useState<ProductSortOptions>("asc");

  let initialValues:SortType[] = [{key: "newest", value: "Newest"}, {key:"oldest", value: "Oldest"}, 
    {key: "asc" , value: "Name (A-Z)"}, {key:"desc" , value: "Name (Z-A)"}]

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setSelectedValues(event.target.value as ProductSortOptions);
  };

  useEffect(() => {
    onSortChange(selectedValues)
  }, [selectedValues])

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">Sort</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          value={selectedValues}
          onChange={handleChange}
        >
          {initialValues.map((sort) => (
            <MenuItem key={sort.key} value={sort.key}>
              {sort.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )

};

export const DiscoverPage: React.FC = () => {
  const history = useHistory();
 
   const navigateHandler = (productName: string) => {
    history.push("/home/marketplace/product/"+productName);
   };

   const [teamFilters, setTeamFilters] = useState<ITeamFilter[]>([]);
   const [apiTypes, setApiTypes] = useState<string[]>([]);

  // Fetches the initial data from Backend for Filters
  useEffect(() => {
    ProductService.getProductFilters().toPromise().then(
      (output) => {
        setTeamFilters(output.data.teamFilters);
        setApiTypes(output.data.apiTypes);
      },
      (error) => {
        setTeamFilters([]);
        setApiTypes([])
      }
    );
  }, []);

  return (
     <main>
       <Row margin="2">
       <Column margin="b3">
         <div>
           <FeaturedProducts onProductClick={navigateHandler} />
         </div>
       </Column>
       <Divider />
       <Column margin="y3">
         <GlobalProducts onProductClick={navigateHandler} apiTypesFromBackend={apiTypes} teamFiltersFromBackend={teamFilters}/>
       </Column>
       </Row>
     </main>
   );
 };