import { Button } from "@material-ui/core";
import React from "react";
import { useHistory, useParams } from "react-router";

export const SubscriptionNavigator = () => {

    const history = useHistory();
    const { productId } = useParams<any>();

    const openSubscriptionPage = () => {
        history.push(`/home/consumer/products/${productId}/subscribe`);
    }
    return (
        <Button variant="contained" color="primary" style={{textTransform: 'none'}} onClick={openSubscriptionPage}>Subscribe</Button>
    )
}