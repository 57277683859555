import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from "../../../store/actions/actions.tsx";
import ProfileService, { UserProfileAPI } from "../../../services/profileService";
import UserTokenViewer from './profile-forms/UserTokenViewer';
import { Button } from "@material-ui/core";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

/**
 * @class Profile
 * @description component contains User profile details and photo uploading functionality
 */
class Profile extends React.PureComponent {

	/**
	 * @function constructor
	 * @param {*} props - Having properties passed by parent element
	 *  * used for state initialization
	 */
     constructor(props) {
          super(props);
          this.uploader = React.createRef();
          this.state = {
               breadcrumb: { 'title': 'My Profile', 'link': '/home/profile' },
               message: null,
               error: false
          };

     }

     getImagefromUser = () => {
          this.uploader.click();
     }

     onUserImagNotFound = () => {
          this.setState({ 'photo': '/assets/images/user-default.png' });
     }

     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          let photo;
          if (this.props.user) {
               if (this.props.user.photo) {
                    photo = (<img src={this.props.user.photo} onError={this.onUserImagNotFound} className="photo" alt="user" />);
               } else {
                    photo = (<img src="/assets/images/user-default.png" className="photo" alt="user" />);
               }
          }
          let message;
          if (this.state.message) {
               message = (<div className={"message " + (this.state.error ? "error" : "info")}>{this.state.message}</div>);
          }
          return (
               <div className="profile">
                    <div className="user">
                         <div className="photo-block">

                              {photo}
                              <div className="photo-edit" onClick={this.getImagefromUser}>
                                   <span className="icon icon-image"></span>
                                   <input type="file" id="photo-receiver" ref={input => { this.uploader = input; }} onChange={this.uploadPhoto} />
                              </div>
                         </div>
                         <div className="name">{this.props.user.firstName} {this.props.user.lastName}</div>
                         {message}
                         <div className="user__actions">
                              <UserTokenViewer />
                         </div> <div className="user__actions">
                              <Button startIcon={<PermIdentityIcon />} href={process.env.REACT_APP_USER_PROFILE_UI_URL + '?access_token=' + this.props.accessToken} variant="outlined" color="primary"
                                   target="_blank" rel="noopener noreferrer">
                                   My Profile
                              </Button>
                         </div>
                    </div>




               </div>
          );
     }
     uploadPhoto = (event) => {
          let apiCall = UserProfileAPI.get_my_photo;
          let request = {
               userId: this.props.user.id
          };

          let payload = new FormData();
          payload.append('file', event.target.files[0]);
          payload.append('imageType', 'avatar');

          this.setState({ message: "Uploading...", error: false });
          ProfileService.put(
               UserProfileAPI.parseRequestURL(apiCall, request),
               payload
          ).then(
               (response) => {
                    this.props.dispatch(
                         {
                              type: actions.storeUserDetails,
                              payload: {
                                   photo: response.data
                              }
                         }
                    );
                    this.setState({ message: null });
               }
          ).catch(
               (error) => {
                    this.setState({ isApiLoaded: true, message: error.response.data.message, error: true });
               }
          );
     }
}

/**
 * For mapping necessary redux state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state) => {
     return {
          user: state.userManager,
          accessToken: state.auth?.user?.access_token,
          team: state.teamManager
     }
};
export default withRouter(connect(mapStateToProps)(Profile));