import React, { Ref, useCallback, useImperativeHandle, FC, PropsWithRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, makeStyles, createStyles, Typography } from '@material-ui/core';
import { useCompactDialog } from '../../../../legacy-components/dialog/UseCompactDialog';
import { IdentityApplicationEnvironmentType } from './PublisherApplicationService';
import { ApplicationDeleteSecretFormData } from './models/application.model';
import { FormattedMessage } from 'react-intl';
import ApplicationDeleteSecretForm from './application-form/ApplicationDeleteSecretForm';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(({ spacing }) => {
    return createStyles({
        header: {
            position: "relative"
        },
        typeText: {
            padding: spacing(3, 0, 2, 0)
        },
        secondaryText: {
            padding: spacing(1, 0, 1, 0)
        },
        warning: {
            margin: spacing(0, 0, 2, 0)
        }
    })
});

export interface IdentityApplicationSecretDeleteDialogRef {
    open(): void
}

interface IdentityApplicationSecretDeleteDialogProps {
    ref?: Ref<IdentityApplicationSecretDeleteDialogRef | undefined>,
    applicationName: string;
    env: IdentityApplicationEnvironmentType,
    onSubmit: (data: ApplicationDeleteSecretFormData) => void
    secretId: string;
}

interface ApplicationDeleteSecretFormViewerProps {
    applicationName: string;
    env: IdentityApplicationEnvironmentType;
    onCancel: () => void;
    onSubmit: (data: ApplicationDeleteSecretFormData) => void;
    secretId: string;
}

const ApplicationDeleteSecretFormContent = ({ applicationName }: {
    applicationName: string
}) => {
    const styles = useStyles();
    const applicationNameFormatter = () => <strong>{applicationName}</strong>;
    const formatStrong = (value: string) => <strong>{value}</strong>;

    return (
        <>
            <Alert severity="warning" className={styles.warning}>
                <Typography variant="subtitle2" component="p">
                    <FormattedMessage defaultMessage="Deleting a secret key will break any current integrations using this key." />
                </Typography>
            </Alert>

            <Typography component="p" className={styles.secondaryText}>
                <FormattedMessage values={{
                    applicationName: applicationNameFormatter
                }} defaultMessage='This action cannot be undone. This will permanently remove the secret for the application "<applicationName>name</applicationName>" and cannot be accessed elsewhere.' />
            </Typography>

            <Typography component="p" className={styles.secondaryText}>
                <FormattedMessage defaultMessage="Please note that removing this Client Secret will not invalidate any user tokens, however you will need to update any application configuration using this secret because it will no longer work" />
            </Typography>

            <Typography className={styles.typeText} variant="body2" component="p">
                <FormattedMessage values={{
                    formatStrong: formatStrong
                }} defaultMessage="Type <formatStrong>last 5 characters</formatStrong> of the secret to confirm." />
            </Typography>
        </>
    )
}

const ApplicationDeleteSecretFormViewer = ({ applicationName, env, onSubmit, onCancel, secretId }: ApplicationDeleteSecretFormViewerProps) => {

    return (
        <ApplicationDeleteSecretForm secretId={secretId} applicationName={applicationName} formContent={<ApplicationDeleteSecretFormContent applicationName={applicationName} />} env={env} onCancel={onCancel} onSuccess={onSubmit} />
    )
}

const IdentityApplicationSecretDeleteDialog: FC<PropsWithRef<IdentityApplicationSecretDeleteDialogProps>> = forwardRef(({ applicationName, env, onSubmit, secretId }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleSubmit = useCallback((data: ApplicationDeleteSecretFormData) => {
        setOpen(false);
        onSubmit(data);
    }, [onSubmit, setOpen]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >

            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Delete secret key" />
                </DialogTitle>
            </div>
            <ApplicationDeleteSecretFormViewer secretId={secretId} applicationName={applicationName} env={env} onCancel={handleClose} onSubmit={handleSubmit} />
        </Dialog>
    );
});

IdentityApplicationSecretDeleteDialog.propTypes = {
    applicationName: PropTypes.string.isRequired,
    env: PropTypes.oneOf<IdentityApplicationEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired
}

export default IdentityApplicationSecretDeleteDialog

