import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { TrimbleColors } from "legacy-components/TrimbleColors";

const useBadgeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: ".125rem",
      fontWeight: 700,
      textTransform: "none",
      lineHeight: "1",
      display: "inline-flex",
    },
    badge: {
      fontSize: ".75rem",
      padding: "calc((20px - .75rem)/ 2) .5rem",
      backgroundColor: TrimbleColors.Brand.BlueMid,
      color: TrimbleColors.White,

      "&__counter": {
        paddingRight: ".6em",
        paddingLeft: ".6em",
        borderRadius: "10rem",
      },

      "&__small": {
        fontSize: ".625rem",
        padding: "calc((14px - .625rem)/ 2) .25rem",
      },

      "&__large": {
        fontSize: "1rem",
        padding: "calc((28px - 1rem)/ 2) .75rem",
      },

      "&__secondary": {
        backgroundColor: TrimbleColors.Gray8,
      },

      "&__tertiary": {
        backgroundColor: TrimbleColors.Gray2,
        color: TrimbleColors.GrayDark3,
      },

      "&__dark": {
        backgroundColor: TrimbleColors.Brand.Gray,
      },

      "&__success": {
        backgroundColor: TrimbleColors.GreenDark,
      },

      "&__warning": {
        backgroundColor: TrimbleColors.YellowDark,
        color: TrimbleColors.Brand.Gray,
      },

      "&__danger": {
        backgroundColor: TrimbleColors.Red,
      },

      "&__text--primary": {
        backgroundColor: "transparent",
        color: TrimbleColors.Brand.BlueMid,
      },

      "&__text--secondary": {
        backgroundColor: "transparent",
        color: TrimbleColors.Gray8,
      },

      "&__text--tertiary": {
        backgroundColor: "transparent",
        color: TrimbleColors.Gray2,
      },

      "&__text--dark": {
        backgroundColor: "transparent",
        color: TrimbleColors.Brand.Gray,
      },

      "&__text--success": {
        backgroundColor: "transparent",
        color: TrimbleColors.GreenDark,
      },

      "&__text--warning": {
        backgroundColor: "transparent",
        color: TrimbleColors.YellowDark,
      },

      "&__text--danger": {
        backgroundColor: "transparent",
        color: TrimbleColors.Red,
      },
    },
  })
);

export default useBadgeStyles;
