import React, { Fragment } from "react";
import Typography from "../Typography";

import useGenericPageTitleStyle from "./GenericPageTitle.style";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import { Row, Column } from "legacy-components/responsive-grid/ResponsiveGrid";
import { StatusBadge, StatusBadgeInfo } from "legacy-components/badges/StatusBadge";
import { IProductPublishedInfo } from "pages/home/consumer/service/ProductService";
import Lock from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Tooltip } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

/* eslint-disable-next-line */
export interface GenericPageTitleProps {
  title?: JSX.Element | string; // Page Title
  subTitle?: JSX.Element | string; // Page Sub Title
  controls?: JSX.Element | null; // Side Controls or Action Elements
  async?: boolean;
  className?: string;
  badges?: IProductPublishedInfo[] | null;
}

export const GenericPageTitle: React.FC<GenericPageTitleProps> = ({
  title,
  subTitle,
  controls,
  async = false,
  className,
  badges
}) => {
  const classes = useGenericPageTitleStyle();
  return (
    <Row className={clsx(classes.header, className)}>
      <Column xl={9} md={8}>
        <Row>
          {/* Display Title or loader if async enabled */}
          <Column margin="b1">
            {title && typeof title === "string" && (
              <Typography type="h1">{title}</Typography>
            )}
            {title && typeof title !== "string" && { title }}
            {!title && async && <Skeleton animation="wave" width={"20%"} />}
          </Column>
          <Column margin="b1">
          {badges &&  <StatusBadge align="right" status={createStatusBadge(badges)} />}
          </Column>
          {/* Display Sub Title or loader if async enabled */}
          <Column>
            {subTitle && typeof subTitle === "string" && (
              <Typography type="h4">{subTitle}</Typography>
            )}
            {subTitle && typeof subTitle !== "string" && { subTitle }}
            {!title && async && <Skeleton animation="wave" width={"30%"} />}
          </Column>
        </Row>
      </Column>
      <Column xl={3} md={4}>
        {title && controls && <Row align="end">{controls}</Row>}
        {!title && async && (
          <Row>
            <Column margin="r0">
              <Skeleton
                animation="wave"
                width={100}
                height={40}
                className={classes.controlSkeleton}
              />
            </Column>
          </Row>
        )}
      </Column>
    </Row>
  );
};

const createStatusBadge = (badges: IProductPublishedInfo[] | null | undefined): StatusBadgeInfo[] => {

  var resultBadges : StatusBadgeInfo[] = [];

  badges && badges.map((info) =>{ 
    resultBadges.push({
      kind: "access",
      key: info.gateway.toLowerCase(),
      icon: (<>
      {info.approvalType === "AUTO" ?
      <>
      <Fragment>&nbsp;</Fragment>
      <Tooltip title={<FormattedMessage defaultMessage="OPEN" />}>
       <VisibilityIcon fontSize="inherit" />
       </Tooltip>
      </>
       :
       <>
       <Fragment>&nbsp;</Fragment>
       <Tooltip title={<FormattedMessage defaultMessage="CLOSED" />}>
       <VisibilityOffIcon fontSize="inherit"/>
       </Tooltip>
       </>
    }
      
    </>)
         
    })
  });

  return resultBadges;
}

export default GenericPageTitle;
