import { Box, Paper, Typography } from "@material-ui/core";
import { IdentityApplication } from "libs/resources/identity/applications/PublisherApplicationService";
import React from "react";
import { useSelector } from "react-redux";
import { ConsumerKey } from "./ConsumerKeyViewer";
import { ConsumerSecretList } from "./ConsumerSecretListViewer";
import { ErrorMessage } from "modus/components/error-message/ErrorMessage";

interface AppConfigurationProps {
  application: IdentityApplication;
}


/**
 * Renders the configuration details of an application.
 *
 * @param {Object} props - The component props.
 * @param {IdentityApplication} props.application - The application object.
 * @returns {JSX.Element} - The rendered component.
 * @description  this component dynamically generates a list of configuration details 
 * based on the application's responses and the current identity environment, 
 * displaying them in a structured and styled manner.
 */
export const AppConfiguration: React.FC<AppConfigurationProps> = ({
  application,
}) => {
  const identityEnvironment = useSelector(
    (state: any) => state?.common?.identityEnvironment
  );

  return (
    <Paper style={{marginTop: "30px"}}>
      <Box p={"20px"}>
        <Typography variant="body1" className={"app-configuration"}>
          Configurations
        </Typography>
        <Box>
          {application?.applicationGatewayResponses
            ?.filter(
              (response) => response.organisationName === identityEnvironment
            )
            ?.map((response, index) => (
              <Box key={response.consumerKey}>
                <ConsumerKey
                  clientId={response.consumerKey ?? application?.applicationId}
                />
                <ConsumerSecretList appName={application?.applicationName}/>
              </Box>
            ))}
            {
              (!application?.applicationGatewayResponses || application?.applicationGatewayResponses?.length === 0) && (
                <ErrorMessage message={"No configurations found"} />
              )
            }
        </Box>
      </Box>
    </Paper>
  );
};

