
import * as actions from "../actions/actions.tsx";
import { updateObject } from "../../utils";

/**
 *  Mapping set of Reducers for each Authentication Actions
 * @param {Object} action - Action from action creator
 * @param {Object} state - Default application state
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action = {}) => {
     switch (action.type) {
          case actions.storeEnvironments: return storeEnvironments(state, action);
          default: return state;
     }
};



/**
 * To Update Breadcrumb value on change of links
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const storeEnvironments = (state, action) => {
     return updateObject({
          environments: action.payload
     });
}

const INITIAL_STATE = {
     proxy: null
};

