import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ProductSubscriptionList } from './pages/product-subscription-list/ProductSubscriptionList';
import SubscriptionSummaryPage from './pages/subscription-summary-page/SubscriptionSummaryPage';
import { GenericPageHeaderSkeleton } from 'legacy-components/page-headers/GenericPageHeader';
import { ThemeProvider } from '@material-ui/core';
import { theme } from 'themes/mui';

const fallBackdiv = <div className="message info">You do not have permission to access the page. Please contact your Team owner.</div>
/**
 * @class HomeRoutes
 * @description For storing internal page redirection inside Home Module
 */
class SubscriptionRoutes extends React.Component<any, any> {

     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          return (
               <ThemeProvider theme={theme}>
               <Suspense fallback={<GenericPageHeaderSkeleton />}>
               <Switch>
                    <Route path="/home/subscriptions" exact render={() => (<ProductSubscriptionList />)} />
                    <Route path="/home/subscriptions/:subscriptionId" render={() => (<SubscriptionSummaryPage />)} />
               </Switch>
               </Suspense>
               </ThemeProvider>
          );
     }
}

export default withRouter(SubscriptionRoutes);