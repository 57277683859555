import React from "react";
import type { ReactElement } from "react";
import type { PropsWithChildren } from "react";

import "./Table.scss";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import type { AgGridReactProps, AgGridColumnProps } from "ag-grid-react";
import { Box, Link as MuiLink, Paper } from "@material-ui/core";
import { notBlank } from "legacy-components/Helper";
import Typography from "legacy-components/typography/Typography";
import Loader from "legacy-components/loader";

export interface TableProps extends AgGridReactProps {
  height?: string | number;
  width?: string | number;
  noRowsText?: string | ReactElement;
}

export const TableColumn: React.FC<PropsWithChildren<AgGridColumnProps>> = ({
  children,
  ...options
}) => {
  return <AgGridColumn flex={1} {...options} />;
};

export const filterTableData = (list: Array<unknown> | null | undefined) =>
  list && notBlank(list) ? list : undefined;

export const Table: React.FC<PropsWithChildren<TableProps>> = ({
  width = "100%",
  height = undefined,
  noRowsText = "No Entires available",
  children,
  pagination = true,
  ...options
}) => {
  const NoRows = () => {
    return <Typography type="body">{noRowsText}</Typography>;
  };

  const Title: React.FC<{ valueFormatted: string; value: string }> = ({
    valueFormatted,
    value,
  }) => {
    return (
      <span className="grid-title">
        <Typography type="body" color="primary">
          {valueFormatted ?? value}
        </Typography>
      </span>
    );
  };

  const Link: React.FC<{ valueFormatted: string; value: string }> = ({
    valueFormatted,
    value,
  }) => {
    return (
      <Box alignContent={"center"} height={"100%"}>
        <MuiLink className="grid-link" target="_blank" href={valueFormatted?? value}>
                <Typography type="body" color="primary">
                  {valueFormatted ?? value}
                </Typography>
              </MuiLink>
      </Box>
      
    );
  };

  const Email: React.FC<{ valueFormatted: string; value: string }> = ({
    valueFormatted,
    value,
  }) => {
    return (
      <a href={`mailto:${valueFormatted ?? value}`} className="grid-title">
        <Typography type="body" color="primary" transform="none">
          {valueFormatted ?? value}
        </Typography>
      </a>
    );
  };

  return (
    <Paper className="table">
      <section className="ag-theme-alpine" style={{ height:height, width }}>
        <AgGridReact
          
          {...options}
          frameworkComponents={{
            LoadingOverlay: Loader,
            NoRowsOverlay: NoRows,
            Title,
            Email,
            Link,
            ...options.frameworkComponents,
          }}
          loadingOverlayComponent="LoadingOverlay"
          noRowsOverlayComponent="NoRowsOverlay"
          domLayout={!height? "autoHeight": undefined}
          pagination={pagination}
          paginationPageSize = {10}

          
        >
          {children}
        </AgGridReact>
      </section>
    </Paper>
  );
};

export default Table;
