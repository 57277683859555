import React from "react";
import { Link } from "@material-ui/core";
import { Column, Row } from "legacy-components/responsive-grid/ResponsiveGrid";
import Typography from "legacy-components/typography/Typography";
import { TeamDetail } from "pages/home/team/models/team.model";
export interface SupportInfoProps {
  teamDetail: TeamDetail | null | undefined ;
}

export const SupportInfo: React.FC<SupportInfoProps> = ({ teamDetail }) => (
  <Column margin="y1">
    <Row margin="b1">
      <Typography type="h5">Support</Typography>
    </Row>
    {(teamDetail?.support) && (teamDetail?.email) && (teamDetail?.website) ? (
      <>
      {/* <Typography type="h6">Product Support</Typography> */}
      <Typography type="body">
        For more information about this product, contact Product team by visting
        <Link href={teamDetail.website}> Product site </Link>
         or Email to
        <Link href={`mailto:${teamDetail.email}`}>
           {" " + teamDetail.email}
        </Link>.
      </Typography>
      <br />
      </>
    )
      :
    (<Typography type="body">The publisher is yet to update the contact information.</Typography> )}









    <br></br>
    <Typography type="body" color="error">
      <b>For Trimble internal use only</b>
    </Typography>
  </Column>
);
