import { ExportToCsv } from "export-to-csv";
import { IAPIProductSubscription } from "libs/resources/subscription/SubscriptionService";

export function ExportAsCSV(data: IAPIProductSubscription[] | null, csvFileName: string) {
    if (data && data?.length > 0) {
        data.forEach((subscription) => {
            delete subscription.subscriptionId
            delete subscription.subscriptionRequestedTime
        })
        const csvConfigurations = {
            filename: csvFileName,
            fieldSeparator: ',',
            decimalSeparator: '.',
            quoteStrings: '',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
    
        };
    
        const csvExporter = new ExportToCsv(csvConfigurations);
        
        csvExporter.generateCsv(data);
    }   

}