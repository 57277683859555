import React, { Ref, FC, PropsWithRef, forwardRef, useCallback, useImperativeHandle, memo } from "react";
import { Dialog, DialogTitle, makeStyles, createStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import ProductDocumentDeleteForm from "./ProductDocumentDeleteForm";
import { ProductDocumentFieldFileInfo } from "./ProductDocumentField";
import { useCompactDialog } from "../../../../../legacy-components/dialog/UseCompactDialog";

const useStyles = makeStyles(() => {
    return createStyles({
        header: {
            position: "relative"
        }
    })
});

export interface ProductDocumentFormConfirmDialogRef {
    open(): void
}

interface ProductDocumentFormConfirmDialogProps {
    ref?: Ref<ProductDocumentFormConfirmDialogRef | undefined>
    onSuccess: () => void;
    docInfo: ProductDocumentFieldFileInfo;
    productName: string;
}

interface ProductDocumentFormViewerProps {
    docInfo: ProductDocumentFieldFileInfo;
    productName: string;
    onCancel: () => void;
    onSubmit: () => void
}

const ProductDocumentDeleteFormContent = ({ docName }: {
    docName: string
}) => {

    const docNameFormatter = () => <strong>{docName}</strong>;

    return (
        <Typography variant="body1" component="p">
            <FormattedMessage values={{
                docName: docNameFormatter
            }} defaultMessage="This will permanently delete the <docName>name</docName> document" />
        </Typography>
    )
}


const ProductDocumentFormViewer = ({ docInfo, onSubmit, onCancel, productName }: ProductDocumentFormViewerProps) => {

    return (
        <ProductDocumentDeleteForm productName={productName} formContent={<ProductDocumentDeleteFormContent docName={docInfo.fileInfo.filename} />} docInfo={docInfo} onCancel={onCancel} onSuccess={onSubmit} />
    )
}

const ProductDocumentFormConfirmDialog: FC<PropsWithRef<ProductDocumentFormConfirmDialogProps>> = forwardRef(({ onSuccess, docInfo, productName }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleSubmit = useCallback(() => {
        setOpen(false);
        onSuccess();
    }, [onSuccess, setOpen]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >

            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Delete this document?" />
                </DialogTitle>
            </div>
            <ProductDocumentFormViewer productName={productName} onCancel={handleClose} onSubmit={handleSubmit} docInfo={docInfo} />
        </Dialog>
    );
});

ProductDocumentFormConfirmDialog.propTypes = {

}

export default memo(ProductDocumentFormConfirmDialog);