
import * as actions from "../actions/actions.tsx";
import { updateObject } from "../../utils";

/**
 * @class ProxyManager
 * @type reducer
 * @description Mapping set of Reducers for each Proxy specific Actions
 * @param {Object} action - Action from action creator
 * @param {Object} state - Default application state
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action = {}) => {
     switch (action.type) {
          case actions.storeProxy: return storeProxy(state, action);
          case actions.storeProxyThrottlingConfig: return storeProxyThrottlingConfig(state, action);
          default: return state;
     }
};



/**
 * @function storeProxy
 * @description To store API Summary proxy details
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const storeProxy = (state, action) => {
     return updateObject({
          proxy: action.payload
     });
}

const storeProxyThrottlingConfig = (state, action) => {
     return updateObject(state, { 
          proxyThrottlingConfig: action.payload
     });
}

const INITIAL_STATE = {
     proxy: null,
     proxyThrottlingConfig: null
};

