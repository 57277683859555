import React, { PropsWithChildren, Ref, memo, ReactElement, FC, useCallback } from "react";
import { Typography, makeStyles, createStyles, List, Paper, Divider } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import ProductDocumentField, { ProductDocumentFieldRef, ProductDocumentFieldFileInfo } from "./ProductDocumentField";
import NAWrap from "../../../../../legacy-components/form/action/NAWrap";
import ProductDocumentForm from "./ProductDocumentForm";
import { ProductDocumentationInfoDispatcher } from "../ProductPublishInfo";
import { ProductDocumentPublishedData } from "../../models/products.model";
import OpenInNewTabLink from "../../../../../legacy-components/form/action/OpenInNewTabLink";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme =>
    createStyles({
        documentInfo: {
            padding: theme.spacing(1, 2, 0, 2),
            textAlign: "center"
        },
        documentField: {
            padding: theme.spacing(2, 0)
        },
    }),
);


interface ProductDocumentationInfoProps {
    noDocMessage?: ReactElement;
    docInfo: ProductDocumentFieldFileInfo[];
    docFieldRef?: Ref<ProductDocumentFieldRef | undefined>;
    productName?: string;
    dispatcher?: ProductDocumentationInfoDispatcher
}

const SingleDocView = memo(({ docInfo, noDocMessage, docFieldRef }: {
    noDocMessage?: ReactElement;
    docInfo: ProductDocumentFieldFileInfo[];
    docFieldRef: Ref<ProductDocumentFieldRef | undefined>;
}) => {
    return (
        <NAWrap value={docInfo[0]?.fileInfo} fallback={<Typography variant="body2">
            {noDocMessage ?? <FormattedMessage defaultMessage="No documentation added to the product" />}
        </Typography>}>
            <ProductDocumentField addWrap={true} ref={docFieldRef} docInfo={docInfo[0]} />
        </NAWrap>
    )
});

const MultiDocViewItem: FC<{
    docInfo: ProductDocumentFieldFileInfo,
    showDivider: boolean,
    productName: string,
    dispatcher?: ProductDocumentationInfoDispatcher
}> = memo(({ docInfo, showDivider, productName, dispatcher }) => {
    const onDelete = useCallback(() => {
        dispatcher?.({
            type: "remove",
            payload: {
                id: docInfo.id
            }
        })
    }, [docInfo, dispatcher]);

    const onCreate = useCallback((data: ProductDocumentFieldFileInfo) => {
        dispatcher?.({
            type: "update",
            payload: {
                id: docInfo.id,
                value: data
            }
        })
    }, [docInfo, dispatcher]);

    const onChange = useCallback((data: ProductDocumentPublishedData) => {
        dispatcher?.({
            type: "updatePublished",
            payload: {
                id: docInfo.id,
                value: data
            }
        })
    }, [docInfo, dispatcher]);

    return (
        <>
            {showDivider && <Divider variant="inset" component="li" />}
            <ProductDocumentForm onChange={onChange} onCreate={onCreate} productName={productName} onDelete={onDelete} docInfo={docInfo} />
        </>
    )
});

const MultiDocView = memo(({ docInfo, noDocMessage, productName, dispatcher }: {
    noDocMessage?: ReactElement;
    docInfo: ProductDocumentFieldFileInfo[];
    productName: string;
    dispatcher?: ProductDocumentationInfoDispatcher
}) => {
    return (
        <NAWrap value={docInfo} fallback={<Typography variant="body2">
            {noDocMessage ?? <FormattedMessage defaultMessage="No documentations added to the product" />}
        </Typography>}>
            <Paper variant="outlined">
                <List dense>
                    {
                        docInfo.map((value, index) => {
                            return (
                                <MultiDocViewItem productName={productName} key={index} showDivider={index !== 0} docInfo={value} dispatcher={dispatcher} />
                            )
                        })
                    }
                </List>
            </Paper>
        </NAWrap>
    )
});

const ProductDocumentationInfo = ({ docFieldRef, children, docInfo, noDocMessage, productName, dispatcher }: PropsWithChildren<ProductDocumentationInfoProps>) => {
    const styles = useStyles();

    const openAPILinkFormatter = (chunks: string) => {
        return (
            <OpenInNewTabLink href="https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md">
                {chunks}
            </OpenInNewTabLink>
        );
    }

    return (
        <div className={styles.documentInfo}>
            <Alert severity="info">Exactly one document can be displayed to consumers on each environment.</Alert>
            <br/>
            {docFieldRef ? <SingleDocView docInfo={docInfo} docFieldRef={docFieldRef} noDocMessage={noDocMessage} /> : (productName && <MultiDocView productName={productName} docInfo={docInfo} noDocMessage={noDocMessage} dispatcher={dispatcher} />)}

            <div className={styles.documentField}>
                {children}
            </div>

            <div>
                <Typography variant="caption">
                    <FormattedMessage values={{
                        openAPILink: openAPILinkFormatter
                    }} defaultMessage="Accepts <openAPILink>OpenAPI Specs</openAPILink> only. Documentation is generated from a static snapshot of the selected document." />
                </Typography>
            </div>
        </div>
    )
}

export default memo(ProductDocumentationInfo);