import React, { memo } from "react";
import { SvgIconProps, SvgIcon, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing, palette, typography }) =>
    createStyles({
        privacyIcon : {
            width: '1rem',
            height: 'auto'
        }
    })
);

function PrivacyIcon(props: SvgIconProps) {

    const classes = useStyles();

    return (
        
        <SvgIcon {...props} viewBox="0 0 18 22" fill="none"  className={classes.privacyIcon}>            
            <path d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0Z" fill="#A51D25"/>
            <path d="M11.6667 8.33333H11.1905V7.38095C11.1905 6.06667 10.1238 5 8.80952 5C7.49524 5 6.42857 6.06667 6.42857 7.38095V8.33333H5.95238C5.42857 8.33333 5 8.7619 5 9.28571V14.0476C5 14.5714 5.42857 15 5.95238 15H11.6667C12.1905 15 12.619 14.5714 12.619 14.0476V9.28571C12.619 8.7619 12.1905 8.33333 11.6667 8.33333ZM8.80952 12.619C8.28571 12.619 7.85714 12.1905 7.85714 11.6667C7.85714 11.1429 8.28571 10.7143 8.80952 10.7143C9.33333 10.7143 9.76191 11.1429 9.76191 11.6667C9.76191 12.1905 9.33333 12.619 8.80952 12.619ZM10.2857 8.33333H7.33333V7.38095C7.33333 6.56667 7.99524 5.90476 8.80952 5.90476C9.62381 5.90476 10.2857 6.56667 10.2857 7.38095V8.33333Z" fill="white"/>
        </SvgIcon>
        
    );
}

export default memo(PrivacyIcon);