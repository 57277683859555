import { IdentityApplication, IdentityApplicationPublishedGateway } from "../PublisherApplicationService";
import React, { FC, memo, useCallback, useRef, useContext, useMemo } from "react";
import { AccordionContent } from "../../../../../legacy-components/form/components/accordion-form/AccordionForm";
import FormViewInliner from "../../../../../legacy-components/form/container/FormViewInliner";
import { FormattedMessage } from "react-intl";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import NAWrap from "../../../../../legacy-components/form/action/NAWrap";
import PropTypes from "prop-types";
import { IdentityApplicationEnvironmentType } from '../PublisherApplicationService';
import { CopyIdentityApplicationValues, IdentityApplicationCredentialsView } from "./IdentityApplicationInfo";
import { ApplicationDetailTypeView } from "../application-form/ApplicationConfigurationInfo";
import { ResourceAbility } from "libs/security/authorization";
import EditIcon from '@material-ui/icons/Edit';
import ApplicationSummaryDialog, { ApplicationSummaryDialogRef } from "../application-form/summary/ApplicationSummaryDialog";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import { ApplicationSummaryFormData } from "../models/application.model";
import { IdentityApplicationViewContext } from "../IdentityApplicationView";
import { sanitize } from "dompurify";

interface IdentityApplicationDetailsViewProps {
    application: IdentityApplication
    publishedInfo?: IdentityApplicationPublishedGateway;
}

const HeaderControls: FC<{
    onEdit: () => void,
    env: IdentityApplicationEnvironmentType
}> = ({ onEdit, env }) => {
    const handleEdit = useCallback(() => onEdit(), [onEdit]);

    return (
        <ResourceAbility can="update" resource={({ Application }) => [
            Application.create.environment[env]]}  >
            <Tooltip title={<FormattedMessage defaultMessage="Edit details" />}>
                <span>
                    <IconButton size="small" edge="end" onClick={handleEdit}>
                        <EditIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </ResourceAbility>
    )
}

const NotificationMessage = () => {
    return (
        <FormattedMessage defaultMessage="Application Updated" />
    )
}

function IdentityApplicationDetailsView({ application, publishedInfo }: IdentityApplicationDetailsViewProps) {
    const applicationViewContext = useContext(IdentityApplicationViewContext);
    const notificationContext = useContext(SnackNotificationContext);
    const dialogRef = useRef<ApplicationSummaryDialogRef>();

    const applicationInfo = useMemo(() => {
        return applicationViewContext.state?.application ?? application
    }, [applicationViewContext, application])

    const onSuccess = useCallback((data: ApplicationSummaryFormData) => {
        if (data?.displayName) {
            notificationContext.show({
                id: "application-summary-updated",
                message: <NotificationMessage />
            });

            applicationViewContext.dispatch({
                type: "applicationUpdate",
                payload: {
                    application: {
                        ...applicationViewContext.state?.application ?? application,
                        ...data
                    }
                }
            })
        }

    }, [notificationContext, applicationViewContext, application]);

    const onEdit = useCallback(() => dialogRef?.current?.open({
        description: sanitize(applicationInfo.description?? ""),
        displayName: sanitize(applicationInfo.displayName??"")
    }), [dialogRef, applicationInfo]);

    return (
        <>
            <ApplicationSummaryDialog onSubmit={onSuccess} applicationName={applicationInfo.applicationName} env={application.organisationName} ref={dialogRef} />

            <AccordionContent size="small" header={<FormattedMessage defaultMessage="Summary" />} controls={<HeaderControls env={applicationInfo.organisationName} onEdit={onEdit} />}>
                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Name" />} controls={<CopyIdentityApplicationValues value={applicationInfo.applicationName} />}>
                    <Typography variant="body1">
                        <NAWrap value={applicationInfo.applicationName} showMessage={true}>
                            {applicationInfo.applicationName}
                        </NAWrap>
                    </Typography>
                </FormViewInliner>

                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Type" />}>
                    <Typography variant="body1">
                        <NAWrap value={applicationInfo.applicationType?.name} showMessage={true}>
                            <ApplicationDetailTypeView applicationType={applicationInfo.applicationType?.name} />
                        </NAWrap>
                    </Typography>
                </FormViewInliner>

                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Display Name" />}>
                    <Typography variant="body1">
                        <NAWrap value={applicationInfo.displayName} showMessage={true}>
                            {applicationInfo.displayName}
                        </NAWrap>
                    </Typography>
                </FormViewInliner>

                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Description" />}>
                    <Typography variant="body1">
                        <NAWrap value={applicationInfo.description} showMessage={true}>
                            {sanitize(applicationInfo.description??"")}
                        </NAWrap>
                    </Typography>
                </FormViewInliner>

                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Created By" />}>
                    <Typography variant="body1">
                        <NAWrap value={applicationInfo.createdBy} showMessage={true}>
                            {applicationInfo.createdBy}
                        </NAWrap>
                    </Typography>
                </FormViewInliner>


            </AccordionContent>

            {publishedInfo && (
                <AccordionContent>
                    <IdentityApplicationCredentialsView allowModify={true} applicationName={applicationInfo.applicationName} publishedGateway={publishedInfo} />
                </AccordionContent>)}
        </>
    )
}

IdentityApplicationDetailsView.propTypes = {
    application: PropTypes.shape({
        applicationId: PropTypes.string.isRequired,
        applicationName: PropTypes.string.isRequired,
        organisationName: PropTypes.oneOf<IdentityApplicationEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired
    }).isRequired,
    publishedInfo: PropTypes.shape({
        organisationName: PropTypes.oneOf<IdentityApplicationEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired
    }).isRequired,
}

export default memo(IdentityApplicationDetailsView);

