import ApplicationService from "../../../../libs/resources/identity/applications/ApplicationService";
import { useEffect } from "react";
import AlertService from "legacy-components/alert/AlertService";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";

export const useResourceAppVersionDeploymentCreator = (resourceAppId: string, version: string, targetUrl: string, region: string, timeout: number) => {
    
    const {state,  subscribe } = useAsyncAction(() => ApplicationService.createVersionDeployment(resourceAppId, version, targetUrl, region, timeout
    ));

    const createVersionDeployment = () => {
        subscribe();
    }

    useEffect(() => {
        if(state?.error)
        {
            AlertService.error({
                message: state?.error?.data?.message,
                id: "CREATEDEPLOYMENT",
              });
        }

    },[state?.error])

    return {createDeploymentstate: state, createVersionDeployment}
}