import { Badge } from "@material-ui/core"
import KVMViewer from "modus/components/misc/KVMViewer"
import React, {  } from "react"
import { useSelector } from "react-redux"
import { IDENTITY_ENVIRONMENT_TYPE } from "header/environment-selector/ENV_CONSTANTS"
import { DeploymentStatusBadge, DeploymentStatusKeyType } from "./DeploymentStatusBadge"
import { EnvBadge } from "./EnvBadge"

interface DeploymentDetailsProps {
    region?: string;
    targetEndpoint:string;
    deployedEndpoint: string;
    timeout?: string;
    deploymentStatus: string;
}
export const DeploymentDetails:React.FC<DeploymentDetailsProps> = (props) => {

    const identityEnvironment = useSelector(
        (state: any) => state?.common?.identityEnvironment
      );

    return(<KVMViewer dataList={
        {
            [identityEnvironment === IDENTITY_ENVIRONMENT_TYPE.PRODUCTION ? "Region": "Environment"] : <EnvBadge env={props?.region as any} />,
            "Target Endpoint": props?.targetEndpoint,
            "Deployed Endpoint": props?.deployedEndpoint,
            "Connection Timeout": `${props?.timeout} (in seconds)`,
            "Deployment Status": <DeploymentStatusBadge status={props?.deploymentStatus as DeploymentStatusKeyType}/>

        }
    } />
    );
}