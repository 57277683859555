
import ConsumerClient from "./ConsumerClient";
import { from } from "rxjs";
import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";

export interface ISubscriptionRequest {
  applicationName: string;
  gatewayName: string;
  businessContext?: string | null;
}

@APIResource(ConsumerClient, "/products")
class SubscriptionServiceImpl {
  @APIMethod("/:productName/subscriptions")
  public createSubscription(productName:string, request: ISubscriptionRequest) {
    return from(
      ConsumerClient.post(apiMethodContext(arguments), request, {
        params: {
          productName,
        },
      })
    );
  }

  @APIMethod("/:productName/subscriptions/:subscriptionId")
  public deleteSubscription(productName:string, subscriptionId: string) {
    return from(
      ConsumerClient.delete(apiMethodContext(arguments), {
        params: {
          productName,
          subscriptionId,
        },
      })
    );
  }

}

const SubscriptionService = new SubscriptionServiceImpl();

export default SubscriptionService;
