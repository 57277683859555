import React, { useCallback, useState } from 'react';
import { Dialog, DialogTitle, makeStyles, createStyles, Divider, DialogProps } from '@material-ui/core';

const useStyles = makeStyles(() => {
    return createStyles({
        header: {
            position: "relative"
        },
        dialog: {
            overflowX: "hidden"
        }
    })
});

const CompactDialog = (props: any) => {
    const style = useStyles();
    const [maxWidth] = useState<DialogProps['maxWidth']>('sm');
    const [scroll] = useState<DialogProps['scroll']>('paper');
   
    const handleClose = useCallback(() => {
        props.onClose();
    }, [props]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={props.open as boolean}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            classes={{
                paper: style.dialog
            }}>

            <div className={style.header}>
                <DialogTitle>
                    {props.title}
                </DialogTitle>
                <Divider />
            </div>
            <div>
                {props.children}
            </div>
        </Dialog>
    );
}


export default CompactDialog;

