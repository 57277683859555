import React, { memo, useState, FC, useCallback, useRef, useContext, useEffect, MutableRefObject } from "react";
import { AccordionContent } from "../../../../../legacy-components/form/components/accordion-form/AccordionForm";
import { FormattedMessage } from "react-intl";
import { Tooltip, IconButton } from "@material-ui/core";
import PublisherApplicationService, { IdentityApplicationEnvironmentType, IdentityApplicationTypeConfig } from '../PublisherApplicationService';
import EditIcon from '@material-ui/icons/Edit';
import ApplicationOAuthConfigurationDialog, { ApplicationOAuthConfigurationDialogRef } from "../application-form/oauth-configs/ApplicationOAuthConfigurationDialog";
import { ApplicationConfigurationFormData } from "../models/application.model";
import { IdentityApplicationViewContext } from "../IdentityApplicationView";
import { SnackNotificationContext } from "../../../../../legacy-components/notifications/GenericSnackNotification";
import { ResourceAbility } from "../../../../security/authorization";
import { createIdentityApplicationPublishedStatus, IdentityApplicationOAuthView, IdentityApplicationPublishedStatus, IdentityApplicationSAMLView, IdentityApplicationScopeValidationView } from "./IdentityApplicationInfo";
import { useAsyncAction, AsyncLoader } from "../../../../../legacy-components/form/action/AsyncAction";
import { ResourceViewOuterContentSkeleton } from "../../../../../legacy-components/resource-view/ResourceView";

const HeaderControls: FC<{
    onEdit: () => void,
    env: IdentityApplicationEnvironmentType
}> = ({ onEdit, env }) => {
    const handleEdit = useCallback(() => onEdit(), [onEdit]);

    return (
        <ResourceAbility can="update" resource={({ Application }) => [
            Application.create.environment[env]]}  >
            <Tooltip title={<FormattedMessage defaultMessage="Edit configuration" />}>
                <span>
                    <IconButton size="small" edge="end" onClick={handleEdit}>
                        <EditIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </ResourceAbility>
    )
}

const NotificationMessage = () => {
    return (
        <FormattedMessage defaultMessage="Application Updated" />
    )
}

interface ApplicationOAuthConfigurationPublishViewProps {
    typeConfigs: IdentityApplicationTypeConfig[] | null;
    status: IdentityApplicationPublishedStatus;
    dialogRef: MutableRefObject<ApplicationOAuthConfigurationDialogRef | undefined>
    onSuccess: (data: ApplicationConfigurationFormData) => void
}

const ApplicationOAuthConfigurationPublishView = ({ typeConfigs, status, onSuccess, dialogRef }: ApplicationOAuthConfigurationPublishViewProps) => {

    const [typeConfig] = useState(() => {
        return typeConfigs?.filter(value => value.type === status.applicationType)?.[0]
    })

    return status.applicationName && status.env ? (
        <ApplicationOAuthConfigurationDialog typeConfig={typeConfig} publishType={status.publishedGateway ? "update" : "publish"} onSubmit={onSuccess} applicationName={status.applicationName} env={status.env} ref={dialogRef} />
    ) : (<></>)
}

function IdentityApplicationConfigurationsView() {
    const applicationViewContext = useContext(IdentityApplicationViewContext);
    const notificationContext = useContext(SnackNotificationContext);

    const [status, setStatus] = useState(createIdentityApplicationPublishedStatus(applicationViewContext.state));

    useEffect(() => {
        setStatus(createIdentityApplicationPublishedStatus(applicationViewContext.state))
    }, [applicationViewContext]);

    const dialogRef = useRef<ApplicationOAuthConfigurationDialogRef>();

    const onSuccess = useCallback((data: ApplicationConfigurationFormData) => {
        if (data.__applicationPublishInfo?.application) {
            notificationContext.show({
                id: "application-view-publish",
                message: <NotificationMessage />
            });

            applicationViewContext.dispatch({
                type: "applicationUpdate",
                payload: {
                    application: data.__applicationPublishInfo.application
                }
            })
        }

    }, [notificationContext, applicationViewContext]);

    const onEdit = useCallback(() => dialogRef?.current?.open({
        grantTypes: status.publishedGateway?.grantTypes,
        callbackUrls: status.publishedGateway?.redirectUri?.join(","),
        logoutUrls: status.publishedGateway?.logoutUri?.join(",")
    }), [status, dialogRef]);


    const [response, { subscribe }] = useAsyncAction(() => PublisherApplicationService.getTypes());
    useEffect(() => {
        const unsubscribe = subscribe()

        return () => unsubscribe();
    }, [subscribe]);


    return (
        <AsyncLoader state={response} loader={<ResourceViewOuterContentSkeleton />}>
            <>
                <ApplicationOAuthConfigurationPublishView typeConfigs={response?.value} dialogRef={dialogRef} status={status} onSuccess={onSuccess} />

                <AccordionContent size="small" header={<FormattedMessage defaultMessage="OAuth Configurations" />} controls={status.env && <HeaderControls env={status.env} onEdit={onEdit} />}>
                    <IdentityApplicationOAuthView status={status} />
                </AccordionContent>

                <AccordionContent size="small" header={<FormattedMessage defaultMessage="Enforce scope validation" />} >
                    <IdentityApplicationScopeValidationView status={status} />
                </AccordionContent>

                {
                    status.samlSpMetadata && <AccordionContent size="small" header={<FormattedMessage defaultMessage="SAML Configurations" />}>
                        <IdentityApplicationSAMLView status={status} />
                    </AccordionContent>
                }
            </>
        </AsyncLoader>
    )
}

IdentityApplicationConfigurationsView.propTypes = {

}

export default memo(IdentityApplicationConfigurationsView);

