import Axios from "axios";
import { from } from "rxjs";

export interface IAPITeam {
    id: string,
    name: string,
    email: string,
    team: string,
    role: string
}

class TeamServiceFactory {

    private get_list_user = "/publisher/team/{teamName}/users";
    private parsed_url = "";

    httpClient = Axios.create();

    public getUserList(teamName: string | undefined) {
        this.parsed_url = this.parseRequestURL(this.get_list_user,
            { teamName: teamName });
        return from(this.httpClient.get<IAPITeam[]>(`${this.parsed_url}`).then(value => value.data));
    }



    public useToken(token: string) {
        this.httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        this.httpClient.defaults.baseURL = process.env.REACT_APP_UNIFIED_API_URL;
        this.httpClient.defaults.headers.post['Content-Type'] = 'application/json';
    }

    public useTeam(team: string | undefined) {
        this.httpClient.defaults.params = {
            "teamId": team
        }
    }

    public parseRequestURL = (requestURL: string, request: {
        [key: string]: string | undefined
    }) => {
        Object.keys(request).forEach(key => {
            const value = request[key];
            if (value) {
                requestURL = requestURL.replace(new RegExp('{' + key + '}', 'g'), value);
            }
        });
        return requestURL;
    }
}

const TeamService = new TeamServiceFactory();

export default TeamService;