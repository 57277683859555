import { UrlConfigInfo } from './UrlConfig';
import { mapToArray } from '../../shared/common';

type QueryParams = Map<string, string>;

export class UrlFactory {
    private newParams = new Map<string, string>();
    private urlFragments: string[] = [];

    private static normalizeURL(value: string): string {
        return value.replace(/([^:])(\/\/+)/g, '$1/');
    }

    constructor(private readonly configInfo: UrlConfigInfo, queryParams?: { [key: string]: string }) {
        const queryMap = new Map(Object.keys(queryParams ?? {}).map(key => [key, queryParams?.[key] ?? ""]));
        this.newParams = this.createQueryParams(queryMap);
        this.computeStatus();
    }

    public get params(): { [key: string]: string } {
        return Array.from(this.newParams.entries()).reduce((agg: { [key: string]: string }, val) => {
            agg[val[0]] = val[1]

            return agg;
        }, {});
    }

    public get url(): string {
        let url = [...this.urlFragments].join("/");

        if (!this.configInfo?.methodInfo?.params?.options?.noTrailingSlash) {
            url = `${url}/`;
        }

        return UrlFactory.normalizeURL(url);
    }

    private createQueryParams(queryParams: QueryParams): QueryParams {
        let newParams: QueryParams = new Map();

        Array.from(queryParams.keys())
            .forEach(key => {
                const value = queryParams.get(key);

                if (value) {
                    newParams.set(key, value)
                }
            });

        return newParams;
    }


    private computeStatus() {
        const paths = mapToArray(this.configInfo.methodInfo?.path);

        this.urlFragments = paths
            .reduce((agg: string[], fragment) => fragment ? agg.concat(...UrlFactory.normalizeURL(fragment).split("/")) : agg, [])
            .reduce((agg: string[], fragment) => {
                if (fragment.startsWith(":")) {
                    const key = fragment.replace(":", "");
                    const value = this.newParams.get(key);

                    if (value) {
                        this.newParams.delete(key);
                        agg.push(value);
                    }
                } else {
                    agg.push(fragment);
                }

                return agg;
            }, []);
    }
}