import React from "react";
import { DialogContent, DialogTitle } from "@material-ui/core";


const VerificationHelper = (props: any) => {
    return (
        <section>
            <DialogTitle>What is the verification process?</DialogTitle>
            <DialogContent>
                <p> In order to publish your API to the Trimble Developer Marketplace for global access and consumption, you must complete the review process with your Sector Admin.</p>
                <p> The review process includes evaluating your API for compliance to standards and policies. Please fill on required fields to have your API proxy reviewed for verification. If your request is accepted, your API Proxy will become available for continued development.</p>
            </DialogContent>
        </section>
    );
}
export default VerificationHelper;