import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { ConsumerClient2 } from "./ConsumerClient";
import { from } from "rxjs";
import { Product } from "pages/home/products/components/ProductStackview";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { ProductDetail } from "pages/home/products/components/Product.model";

@APIResource(ConsumerClient2, "")
class ProductServiceImpl {
  @APIMethod("/products", {
    options: {
      noTrailingSlash: true
  }
  })
  public getProductList(identityEnvironment: string) {
    return from(
      ConsumerClient2.get<Product[]>(apiMethodContext(arguments), {
        params: {
          identityEnv: identityEnvironment
        },
      }));
  }

  @APIMethod("/products/:productId", {
    options: {
      noTrailingSlash: true
  }
  })
  public getProduct(productId: string) {
    return from(
      ConsumerClient2.get<ProductDetail>(apiMethodContext(arguments), {
        params: {
          productId: productId
        },
      }));
  }
}

export const ConsumerProductService = new ProductServiceImpl();

