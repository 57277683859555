import React, { FC, PropsWithChildren, ReactElement, memo } from 'react'
import PropTypes from "prop-types";
import { makeStyles, createStyles } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            lineHeight: "inherit",
            fontSize: "inherit",
            verticalAlign: "text-top",
            color: "inherit",
            cursor: "pointer",

            "& .MuiSvgIcon-root": {
                fontSize: "inherit"
            }
        }
    }),
);

interface InlineIconFormatterProps {
    icon: ReactElement
}

const InlineIconFormatter: FC<PropsWithChildren<InlineIconFormatterProps>> = props => {
    const styles = useStyles();

    return (
        <>{props.children} <span className={styles.icon}>{props.icon}</span></>
    )
}

InlineIconFormatter.propTypes = {
    children: PropTypes.any.isRequired,
    icon: PropTypes.any.isRequired
};

export default memo(InlineIconFormatter);


export const InlineOpenInNewIconFormatter = memo(({ children }: PropsWithChildren<{}>) => {
    return (
        <InlineIconFormatter icon={<OpenInNewIcon />}>
            {children}
        </InlineIconFormatter>
    )
});