import {ColDef, ICellRendererParams} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { GridViewAgGridContainer, GridViewFrameworkComponentsType, useGenericGridViewElements } from "legacy-components/grid-view/GridView";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ProxyService from "../../../../libs/resources/proxy/ProxyService";
import StarTick from "../../../../legacy-components/icons/StarTick";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import {Tooltip} from "@material-ui/core";

interface AudiencesGridViewProps {
    apiName: string
    gatewayName: string
}

const noDataMessage = <FormattedMessage defaultMessage="No audience metadata for the proxy" />;

export const AudiencesGridView : React.FC<AudiencesGridViewProps> =
    ({apiName, gatewayName}) => {

    const intl = useIntl();

    const { onGridReady, frameworkComponents, gridViewOverlayPropRef, getRowNodeId } = useGenericGridViewElements({
        resolverFn: () => ProxyService.getAudienceMetadata(apiName, gatewayName),
        noDataMessage
    });

    const StatusBadge = ({ data }: ICellRendererParams) => {
        if (data.status === true)
            return <StarTick/>;
        else
            return <NewReleasesIcon style={{ color: ("#FBAD26")}}/>
    };

    const ActualAudience = ({ data }: ICellRendererParams) => {
      return <Tooltip title={data.audience}><span>{data.audience ? (data.audience.split('-')?.[0] + "...") : "-"}</span></Tooltip>
    };

    const [columnDefs] = useState<ColDef[]>(() => {
        const columns: ColDef[] = [{
            field: "status",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Status"
            }),
            flex: 0.8,
            resizable: true,
            cellRendererFramework : StatusBadge
        }, {
            field: "applicationName",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Application"
            }),
            flex: 1.2,
            resizable: true
        }, {
            field: "audience",
            headerName: intl.formatMessage({
                defaultMessage: "Actual Audience"
            }),
            flex: 1,
            resizable: true,
            cellRendererFramework : ActualAudience
        }, {
            field: "teamName",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Team"
            }),
            flex: 1,
            resizable: true
        }, {
            field: "createdBy",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "App Created By"
            }),
            flex: 1.2,
            resizable: true
        }
        ];

        return columns;
    });

    return (
        <GridViewAgGridContainer>
            <AgGridReact
                columnDefs={columnDefs}
                frameworkComponents={frameworkComponents}
                loadingOverlayComponent={GridViewFrameworkComponentsType.LoadingOverlay}
                noRowsOverlayComponent={GridViewFrameworkComponentsType.NoRowsOverlay}
                noRowsOverlayComponentParams={{ gridViewOverlayPropRef }}
                suppressRowClickSelection={true}
                suppressCellSelection={true}
                suppressRowHoverHighlight={true}
                getRowNodeId={getRowNodeId}
                onGridReady={onGridReady}
            />
        </GridViewAgGridContainer>
    )
}