import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { CloudConsoleClient } from "libs/utils/http/client/HttpClient";
import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { from } from "rxjs";

export interface IAPIProxyDeployment {
  deployStatus: string;
  environmentName?: string;
  backendUrl: string;
  deployedUrl: string;
  organisation?: string;
  timeout?: string;
  deploymentLocation?: string;
}

export interface IAPIProxy {
  id: string;
  apiName?: string;
  description?: string;
  apiContext?: string;
  version?: string;
  publishStatus?: string;
  apiDeployments?: IAPIProxyDeployment[];
  resourcePath?: string;
  permissions?: string;
  isShared?: boolean;
  accessRole?: AccessRoleType;
  isVerified?: number;
}

export interface IAPIProxyDeployRequest {
  backendUrl?: string;
  timeout?: number;
  basepath?: string;
  threatProtection?: string;
  tls?: string;
  isAdvanced?: boolean;
  migrateApi?: boolean;
  jwtSupport?: Array<string>;
}

export interface IAPINoAuth {
  requestPath: string;
  apiId: string;
}
export interface IAPIProxyNoAuthRequest {
  requestVerb: string;
  requestPath: string;
  id: string;
  apiName: string;
  environment: string;
}
export interface IAPIProxyNoAuthResponse {
  apiId: string;
  environment: string;
  pathVerbDetails: PathVerbDetails[];
}
export interface PathVerbDetails {
  path: string;
  methods: PathMethods[];
}
export interface PathMethods {
  method: string;
  noAuthId: string;
}
export interface APICreateRequest {
  displayName: string | undefined;
  version: string | undefined;
  context: string | undefined;
  namespace: string | undefined;
  resourceServerName: string | undefined;
}
export type AccessRoleType = "viewer" | "editor";

export interface AudienceMetadata {
    apiName: string;
    applicationName: string;
    audience: string;
    status: boolean;
    environmentName: string;
    updated: string;
}

/**
 * Purpose: This list is needed to hide the Proxy environment configuration in Enhanced Provider Workflow.
 * This list is needed until removing Proxy Workflow in UI Completely. 
 */
const preProdEnvlist = [''];

@APIResource(CloudConsoleClient, "publisher")
class ProxyServiceFactory {

 

  @APIMethod("/api/:apiName")
  public getApiProxy(apiName: string) {
    return from(
      CloudConsoleClient.get<IAPIProxy>(apiMethodContext(arguments), {
        params: {
          apiName,
          includeEmailAddress: true,
        },
      }).then((value) => ({...value.data,
        apiDeployments: value?.data?.apiDeployments?.filter((deployment: IAPIProxyDeployment) => !preProdEnvlist.includes(deployment?.environmentName ?? "") )
      }))
    );
  }

}

const ProxyService = new ProxyServiceFactory();

export default ProxyService;
