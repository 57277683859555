import React, { Ref, useCallback, useImperativeHandle, FC, PropsWithRef, forwardRef } from 'react';
import { Dialog, DialogTitle, makeStyles, createStyles, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { useCompactDialog } from '../../../legacy-components/dialog/UseCompactDialog';
import { ICertificate, CertInfo } from './CertificateService';
import { FormattedMessage } from 'react-intl';
import ActionInliner from '../../../legacy-components/form/action/ActionInliner';
import FormViewInliner from '../../../legacy-components/form/container/FormViewInliner';
import NAWrap from '../../../legacy-components/form/action/NAWrap';
import HighlightWrap from '../../../legacy-components/form/action/HighlightWrap';
import { EnvironmentStatusBadgeCellRenderer } from '../../../legacy-components/grid-view/GridView';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(({ spacing }) => {
    return createStyles({
        header: {
            position: "relative"
        },
        typeText: {
            padding: spacing(3, 0, 2, 0)
        },
        button: {
            paddingTop: '7px',
            float: "right"
        },
        root: {
            width: '100%',
        },
        heading: {
            flexBasis: '83%',
            flexShrink: 0,
        },
        secondaryHeading: {
            float: "right"
        }
    })
});

export interface CertificateDetailDialogRef {
    open(): void
}

interface CertificateDetailDialogProps {
    ref?: Ref<CertificateDetailDialogRef | undefined>,
    certificate: ICertificate;
}

const CertificateDetailsViewer: FC<{
    certInfo: CertInfo[]
}> = ({ certInfo }) => {

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const checkExpanded = (panel: any, expanded: any): boolean => {
        return (expanded === panel ? true : false);
    }

    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (

        <div className={classes.root}>
            {  certInfo.map(({ isValid, issuer, subject, expiry, commonName }, index) => {
                return (
                    <Accordion id={`accordin${index}-header`} expanded={checkExpanded(`panel${index}`, expanded)} onChange={handleChange(`panel${index}`)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}-header`}
                        >
                            <Typography className={classes.heading}>{commonName}</Typography>
                            <EnvironmentStatusBadgeCellRenderer value={isValid as string} />
                        </AccordionSummary>
                        <AccordionDetails id={`accordin${index}-details1`} >
                            <FormViewInliner variant="inline" gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Issuer" />}>
                                <Typography id={`accordin${index}-typo1`}>
                                    <NAWrap value={issuer} showMessage={true}>
                                        <HighlightWrap> <code>{issuer}</code></HighlightWrap>
                                    </NAWrap>
                                </Typography>
                            </FormViewInliner>
                        </AccordionDetails>

                        <AccordionDetails id={`accordin${index}-details2`} >
                            <FormViewInliner variant="inline" gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Subject" />}>
                                <Typography id={`accordin${index}-typo2`}>
                                    <NAWrap value={subject} showMessage={true}>
                                        <HighlightWrap> <code>{subject}</code></HighlightWrap>
                                    </NAWrap>
                                </Typography>
                            </FormViewInliner>
                        </AccordionDetails>

                        <AccordionDetails id={`accordin${index}-details3`} >
                            <FormViewInliner variant="inline" gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Expiry Date" />}>
                                <Typography id={`accordin${index}-typo3`} >
                                    <NAWrap value={expiry} showMessage={true}>
                                        <HighlightWrap> <code>{expiry}</code></HighlightWrap>
                                    </NAWrap>
                                </Typography>
                            </FormViewInliner>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
    );
}

const CertificateDetailDialog: FC<PropsWithRef<CertificateDetailDialogProps>> = forwardRef(({ certificate }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
        >

            <div className={style.header}>
                {<DialogTitle>
                    <FormattedMessage defaultMessage="Certificate Details" />
                </DialogTitle>}

            </div>
            <DialogContent>
                <CertificateDetailsViewer certInfo={certificate.certInfo} />
            </DialogContent>
            <DialogActions>
                <ActionInliner>
                    <Button onClick={handleClose}>
                        <FormattedMessage defaultMessage="Close" />
                    </Button>
                </ActionInliner>
            </DialogActions>
        </Dialog>
    );
});

export default CertificateDetailDialog

