import React, { memo, useRef, useCallback } from 'react'
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import SettingsIcon from '@material-ui/icons/Settings';
import ProductDocumentPublishDialog, { ProductDocumentPublishDialogRef } from './ProductDocumentPublishDialog';
import { ProductDocumentFieldFileInfo } from './ProductDocumentField';
import { ProductDocumentPublishedData } from '../../models/products.model';

interface ProductDocumentPublishActionProps {
    onChange: (data: ProductDocumentPublishedData) => void,
    docInfo: ProductDocumentFieldFileInfo,
    productName: string;
}

function ProductDocumentPublishAction({ onChange, docInfo, productName }: ProductDocumentPublishActionProps) {
    const confirmDialogRef = useRef<ProductDocumentPublishDialogRef>();

    const onClick = useCallback(() => confirmDialogRef?.current?.open(), []);

    return (
        <>
            <ProductDocumentPublishDialog ref={confirmDialogRef} onChange={onChange} docInfo={docInfo} productName={productName} />

            <Button onClick={onClick} variant="outlined" size="small" startIcon={<SettingsIcon />}>
                <FormattedMessage defaultMessage="Visibility" />
            </Button>
        </>
    )
}

ProductDocumentPublishAction.propTypes = {
    onChange: PropTypes.func.isRequired
}

export default memo(ProductDocumentPublishAction);

