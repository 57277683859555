import { ColDef, CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { GridViewAgGridContainer, GridViewApprovalStatusBadgeCellRenderer, GridViewFrameworkComponentsType, useGenericGridViewElements } from "legacy-components/grid-view/GridView";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SubscriptionService from "./SubscriptionService";
import { useHistory } from "react-router";

interface IdentityApplicationSubscriptionGridViewProps {
    applicationName: string
    gatewayName: string
}

const noDataMessage = <FormattedMessage defaultMessage="No subscriptions available for the application" />;

export const IdentityApplicationSubscriptionGridView : React.FC<IdentityApplicationSubscriptionGridViewProps> = 
    ({applicationName, gatewayName}) => {

    const intl = useIntl();

    const { onGridReady, frameworkComponents, gridViewOverlayPropRef, getRowNodeId } = useGenericGridViewElements({
        resolverFn: () => SubscriptionService.getApplicationSubScriptions(applicationName, gatewayName) ,
        noDataMessage
    });

    const formatDate = (date: any) => {
        const d = new Date(date);
        let formatted = d.getDate().toString().concat("/").concat((d.getMonth() + 1).toString().concat("/").concat(d.getFullYear().toString()));
        return formatted;
    }
    let history = useHistory();

    const onCellClicked = (event: CellClickedEvent) => {
        history.push(`/home/marketplace/product/${event.data.productName}`)
    }
    const [columnDefs] = useState<ColDef[]>(() => {
        const columns: ColDef[] = [{
            field: "productName",
            sortable: true,
            unSortIcon : true,
            filter: "agTextColumnFilter",
            cellRenderer : function(params) {
                return '<div style = "color:#00437B">' + params.value + '</div>';
            },
            onCellClicked : (event) =>onCellClicked(event),
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Product Name"
            }),
            flex: 1,
            resizable: true
        }, {
            field: "productTeamName",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Product Team"
            }),
            flex: 1,
            resizable: true
        }, {
            field: "subscriptionRequestedTime",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Subscription Date"
            }),
            flex: 1.3,
            valueGetter: (params) => {
                return(formatDate(params.data.subscriptionRequestedTime))
            }
        }, {
            field: "subscriberEmail",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Subscribed By"
            }),
            flex: 1,
            resizable: true
        }, {
            field: "status",
            cellRendererFramework: GridViewApprovalStatusBadgeCellRenderer,
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Status"
            }),
            flex: 1,
            resizable: true
        }
        ];

        return columns;
    });

    return (
        <GridViewAgGridContainer>
            <AgGridReact
                columnDefs={columnDefs}
                frameworkComponents={frameworkComponents}
                loadingOverlayComponent={GridViewFrameworkComponentsType.LoadingOverlay}
                noRowsOverlayComponent={GridViewFrameworkComponentsType.NoRowsOverlay}
                noRowsOverlayComponentParams={{ gridViewOverlayPropRef }}
                suppressRowClickSelection={true}
                suppressCellSelection={true}
                suppressRowHoverHighlight={true}
                getRowNodeId={getRowNodeId}
                onGridReady={onGridReady}
            />
        </GridViewAgGridContainer>
    )
}