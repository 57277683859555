import { ApplicationDeleteFormData } from "../models/application.model";
import PublisherApplicationService, { IdentityApplicationEnvironmentType } from "../PublisherApplicationService";
import { FC, useState, useContext, ReactElement } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { FormApi, FORM_ERROR } from "final-form";
import { FormRenderProps, Field } from "react-final-form";
import { ValidatorBuilderContext } from "../../../../../legacy-components/form/validator/Validator";
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Button, InputAdornment } from "@material-ui/core";
import FormContainer from "../../../../../legacy-components/form/container/FormContainer";
import React from "react";
import FormInputContainer from "../../../../../legacy-components/form/container/FormInputContainer";
import FormTextInput from "../../../../../legacy-components/form/input/FormTextInput";
import ActionInliner from "../../../../../legacy-components/form/action/ActionInliner";
import { StatusBadgeInfo, StatusBadge } from "../../../../../legacy-components/badges/StatusBadge";
import { WarnButton } from "../../../../../legacy-components/form/action/WarnButton";

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            padding: spacing(3)
        },
    }),
);
interface ApplicationDeleteFormProps {
    onCancel: () => void,
    onSuccess: (data: ApplicationDeleteFormData) => void
    applicationName: string,
    env: IdentityApplicationEnvironmentType,
    formContent?: ReactElement
}

const ApplicationDeleteForm: FC<ApplicationDeleteFormProps> = ({ onCancel, onSuccess, applicationName, env, formContent }) => {
    const intl = useIntl();
    const styles = useStyles();

    const onSubmit = () => (data: ApplicationDeleteFormData, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                if (data.applicationName === applicationName) {
                    PublisherApplicationService.environments[env]?.delete(applicationName)
                        .then(
                            () => resolve(),
                            error => resolve({
                                [FORM_ERROR]: error
                            }));
                } else {
                    resolve({
                        applicationName: intl.formatMessage({
                            defaultMessage: "Application name didn't match"
                        })
                    })
                }
            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        const validator = useContext(ValidatorBuilderContext);
        const [deploymentStatus] = useState<StatusBadgeInfo>({
            kind: "access",
            key: env,
            access: false
        });

        return (
            <div className={styles.root}>
                {formContent}

                <FormInputContainer>
                    <Field
                        name="applicationName"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Application Name"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "Type the application name"
                        })}
                        validate={validator.from({
                            required: true
                        })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <StatusBadge status={deploymentStatus} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormInputContainer>

                <ActionInliner padding="inset-top">
                    <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                    <WarnButton disabled={formRenderProps.submitting || formRenderProps.pristine} type="submit" variant="contained" color="primary" disableElevation>
                        <FormattedMessage defaultMessage="Delete" />
                    </WarnButton>
                </ActionInliner>
            </div>);
    }

    return <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

ApplicationDeleteForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    applicationName: PropTypes.string.isRequired,
    env: PropTypes.oneOf<IdentityApplicationEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired
}

export default ApplicationDeleteForm;