import React, { FC, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import StepperForm from '../../../legacy-components/form/components/stepper-form/StepperForm';
import ProductAboutForm from './product-forms/ProductAboutForm';
import { Container } from '@material-ui/core';
import ProductDetailsForm from './product-forms/ProductDetailsForm';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import ProductPublishForm from './product-forms/ProductPublishForm';
import { SnackNotificationContext } from '../../../legacy-components/notifications/GenericSnackNotification';
import ProductProxyForm from './product-forms/ProductProxyForm';
import GenericPageHeader from '../../../legacy-components/page-headers/GenericPageHeader';
import { useCreateFormStyles } from '../../../legacy-components/form/components/create-form/CreateForm';
import ProductApiTypeForm from './product-forms/ProductApiTypeForm';

const ProductCreate: FC<any> = props => {
    const createFormStyles = useCreateFormStyles();

    const snackNotification = useContext(SnackNotificationContext);


    const onComplete = useCallback(() => {
        snackNotification.show({
            id: "create-product-success",
            message: <FormattedMessage defaultMessage="Product created successfully" />
        })
        props.dispatch(props.history.push("/home/products/"));
    }, [props, snackNotification]);

    return (
        <>
            <GenericPageHeader
                header={<FormattedMessage defaultMessage="API Products" />}
                title={<FormattedMessage defaultMessage="Create Product" />}
            />

            <Container>
                <div className={createFormStyles.root}>
                    <StepperForm id="product-creation" onComplete={onComplete}>
                        <StepperForm.type.Step key="about" stepId="about" label={<FormattedMessage defaultMessage="About" />}>
                            <ProductAboutForm stepperId="product-creation" />
                        </StepperForm.type.Step>

                        <StepperForm.type.Step key="apiType" stepId="apiType" label={<FormattedMessage defaultMessage="API Type" />}>
                            <ProductApiTypeForm stepperId="product-creation" />
                        </StepperForm.type.Step>

                        <StepperForm.type.Step key="proxy" stepId="proxy" label={<FormattedMessage defaultMessage="Proxies" />}>
                            <ProductProxyForm stepperId="product-creation" />
                        </StepperForm.type.Step>

                        <StepperForm.type.Step key="productDetail" stepId="productDetail" label={<FormattedMessage defaultMessage="Product Detail" />}>
                            <ProductDetailsForm stepperId="product-creation" />
                        </StepperForm.type.Step>

                        <StepperForm.type.Step key="publish" stepId="publish" label={<FormattedMessage defaultMessage="Publish" />}>
                            <ProductPublishForm stepperId="product-creation" />
                        </StepperForm.type.Step>
                    </StepperForm>
                </div>
            </Container>
        </>
    )
}

const mapStateToProps = () => ({

});

export default withRouter(connect(mapStateToProps)(ProductCreate));

