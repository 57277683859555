import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import GenericPageHeader from '../../../../legacy-components/page-headers/GenericPageHeader';
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import TeamCard from "./TeamCard";
import TeamCreator from "./TeamCreator";
import Loader from "../../../../legacy-components/loader";
import NameSpaces from "pages/home/team/NameSpaces";
import { Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

class TeamGrid extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            teamList: null,
            error: false
        }
    }
    componentDidMount() {
        this.loadTeamDetails();
    }

    loadTeamDetails = () => {
        UnifiedAPIService.get(
            UnifiedAPI.parseRequestURL(UnifiedAPI.list_sector_teams, {
                sectorName: this.props.match.params.sector
            })
        ).then((response: any) => {
            this.setState({ teamList: response.data })
        }, error => {
            this.setState({ teamList: [] });
            this.setState({error : true})
        });
    }

    render() {
        
        let teamDetails;
        if (this.state.teamList) {
            let teamListDetails: Array<any> = [];
            this.state.teamList.forEach((team: any) => {
                teamListDetails.push(<TeamCard team={team} key={team.teamName} onChange={this.loadTeamDetails} />);
            });
            teamDetails = <div className="cards">{teamListDetails}</div>;
        } else {
            teamDetails = (<div><Loader /></div>)
        }
        return (
            <>
            {this.state.error && <Alert severity='error'>The following team lists is restricted to the following user for the selected team</Alert>}
           {!this.state.error && <div className="team-list flex-col-center">
                <GenericPageHeader header={<FormattedMessage defaultMessage="My Teams" />} title={<Typography variant="h4" gutterBottom className="sector">{this.props.match.params.sector}</Typography>} controls={<><NameSpaces sectorName={this.props.match.params.sector} /><TeamCreator onCreateSuccess={this.loadTeamDetails} /></>} />
                {teamDetails}
            </div>}
            </>
        );
    }
}

/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state
 */
const mapStateToProps = (state: any) => {
    return {
        sector: state.userManager.sector
    };

};
export default withRouter(connect(mapStateToProps)(TeamGrid));
