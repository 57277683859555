import { FC, useContext } from "react";
import { FormRenderProps, Field } from "react-final-form";
import FormContainer from "legacy-components/form/container/FormContainer";
import { useIntl, FormattedMessage } from "react-intl";
import { ValidatorBuilderContext } from "legacy-components/form/validator/Validator";
import React from "react";
import { Button } from "@material-ui/core";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import FormTextInput from "legacy-components/form/input/FormTextInput";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import ApprovalService from "pages/home/approval/ApprovalService";
import { ApprovalSubmitRequest } from "pages/home/approval/model/approval.model";
import { FormApi, FORM_ERROR } from "final-form";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import ProxyVerificationService from "../ProxyVerificationService";

interface ApiVerificationReviewerFormProps {
  onSuccess?: () => void;
  onCancel?: () => void;
  reviewers?: Array<{
    key?: string;
    value?: string;
  }>;
  submitRequest?: ApprovalSubmitRequest;
  proxy: string;
}
export const ApiVerificationReviewerForm: FC<ApiVerificationReviewerFormProps> =
  ({ proxy, reviewers, onCancel, onSuccess }) => {
    const NotificationMessage = () => {
      return (
        <FormattedMessage defaultMessage="Request Submitted Successfully and Your API is verified." />
      );
    };
    const notificationContext = useContext(SnackNotificationContext);
    const intl = useIntl();
    const onSubmit = () => (data: any, form: FormApi) => {
      return new Promise((resolve) => {
        const formState = form.getState();
        if (formState.pristine) {
          ProxyVerificationService.forVerifiedStatus(proxy).subscribe(
            () => {
              notificationContext.show({
                id: "submit-request",
                message: <NotificationMessage />,
              });
              resolve();
            },
            (error) => {
              resolve({
                [FORM_ERROR]: error,
              });
            }
          );
        } else {
          /*const submitRequest: ApprovalSubmitRequest = {
            resourceName: proxy,
            reviewerEmail: [data.reviewerEmail],
          };
          ApprovalService.forSubmitApproval("API", submitRequest).subscribe(
            () => {
              notificationContext.show({
                id: "submit-request",
                message: <NotificationMessage />,
              });
              resolve();
            },
            (error) => {
              resolve({
                [FORM_ERROR]: error,
              });
            }
          );*/
        }
      });
    };

    const FormComponents = (
      formRenderProps: FormRenderProps<ApprovalSubmitRequest, {}>
    ) => {
      const validator = useContext(ValidatorBuilderContext);

      return (
        <>
          {/* <FormInputContainer
            title={
              <FormattedMessage defaultMessage="Please select someone to review your request" />
            }
          >
            <Field
              name="reviewerEmail"
              type="text"
              component={FormTextInput}
              label={intl.formatMessage({
                defaultMessage: "Name, Email Address",
              })}
              placeholder={intl.formatMessage({
                defaultMessage: "Name, Email Address",
              })}
              options={reviewers}
              validate={validator.from({
                required: true,
              })}
            />
          </FormInputContainer> */}

          <ActionInliner>
            <Button
              onClick={onCancel}
              disabled={formRenderProps.submitting}
              type="button"
              variant="outlined"
              disableElevation
            >
              {" "}
              <FormattedMessage defaultMessage="Cancel" />
            </Button>

            <Button
              disabled={formRenderProps.submitting}
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
            >
              {" "}
              <FormattedMessage defaultMessage="Yes" />
            </Button>
          </ActionInliner>
        </>
      );
    };

    if (reviewers?.length == 0) {
      return (
        <span>
          There is no reviewers available for your sector at the moment.
        </span>
      );
    } else {
      return (
        <FormContainer
          previewErrors={true}
          FormProps={{ onSubmit }}
          onSubmitSuccess={onSuccess}
          children={FormComponents}
        />
      );
    }
  };
ApiVerificationReviewerForm.propTypes = {};

export default ApiVerificationReviewerForm;
