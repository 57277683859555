import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Typography, Link } from "@material-ui/core";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import NAWrap from "legacy-components/form/action/NAWrap";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import { ProxyVerificationPerformanceInfo } from "pages/home/proxy/summary/verification/models/verification.model";

interface ProxyVerificationPerformanceDocViewProps {
  proxyVerificationPerformanceInfo?: ProxyVerificationPerformanceInfo | null;
  onSuccess?: () => void;
}

const ProxyVerificationPerformanceDocView: FC<ProxyVerificationPerformanceDocViewProps> =
  ({ proxyVerificationPerformanceInfo }) => {
    return (
      <FormInputContainer
        title={
          <Typography
            variant="subtitle2"
            children={
              <FormattedMessage defaultMessage="What performance guarantees can you provide?" />
            }
          />
        }
      >
        <FormViewInliner
          variant="inline"
          gutter="gutter-bottom"
          title={<FormattedMessage defaultMessage="Uptime (past 6 months)" />}
        >
          <Typography variant="body1">
            <NAWrap
              value={proxyVerificationPerformanceInfo?.performanceUptime}
              showMessage={true}
            >
              {proxyVerificationPerformanceInfo?.performanceUptime}
            </NAWrap>
          </Typography>
        </FormViewInliner>
        {/* <FormViewInliner variant="inline" gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Capacity (max r/s)" />} >
                <Typography variant="body1">
                    <NAWrap value={proxyVerificationPerformanceInfo?.performanceCapacity} showMessage={true}>
                        {proxyVerificationPerformanceInfo?.performanceCapacity}
                    </NAWrap>
                </Typography>
            </FormViewInliner>
            <FormViewInliner variant="inline" gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Error Rate (Errors/min)" />} >
                <Typography variant="body1">
                    <NAWrap value={proxyVerificationPerformanceInfo?.performanceErrorRate} showMessage={true}>
                        {proxyVerificationPerformanceInfo?.performanceErrorRate}
                    </NAWrap>
                </Typography>
            </FormViewInliner> */}
        <FormViewInliner
          variant="inline"
          gutter="gutter-bottom"
          title={
            <FormattedMessage defaultMessage="Avg. Latency (P95) - past month" />
          }
        >
          <Typography variant="body1">
            <NAWrap
              value={
                proxyVerificationPerformanceInfo?.performanceAverageLatency
              }
              showMessage={true}
            >
              {proxyVerificationPerformanceInfo?.performanceAverageLatency}
            </NAWrap>
          </Typography>
        </FormViewInliner>
        <FormViewInliner
          variant="inline"
          gutter="gutter-bottom"
          title={<FormattedMessage defaultMessage="Status Page" />}
        >
          <Typography variant="body1">
            <NAWrap
              value={proxyVerificationPerformanceInfo?.performanceStatusPage}
              showMessage={true}
            >
              <Link
                color="primary"
                href={proxyVerificationPerformanceInfo?.performanceStatusPage}
                target="_blank"
              >
                {proxyVerificationPerformanceInfo?.performanceStatusPage}
              </Link>
            </NAWrap>
          </Typography>
        </FormViewInliner>
        {proxyVerificationPerformanceInfo?.performanceMetricsUrl &&
          proxyVerificationPerformanceInfo?.performanceMetricsUrl?.map(
            (url, index) => {
              return (
                <FormViewInliner
                  key={index}
                  variant="inline"
                  gutter="gutter-bottom"
                  title={
                    index == 0 ? (
                      <FormattedMessage defaultMessage="Urls" />
                    ) : (
                      <></>
                    )
                  }
                >
                  <Typography key={index} variant="body1">
                    <NAWrap value={url} showMessage={true}>
                      <Link color="primary" href={url} target="_blank">
                        {url}
                      </Link>
                    </NAWrap>
                  </Typography>
                </FormViewInliner>
              );
            }
          )}
        <FormViewInliner
          variant="inline"
          gutter="gutter-bottom"
          title={<FormattedMessage defaultMessage="Additional Input" />}
        >
          <Typography variant="body1">
            <NAWrap
              value={
                proxyVerificationPerformanceInfo?.performanceAdditionalInput
              }
              showMessage={true}
            >
              {proxyVerificationPerformanceInfo?.performanceAdditionalInput}
            </NAWrap>
          </Typography>
        </FormViewInliner>
      </FormInputContainer>
    );
  };

ProxyVerificationPerformanceDocView.propTypes = {};

export default ProxyVerificationPerformanceDocView;
