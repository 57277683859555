import { makeStyles, createStyles, Typography } from "@material-ui/core";
import AccordionForm, { AccordionSummaryTitle, AccordionContent } from "../form/components/accordion-form/AccordionForm";
import React, { useEffect, PropsWithChildren, memo, FC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import FormViewInliner, { FormViewInlinerVariant } from "../form/container/FormViewInliner";
import { Observable } from "rxjs";
import { useAsyncAction, AsyncActionState } from "../form/action/AsyncAction";

export const useResourceViewStyles = makeStyles(() =>
    createStyles({
        section: {
            width: '100%',
        }
    }),
);

export const useResourceViewSectionTitleStyles = makeStyles(({ spacing }) =>
    createStyles({
        title: {
            margin: spacing(3, 2, 2)
        }
    }),
);

export const useResourceViewNoDataStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            padding: spacing(3),
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center"
        },
        content: {
            display: "flex",
            flexDirection: "column"
        }
    }),
);

export const useResourceViewElements: <T>(options: {
    resolverFn: () => Observable<T>
}) => {
    response: AsyncActionState<T | null>
} = ({
    resolverFn
}) => {
        const [response, { subscribe }] = useAsyncAction(resolverFn);

        useEffect(() => {
            const unsubscribe = subscribe()

            return () => unsubscribe();
        }, [subscribe]);

        return {
            response
        };
    }

export const ResourceViewSectionTitle = memo(({ children }: PropsWithChildren<{}>) => {
    const styles = useResourceViewSectionTitleStyles();

    return (
        <div className={styles.title}>
            <Typography variant="subtitle2" className="highlight-subtitle">
                {children}
            </Typography>
        </div>
    )
});

export const ResourceViewContentSkeleton: FC<{
    variant?: FormViewInlinerVariant,
    width?: number | string,
    count?: number
}> = memo(({ variant, width = 200, count = 4 }) => {
    const styles = useResourceViewStyles();

    return (
        <div className={styles.section}>
            <>
                {
                    (new Array(count).fill(0)).map((_, index) =>
                        (
                            <FormViewInliner key={index} variant={variant} gutter="gutter-bottom" title={<Skeleton width={140} />}>
                                <Typography variant="body1">
                                    <Skeleton width={width} />
                                </Typography>
                            </FormViewInliner>
                        )
                    )
                }
            </>
        </div>
    )
})


export const ResourceViewSkeleton = memo(() => {
    return (
        <AccordionForm id="resource-summary-loader">
            <AccordionForm.type.Section id="resource-loader" summary={
                <AccordionSummaryTitle>
                    <Skeleton width={140} />
                </AccordionSummaryTitle >}>
                <AccordionContent>
                    <ResourceViewContentSkeleton />
                </AccordionContent>
            </AccordionForm.type.Section>
        </AccordionForm>
    );
});

export const ResourceViewOuterContentSkeleton = memo(() => {
    return (
        <AccordionContent>
            <ResourceViewContentSkeleton />
        </AccordionContent>
    );
});

export const ResourceViewNoData: FC<PropsWithChildren<{}>> = memo(({ children }) => {
    const styles = useResourceViewNoDataStyles();

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
});