import React, { FC, useState, useImperativeHandle, useCallback, PropsWithRef, Ref, forwardRef, useEffect } from 'react'
import { useStyles } from 'pages/home/products/product-forms/ProductProxyDialog';
import { Dialog, DialogTitle, IconButton } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useCompactDialog } from 'legacy-components/dialog/UseCompactDialog';
import { DelegationForm } from '../forms/DelegationForm';
import ApprovalService from "pages/home/approval/ApprovalService";
import { UserInfo } from 'pages/home/approval/model/approval.model';

export interface DelegateDialogRef {
    open(): void,
    close(): void
}
interface DelegateDialogProps {
    ref: Ref<DelegateDialogRef | undefined>,
    subscription: string,
    requestId?: string,
    onSuccess: () => void,
    product?: string,
    application?: string
}
const DelegateDialog: FC<PropsWithRef<DelegateDialogProps>> = forwardRef(({ subscription, product, application, requestId, onSuccess }, ref) => {
    const styles = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();
    const [delegators, setDelegators] = useState<Array<{
        key?: string,
        value?: string
    }>>();
    useEffect(() => {
        const id = requestId ? requestId : "";
        ApprovalService.forDelegators(id).subscribe(response => {
            setDelegators(response.map(
                (data: UserInfo) => {
                    return { "key": data.emailAddress, "value": data.name + ", " + data.emailAddress }
                }
            )
            );
        }, error => {
        });
    }, []);
    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen(),
        close: () => handleClose()
    }));
    const [] = useState<Array<string> | null>(null);

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={maxWidth}
                open={open}
                scroll={scroll}
                onClose={handleClose}
            >
                <DialogTitle id="form-dialog-title"><FormattedMessage defaultMessage="Delegate Submission" /></DialogTitle>

                <IconButton className={styles.closeButton}>
                    {/* <CloseIcon onClick={() => handleClose()} /> */}
                </IconButton>
                <div className={styles.createForm}>

                    {<DelegationForm subscription={subscription} product={product} application={application} delegators={delegators} requestId={requestId} onSuccess={onSuccess} onCancel={handleClose} />}
                </div>
            </Dialog>
        </>
    );
});

export default (DelegateDialog);