import React, { Ref, useCallback, useImperativeHandle, FC, PropsWithRef, forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, makeStyles, createStyles, Typography } from '@material-ui/core';
import { ProductUnpublishFormData } from '../../models/products.model';
import { ProductEnvironmentType } from '../../../../../libs/resources/product/ProductService';
import { FormattedMessage } from 'react-intl';
import ProductUnpublishForm from '../ProductUnpublishForm';
import { useCompactDialog } from '../../../../../legacy-components/dialog/UseCompactDialog';

const useStyles = makeStyles(({ spacing }) => {
    return createStyles({
        header: {
            position: "relative"
        },
        dialog: {
            overflowX: "hidden"
        },
        typeText: {
            padding: spacing(3, 0, 2, 0)
        }
    })
});

export interface ProductEnvironmentConfirmationDialogRef {
    open(): void
}

interface ProductEnvironmentConfirmationDialogProps {
    ref?: Ref<ProductEnvironmentConfirmationDialogRef | undefined>,
    productName: string;
    env: ProductEnvironmentType,
    onSubmit: (data: ProductUnpublishFormData) => void
}

interface ProductUnpublishFormViewerProps {
    productName: string;
    env: ProductEnvironmentType;
    onCancel: () => void;
    onSubmit: (data: ProductUnpublishFormData) => void
}

const ProductUnpublishFormContent = ({ productName }: {
    productName: string
}) => {
    const styles = useStyles();
    const productNameFormatter = () => <strong>{productName}</strong>;

    return (
        <>
            <Typography component="p">
                <FormattedMessage values={{
                    productName: productNameFormatter
                }} defaultMessage="This action cannot be undone. This will permanently unpublish the <productName>name</productName> and cannot be accessed elsewhere." />
            </Typography>

            <Typography className={styles.typeText} variant="body2" component="p">
                <FormattedMessage values={{
                    productName: productNameFormatter
                }} defaultMessage="Type <productName>name</productName> to confirm." />
            </Typography>
        </>
    )
}

const ProductUnpublishFormViewer = ({ productName, env, onSubmit, onCancel }: ProductUnpublishFormViewerProps) => {

    return (
        <ProductUnpublishForm productName={productName} formContent={<ProductUnpublishFormContent productName={productName} />} env={env} onCancel={onCancel} onSuccess={onSubmit} />
    )
}

const ProductEnvironmentConfirmationDialog: FC<PropsWithRef<ProductEnvironmentConfirmationDialogProps>> = forwardRef(({ productName, env, onSubmit }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleSubmit = useCallback((data: ProductUnpublishFormData) => {
        setOpen(false);
        onSubmit(data);
    }, [onSubmit, setOpen]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            classes={{
                paper: style.dialog
            }}>

            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Unpublish this product?" />
                </DialogTitle>
            </div>
            <ProductUnpublishFormViewer productName={productName} env={env} onCancel={handleClose} onSubmit={handleSubmit} />
        </Dialog>
    );
});

ProductEnvironmentConfirmationDialog.propTypes = {
    productName: PropTypes.string.isRequired,
    env: PropTypes.oneOf<ProductEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired
}

export default memo(ProductEnvironmentConfirmationDialog);

