import React, { ReactNode, memo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import clsx from 'clsx';

interface ActionInlinerStyleOptions {
    align?: "left" | "right" | "center"
}

const useStyles = (options: ActionInlinerStyleOptions) => makeStyles(theme =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: options.align === 'right' ? 'flex-start' : options.align === 'center' ? 'center' : 'flex-end',
            '& > *:not(:first-of-type)': {
                marginLeft: theme.spacing(2)
            },
        },
        insetPadding: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        },        
        insetTopPadding: {
            paddingTop: theme.spacing(3)
        }
    }),
);

interface ActionInlinerProps {
    children: ReactNode,
    align?: "left" | "right" | "center",
    padding?: "inset" | "inset-top"
}

const ActionInliner = (props: ActionInlinerProps) => {
    const style = useStyles({
        align: props.align
    })();

    return (<div className={clsx(style.root, {
        [style.insetPadding]: props.padding === "inset",
        [style.insetTopPadding]: props.padding === "inset-top"
    })}>{props.children}</div>)
};
ActionInliner.propTypes = {
    children: PropTypes.any.isRequired,
    padding: PropTypes.oneOf(["inset", "inset-top"]),
    align: PropTypes.oneOf(["left", "right", "center"])
};

export default memo(ActionInliner);