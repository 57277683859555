import { CertificateDeleteFormData } from "../models/certificate.model";
import CertificateService, {ICertificate} from "../CertificateService";
import { FC, useState, useContext, ReactElement } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { FormApi, FORM_ERROR } from "final-form";
import { FormRenderProps, Field } from "react-final-form";
import { ValidatorBuilderContext } from "../../../../legacy-components/form/validator/Validator";
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Button, InputAdornment } from "@material-ui/core";
import FormContainer from "../../../../legacy-components/form/container/FormContainer";
import React from "react";
import FormInputContainer from "../../../../legacy-components/form/container/FormInputContainer";
import FormTextInput from "../../../../legacy-components/form/input/FormTextInput";
import ActionInliner from "../../../../legacy-components/form/action/ActionInliner";
import { StatusBadgeInfo, StatusBadge } from "../../../../legacy-components/badges/StatusBadge";
import { WarnButton } from "../../../../legacy-components/form/action/WarnButton";

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            padding: spacing(3)
        },
    }),
);
interface CertificateDeleteFormProps {

    onCancel: () => void,
    onSuccess: (data: CertificateDeleteFormData) => void
    certificate: ICertificate,
    formContent?: ReactElement
}

const CertificateDeleteForm: FC<CertificateDeleteFormProps> = ({ onCancel, onSuccess, certificate, formContent }) => {
    const intl = useIntl();
    const styles = useStyles();
   
    const onSubmit = () => (data: CertificateDeleteFormData, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                if (data.action === "DELETE") {
                    CertificateService.forDeleteCertificateInfo({
                        env: certificate?.environment,
                        certId: certificate?.certId
                        })
                        .then(() => {
                            resolve();
                        }).catch((error) => {
                            resolve({
                                [FORM_ERROR]: error ?? "Unknown error occured while deleting the certificate"
                            });
                        });
                } else {
                    resolve({
                        action: intl.formatMessage({
                            defaultMessage: "Action didn't match"
                        })
                    })
                }
            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        const validator = useContext(ValidatorBuilderContext);
        const [deploymentStatus] = useState<StatusBadgeInfo>({
            kind: "access",
            key: certificate.environment,
            access: false
        });

        const handleChange = (e: any) => {
            e.preventDefault();
          };

        return (
            <div className={styles.root}>
                {formContent}

                <FormInputContainer>
                    <Field
                        name="action"
                        type="text"
                        onCut={handleChange}
                        onCopy={handleChange}
                        onPaste={handleChange}
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Action"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "Type DELETE"
                        })}
                        validate={validator.from({
                            required: true
                        })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <StatusBadge status={deploymentStatus} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormInputContainer>

                <ActionInliner padding="inset-top">
                    <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                    <WarnButton disabled={formRenderProps.submitting || formRenderProps.pristine} type="submit" variant="contained" color="primary" disableElevation>
                        <FormattedMessage defaultMessage="Delete" />
                    </WarnButton>
                </ActionInliner>
            </div>);
    }

    return <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

CertificateDeleteForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

export default CertificateDeleteForm;