import React, { PropsWithChildren, useState, ReactElement, memo, useCallback, ChangeEvent } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles, Accordion, AccordionSummary, AccordionDetails, Divider, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            width: '100%',
            paddingBottom: spacing(3)
        },

        details: {
            padding: 0
        },

        accordionExpanded: {
            "&--has-next:not(.Mui-expanded)": {
                borderBottomLeftRadius: spacing(1),
                borderBottomRightRadius: spacing(1)
            },
            "&--has-prev:not(.Mui-expanded)": {
                borderTopLeftRadius: spacing(1),
                borderTopRightRadius: spacing(1)
            }
        },

        summary: {
            position: "relative"
        }
    }),
);

export const useAccordionStyle = makeStyles(({ typography, transitions, spacing }) =>
    createStyles({
        summary: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignContent: "center",
            margin: 0,
            '.MuiAccordionSummary-content.Mui-expanded & $summary': {
                "&__title": {
                    fontSize: typography.h5.fontSize,
                    fontWeight: typography.h5.fontWeight,
                }
            },

            "&__title": {
                fontSize: typography.body2.fontSize,
                transition: transitions.create('font-size'),
                textTransform: 'capitalize', 
            },

            "&__controls": {
                paddingRight: spacing(1)
            }
        },
        content: {
            width: "100%",

            "&:last-of-type": {
                paddingBottom: spacing(1),
            },

            "&__layout": {
                width: "100%",
                padding: spacing(2, 3, 2, 3),               

                "&--small": {
                    "& $content": {
                        "&__header": {
                            height: "24px"                            
                        }
                    }
                },

                "&--medium": {

                }
            },

            "&__header": {
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                height: "36px",
                marginBottom: spacing(2),

                "&-title": {
                    overflow: "hidden"
                },

                "&-controls": {
                    position: "relative"
                }
            },

            "&__section": {
                padding: spacing(1, 0, 0, 0),
            }
        }
    }),
);

interface AccordionSummaryTitleProps {
    controls?: ReactElement | null
}

export const AccordionSummaryTitle = memo(({ children, controls }: PropsWithChildren<AccordionSummaryTitleProps>) => {
    const styles = useAccordionStyle();

    return (
        <div className={styles.summary}>
            <div className={`${styles.summary}__title`}>
                {children}
            </div>

            {
                controls && <div className={`${styles.summary}__controls`}>
                    {controls}
                </div>
            }
        </div>
    );
});

interface AccordionContentProps {
    controls?: ReactElement | null,
    actions?: ReactElement | null,
    header?: ReactElement | null,
    size?: "medium" | "small"
}

export const AccordionContent = memo(({ children, controls, header, actions, size }: PropsWithChildren<AccordionContentProps>) => {
    const styles = useAccordionStyle();

    return (
        <div className={styles.content}>
            <Divider />

            <div className={clsx(`${styles.content}__layout`, {
                [`${styles.content}__layout--small`]: size === "small"
            })}>
                {(header || controls) &&
                    (
                        <div className={`${styles.content}__header`}>
                            <div className={`${styles.content}__header-title`}>
                                <Typography className="highlight-subtitle" component="div" variant={size === "small" ? "subtitle2" : "h6"}>
                                    {header}
                                </Typography>
                            </div>

                            <div className={`${styles.content}__header-controls`}>
                                {controls}
                            </div>
                        </div>
                    )
                }

                <div className={`${styles.content}__section`}>
                    {children}
                </div>

                {actions &&
                    <div className={`${styles.content}__actions`}>
                        {actions}
                    </div>
                }
            </div>
        </div>
    );
});


interface AccordionSectionProps {
    id: string;
    children: ReactElement;
    summary: ReactElement;
}

interface AccordionFormProps {
    id: string;
}

function AccordionForm({ id, children }: PropsWithChildren<AccordionFormProps>) {
    const styles = useStyles();

    const [sections] = useState(React.Children.toArray(children) as React.ReactElement<AccordionSectionProps>[]);

    const [expandedMap, setExpanedMap] = useState<{
        [key: number]: boolean
    }>({});

    const onChange = useCallback((id: string, index: number) => (_event: ChangeEvent<{}>, newExpaned: boolean) => {
        setExpanedMap(value => {
            return {
                ...value,
                [index]: newExpaned
            }
        })
    }, [setExpanedMap]);

    const onHandleChange = useCallback((id: string, index: number) => onChange(id, index), [onChange]);

    return (
        <div key={id} className={styles.root}>
            {sections.map(({ props }, index) => {
                return (
                    <Accordion defaultExpanded className={clsx({
                        [`${styles.accordionExpanded}--has-prev`]: expandedMap[index - 1] ?? true,
                        [`${styles.accordionExpanded}--has-next`]: expandedMap[index + 1] ?? true
                    })} key={props.id} onChange={onHandleChange(props.id, index)}>
                        <AccordionSummary
                            className={styles.summary}
                            expandIcon={<ExpandMoreIcon />}
                            id={props.id}
                        >
                            {props.summary}
                        </AccordionSummary>

                        <AccordionDetails className={styles.details}>
                            {props.children}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
    )
}

AccordionForm.Section = ({ children }: AccordionSectionProps) => children;

AccordionForm.propTypes = {
    id: PropTypes.string.isRequired
}

export default memo(AccordionForm);

