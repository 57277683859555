import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const NavigationSkeleton = () => {
    return (
        <Box>
        {(new Array(10)).fill(0).map((_, index) => (
            <Box mt={1} flexDirection={"row"} display={"flex"} key={index}>
                <Box mr="10px" p="10px 15px">
                    <Skeleton animation="wave" variant="circle" width={24} height={24} style={{ backgroundColor: '#ffffff40' }}/>
                </Box>
                <Box mr="10px" mt={'10px'} >
                    <Skeleton animation="wave" variant="text" width={"209px"} height={12} style={{ backgroundColor: '#ffffff40' }} />
                </Box>
            </Box>
            )
            )
        }
        </Box>
    )
}

export default NavigationSkeleton;