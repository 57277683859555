import React, { useCallback, ReactElement, FC, useState, useRef, useContext } from 'react';
import PublisherApplicationService, { IdentityApplicationEnvironmentType, IdentityApplicationPublishedGateway, IdentityApplication, IdentityApplicationEnvironmentAuthority, IdentityApplicationTypes, IdentityApplicationSPMetadata } from "../PublisherApplicationService";
import { StatusBadgeInfo } from "../../../../../legacy-components/badges/StatusBadge";
import { useReducer, useEffect } from "react";
import { useResourceViewElements } from "../../../../../legacy-components/resource-view/ResourceView";
import { NEVER } from "rxjs";
import FormViewInliner, { FormViewInlinerVariant } from "../../../../../legacy-components/form/container/FormViewInliner";
import { FormattedMessage, FormattedList } from 'react-intl';
import { ApplicationGrantTypeViewer, useApplicationConfigurationInfo } from '../application-form/ApplicationConfigurationInfo';
import { Typography, makeStyles, createStyles, Tooltip, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Switch, Button, CircularProgress, Box } from '@material-ui/core';
import OpenInNewTabLink from '../../../../../legacy-components/form/action/OpenInNewTabLink';
import CopyToClipboard, { AsyncCopyToClipboard } from '../../../../../legacy-components/form/action/CopyToClipboard';
import NAWrap from '../../../../../legacy-components/form/action/NAWrap';
import HighlightWrap from '../../../../../legacy-components/form/action/HighlightWrap';
import SecretWrap from '../../../../../legacy-components/form/action/SecretWrap';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CertificateIcon from '../../../../../legacy-components/icons/CertificateIcon';
import InlineWrap from '../../../../../legacy-components/form/action/InlineWrap';
import UnfoldLess from '@material-ui/icons/UnfoldLess';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import { AsyncAutoLoader, useAsyncAction } from 'legacy-components/form/action/AsyncAction';
import Skeleton from '@material-ui/lab/Skeleton';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { ResourceAbility } from 'libs/security/authorization';
import IdentityApplicationSecretDeleteDialog, { IdentityApplicationSecretDeleteDialogRef } from '../IdentityApplicationSecretDeleteDialog';
import IdentityApplicationService, { IdentityApplicationSecretInfo } from '../IdentityApplicationService';
import IdentityApplicationScopeValidationDialog, { IdentityApplicationScopeValidationDialogRef } from '../IdentityApplicationScopeValidationDialog';
import { IdentityApplicationViewContext } from '../IdentityApplicationView';
import { ApplicationConfigurationFormData } from '../models/application.model';
import { SnackNotificationContext } from 'legacy-components/notifications/GenericSnackNotification';

const useClientSecretControlsStyles = makeStyles(({ spacing }) =>
    createStyles({
        secretView: {
            marginRight: spacing(1)
        }
    }),
);

const useSAMLStyles = makeStyles(({ palette }) =>
    createStyles({
        certificateIcon: {
            color: palette.success.main
        }
    }),
);

const useSecretStyles = makeStyles(({ palette }) =>
    createStyles({
        keyIcon: {
            color: palette.success.main
        }
    }),
);

const useSecretSkeletonStyles = makeStyles(({ spacing }) =>
    createStyles({
        spacer: {
            padding: spacing(0, 0.5)
        }
    }),
);


export type IdentityApplicationPublishStatus = {
    [key in IdentityApplicationEnvironmentType]?: IdentityApplicationPublishedGateway;
}

export interface IdentityApplicationUpdateAction {
    type: "applicationUpdate",
    payload: {
        application: IdentityApplication
    }
}

export interface IdentityApplicationViewState {
    publishStatus: IdentityApplicationPublishStatus | null;
    application: IdentityApplication | null;
    statusBadge: StatusBadgeInfo[] | null;
    publishedGateway: IdentityApplicationPublishedGateway | null;
}

export type IdentityApplicationViewAction = IdentityApplicationUpdateAction;

const createLink = (url: string) => (<OpenInNewTabLink key={url} href={url}>{url}</OpenInNewTabLink>);

export const createIdentityApplicationPublishStatus = (publishedGateway: IdentityApplicationPublishedGateway[]) => {
    return publishedGateway?.reduce((agg: IdentityApplicationPublishStatus, value) => {

        if (value.organisationName && value) {
            agg[value.organisationName] = value;
        }

        return agg;
    }, {}) ?? null;
};

const createStatusBadge = (publishStatus: IdentityApplicationPublishStatus | null): StatusBadgeInfo[] => {
    return Object.keys(publishStatus ?? {}).reduce((agg: StatusBadgeInfo[], key) => {

        agg.push({
            kind: "access",
            access: true,
            key
        })

        return agg;
    }, []);
}

function applicationViewReducer(state: IdentityApplicationViewState, action: IdentityApplicationViewAction) {
    switch (action.type) {
        case "applicationUpdate": {
            const publishStatus = createIdentityApplicationPublishStatus(action.payload.application.applicationGatewayResponses ?? []);

            return {
                ...state,
                application: action.payload.application,
                publishStatus,
                statusBadge: createStatusBadge(publishStatus),
                publishedGateway: publishStatus[action.payload.application.organisationName] ?? null
            }
        }

        default: return state
    }
}

export interface IdentityApplicationPublishedStatus {
    applicationName?: string;
    publishedGateway?: IdentityApplicationPublishedGateway;
    env?: IdentityApplicationEnvironmentType;
    redirectUrls: ReactElement[];
    logoutUrls: ReactElement[];
    applicationType?: IdentityApplicationTypes;
    samlSpMetadata?: IdentityApplicationSPMetadata;
    enforceScopeValidation?: boolean;
}

export const createIdentityApplicationPublishedStatus = (state: IdentityApplicationViewState | null): IdentityApplicationPublishedStatus => {
    const applicationName = state?.application?.applicationName;
    const publishedGateway = state?.publishedGateway ?? undefined;

    return {
        applicationName,
        publishedGateway,
        env: state?.application?.organisationName,
        redirectUrls: (publishedGateway?.redirectUri ?? []).map(createLink),
        logoutUrls: (publishedGateway?.logoutUri ?? []).map(createLink),
        applicationType: state?.application?.applicationType?.name,
        samlSpMetadata: state?.application?.samlSpMetadata,
        enforceScopeValidation: state?.publishedGateway?.enforceScopeValidation
    }
};

export const useIdentityApplicationInfo = ({ applicationName, environmentName }: {
    applicationName?: string,
    environmentName?: IdentityApplicationEnvironmentType
}) => {
    const [state, dispatch] = useReducer(applicationViewReducer, {
        publishStatus: null,
        application: null,
        statusBadge: null,
        publishedGateway: null
    });

    const { response } = useResourceViewElements({
        resolverFn: () => {
            return applicationName && environmentName ? PublisherApplicationService.environments[environmentName].get(applicationName) : NEVER
        }
    })

    useEffect(() => {
        if (response.loaded && response.value) {
            dispatch({
                type: "applicationUpdate",
                payload: {
                    application: response.value
                }
            })
        }

    }, [response]);

    return {
        state,
        dispatch,
        response,
    }
}

export const CopyIdentityApplicationValues: FC<{ value?: string }> = ({ value }) => {
    return (
        value ? <CopyToClipboard size="small" edge="end" text={value} /> : <></>
    )
};

const ClientSecretViewControls: FC<{
    applicationName: string,
    env: IdentityApplicationEnvironmentType,
    reveled: boolean,
    secretId: string,
    onToggle: () => void,
    onDelete: ((secretId: string) => void) | null
}> = ({
    applicationName,
    env,
    reveled,
    secretId,
    onToggle,
    onDelete
}) => {
        const styles = useClientSecretControlsStyles();
        const instance = useAsyncAction(() => IdentityApplicationService.environments[env].getSecretValue(applicationName, secretId), {
            mapFn: response => response.secret
        })

        const dialogRef = useRef<IdentityApplicationSecretDeleteDialogRef>();
        const deleteHandler = useCallback(() => dialogRef.current?.open(), [dialogRef]);
        const submitHandler = useCallback(() => onDelete?.(secretId), [onDelete, secretId]);

        return (
            <>
                <Tooltip enterNextDelay={1000} title={reveled ? <FormattedMessage defaultMessage="Hide Client Secret" /> : <FormattedMessage defaultMessage="Revel Client Secret" />}>
                    <span className={styles.secretView}>
                        <IconButton size={"small"} onClick={onToggle}>
                            {reveled ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                        </IconButton>
                    </span>
                </Tooltip>

                <AsyncCopyToClipboard className={styles.secretView} size="small" edge="end" instance={instance} />

                {onDelete &&
                    <ResourceAbility can="delete" resource={({ Application }) => Application.create.environment[env]}>
                        <>
                            <IdentityApplicationSecretDeleteDialog onSubmit={submitHandler} env={env} applicationName={applicationName} ref={dialogRef} secretId={secretId} />

                            <Tooltip enterNextDelay={1000} title={<FormattedMessage defaultMessage="Delete Secret" />}>
                                <span>
                                    <IconButton onClick={deleteHandler} size={"small"}>
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </>
                    </ResourceAbility>
                }
            </>
        )
    }

const SAMLCertViewControls: FC<{
    expanded: boolean,
    value?: string,
    onToggle: () => void
}> = ({
    expanded,
    value,
    onToggle,
}) => {
        const styles = useClientSecretControlsStyles();

        return (
            <>
                <Tooltip enterNextDelay={1000} title={<FormattedMessage defaultMessage="Show Full Certificate" />}>
                    <span className={styles.secretView}>
                        <IconButton size={"small"} onClick={onToggle}>
                            {expanded ? <UnfoldLess /> : <UnfoldMore />}
                        </IconButton>
                    </span>
                </Tooltip>

                <CopyIdentityApplicationValues value={value} />
            </>
        )
    }


interface SecretViewState {
    secrets: IdentityApplicationSecretInfo[];
    status: string,
    disableAdd: boolean,
    disableDelete: boolean
}

const secretViewInitialState: SecretViewState = {
    secrets: [],
    status: "init",
    disableAdd: true,
    disableDelete: true
};

interface SecretViewLoadingAction {
    type: "loaded",
    payload: {
        status: "loaded",
        secrets: IdentityApplicationSecretInfo[];
    }
}

interface SecretViewCreateAction {
    type: "create"
}

interface SecretViewDeletedAction {
    type: "deleted"
    payload: {
        status: "loaded",
        secretId: string;
    }
}

interface SecretViewAddSecretAction {
    type: "add",
    payload: {
        status: "loaded",
        secret: IdentityApplicationSecretInfo;
    }
}

const computeSecretViewState = (secrets: SecretViewState["secrets"], status: string): SecretViewState => {
    return {
        secrets,
        disableAdd: secrets.length >= 5,
        disableDelete: secrets.length <= 1,
        status
    }
}

type SecretViewAction = SecretViewLoadingAction | SecretViewCreateAction | SecretViewAddSecretAction | SecretViewDeletedAction;

const secretViewReducer = (state: SecretViewState, action: SecretViewAction): SecretViewState => {
    switch (action.type) {
        case "loaded": {
            return computeSecretViewState([...action.payload.secrets], action.payload.status)
        }
        case "create": {
            return {
                ...state,
                disableAdd: true,
                disableDelete: true,
                status: "create"
            }
        }
        case "add": {
            return computeSecretViewState([...state.secrets, action.payload.secret], action.payload.status)
        }
        case "deleted": {
            return computeSecretViewState(state.secrets.filter(value => value.id !== action.payload.secretId), action.payload.status)
        }

        default:
            return state
    }
}

const IdentityApplicationClientSecretView: FC<{
    applicationName: string,
    env: IdentityApplicationEnvironmentType,
    variant?: FormViewInlinerVariant,
    allowModify?: boolean
}> = ({ applicationName, env, variant, allowModify }) => {
    const asyncState = useAsyncAction(() => IdentityApplicationService.environments[env].getSecretMetadata(applicationName))

    const [state, dispatch] = useReducer(secretViewReducer, {
        ...secretViewInitialState
    });

    useEffect(() => {
        if (asyncState[0].status === "loaded") {
            dispatch({
                type: "loaded",
                payload: {
                    secrets: asyncState[0].value ?? [],
                    status: "loaded"
                }
            });
        }
    }, [asyncState]);

    const onCreate = useCallback(() => {
        dispatch({
            type: "create"
        })

        IdentityApplicationService.environments[env].createSecret(applicationName)
            .then(value => {
                dispatch({
                    type: "add",
                    payload: {
                        secret: {
                            id: value.id,
                        },
                        status: "loaded"
                    }
                })
            });
    }, [dispatch, env, applicationName]);

    const onDelete = useCallback((secretId: string) => {
        dispatch({
            type: "deleted",
            payload: {
                secretId,
                status: "loaded"
            }
        })
    }, [dispatch])

    return (
        <AsyncAutoLoader instance={asyncState} loader={<ClientSecretViewerSkeleton variant={variant} />}>
            <>
                {
                    state.secrets.map((secret, index) => (
                        <ClientSecretViewer onDelete={allowModify && !state.disableDelete ? onDelete : null} applicationName={applicationName} env={env} variant={variant} key={secret.id} value={secret.id} showTitle={index === 0} />
                    ))
                }

                <NAWrap value={allowModify}>
                    <ResourceAbility can="delete" resource={({ Application }) => Application.create.environment[env]}>
                        <FormViewInliner variant={variant} gutter="gutter-bottom">
                            <Button onClick={onCreate} startIcon={state.status === "create" ? <CircularProgress size="16px" /> : <AddIcon />} disabled={state.disableAdd} disableElevation variant="contained" size="small">
                                <FormattedMessage defaultMessage="Add Secret" />
                            </Button>
                        </FormViewInliner>
                    </ResourceAbility>
                </NAWrap>

            </>
        </AsyncAutoLoader>
    );
}

const ClientSecretValueViewerSkeleton = () => {
    const styles = useSecretSkeletonStyles();

    return (
        <Box display="flex" flex="row" alignItems="center">
            <Skeleton width="80px" />
            <div className={styles.spacer}> </div>
            <Skeleton width="80px" />
            <div className={styles.spacer}> </div>
            <Skeleton width="80px" />
            <div className={styles.spacer}> </div>
            <Skeleton width="80px" />
        </Box>
    );
}

const ClientSecretValueViewer: FC<{
    applicationName: string,
    env: IdentityApplicationEnvironmentType,
    secretId: string
}> = ({ applicationName, env, secretId }) => {

    const asyncInstance = useAsyncAction(() => IdentityApplicationService.environments[env].getSecretValue(applicationName, secretId))

    return (
        <AsyncAutoLoader instance={asyncInstance} loader={<ClientSecretValueViewerSkeleton />}>
            <code>{asyncInstance[0].value?.secret}</code>
        </AsyncAutoLoader>
    );
}

const ClientSecretViewerSkeleton: FC<{
    variant?: FormViewInlinerVariant
}> = ({
    variant
}) => {
        return (
            <>
                <FormViewInliner variant={variant} gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Client Secrets" />}>
                    <Typography variant="body1">
                        <Skeleton width="240px" />
                    </Typography>
                </FormViewInliner>

                <FormViewInliner variant={variant} gutter="gutter-bottom">
                    <Typography variant="body1">
                        <Skeleton width="240px" />
                    </Typography>
                </FormViewInliner>
            </>
        )
    }

const ClientSecretViewer: FC<{
    applicationName: string,
    env: IdentityApplicationEnvironmentType,
    showTitle: boolean,
    value: string,
    variant?: FormViewInlinerVariant,
    onDelete: ((secretId: string) => void) | null
}> = ({
    applicationName,
    env,
    showTitle,
    value,
    variant,
    onDelete
}) => {
        const styles = useSecretStyles();
        const [revel, setRevel] = useState(false);
        const toggleHandler = useCallback(() => setRevel(value => !value), []);

        return (
            <FormViewInliner variant={variant} gutter="gutter-bottom" title={showTitle ? <FormattedMessage defaultMessage="Client Secrets" /> : undefined} controls={<ClientSecretViewControls onDelete=
                {onDelete} reveled={revel} onToggle={toggleHandler} applicationName={applicationName} env={env} secretId={value} />}>
                <Typography variant="body1">
                    <NAWrap value={value} showMessage={true}>
                        <HighlightWrap icon={<VpnKeyOutlinedIcon className={styles.keyIcon} />}>
                            <SecretWrap length={32} revel={revel}>
                                <ClientSecretValueViewer applicationName={applicationName} env={env} secretId={value} />
                            </SecretWrap>
                        </HighlightWrap>
                    </NAWrap>
                </Typography>
            </FormViewInliner>
        )
    }

const DomainViewer: FC<{
    env: IdentityApplicationEnvironmentType,
    variant?: FormViewInlinerVariant
}> = ({ env, variant }) => {
    const [domain] = useState(IdentityApplicationEnvironmentAuthority[env])

    return (
        <FormViewInliner variant={variant} gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Domain" />} controls={<CopyIdentityApplicationValues value={domain} />}>
            <Typography variant="body1">
                <NAWrap value={domain} showMessage={true}>
                    {domain}
                </NAWrap>
            </Typography>
        </FormViewInliner>
    )
}

export const IdentityApplicationOAuthView: FC<{
    status: IdentityApplicationPublishedStatus
}> = ({ status }) => {
    return (
        <>
            <FormViewInliner variant={status.publishedGateway ? "block" : "inline"} gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Grant Types" />}>
                <ApplicationGrantTypeViewer publishedInfo={status.publishedGateway} />
            </FormViewInliner>

            {!!status.redirectUrls.length && <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Allowed Callback URLs" />}>
                <Typography variant="body1">
                    <FormattedList value={status.redirectUrls} type="conjunction" />
                </Typography>
            </FormViewInliner>}

            {!!status.logoutUrls.length && <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Allowed Logout URLs" />}>
                <Typography variant="body1">
                    <FormattedList value={status.logoutUrls} type="conjunction" />
                </Typography>
            </FormViewInliner>}
        </>
    )
}

const ScopeValidationNotificationMessage = () => {
    return (
        <FormattedMessage defaultMessage="Scope Validation Enabled" />
    )
}

const ScopeValidationListItem: FC<{
    status: IdentityApplicationPublishedStatus,
    primary: ReactElement | string,
    secondary: ReactElement | string
}> = ({ status , primary, secondary}) => {
    const applicationViewContext = useContext(IdentityApplicationViewContext);
    const notificationContext = useContext(SnackNotificationContext);
    const dialogRef = useRef<IdentityApplicationScopeValidationDialogRef>();

    const scopeHandler = useCallback(() => dialogRef.current?.open(), [dialogRef]);

    const onSuccess = useCallback((data: ApplicationConfigurationFormData) => {
        if (data.__applicationPublishInfo?.application) {
            notificationContext.show({
                id: "application-scope-validation-enable",
                message: <ScopeValidationNotificationMessage />
            });

            applicationViewContext.dispatch({
                type: "applicationUpdate",
                payload: {
                    application: data.__applicationPublishInfo.application
                }
            })
        }

    }, [notificationContext, applicationViewContext]);

    return (
        <>
            <IdentityApplicationScopeValidationDialog onSubmit={onSuccess}  application={applicationViewContext.state?.application ?? undefined} ref={dialogRef} />
            <ListItem disableGutters={true} dense>
                <ListItemText primary={primary} secondary={secondary} />
                <ListItemSecondaryAction>
                    <Switch
                        edge="end"
                        checked={status.enforceScopeValidation}
                        color="primary"
                        onClick={scopeHandler}
                    />
                </ListItemSecondaryAction>
            </ListItem >
        </>
    )
}

const ScopeValidationListItemDisabled: FC<{
    primary: ReactElement | string,
    secondary: ReactElement | string
}> = ({ primary, secondary }) => {
    return (
        <>
            <ListItem disableGutters={true} dense>
                <ListItemText primary={primary} secondary={secondary} />
                <ListItemSecondaryAction>
                    <Switch
                        edge="end"
                        checked={true}
                        color="primary"
                        disabled={true}
                    />
                </ListItemSecondaryAction>
            </ListItem >
        </>
    )
}

export const IdentityApplicationScopeValidationView: FC<{
    status: IdentityApplicationPublishedStatus
}> = ({ status}) => {
    const { availableScopeValidationMethods } = useApplicationConfigurationInfo();

    return (
        <>
            <List disablePadding={true} >
                {
                    availableScopeValidationMethods.map((scopeValidation, index) => (status.enforceScopeValidation ? <ScopeValidationListItemDisabled primary={scopeValidation.title} secondary={scopeValidation.helperText} key={index}/>: <ScopeValidationListItem primary={scopeValidation.title} secondary={scopeValidation.helperText} key={index} status={status} />))
                }
            </List>
        </>
    )
}

export const IdentityApplicationSAMLView: FC<{
    status: IdentityApplicationPublishedStatus
}> = ({ status: { samlSpMetadata, applicationName } }) => {
    const styles = useSAMLStyles();

    const [expand, setExpand] = useState(false);
    const toggleHandler = useCallback(() => setExpand(value => !value), []);

    return (
        <>
            <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Entity ID" />} controls={<CopyIdentityApplicationValues value={applicationName} />}>
                <Typography variant="body1">
                    <NAWrap value={applicationName} showMessage={true}>
                        {applicationName}
                    </NAWrap>
                </Typography>
            </FormViewInliner>

            <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Consumer URL" />} controls={<CopyIdentityApplicationValues value={samlSpMetadata?.assertionConsumerUrl ?? ""} />}>
                <Typography variant="body1">
                    <NAWrap value={samlSpMetadata?.assertionConsumerUrl} showMessage={true}>
                        {
                            samlSpMetadata?.assertionConsumerUrl && <OpenInNewTabLink href={samlSpMetadata?.assertionConsumerUrl}>
                                {samlSpMetadata?.assertionConsumerUrl}
                            </OpenInNewTabLink>
                        }
                    </NAWrap>
                </Typography>
            </FormViewInliner>

            <FormViewInliner variant="block" gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Signing Certificate" />} controls={<SAMLCertViewControls expanded={expand} onToggle={toggleHandler} value={samlSpMetadata?.x509RequestSigningCert} />}>
                <Typography variant="body2">
                    <NAWrap value={samlSpMetadata?.x509RequestSigningCert} showMessage={true}>
                        <HighlightWrap icon={<CertificateIcon className={styles.certificateIcon} />}>
                            <InlineWrap content={samlSpMetadata?.x509RequestSigningCert} className="use-code-font" expand={expand} />
                        </HighlightWrap>
                    </NAWrap>
                </Typography>
            </FormViewInliner>
        </>
    )
}

export const IdentityApplicationCredentialsView: FC<{
    applicationName?: string,
    publishedGateway: IdentityApplicationPublishedGateway,
    variant?: FormViewInlinerVariant,
    allowModify?: boolean
}> = ({ applicationName, publishedGateway, variant, allowModify }) => {
    return (
        <>
            <DomainViewer variant={variant} env={publishedGateway.organisationName} />

            <FormViewInliner variant={variant} gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Client ID" />} controls={<CopyIdentityApplicationValues value={publishedGateway.consumerKey} />}>
                <Typography variant="body1">
                    <NAWrap value={publishedGateway.consumerKey} showMessage={true}>
                        <HighlightWrap>
                            <code>{publishedGateway.consumerKey}</code>
                        </HighlightWrap>
                    </NAWrap>
                </Typography>
            </FormViewInliner>

            <NAWrap value={applicationName}>
                <IdentityApplicationClientSecretView allowModify={allowModify} variant={variant} env={publishedGateway.organisationName} applicationName={applicationName ?? ""} />
            </NAWrap>
        </>
    )
}