import React, { FC, useContext, useState, ReactElement } from 'react'
import FormContainer from '../../../../legacy-components/form/container/FormContainer';
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import { FormRenderProps, Field } from 'react-final-form';
import { Button, InputAdornment, makeStyles, createStyles } from '@material-ui/core';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { FormApi, FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import FormTextInput from '../../../../legacy-components/form/input/FormTextInput';
import { ValidatorBuilderContext } from '../../../../legacy-components/form/validator/Validator';
import PropTypes from 'prop-types';
import { StatusBadge, StatusBadgeInfo } from '../../../../legacy-components/badges/StatusBadge';
import { WarnButton } from '../../../../legacy-components/form/action/WarnButton';
import { ProductUnpublishFormData } from '../models/products.model';
import ProductService, { ProductEnvironmentType } from '../../../../libs/resources/product/ProductService';

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            padding: spacing(3)
        },
    }),
);

interface ProductUnpublishFormProps {
    onCancel: () => void,
    onSuccess: (data: ProductUnpublishFormData) => void
    productName: string,
    env: ProductEnvironmentType,
    formContent?: ReactElement
}

const ProductUnpublishForm: FC<ProductUnpublishFormProps> = ({ onCancel, onSuccess, productName, env, formContent }) => {
    const intl = useIntl();
    const styles = useStyles();

    const onSubmit = () => (data: ProductUnpublishFormData, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                if (data.productName === productName) {
                    ProductService.environments[env]?.unpublish(productName)
                        .then(
                            () => resolve(),
                            error => resolve({
                                [FORM_ERROR]: error
                            }));
                } else {
                    resolve({
                        productName: intl.formatMessage({
                            defaultMessage: "Product name didn't match"
                        })
                    })
                }
            }
        })
    }


    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        const validator = useContext(ValidatorBuilderContext);
        const [publishStatus] = useState<StatusBadgeInfo>({
            kind: "access",
            key: env,
            access: false
        });

        return (
            <div className={styles.root}>
                {formContent}

                <FormInputContainer>
                    <Field
                        name="productName"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Product Name"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "Type the product name"
                        })}
                        validate={validator.from({
                            required: true
                        })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <StatusBadge status={publishStatus} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormInputContainer>

                <ActionInliner padding="inset-top">
                    <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                    <WarnButton disabled={formRenderProps.submitting || formRenderProps.pristine} type="submit" variant="contained" color="primary" disableElevation>
                        <FormattedMessage defaultMessage="Unpublish" />
                    </WarnButton>
                </ActionInliner>
            </div>);
    }

    return <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

ProductUnpublishForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    productName: PropTypes.string.isRequired,
    env: PropTypes.oneOf<ProductEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired
}

export default ProductUnpublishForm;