import React, { Ref, FC, PropsWithRef, forwardRef, useCallback, useImperativeHandle, memo } from "react";
import { Dialog, DialogTitle, Divider, makeStyles, createStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { ProductDocumentFieldFileInfo } from "./ProductDocumentField";
import { useCompactDialog } from "../../../../../legacy-components/dialog/UseCompactDialog";
import ProductDocumentPublishForm from "./ProductDocumentPublishForm";
import { ProductDocumentPublishedData } from "../../models/products.model";

const useStyles = makeStyles(() => {
    return createStyles({
        header: {
            position: "relative"
        }
    })
});

export interface ProductDocumentPublishDialogRef {
    open(): void
}

interface ProductDocumentPublishDialogProps {
    ref?: Ref<ProductDocumentPublishDialogRef | undefined>
    onChange: (data: ProductDocumentPublishedData) => void;
    docInfo: ProductDocumentFieldFileInfo;
    productName: string;
}

interface ProductDocumentPublishViewerProps {
    docInfo: ProductDocumentFieldFileInfo;
    productName: string;
    onChange: (data: ProductDocumentPublishedData) => void;
}

const ProductDocumentPublishFormContent = ({ docName }: {
    docName: string
}) => {

    const docNameFormatter = () => <strong>{docName}</strong>;

    return (
        <Typography variant="body1" component="p">
            <FormattedMessage values={{
                docName: docNameFormatter
            }} defaultMessage="<docName>name</docName> will be used as the documentation for the product in the following selected environments" />
        </Typography>
    )
}


const ProductDocumentPublishViewer = ({ docInfo, onChange, productName }: ProductDocumentPublishViewerProps) => {

    return (
        <ProductDocumentPublishForm productName={productName} formContent={<ProductDocumentPublishFormContent docName={docInfo.fileInfo.filename} />} docInfo={docInfo} onChange={onChange} />
    )
}

const ProductDocumentPublishDialog: FC<PropsWithRef<ProductDocumentPublishDialogProps>> = forwardRef(({  docInfo, productName, onChange }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleChange = useCallback((data: ProductDocumentPublishedData) => {
        onChange(data);
        setOpen(false);
    }, [onChange, setOpen]);
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >

            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Visibility Configuration" />
                </DialogTitle>
                <Divider />
            </div>
            <ProductDocumentPublishViewer productName={productName} onChange={handleChange} docInfo={docInfo} />
        </Dialog>
    );
});

ProductDocumentPublishDialog.propTypes = {

}

export default memo(ProductDocumentPublishDialog);