import { createStyles, makeStyles } from "@material-ui/core/styles";
import type { Theme } from "@material-ui/core/styles";
import { TrimbleColors } from "legacy-components/TrimbleColors";

export const useStatusBadgeSyles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      textTransform: "uppercase",
      display: "block",
      marginTop: "13px",
      "&::first-letter": {
        textTransform: "uppercase",
      },
    },
    SUBSCRIBED: {
      backgroundColor: TrimbleColors.Green,
    },
    PENDING_REVIEW: {
      backgroundColor: TrimbleColors.Gray8,
    },
    DENIED: {
      backgroundColor: TrimbleColors.Red
    },
    REVOKED: {
      backgroundColor: TrimbleColors.Red
    },
    "trimble-prod": {
      backgroundColor: TrimbleColors.GreenDark,
    },
    "trimble-pre-prod": {
      backgroundColor: TrimbleColors.Green,
    }
  })
);
