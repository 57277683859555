import {
    Box,
    Button,
    createStyles,
    makeStyles,
    MenuItem,
    Select,
  } from "@material-ui/core";
  import ActionInliner from "legacy-components/form/action/ActionInliner";
  import FormAlert from "legacy-components/form/container/FormAlert";
  import { InputContainer } from "modus/components/form/input-container/InputContainer";
  import React, { useEffect, useState } from "react";
  import { FormattedMessage, useIntl } from "react-intl";
  import { useSelector } from "react-redux";
  import { useResourceAppVersionDeploymentCreator } from "../../components/ResourceAppDeploymentCreator";
  import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { parseInteger } from "legacy-components/form/validator/Validator";
import { notBlank } from 'libs/utils/shared/common';
import Alert from "legacy-components/alert/Alert";

  const useStyles = makeStyles(() =>
    createStyles({
      formLayout: {
        display: "flex",
        width: "100%",
      },
      formContainer: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      submitLoader: {
        position: "absolute",
        zIndex: 2,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(255, 255, 255, 0.32)",
      },
    })
  );
  
  interface CreateDeploymentFormProps {
    onCancel: () => void;
    onSuccess?: () => void;
    resourceAppName: string,
    resourceAppId: string;
    version: string;
    availableRegions: { key: string; value: string; }[]

  }
  const CreateDeploymentForm: React.FC<CreateDeploymentFormProps> = ({
    onCancel,
    onSuccess,
    resourceAppName,
    resourceAppId,
    version,
    availableRegions
  }) => {

    const intl = useIntl();
    const styles = useStyles();
    const [success, setSuccess] = useState(false);
    const [loader, setLoader] = useState(false);
    
  
    const FormErrorViewer = ({ errors }: { errors?: string[] }) => {
      return (
        <>
          {errors?.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </>
      );
    };
  
  
    const canShowCreateResourceApp =
      process.env.REACT_APP_NEW_RESOURCE_APP_CREATION === "true";
    const [error, setError] = useState({
      is_error: false,
      message: "",
    });
    const [formValues, setFormValues] = useState({
      region: "",
      targetEndpoint: "",
      timeout: "",
    });
    const [hasFieldError, setHasFieldError] = useState(false); // New state to track child component validation error
    const [hasFieldError2, setHasFieldError2] = useState(false); // New state to track child component validation error
    const [hasFieldError3, setHasFieldError3] = useState(false); // New state to track child component validation error
  
    const handleSetError = (value: boolean) => {
      setHasFieldError(value); // Track error from child component
    };
  
    const handleSetError2 = (value: boolean) => {
      setHasFieldError2(value); // Track error from child component
    };
    const handleSetError3 = (value: boolean) => {
      setHasFieldError3(value); // Track error from child component
    };
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, type, checked } = e.target;
      setFormValues({
        ...formValues,
        [name]: type === "checkbox" ? checked : value,
      });
    };
    
    const [region, setRegion] = useState<string>("");
    const [targetEndpoint, setTargetEndpoint] = useState<string>("");
    const [timeout, setTimeout] = useState<number>();
  
    const onRegionChange = (event: { target: { value: any; }; }) => {
      setRegion(event?.target?.value)
    }
  
    const {createDeploymentstate, createVersionDeployment} = useResourceAppVersionDeploymentCreator(resourceAppId, version, formValues.targetEndpoint, region, (notBlank(formValues.timeout) ) ? parseInteger(formValues.timeout) : 30);
    const handleSubmit = () => {
      createVersionDeployment();
  
    };
  
    useEffect(() => {
      createDeploymentstate?.loaded && onSuccess && onSuccess();
  
    },[createDeploymentstate?.loaded, createDeploymentstate?.value])
  
    const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment);
    const endpoint = (identityEnvironment === "trimble-pre-prod" ? "https://cloud.stage.api.trimblecloud.com/".concat(resourceAppName + "/" + version)
    : "https://cloud.api.trimble.com/".concat(resourceAppName + "/" + version));
  
    return (
      <form
        className={styles.formLayout}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Box className={styles.formContainer} style={{ padding: "30px" }}>
          {loader && <Box className={styles.submitLoader}></Box>}
          {error.is_error && (
            <FormAlert severity="error">
              <FormErrorViewer errors={[error.message]} />
            </FormAlert>
          )}
          {success && (
            <FormAlert severity="success">
              {"Deployment started Successfully"}
            </FormAlert>
          )}
  {createDeploymentstate?.error && (
          <Alert id= "CREATEDEPLOYMENT" />

          )}
          <Box style={{    color: '#464B52',
            fontSize: '14px',
            lineHeight: '21px',
            paddingBottom: '7px',
            fontWeight: 600
            }}>Region&nbsp;<span style={{color:'#DA212C'}}>*</span></Box>
          <Select id="regions"
           IconComponent={ExpandMoreIcon}
           variant="outlined"
           className="header-selector resource-version-select"
           placeholder="Select the region you want to deploy"
           style={{ minWidth: "15%" }} onChange={onRegionChange}>

{        availableRegions?.map((region) => {
      return <MenuItem value={region.value} key={region.value}>
          {region.key}
        </MenuItem>
        })}
        
      
          </Select>
          <InputContainer
            label={intl.formatMessage({
              defaultMessage: "Target Endpoint",
            })}
            placeholder={intl.formatMessage({
              defaultMessage: "Enter the target backend endpoint",
            })}
            value={formValues.targetEndpoint}
            onChange={handleChange}
            name={"targetEndpoint"}
            setError={handleSetError2}
            required
          ></InputContainer>
  
          <InputContainer
            label={intl.formatMessage({
              defaultMessage: "Consumption Endpoint",
            })}
            value={endpoint}
            disabled
            onChange={handleChange}
            name={"consumptionEndpoint"}
            validate={{
              required: true,
              minLength: 3,
              maxLength: 64,
              allowSpace: true,
              pattern: "^[a-zA-Z0-9-_ ]+$",
            }}
            setError={handleSetError2}
            required
          ></InputContainer>
          <InputContainer
            label={intl.formatMessage({
              defaultMessage: "Connect Timeout",
            })}
            placeholder={intl.formatMessage({
              defaultMessage: "Enter the connection timeout",
            })}
            value={formValues.timeout}
            onChange={handleChange}
            name={"timeout"}
            validate={{
              required: true,
              allowSpace: true,
              pattern: "^[0-9]+$",
            }}
            setError={handleSetError2}
            required
          ></InputContainer>
  
          <ActionInliner padding="inset-top">
            <Button
              onClick={onCancel}
              type="button"
              variant="outlined"
              disableElevation
            >
              {" "}
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
  
            <Button
            onClick={handleSubmit}
              variant="contained"
              color="primary"
              disableElevation
              disabled={
                hasFieldError || hasFieldError2 || !formValues.targetEndpoint
              }
            >
              <FormattedMessage defaultMessage="Create" />
            </Button>
          </ActionInliner>
          <Box pb={2} />
        </Box>
      </form>
    );
  };
  
  export default CreateDeploymentForm;