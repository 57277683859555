import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { CloudConsoleClient } from "libs/utils/http/client/HttpClient";
import { APIResource, APIMethod } from "libs/utils/http/decorator/UrlDecorator";
import { IdentityApplicationPublishType, IdentityApplicationEnvironmentType, IdentityApplication, IdentityApplicationPublishRequest } from "./PublisherApplicationService";
import { from } from "rxjs";


const formatError = (error: any, defaultMessage: string) => error?.response?.data?.code !== undefined && error.response.data.message ? error.response.data.message : defaultMessage;

@APIResource(CloudConsoleClient, "publisher/applications")
class ApplicationServiceFactory {
  @APIMethod("/:resourceAppId/subscriptions")
    public getSubscriptions(resourceAppId: string){
      return from(
        CloudConsoleClient.get<any>(apiMethodContext(arguments), {
          params: {
            resourceAppId          },
        }).then((value) => value.data)
      );
    }


    @APIMethod("/:applicationName/gateway/:environmentName/:publishType")
    public publish(publishType: IdentityApplicationPublishType, environmentName: IdentityApplicationEnvironmentType, applicationName: string, request?: IdentityApplicationPublishRequest) {
        const context = apiMethodContext(arguments);
        const method = publishType === "update" ? CloudConsoleClient.put : CloudConsoleClient.post;

        return new Promise<IdentityApplication>((resolve, reject) => {
            method(context, request, {
                params: {
                    applicationName,
                    environmentName,
                    publishType
                }
            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while publishing the application"))
            );
        });
    }

    @APIMethod("/:resourceAppId/versions")
    public getAllVersions(resourceAppId: string) {
      return from(
        CloudConsoleClient.get<any>(apiMethodContext(arguments), {
          params: {
            resourceAppId          },
        }).then((value) => value.data)
      );
    }


    @APIMethod("/:resourceAppId/versions")
    public createNewVersion(resourceAppId: string) {
      return from(
        CloudConsoleClient.post<any>(apiMethodContext(arguments),{},{
          params: {
            resourceAppId
          },
        }).then((value) => value.data)
      );
    
    }

    @APIMethod("/:resourceAppId/versions/:version/deploy")
    public createVersionDeployment(resourceAppId: string, version : string,
       targetUrl: string, region: string, timeout: number){
      return from(
        CloudConsoleClient.post<any>(apiMethodContext(arguments),{
          backendUrl: targetUrl,
          location: region,
          type: region === "global" ? "GLOBAL" : "REGION",
          timeout: timeout
        },{
          params: {
            resourceAppId,
            version

          },
        }).then((value) => value.data)
      );
    }

    @APIMethod("/:consumerKey/scopes")
    public addScope(consumerKey: string, scope: string, description: string) {
      return from(
        CloudConsoleClient.put<any>(apiMethodContext(arguments),{
          "scope": scope,
          "description": description
        },{
          params: {
            consumerKey
          },
        }).then((value) => value.data)
      );
    }
}

const ApplicationService = new ApplicationServiceFactory();

export default ApplicationService;