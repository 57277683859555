import { APIResource, APIMethod } from "../../../libs/utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../../libs/utils/http/client/HttpClient";
import { apiMethodContext } from "../../../libs/utils/http/builder/UrlConfig";
import { from } from "rxjs";

interface ICertUploadRequest {
    certFile: File;
    description: string;
    env: string;
}

interface ICertUpdateRequest {
    certFile: File;
    description: string;
    certId: string;
    env: string;
}

interface ICertDeleteRequest {
    certId: string;
    env: string;
}

interface ICertificateResponse {
    certId: string;
    description: string;
    created?: string;
    updated?: string;
}

export interface ICertificate {
    Id: string,
    certId: string;
    description: string;
    expirationDate: string;
    status: string;
    created?: string;
    updated?: string;
    environment: string;
    certInfo: CertInfo[];
    action?: string;
    expiryDuration: string;
    expiryDurationHrs: string;
    certName: string;
}

export interface CertInfo {
    issuer: string;
    subject: string;
    expiry: string;
    commonName: string;
    isValid: string;
}

@APIResource(CloudConsoleClient, "/publisher/truststores")
class CertificateServiceFactory {

    @APIMethod("/gateway/:gateway/certs")
    public forCertificates(gateway: string) {
        return from(CloudConsoleClient.get<ICertificate[]>(apiMethodContext(arguments), {
            params: {
                gateway
            }
        }).then(response => response.data));
    }

    @APIMethod("/environments/:env/certs/:certId")
    public forCertificateInfo(env: string, certId: string) {
        return from(CloudConsoleClient.get<ICertificateResponse[]>(apiMethodContext(arguments), {
            params: {
                env,
                certId
            }
        }).then(response => response.data));
    }

    @APIMethod("/environments/:env/upload")
    public uploadCert(uploadRequest: ICertUploadRequest) {
        const context = apiMethodContext(arguments);

        return new Promise<ICertificateResponse>((resolve, reject) => {
            const formData = new FormData();

            if (uploadRequest.description) {
                formData.append('description', uploadRequest.description);
            }

            formData.append('certFile', uploadRequest.certFile);

            CloudConsoleClient.post(context, formData, {
                params: {
                    env: uploadRequest.env
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while uploading certificate")
            );
        });
    }

    @APIMethod("/environments/:env/upload")
    public updateCert(uploadRequest: ICertUpdateRequest) {
        const context = apiMethodContext(arguments);

        return new Promise<ICertificateResponse>((resolve, reject) => {
            const formData = new FormData();

            if (uploadRequest.description) {
                formData.append('description', uploadRequest.description);
            }
            formData.append('certId', uploadRequest.certId);
            formData.append('certFile', uploadRequest.certFile);

            CloudConsoleClient.put(context, formData, {
                params: {
                    env: uploadRequest.env
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while uploading certificate")
            );
        });
    }

    @APIMethod("/environments/:env/certs/:certId")
    public forDeleteCertificateInfo(deleteRequest: ICertDeleteRequest) {
        const context = apiMethodContext(arguments);
        return new Promise<ICertificateResponse>((resolve, reject) => {
            CloudConsoleClient.delete(context, {
                params: {
                    env: deleteRequest.env,
                    certId: deleteRequest.certId
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while deleting certificate")
            );
        });
    }
}
const CertificateService = new CertificateServiceFactory();

export default CertificateService;
