import { Button, Dialog, DialogTitle } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { useDialogProps } from "legacy-components/dialog/Dialog";
import CreateDeploymentForm from "./CreateDeploymentForm";
import { FormattedMessage } from "react-intl";

export interface DeploymentCreatorProps {
  resourceAppName: string;
  resourceAppId: string;
  version: string;
  refreshDeploymentList: () => void;
  deployedRegions: string[] | undefined;
}
export const DeploymentCreator: React.FC<DeploymentCreatorProps> = ({
  resourceAppName,
  resourceAppId,
  version,
  refreshDeploymentList,
  deployedRegions,
}) => {
  const { open, toggle } = useDialogProps();
  const onSuccess = () => {
    refreshDeploymentList();
  };

  const [availableRegions, setAvailableRegions] = useState([
    { key: "Australia", value: "au" },
    { key: "South Asia Pacific", value: "ap" },
    { key: "Global", value: "global" },
  ]);

  useEffect(() => {
    const updatedRegions = availableRegions?.filter((region) => {
      if (!deployedRegions?.includes(region.value)) {
        return region;
      }
    });
    setAvailableRegions(updatedRegions);
  }, [deployedRegions, setAvailableRegions]);


  return (
    <>
      {availableRegions?.length > 0 && (
        <>
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{ padding: "24px 21px" }}
            startIcon={<AddIcon />}
            onClick={() => toggle()}
          >
            Add New Deployment
          </Button>
          <Dialog
            open={open}
            title="Add New Deployment"
            onClose={toggle}
            fullWidth={true}
            style={{ padding: "24px 21px" }}
          >
            <div>
              <DialogTitle>
                <FormattedMessage defaultMessage="Add New Deployment" />
              </DialogTitle>
              <CreateDeploymentForm
                onCancel={toggle}
                onSuccess={onSuccess}
                resourceAppName={resourceAppName}
                resourceAppId={resourceAppId}
                version={version}
                availableRegions={availableRegions}
              />
            </div>
          </Dialog>
        </>
      )}
    </>
  );
};
