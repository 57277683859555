import React, { useCallback, useRef, useState, FC } from "react";
import { AgGridReact } from 'ag-grid-react';
import { useGenericGridViewElements, GridViewFrameworkComponentsType, GridViewAgGridContainer, GridViewApprovalStatusBadgeCellRenderer } from '../../../../legacy-components/grid-view/GridView';
import { FormattedMessage, useIntl } from "react-intl";
import { ColDef, CellClickedEvent, ICellRendererParams } from 'ag-grid-community';
import { Box, IconButton } from "@material-ui/core";
import ApplicationService from "../../../../libs/resources/identity/applications/ApplicationService";
import CheckCircleRounded from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from '@material-ui/icons/Cancel';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom';
import Unsubscriber from "pages/home/subscriptions/components/Unsubscriber/Unsubscriber";


export interface ISubscriptionsListProps{
    consumerKey: string;
}

export const SubscriptionsList : React.FC<ISubscriptionsListProps> = ({consumerKey}) => 
{    
    const intl = useIntl();
    const noDataMessage = <FormattedMessage defaultMessage="No subscriptions available" />;

    const { onGridReady, frameworkComponents, gridViewOverlayPropRef, getRowNodeId, createRowActionColumn, refreshStream } = useGenericGridViewElements({
        resolverFn: () => ApplicationService.getSubscriptions(consumerKey),
        noDataMessage
    });

    const Type = ({ value }: { value: string }) => {
        return (
            <Box key={value}     style={{height: '100%',
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center'}} >
                {/* {value === "application" ? <ApplicationIcon style={{color:'#0E416C'}}/>: <ServiceApplicationIcon style={{color:'#0E416C'}} />} */}
            </Box>
            
        )
    }

    const StatusBadge = ({ data }: ICellRendererParams) => {
        if (data.status === "SUBSCRIBED")
            return <CheckCircleRounded style={{ color: "#006638" }} />
        else
            return <CancelIcon style={{ color: ("#A9A9A9")}}/>
    };

    const [rowActionField] = useState(createRowActionColumn<any>({
        actions: data => [  
        <IconButton onClick={() =>onSubscriptionView(data)}>
            <SettingsIcon /> 
        </IconButton>
         ,    
         <Unsubscriber
         subscriptionId={data?.subscriptionId}
         applicationName={data.applicationName}
         isIcon={true}
         isPrimaryColor={false}
         reloadData={refreshStream}

       />
    ],
        width: 160
    }));

    const history = useHistory();

    const onSubscriptionView = useCallback((data: any) => {
            history.push(`/home/subscriptions/${data.subscriptionId}`);
    }, [history, rowActionField]);

    const onProductClick = useCallback((event: CellClickedEvent) => {
        if (event.colDef.field !== rowActionField.field) {
            history.push(`/home/consumer/products/${event.data.productId}`);
        }
    }, [history, rowActionField]);

    const [columnDefs] = useState<ColDef[]>(() => {
        const columns: ColDef[] = [
            {
            field: "productName",
            sortable: true,
            unSortIcon : true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "ProductName"
            }),
            flex: 1,
            cellRenderer : function(params) {
                return '<div style = "color:#00437B">' + params.value + '</div>';
            },
            onCellClicked : (event) =>onProductClick(event),

        }, {
            field: "subscribedDate",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Subscribed Date"
            }),
            flex: 1
        },
        {
            field: "status",
            sortable: true,
            unSortIcon : true,
            headerName: intl.formatMessage({
                defaultMessage: "Status"
            }),
            flex: 1,
            resizable: true,

            cellRendererFramework: StatusBadge,
        },
    
            rowActionField
        ];

        return columns;
    });

   
    return (
        <GridViewAgGridContainer>
            <AgGridReact
                columnDefs={columnDefs}
                frameworkComponents={{...frameworkComponents, Type}}
                loadingOverlayComponent={GridViewFrameworkComponentsType.LoadingOverlay}
                noRowsOverlayComponent={GridViewFrameworkComponentsType.NoRowsOverlay}
                noRowsOverlayComponentParams={{ gridViewOverlayPropRef }}
                suppressRowClickSelection={true}
                suppressCellSelection={true}
                suppressRowHoverHighlight={true}
                getRowNodeId={getRowNodeId}
                onGridReady={onGridReady}
            />
        </GridViewAgGridContainer>
    )
}