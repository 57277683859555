import Alert from '@material-ui/lab/Alert';
import React from 'react';

const NoAccessMessage = (props: any) => {
     
     return (
       <section className="flex-col-center">
            <Alert severity="info" className="mt-2">
               Your access request is in progress. After processing from our side, you can able to access this feature.<br/>
               Please contact trimble cloud support if your access is pending for long time.
            </Alert>
       </section>        
     );
}

export default (NoAccessMessage);