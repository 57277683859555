import React from "react";
import ApplicationView from "pages/home/subscriptions/views/ApplicationView";
import { Grid } from "@material-ui/core";
import ProductView from "pages/home/subscriptions/views/ProductView";
import { ServiceListViewer } from "pages/home/cloud-services/ServiceList.viewer";
import Accordion from "modus/components/misc/Accordion";
import { ErrorMessage } from "modus/components/error-message/ErrorMessage";
import { IService, ISubscriptionSummary } from "../../pages/subscription-summary-page/SubscriptionSummaryPage";

export const ProductAccordion: React.FC<{subscription?: ISubscriptionSummary}> = ({subscription}) => {
    return (
      <Accordion 
                title={"Product"}
                defaultExpanded={true}
                details={
                  <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "inline-block",
                        margin: "0px 24px",
                      }}
                    >
                      {subscription && <ProductView subscription={subscription} />}
                    </div>
                }
              />
    )
  }
  export const ApplicationAccordion: React.FC<{subscription?: ISubscriptionSummary}> = ({subscription}) => {
    return (
        <Accordion 
        title={"Application"}
        defaultExpanded={true}
        details={
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "inline-block",
                  margin: "0px 24px",
                }}
              >
                { subscription && <ApplicationView subscription={subscription} />}
              </div>
        } />
    );
  }
  export const ServiceAccordion: React.FC<{services?: IService[], productId?: string}> = ({services, productId}) => {
    return (
      <Grid container spacing={2}>
          <Grid item xs={12} >
            {(
              <Accordion
                defaultExpanded={true}
                title = "Services"
                details={
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "inline-block",
                      }}
                    >
                      {services && <ServiceListViewer productId={productId} services={services} />}
                      {(!services || services?.length === 0)&& <ErrorMessage message={"No Services found"}/>}
                    </div>
                } />
            )}
          </Grid>
        </Grid>
    );
  }