import React, { memo } from "react";
import { SvgIconProps, SvgIcon, makeStyles, createStyles, Theme } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        CloudConsoleLogo: {
            fill: 'inherit',
            width: "226px",
            height: "19px",
            [theme.breakpoints.down('sm')]: {
                width: "200px"
            }
        }
    })
);

function CloudConsoleLogo(props: SvgIconProps) {

    const classes = useStyles();
    return (
        <SvgIcon className={classes.CloudConsoleLogo} viewBox="0 0 226 19" width="226" height="19" fill="none"  {...props}>
            <path d="M8.86216 18V3.64796H14.1062V0.979959H0.743164V3.64796H5.98716V18H8.86216Z" fill="#363545" />
            <path d="M15.5728 18V10.272C16.3778 8.96096 17.5278 8.10996 19.3448 8.10996C19.5058 8.10996 19.9658 8.13296 20.1038 8.15596L20.1958 5.55696H20.0118C17.6888 5.55696 16.3778 6.56896 15.6188 7.78796H15.5728V5.78696H12.7438V18H15.5728Z" fill="#363545" />
            <path d="M24.5223 3.67096V1.20996H21.6243V3.67096H24.5223ZM24.4993 18V5.78696H21.6473V18H24.4993Z" fill="#363545" />
            <path d="M29.9379 18V9.55896C30.9959 8.61596 31.9389 7.97196 33.0659 7.97196C34.3999 7.97196 34.7909 8.59296 34.7909 9.94996V18H37.6199V9.55896C38.6779 8.61596 39.5979 7.97196 40.7479 7.97196C42.0819 7.97196 42.4729 8.59296 42.4729 9.94996V18H45.3019V9.23696C45.3019 7.05196 44.2209 5.55696 41.7369 5.55696C39.8739 5.55696 38.4249 6.49996 37.4589 7.55796C36.9759 6.36196 36.1709 5.55696 34.1239 5.55696C32.2609 5.55696 30.8809 6.43096 29.9379 7.46596V5.78696H27.1089V18H29.9379Z" fill="#363545" />
            <path d="M53.9919 18.184C57.1199 18.184 59.2129 16.114 59.2129 11.882C59.2129 7.64996 57.1659 5.55696 54.3829 5.55696C52.5659 5.55696 51.3009 6.43096 50.4959 7.39696V0.979959H47.6669V18H50.4959V16.643C51.3929 17.655 52.6809 18.184 53.9919 18.184ZM53.3709 15.93C52.3589 15.93 51.2549 15.401 50.4959 14.412V9.51296C51.1629 8.70796 52.2899 7.97196 53.4629 7.97196C55.2799 7.97196 56.3839 9.32896 56.3839 11.905C56.3839 14.619 55.5099 15.93 53.3709 15.93Z" fill="#363545" />
            <path d="M64.0045 18V0.979959H61.1525V18H64.0045Z" fill="#363545" />
            <path d="M71.8121 18.253C73.9971 18.253 75.6761 17.448 76.9411 16.045L75.4461 14.573C74.5261 15.47 73.6061 15.999 72.0191 15.999C70.2251 15.999 68.8681 15.033 68.7531 12.825H77.1021V12.158C77.1021 7.18996 74.4801 5.55696 71.7431 5.55696C69.2131 5.55696 65.9241 7.25896 65.9241 11.905C65.9241 16.39 68.7531 18.253 71.8121 18.253ZM68.7991 10.709C69.0751 8.63896 70.3401 7.85696 71.7201 7.85696C73.4221 7.85696 74.3421 8.84596 74.5031 10.709H68.7991Z" fill="#363545" />
            <path d="M91.2473 18.322C94.6513 18.322 96.8593 16.229 97.6873 12.871L96.1233 12.526C95.3873 15.378 93.8003 16.85 91.2703 16.85C87.9583 16.85 85.7963 14.09 85.7963 9.37496C85.7963 5.00496 87.9353 2.15296 91.2243 2.15296C93.8233 2.15296 95.0883 3.55596 95.8473 6.24696L97.4113 5.85596C96.6063 2.70496 94.6973 0.657959 91.2473 0.657959C86.9923 0.657959 84.1633 4.26896 84.1633 9.42096C84.1633 14.849 86.9463 18.322 91.2473 18.322Z" fill="#363545" />
            <path d="M100.176 18H101.763V0.979959H100.176V18Z" fill="#363545" />
            <path d="M109.681 18.253C112.648 18.253 115.155 15.976 115.155 11.951C115.155 7.90296 112.648 5.64896 109.681 5.64896C106.691 5.64896 104.184 7.90296 104.184 11.951C104.184 15.976 106.714 18.253 109.681 18.253ZM109.681 16.896C107.082 16.896 105.771 14.619 105.771 11.928C105.771 9.07596 107.22 7.00596 109.681 7.00596C112.234 7.00596 113.568 9.28296 113.568 11.928C113.568 14.849 112.119 16.896 109.681 16.896Z" fill="#363545" />
            <path d="M120.954 18.253C122.84 18.253 124.381 17.241 125.554 16.091V18H127.164V5.87896H125.554V14.78C124.197 16.068 122.886 16.896 121.345 16.896C119.666 16.896 118.93 16.068 118.93 14.366V5.87896H117.343V14.642C117.343 16.896 118.539 18.253 120.954 18.253Z" fill="#363545" />
            <path d="M134.732 16.85C132.708 16.85 131.052 15.309 131.052 11.974C131.052 8.54696 132.593 6.98296 134.732 6.98296C136.135 6.98296 137.308 7.69596 138.504 8.98396V14.872C137.354 16.091 136.227 16.85 134.732 16.85ZM134.456 18.207C136.273 18.207 137.515 17.287 138.504 16.229C138.504 16.873 138.642 17.586 138.78 18H140.459C140.183 17.379 140.091 16.482 140.091 15.01V0.979959H138.504V7.53496C137.561 6.47696 136.158 5.64896 134.594 5.64896C131.696 5.64896 129.465 7.71896 129.465 11.951C129.465 16.183 131.673 18.207 134.456 18.207Z" fill="#363545" />
            <path d="M154.176 18.322C157.58 18.322 159.788 16.229 160.616 12.871L159.052 12.526C158.316 15.378 156.729 16.85 154.199 16.85C150.887 16.85 148.725 14.09 148.725 9.37496C148.725 5.00496 150.864 2.15296 154.153 2.15296C156.752 2.15296 158.017 3.55596 158.776 6.24696L160.34 5.85596C159.535 2.70496 157.626 0.657959 154.176 0.657959C149.921 0.657959 147.092 4.26896 147.092 9.42096C147.092 14.849 149.875 18.322 154.176 18.322Z" fill="#363545" />
            <path d="M167.549 18.253C170.516 18.253 173.023 15.976 173.023 11.951C173.023 7.90296 170.516 5.64896 167.549 5.64896C164.559 5.64896 162.052 7.90296 162.052 11.951C162.052 15.976 164.582 18.253 167.549 18.253ZM167.549 16.896C164.95 16.896 163.639 14.619 163.639 11.928C163.639 9.07596 165.088 7.00596 167.549 7.00596C170.102 7.00596 171.436 9.28296 171.436 11.928C171.436 14.849 169.987 16.896 167.549 16.896Z" fill="#363545" />
            <path d="M175.326 18H176.913V9.12196C178.27 7.81096 179.581 7.00596 181.145 7.00596C182.801 7.00596 183.56 7.83396 183.56 9.53596V18H185.147V9.23696C185.147 6.98296 183.928 5.64896 181.536 5.64896C179.627 5.64896 178.086 6.63796 176.913 7.78796V5.87896H175.326V18Z" fill="#363545" />
            <path d="M191.911 18.23C194.648 18.23 196.626 16.965 196.626 14.757C196.626 12.388 195.062 11.652 192.026 10.916C189.91 10.41 189.082 9.92696 189.082 8.75396C189.082 7.83396 189.818 6.95996 191.704 6.95996C193.176 6.95996 194.326 7.53496 195.246 8.45496L196.143 7.39696C195.062 6.33896 193.728 5.64896 191.658 5.64896C189.036 5.64896 187.541 7.09796 187.541 8.82296C187.541 10.939 188.967 11.675 191.75 12.365C194.119 12.917 195.085 13.446 195.085 14.78C195.085 16.137 194.004 16.896 191.934 16.896C190.255 16.896 189.082 16.206 188.162 15.148L187.15 16.068C188.07 17.31 189.749 18.23 191.911 18.23Z" fill="#363545" />
            <path d="M203.55 18.253C206.517 18.253 209.024 15.976 209.024 11.951C209.024 7.90296 206.517 5.64896 203.55 5.64896C200.56 5.64896 198.053 7.90296 198.053 11.951C198.053 15.976 200.583 18.253 203.55 18.253ZM203.55 16.896C200.951 16.896 199.64 14.619 199.64 11.928C199.64 9.07596 201.089 7.00596 203.55 7.00596C206.103 7.00596 207.437 9.28296 207.437 11.928C207.437 14.849 205.988 16.896 203.55 16.896Z" fill="#363545" />
            <path d="M211.466 18H213.053V0.979959H211.466V18Z" fill="#363545" />
            <path d="M220.786 18.253C223.086 18.253 224.581 17.195 225.639 15.493L224.65 14.711C223.73 16.137 222.695 16.919 220.878 16.919C218.785 16.919 217.083 15.24 217.06 12.25H225.731V11.951C225.731 7.81096 223.615 5.64896 220.786 5.64896C218.233 5.64896 215.473 7.62696 215.473 11.928C215.473 16.068 217.957 18.253 220.786 18.253ZM217.083 10.985C217.382 8.24796 219.015 6.98296 220.763 6.98296C222.925 6.98296 224.029 8.68496 224.19 10.985H217.083Z" fill="#363545" />
        </SvgIcon>
    );
}

export default memo(CloudConsoleLogo);