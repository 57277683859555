import React, { useEffect, useState, useCallback, useRef } from "react";
import type { PropsWithChildren } from "react";

import clsx from "clsx";
import useAlertStyles from "./Alert.style";

import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import HelpIcon from "@material-ui/icons/Help";
import { CircularProgress, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import type { IAlertOptions } from "./AlertService";
import AlertService from "./AlertService";

interface AlertProps {
  id: string;
  disableBorder?: boolean;
}
export const Alert: React.FC<PropsWithChildren<AlertProps>> = ({
  id,
  disableBorder = false,
  children,
}) => {
  const classes = useAlertStyles();
  const [options, setOptions] = useState<IAlertOptions>({
    message: "",
    severity: "info",
    isAutoHide: true,
    isShowClose: false,
    id: "",
  });
  const intervalTime = 6000;
  const interval = useRef<NodeJS.Timeout | undefined>();

  const onCloseAlert = useCallback(() => {
    AlertService.clear();
    options.onClose && options.onClose();
    interval.current && clearTimeout(interval.current);
  }, [options]);
  useEffect(() => {
    AlertService.onAlert(id).subscribe((option: IAlertOptions) => {
      setOptions(option);
      if (option.message !== "" && option.isAutoHide) {
        interval.current = setTimeout(() => {
          AlertService.clear(option.id);
          option.onClose && option.onClose();
        }, intervalTime);
      }
    });
  }, [id, onCloseAlert, options]);

  return (
    <div
      className={clsx(classes.alertRoot, classes[options.severity], {
        [classes.hide]: options.message === "",
        [classes.noBorder]: disableBorder,
      })}
    >
      <div
        className={clsx(classes.icon, {
          [classes.hideIcon]: options.message === "",
        })}
      >
        {options.severity === "error" && <ErrorIcon />}
        {options.severity === "info" && <ErrorIcon />}
        {options.severity === "secondary" && <HelpIcon />}
        {options.severity === "success" && <CheckCircleIcon />}
        {options.severity === "warning" && <WarningIcon />}
        {options.severity === "loading" && <CircularProgress size={20} />}
      </div>
      <div
        className={clsx(classes.message, {
          [classes.rightSpace]: options.isShowClose,
        })}
      >
        {options.message ?? children}
      </div>
      {options.isShowClose && (
        <span className={classes.right}>
          <IconButton
            aria-label="dismiss"
            className={classes.closeButton}
            onClick={onCloseAlert}
          >
            <CloseIcon />
          </IconButton>
        </span>
      )}
    </div>
  );
};

export default Alert;
