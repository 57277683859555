import React, { PropsWithChildren, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core';

interface SecretWrapProps {
    revel: boolean;
    length?: number
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            letterSpacing: 1,
            fontSize: "inherit"
        }
    }),
);

function SecretWrap({ children, revel, length }: PropsWithChildren<SecretWrapProps>) {
    const styles = useStyles();
    const [dotted] = useState(new Array(length ?? 10).fill("•"))

    return (
        <>
            {revel ? children : (<span className={styles.root}>{dotted}</span>)}
        </>
    )
}

SecretWrap.propTypes = {
    revel: PropTypes.bool.isRequired,
    length: PropTypes.number
}

export default memo(SecretWrap);

