import { from } from "rxjs";
import { APIResource, APIMethod } from "libs/utils/http/decorator/UrlDecorator";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { CloudConsoleClient } from "libs/utils/http/client/HttpClient";

export type IdentityApplicationEnvironmentType = "trimble-pre-prod" | "trimble-prod";



export interface IdentityApplicationPublishedGateway {
    consumerKey: string
    consumerSecret: string[]
    grantTypes?: string[]
    logoutUri?: string[]
    organisationName: IdentityApplicationEnvironmentType
    redirectUri?: string[]
    enforceScopeValidation?: boolean
}

export interface IdentityApplicationSPMetadata {
    assertionConsumerUrl: string;
    x509RequestSigningCert?: string;
}

export interface IdentityApplicationType {
    id: string;
    name: IdentityApplicationTypes
}

export interface IdentityApplication {
    applicationId: string,
    applicationName: string,
    applicationType?: IdentityApplicationType;
    displayName?: string,
    description?: string,
    organisationName: IdentityApplicationEnvironmentType;
    applicationGatewayResponses?: IdentityApplicationPublishedGateway[];
    samlSpMetadata?: IdentityApplicationSPMetadata;
    userId?: string;
    isOwnedByUser?: Boolean,
    createdBy?: string;
    consumerKey: string;
}

export interface IdentityApplicationCreateRequest {
    name?: string,
    displayName?: string,
    description?: string,
    applicationType: IdentityApplicationTypes;
}

export interface IdentityApplicationUpdateRequest {
    name: string;
    displayName?: string,
    description?: string
}

export interface IdentityApplicationPublishRequest {
    grantTypes?: string[],
    redirectUri?: string[],
    logoutUri?: string[],
    enforceScopeValidation?: true
}

export type IdentityApplicationTypes = "application" | "service-application" | "resource" | "resource-server";


const formatError = (error: any, defaultMessage: string) => error?.response?.data?.code !== undefined && error.response.data.message ? error.response.data.message : defaultMessage;
export type IdentityApplicationPublishType = "publish" | "update";

@APIResource(CloudConsoleClient, "publisher/applications")
class ApplicationServiceFactory {

    @APIMethod("/:applicationName/gateway/:environmentName")
    public get(environmentName: IdentityApplicationEnvironmentType, applicationName: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<IdentityApplication>((resolve, reject) => {
            CloudConsoleClient.get<IdentityApplication>(context, {},).then(
                (response: any)  => resolve(response.data),
                (error: any)  => reject(formatError(error, "Unknown error occured while getting the application")));
        }))
    }


    @APIMethod("/:applicationName/gateway/:environmentName")
    private create(environmentName: IdentityApplicationEnvironmentType, request?: IdentityApplicationCreateRequest) {
        const context = apiMethodContext(arguments);

        return new Promise<IdentityApplication>((resolve, reject) => {
            CloudConsoleClient.post(context, request, {
                params: {
                    applicationName: request?.name,
                    environmentName
                }
            }).then(
                (response: any)  => resolve(response.data),
                (error: any)  => reject(formatError(error, "Unknown error occured while creating application")));
        });
    }

    @APIMethod("/:applicationName/gateway/:environmentName/update")
    public updateApplication(environmentName: IdentityApplicationEnvironmentType, applicationName: string, request?: IdentityApplicationPublishRequest) {
        const context = apiMethodContext(arguments);

        return from(new Promise<IdentityApplication>((resolve, reject) => {
            CloudConsoleClient.put(context, request, {
                params: {
                    applicationName,
                    environmentName
                }
            }).then(
                (response: any) => resolve(response.data),
                (error: any) => reject(formatError(error, "Unknown error occured while updating the application"))
            );
        }));
    }
    
    @APIMethod("/:applicationName/gateway/:environmentName")
    public delete(environmentName: IdentityApplicationEnvironmentType, applicationName: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<void>((resolve, reject) => {
            CloudConsoleClient.delete<void>(context, {
                params: {
                    applicationName,
                    environmentName
                }
            }).then(
                (response: any) => resolve(),
                (error: any) => reject(formatError(error, "Unknown error occured while deleting the application"))
            );
        }));
    }

    @APIMethod("/:applicationName/gateway/:environmentName/:publishType")
    private publish(publishType: IdentityApplicationPublishType, environmentName: IdentityApplicationEnvironmentType, applicationName: string, request?: IdentityApplicationPublishRequest) {
        const context = apiMethodContext(arguments);
        const method = publishType === "update" ? CloudConsoleClient.put : CloudConsoleClient.post;

        return new Promise<IdentityApplication>((resolve, reject) => {
            method(context, request, {
                params: {
                    applicationName,
                    environmentName,
                    publishType
                }
            }).then(
                (response: any) => resolve(response.data),
                (error: any) => reject(formatError(error, "Unknown error occured while publishing the application"))
            );
        });
    }

}

const ApplicationService = new ApplicationServiceFactory();

export default ApplicationService;