import React from "react";
import ProxyEnvironments from "./ProxyEnvironments";
import ProxySingleEnvironments from "./ProxySingleEnvironments";
import { ResourceAbility } from "libs/security/authorization";

export default function ProxyEnvironmentsView(props) {

    return (
        <ResourceAbility can="create" resource={({ Proxy }) => Proxy.org.environment["trimble-prod"]} fallBack={<ProxySingleEnvironments proxy={props.proxy} onUpdate={props.reload} />} >
            <ProxyEnvironments proxy={props.proxy} onUpdate={props.onUpdate} />
        </ResourceAbility >

    );
}