import React, { Ref, useCallback, useImperativeHandle, FC, PropsWithRef, forwardRef } from 'react';
import { Dialog, DialogTitle, makeStyles, createStyles, Typography } from '@material-ui/core';
import { useCompactDialog } from '../../../../legacy-components/dialog/UseCompactDialog';
import {  IdentityApplication } from './PublisherApplicationService';
import { ApplicationConfigurationFormData } from './models/application.model';
import { FormattedMessage } from 'react-intl';
import ApplicationScopeValidationForm from './application-form/ApplicationScopeValidationForm';

const useStyles = makeStyles(({ spacing }) => {
    return createStyles({
        header: {
            position: "relative"
        },
        typeText: {
            padding: spacing(3, 0, 2, 0)
        }
    })
});

export interface IdentityApplicationScopeValidationDialogRef {
    open(): void
}

interface IdentityApplicationScopeValidationDialogProps {
    ref?: Ref<IdentityApplicationScopeValidationDialogRef | undefined>,
    application?: IdentityApplication;
    onSubmit: (data: ApplicationConfigurationFormData) => void
}

interface ApplicationScopeValidationFormViewerProps {
    application?: IdentityApplication;
    onCancel: () => void;
    onSubmit: (data: ApplicationConfigurationFormData) => void
}


const ApplicationScopeValidationFormViewer = ({ application , onSubmit, onCancel }: ApplicationScopeValidationFormViewerProps) => {

    return (
        <ApplicationScopeValidationForm application={application} formContent={<ApplicationScopeValidationFormContent applicationName={application?.applicationName} />} onCancel={onCancel} onSuccess={onSubmit} />
    )
}

const ApplicationScopeValidationFormContent = ({ applicationName }: {
    applicationName?: string
}) => {
    const styles = useStyles();
    const applicationNameFormatter = () => <strong>{applicationName}</strong>;

    return (
        <>
            <Typography component="p">
                <FormattedMessage values={{
                    applicationName: applicationNameFormatter
                }} defaultMessage="This action cannot be undone. This will permanently enable the scope validation for the application <applicationName>name</applicationName> and cannot be disabled elsewhere." />
            </Typography>

            <Typography className={styles.typeText} variant="body2" component="p">
                <FormattedMessage values={{
                    applicationName: applicationNameFormatter
                }} defaultMessage="Type <applicationName>name</applicationName> to confirm." />
            </Typography>
        </>
    )
}

const IdentityApplicationScopeValidationDialog: FC<PropsWithRef<IdentityApplicationScopeValidationDialogProps>> = forwardRef(({ application, onSubmit }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSubmit = useCallback((data: ApplicationConfigurationFormData) => {
        setOpen(false);
        onSubmit(data);
    }, [onSubmit, setOpen]);

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >

            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Enable Scope Validation?" />
                </DialogTitle>
                <ApplicationScopeValidationFormViewer application = {application} onCancel={handleClose} onSubmit={handleSubmit} />
            </div>
        </Dialog>
    );
});

IdentityApplicationScopeValidationDialog.propTypes = {
}

export default IdentityApplicationScopeValidationDialog

