import React from "react";
import { DialogTitle, DialogContent } from "@material-ui/core";

const SSLConfigHelper = (props: any) => {

    return (
        <section>
            <DialogTitle>Securing your APIs over public network</DialogTitle>
            <DialogContent>
                <p>The following TLS options are available. Please make sure to upload the certificates to APICloud truststore (Navigate to API Management -&gt; Certificates to upload certs for respective environments) before enabling SSL for your API Proxy</p>
                <p><b>Note:</b> If a cert is part of a chain, then you must create a single file containing all the certs and upload that file.</p>
                <ul>
                    <li>
                        <h4>One Way TLS</h4>
                        <p>One-way TLS authentication creates a truststore on the API Cloud and a keystore on the server.</p>
                    </li>

                    <li>
                        <h4>Mutual TLS</h4>
                        <p>Two-way authentication creates a truststore and a keystore on both the API Cloud and the server.</p>
                    </li>
                </ul>
               
                <ul>
                    <li>
                        <h4>Keystores</h4>
                        <p>Contains an TLS certificate and private key used to identify the entity during TLS handshaking. When you create the keystore and upload the TLS cert, you specify an alias name used to reference the cert/key pair.</p>
                    </li>
                    <li>
                        <h4>Truststores</h4>
                        <p>Contains certificates used to verify certificates received as part of TLS handshaking. It is used when you have to validate self-signed certificates received from the TLS server, or certificates that are not signed by a trusted CA. It is also required when performing two-way TLS when Edge acts as the TLS server. API Cloud v2.0 provide self-serviceable APIs to manage certificates in the truststore.</p>
                    </li>
                </ul>

            </DialogContent>
        </section>
    );

}
export default SSLConfigHelper;