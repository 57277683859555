

import { from } from "rxjs";
import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { ConsumerClient2 } from "pages/home/consumer/service/ConsumerClient";
import { ISubscriptionSummary } from "../pages/subscription-summary-page/SubscriptionSummaryPage";

export interface ISubscriptionRequest {
  applicationName: string;
  gatewayName: string;
  businessContext?: string | null;
}

@APIResource(ConsumerClient2, "")
class ConsumerSubscriptionServiceImpl {
  

  @APIMethod("/subscriptions/:subscriptionId")
  public getSubscriptionSummary(subscriptionId: string, org?: string) {
    return from(
        ConsumerClient2.get<ISubscriptionSummary>(apiMethodContext(arguments), {
        params: {
          subscriptionId
        },
      })
    );
  }

  @APIMethod("/subscriptions/:subscriptionId/events/:subscriptionTrn")
  public deleteEventSubscriptionConfig(subscriptionId: string, subscriptionTrn: string) {
    return from(
      ConsumerClient2.delete<void>(apiMethodContext(arguments), {
        params: {
          subscriptionId,
          subscriptionTrn
        },
      })
    );
  }

  @APIMethod("/subscriptions/:subscriptionId")
  public deleteSubscription(subscriptionId: string, org?: string) {
    return from(
        ConsumerClient2.delete<void>(apiMethodContext(arguments), {
        params: {
          subscriptionId
        },
      })
    );
  }

}

const ConsumerSubscriptionService = new ConsumerSubscriptionServiceImpl();

export default ConsumerSubscriptionService;


