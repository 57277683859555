import { AxiosResponse } from "axios";
import { AsyncActionState, useAsyncAction } from "legacy-components/form/action/AsyncAction";
import { ConsumerProductService } from "pages/home/consumer/service/ConsumerProductService";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { ProductDetail } from "../Product.model";
import { Controller } from "modus/utilities/ViewController";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

export interface ProductDetailModel {
  response: AsyncActionState<AxiosResponse<ProductDetail> | null>;
  subscribe?: (stream?: boolean) => () => void;
}

export const useProductDetailsController:Controller<{},ProductDetailModel> = () => {
  const {productId} = useParams<any>();
  const [response, {subscribe}] = useAsyncAction(() => ConsumerProductService.getProduct(productId));
  const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment);
  const history = useHistory();

  useEffect(() => {
    if (!productId || !subscribe) {
      return;
    }

    subscribe();
  }, [productId, subscribe]);

  useEffect(() => {
    if (
      !response.value?.data.identityEnvs ||
      !identityEnvironment ||
      !history
    ) {
      return;
    }

    if (!response.value?.data.identityEnvs.includes(identityEnvironment)) {
      history.push("home/consumer/products/");
      return;
    }
  }, [identityEnvironment, history, response.value]);

  return {
    response,
    subscribe
  };
};
