import {  CircularProgress, createStyles, IconButton, IconButtonProps, makeStyles, Theme, Tooltip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import clsx from "clsx";
import { AsyncActionState } from "legacy-components/form/action/AsyncAction";
import React, { useEffect, useState } from "react";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            borderColor: '#0063A3',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '4px',
        }
    }));

interface ViewButtonProps extends IconButtonProps {
    open : boolean;
    onClick: () => void;
    state?: AsyncActionState<any>;
}
export const ViewButton:React.FC<ViewButtonProps> = ({open, onClick, className, state, ...props}) => {
    const classes = useStyles();
    const [viewMarked, setViewMarked] = useState<boolean>(false);

    const buttonClicked = () => {
        setViewMarked(true);
        onClick(); 
    }

    useEffect(()=>{
        if(state?.loaded && viewMarked) {
            setViewMarked(false);
        }
    },[state?.loaded])
    return (
        <Tooltip title={open? "Hide": "View"}>
        <IconButton  className={clsx([classes.iconButton,className])} 
            disabled={state?.loading} onClick={buttonClicked}
            {...props}>
          {((!viewMarked || !state?.loading ) && !open) && (<VisibilityIcon style={{color:'#0063A3'}} />)}
          {((!viewMarked || !state?.loading ) && open) && (<VisibilityOffIcon style={{color:'#0063A3'}} />)}
          {(viewMarked && state?.loading) && (<CircularProgress size={"24px"} style={{color:'#0063A3'}} />)}
        </IconButton>
        </Tooltip>
    );
}