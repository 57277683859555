import { IdentityApplicationEnvironmentType } from "../../PublisherApplicationService";
import { ApplicationSummaryFormData } from "../../models/application.model";
import { Dialog } from "@material-ui/core";
import React, { Ref, FC, PropsWithRef, forwardRef, useState, useCallback, useImperativeHandle } from "react";
import { useCompactDialog, CompactDialogLayout } from "../../../../../../legacy-components/dialog/UseCompactDialog";
import { FormattedMessage } from "react-intl";
import PropTypes from 'prop-types';
import ApplicationSummaryForm from "../ApplicationSummaryForm";


export interface ApplicationSummaryDialogRef {
    open(data?: ApplicationSummaryFormData): void
}

interface ApplicationSummaryDialogProps {
    ref?: Ref<ApplicationSummaryDialogRef | undefined>,
    applicationName: string;
    env: IdentityApplicationEnvironmentType,
    onSubmit: (data: ApplicationSummaryFormData) => void,
}

interface SummaryFormViewerProps {
    applicationName: string;
    env: IdentityApplicationEnvironmentType;
    onCancel: () => void;
    onSubmit: (data: ApplicationSummaryFormData) => void,
    initialValues?: ApplicationSummaryFormData;
}

const SummaryFormViewer: FC<SummaryFormViewerProps> = ({ applicationName, env, onSubmit, onCancel, initialValues }) => {
    return (<ApplicationSummaryForm initialValues={initialValues} applicationName={applicationName} env={env} onCancel={onCancel} onSuccess={onSubmit} />);
}

const ApplicationSummaryDialog: FC<PropsWithRef<ApplicationSummaryDialogProps>> = forwardRef(({ applicationName, env, onSubmit }, ref) => {

    const { maxWidth, open, scroll, setOpen } = useCompactDialog();
    const [formValues, setFormValues] = useState<{
        value?: ApplicationSummaryFormData
    } | undefined>()

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleSubmit = useCallback((data: ApplicationSummaryFormData) => {
        setOpen(false);
        onSubmit(data);
    }, [onSubmit, setOpen]);

    const handleClickOpen = (value?: ApplicationSummaryFormData) => {
        setFormValues({
            value
        });
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: (data?: ApplicationSummaryFormData) => handleClickOpen(data)
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <CompactDialogLayout header={<FormattedMessage defaultMessage="Edit details" />}>
                <SummaryFormViewer initialValues={formValues?.value} applicationName={applicationName} env={env} onCancel={handleClose} onSubmit={handleSubmit} />
            </CompactDialogLayout>
        </Dialog>
    );
});

ApplicationSummaryDialog.propTypes = {
    applicationName: PropTypes.string.isRequired,
    env: PropTypes.oneOf<IdentityApplicationEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired
}

export default ApplicationSummaryDialog

