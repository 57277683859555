import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormattedMessage } from "react-intl";
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import { makeStyles, createStyles } from "@material-ui/core";
import TeamCreationForm from "./TeamCreationForm";
import { ResourceAbility } from "libs/security/authorization";
import DivisionService, { Division } from "../division/DivisionService";

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        content: {
            padding: spacing(3)
        },
        dialog: {
          borderBottom: '1px solid #ccc',
          minWidth: '500px'
        }
    }),
);

const TeamCreator = (props: any) => {
  const styles = useStyles();
  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    props.onCreateSuccess();
    handleClose();

  }
  const [divisions, setDivisions] = useState<Array<{
    key?: string,
    value?: string
}>>();


useEffect(() => {
    DivisionService.getDivisions().subscribe(response => {
        setDivisions(response.map(
            (data: Division) => {
                return { "key": data.name + "," + data.baCode, "value": data.name + ", " + data.baCode }
            }
        )
        );
    }, () => {
    });
}, []);

return (
    <>
    
      <ResourceAbility can="create" resource={({ TeamMembers }) => TeamMembers.create} >
      <Button startIcon={<GroupAddOutlinedIcon />} variant="contained" color="primary" onClick={handleClickOpen}>
          <FormattedMessage defaultMessage="Add Team" />
        </Button>
      </ResourceAbility>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title" 
      >
        <DialogTitle id="form-dialog-title" className={styles.dialog}><FormattedMessage defaultMessage="Add Team" /></DialogTitle>
        <DialogContent className={styles.content}>
          <DialogContentText>
          </DialogContentText>
          <div>
           <TeamCreationForm onCancel={handleClose} onSuccess={onSuccess} divisions = {divisions}/>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TeamCreator;