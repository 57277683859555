import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Typography } from "@material-ui/core";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import NAWrap from "legacy-components/form/action/NAWrap";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import { ProxyVerificationApiQualityInfo } from "pages/home/proxy/summary/verification/models/verification.model";

interface ProxyVerificationApiQualityViewProps {
  proxyVerificationApiQualityInfo?: ProxyVerificationApiQualityInfo | null;
  onSuccess?: () => void;
}

const ProxyVerificationApiQualityView: FC<ProxyVerificationApiQualityViewProps> =
  ({ proxyVerificationApiQualityInfo }) => {
    function capitalizeName(name: string | undefined) {
      return name
        ?.toLowerCase()
        .replaceAll(/_/g, " ")
        .replace(/\b(\w)/g, (s) => s.toUpperCase());
    }
    return (
      <FormInputContainer
        title={
          <Typography
            variant="subtitle2"
            children={
              <FormattedMessage defaultMessage="Please identify the maturity of your API Proxy as you make it accessible for other Trimble developers to use. *" />
            }
          />
        }
      >
        <FormViewInliner
          variant="inline"
          gutter="gutter-bottom"
          title={<FormattedMessage defaultMessage="API Quality" />}
        >
          <Typography variant="body1">
            <NAWrap
              value={proxyVerificationApiQualityInfo?.apiQuality}
              showMessage={true}
            >
              {capitalizeName(proxyVerificationApiQualityInfo?.apiQuality)}
            </NAWrap>
          </Typography>
        </FormViewInliner>
        <FormViewInliner
          variant="inline"
          gutter="gutter-bottom"
          title={<FormattedMessage defaultMessage="Additional Input" />}
        >
          <Typography variant="body1">
            <NAWrap
              value={proxyVerificationApiQualityInfo?.additionalInput}
              showMessage={true}
            >
              {proxyVerificationApiQualityInfo?.additionalInput}
            </NAWrap>
          </Typography>
        </FormViewInliner>
      </FormInputContainer>
    );
  };

ProxyVerificationApiQualityView.propTypes = {};

export default ProxyVerificationApiQualityView;
