import { IdentityApplicationEnvironmentType } from "libs/resources/identity/applications/PublisherApplicationService";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { CloudConsoleClient } from "libs/utils/http/client/HttpClient";
import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { from, Observable } from "rxjs";


export interface IdentityApplicationSecretInfo {
    id: string;
}

interface IdentityApplicationSecretValue {
    secret: string;
}

const formatError = (error: any, defaultMessage: string) => error?.response?.data?.code !== undefined && error.response.data.message ? error.response.data.message : defaultMessage;


@APIResource(CloudConsoleClient, "identities/applications")
class ApplicationConfigurationServiceFactory {

    

    @APIMethod("/:applicationName/secrets", {
        options: {
            noTrailingSlash: true
        }
    })
    public getApplicationSecretMetadata(gateway: IdentityApplicationEnvironmentType, applicationName: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<IdentityApplicationSecretInfo[]>((resolve, reject) => {
            CloudConsoleClient.get<IdentityApplicationSecretInfo[]>(context, {
                params: {
                    applicationName,
                    gateway
                }

            }).then(
                (response: any) => resolve(response.data),
                (error: any) => reject(formatError(error, "Unknown error occured while fetching application secrets")));
        }));
    }

    @APIMethod("/:applicationName/secrets", {
        options: {
            noTrailingSlash: true
        }
    })
    public createSecret(gateway: IdentityApplicationEnvironmentType, applicationName: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<IdentityApplicationSecretInfo>((resolve, reject) => {
            CloudConsoleClient.post<IdentityApplicationSecretInfo>(context, {}, {
                params: {
                    applicationName,
                    gateway
                }

            }).then(
                (response: any)  => resolve(response.data),
                (error: any)  => reject(formatError(error, "Unknown error occured while creating application secret")));
        }))
    }

    @APIMethod("/:applicationName/secrets/:secretId", {
        options: {
            noTrailingSlash: true
        }
    })
    public getSecretValue(gateway: IdentityApplicationEnvironmentType, applicationName: string, secretId: string) {
        const context = apiMethodContext(arguments);
        return from(new Promise<IdentityApplicationSecretValue>((resolve, reject) => {
            CloudConsoleClient.get<IdentityApplicationSecretValue>(context, {
                params: {
                    applicationName,
                    gateway,
                    secretId
                }
            }).then(
                (response: any) => {
                    resolve(response.data as IdentityApplicationSecretValue)
                },
                (error:any) => reject(formatError(error, "Unknown error occured while fetching application secret")));
        
        }));
    }

    @APIMethod("/:applicationName/secrets/:secretId")
    public deleteSecret(gateway: IdentityApplicationEnvironmentType, secretId: string, applicationName: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<void>((resolve, reject) => {
            CloudConsoleClient.delete<void>(context, {
                params: {
                    applicationName,
                    gateway,
                    secretId
                }

            }).then(
                () => resolve(),
                (error: any) => reject(formatError(error, "Unknown error occured while deleting application secret")));
        }))
    }
}

const ApplicationConfigurationService = new ApplicationConfigurationServiceFactory();

export default ApplicationConfigurationService;