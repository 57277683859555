import React from "react";
import { DialogTitle,  DialogContent } from "@material-ui/core";


const QuotaHelper = (props: any) => {
    return (
        <section>
            <DialogTitle>What is Subscription Quota?</DialogTitle>
            <DialogContent>
                <p>This policy enforces consumption limits on client apps by maintaining a distributed 'counter' that tallies incoming requests. The counter can tally API calls for any identifiable entity, including apps, developers, API keys, access tokens, and so on. Usually, API keys are used to identify client apps. This policy is computationally expensive so, for high-traffic APIs, it should configured for longer time intervals, such as a day or month. This policy should be used to enforce business contracts or SLAs with developers and partners, rather than for operational traffic management.</p>
                <p>Use the Quota policy to configure the number of request messages that an API proxy allows over a period of time, such as a minute, hour, day, week, or month. You can set the quota to be the same for all apps accessing the API proxy, or you can set the quota based on:</p>
                <ul>
                    <li>The product that contains the API proxy</li>
                    <li>The app requesting the API</li>
                    <li>The app developer</li>
                </ul>

            </DialogContent>
        </section>
    );
}
export default QuotaHelper;