import { from, Observable } from "rxjs";
import { APIResource, APIMethod } from "../../../utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../../utils/http/client/HttpClient";
import { apiMethodContext } from "../../../utils/http/builder/UrlConfig";
import { IdentityApplicationEnvironmentType } from "./PublisherApplicationService";

export interface IdentityApplicationSecretInfo {
    id: string;
}

interface IdentityApplicationSecretValue {
    secret: string;
}

const formatError = (error: any, defaultMessage: string) => error?.response?.data?.code !== undefined && error.response.data.message ? error.response.data.message : defaultMessage;


@APIResource(CloudConsoleClient, "identities/applications")
class IdentityApplicationServiceFactory {

    private applicationSecretMap = new Map<string, IdentityApplicationSecretValue>();

    public environments: {
        [key in IdentityApplicationEnvironmentType]: {
            getSecretMetadata: (applicationName: string) => Observable<IdentityApplicationSecretInfo[]>,
            getSecretValue: (applicationName: string, secretId: string) => Observable<IdentityApplicationSecretValue>,
            deleteSecret: (secretId: string, applicationName: string) => Promise<void>,
            createSecret: (applicationName: string) => Promise<IdentityApplicationSecretInfo>
        }
    } = {
            "trimble-pre-prod": {
                getSecretMetadata: (applicationName: string) => this.getApplicationSecretMetadata("trimble-pre-prod", applicationName),
                getSecretValue: (applicationName: string, secretId: string) => this.getSecretValue("trimble-pre-prod", applicationName, secretId),
                deleteSecret: (secretId, applicationName) => this.deleteSecret("trimble-pre-prod", secretId, applicationName),
                createSecret: (applicationName) => this.createSecret("trimble-pre-prod", applicationName),
            },
            "trimble-prod": {
                getSecretMetadata: (applicationName: string) => this.getApplicationSecretMetadata("trimble-prod", applicationName),
                getSecretValue: (applicationName: string, secretId: string) => this.getSecretValue("trimble-prod", applicationName, secretId),
                deleteSecret: (secretId, applicationName) => this.deleteSecret("trimble-prod", secretId, applicationName),
                createSecret: (applicationName) => this.createSecret("trimble-prod", applicationName),
            }
        };

    @APIMethod("/:applicationName/secrets", {
        options: {
            noTrailingSlash: true
        }
    })
    private getApplicationSecretMetadata(gateway: IdentityApplicationEnvironmentType, applicationName: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<IdentityApplicationSecretInfo[]>((resolve, reject) => {
            CloudConsoleClient.get<IdentityApplicationSecretInfo[]>(context, {
                params: {
                    applicationName,
                    gateway
                }

            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while fetching application secrets")));
        }));
    }

    @APIMethod("/:applicationName/secrets", {
        options: {
            noTrailingSlash: true
        }
    })
    private createSecret(gateway: IdentityApplicationEnvironmentType, applicationName: string) {
        const context = apiMethodContext(arguments);

        return new Promise<IdentityApplicationSecretInfo>((resolve, reject) => {
            CloudConsoleClient.post<IdentityApplicationSecretInfo>(context, {}, {
                params: {
                    applicationName,
                    gateway
                }

            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while creating application secret")));
        })
    }

    @APIMethod("/:applicationName/secrets/:secretId", {
        options: {
            noTrailingSlash: true
        }
    })
    private getSecretValue(gateway: IdentityApplicationEnvironmentType, applicationName: string, secretId: string) {
        const context = apiMethodContext(arguments);

        return from(new Promise<IdentityApplicationSecretValue>((resolve, reject) => {
            const secretMapKey = `${applicationName}::${secretId}`;
            const secretCache = this.applicationSecretMap.get(secretMapKey);

            if (secretCache?.secret) {
                resolve(secretCache);
            } else {
                CloudConsoleClient.get<IdentityApplicationSecretValue>(context, {
                    params: {
                        applicationName,
                        gateway,
                        secretId
                    }
                }).then(
                    response => {
                        this.applicationSecretMap.set(secretMapKey, response.data);
                        resolve(response.data)
                    },
                    error => reject(formatError(error, "Unknown error occured while fetching application secret")));
            }
        }));
    }

    @APIMethod("/:applicationName/secrets/:secretId")
    public deleteSecret(gateway: IdentityApplicationEnvironmentType, secretId: string, applicationName: string) {
        const context = apiMethodContext(arguments);

        return new Promise<void>((resolve, reject) => {
            CloudConsoleClient.delete<void>(context, {
                params: {
                    applicationName,
                    gateway,
                    secretId
                }

            }).then(
                () => resolve(),
                error => reject(formatError(error, "Unknown error occured while deleting application secret")));
        })
    }
}

const IdentityApplicationService = new IdentityApplicationServiceFactory();

export default IdentityApplicationService;