import { APIResource, APIMethod } from "../../utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../utils/http/client/HttpClient";
import { from } from "rxjs";
import { apiMethodContext } from "../../utils/http/builder/UrlConfig";
import { IAPIProductSubscription } from "../subscription/SubscriptionService";

export interface IAPIProductPublishedGatway {
    gatewayName: ProductEnvironmentType;
    status: string | null;
    approvalType?: string;
}

export interface IAPIProductSpecGateway {
    openApiSpecId: string;
    gatewayName: ProductEnvironmentType;
}

export interface IAPIProduct {
    id: string,
    name: string,
    displayName?: string,
    longDescription?: string,
    shortDescription?: string,
    visibility?: string,
    iso?: boolean,
    soc2?: boolean,
    soc3?: boolean,
    tsdcl?: boolean,
    openApiSpec?: {
        id: string,
        name: string,
        fileName: string
    },
    publishedGateway?: IAPIProductPublishedGatway[],
    openApiSpecGateway?: IAPIProductSpecGateway[],
    createdBy?: string,
    updatedBy?: string,
    apiTypeMapping?: string[],
    publishStatus: string,
    isProxiesMapped : boolean,
    hasSubscriptions : boolean,
    isPromoted : boolean
}

export interface IAPIProductAttachSpecRequest {
    productName: string;
    openApiSpecId: string;
}

interface IAPIProductPublishRequest {

}

export type ProductEnvironmentType = "trimble-pre-prod" | "trimble-prod";

@APIResource(CloudConsoleClient, "publisher/product")
class ProductServiceFactory {

    public environments: {
        [key in ProductEnvironmentType]: {
            publish: (productName: string, approvalType: string, request?: IAPIProductPublishRequest) => Promise<void>,
            unpublish: (apiName: string) => Promise<void>,
            updateSpec: (request: IAPIProductAttachSpecRequest) => Promise<IAPIProduct>,
            updateApprovalType: (productName: string, approvalType: string) => Promise<void>,
        }
    } = {
            "trimble-pre-prod": {
                publish: (productName, approvalType, request) => this.publish("trimble-pre-prod", productName, approvalType, request),
                unpublish: (productName) => this.unpublish("trimble-pre-prod", productName),
                updateSpec: (request: IAPIProductAttachSpecRequest) => this.updateSpec("trimble-pre-prod", request),
                updateApprovalType: (productName, approvalType) => this.forApprovalType("trimble-pre-prod", productName, approvalType)
            },
            "trimble-prod": {
                publish: (productName, approvalType, request) => this.publish("trimble-prod", productName, approvalType, request),
                unpublish: (productName) => this.unpublish("trimble-prod", productName),
                updateSpec: (request: IAPIProductAttachSpecRequest) => this.updateSpec("trimble-prod", request),
                updateApprovalType: (productName, approvalType) => this.forApprovalType("trimble-prod", productName, approvalType)
            }
        };

    @APIMethod()
    public getList() {
        return from(CloudConsoleClient.get<IAPIProduct[]>(apiMethodContext(arguments), {
            params:{
                includeEmailAddress:true
            }
        }).then(value => value.data));
    }

    @APIMethod("/:productName")
    public get(productName: string) {
        return from(CloudConsoleClient.get<IAPIProduct>(apiMethodContext(arguments), {
            params: {
                productName,
                includeEmailAddress: true
            }
        }).then(value => value.data));
    }

    @APIMethod("/apiTypes")
    public getApiTypes() {
        return from(CloudConsoleClient.get<string[]>(apiMethodContext(arguments), {
            params: {
            }
        }).then(value => value.data));
    }

    @APIMethod("/:productName/gateway/:environmentName/publish")
    private publish(environmentName: ProductEnvironmentType, productName: string, approvalType: string ='AUTO', request?: IAPIProductPublishRequest) {
        const context = apiMethodContext(arguments);

        return new Promise<void>((resolve, reject) => {
            CloudConsoleClient.post(context, request, {
                params: {
                    productName,
                    environmentName,
                    approvalType
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while publishing the product")
            );
        });
    }

    @APIMethod("/:productName/gateway/:environmentName")
    private forApprovalType(environmentName: ProductEnvironmentType, productName: string, approvalType: string) {
        const context = apiMethodContext(arguments);

        return new Promise<void>((resolve, reject) => {
            CloudConsoleClient.put(context, {}, {
                params: {
                    productName,
                    environmentName,
                    approvalType
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while updating the product approval type")
            );
        });
    }

    @APIMethod("/:productName/gateway/:environmentName/unpublish")
    private unpublish(environmentName: ProductEnvironmentType, productName: string) {
        const context = apiMethodContext(arguments);

        return new Promise<void>((resolve, reject) => {
            CloudConsoleClient.delete(context, {
                params: {
                    productName,
                    environmentName
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while unpublishing the product")
            );
        });
    }

    @APIMethod("/:productName")
    private updateSpec(gatewayName: ProductEnvironmentType, { openApiSpecId, productName }: IAPIProductAttachSpecRequest) {
        const context = apiMethodContext(arguments);

        return new Promise<IAPIProduct>((resolve, reject) => {
            CloudConsoleClient.put(context, {
                openApiSpecGateway: {
                    openApiSpecId,
                    gatewayName
                }
            }, {
                params: {
                    productName
                }
            }).then(
                response => resolve(response.data),
                error => reject(error.response?.data?.message ?? "Unknown error occured while updating documentation")
            );
        });
    }

    @APIMethod("/apiTypes")
    public getProductApiTypes() {
        return from(CloudConsoleClient.get<string[]>(apiMethodContext(arguments), {
            params: {
            }
        }).then(value => value.data));
    }

    @APIMethod("/:productName/promote")
    public promoteProductToPartnerMarketplace(productName : string) {
        const context = apiMethodContext(arguments);
        return new Promise<number>((resolve, reject) => {
            CloudConsoleClient.post(context, {},{
                params: {
                    productName,
                }
            }).then(
                response => resolve(response.status),
                error => reject(error.response?.data?.message ?? "Unknown error occured while promoting the product")
            );
        });
    }

    @APIMethod("/:productName/demote")
    public demoteProductToPartnerMarketplace(productName : string) {
        const context = apiMethodContext(arguments);
        return new Promise<number>((resolve, reject) => {
            CloudConsoleClient.delete(context,{
                params: {
                    productName,
                }
            }).then(
                response => resolve(response.status),
                error => reject(error.response?.data?.message ?? "Unknown error occured while demoting the product")
            );
        });
    }

    @APIMethod("/:productName/subscription/:subscriptionId")
    public updateSubscriptionStatus(subscriptionId: string, action: any) {
        const context = apiMethodContext(arguments);
        return from(CloudConsoleClient.put(apiMethodContext(arguments),{}, {
            params: {
                productName: "sample",
                subscriptionId,
                action
            }
        }).then(value => value.data));
    }

    @APIMethod("/:productName/subscription")
    public getSubscriptions(productName: string, type: string) {
        return from(CloudConsoleClient.get<IAPIProductSubscription[]>(apiMethodContext(arguments), {
            params: {
                productName,
                type
            }
        }).then(value => value.data));
    }
}

const ProductService = new ProductServiceFactory();

export default ProductService;