import React, { PropsWithChildren, memo } from "react";
import { Typography, makeStyles, createStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme =>
    createStyles({
        documentInfo: {
            padding: theme.spacing(1, 2, 0, 2),
            textAlign: "center"
        },
        documentField: {
            padding: theme.spacing(2, 0)
        },
    }),
);

export interface CertificateFileInfo {
    name?: string;
    type?: string;
}

export interface CertificateInfoProps {
    certFile: CertificateFileInfo;
}

const CertificateInfo = ({ children, certFile }: PropsWithChildren<CertificateInfoProps>) => {
    const styles = useStyles();

    return (
        <div className={styles.documentInfo}>
            <Typography variant="body2">
                {certFile.name}
            </Typography>
            <div className={styles.documentField}>
                {children}
            </div>

            <div>
                <Typography variant="caption">
                    <FormattedMessage values={{

                    }} defaultMessage="Accepts valid certificate file only. The recommended file format is .PEM" />
                </Typography>
            </div>
        </div>
    )
}

export default memo(CertificateInfo);