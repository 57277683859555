import React, { useRef, useCallback } from 'react';
import { Box, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import UserTokenPickerDialog, { UserTokenPickerDialogRef } from './UserTokenPickerDialog';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

interface UserTokenViewerProps {
    accessToken?: string;
}

function UserTokenViewer({ accessToken }: UserTokenViewerProps) {
    const userTokenDialogRef = useRef<UserTokenPickerDialogRef>();

    const handleClick = useCallback(() => userTokenDialogRef?.current?.open(), [userTokenDialogRef]);

    return (
        <Box>
            <UserTokenPickerDialog accessToken={accessToken} ref={userTokenDialogRef} />
            <Button startIcon={<SecurityOutlinedIcon />} onClick={handleClick} variant="outlined" color="primary">

                <FormattedMessage defaultMessage="Get Access Keys" />
            </Button>
        </Box>
    )
}

UserTokenViewer.propTypes = {
    accessToken: PropTypes.string
}

const mapStateToProps = (state: any) => {
    return {
        accessToken: state.auth?.user?.access_token
    }
};

export default connect(mapStateToProps)(UserTokenViewer);