import SubscriptionService, {
  IAPIApplicationSubscription,
} from "libs/resources/subscription/SubscriptionService";
import React, { useEffect, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { ProductSubscriptionListAccordion } from "./ProductSubscriptionListAccordian";
import { productToApplicationMap } from "legacy-components/Helper";
import { AsyncActionState, useAsyncAction } from "legacy-components/form/action/AsyncAction";
import { ErrorMessage } from "modus/components/error-message/ErrorMessage";
import { StackSkeleton } from "pages/home/products/components/ProductList.viewer";
import GenericPageTitle from "modus/components/generic-page-title/GenericPageTitle";

export interface IApplicationSubscriptionList {
  applicationName: string;
  consumerKey: string;
  gatewayName: string;
  status: string;
  subscriberEmail: string;
  subscriptionId: string;
  subscriptionRequestedTime: string;
  productTeamName: string;
}

export interface ITransformedSubscriptionList {
  productName: string;
  productId: string;
  applications: Array<IApplicationSubscriptionList>;
}
export const ProductSubscriptionList: React.FC = () => {
  const identityEnvironment = useSelector(
    (state: any) => state?.common?.identityEnvironment
  );

  const [response, { subscribe: subscribe }] = useAsyncAction(() =>
    SubscriptionService.getTeamSubScriptions(false, identityEnvironment)
  );

  useEffect(() => {
    if (!identityEnvironment || !subscribe) {
      return;
    }
    subscribe();
  }, [subscribe, identityEnvironment]);

  return (
    <Box m="16px">
      <GenericPageTitle
        title={"Subscriptions"}
        description={<>View and configure Project Subscriptions</>}
        noSkeleton
      />
      <Box mt="16px"/>
      <ProductAccordion state={response} reloadData={subscribe} />
    </Box>
  );
};

interface ProductAccordionProps {
  state: AsyncActionState<IAPIApplicationSubscription[] | null>;
  reloadData: ()=>void;
}
const ProductAccordion = ({ state, reloadData }: ProductAccordionProps) => {
  return useMemo(() => (
    <>
      <StackSkeleton count={4} response={state} />
      {state?.loaded && state?.value && state?.value?.length >= 1 && (
        <Box style={{maxHeight: "90vh" , overflowY:"auto"}}>
          <ProductSubscriptionListAccordion
            data={productToApplicationMap(state?.value)}
            reloadData={reloadData}
          />
        </Box>
      )}
      {state?.loaded && (!state?.value || state?.value?.length < 1) && (
        <ErrorMessage message={"No Subscriptions found"} />
      )}
    </>
  ),[state?.value,state?.loaded, state?.loading, reloadData] );
};
