import React, {
  FC,
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InlineIconFormatter from "legacy-components/formatters/InlineIconFormatter";
import { Button } from "@material-ui/core";
import { ProxyVerificationInfo } from "./models/verification.model";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {
  AccordionSummaryTitle,
  AccordionContent,
} from "legacy-components/form/components/accordion-form/AccordionForm";
import ActionInliner from "../../../../../legacy-components/form/action/ActionInliner";
import ProxyVerificationService from "./ProxyVerificationService";
import ReviewerDialog, { ReviewerDialogRef } from "./dialog/ReviewerDialog";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import {
  useAsyncAction,
  AsyncLoader,
} from "legacy-components/form/action/AsyncAction";
import ProxyVerificationSecurityComplianceView from "pages/home/proxy/summary/verification/view/ProxyVerificationSecurityComplianceView";
import ProxyVerificationPerformanceDocView from "./view/ProxyVerificationPerformanceDocView";
import ProxyVerificationSupportView from "./view/ProxyVerificationSupportView";
import ProxyVerificationApiQualityView from "./view/ProxyVerificationApiQualityView";

interface ProxyVerificationProps {
  onCancel?: () => void;
  onBack: () => void;
  onSubmit: () => void;
  proxy: string;
}

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    accordionSummary: {
      padding: "10px 15px",
      margin: 0,
      "&.Mui-expanded": {
        margin: " 0 !important",
        padding: "0 15px",
      },
    },
    accordionDetails: {
      padding: 0,
    },
    root: {
      padding: spacing(2),
      width: "100%",
    },
    title: {
      marginBottom: "15px",
    },
    container: {
      marginBottom: "10px",
    },
    createForm: {
      padding: spacing(3),
      align: "right",
    },
  })
);
const ProxyVerificationReview: FC<ProxyVerificationProps> = ({
  proxy,
  onSubmit,
  onBack,
}) => {
  const getApprovalVerificationDetails = (
    proxyVerification: ProxyVerificationInfo
  ) => {
    return proxyVerification;
  };
  const [response, { subscribe }] = useAsyncAction(
    () => ProxyVerificationService.forGetStarted(proxy),
    {
      mapFn: getApprovalVerificationDetails,
    }
  );
  useEffect(() => {
    const unsubscribe = subscribe();
    return () => unsubscribe();
  }, [subscribe]);
  const classes = useStyles();
  const [] = useState(false);
  const confirmDialogRef = useRef<ReviewerDialogRef>();

  const onReviewSubmit = useCallback(
    () => confirmDialogRef?.current?.open(),
    []
  );
  const onReviewBack = useCallback(() => onBack(), []);
  const NotificationMessage = () => {
    return <FormattedMessage defaultMessage="Request Submitted Successfully" />;
  };
  const notificationContext = useContext(SnackNotificationContext);
  const onSuccess = useCallback(() => {
    notificationContext.show({
      id: "review-submit",
      message: <NotificationMessage />,
    });
    confirmDialogRef?.current?.close();
    onSubmit();
  }, []);

  return (
    <>
      <ReviewerDialog
        ref={confirmDialogRef}
        proxy={proxy}
        onSuccess={onSuccess}
      />
      <AsyncLoader state={response} loader={<> </>}>
        <div>
          <Accordion expanded={true}>
            <AccordionSummary className={classes.accordionSummary}>
              <AccordionSummaryTitle
                controls={
                  <ActionInliner padding="inset-top">
                    <Button
                      type="button"
                      onClick={onReviewBack}
                      variant="outlined"
                      disableElevation
                    >
                      {" "}
                      <FormattedMessage defaultMessage="Back" />
                    </Button>

                    <Button
                      type="button"
                      onClick={onReviewSubmit}
                      variant="contained"
                      color="primary"
                      disableElevation
                    >
                      {" "}
                      <FormattedMessage defaultMessage="Submit" />
                    </Button>
                  </ActionInliner>
                }
              >
                Review & Submit
              </AccordionSummaryTitle>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <AccordionContent
                size="small"
                header={
                  <div>
                    <InlineIconFormatter icon={<></>}>
                      <FormattedMessage defaultMessage="API Quality/Maturity" />
                    </InlineIconFormatter>
                  </div>
                }
              >
                <ProxyVerificationApiQualityView
                  proxyVerificationApiQualityInfo={response.value}
                />
              </AccordionContent>
            </AccordionDetails>
            <AccordionDetails className={classes.accordionDetails}>
              <AccordionContent
                size="small"
                header={
                  <div>
                    <InlineIconFormatter icon={<> </>}>
                      <FormattedMessage defaultMessage="Security & Compliance" />
                    </InlineIconFormatter>
                  </div>
                }
              >
                <ProxyVerificationSecurityComplianceView
                  proxyVerificationSecurityInfo={response.value}
                />
              </AccordionContent>
            </AccordionDetails>
            <AccordionDetails className={classes.accordionDetails}>
              <AccordionContent
                size="small"
                header={
                  <div>
                    <InlineIconFormatter icon={<></>}>
                      <FormattedMessage defaultMessage="Performance & Documentation" />
                    </InlineIconFormatter>
                  </div>
                }
              >
                <ProxyVerificationPerformanceDocView
                  proxyVerificationPerformanceInfo={response.value}
                />
              </AccordionContent>
            </AccordionDetails>
            <AccordionDetails className={classes.accordionDetails}>
              <AccordionContent
                size="small"
                header={
                  <div>
                    <InlineIconFormatter icon={<></>}>
                      <FormattedMessage defaultMessage="Support" />
                    </InlineIconFormatter>
                  </div>
                }
                actions={
                  <ActionInliner padding="inset-top">
                    <Button
                      type="button"
                      onClick={onReviewBack}
                      variant="outlined"
                      disableElevation
                    >
                      {" "}
                      <FormattedMessage defaultMessage="Back" />
                    </Button>

                    <Button
                      type="button"
                      onClick={onReviewSubmit}
                      variant="contained"
                      color="primary"
                      disableElevation
                    >
                      {" "}
                      <FormattedMessage defaultMessage="Submit" />
                    </Button>
                  </ActionInliner>
                }
              >
                <ProxyVerificationSupportView
                  proxyVerificationSupportInfo={response.value}
                />
              </AccordionContent>
            </AccordionDetails>
          </Accordion>
        </div>
      </AsyncLoader>
    </>
  );
};

ProxyVerificationReview.propTypes = {
  proxy: PropTypes.string.isRequired,
};

export default ProxyVerificationReview;
