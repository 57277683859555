import React, { PropsWithChildren, memo } from 'react'
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import InfoIcon from '@material-ui/icons/Info';
interface FormAlertProps {
    severity: "error"|"success" | "info";
}

const useStyles = makeStyles(({ spacing, palette, typography }) =>
    createStyles({
        root: {
            width: '95%',
            display: "flex",
            padding: spacing(0.75, 2),
            border: "1px solid #ccc",
            borderLeftWidth: "8px",
            margin: spacing(2, 2),
            borderRadius:".125rem"
        },
        success: {
            borderColor: "#4a821f",
            color: "#4a821f"
        },
        error: {
           borderColor: "#c81922",
            color: "#c81922"
        },
        info: {
            color:"#005f9e",
            borderColor:"#005f9e"
        },
        message: {
            display:"flex",
            padding: spacing(1, 0),
        },
        title: {
            marginTop: "-2px",
            paddingRight: spacing(1),
            fontWeight: typography.fontWeightMedium
        },
        icon: {
            display: "flex",
            opacity: 0.9,
            padding: spacing(1, 0),
            marginRight: spacing(1.5)
        }
    }),
);

function FormAlert({ children, severity }: PropsWithChildren<FormAlertProps>) {
    const styles = useStyles();

    return (
        <div className= {clsx(styles.root, {
            [styles.success]: severity === "success",
            [styles.error]: severity === "error",
            [styles.info]: severity === "info"
        })}>
            <div className={styles.icon}>
                {severity ==="error" && <ErrorOutlineIcon />}
                {severity ==="success" && <CheckIcon/>}
                {severity ==="info" && <InfoIcon/>}
            </div>
            <div className={styles.message}>
                <span >
                    <Typography className={styles.title} variant="body1">
                        {severity ==="error" && <FormattedMessage defaultMessage="Error! " /> }
                        {severity ==="success" && <FormattedMessage defaultMessage="Success! " /> }
                    </Typography>
                </span>
                <span>{children}</span>
                
            </div>
        </div>
    )
}

FormAlert.propTypes = {
    severity: PropTypes.oneOf(["error","success","info"]).isRequired,
    children: PropTypes.any.isRequired
}

export default memo(FormAlert);

