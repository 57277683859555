import React, { FC, PropsWithRef, forwardRef } from "react";
import CertificateList from "./CertificateList";
import CertificateSingleList from "./CertificateSingleList";
import { ResourceAbility } from "libs/security/authorization";


interface CertificateViewProps {

}



const CertificateView: FC<PropsWithRef<CertificateViewProps>> = forwardRef(({ }, ref) => {

    return (


        <ResourceAbility can="read" resource={({ Certificate }) => Certificate.list.environment["trimble-prod"]} fallBack={<CertificateSingleList />} >
            <CertificateList />
        </ResourceAbility >


    );
});

export default (CertificateView);