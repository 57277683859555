import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import AuthService from '../services/authService';

/**
 * This function check user authentication to access the component
 * @param {Component} - Component to be loaded after Privacy check
 * @param {boolean} - user logged in / not 
 * @param {Object} - Additional Component Attributes
 * @return route
 */
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }: any) => {

     return (
          <Route {...rest} render={(props: any) => (

               isAuthenticated ? (
                    <Component {...props} key="new-component">
                         {props.children}
                    </Component>
               ) : (
                         <Redirect to={{
                              pathname: '/login',
                              state: { from: props.location }
                         }} />
                    )
          )} />
     );
};

/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state: any) => {

     return {
          isAuthenticated: AuthService.isAuthenticated(state)
     };
};

export default connect(mapStateToProps)(PrivateRoute);