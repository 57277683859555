import React, { Ref, useCallback, useImperativeHandle, FC, PropsWithRef, forwardRef } from 'react';
import { Dialog, DialogTitle, makeStyles, createStyles, Typography } from '@material-ui/core';
import { useCompactDialog } from '../../../legacy-components/dialog/UseCompactDialog';
import {ICertificate} from "./CertificateService";
import { CertificateDeleteFormData } from './models/certificate.model';
import { FormattedMessage } from 'react-intl';
import CertificateDeleteForm from './forms/CertificateDeleteForm';

const useStyles = makeStyles(({ spacing }) => {
    return createStyles({
        header: {
            position: "relative"
        },
        typeText: {
            padding: spacing(3, 0, 2, 0)
        }
    })
});

export interface CertificateDeleteDialogRef {
    open(): void
}

interface CertificateDeleteDialogProps {
    ref?: Ref<CertificateDeleteDialogRef | undefined>,
    certificate: ICertificate,
    onSubmit: (data: CertificateDeleteFormData) => void
}

interface CertificateDeleteFormViewerProps {
    certificate: ICertificate,
    onCancel: () => void;
    onSubmit: (data: CertificateDeleteFormData) => void
}

const CertificateDeleteFormContent = ({ certificate }: {
    certificate: ICertificate
}) => {
    const styles = useStyles();
    const certificateFormatter = () => <strong>{certificate.description}</strong>;
    return (
        <>
            <Typography component="p">
                <FormattedMessage values={{
                    description: certificateFormatter
                }} defaultMessage="This action cannot be undone. This will permanently remove the <description>name</description> and cannot be accessed elsewhere." />
            </Typography>

            <Typography className={styles.typeText} variant="body2" component="p">
                <FormattedMessage values={{
                    description: certificateFormatter
                }} defaultMessage="Type DELETE to confirm." />
            </Typography>
        </>
    )
}

const CertificateDeleteFormViewer = ({ certificate, onSubmit, onCancel }: CertificateDeleteFormViewerProps) => {

    return (
        <CertificateDeleteForm certificate={certificate} formContent={<CertificateDeleteFormContent certificate={certificate} />} onCancel={onCancel} onSuccess={onSubmit} />
    )
}

const CertificateDeleteDialog: FC<PropsWithRef<CertificateDeleteDialogProps>> = forwardRef(({ certificate, onSubmit }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleSubmit = useCallback((data: CertificateDeleteFormData) => {
        setOpen(false);
        onSubmit(data);
    }, [onSubmit, setOpen]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));
    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >

            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Delete this certificate?" />
                </DialogTitle>
            </div>
            <CertificateDeleteFormViewer certificate={certificate} onCancel={handleClose} onSubmit={handleSubmit} />
        </Dialog>
    );
});

CertificateDeleteDialog.propTypes = {
   
}

export default CertificateDeleteDialog

