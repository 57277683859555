import React, { memo } from 'react'
import FormViewInliner from '../../../../legacy-components/form/container/FormViewInliner';
import { Typography, Tooltip, IconButton, Chip, makeStyles, Theme, createStyles } from '@material-ui/core';
import CopyToClipboard from '../../../../legacy-components/form/action/CopyToClipboard';
import { AccordionContent } from '../../../../legacy-components/form/components/accordion-form/AccordionForm';
import { IAPIProduct } from '../../../../libs/resources/product/ProductService';
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import NAWrap from '../../../../legacy-components/form/action/NAWrap';
import { ProductVisibilityViewer, ProductComplianceViewer } from '../product-forms/ProductDetailInfo';
import { ProductBasicEdit } from '../product-forms/ProductBasicEdit';
import { ResourceAbility, useResourceAbility } from 'libs/security/authorization';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { sanitizeText } from '../product-forms/FileXSSRemover';

interface ProductDetailsViewProps {
    product: IAPIProduct,
    onSuccessEdit: (data?: any) => void
}


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: "100%",
            maxWidth: "auto",
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
        appLink: {
            textDecoration: 'none',
            color: '#00437B !important',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    }),
);

function ProductDetailsView({ product, onSuccessEdit }: ProductDetailsViewProps) {
    const CopyProductName = <CopyToClipboard size="small" edge="end" text={product.name} />;
    const CopyProductDisplayName = <CopyToClipboard size="small" edge="end" text={product?.displayName ? product.displayName : "Not Available"} />;
    const compliance = [];
    if (product.iso)
    {
        compliance.push("iso")
    }
    if (product.soc2)
    {
        compliance.push("soc2");
    }
    if (product.soc3)
    {
        compliance.push("soc3");
    }
    if (product.tsdcl)
    {
        compliance.push("tsdcl");
    }
    const productUpdate = {
        displayName : sanitizeText(product.displayName),
        shortDescription : sanitizeText(product.shortDescription),
        longDescription : sanitizeText(product.longDescription),
        visibility : product.visibility,
        compliance : compliance,
        apiTypeMapping: product.apiTypeMapping == null ? [] : product.apiTypeMapping
    }

    const onSuccess = () => {
        onSuccessEdit();
    }

    const history = useHistory();

    const onClick = () => {
        history.push(`/home/marketplace/product/${product.name}`);
    }

    const headerControls =

        (
            <ResourceAbility can="create" resource={({ Product }) => Product.create}>
                <ProductBasicEdit product={productUpdate} productName={product.name} onSuccess={onSuccess}></ProductBasicEdit>
            </ResourceAbility>
        );
    const [hasInvite] = useResourceAbility({
        resource: ({ TeamMembers }) => TeamMembers.invite,
    });
    const classes = useStyles();
    const canShowNewProducts = process.env.REACT_APP_NEW_PRODUCT_DISCOVERY === 'true';
    return (
        <AccordionContent controls={headerControls}>
            <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Name" />} controls={CopyProductName}>
                <Typography variant="body1" className="no-overflow">
                    <NAWrap value={product.name} showMessage={true}>
                        {(
                            product?.publishStatus === "COMPLETED" ? 
                                <>
                                    <Tooltip title="View Product in Marketplace">
                                        <NavLink className={classes.appLink} to={canShowNewProducts? `/home/consumer/products/${product.id}` : `/home/marketplace/product/${product.name}`}>
                                            {product.name}
                                        </NavLink>
                                    </Tooltip>
                                </> :  
                                <>
                                    {product.name}
                                </>
                        )}
                    </NAWrap>
                </Typography>
            </FormViewInliner>

            <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Display Name" />} controls={CopyProductDisplayName}>
                <Typography variant="body1" className="no-overflow">
                    <NAWrap value={product.displayName} showMessage={true}>
                        {sanitizeText(product.displayName)}
                    </NAWrap>
                </Typography>
            </FormViewInliner>

            <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Short Description" />}>
                <Typography variant="body1" className="no-overflow">
                    <NAWrap value={product.shortDescription} showMessage={true}>
                    {product?.shortDescription?.split('\n').map(str => <p>{sanitizeText(str)}</p>)}
                    </NAWrap>
                </Typography>
            </FormViewInliner>

            <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Long Description" />}>
                <Typography variant="body1" className="no-overflow">
                    <NAWrap value={product.longDescription} showMessage={true}>
                    {product?.longDescription?.split('\n').map(str => <p>{sanitizeText(str)}</p>)}
                    </NAWrap>
                </Typography>
            </FormViewInliner>

            <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="API Type" />}>
                <Typography variant="body1">
                <NAWrap value={product.apiTypeMapping} showMessage={true}>
                <div className={classes.chips}>
                        {product.apiTypeMapping?.map((value) => (
                            <Chip key={value} label={value} className={classes.chip} />
                        ))}
                    </div>
                    </NAWrap>
                </Typography>
            </FormViewInliner>
            {(hasInvite?.create() &&
            <>
            <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Created By" />}>
                <Typography variant="body1" className="no-overflow">
                    <NAWrap value={product.createdBy} showMessage={true}>
                        {product.createdBy}
                    </NAWrap>
                </Typography>
            </FormViewInliner>

            <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Updated By" />}>
                <Typography variant="body1" className="no-overflow">
                    <NAWrap value={product.updatedBy} showMessage={true}>
                        {product.updatedBy}
                    </NAWrap>
                </Typography>
            </FormViewInliner>
            </>
            )}

            <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Visibility" />}>
                <Typography variant="body1">
                    <ProductVisibilityViewer product={product} />
                </Typography>
            </FormViewInliner>

            <FormViewInliner title={<FormattedMessage defaultMessage="Compliance" />}>
                <Typography variant="body1">
                    <ProductComplianceViewer product={product} />
                </Typography>
            </FormViewInliner>



        </AccordionContent>
    )
}

ProductDetailsView.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }).isRequired
}

export default memo(ProductDetailsView);

