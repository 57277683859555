import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Typography, Link } from "@material-ui/core";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import NAWrap from "legacy-components/form/action/NAWrap";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import { ProxyVerificationSecurityInfo } from "pages/home/proxy/summary/verification/models/verification.model";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

interface ProxyVerificationSecurityComplianceViewProps {
  proxyVerificationSecurityInfo?: ProxyVerificationSecurityInfo | null;
  onSuccess?: () => void;
}
const ProxyVerificationSecurityComplianceView: FC<ProxyVerificationSecurityComplianceViewProps> =
  ({ proxyVerificationSecurityInfo }) => {
    return (
      <React.Fragment>
        <FormInputContainer
          title={
            <React.Fragment>
              <Typography
                variant="subtitle2"
                children={<FormattedMessage defaultMessage="TSDLC" />}
              />
              <Typography variant="body2">
                <FormattedMessage defaultMessage="Do you do Open Source Scanning via WhiteSource or similar? * " />
              </Typography>
            </React.Fragment>
          }
        >
          <FormViewInliner variant="list">
            <Typography variant="body1">
              <NAWrap
                value={proxyVerificationSecurityInfo?.securityTsdlcWhitesource}
                showMessage={true}
              >
                {proxyVerificationSecurityInfo?.securityTsdlcWhitesource ? (
                  <DoneIcon color="primary" />
                ) : (
                  <CloseIcon color="primary" />
                )}
              </NAWrap>
            </Typography>
          </FormViewInliner>
        </FormInputContainer>
        <FormInputContainer
          title={
            <React.Fragment>
              <Typography variant="body2">
                <FormattedMessage defaultMessage="Do you do static code analysis scanning via Veracode or similar? *" />
              </Typography>
            </React.Fragment>
          }
        >
          <FormViewInliner variant="list" gutter="gutter-bottom">
            <Typography variant="body1">
              <NAWrap
                value={proxyVerificationSecurityInfo?.securityTsdlcVeracode}
                showMessage={true}
              >
                {proxyVerificationSecurityInfo?.securityTsdlcVeracode ? (
                  <DoneIcon color="primary" />
                ) : (
                  <CloseIcon color="primary" />
                )}
              </NAWrap>
            </Typography>
          </FormViewInliner>
        </FormInputContainer>
        <FormInputContainer
          title={
            <React.Fragment>
              <Typography variant="body2">
                <FormattedMessage defaultMessage="Do you do Dynamic and/or API vulnerability scanning via Rapid7 or similar? *" />
              </Typography>
            </React.Fragment>
          }
        >
          <FormViewInliner variant="list" gutter="gutter-bottom">
            <Typography variant="body1">
              <NAWrap
                value={proxyVerificationSecurityInfo?.securityTsdlcRapid7}
                showMessage={true}
              >
                {proxyVerificationSecurityInfo?.securityTsdlcRapid7 ? (
                  <DoneIcon color="primary" />
                ) : (
                  <CloseIcon color="primary" />
                )}
              </NAWrap>
            </Typography>
          </FormViewInliner>
        </FormInputContainer>
        <FormInputContainer
          title={
            <React.Fragment>
              <Typography variant="body2">
                <FormattedMessage defaultMessage="Are all of the above done at least quarterly? *" />
              </Typography>
            </React.Fragment>
          }
        >
          <FormViewInliner variant="list" gutter="gutter-bottom">
            <Typography variant="body1">
              <NAWrap
                value={proxyVerificationSecurityInfo?.securityTsdlcDoneQuaterly}
                showMessage={true}
              >
                {proxyVerificationSecurityInfo?.securityTsdlcDoneQuaterly ? (
                  <DoneIcon color="primary" />
                ) : (
                  <CloseIcon color="primary" />
                )}
              </NAWrap>
            </Typography>
          </FormViewInliner>
        </FormInputContainer>
        <FormInputContainer
          title={
            <React.Fragment>
              <Typography
                variant="subtitle2"
                children={<FormattedMessage defaultMessage="Data Privacy" />}
              />
              <Typography variant="body2">
                <FormattedMessage defaultMessage="Does your API Proxy handle PII? *" />
              </Typography>
            </React.Fragment>
          }
        >
          <FormViewInliner variant="list" gutter="gutter-bottom">
            <Typography variant="body1">
              <NAWrap
                value={proxyVerificationSecurityInfo?.securityDataPrivacyRopa}
                showMessage={true}
              >
                {proxyVerificationSecurityInfo?.securityDataPrivacyRopa ? (
                  <Link
                    color="primary"
                    href={
                      proxyVerificationSecurityInfo?.securityDataPrivacyRopa
                    }
                    target="_blank"
                  >
                    {proxyVerificationSecurityInfo?.securityDataPrivacyRopa}
                  </Link>
                ) : (
                  <CloseIcon color="primary" />
                )}
              </NAWrap>
            </Typography>
          </FormViewInliner>
        </FormInputContainer>

        <FormInputContainer
          title={
            <React.Fragment>
              <Typography
                variant="subtitle2"
                children={
                  <FormattedMessage defaultMessage="Data Sovereignty" />
                }
              />
              <Typography variant="body2">
                <FormattedMessage defaultMessage="Does your API Proxy have the ability to transfer or replicate data to more than one region? *" />
              </Typography>
            </React.Fragment>
          }
        >
          <FormViewInliner variant="list" gutter="gutter-bottom">
            <Typography variant="body1">
              <NAWrap
                value={proxyVerificationSecurityInfo?.securityDataSovereignty}
                showMessage={true}
              >
                {proxyVerificationSecurityInfo?.securityDataSovereignty ? (
                  <>
                    {proxyVerificationSecurityInfo?.dataSovereigntyRegion?.join(
                      ", "
                    )}
                  </>
                ) : (
                  <CloseIcon color="primary" />
                )}
              </NAWrap>
            </Typography>
          </FormViewInliner>
        </FormInputContainer>
      </React.Fragment>
    );
  };

ProxyVerificationSecurityComplianceView.propTypes = {};

export default ProxyVerificationSecurityComplianceView;
