import React, { memo, useState, FC, useCallback, useEffect, Fragment } from 'react'
import FormContainer, { FormContainerChild } from '../../../../legacy-components/form/container/FormContainer';
import { Field } from 'react-final-form';
import ProductDocumentationInfo from '../product-forms/document-configs/ProductDocumentationInfo';
import { ProductDocumentFieldFileInfo } from '../product-forms/document-configs/ProductDocumentField';
import FormFileInput, { FormFileInputContentProps } from '../../../../legacy-components/form/input/FormFileInput';
import ProductDocumentFieldInput from '../product-forms/document-configs/ProductDocumentFieldInput';
import { OnChange } from 'react-final-form-listeners';
import { AccordionContent } from '../../../../legacy-components/form/components/accordion-form/AccordionForm';
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { IAPIProduct } from '../../../../libs/resources/product/ProductService';
import { ProductPublishFormData } from '../models/products.model';
import { useProductDocumentationInfo } from '../product-forms/ProductPublishInfo';
import SpecDocumentationService from '../../../../libs/resources/documentation/DocumentationService';
import { useAsyncAction, AsyncLoader } from '../../../../legacy-components/form/action/AsyncAction';
import { Box, Divider, Paper, List, Avatar, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

interface ProductDocumentationViewProps {
    product: IAPIProduct;
}

interface DocumentationPickerFormProps {
    accept: string[];
    onChange: (value: File[]) => void;
    docInfo: ProductDocumentFieldFileInfo;
}

const DocumentPickerSkeleton = () => {
    const [values] = useState(Array.from(Array(3).keys()));

    return (
        <>
            <Box width="100%">
                <Paper variant="outlined">
                    <List dense>
                        {
                            values.map(value => {
                                return (
                                    <Fragment key={value}>
                                        {value !== 0 && <Divider variant="inset" component="li" />}
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <Skeleton variant="circle" width={24} height={24} />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={<Skeleton width={200} />} secondary={<Skeleton width={120} />} />
                                            <ListItemSecondaryAction>
                                                <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                    <Box>
                                                        <Skeleton width={100} height={24} />
                                                    </Box>

                                                    <Box paddingLeft="16px">
                                                        <Skeleton variant="circle" width={24} height={24} />
                                                    </Box>
                                                </Box>

                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </Fragment>
                                )
                            })
                        }
                    </List>
                </Paper>
            </Box>
        </>
    )
}


const DocumentationPickerForm: FC<DocumentationPickerFormProps> = ({ accept, onChange, docInfo }) => {
    const [dataFields] = useState(["__documentationInfo"]);

    const onSubmit = useCallback(() => () => Promise.resolve(), [])

    const FormComponents: FormContainerChild<ProductPublishFormData> = () => {
        return (
            <>
                <Field
                    name="documentation"
                    children={FormFileInput}
                    accept={accept}
                    boxContent={(props: FormFileInputContentProps) => <ProductDocumentFieldInput {...props} docInfo={docInfo} multiple={true} />}
                />
                <OnChange name="documentation">
                    {(value: File[]) => onChange(value)}
                </OnChange>
            </>
        );
    }

    return <FormContainer FormProps={{ onSubmit }} dataFields={dataFields} children={FormComponents} />;
}

function ProductDocumentationView({ product }: ProductDocumentationViewProps) {
    const { accept, docInfoDispatch, docInfoState: { docInfo }, getMIMEType } = useProductDocumentationInfo();

    const [response, { subscribe }] = useAsyncAction(() => SpecDocumentationService.forProduct(product.name,), {
        mapFn: values => {
            return values.map(value => {
                const publishedGateways = (product.openApiSpecGateway ?? []).filter(gatewayInfo => gatewayInfo.openApiSpecId === value.id).map(value => value.gatewayName);

                return {
                    id: value.id,
                    fileInfo: {
                        filename: value.fileName ?? value.name,
                        type: getMIMEType(value.fileName) ?? "",
                    },
                    loaded: true,
                    openApiSpecId: value.id,
                    publishedGateways
                }
            })
        }
    });

    const onChange = useCallback((value: File[]) => {
        docInfoDispatch({
            type: "add",
            payload: {
                value: value.map(file => {
                    return {
                        id: file.name,
                        fileInfo: {
                            filename: file.name,
                            type: getMIMEType(file.name) ?? "",
                        },
                        loaded: false,
                        file,
                        isNew: true
                    }
                })
            }
        });

    }, [docInfoDispatch, getMIMEType]);

    useEffect(() => {
        const unsubscribe = subscribe()

        return () => unsubscribe();
    }, [subscribe]);

    useEffect(() => docInfoDispatch({
        type: "set",
        payload: {
            value: response.value
        }
    }), [docInfoDispatch, response.value])

    return (
        <AccordionContent size="small" header={<FormattedMessage defaultMessage="Documentation" />}>
            <AsyncLoader state={response} loader={<DocumentPickerSkeleton />}>
                <ProductDocumentationInfo dispatcher={docInfoDispatch} productName={product.name} docInfo={docInfo}>
                    <DocumentationPickerForm accept={accept} docInfo={docInfo[0]} onChange={onChange} />
                </ProductDocumentationInfo>
            </AsyncLoader>
        </AccordionContent>
    )
}

ProductDocumentationView.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }).isRequired
}

export default memo(ProductDocumentationView);

