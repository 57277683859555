import DataRemover from "modus/components/misc/DataRemover";
import ApplicationService, { IdentityApplication } from "../../services/ApplicationService";
import { useSelector } from "react-redux";
import { EnvironmentType } from "header/environment-selector/ENV_CONSTANTS";
import React, { useContext, useEffect } from "react";
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import { FormattedMessage } from "react-intl";
import { useAsyncAction } from "legacy-components/form/action/AsyncAction";


interface ApplicationRemoverProps {
    application: IdentityApplication;
    iconOnly?: boolean;
    onSuccess?: ()=>void;
}
export const ApplicationRemover: React.FC<ApplicationRemoverProps> = ({ application,onSuccess,  iconOnly=false }) => {
    const history = useHistory();
    const notification = useContext(SnackNotificationContext);

  
    const identityEnvironment = useSelector(
        (state: any) => state?.common?.identityEnvironment
      );
      
    const [deleteState, {subscribe} ] = useAsyncAction(() =>
        ApplicationService.delete(
          identityEnvironment as EnvironmentType,
          application?.applicationName as string,
        )
      );
      const isResource: boolean = application?.applicationType?.name === "resource"; 


  useEffect(() => {
    if (!deleteState) {
      return
    }
    if (deleteState.error) {
      notification.show({
        id: "deletestate-error",
        message: deleteState.value as any,
        timeout: 4000
    })

    }
    if (deleteState.loaded ) {
      notification.show({
        id: "deletestate-success",
        message: <FormattedMessage defaultMessage="Deleted successfully." />,
        timeout: 4000
    });
    onSuccess && onSuccess();

    }
  },[deleteState, notification]);


return (
<DataRemover
            style={{ backgroundColor: !isResource ? "#DA212C" : "#00000042", color: "#FFFFFF", border: 'none'}}
            confirmationText={application?.applicationName}
            enterButtonText={iconOnly ? undefined : "Delete Application"}
            onConfirm={subscribe}
            asyncActionState={deleteState}
            tooltipText={"This will delete the application permanently."}
            title="Delete Application"
            successMessage="Deleted Successfully"
            onSuccess={()=>{history.push("/home/applications/list")}}
            icon={iconOnly?undefined: <span />}
            disabled = {isResource}
            message={
                <Box>
                    <Alert severity="error">Deleting an application will break any current integrations using this application.</Alert>
                    <Box marginTop={"30px"}>
                    This action cannot be undone. This will permanently remove the application "{application?.applicationName}" and cannot be accessed elsewhere.
                    <br/>
                    </Box>
                </Box>
            }
            />
        );
    }