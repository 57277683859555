import React, { memo } from "react";
import { SvgIconProps, SvgIcon } from "@material-ui/core";

function DeleteIcon(props: SvgIconProps) {
    return (
        <SvgIcon  viewBox="0 0 15 18" fill="none" {...props}>
            <path d="M10.6169 0.711719L10.2229 0.301719C10.0403 0.111719 9.80001 0.0117188 9.54053 0.0117188H5.533C5.27352 0.0117188 5.03326 0.121719 4.85066 0.301719L4.45664 0.711719C4.27404 0.901719 4.03378 1.00172 3.7743 1.00172H1.76573C1.23715 1.00172 0.804688 1.45172 0.804688 2.00172C0.804688 2.55172 1.23715 3.00172 1.76573 3.00172H13.2982C13.8268 3.00172 14.2592 2.55172 14.2592 2.00172C14.2592 1.45172 13.8268 1.00172 13.2982 1.00172H11.2896C11.0301 1.00172 10.7995 0.891719 10.6169 0.711719ZM3.6878 18.0017H11.3761C12.4333 18.0017 13.2982 17.1017 13.2982 16.0017V4.00172H1.76573V16.0017C1.76573 17.1017 2.63066 18.0017 3.6878 18.0017ZM9.01196 5.78172H9.89612V16.0017H9.01196V5.78172ZM5.1678 5.78172H6.05196V16.0017H5.1678V5.78172Z" fill={props.style?.fill ?? props?.style?.color ?? "inherit"}/>
        </SvgIcon>
    );
}

export default memo(DeleteIcon);
