import React, { FC, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import GenericPageHeader from "legacy-components/page-headers/GenericPageHeader";
import InlineIconFormatter from "legacy-components/formatters/InlineIconFormatter";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import Helper from "pages/home/helper/Helper";
import VerificationHelper from "pages/home/helper/VerificationHelper";
import {
  ProxyVerificationInfo,
  ProxyVerificationApiQualityInfo,
  ProxyVerificationSupportInfo,
  ProxyVerificationSecurityInfo,
  ProxyVerificationPerformanceInfo,
} from "./models/verification.model";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {
  AccordionSummaryTitle,
  AccordionContent,
} from "legacy-components/form/components/accordion-form/AccordionForm";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SecurityComplianceForm from "./forms/SecurityComplianceForm";
import PerformanceDocumentationForm from "./forms/PerformanceDocumentationForm";
import SupportForm from "./forms/SupportForm";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ProxyVerificationService from "./ProxyVerificationService";
import ProxyVerificationReview from "./ProxyVerificationReview";
import { Button, Typography } from "@material-ui/core";
import { Approval } from "pages/home/approval/model/approval.model";
import ActionInliner from "../../../../../legacy-components/form/action/ActionInliner";
import {
  useAsyncAction,
  AsyncLoader,
} from "legacy-components/form/action/AsyncAction";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import ApiQualityForm from "./forms/ApiQualityForm";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

interface ProxyVerificationProps {
  proxyVerification: ProxyVerificationInfo;
  proxy: string;
  onSuccess?: (data: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    accordionSummary: {
      padding: "10px 15px",
      margin: 0,
      "&.Mui-expanded": {
        margin: " 0 !important",
        padding: "0 15px",
      },
      align: "center",
    },
    accordionDetails: {
      display: "block !important",
      padding: 0,
    },
    root: {
      textAlign: "center",
      width: "100%",
    },
    title: {},
    container: {
      marginBottom: "10px",
    },
    container1: {
      marginBottom: "10px",
      width: "75%",
    },
    secondaryHeading: {
      float: "right",
    },
    skeleton: {
      align: "center",
      width: "60%",
      margin: "120px",
    },
  })
);
const ProxyVerification: FC<ProxyVerificationProps> = ({
  proxy,
  onSuccess,
}) => {
  const [isFormCompleted, setIsFormCompleted] = useState<string>();
  const [isShowReview, setIsShowReview] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasVerified, setHasVerified] = useState<boolean | undefined | null>(
    null
  );
  const [proxyVerificationApiQualityInfo, setProxyVerificationApiQualityInfo] =
    useState<ProxyVerificationApiQualityInfo>();
  const [proxyVerificationSecurityInfo, setProxyVerificationSecurityInfo] =
    useState<ProxyVerificationSecurityInfo>();
  const [proxyVerificationSupportInfo, setProxyVerificationSupportInfo] =
    useState<ProxyVerificationSupportInfo>();
  const [
    proxyVerificationPerformanceInfo,
    setProxyVerificationPerformanceInfo,
  ] = useState<ProxyVerificationPerformanceInfo>();
  const [approval, setApproval] = useState<Approval>();

  const onSuccessApproval = () => {
    setIsSubmitted(true);
    setIsShowReview(false);
    onSuccess && onSuccess(proxy);
  };
  const onSuccessForm = () => {
    setIsFormCompleted(Math.random().toString());
  };
  const onReviewBack = () => {
    setIsShowReview(false);
  };
  const getApprovalVerificationDetails = (
    proxyVerification: ProxyVerificationInfo
  ) => {
    setHasVerified(proxyVerification?.hasSubmitted);
    setProxyVerificationApiQualityInfo({
      apiQuality: proxyVerification?.apiQuality,
      additionalInput: proxyVerification?.apiQualityAdditionalInput,
    });
    setProxyVerificationSecurityInfo({
      securityTsdlcUrl: proxyVerification.securityTsdlcUrl,
      securityIso27001Url: proxyVerification.securityIso27001Url,
      securityCcpaUrl: proxyVerification.securityCcpaUrl,
      securityGdprUrl: proxyVerification.securityGdprUrl,
      securityAdditionalInput: proxyVerification?.securityAdditionalInput,
      securityTsdlcWhitesource: proxyVerification.securityTsdlcWhitesource,
      securityTsdlcVeracode: proxyVerification.securityTsdlcVeracode,
      securityTsdlcRapid7: proxyVerification.securityTsdlcRapid7,
      securityTsdlcDoneQuaterly: proxyVerification.securityTsdlcDoneQuaterly,
      securityDataPrivacyPii: proxyVerification.securityDataPrivacyPii,
      securityDataPrivacyRopa: proxyVerification.securityDataPrivacyRopa,
      securityDataSovereignty: proxyVerification.securityDataSovereignty,
      dataSovereigntyRegion: proxyVerification.dataSovereigntyRegion,
    });
    setProxyVerificationSupportInfo({
      supportWebsite: proxyVerification?.supportWebsite,
      supportEmail: proxyVerification?.supportEmail,
      supportAdditionalInput: proxyVerification?.supportAdditionalInput,
      supportType: proxyVerification?.supportType,
      supportProcessDesc: proxyVerification.supportProcessDesc,
      supportRoadMap: proxyVerification.supportRoadMap,
    });
    setProxyVerificationPerformanceInfo({
      performanceUptime: proxyVerification?.performanceUptime,
      performanceCapacity: proxyVerification?.performanceCapacity,
      performanceErrorRate: proxyVerification?.performanceErrorRate,
      performanceAverageLatency: proxyVerification?.performanceAverageLatency,
      performanceMetricsUrl: proxyVerification?.performanceMetricsUrl,
      performanceStatusPage: proxyVerification?.performanceStatusPage,
      performanceMetricsFiles: proxyVerification?.performanceMetricsFiles,
      performanceAdditionalInput: proxyVerification?.performanceAdditionalInput,
    });
    setApproval(proxyVerification.approval);
    return proxyVerification;
  };
  const [response, { subscribe }] = useAsyncAction(
    () => ProxyVerificationService.forGetStarted(proxy),
    {
      mapFn: getApprovalVerificationDetails,
    }
  );
  useEffect(() => {
    const unsubscribe = subscribe();

    return () => unsubscribe();
  }, [subscribe, isSubmitted, isFormCompleted]);

  const classes = useStyles();
  const [isShowVerificationHelper, toggleVerificationHelper] = useState(false);

  const onSubmitReview = () => {
    setIsShowReview(true);
  };
  let history = useHistory();
  const onTrack = (proxy: string, requestId?: string) => {
    history.push(`/home/approvals/api/${proxy}/${requestId}/review`);
  };
  const Review = () => {
    const canShowReview: boolean =
      proxyVerificationApiQualityInfo?.apiQuality &&
      proxyVerificationSecurityInfo?.securityTsdlcWhitesource &&
      proxyVerificationSecurityInfo?.securityTsdlcVeracode &&
      proxyVerificationSecurityInfo?.securityTsdlcRapid7 &&
      proxyVerificationSecurityInfo?.securityTsdlcDoneQuaterly &&
      proxyVerificationSecurityInfo?.securityDataPrivacyPii &&
      proxyVerificationSecurityInfo?.securityDataSovereignty !== null &&
      proxyVerificationPerformanceInfo?.performanceUptime &&
      proxyVerificationPerformanceInfo?.performanceAverageLatency &&
      proxyVerificationPerformanceInfo?.performanceStatusPage &&
      proxyVerificationSupportInfo?.supportType
        ? true
        : false;
    return (
      <Button
        onClick={onSubmitReview}
        disabled={!canShowReview || hasVerified === true}
        variant="contained"
        color="primary"
      >
        <FormattedMessage defaultMessage="Review & Submit" />
      </Button>
    );
  };
  const DocumentPickerSkeleton = () => {
    return (
      <>
        <div className={classes.skeleton}>
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </div>
      </>
    );
  };
  return (
    <AsyncLoader state={response} loader={<DocumentPickerSkeleton />}>
      <Container maxWidth="md" className={classes.container}>
        {/*approval?.action?.statusType === "PENDING_APPROVAL" ? (
          <Container maxWidth="md" className={classes.container1}>
            <Accordion expanded={true}>
              <AccordionSummary className={classes.accordionSummary}>
                <AccordionSummaryTitle>Success!</AccordionSummaryTitle>
                <CheckCircleIcon color="primary" />
              </AccordionSummary>

              <AccordionDetails className={classes.accordionDetails}>
                <AccordionContent size="small">
                  <div>
                    <p>
                      Your verification request for <b>{proxy}</b> has been
                      submitted to{" "}
                      <b>{approval?.reviewers[0]?.reviewer?.name}</b> for
                      review.
                    </p>
                    <p>
                      Please allow 5–7 days for the review process to be
                      completed.
                    </p>
                    <ActionInliner align="center" padding="inset-top">
                      <Button
                        type="button"
                        onClick={() => onTrack(proxy, approval?.requestId)}
                        variant="contained"
                        color="primary"
                      >
                        {" "}
                        <FormattedMessage defaultMessage="Track Progress" />
                      </Button>
                    </ActionInliner>
                  </div>
                </AccordionContent>
              </AccordionDetails>
            </Accordion>
          </Container>
        )*/}
        {isShowReview ? (
          <ProxyVerificationReview
            proxy={proxy}
            onSubmit={onSuccessApproval}
            onBack={onReviewBack}
          />
        ) : (
          hasVerified === true && (
            <div>
              <Alert severity="success">
                <AlertTitle>
                  <p>
                    Your request for verification of API <b>{proxy}</b> has been
                    submitted.
                  </p>
                </AlertTitle>
                <p>
                  You can still update the forms and make necessary changes.
                </p>
                <p>
                  <strong>Note:</strong> Your verification submission is subject
                  to review.
                </p>
              </Alert>
              {/* <p>
                      Your verification request for API <b>{proxy}</b> has been
                      submitted and verified.
                    </p>
                    <p>
                      Please continue to update forms to have the details
                      up-to-date.
                    </p>
                    <p>
                      Disclaimer: Your submission will be reviewed by the
                      respective stakeholders.
                    </p> */}
            </div>
          )
        )}
        {/* :  */}
        {isShowReview === false && (
          <>
            <GenericPageHeader
              controls={<Review />}
              title={
                <Typography variant="subtitle1" className={classes.title}>
                  <InlineIconFormatter
                    icon={
                      <HelpOutlineOutlinedIcon
                        onClick={() => {
                          toggleVerificationHelper(true);
                        }}
                      />
                    }
                  >
                    <FormattedMessage defaultMessage="Submission Details" />
                  </InlineIconFormatter>
                </Typography>
              }
            />
            <Helper
              open={isShowVerificationHelper}
              handleClose={() => {
                toggleVerificationHelper(false);
              }}
              children={<VerificationHelper />}
            ></Helper>

            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.accordionSummary}
                >
                  <AccordionSummaryTitle>API Quality</AccordionSummaryTitle>
                  {proxyVerificationApiQualityInfo?.apiQuality && (
                    <CheckCircleIcon color="primary" />
                  )}
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <ApiQualityForm
                    proxy={proxy}
                    proxyVerificationApiQualityInfo={
                      proxyVerificationApiQualityInfo
                    }
                    onSuccess={onSuccessForm}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.root}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.accordionSummary}
                >
                  <AccordionSummaryTitle>
                    Security & Compliance
                  </AccordionSummaryTitle>
                  {proxyVerificationSecurityInfo?.securityTsdlcWhitesource ===
                    true &&
                    proxyVerificationSecurityInfo?.securityTsdlcVeracode ===
                      true &&
                    proxyVerificationSecurityInfo?.securityTsdlcRapid7 ===
                      true &&
                    proxyVerificationSecurityInfo?.securityTsdlcDoneQuaterly ===
                      true &&
                    proxyVerificationSecurityInfo?.securityDataPrivacyRopa &&
                    proxyVerificationSecurityInfo?.securityDataSovereignty !==
                      null && <CheckCircleIcon color="primary" />}
                </AccordionSummary>

                <AccordionDetails className={classes.accordionDetails}>
                  <SecurityComplianceForm
                    proxy={proxy}
                    proxyVerificationSecurityInfo={
                      proxyVerificationSecurityInfo
                    }
                    onSuccess={onSuccessForm}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.accordionSummary}
                >
                  <AccordionSummaryTitle>
                    Performance & Documentation
                  </AccordionSummaryTitle>
                  {proxyVerificationPerformanceInfo?.performanceUptime &&
                    proxyVerificationPerformanceInfo?.performanceAverageLatency && (
                      <CheckCircleIcon color="primary" />
                    )}
                </AccordionSummary>

                <AccordionDetails className={classes.accordionDetails}>
                  <PerformanceDocumentationForm
                    proxy={proxy}
                    proxyVerificationPerformanceInfo={
                      proxyVerificationPerformanceInfo
                    }
                    onSuccess={onSuccessForm}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.accordionSummary}
                >
                  <AccordionSummaryTitle>Support</AccordionSummaryTitle>
                  {proxyVerificationSupportInfo?.supportType && (
                    <CheckCircleIcon color="primary" />
                  )}
                </AccordionSummary>

                <AccordionDetails className={classes.accordionDetails}>
                  <SupportForm
                    proxy={proxy}
                    proxyVerificationSupportInfo={proxyVerificationSupportInfo}
                    onSuccess={onSuccessForm}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </>
        )}
      </Container>
    </AsyncLoader>
  );
};

ProxyVerification.propTypes = {};

export default ProxyVerification;
