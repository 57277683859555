import { ApplicationConfigurationFormData, ApplicationSharedFormData } from "../models/application.model";
import PublisherApplicationService, { IdentityApplicationEnvironmentType, IdentityApplicationPublishType, IdentityApplicationTypeConfig, IdentityApplicationTypes } from "../PublisherApplicationService";
import { FC, useState, useContext } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { FormApi, FORM_ERROR } from "final-form";
import { FormRenderProps, Field } from "react-final-form";
import { ValidatorBuilderContext } from "../../../../../legacy-components/form/validator/Validator";
import PropTypes from 'prop-types';
import { useApplicationConfigurationInfo, ApplicationGrantTypeView } from "./ApplicationConfigurationInfo";
import { makeStyles, createStyles, Typography, Button } from "@material-ui/core";
import FormContainer, { FormContainerOptions } from "../../../../../legacy-components/form/container/FormContainer";
import React from "react";
import FormInputContainer from "../../../../../legacy-components/form/container/FormInputContainer";
import { FieldArray } from "react-final-form-arrays";
import FormSelectionList from "../../../../../legacy-components/form/input/FormSelectionList";
import FormConditionalField from "../../../../../legacy-components/form/input/FormConditionalField";
import FormTextInput from "../../../../../legacy-components/form/input/FormTextInput";
import ActionInliner from "../../../../../legacy-components/form/action/ActionInliner";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        actions: {
            padding: spacing(3),
        },
        content: {
            padding: spacing(3),
            overflow: "auto"
        }
    }),
);

interface ApplicationOAuthConfigurationFormProps {
    onCancel: () => void,
    onSuccess: (data: ApplicationConfigurationFormData) => void
    applicationName: string,
    env: IdentityApplicationEnvironmentType,
    initialValues?: ApplicationConfigurationFormData,
    publishType: IdentityApplicationPublishType,
    typeConfig?: IdentityApplicationTypeConfig
}

const ApplicationOAuthConfigurationForm: FC<ApplicationOAuthConfigurationFormProps> = ({ onCancel, onSuccess, initialValues, applicationName, env, publishType, typeConfig }) => {
    const validator = useContext(ValidatorBuilderContext);

    const intl = useIntl();
    const styles = useStyles();

    const { authenticationMethods: availableAuthenticationMethods, canShowUrls, canEnableUrls, makeUrls, authUrlValidator } = useApplicationConfigurationInfo();

    const [authenticationMethods] = useState(() => {

        return typeConfig ? availableAuthenticationMethods.filter(method => typeConfig.grants?.some(value => value === method.value)) : availableAuthenticationMethods;
    });

    const [dataFields] = useState(["__formResponse"]);

    const onSubmit = () => (data: ApplicationConfigurationFormData, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                if (applicationName && env) {
                    const canAllowUrls = canEnableUrls(data?.grantTypes ?? []);
                    let logoutUri: string[] = [];
                    let redirectUri: string[] = [];

                    if (canAllowUrls) {
                        logoutUri = makeUrls(data?.logoutUrls);
                        redirectUri = makeUrls(data?.callbackUrls);
                    }

                    PublisherApplicationService.environments[env].publish(publishType, applicationName, {
                        logoutUri,
                        redirectUri,
                        grantTypes: data?.grantTypes ?? []
                    }).then((result) => {
                        form.mutators.setValue("__applicationPublishInfo", {
                            application: result
                        });

                        resolve();
                    }, error => {
                        resolve({
                            [FORM_ERROR]: error
                        });
                    });
                } else {
                    resolve({
                        [FORM_ERROR]: intl.formatMessage({
                            defaultMessage: "Application Environment and Name is required"
                        })
                    });
                }
            }
        })
    }
    
    const FormComponents = ({ submitting, pristine, form }: FormRenderProps<ApplicationConfigurationFormData, {}>, containerOptions: FormContainerOptions<ApplicationConfigurationFormData, ApplicationSharedFormData>) => {
        const formState = form.getState();
        let callbackUrl = formState.values.callbackUrls;
        let callbackUrls = callbackUrl?.split(",");
        let callbackUrlsCount = callbackUrls ? callbackUrls.length : 0;
           
        let logoutUrl = formState.values.logoutUrls;
        let logoutUrls = logoutUrl?.split(",");
        let logoutUrlsCount = logoutUrls ? logoutUrls.length : 0;
        return (
            <>
                <div className={styles.content}>
                    <FormInputContainer title={<Typography variant="subtitle2" children={<ApplicationGrantTypeView applicationType={typeConfig?.type} />} />}>
                        <>
                            <FieldArray
                                name="grantTypes"
                                children={FormSelectionList}
                                options={authenticationMethods}
                                validate={validator.from({
                                    required: true
                                })}
                                helperText="grantTypes"
                            />
                        </>
                    </FormInputContainer>
                    <FormConditionalField observe="grantTypes" condition={canShowUrls}>
                        <Alert severity="info">
                            For allowed callback and logout urls, the limit is 250. 
                        </Alert>
                        <FormInputContainer title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Allowed Callback URLs" />} />}>
                            <Field
                                name="callbackUrls"
                                type="text"
                                multiline
                                rows={5}
                                component={FormTextInput}
                                placeholder={intl.formatMessage({
                                    defaultMessage: "Comma Seperated Values"
                                })}
                                validate={validator.from({
                                    required: true,
                                    custom: authUrlValidator
                                })}
                            />
                            <div style={{"float": "right"}}><strong>{`${callbackUrlsCount}`} </strong> {`out of 250 callback urls`}</div>
                        </FormInputContainer>

                        <FormInputContainer title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Allowed Logout URLs" />} />}>
                            <Field
                                name="logoutUrls"
                                type="text"
                                multiline
                                rows={5}
                                component={FormTextInput}
                                placeholder={intl.formatMessage({
                                    defaultMessage: "Comma Seperated Values"
                                })}
                                validate={validator.from({
                                    required: true,
                                    custom: authUrlValidator
                                })}
                            />
                             <div style={{"float": "right"}}><strong>{`${logoutUrlsCount}`} </strong> {`out of 250 logout urls`}</div>
                        </FormInputContainer>
                    </FormConditionalField>
                </div>

                <div className={styles.actions}>
                    <ActionInliner>
                        <Button onClick={onCancel} disabled={submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                        <Button disabled={submitting || pristine} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Save Changes" /></Button>
                    </ActionInliner>
                </div>
            </>);
    }

    return <FormContainer previewErrors={true} dataFields={dataFields} FormProps={{ onSubmit, initialValues }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

ApplicationOAuthConfigurationForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    applicationName: PropTypes.string.isRequired,
    env: PropTypes.oneOf<IdentityApplicationEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired,
    typeConfig: PropTypes.shape({
        type: PropTypes.oneOf<IdentityApplicationTypes>(["application", "service-application", "resource", 'resource-server']).isRequired,
        grants: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    })
}

export default ApplicationOAuthConfigurationForm;