import Table from "legacy-components/table/Table";
import React from "react";
import { IService } from "../subscriptions/pages/subscription-summary-page/SubscriptionSummaryPage";
import { Box, createStyles, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import ServiceIcon from "legacy-components/icons/ServiceIcon";

import { useCallback } from "react";
import { useHistory } from 'react-router';


interface ServiceListViewerProps {
    services: IService[];
    productId?: String;
}



const useServiceListStyles = makeStyles((theme: Theme) =>
    createStyles({
      iconCircle: {
        borderRadius: "50%",
        backgroundColor: "#217CBB",
        width: "48px",
        height: "48px",
        marginRight: "26px",
        display: "flex"
      },
      icon: {
        fill: "#fff", fontSize: "2.2rem", margin:"auto"
      },
    serviceItem: {
        borderBottom: "1px solid #E0E1E9",
        "&:first-child": {

        borderTop: "1px solid #E0E1E9",
        }
    }
    }),
  );

  
export const ServiceListViewer: React.FC<ServiceListViewerProps> = ({services, productId}) => {
    const classes = useServiceListStyles();
    const history = useHistory();
    const canShowNewProducts = process.env.REACT_APP_NEW_PRODUCT_DISCOVERY === 'true';

const onClickProduct = useCallback((productId) => {
    if(canShowNewProducts)
        history.push(`/home/consumer/products/${productId}`);
  }, [history]);


  return (
    <List>
        {
            services?.map((service: IService) => (
                <ListItem className={classes.serviceItem}>
                    <ListItemIcon>
                        <Box className={classes.iconCircle}>
                            <ServiceIcon className={classes.icon} />
                        </Box>
                    </ListItemIcon>
                    <ListItemText>
                        <Box>
                            <Typography variant="body1" style={{color: '#217CBB', fontWeight: "bold"}}>
                            <Link onClick={()=>onClickProduct(productId)} > 
                                {service.displayName ?? service.serviceName}
                                </Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1" style={{textAlign: "justify"}}>
                                {service.description}
                            </Typography>
                        </Box>
                        
                    </ListItemText>
                </ListItem>
            ))
        }
    </List>
  );
};
