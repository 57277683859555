import React, { useState, ReactElement, PropsWithChildren, useEffect, ReactNode, useCallback, memo } from 'react';
import { Stepper, Step, StepLabel, StepContent, withStyles, StepConnector, useTheme, makeStyles, createStyles, StepIconProps } from '@material-ui/core';
import StepperFormContent from './StepperFormContent';
import { StepperFormContext, createStepperFormContext } from './StepperFormContext';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import PropTypes from "prop-types";

const useStyles = makeStyles(theme =>
    createStyles({
        stepContentRoot: {
            borderLeftWidth: 3,
            borderColor: theme.palette.primary.main,
            marginLeft: 11
        },
        stepLabelRoot: {
            '& .MuiStepLabel-label': {
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightMedium
            }
        }
    }),
);

const useStepIconStyles = makeStyles(theme => createStyles({
    root: {
        display: 'flex',
        height: 24,
        width: 24,
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.getContrastText(theme.palette.grey[800]),
        textAlign: "center"
    },
    rootActive: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    },
    rootComplete: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main
    },
    completed: {
        color: theme.palette.secondary.contrastText,
        width: 20,
        height: 20,
        marginLeft: 2
    },
    icon: {
        width: "100%",
        height: "100%",
        fontSize: theme.typography.pxToRem(14),
        lineHeight: "26px"
    }
}));

export interface FormStepProps {
    children: ReactElement<{
        onSubmit: (...params: any) => void
    }>, key: string, stepId: string, label: ReactNode
}

function ThemedStepIcon(props: StepIconProps) {
    const styles = useStepIconStyles();
    const { active, completed, icon } = props;

    return (
        <div
            className={clsx(styles.root, {
                [styles.rootActive]: active,
                [styles.rootComplete]: completed,
            })}
        >
            {completed ? <Check className={styles.completed} /> : <div className={styles.icon}>{icon}</div>
            }
        </div>
    );
}

interface StepperFormProps {
    id: string;
    onComplete?: () => void
}

/**
 * @function StepperForm
 * @description StepperForm can be use to create wizard like forms, it will play nicely with the FormContainer and provides context to manage the form data with the different steps.
 */
const StepperForm = (props: PropsWithChildren<StepperFormProps>) => {
    const [activeStep, setActiveStep] = useState(0);
    const theme = useTheme();
    const styles = useStyles();

    const [stepperFormContext] = useState(createStepperFormContext(props.id));

    const [steps] = useState(React.Children.toArray(props.children) as React.ReactElement<FormStepProps>[]);

    const onStepChanges = useCallback(() => {
        if (steps.length > 0 && activeStep >= steps.length) {
            props.onComplete?.();
        }
    }, [steps, props, activeStep]);

    const ThemedStepConnector = withStyles({
        active: {
            '& $lineVertical': {
                borderColor: theme.palette.secondary.main
            }
        },
        completed: {
            '& $lineVertical': {
                borderColor: theme.palette.secondary.main
            }
        },
        vertical: {
            marginLeft: 11
        },
        lineVertical: {
            borderLeftWidth: 3,
            borderRadius: 1,
        },
    })(StepConnector);

    useEffect(() => onStepChanges(), [onStepChanges, activeStep])

    return <StepperFormContext.Provider value={stepperFormContext}>
        <Stepper activeStep={activeStep} orientation="vertical" connector={<ThemedStepConnector />}>
            {steps.map(({ props }, index) => {
                return (
                    <Step key={props.stepId}>
                        <StepLabel className={styles.stepLabelRoot} StepIconComponent={ThemedStepIcon}>{props.label}</StepLabel>
                        <StepContent className={styles.stepContentRoot}>
                            <StepperFormContent stepId={props.stepId} nextStepIndex={index + 1} prevStepIndex={index - 1} onStep={setActiveStep}>
                                {props.children}
                            </StepperFormContent>
                        </StepContent>
                    </Step>
                );
            })}
        </Stepper>
    </StepperFormContext.Provider>;
};

StepperForm.Step = ({ children }: FormStepProps) => children;

StepperForm.propTypes = {
    onComplete: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
};

export default memo(StepperForm);