import React from "react";
import { Dialog } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Helper = (props: any) => {
    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.handleClose}
            disableBackdropClick={false}
            fullWidth={true}
            maxWidth="md"
            className="helper"
        >
           {props.children}
        </Dialog>
    );

}
export default Helper;