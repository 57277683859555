import React, { FC } from 'react'
import FormContainer, { FormContainerOptions, FormContainerSubmitHandler } from '../../../../legacy-components/form/container/FormContainer';
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import { FormRenderProps, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Button, Typography } from '@material-ui/core';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { FORM_ERROR } from 'final-form';
import FormSelectionList from '../../../../legacy-components/form/input/FormSelectionList';
import FormSelectionGroup from '../../../../legacy-components/form/input/FormSelectionGroup';
import { FormattedMessage } from 'react-intl';
import UnifiedAPIService, { UnifiedAPI } from '../../../../services/UnifiedAPIService';
import { ProductDetailsFormData } from '../models/products.model';
import PropTypes from "prop-types";
import { useProductDetailInfo } from './ProductDetailInfo';

interface ProductDetailsFormProps {
    stepperId: string
}

const ProductDetailsForm: FC<ProductDetailsFormProps> = ({ stepperId }) => {
    const onSubmit: FormContainerSubmitHandler<ProductDetailsFormData> = ({ stepperOptions }) => (data, form) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                UnifiedAPIService.put(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.edit_product_api, {
                        id: stepperOptions?.formData?.about?.__productInfo.name
                    }), {
                    visibility: data.visibility ?? undefined,
                    iso: data.compliance?.some(value => value === "iso") ?? false,
                    soc2: data.compliance?.some(value => value === "soc2") ?? false,
                    soc3: data.compliance?.some(value => value === "soc3") ?? false,
                    tsdcl: data.compliance?.some(value => value === "tsdcl") ?? false
                }).then(() => resolve(), error => {
                    resolve({
                        [FORM_ERROR]: error.message
                    });
                });
            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>, containerOptions: FormContainerOptions) => {
        const { compilanceOptions, visibilityOptions } = useProductDetailInfo();

        return (<>
            <FormInputContainer padding="inset" title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Visibility" />} />}>
                <Field
                    name="visibility"
                    children={FormSelectionGroup}
                    options={visibilityOptions}
                    defaultSelectionIndex={0}
                />
            </FormInputContainer>
            <FormInputContainer padding="inset" title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Compliance" />} />}>
                <FieldArray
                    name="compliance"
                    children={FormSelectionList}
                    options={compilanceOptions}
                />
            </FormInputContainer>
            <ActionInliner padding="inset">
                {containerOptions.stepperOptions.navigateBack && <Button onClick={containerOptions.stepperOptions.navigateBack} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Back" /></Button>}

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Continue" /></Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer stepperId={stepperId} FormProps={{ onSubmit }} children={FormComponents} />;
}

ProductDetailsForm.propTypes = {
    stepperId: PropTypes.string.isRequired
}

export default ProductDetailsForm;