import { Link } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from 'services/authService';

const RegisterMessage = (props: any) => {
     const [seconds, setSeconds] = useState(10);
     
     const  id_token = useSelector((state: any) => state.auth?.user?.id_token);
     const dispatch = useDispatch();
     
     let interval: NodeJS.Timeout;
     useEffect(() => {
          interval = setInterval(() => {
               setSeconds((second: number) => second > 0 ? second - 1 : 0 );
             }, 1000);
     },[]);
     useEffect(() => {
          if(seconds === 0) {
              clearInterval(interval);
              AuthService.signOutRedirect(id_token);
          }
     },[seconds, dispatch, id_token]);
     
     
     return (
       <section className="flex-col-center">
            <section className="click-hider hide1"></section>
            <section className="click-hider hide2"></section>
            <Alert severity="info" className="mt-2">
            It looks like you do not have access to the Trimble Cloud Console currently.. <br/>
               <ul>
                    <li>You must be a Trimble employee to access the Trimble Cloud Console.</li>
                    <li>You must be registered as a user in the console by your Sector Admin. Learn more about how to get access &nbsp;
                         <Link target="_blank" href="https://docs.trimblecloud.com/apicloud/content/how-to-guides/api-access-cloudconsole/index.html">here</Link>.
                    </li>
               </ul>
            </Alert>
            
            <Alert severity="warning" className="mt-2">
                 For security reasons, We are logging out in {seconds} seconds and redirecting to  Landing page for further details.
               </Alert>
       </section>        
     );
}

export default (RegisterMessage);
