import React, { Ref, FC, PropsWithRef, forwardRef, useImperativeHandle } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import UserInviteForm from "./forms/userInviteForm";
import { FormattedMessage } from "react-intl";
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { makeStyles, createStyles } from "@material-ui/core";
import { ResourceAbility } from 'libs/security/authorization';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    content: {
      padding: spacing(3)
    }
  }),
);


export interface AddUserDialogRef {
  open(): void,
  close(): void
}
interface AddUserDialogProps {
  ref: Ref<AddUserDialogRef | undefined>,
  onSuccess: () => void;
}

const AddUserDialog: FC<PropsWithRef<AddUserDialogProps>> = forwardRef(({ onSuccess }, ref) => {
  const styles = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onSuccess();
  };

  useImperativeHandle(ref, () => ({
    open: () => handleClickOpen(),
    close: () => handleClose()
  }));
  return (
    <>
      <ResourceAbility can="create" resource={({ TeamMembers }) => TeamMembers.invite} >
        <Button startIcon={<PersonAddOutlinedIcon />} variant="contained" color="primary" onClick={handleClickOpen}>
          <FormattedMessage defaultMessage="Invite Member" />
        </Button>
      </ResourceAbility>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"><FormattedMessage defaultMessage="Invite Member" /></DialogTitle>
        <DialogContent className={styles.content}>
          <DialogContentText>
            <FormattedMessage defaultMessage=" The member you’re inviting must have a user account with Trimble identity." />
          </DialogContentText>
          <div>
            <UserInviteForm onCancel={handleClose} onSuccess={handleClose} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default (AddUserDialog);
