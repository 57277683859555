import React, { FC, PropsWithRef, forwardRef, useCallback, useImperativeHandle, memo, Ref } from 'react';
import { Dialog, makeStyles, createStyles, DialogTitle, DialogActions, Button, DialogContent, FormControl, InputLabel, InputAdornment, OutlinedInput } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import CopyToClipboard from '../../../../legacy-components/form/action/CopyToClipboard';
import { useCompactDialog } from '../../../../legacy-components/dialog/UseCompactDialog';

export interface UserTokenPickerDialogRef {
    open(): void
}

interface UserTokenPickerDialogProps {
    ref?: Ref<UserTokenPickerDialogRef | undefined>
    accessToken?: string
}

const useStyles = makeStyles(() => {
    return createStyles({
        header: {
            position: "relative"
        },
        dialog: {
            overflowX: "hidden"
        }
    })
});

const UserTokenPickerDialog: FC<PropsWithRef<UserTokenPickerDialogProps>> = forwardRef(({ accessToken }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const onClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={onClose}
            classes={{
                paper: style.dialog
            }}>

            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Access Keys" />
                </DialogTitle>
            </div>
            <DialogContent>
                <FormInputContainer>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="outlined-user-token"><FormattedMessage defaultMessage="User Token" /></InputLabel>
                        <OutlinedInput
                            id="outlined-user-token"
                            type="text"
                            readOnly={true}
                            inputProps={{
                                className: "use-code-font"
                            }}
                            defaultValue={accessToken}
                            label={<FormattedMessage defaultMessage="User Token" />}
                            endAdornment={
                                <InputAdornment position="end">
                                    {accessToken ? <CopyToClipboard edge="end" text={accessToken} /> : null}
                                </InputAdornment>}
                        />
                    </FormControl>
                </FormInputContainer>
            </DialogContent>
            <DialogActions>
                <ActionInliner>
                    <Button onClick={onClose}>
                        <FormattedMessage defaultMessage="Close" />
                    </Button>
                </ActionInliner>
            </DialogActions>
        </Dialog>
    )
});

UserTokenPickerDialog.propTypes = {

};

export default memo(UserTokenPickerDialog);


