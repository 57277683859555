import React from "react";
import { DialogTitle, DialogContent } from "@material-ui/core";
import APIProductHelperImage from "./images/APIProductHelperImage";


const APIProductHelper = (props: any) => {

    return (
        <section>
            <DialogTitle>What is API Product?</DialogTitle>
            <DialogContent>
                <p>As an API provider, you create API products to bundle your APIs and make them available to app developers for consumption. You can think of API products as your product line.</p>
                <p>Specifically, an API product bundles together the following:</p>
                <ul>
                    <li>Collection of API resources (URIs)</li>
                    <li>Service plan</li>
                    <li>Metadata specific to your business for monitoring or analytics (optional)</li>
                </ul>
                <p>The API resources bundled in an API product can come from one or more APIs, so you can mix and match resources to create specialized feature sets, as shown in the following figure.</p>
                <APIProductHelperImage/>
                <p>You can create multiple API products to address use cases that solve specific needs. For example, you can create an API product that bundles a number of mapping resources to enable developers to easily integrate maps into their applications.</p>
            </DialogContent>
        </section>
    );

}
export default APIProductHelper;