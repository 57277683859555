export const TrimbleColors = {
  // Brand Colors
  Brand: {
    BlueDark: "#002D5B",
    Blue: "#00437B",
    BlueMid: "#005F9E",
    BlueLight: "#009AD9",
    Yellow: "#FFBE00",
    Green: "#4D6F33",
    Red: "#A51D25",
    Gray: "#363545",
  },

  // GrayScale
  White: "#FFFFFF",
  GrayHalf: "#f9f9fb",
  GrayLight: "#F3F3F7",
  Gray0: "#EAEAEF",
  Gray1: "#E2E2E7",
  Gray2: "#D0D0D7",
  Gray3: "#BFBFC6",
  Gray4: "#AEAEB6",
  Gray5: "#9D9DA6",
  Gray6: "#8C8B96",
  Gray7: "#7B7A86",
  Gray8: "#6A6976",
  Gray9: "#595868",
  Gray10: "#474655",
  GrayDark1: "#262533",
  GrayDark2: "#1B1A26",
  GrayDark3: "#0B0A12",
  Black: "#000000",

  //Blue Buttons & Notifications
  Blue: "#0D6AA8",
  BlueLight: "#197CB6",
  BlueDark: "#07599B",
  BluePale: "#CFE1EE",

  //Green Buttons & Notifications
  Green: "#5E9331",
  GreenLight: "#72A544",
  GreenDark: "#4A821F",
  GreenPale: "#DFE9D6",

  //Yellow Buttons
  Yellow: "#FFBE00",
  YellowLight: "#FFD200",
  YellowDark: "#FFA500",
  YellowPale: "#FFF2CC",

  //Orange Notifications
  Orange: "#ff8b00",
  OrangeLight: "#ffa500",
  OrangeDark: "#ff6e00",
  OrangePale: "#FFE8CC",

  //Red Buttons & Notifications
  Red: "#C81922",
  RedLight: "#D52A33",
  RedDark: "#BC0911",
  RedPale: "#F4D1D3",

  //Markup
  Markup: "#ff00ff",
};
