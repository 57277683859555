import React, { useState, useEffect, createContext, useReducer, Dispatch, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter, RouteComponentProps } from 'react-router';
import { Container } from '@material-ui/core';
import AccordionForm, { AccordionSummaryTitle } from '../../../legacy-components/form/components/accordion-form/AccordionForm';
import ProductDocumentationView from './product-views/ProductDocumentationView';
import ProductDetailsView from './product-views/ProductDetailsView';
import ProductProxyView from './product-views/ProductProxyView';
import ProductPublishView from './product-views/ProductPublishView';
import { AsyncLoader, useAsyncAction } from '../../../legacy-components/form/action/AsyncAction';
import ProductService, { IAPIProduct, ProductEnvironmentType, IAPIProductPublishedGatway } from '../../../libs/resources/product/ProductService';
import { NEVER } from 'rxjs';
import { FormattedMessage } from 'react-intl';
import GenericPageHeader, { GenericPageHeaderSkeleton } from '../../../legacy-components/page-headers/GenericPageHeader';
import { StatusBadgeInfo } from '../../../legacy-components/badges/StatusBadge';
import { useResourceViewStyles, ResourceViewSkeleton } from '../../../legacy-components/resource-view/ResourceView';
import ProductSubscriptionView from './product-views/ProductSubscriptionView';
import DeleteIcon from '@material-ui/icons/Delete';
import ProductDeleteAction from './product-forms/ProductDeleteAction';
import Alert from '@material-ui/lab/Alert';

type ProductPublishStatus = {
    [key in ProductEnvironmentType]?: IAPIProductPublishedGatway;
}

const createPublishStatus = (publishedGateway: IAPIProductPublishedGatway[] | null) => {
    return publishedGateway?.reduce((agg: ProductPublishStatus, value) => {

        if (value.gatewayName && value.status) {
            agg[value.gatewayName] = value;
        }

        return agg;
    }, {}) ?? null;
};

const createStatusBadge = (publishStatus: ProductPublishStatus | null): StatusBadgeInfo[] => {
    return Object.keys(publishStatus ?? {}).reduce((agg: StatusBadgeInfo[], key) => {

        agg.push({
            kind: "access",
            access: true,
            key
        })

        return agg;
    }, []);
}

interface RouterPathProps {
    productName?: string
}

interface ProductViewProps {

}

interface PublishUpdateAction {
    type: "publishUpdate",
    payload: {
        env: ProductEnvironmentType,
        publishedGateway?: IAPIProductPublishedGatway
    }
}

interface ProductUpdateAction {
    type: "productUpdate",
    payload: {
        product: IAPIProduct
    }
}

interface ProductViewState {
    publishStatus: ProductPublishStatus | null;
    product: IAPIProduct | null;
    statusBadge: StatusBadgeInfo[] | null;
}

type ProductViewAction = PublishUpdateAction | ProductUpdateAction;

export const ProductViewContext = createContext<{
    state: ProductViewState | null,
    dispatch: Dispatch<ProductViewAction>
}>({
    state: null,
    dispatch: () => { }
});

function productViewReducer(state: ProductViewState, action: ProductViewAction) {
    switch (action.type) {
        case "publishUpdate": {
            const publishStatus = {
                ...state.publishStatus,
                [action.payload.env]: action.payload.publishedGateway?.status ? action.payload.publishedGateway : null
            };

            return {
                ...state,
                publishStatus,
                statusBadge: createStatusBadge(publishStatus)
            }
        }
        case "productUpdate": {
            const publishStatus = createPublishStatus(action.payload.product.publishedGateway ?? null);

            return {
                ...state,
                product: action.payload.product,
                publishStatus,
                statusBadge: createStatusBadge(publishStatus)
            }
        }

        default: return state
    }
}

const ProductDetails = (
    <AccordionSummaryTitle>
        <FormattedMessage defaultMessage="Product Details" />
    </AccordionSummaryTitle>
);

const ProductProxy = (
    <AccordionSummaryTitle>
        <FormattedMessage defaultMessage="Proxies" />
    </AccordionSummaryTitle>
)

const ProductSubscriptions = (
    <AccordionSummaryTitle>
        <FormattedMessage defaultMessage="Subscriptions" />
    </AccordionSummaryTitle>
)
const ProductPublish = (
    <AccordionSummaryTitle>
        <FormattedMessage defaultMessage="Publish" />
    </AccordionSummaryTitle>
)
function ProductView({ match }: RouteComponentProps<RouterPathProps> & ProductViewProps) {
    const styles = useResourceViewStyles();
    /*const { response } = useResourceViewElements({
        resolverFn: () => match.params.productName ? ProductService.get(match.params.productName) : NEVER
    })*/

    const [isFormCompleted, setIsFormCompleted] = useState<string>();

    const onSuccessEdit = () => {
        setIsFormCompleted(Math.random().toString());
    }

      const [response, { subscribe }] = useAsyncAction(() => match.params.productName ? ProductService.get(match.params.productName) : NEVER);
      useEffect(() => {
        const unsubscribe = subscribe()
    
        return () => unsubscribe();
      }, [subscribe, isFormCompleted]);

    const [state, dispatch] = useReducer(productViewReducer, {
        publishStatus: null,
        product: null,
        statusBadge: null
    })

    const [viewContext, setViewContext] = useState({
        state,
        dispatch
    });

   

    useEffect(() => {
        setViewContext({
            state,
            dispatch
        })
    }, [state, dispatch]);

   
    useEffect(() => {
        if (response.loaded && response.value) {
            dispatch({
                type: "productUpdate",
                payload: {
                    product: response.value
                }
            })
        }

    }, [response]);

type alertType = "success" | "error" | "warning" | undefined;
const [apiMessage, setApiMessage] = useState<string | undefined>();
    const [apiMessageType, setApiMessageType] = useState<alertType>();
    const hideMessage = useCallback((event) => {
        event?.preventDefault();
        event?.stopPropagation();
        setApiMessage("");
        setApiMessageType(undefined);
    }, [setApiMessageType, setApiMessage]);
const showMessage = useCallback((message: string, type?: string) => {
    if (!type) {
        type = "success";
    }
    if (message) {
        setApiMessage(message);
        setApiMessageType(type as ("success" | "error"));
        setTimeout(() => {
            setIsFormCompleted(Math.random().toString());
            hideMessage(null);
        }, 4000);
    }
}, [setApiMessageType, setApiMessage]);

const showDeleteMessage = (response: any) => {
    showMessage(response.message, response.messageType);
}
    return (
        <>
        {response?.error && (<Alert severity='error'>The following product is restricted to the following user for the selected team </Alert>)
                }
        <ProductViewContext.Provider value={viewContext}>
            <AsyncLoader trigger={!!state.product} state={response} loader={<GenericPageHeaderSkeleton />}>

                {state.product && response?.value?.name && 
                 <div className="proxy-summary-header">
                 <GenericPageHeader
                      header={<FormattedMessage defaultMessage="API Products" />}
                      title={response.value?.name}
                      controls={<ProductDeleteAction productName={response.value.name} 
                                        isProxiesMapped = {response.value.isProxiesMapped} 
                                        hasSubscriptions = {response.value.hasSubscriptions}
                                        onDeleteTriggered={showDeleteMessage}/>}
                 />
                 </div>
                }   
                                {state.product && response?.value?.visibility && 
               (response.value.visibility == "sector" || response.value?.visibility == "trimble") && 
               response?.value?.openApiSpecGateway && 
               response?.value?.openApiSpecGateway[0]?.openApiSpecId == "" &&  
             <div style={{ marginLeft: "20px" }}>
           <Alert severity="info">Product is published as {response.value.visibility} visibility but SPEC is not attached. Product might not be visible in Marketplace.</Alert>
           <br></br>
           </div>
            }        
            </AsyncLoader>
            <Container maxWidth="md">
                <AsyncLoader trigger={!!state.product} state={response} loader={<ResourceViewSkeleton />}>
                    {state.product && <AccordionForm id="product-summary">
                        <AccordionForm.type.Section id="product-detail" summary={ProductDetails}>
                            <div className={styles.section}>
                                <ProductDetailsView product={state.product} onSuccessEdit={onSuccessEdit} />
                            </div>
                        </AccordionForm.type.Section>

                        <AccordionForm.type.Section id="product-proxy" summary={ProductProxy}>
                            <div className={styles.section}>
                                <ProductProxyView product={state.product} />
                            </div>
                        </AccordionForm.type.Section>
                        <AccordionForm.type.Section id="product-subscriptions" summary={ProductSubscriptions}>
                            <div className={styles.section}>
                                <ProductSubscriptionView product={state.product} />
                            </div>
                        </AccordionForm.type.Section>
                        <AccordionForm.type.Section id="product-publish" summary={ProductPublish}>
                            <div className={styles.section}>
                                <ProductDocumentationView product={state.product} />
                                <ProductPublishView product={state.product} />
                            </div>
                        </AccordionForm.type.Section>


                    </AccordionForm>
                    }
                </AsyncLoader>        
                </Container>
        </ProductViewContext.Provider>
    </>)
}

ProductView.propTypes = {
    match: PropTypes.any.isRequired
}

export default withRouter(ProductView);

