export class UrlParamsConfig {
    constructor(
        public options?: {
            noAuthorization?: boolean,
            noTrailingSlash?: boolean,
            absolute?: boolean
        }
    ) { }
}

export class UrlConfigInfo {
    resourceInfo?: {
        name?: string
    };
    methodInfo?: {
        path?: string | string[],
        params?: UrlParamsConfig
    }
}

const urlConfigMap = new Map<string, UrlConfigInfo>();

const globalMetaInfo = {
    configCounter: 0
}

export const _createResourceConfig = (resourceInfo: UrlConfigInfo) => {
    const id = String(++globalMetaInfo.configCounter);

    urlConfigMap.set(id, resourceInfo);
    return id;
}

export const _updateResourceConfig = (id: string, resourceInfo: UrlConfigInfo) => {
    urlConfigMap.set(id, resourceInfo);
}

export const _getResourceConfig = (id: string) => urlConfigMap.get(id);

export const apiMethodContext = (args: IArguments): string => {
    if (args && args.length) {
        const ref = args[args.length - 1];

        if (ref?.requestId ?? false) {
            return ref.requestId;
        }
    }

    throw new Error("No context for APIMethod, use @APIMethod decorator")
}