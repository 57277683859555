import {
  APIResource,
  APIMethod,
} from "../../../../../libs/utils/http/decorator/UrlDecorator";
import { CloudConsoleClient } from "../../../../../libs/utils/http/client/HttpClient";
import { apiMethodContext } from "../../../../../libs/utils/http/builder/UrlConfig";
import { from } from "rxjs";
import {
  ProxyVerificationInfo,
  ProxyVerificationBusinessValueInfo,
  ProxyVerificationSupportInfo,
  ProxyVerificationSecurityInfo,
  ProxyVerificationPerformanceInfo,
  ProxyVerificationApiQualityInfo,
} from "./models/verification.model";

export const learnMore =
  "https://docs.trimblecloud.com/apicloud_v2/home/features/";

const formatError = (error: any, defaultMessage: string) =>
  error?.response?.data?.code !== undefined && error.response.data.message
    ? error.response.data.message
    : defaultMessage;

@APIResource(CloudConsoleClient, "/publisher/api")
class ProxyVerificationServiceFactory {
  @APIMethod("/:proxy/verification/get-started")
  public forGetStarted(proxy: string) {
    const context = apiMethodContext(arguments);

    return from(
      new Promise<ProxyVerificationInfo>((resolve, reject) => {
        CloudConsoleClient.post<ProxyVerificationInfo>(
          context,
          {},
          {
            params: {
              proxy,
            },
            responseType: "json",
          }
        ).then(
          (response) => {
            resolve(response.data);
          },
          (error) =>
            reject(
              formatError(
                error,
                "Unknown error occured while fetching get started"
              )
            )
        );
      })
    );
  }

  @APIMethod("/:proxy/verification/business-value")
  public forBusinessValue(
    proxy: string,
    businessInfo: ProxyVerificationBusinessValueInfo
  ) {
    const context = apiMethodContext(arguments);

    return from(
      new Promise<ProxyVerificationInfo>((resolve, reject) => {
        CloudConsoleClient.post<ProxyVerificationInfo>(
          context,
          {
            value: businessInfo.businessValue,
            additionalInput: businessInfo.businessValueAdditionalInput,
          },
          {
            params: {
              proxy,
            },
          }
        ).then(
          (response) => resolve(response.data),
          (error) =>
            reject(
              formatError(
                error,
                "Unknown error occured while fetching api verification business value"
              )
            )
        );
      })
    );
  }

  @APIMethod("/:proxy/verification/support")
  public forSupport(proxy: string, supportInfo: ProxyVerificationSupportInfo) {
    const context = apiMethodContext(arguments);

    return from(
      new Promise<ProxyVerificationInfo>((resolve, reject) => {
        CloudConsoleClient.post<ProxyVerificationInfo>(
          context,
          {
            supportWebsite: supportInfo.supportWebsite,
            supportEmail: supportInfo.supportEmail,
            additionalInput: supportInfo.supportAdditionalInput,
            supportType: supportInfo.supportType,
            supportRoadMap: supportInfo.supportRoadMap,
            supportProcessDesc: supportInfo.supportProcessDesc,
          },
          {
            params: {
              proxy,
            },
          }
        ).then(
          (response) => resolve(response.data),
          (error) =>
            reject(
              formatError(
                error,
                "Unknown error occured while fetching api verification support info"
              )
            )
        );
      })
    );
  }

  @APIMethod("/:proxy/verification/security-and-compliance")
  public forSecurityCompliance(
    proxy: string,
    form: string,
    securityComplianceInfo: ProxyVerificationSecurityInfo
  ) {
    const context = apiMethodContext(arguments);

    return from(
      new Promise<ProxyVerificationInfo>((resolve, reject) => {
        CloudConsoleClient.post<ProxyVerificationInfo>(
          context,
          {
            tsdlcUrl: securityComplianceInfo.securityTsdlcUrl,
            iso27001Url: securityComplianceInfo.securityIso27001Url,
            gdprUrl: securityComplianceInfo.securityGdprUrl,
            ccpaUrl: securityComplianceInfo.securityCcpaUrl,
            additionalInput: securityComplianceInfo?.securityAdditionalInput,
            tsdlcWhitesource: securityComplianceInfo?.securityTsdlcWhitesource,
            tsdlcVeracode: securityComplianceInfo?.securityTsdlcVeracode,
            tsdlcRapid7: securityComplianceInfo?.securityTsdlcRapid7,
            tsdlcDoneQuaterly:
              securityComplianceInfo?.securityTsdlcDoneQuaterly,
            dataPrivacyPii: securityComplianceInfo?.securityDataPrivacyPii,
            dataPrivacyRopa: securityComplianceInfo?.securityDataPrivacyRopa,
            dataSovereignty: securityComplianceInfo?.securityDataSovereignty,
            dataSovereigntyRegion:
              securityComplianceInfo?.dataSovereigntyRegion,
          },
          {
            params: {
              proxy,
              form,
            },
          }
        ).then(
          (response) => resolve(response.data),
          (error) =>
            reject(
              formatError(
                error,
                "Unknown error occured while fetching api verification security compliance info"
              )
            )
        );
      })
    );
  }

  @APIMethod("/:proxy/verification/performance-metrics")
  public forPerformanceMetrics(
    proxy: string,
    performanceMetricsInfo: ProxyVerificationPerformanceInfo
  ) {
    const context = apiMethodContext(arguments);

    return from(
      new Promise<ProxyVerificationInfo>((resolve, reject) => {
        CloudConsoleClient.post<ProxyVerificationInfo>(
          context,
          {
            performanceUptime: performanceMetricsInfo?.performanceUptime,
            performanceCapacity: performanceMetricsInfo?.performanceCapacity,
            performanceErrorRate: performanceMetricsInfo?.performanceErrorRate,
            performanceAverageLatency:
              performanceMetricsInfo?.performanceAverageLatency,
            performanceStatusPage:
              performanceMetricsInfo?.performanceStatusPage,
            performanceMetricsUrl:
              performanceMetricsInfo?.performanceMetricsUrl,
            performanceMetricsFiles:
              performanceMetricsInfo?.performanceMetricsFiles,
            additionalInput: performanceMetricsInfo?.performanceAdditionalInput,
          },
          {
            params: {
              proxy,
            },
          }
        ).then(
          (response) => resolve(response.data),
          (error) =>
            reject(
              formatError(
                error,
                "Unknown error occured while fetching api verification performance metrics info"
              )
            )
        );
      })
    );
  }

  @APIMethod("/:proxy/verification/performance-metrics/file-download/:fileId")
  public forPerformanceMetricFile(proxy: string, fileId: string) {
    return from(
      CloudConsoleClient.get<Blob>(apiMethodContext(arguments), {
        params: {
          proxy,
          fileId,
        },
        responseType: "blob",
      }).then((value) => value)
    );
  }

  @APIMethod("/:proxy/verification/api-quality")
  public forApiQuality(
    proxy: string,
    apiQualityInfo: ProxyVerificationApiQualityInfo
  ) {
    const context = apiMethodContext(arguments);

    return from(
      new Promise<ProxyVerificationInfo>((resolve, reject) => {
        CloudConsoleClient.post<ProxyVerificationInfo>(
          context,
          {
            apiQuality: apiQualityInfo.apiQuality,
            additionalInput: apiQualityInfo.additionalInput,
          },
          {
            params: {
              proxy,
            },
          }
        ).then(
          (response) => resolve(response.data),
          (error) =>
            reject(
              formatError(
                error,
                "Unknown error occured while fetching api verification api quality"
              )
            )
        );
      })
    );
  }

  @APIMethod("/:proxy/verification/verified")
  public forVerifiedStatus(proxy: string, verified?: boolean) {
    const context = apiMethodContext(arguments);

    return from(
      new Promise<ProxyVerificationInfo>((resolve, reject) => {
        CloudConsoleClient.post<ProxyVerificationInfo>(
          context,
          {
            verified,
          },
          {
            params: {
              proxy,
            },
          }
        ).then(
          (response) => resolve(response.data),
          (error) =>
            reject(
              formatError(
                error,
                "Unknown error occured while fetching api verification verified info"
              )
            )
        );
      })
    );
  }
}
const ProxyVerificationService = new ProxyVerificationServiceFactory();

export default ProxyVerificationService;
