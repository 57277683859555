import React, { useEffect, useMemo } from "react";
import { Box, Grid, Tooltip } from "@material-ui/core";
import { ConsumerSecret } from "./ConsumerSecretViewer";
import ApplicationConfigurationService, {
  IdentityApplicationSecretInfo,
} from "../services/ApplicationConfigurationService";
import { useSelector } from "react-redux";
import Submit from "modus/components/form/submit/Submit";
import AddIcon from "@material-ui/icons/Add";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";

interface ConsumerSecretCreatorProps {
  appName: string;
  onCreate: (value: IdentityApplicationSecretInfo) => void;
  disabled: boolean;
}
export const ConsumerSecretCreator: React.FC<ConsumerSecretCreatorProps> = ({
  appName,
  disabled = false,
  onCreate,
}) => {
  const identityEnvironment = useSelector(
    (state: any) => state?.common?.identityEnvironment
  );

  const { reset, state, subscribe } = useAsyncAction(() =>
    ApplicationConfigurationService.createSecret(identityEnvironment, appName)
  );

  return useMemo(() => (
    <Tooltip title="You can add up to 5 secrets" enterDelay={200}>
      <span>
        <Submit
          size="large"
          variant="outlined"
          color="primary"
          style={{ padding: "24px 21px" }}
          startIcon={<AddIcon />}
          state={state as any}
          onClick={() => subscribe()}
          onSuccess={(value: any)=>{
            onCreate(value);
            setTimeout(() => reset(), 200);
          }}
          disabled={disabled}
        >
          Add Secret
        </Submit>
      </span>
    </Tooltip>
  ),[subscribe,onCreate,disabled, reset, state?.loading]);
};
