import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from "../../../../store/actions/actions.tsx";
import UnifiedAPIService, { UnifiedAPI } from "../../../../services/UnifiedAPIService";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import GenericPageHeader from '../../../../legacy-components/page-headers/GenericPageHeader';
import ProxyShareViewAction from "./ProxyShareViewAction";
import ProxyDeleteAction from "./ProxyDeleteAction";
import ProxyEnvironmentView from "./ProxyEnvironmentView";
import Alert from '@material-ui/lab/Alert';

/**
 * @class ProxySummary
 * @description for showing all api Proxy specific details - Initial component for API Summary Routing
 */
class ProxySummary extends React.PureComponent {

     /**
      * @function constructor
      * @param {*} props - Having properties passed by parent element
      *  * used for state initialization
      */
     constructor(props) {
          super(props);
          this.state = {
               isApiLoaded: false,
               isProxyThrottlingConfigLoaded: false,
               apis: null,
               proxy: this.props.proxy,
               proxyThrottlingConfig: this.props.proxyThrottlingConfig,
               tabIndex: 0,
               error: false
          };
     }
     
     componentDidMount() {
          if (!this.state.proxy) {
               this.loadProxy();
          }
          if (!this.state.proxyThrottlingConfig) {
               this.loadProxyThrottlingConfig();
          }
     }

     componentWillUnmount() {
          this.props.dispatch({ type: actions.storeProxy, payload: null });
     }

     /**
      * @function loadProxy
      * @description this loads Proxy details if page loaded directly
      */
     loadProxy = () => {
          let apiCall = UnifiedAPI.get_team_proxy;

          if (!this.state.isApiLoaded) {
               let request = {
                    apiName: this.props.match.params.apiName,
                    includeEmailAddress: true
               };
               UnifiedAPIService.request(
                    {
                         url: UnifiedAPI.parseRequestURL(apiCall, request),
                         method: 'GET'
                    }
               ).then(
                    (response) => {
                         this.setState({ isApiLoaded: true });
                         this.props.dispatch({ type: actions.storeProxy, payload: response.data });
                         
                    }
               ).catch(
                    () => {
                         this.setState({ isApiLoaded: true });
                         this.setState({ error: true });
                    }
               );
          }
     }

     loadProxyThrottlingConfig = () => {
          let apiCall = UnifiedAPI.get_proxy_throttling_config;

          if (!this.state.isProxyThrottlingConfigLoaded) {
               let request = {
                    apiName: this.props.match.params.apiName
               };
               UnifiedAPIService.request(
                    {
                         url: UnifiedAPI.parseRequestURL(apiCall, request),
                         method: 'GET'
                    }
               ).then(
                    (response) => {
                         this.setState({ isProxyThrottlingConfigLoaded: true });
                         this.props.dispatch({ type: actions.storeProxyThrottlingConfig, payload: response.data });
                    }
               ).catch(
                    () => {
                         this.setState({ isProxyThrottlingConfigLoaded: true });
                    }
               );
          }
     }
   
     onDeleteSuccess = () => {
          setTimeout(() => {
               this.props.history.push('/home/proxy/');
          }, 5000);
     }
     reload = () => {
          // setTimeout(() => {
          //      this.props.history.go(0);
          // }, 2000);
     }
     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          return (
               <>
               
                    { this.state.error && <Alert severity='error'>The following proxy is restricted to the following user for the selected team</Alert>}
                    <div className="proxy-summary-header">
                         {this.props.proxy && <GenericPageHeader
                              header={<FormattedMessage defaultMessage="API Proxy" />}
                              title={this.props.proxy && (this.props.proxy.displayName ?? this.props.proxy.apiName)}
                              controls={
                                   this.props.proxy &&
                                   <section className="flex-row">
                                        <ProxyShareViewAction proxy={this.props.proxy} />
                                        <ProxyDeleteAction proxy={this.props.proxy} onSuccess={this.onDeleteSuccess} />
                                   </section>
                              }
                         />}
                    </div>
                    <div className="description">
                         {this.props.proxy?.description}
                    </div>
                    {(this.props.proxy && <ProxyEnvironmentView proxy={this.props.proxy} onUpdate={this.reload} />)}
               </>
          );
     }
}

/**
 * Properties to be received from Parent component for initializing this component
 */
ProxySummary.propTypes = {
     proxy: PropTypes.any
};

/**
 * Default property value if component nto passign the value
 */
ProxySummary.defaultProps = {
     proxy: null
};

/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state) => {
     return {
          proxy: state.proxyManager.proxy,
          breadcrumbs: state.common.breadcrumbs,
          proxyThrottlingConfig: state.proxyManager.proxyThrottlingConfig
     }
};
export default withRouter(connect(mapStateToProps)(ProxySummary));
