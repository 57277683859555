import React, { FC, useState, useCallback, useRef, Ref, forwardRef, useImperativeHandle, PropsWithRef, useContext } from 'react'
import { AgGridReact } from 'ag-grid-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ColDef } from 'ag-grid-community';
import { useGenericGridViewElements, GridViewAgGridContainer, GridViewFrameworkComponentsType, EnvironmentStatusBadgeCellRenderer } from '../../../legacy-components/grid-view/GridView';
import { ICertificate } from './CertificateService';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import CertificateUploadDialog, { CertificateUploadDialogRef } from './CertificateUploadDialog';
import CertificateDetailDialog, { CertificateDetailDialogRef } from './CertificateDetailDialog';
import CertificateDeleteDialog, { CertificateDeleteDialogRef } from './CertificateDeleteDialog';
import { SnackNotificationContext } from '../../../legacy-components/notifications/GenericSnackNotification';
import CertificateExpiryService from './CertificateExpiryService';

export interface CertificateExpiryGridRef {
    refreshStream: () => void
}

interface CertificateExpiryGridProps {
    ref: Ref<CertificateExpiryGridRef | undefined>
}

const noDataMessage = <FormattedMessage defaultMessage="No certificates available" />;

const NotificationMessage = () => {
    return (
        <FormattedMessage defaultMessage="Certificate Updated Successfully" />
    )
}


const DeleteNotificationMessage = () => {
    return (
        <FormattedMessage defaultMessage="Certificate Deleted Successfully" />
    )
}


const EditCertificateAction: FC<{
    data: ICertificate, refreshStream: () => void
}> = ({ data, refreshStream }) => {
    const certificateDialogRef = useRef<CertificateUploadDialogRef>();

    const editHandler = useCallback(() => certificateDialogRef.current?.open(), [certificateDialogRef]);
    const onSuccessHandler = useCallback(() => {
        refreshStream();
    }, []);
    return (
        <>
            <CertificateUploadDialog ref={certificateDialogRef} certificate={data} onSuccess={onSuccessHandler} />
            <Tooltip enterDelay={1000} title={<FormattedMessage defaultMessage="Update Certificate" />}>
                <span>
                    <IconButton size="small" onClick={editHandler}>
                        <EditIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
}


const DeleteAction: FC<{
    data: ICertificate
    onSubmit: () => void
}> = ({ data, onSubmit }) => {
    const confirmDialogRef = useRef<CertificateDeleteDialogRef>();

    const deleteHandler = useCallback(() => confirmDialogRef.current?.open(), [confirmDialogRef]);

    const submitHandler = useCallback(() => {
        onSubmit();
    }, [onSubmit]);

    return (
        <>
            <CertificateDeleteDialog onSubmit={submitHandler} certificate={data} ref={confirmDialogRef} />

            <Tooltip enterDelay={1000} title={<FormattedMessage defaultMessage="Delete Certificate" />}>
                <span>
                    <IconButton onClick={deleteHandler} size="small">
                        <DeleteIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>

    );
}

const CertificateExpiryGrid: FC<PropsWithRef<CertificateExpiryGridProps>> = forwardRef(({ }, ref) => {
    const intl = useIntl();

    const { onGridReady, frameworkComponents, gridViewOverlayPropRef, getRowNodeId, createRowActionColumn, refreshStream } = useGenericGridViewElements({
        resolverFn: () => CertificateExpiryService.forCertificateExpiry(),
        noDataMessage
    });
    const notificationContext = useContext(SnackNotificationContext);
    const refreshHandler = useCallback(() => {
        notificationContext.show({
            id: "certificate-update",
            message: <NotificationMessage />
        });

        refreshStream();
    }, [notificationContext, refreshStream]);

    const deleteHandler = useCallback(() => {
        notificationContext.show({
            id: "certificate-grid-delete",
            message: <DeleteNotificationMessage />
        });

        refreshStream();
    }, [notificationContext, refreshStream]);

    const [rowActionField] = useState(createRowActionColumn<ICertificate>({
        actions: data => [<EditCertificateAction data={data} refreshStream={refreshHandler} />, 
        <DeleteAction data={data} onSubmit={deleteHandler} />],
        width: 160
    }));

    useImperativeHandle(ref, () => ({
        refreshStream: () => {
            refreshStream()
        }
    }));
    const formatDate = (date: any) => {
        const d = new Date(date);
        let formatted = d.getDate().toString().concat("/").concat((d.getMonth() + 1).toString().concat("/").concat(d.getFullYear().toString()));
        return formatted;
    }
    const [columnDefs] = useState<ColDef[]>(() => {
        const columns: ColDef[] = [{
            field: "description",
            sortable: true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Name"
            }),
            flex: 1.5,
            resizable: true
        }, {
            field: "expiryDate",
            sortable: true,
            headerName: intl.formatMessage({
                defaultMessage: "Expiration Date"
            }),
            flex: 1,
            valueGetter: (params) => {
                let date = params.data.expiryDate;
                return formatDate(date)
            }   
        }, 
        {
            field: "expiryDuration",
            sortable: true,
            headerName: intl.formatMessage({
                defaultMessage: "Expiring/Expired"
            }),
            flex: 1.5,
            valueGetter: (params) => {
                let duration = params.data.expiryDuration;
                let status = params.data.status;
                if (duration === 0) {
                    if(status === "No")
                        return "EXPIRED_TODAY";
                    else 
                        return "EXPIRING_TODAY"
                } else if(duration < 0){
                    return "EXPIRED";
                } else if(duration === 1) {
                    return "EXPIRING_TOMO"
                } else {
                    return "EXPIRING"
                }
            },
            type: 'rightAligned',
            cellRendererFramework: EnvironmentStatusBadgeCellRenderer
        },
        {
            field: "expiryDuration",
            sortable: true,
            headerName: intl.formatMessage({
                defaultMessage: "Expiry Duration"
            }),
            flex: 1,
            valueGetter: (params) => {
                let duration = params.data.expiryDuration;
                let hours = params.data.expiryDurationHrs;
                if (duration === 0) {
                    return ""
                } else if(duration < 0) {
                    return Math.abs(duration) + " day(s) ago"
                } else {
                    return duration + " day(s)"
                }
            }        
        }, {
            field: "teamName",
            sortable: true,
            headerName: intl.formatMessage({
                defaultMessage: "Team"
            }),
            flex: 1,     
        },
        {
            field: "environment",
            sortable: true,
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Environment"
            }),
            flex: 1,
            type: 'rightAligned',
            cellRendererFramework: EnvironmentStatusBadgeCellRenderer
        },
            rowActionField
        ];

        return columns;
    });

    return (
        <GridViewAgGridContainer>
            <AgGridReact
                columnDefs={columnDefs}
                frameworkComponents={frameworkComponents}
                loadingOverlayComponent={GridViewFrameworkComponentsType.LoadingOverlay}
                noRowsOverlayComponent={GridViewFrameworkComponentsType.NoRowsOverlay}
                noRowsOverlayComponentParams={{ gridViewOverlayPropRef }}
                suppressRowClickSelection={true}
                suppressCellSelection={true}
                suppressRowHoverHighlight={true}
                getRowNodeId={getRowNodeId}
                onGridReady={onGridReady}
            />
        </GridViewAgGridContainer>
    )
})

export default (CertificateExpiryGrid);

