import React from "react";
import { DialogTitle, DialogContent } from "@material-ui/core";

const TimeoutConfigtHelper = (props: any) => {

    return (
        <section>
            <DialogTitle>What is Connection Timeout?</DialogTitle>
            <DialogContent>
                <p>This configuration allows you to set the connection timeout for your API Proxy between 0 s up to 55s, with a default of 30s.</p>
            </DialogContent>
        </section>
    );

}
export default TimeoutConfigtHelper;