import React from "react";
import { DialogTitle, DialogContent } from "@material-ui/core";

const SpikeArrestHelper = (props: any) => {

    return (
        <section>
            <DialogTitle>What is Spike Arrest?</DialogTitle>
            <DialogContent>
                <p>This policy smooths traffic spikes by dividing a limit that you define into smaller intervals. For example, if you define a limit of 100 messages per second, the Spike Arrest policy enforces a limit of about 1 request every 10 milliseconds (1000 / 100); and 30 messages per minute is smoothed into about 1 request every 2 seconds (60 / 30). The Spike Arrest limit should be close to capacity calculated for either your backend service or the API proxy itself. The limit should also be configured for shorter time intervals, such as seconds or minutes. This policy should be used to prevent sudden traffic bursts caused by malicious attackers attempting to disrupt a service using a denial-of-service (DOS) attack or by buggy client applications.</p>
                
            </DialogContent>
        </section>
    );

}
export default SpikeArrestHelper;


/*
<h3>How Spike Arrest Works?</h3>

                <p>Think of Spike Arrest as a way to generally protect against traffic spikes rather than as a way to limit traffic to a specific number of requests. Your APIs and backend can handle a certain amount of traffic, and the Spike Arrest policy helps you smooth traffic to the general amounts you want.</p>
                <p>The runtime Spike Arrest behavior differs from what you might expect to see from the literal per-minute or per-second values you enter.</p>
                <p>For example, say you enter a rate of 30pm (30 requests per minute). In testing, you might think you could send 30 requests in 1 second, as long as they came within a minute. But that's not how the policy enforces the setting. If you think about it, 30 requests inside a 1-second period could be considered a mini spike in some environments.</p>
                <p>What actually happens, then? To prevent spike-like behavior, Spike Arrest smooths the number of full requests allowed by dividing your settings into smaller intervals:</p>
                <ul>
                    <li>Per-minute rates get smoothed into full requests allowed in intervals of seconds.
                        <p>For example, 30pm gets smoothed like this:
                        60 seconds (1 minute) / 30pm = 2-second intervals, or 1 request allowed every 2 seconds. A second request inside of 2 seconds will fail. Also, a 31st request within a minute will fail.
                        </p>
                    </li>
                    <li>
                        Per-second rates get smoothed into full requests allowed in intervals of milliseconds.
                        <p>For example, 10ps gets smoothed like this:
                        1000 milliseconds (1 second) / 10ps = 100-millisecond intervals, or 1 request allowed every 100 milliseconds. A second request inside of 100ms will fail. Also, an 11th request within a second will fail.
                        </p>
                    </li>
                </ul>
                */