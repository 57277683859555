import React, { memo, useCallback } from "react";
import type { PropsWithChildren } from "react";
import { Select as MuiSelect } from "@material-ui/core";
// import "./Select.module.scss";
import clsx from "clsx";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export interface SelectProps {
  label?: string;
  id: string;
  initialValue?: string;
  disabled?: boolean;
  helperMessage?: string;
  placeHolder?: string;
  onChange?: (value: any) => void;
  value?: string;
}

/**
 * @component Select
 * @param {*} props
 */
export const Select: React.FC<PropsWithChildren<SelectProps>> = ({
  disabled = false,
  children,
  ...props
}) => {
 
  return (
   
              <MuiSelect
                defaultValue={props.initialValue}
                disabled={disabled}
                variant="outlined"
                displayEmpty
                IconComponent={ArrowDropDownIcon}
                {...props}
              >
                {children}
              </MuiSelect>
              
          
  );
};

export default memo(Select);
