export const isNullOrUndefined: <T>(value: T) => boolean = value =>
    typeof value === "undefined" || value === null;

export const notBlank: <T>(value: T) => boolean = <T>(value: T): value is NonNullable<T> =>
    !(typeof value === "undefined" || value === null || (typeof value === "string" ? value.length < 1 : false) || (Array.isArray(value) ? value.length < 1 : false)
    || (typeof value === "boolean" && value ===false ));

export const mapToArray: <W>(values: W | W[]) => W[] = values => {
    return Array.isArray(values) ? values : values ? [values] : [];
};

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

