import React, { FC } from "react";
import { DialogTitle, IconButton, Divider } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from "react-intl";
import { useStyles } from "./ProductProxyDialog";
import ProductUpdateForm from "./ProductUpdateApprovalTypeForm";
import { IAPIProduct, IAPIProductPublishedGatway } from "libs/resources/product/ProductService";

interface ProductUpdateProps {

    published?: IAPIProductPublishedGatway;
    product: IAPIProduct
    onClose: () => void
    onSuccess: (data?: any) => void
    onError?: (error?: any) => void
}

export const ProductUpdate: FC<ProductUpdateProps> = ({ product, published, onClose, onSuccess }) => {
    const style = useStyles();

    return (
        <div>

            <div className={style.header}>

                <DialogTitle>
                    <FormattedMessage defaultMessage="Edit Product Approval Option" />


                </DialogTitle>

                <IconButton className={style.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>

                <Divider />
            </div>
            <div className={style.createForm}>
                <ProductUpdateForm productName={product?.name} published={published} initialValues={product} stepperId="product-updation" onSuccess={onSuccess} />
            </div>
        </div>
    )
}

