
import { Box, createStyles, Grid, IconButton, makeStyles, Menu, Typography } from '@material-ui/core';
import { useDialogProps } from 'legacy-components/dialog/Dialog';
import EmailIcon from 'legacy-components/icons/EmailIcon';
import IOTIcon from 'legacy-components/icons/IOTIcon';
import ModusIcon from 'legacy-components/icons/ModusIcon';
import React from 'react';


const useRelativeAppStyles = makeStyles((theme) =>
    createStyles({
  menuContent: {
    width: '230px'
  },
  app: {
    display: 'flex',  
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100px',
    paddingBottom: '15px',
    cursor: 'pointer',
  },
  appList: {
    justifyContent: 'flex-start', 
    padding: '0 15px' 
  },
  appName: {
    textAlign: 'center',
  },
  wrapper: {
    alignItems: 'center',
  }
}));

type AppType = 'IOT'|'EMAIL';

export const RelatedWebApps: React.FC = () => {
  const { open, toggle } = useDialogProps();
  const classes = useRelativeAppStyles();
  const navigate = (appName: AppType) => {
    if(process?.env ) {
        window.open(process.env[`REACT_APP_${appName}_URL`] , '_blank');
    }
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    toggle();
  };
  const handleClose = () => {
    setAnchorEl(null);
    toggle();
  };
  return (
    <>
      <IconButton onClick={handleClick}>
        <ModusIcon name="apps" color={"secondary"}></ModusIcon>
      </IconButton>
      {open && (
        <Menu
            anchorEl={anchorEl}
          id="related-applications"
          aria-labelledby="related-applications"
          open={open}
          onClose={handleClose}
          style={{ marginTop: '50px', marginRight:"10px"}}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            
            style:{top: `0 !important`}}}
        >
          <Box mt={'16px'} minWidth={"250px"}>
            <Grid container className={classes.appList}>
              <Grid item xs={12} md={6} className={classes.wrapper}>
                <Box className={classes.app} onClick={() => navigate('IOT')}>
                  <IOTIcon />
                  <Typography variant="body1" className={classes.appName}>
                    IOT Support Portal
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} className={classes.wrapper}>
                <Box className={classes.app} onClick={() => navigate('EMAIL')}>
                  <EmailIcon />
                  <Typography variant="body1" className={classes.appName}>
                    Transactional Email
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Menu>
      )}
    </>
  );
};
