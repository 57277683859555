import { createStyles, makeStyles } from "@material-ui/core/styles";
import type { Theme } from "@material-ui/core/styles";

const useProductListStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      minHeight: "10px",
      maxHeight: "65px",
      overflow: "hidden"
    },
    collapseIcon: {
      marginBottom: "-7px",
    },
    searchterm: {
      backgroundColor: "white"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "150px",
      maxWidth: "150px",
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    toggleFilter: {
      alignItems: "flex-start", 
      alignContent: "flex-start", 
      alignSelf: "flex-start", 
      marginLeft: "auto"
    },
    noProductMsg : {
      marginTop: "50px"
    }, 
    applyFilterButton: {
      alignItems: "flex-start", 
      alignContent: "flex-start",
      alignSelf: "flex-start",
      marginBottom: "10px", 
      marginTop: "30px"}
  })
);

export default useProductListStyles;
