import React, { memo, FC, useCallback, useEffect, useState, useRef, MutableRefObject, Ref, forwardRef, useImperativeHandle, useContext } from 'react'
import PropTypes from 'prop-types';
import FormContainer, { FormContainerSubmitHandler, FormContainerChild } from '../../../../legacy-components/form/container/FormContainer';
import ProductProxyDialog from '../product-forms/ProductProxyDialog';
import { useProductProxyGrid, ProductProxyTitle, ProductProxyControls } from '../product-forms/ProductProxyInfo';
import { Field, useFormState } from 'react-final-form';
import FormSelectionGrid, { FormSelectionGridRef } from '../../../../legacy-components/form/input/FormSelectionGrid';
import { AccordionContent } from '../../../../legacy-components/form/components/accordion-form/AccordionForm';
import { IAPIProduct } from '../../../../libs/resources/product/ProductService';
import ProxyService, { IAPIProxy } from '../../../../libs/resources/proxy/ProxyService';
import { useAsyncAction, AsyncLoader } from '../../../../legacy-components/form/action/AsyncAction';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box, Divider } from '@material-ui/core';
import { AssignableCollectionItem } from '../../../../legacy-components/form/collection/AssignableCollection';
import { ProductProxyFormData } from '../models/products.model';
import { OnChange } from 'react-final-form-listeners';
import UnifiedAPIService, { UnifiedAPI } from '../../../../services/UnifiedAPIService';
import { FORM_ERROR } from 'final-form';
import { useResourceAbility } from 'libs/security/authorization/Permission';
import { useConfirm } from 'material-ui-confirm';
import { SnackNotificationContext } from '../../../../legacy-components/notifications/GenericSnackNotification';
import { CellClickedEvent } from 'ag-grid-community';
import { useHistory } from 'react-router';

interface ProductProxyViewProps {
    product: IAPIProduct
}

interface ProxyPickerFormProps {
    product: IAPIProduct;
}

interface ProxyPickerFormRef {
    formProxyFieldMount: boolean
}

interface ProxyPickerGridRef {
    revertChanges(): void;
    commitChanges(): void;
}

interface ProxyPickerGridProps {
    productName: string,
    proxyFormRef: MutableRefObject<ProxyPickerFormRef>,
    onSubmit: () => void,
    ref: Ref<ProxyPickerGridRef | undefined>,
    readOnly?: boolean
}

const ProxyGridSkeleton = () => {
    const [values] = useState(Array.from(Array(10).keys()));

    return (
        <>
            <Divider />
            <Box padding="24px" width="100%">
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Skeleton width={160} />
                    <Skeleton variant="circle" width={32} height={32} />
                </Box>
                <Box marginTop="16px" width="100%" height="100%" padding="24px">
                    <Box display="flex" justifyContent="space-between" width="100%" height="40px">
                        <Skeleton height={20} width={80} />
                        <Box display="flex" width={140} justifyContent="space-between">
                            <Skeleton height={20} width={100} />
                            <Box height={20} width={80} />
                        </Box>
                    </Box>
                    <Divider />
                    <Box width="100%" height="180px">
                        {
                            values.map(value => (
                                <Box key={value} display="flex" marginTop="16px" justifyContent="space-between" width="100%" height="24px">
                                    <Skeleton height={20} width={160} />
                                    <Box display="flex" width={140} justifyContent="space-between">
                                        <Skeleton height={20} width={80} />
                                        <Skeleton variant="circle" height={20} width={20} />
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const ProxyPickerGrid: FC<ProxyPickerGridProps> = forwardRef(({ productName, proxyFormRef, onSubmit, readOnly }, ref) => {
    const formState = useFormState<ProductProxyFormData>();
    const fieldRef = useRef<FormSelectionGridRef>();

    const { gridData, gridOptions, onChange, proxyDialogRef, disabled, openDialog } = useProductProxyGrid(productName, {
        headerStyle: "none"
    });

    const handleOpenDialog = useCallback(() => openDialog(() => formState.values?.proxies?.list), [formState, openDialog]);

    const ContentHeader = (<ProductProxyTitle count={formState.values["proxies"]?.count ?? formState.values?.proxies?.list?.length} />);
    const ContentControls = (<ProductProxyControls disabled={disabled} onClick={handleOpenDialog} />
    );

    useImperativeHandle(ref, () => ({
        revertChanges: () => fieldRef.current?.revertChanges(),
        commitChanges: () => fieldRef.current?.commitChanges()
    }), [fieldRef]);

    useEffect(() => {
        if (proxyFormRef.current.formProxyFieldMount) {
            if (formState.values?.proxies && Array.isArray(formState.values?.proxies?.list)) {
                onChange(formState.values.proxies.list
                    .filter(item => item.assign)
                    .map((item: AssignableCollectionItem<IAPIProxy>) => ({
                        key: item.key,
                        value: item.value,
                        assign: true,
                        assigned: true
                    })));
            }

            proxyFormRef.current.formProxyFieldMount = false;
        }
    }, [proxyFormRef, formState, onChange]);

    let history = useHistory();

    const onCellClicked = (event : CellClickedEvent) => {
        if (event.colDef.field === "source") return;
        history.push(`/home/proxy/${event.data.name}`)
    }
    return (
        <AccordionContent header={ContentHeader} controls={ContentControls}>
            <ProductProxyDialog onChange={onChange} ref={proxyDialogRef} />
            <Field
                name="proxies"
                component={FormSelectionGrid}
                gridOptions={gridOptions}
                gridData={gridData}
                ref={fieldRef}
                readOnly={readOnly}
                onCellClicked = {(event: CellClickedEvent) => onCellClicked(event)}
            
            />
            <OnChange name="proxies">
                {() => onSubmit()}
            </OnChange>
        </AccordionContent>
    )
});

const createProxyCollection = (proxies: IAPIProxy[]) => {
    return proxies?.map(proxy => {
        return ({
            key: proxy.id,
            value: proxy,
            assign: true,
            assigned: true
        });
    }) ?? [];
}

const ProxyPickerForm = ({ product }: ProxyPickerFormProps) => {
    const proxyFormRef = useRef({
        formProxyFieldMount: true
    });
    const confirm = useConfirm();
    const proxyGridRef = useRef<ProxyPickerGridRef>();

    const [response, { subscribe }] = useAsyncAction(() => ProxyService.forProduct(product.name),
        {
            mapFn: createProxyCollection
        });

    useEffect(() => {
        const unsubscribe = subscribe()

        return () => unsubscribe();
    }, [subscribe]);


    const NotificationMessage = (props: any) => {
        return (
            <span>{props.error}</span>
        )
    }
    const notificationContext = useContext(SnackNotificationContext);
    const onSubmit: FormContainerSubmitHandler<ProductProxyFormData> = () => (data, form) => {
        return new Promise((resolve) => {
            if (data.proxies?.hasChanges) {
                if (data.proxies?.toRemove.length > 0) {
                    confirm({ description: 'Do you want to unmap this proxy permanently?', dialogProps: { open: true, disableBackdropClick: true } })
                        .then(() => {
                            UnifiedAPIService.post(
                                UnifiedAPI.parseRequestURL(UnifiedAPI.map_proxy_product, {
                                    productName: product.name
                                }), {
                                addApis: data.proxies?.toAdd.map(proxy => proxy.value.name).filter(value => !!value),
                                removeApis: data.proxies?.toRemove.map(proxy => proxy.value.name).filter(value => !!value)
                            }).then(() => {
                                proxyGridRef.current?.commitChanges();
                                resolve()
                            }, error => {
                                proxyGridRef.current?.revertChanges();
                                resolve({
                                    [FORM_ERROR]: error.response?.data?.message ?? "Unknown error occured while selecting the proxy"
                                });
                            });
                        }).catch(() => {
                            proxyGridRef.current?.revertChanges();
                            resolve();
                        });
                } else {
                    UnifiedAPIService.post(
                        UnifiedAPI.parseRequestURL(UnifiedAPI.map_proxy_product, {
                            productName: product.name
                        }), {
                        addApis: data.proxies?.toAdd.map(proxy => proxy.value.name).filter(value => !!value),
                        removeApis: data.proxies?.toRemove.map(proxy => proxy.value.name).filter(value => !!value)
                    }).then(() => {
                        proxyGridRef.current?.commitChanges();
                        resolve()
                    }, error => {
                        //proxyGridRef.current?.revertChanges();
                        if (error.response?.data?.message) {
                            notificationContext.show({
                                timeout: 6000,
                                id: "not-verified",
                                message: <NotificationMessage error={error.response?.data?.message} />
                            });
                        }
                        resolve({
                            [FORM_ERROR]: error.response?.data?.message ?? "Unknown error occured while selecting the proxy"
                        });
                    });
                }
            }
            resolve();
        })
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [can, state, ability] = useResourceAbility({
        resource: ({ Product }) => Product.mapping
    });

    const FormComponents: FormContainerChild<ProductProxyFormData> = (formProps, _options, triggerSubmit) => {
        //const onSubmit = useRef(() => triggerSubmit());
        return (<ProxyPickerGrid ref={proxyGridRef} productName={product.name} proxyFormRef={proxyFormRef} onSubmit={triggerSubmit} readOnly={!can.create()} />);
    }

    return (
        <AsyncLoader state={response} loader={<ProxyGridSkeleton />}>
            {response.value && ability.valid && <FormContainer previewErrors={true} FormProps={{
                onSubmit, initialValues: {
                    proxies: {
                        list: response.value
                    }
                }
            }} children={FormComponents} />}
        </AsyncLoader>
    );
}

function ProductProxyView({ product }: ProductProxyViewProps) {
    return (
        <ProxyPickerForm product={product} />
    )
}

ProductProxyView.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }).isRequired
}

export default memo(ProductProxyView);
