import { Observable, of, throwError, Subject, noop } from "rxjs";
import { useReducer, Reducer, useState, useEffect, useRef, PropsWithChildren, memo, ReactElement, useCallback, useMemo } from "react";
import { take, tap, catchError, map, shareReplay, finalize, takeUntil } from "rxjs/operators";
import { SubSink } from "subsink";
import React from "react";

interface InitAction {
    type: "init",
    payload: {
        status: "init"
    }
}

interface IntermediateAction {
    type: "intermediate";
    payload: {
        value: unknown,
        status: "intermediate"
    }
}

interface ErrorAction {
    type: "error",
    payload: {
        error: unknown,
        status: "error"
    }
}

interface LoadedAction {
    type: "loaded",
    payload: {
        status: "loaded"
    }
}

interface LoadingAction {
    type: "loading",
    payload: {
        status: "loading"
    }
}

type AsyncActionReducerAction = InitAction | IntermediateAction | ErrorAction | LoadedAction | LoadingAction;

export interface AsyncActionState<U = unknown> {
    status: "init" | "intermediate" | "error" | "loaded" | "loading" | null
    value: U | null;
    errorValue?: unknown;
    init: boolean;
    loading: boolean;
    loaded: boolean;
    error: boolean;
    valid: boolean;
    intermediate: boolean;
}

const defaultState: AsyncActionState = {
    status: null,
    value: null,
    init: false,
    loading: false,
    loaded: false,
    error: false,
    valid: false,
    intermediate: false
};

function asyncActionReducer<T>(state: AsyncActionState, action: AsyncActionReducerAction) {
    switch (action.type) {
        case "loading":
            {
                const { value, errorValue } = state;
                return {
                    ...defaultState,
                    value,
                    errorValue,
                    init: false,
                    loaded: false,
                    loading: action.type === "loading",
                    status: action.payload.status,
                    error: false
                }
            }
        case "loaded":
        case "init": {
            const { value, errorValue, error } = state;

            if (!error) {
                return {
                    ...defaultState,
                    value,
                    errorValue,
                    init: action.type === "init",
                    loaded: action.type === "loaded",
                    loading: false,
                    status: action.payload.status,
                    error
                }
            }

            return state;
        }

        case "intermediate": {
            const { errorValue, error } = state;
            const value = action.payload.value as T;

            if (!error) {
                return {
                    ...defaultState,
                    errorValue,
                    intermediate: true,
                    value,
                    status: action.payload.status,
                    error
                }
            }

            return state;
        }

        case "error": {
            const { value } = state;

            return {
                ...defaultState,
                error: true,
                value,
                errorValue: action.payload.error,
                status: action.payload.status
            }
        }

        default: return state;
    }
}

export type AsyncActionInstance<U> = [AsyncActionState<U | null>, {
    subscribe: (stream?: boolean) => () => void
}];

type AsyncOptions<T, U> = {
    mapFn?: (value: T) => U,
    throwError?: boolean
}

export function useAsyncAction<T, U = T>(observerFn$: () => Observable<T>, options?: AsyncOptions<T, U>): AsyncActionInstance<U> {
    const subs = new SubSink();

    const [state, dispatch] = useReducer<Reducer<AsyncActionState, AsyncActionReducerAction>>(asyncActionReducer, {
        ...defaultState
    });

    const [unsubscriber$] = useState(new Subject());   

    const handleNext = useCallback((value: T | U | null) => {
        dispatch({
            type: "intermediate",
            payload: {
                status: "intermediate",
                value
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFinalize = useCallback(() => {
        dispatch({
            type: "loaded",
            payload: {
                status: "loaded",
            }
        })
    }, []);

    const observerFnRef = useRef(observerFn$);

    useEffect(() => {
        observerFnRef.current = observerFn$;
    }, [observerFn$]);

    useEffect(() => {
        return () => {
            unsubscriber$.next();
            unsubscriber$.complete();
            unsubscriber$.unsubscribe();
        }
    }, [unsubscriber$]);

    const createObserver = useCallback(() => observerFnRef?.current()?.pipe(catchError(error => {
        dispatch({
            type: "error",
            payload: {
                status: "error",
                error
            }
        })
        return options?.throwError ? throwError(error) : of(null);
    }), map<unknown, T | U | null>((value: unknown) => {
        if (value !== null && options?.mapFn) {           
            return options?.mapFn(value as T);
        }

        return value as U;
    }), tap({
        next: handleNext
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), finalize(handleFinalize)), [handleNext, options]);

    const subscribe = useCallback((stream?: boolean) => {
        subs.unsubscribe();

        dispatch({
            type: "loading",
            payload: {
                status: "loading"
            }
        });

        const observer = createObserver();

        subs.sink = (stream ? observer : observer.pipe(take(1), shareReplay({
            bufferSize: 1,
            refCount: true
        }))).pipe(takeUntil(unsubscriber$)).subscribe(noop);

        return () => subs.unsubscribe();
    }, [createObserver, subs, unsubscriber$]);

    const [collection] = useState({
        subscribe
    })

    const memoized = useMemo(() => [state, collection], [state, collection])

    return memoized as [AsyncActionState<U | null>, typeof collection];
}

export const AsyncLoader = memo(({ state, children, loader, trigger }: PropsWithChildren<{
    state: AsyncActionState<unknown>,
    loader: ReactElement,
    trigger?: boolean;
}>) => {
    switch (state.status) {
        case "intermediate":
        case "loaded": return (trigger ?? true) ? (<>{children}</>) : loader;
        case "loading": return loader;
        default: return <></>
    }
});

export const AsyncAutoLoader = memo(({ instance, children, loader, trigger }: PropsWithChildren<{
    instance: AsyncActionInstance<unknown>,
    loader: ReactElement,
    trigger?: boolean;
}>) => {
    const [state, { subscribe }] = useMemo(() => instance, [instance]);

    useEffect(() => {
        const unsubscribe = subscribe()

        return () => unsubscribe();
    }, [subscribe]);

    return <AsyncLoader state={state} children={children} loader={loader} trigger={trigger} />;
});


