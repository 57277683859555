import React, { FC, useContext, useEffect, useState } from "react";
import FormContainer from "../../../../legacy-components/form/container/FormContainer";
import FormInputContainer from "../../../../legacy-components/form/container/FormInputContainer";
import { FormRenderProps, Field } from "react-final-form";
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import ActionInliner from "../../../../legacy-components/form/action/ActionInliner";
import { FormApi, FORM_ERROR } from "final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { ProxyDetailsFormData } from "../models/proxy.model";
import FormTextInput from "../../../../legacy-components/form/input/FormTextInput";
import { ValidatorBuilderContext } from "../../../../legacy-components/form/validator/Validator";
import UnifiedAPIService, {
  UnifiedAPI,
} from "../../../../services/UnifiedAPIService";
import PropTypes from "prop-types";
import LinkIcon from "@material-ui/icons/Link";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { useRole } from "libs/security/authorization/Permission";
import ProxyService, {
  APICreateRequest,
} from "libs/resources/proxy/ProxyService";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import TeamServices, { Context } from "pages/home/team/TeamService";
import { sanitize } from "dompurify";

const useStyles = makeStyles(({ palette: { success } }) =>
  createStyles({
    linkIcon: {
      color: success.main,
    },
  })
);

interface ProxyDetailsFormProps {
  onCancel: () => void;
  onSuccess: (data?: any) => void;
  onError?: (error?: any) => void;
}

interface Namespace {
  name: string;
  context: string;
}

const ProxyDetailsForm: FC<ProxyDetailsFormProps> = ({
  onCancel,
  onSuccess,
}) => {
  const intl = useIntl();
  const [nameSpaces, setNameSpaces] = useState<
    Array<{
      key: string;
      value: string;
    }>
  >();

  const [contextMap, setContextMap] = useState<Map<string, string> | null>(
    new Map()
  );

  const [rolepermission] = useRole();
  useEffect(() => {
    if (rolepermission?.team) {
      TeamServices.forTeamNamespace(rolepermission?.team).subscribe(
        (response) => {
          setNameSpaces(
            response.contexts.map((data: Context) => {
              const context = data.context.substr(1, data.context.length - 1);
              contextMap?.set(data.name, context);
              return { key: data.name, value: data.name };
            })
          );
          setContextMap(contextMap);
        },
        (error) => {}
      );
    }
  }, [rolepermission?.team]);
  const NotificationMessage = () => {
    return <FormattedMessage defaultMessage="API Proxy Created Successfully" />;
  };
  const notificationContext = useContext(SnackNotificationContext);
  const onSubmit = () => (data: ProxyDetailsFormData, form: FormApi) => {
    return new Promise((resolve) => {
      const formState = form.getState();

      if (formState.pristine) {
        resolve(null);
      } else {
        const apiName = data.proxyName?.replaceAll(" ", "_");
        let request: APICreateRequest = {
          displayName: sanitize(data.proxyName??""),
          version: sanitize(data.version??""),
          context: sanitize(data.basePath??""),
          namespace: sanitize(data.swproduct??""),
          resourceServerName: sanitize(data.resourceServerName??""),
        };
        ProxyService.createProxy(apiName, request).then(
          (response: any) => {
            notificationContext.show({
              id: "api-quality",
              message: <NotificationMessage />,
            });
            resolve(null);
            onSuccess && onSuccess(response);
          },
          (error: any) =>
            resolve({
              [FORM_ERROR]: error,
            })
        );
      }
    });
  };

  const FormComponents = (
    formRenderProps: FormRenderProps<ProxyDetailsFormData, {}>
  ) => {
    const validator = useContext(ValidatorBuilderContext);
    const style = useStyles();

    const formValues = formRenderProps.form.getState().values;
    let basepath = formValues.basePath;
    switch (basepath) {
      case "/":
        basepath = "";
        break;
      default:
        if (basepath?.startsWith("/") && basepath.endsWith("/")) {
          basepath = basepath.substr(1, basepath.length - 2);
        } else if (basepath?.startsWith("/")) {
          basepath = basepath.substr(1, basepath.length - 1);
        } else if (basepath?.endsWith("/")) {
          basepath = basepath?.substr(0, basepath.length - 2);
        }
        break;
    }
    basepath = basepath ? basepath.concat("/") : "";
    let version = formValues.version;
    version = version ? version : "";
    let swproduct = formValues.swproduct;
    let proxyName = formValues.proxyName;

    swproduct = swproduct
      ? contextMap
        ? contextMap.get(swproduct)?.concat("/")
        : ""
      : "";
    proxyName = proxyName ? proxyName : "";

    const [url, setUrl] = React.useState(`${swproduct}${basepath}${version}`);

    const [scopePrefix, setScopePrefix] = React.useState(
      `${proxyName}`.toLocaleLowerCase()
    );

    const noSpaces = (value: string) => {
      if (!value) {
        return intl.formatMessage({
          defaultMessage: "Version is required",
        });
      }
      return /^\S*$/.test(value) ? undefined : intl.formatMessage({
        defaultMessage: "Version should not contain spaces",
      });
    };

    useEffect(() => {
      setUrl(`https://<domain>/${swproduct}${basepath}${version}`);
      setScopePrefix(`${proxyName}`.toLocaleLowerCase());
    }, [proxyName, swproduct, basepath, version]);

    return (
      <>
        <FormInputContainer>
          <Field
            name="swproduct"
            type="text"
            component={FormTextInput}
            label={intl.formatMessage({
              defaultMessage: "Namespace",
            })}
            placeholder={intl.formatMessage({
              defaultMessage: "Select the namespace for your API",
            })}
            options={nameSpaces}
            validate={validator.from({
              required: true,
            })}
          />
        </FormInputContainer>
        <FormInputContainer>
          <Field
            name="proxyName"
            type="text"
            component={FormTextInput}
            label={intl.formatMessage({
              defaultMessage: "Name",
            })}
            placeholder={intl.formatMessage({
              defaultMessage: "e.g. publisher",
            })}
            validate={validator.from({
              required: true,
              minLength: 3,
            })}
          />
        </FormInputContainer>

        <FormInputContainer>
          <Field
            name="version"
            type="text"
            component={FormTextInput}
            label={intl.formatMessage({
              defaultMessage: "Version",
            })}
            placeholder={intl.formatMessage({
              defaultMessage: "e.g. v1, v2, v3, etc..,",
            })}
            validate={noSpaces}
          />
        </FormInputContainer>

        <FormInputContainer>
          <Field
            name="basePath"
            type="text"
            component={FormTextInput}
            label={intl.formatMessage({
              defaultMessage: "Base Path",
            })}
            placeholder={intl.formatMessage({
              defaultMessage:
                "e.g. /basepath, /base-path, /basepath/path1 etc.,",
            })}
            validate={validator.from({
              required: true,
            })}
          />
        </FormInputContainer>
        <FormInputContainer>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-user-token">
              <FormattedMessage defaultMessage="Example Consumption URL" />
            </InputLabel>
            <OutlinedInput
              id="example_url"
              type="text"
              readOnly={true}
              inputProps={{
                className: "use-code-font",
              }}
              value={url}
              multiline={true}
              label={
                <FormattedMessage defaultMessage="Example Consumption URL" />
              }
              startAdornment={
                <InputAdornment position="start">
                  <LinkIcon className={style.linkIcon} />
                </InputAdornment>
              }
            />
          </FormControl>
        </FormInputContainer>

        <Alert severity="info">
          Scope Prefix will be converted to Lowercase
        </Alert>
        <br />
        <FormInputContainer>
          <Field
            name="resourceServerName"
            type="text"
            component={FormTextInput}
            defaultValue={scopePrefix}
            label={intl.formatMessage({
              defaultMessage: "Scope Prefix",
            })}
            placeholder={intl.formatMessage({
              defaultMessage:
                "e.g. publisher (by default the API name will be populated)",
            })}
          />
        </FormInputContainer>

        <ActionInliner padding="inset-top">
          <Button
            onClick={onCancel}
            disabled={formRenderProps.submitting}
            type="button"
            variant="outlined"
            disableElevation
          >
            {" "}
            <FormattedMessage defaultMessage="Cancel" />
          </Button>

          <Button
            disabled={formRenderProps.submitting}
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
          >
            {" "}
            <FormattedMessage defaultMessage="Create" />
          </Button>
        </ActionInliner>
      </>
    );
  };

  if (!nameSpaces) {
    return (
      <span>Verifying whether youre team have associated Namespaces...</span>
    );
  } else if (nameSpaces?.length === 0) {
    return (
      <span>
        Your team have not yet mapped with any Namespaces. Please inform your
        sector administrator to map a Namespace for your team.
      </span>
    );
  } else {
    return (
      <FormContainer
        FormProps={{ onSubmit }}
        onSubmitSuccess={onSuccess}
        children={FormComponents}
        previewErrors={true}
      />
    );
  }
};

ProxyDetailsForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ProxyDetailsForm;
