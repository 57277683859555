import React, { useState, useEffect, useContext, FC, useCallback } from "react";
import { Button, InputAdornment, TextField, IconButton, Divider } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { AccordionContent } from "legacy-components/form/components/accordion-form/AccordionForm";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import { Field } from "react-final-form";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import FormContainer, { FormContainerSubmitHandler } from "legacy-components/form/container/FormContainer";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import { useSelector } from "react-redux";
import { FORM_ERROR } from "final-form";
import { ValidatorBuilderContext } from "legacy-components/form/validator/Validator";
import FormTextInput from "legacy-components/form/input/FormTextInput";
import { IAPIProxyNoAuthResponse } from "libs/resources/proxy/ProxyService";
import CancelIcon from '@material-ui/icons/Cancel';

export interface NoAuthConfigProps {
    apiName : string;
    environmentName : string;
    deployedUrl : string;
}

const NoAuthConfig : FC<NoAuthConfigProps> = ({apiName, environmentName, deployedUrl}) => {

    const onSubmit: FormContainerSubmitHandler<any> = () => (data, form) => {
        let path = data.addUrl;
        if (!path.startsWith("/")) {
            path = "/"+ path;
        }
        return new Promise((resolve) => {
            const formState = form.getState();
            if (formState.pristine) {
                resolve();
            } else {
                if (data) {
                    UnifiedAPIService.post(
                        UnifiedAPI.parseRequestURL(UnifiedAPI.api_noauth, {
                            apiName: apiName,
                            environment: environmentName,
                            
                        }), [{requestVerb: data.method,
                            requestPath: path}],
                    ).then(() => {
                        init();
                        resolve();
                    }, error => {
                        resolve && resolve({
                            [FORM_ERROR]: error.response?.data?.message ?? "Unknown error occured"
                        });
                    });
                }
            }
        })
    
    }

const Controls = () => {
    return (
        <div className = "addPathButton">
        <ActionInliner>
        <Button variant="contained" color="primary" type = "submit">
                    <FormattedMessage defaultMessage="Add Path" />
        </Button>
        </ActionInliner>
        </div>
    )
}

const [noAuth, setNoAuth] = useState<IAPIProxyNoAuthResponse>();
const init = useCallback(()=> {
    UnifiedAPIService.get(
        UnifiedAPI.parseRequestURL(UnifiedAPI.api_noauth, {
            apiName: apiName,
            environment: environmentName
        }), {
    }).then(
        (response: any) => {  
            setNoAuth(response.data);
    });
},[apiName, environmentName])

useEffect(() => {
    init();
},[]);
const allowedMethods = [
    {"key": "GET", "value" : "GET"},
    {"key": "POST", "value" : "POST"},
    {"key": "PUT", "value" : "PUT"},
    {"key": "DELETE", "value" : "DELETE"}
];

const onDeleteNoAuth = ((noAuthId: string) => {
        UnifiedAPIService.delete(
            UnifiedAPI.parseRequestURL(UnifiedAPI.api_noauth_id, {
                apiName: apiName,
                environment: environmentName,      
                noAuthId : noAuthId
            }),
        ).then(() => {
            init();
        });
})
    const FormComponents = () => {
        const intl = useIntl();
        const validator = useContext(ValidatorBuilderContext);

        return (<>
        <span className = "noauthheader">
        <FormattedMessage defaultMessage="Add API resources here that may need to bypass authentication, subscription validation, and gateway checks." />         
        </span>
        <div className = "noAuthPath">
        <FormInputContainer controls = {<Controls/>}>
         <Field
        InputProps={{
        startAdornment: (
            <InputAdornment position="start" variant = 'outlined' className = "inputAdorment">
               { <TextField  id="filled-basic"
                   inputProps={{style: {      padding: '18px,12px,18px'
                   }}} // the change is here
               className = "noauthendpoint" 
               value ={(deployedUrl).split("api")[0]+"..."+
               (deployedUrl).substring(deployedUrl.length-8)} 
                variant="filled" disabled >
                    </TextField>}
            </InputAdornment>
        )
    }}
        name="addUrl"
        type="text"
        
        component={FormTextInput}
        placeholder={intl.formatMessage({
            defaultMessage: "Add Resource"
        })}
        validate={validator.from({
            required: true,
        })}
        
    />
    </FormInputContainer>
    </div>
    <div className = "methodSelect">
    <FormInputContainer >
        <Field name="method"
        type="text"
        component={FormTextInput}
        label= "Methods"
        options = {allowedMethods}
        validate={validator.from({
            required: true,
        })}
        />
        </FormInputContainer>
        </div>
        </>);
    }

   
    return (
        <AccordionContent size="small" header={<FormattedMessage defaultMessage="No Authentication Resource" />} >
            <FormContainer 
             stepperId={"noauth"} FormProps={{ onSubmit }} children={FormComponents}/>
             <br></br>
             {noAuth && noAuth.pathVerbDetails.map(pathVerbDetail => {
                 return (
                     <>
                     <div className = "noauthdata">
                   <div className = "endpoint">{pathVerbDetail.path}</div>
                    {pathVerbDetail.methods.map(method => (<>
                    <div className = "verb">{method.method}</div>
                    <div className = "cancel">
                <IconButton size="small" aria-label="close" style = {{ color: "#A9A9A9" }} >
                    <CancelIcon fontSize="small"  onClick = {()=> {onDeleteNoAuth(method.noAuthId)}}/>
                </IconButton>   
                </div>
                    </>)

                    )}
                    <div className = "divider">
                    <Divider/>
                    </div>
                    </div>
                    </>)
             }
                 
             
             )}
            
        </AccordionContent>
    )
};

export default NoAuthConfig;