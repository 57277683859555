import Table, { TableColumn } from "legacy-components/table/Table";
import React from "react";
import { IEvent } from "pages/home/subscriptions/pages/subscription-summary-page/SubscriptionSummaryPage";
import { Badge, Box, IconButton, Tooltip } from "@material-ui/core";
import { resolveEventFilterToText } from "./filter";
import CreateIcon from '@material-ui/icons/Create';
import EventService from "../service/EventService";
import DeleteEventConf from "./DeleteEventConfiguration";

interface EventListViewerProps {
  eventDeployments: IEvent[] | undefined;
  onEditClick: any;
  subscriptionId: string;
  onChange: ()=> void;
}

const Filter = (props: any) => {
  return(
    <Box >{
        props?.value?.fields?.length >= 1 ? 
          resolveEventFilterToText(props?.value): 
          <Box style={{color: '#666666'}}>No Filters</Box>
      }
    </Box>
  )
}

export const DeploymentBadge = (props: any) => {
  return (
    <Box style={{
      paddingLeft: "30px",
      textTransform: "capitalize"}}>
      <Badge
      color="primary"
      badgeContent={props?.value}
      style={{ textTransform: "capitalize"}}
      />
    </Box>
    
  )
}



const EventListViewer: React.FC<EventListViewerProps> = ({eventDeployments, onEditClick, subscriptionId, onChange}) => {
  const handleDrawerClose = (event: any) => {
    try {
      EventService.deleteEventSubscription( subscriptionId, event.subscriptionTrn).toPromise().then(()=>{
        onChange();
      });      
  } catch (error) {
      console.error("Error creating subscription:", error);
  }
  };

  const Actions = (props: any) => {
    return (
      <Box className={"actions"}>
        <Tooltip title="Edit">
          <span>
          <IconButton style={{color: '#0063a3'}} onClick={() => onEditClick(props.data)}>
            <CreateIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Box margin="l1" />
        <DeleteEventConf subscriptionId={subscriptionId} subscriptionTrn={props.data.subscriptionTrn} onChange={onChange}/>
      </Box>
      
    )
  }
  return (
    <Box mt={"30px"}>
    <Table
      noRowsText={`No services in this Subscription`}
      rowData={eventDeployments}
      pagination={false}
      height={undefined}
      domLayout= 'autoHeight'
      getRowNodeId={(data) => data.serviceVersion}
       frameworkComponents={{ Filter, DeploymentBadge, Actions }}
    >

      <TableColumn
        flex={1}
        field={"serviceVersion"}
        headerName="Service"
        sortable
        unSortIcon={true}
      />
      <TableColumn
        width={180}
        headerName="Deployment"
        field="serviceDeployment"
        cellRenderer="DeploymentBadge"
        sortable={false}
        unSortIcon={false}
      />
      <TableColumn 
      field="filter"
      headerName="filter"
      cellRenderer={"Filter"}
      flex={2} />

      <TableColumn field="description" 
      headerName="Description"
      flex={2} />
      
      <TableColumn
        width={150}
        headerName="Actions"
        cellRenderer="Actions"
      />
    </Table>
    </Box>
  );
};

export const EventList = EventListViewer;
