import React, { useState, useCallback, useImperativeHandle, FC, PropsWithRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, makeStyles, createStyles, Divider } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ProxyDeployFormData } from '../../models/proxy.model';
import ProxyDeployForm from '../../proxy-forms/ProxyDeployForm';
import { useCompactDialog } from '../../../../../legacy-components/dialog/UseCompactDialog';

const useStyles = makeStyles(() => {
    return createStyles({
        header: {
            position: "relative"
        },
        dialog: {
            overflowX: "hidden"
        }
    })
});

export interface ProxyEnvironmentDialogRef {
    open(data?: ProxyDeployFormData, prefill?: boolean): void
}

const ProxyEnvironmentFormViewer = ({ proxyName, deployment, env, onSubmit, onCancel, initialValues, prefill, isAlreadyDeployed }: any) => {
    return (<ProxyDeployForm prefillValues={prefill ? initialValues : undefined} initialValues={prefill ? undefined : initialValues}
         proxyName={proxyName} deployment={deployment} 
         env={env} onCancel={onCancel} onSuccess={onSubmit} isAlreadyDeployed={isAlreadyDeployed} />)
}

const ProxyEnvironmentDialog: FC<PropsWithRef<any>> = forwardRef(({ proxyName, deployment, env, onSubmit }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();
    const [formValues, setFormValues] = useState<{
        value?: ProxyDeployFormData,
        prefill?: boolean
    } | undefined>()

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleSubmit = useCallback((data: ProxyDeployFormData) => {
        setOpen(false);
        onSubmit(data);
    }, [onSubmit, setOpen]);

    const handleClickOpen = (value?: ProxyDeployFormData, prefill?: boolean) => {
        setFormValues({
            value,
            prefill
        });
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: (data?: ProxyDeployFormData, prefill?: boolean) => handleClickOpen(data, prefill)
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            classes={{
                paper: style.dialog
            }}>

            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Deployment Configuration" />
                </DialogTitle>
                <Divider />
            </div>
            <div>
                <ProxyEnvironmentFormViewer prefill={formValues?.prefill}
                 initialValues={{backendUrl: deployment?.backendUrl, 
                 timeout: deployment?.timeout}} proxyName={proxyName}
                  deployment={deployment} env={env} onCancel={handleClose}
                   onSubmit={handleSubmit} isAlreadyDeployed={deployment?.deployStatus == 'DEPLOY_COMPLETED'
                   ? true : false}/>
            </div>
        </Dialog>
    );
});

ProxyEnvironmentDialog.propTypes = {
    proxyName: PropTypes.string.isRequired,
    env: PropTypes.string.isRequired
}

export default ProxyEnvironmentDialog

