import React, { useContext } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { createStyles, IconButton, makeStyles } from "@material-ui/core";
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded'


import DeleteIcon from '@material-ui/icons/Delete';
import { useConfirm } from "material-ui-confirm";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import ResourceAbility from "libs/security/authorization/ResourceAbility";


const useStyles = makeStyles(() =>
     createStyles({
          teamLogo: {
               fill: "#fff",
               width: "20px"
          },
          actions: {
               padding: '0 !important'
          },
     })
);

/**
 * @class TeamCard
 * @description component contains Single card view for Team listing
 */
const TeamCard = (props: any) => {
     const dispatch = useDispatch();
     const history = useHistory();
     const classes = useStyles();
     const confirm = useConfirm();
     const notificationContext = useContext(SnackNotificationContext);

     const showSummary = () => {
          dispatch(history.push("/home/teams/" + props.team.teamName + "/users"));
     }

     const onRemoveButtonClicked = (event: any) => {
          event.stopPropagation();
          confirm(
               {
                    description: 'Do you want to remove ' + props.team.teamName + ' from your sector?'
               }
          ).then(
               () => {
                    UnifiedAPIService.delete(
                         UnifiedAPI.parseRequestURL(UnifiedAPI.delete_team, {
                              teamId: props.team.id,
                         })
                    ).then(
                         () => {
                              props.onChange && props.onChange(event);

                              notificationContext.show({
                                   id: "success",
                                   message: (<div>{props.team.teamName} team is deleted</div>)
                              });

                         },
                         (error: any) => {
                              notificationContext.show({
                                   id: "success",
                                   message: (<div>{error.response.data.message}</div>)
                              });
                         }
                    )
               }
          );
     }

     return (
          <div className="card team-card" onClick={showSummary} >
               <div className="image-block">
                    <PeopleOutlineRoundedIcon className={classes.teamLogo} />
               </div>
               <h2 className="name">
                    {props.team.displayName}
               </h2>
               <p className="description">
                    {props.team.description}
               </p>
               <div className={" actions " + classes.actions}>
                    {/* <div>
                         <StatusBadge status={{
                              kind: "access",
                              key: "SUPER ADMIN"
                         }} />
                    </div> */}
                    <ResourceAbility can="delete" resource={({ Team }) => Team.delete}>
                         <IconButton onClick={onRemoveButtonClicked}>
                              <DeleteIcon />
                         </IconButton>
                    </ResourceAbility>

               </div>

          </div>
     );

}
export default TeamCard;