import React, { ReactNode, memo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'baseline',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
                marginLeft: theme.spacing(2)
            },
        },
    }),
);

interface FormInputInlinerProps {
    children: ReactNode
}


/**
 * @function FormInputInliner
 * @description FormInputInliner should be used to display inline form controls.
 */
const FormInputInliner = (props: FormInputInlinerProps) => {
    const style = useStyles();

    return (<div className={style.root}>{props.children}</div>)
};

FormInputInliner.propTypes = {
    children: PropTypes.any.isRequired
};

export default memo(FormInputInliner, () => false);