import { CardHeader as MuiCardHeader } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import type { PropsWithChildren } from "react";

import useCardStyles from "./Card.style";
import Typography from "legacy-components/typography/Typography";

export interface CardHeaderProps {
  action?: JSX.Element | string;
  avatar?: JSX.Element | string;
  title?: JSX.Element | string;
  subTitle?: JSX.Element | string;
}
export const CardHeader: React.FC<PropsWithChildren<CardHeaderProps>> = ({
  children,
  action,
  avatar,
  title,
  subTitle,
}) => {
  const classes = useCardStyles();
  return (
    <MuiCardHeader
      className={classes.header}
      data-testid="card-header"
      action={action ?? undefined}
      avatar={
        avatar ?? (
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        )
      }
      title={
        title ? (
          <Typography type="h4" color="primary" className={classes.title} data-testid = "title">
            {title}
          </Typography>
        ) : (
          <Skeleton
            animation="wave"
            height={10}
            width="60%"
            style={{ marginBottom: 6 }}
          />
        )
      }
      subheader={
        subTitle ? (
          <Typography type="body" className={classes.subTitle} data-testid = "subTitle">
            {subTitle}
          </Typography>
        ) : (
          <Skeleton animation="wave" height={10} width="80%" />
        )
      }
    >
      {children}
    </MuiCardHeader>
  );
};

export default CardHeader;
