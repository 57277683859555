import React, { PropsWithChildren, memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import { InlineOpenInNewIconFormatter } from '../../formatters/InlineIconFormatter';

interface OpenInNewTabLinkProps {
    href: string;
}

function OpenInNewTabLink({ children, href }: PropsWithChildren<OpenInNewTabLinkProps>) {
    return (
        <Link rel="noopener noreferrer" href={href} target="_blank">
            <InlineOpenInNewIconFormatter>
                {children}
            </InlineOpenInNewIconFormatter>
        </Link>
    )
}

OpenInNewTabLink.propTypes = {
    href: PropTypes.string.isRequired
}

export default memo(OpenInNewTabLink);

