import React from 'react';

import { Route, Redirect, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';

import { ResourceAbility } from 'libs/security/authorization';
import ApprovalProxyList from './api/ApprovalProxyList';
import ApprovalReview from './api/ApprovalReview';
import ApprovalSubscriptionList from './subscription/ApprovalSubscriptionList';
import SubscriptionApprovalReview from 'pages/home/approval/subscription/ApprovalReview';

const fallBackdiv = <div className="message info">You do not have permission to access the page. Please contact your Team owner.</div>

/**
 * @class ApprovalRoutes
 * @description For storing internal page redirection inside API Summary Module
 */
class ApprovalRoutes extends React.Component<any, any> {

    /**
     * @function render
     * @description repaints the component on each change of state and props value
     */
    render() {
        return (
            <Switch>
                <Route exact path="/home/approvals" render={() => (<Redirect to="/home/approvals/proxy" />)} />
                <Route path="/home/approvals/proxy" render={() => (
                    <ApprovalProxyList />
                )} />
                <Route path="/home/approvals/api/:proxy/:request/:requestType" render={() => (
                    <ApprovalReview />
                )} />
                <Route path="/home/approvals/prod-subscription" render={() => (
                    <ApprovalSubscriptionList />
                )} />
                <Route path="/home/approvals/subscription/:subscription/:request/:requestType" render={() => (
                    <SubscriptionApprovalReview />
                )} />
                <Route path="*"  render={()=><Redirect to ="/home/approvals" />}/>
            </Switch>
        );
    }
}

export default withRouter(ApprovalRoutes);