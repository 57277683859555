import React, {
  FC,
  useState,
  useImperativeHandle,
  useCallback,
  PropsWithRef,
  Ref,
  forwardRef,
  useEffect,
} from "react";
import { useStyles } from "pages/home/products/product-forms/ProductProxyDialog";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useCompactDialog } from "legacy-components/dialog/UseCompactDialog";
import { ApiVerificationReviewerForm } from "../forms/VerificationReviewerForm";
import ApprovalService from "pages/home/approval/ApprovalService";
import { UserInfo } from "pages/home/approval/model/approval.model";

export interface ReviewerDialogRef {
  open(): void;
  close(): void;
}
interface ReviewerDialogProps {
  ref: Ref<ReviewerDialogRef | undefined>;
  proxy: string;
  onSuccess: () => void;
}
const ReviewerDialog: FC<PropsWithRef<ReviewerDialogProps>> = forwardRef(
  ({ proxy, onSuccess }, ref) => {
    const styles = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();
    /*const [reviewers, setReviewers] = useState<
      Array<{
        key?: string;
        value?: string;
      }>
    >();
    useEffect(() => {
      ApprovalService.forReviewers().subscribe(
        (response) => {
          setReviewers(
            response.map((data: UserInfo) => {
              return {
                key: data.emailAddress,
                value: data.name + ", " + data.emailAddress,
              };
            })
          );
        },
        () => {}
      );
    }, []);*/
    const handleClickOpen = useCallback(() => {
      setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
      setOpen(false);
    }, [setOpen]);

    useImperativeHandle(ref, () => ({
      open: () => handleClickOpen(),
      close: () => handleClose(),
    }));

    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={open}
          scroll={scroll}
          onClose={handleClose}
        >
          <DialogTitle id="form-dialog-title">
            <FormattedMessage defaultMessage="API Verification Submission" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to submit the API verification details?
              Click cancel to go back to review and submit or Yes to proceed.
            </DialogContentText>
          </DialogContent>
          <div className={styles.createForm}>
            {
              <ApiVerificationReviewerForm
                proxy={proxy}
                onSuccess={onSuccess}
                onCancel={handleClose}
              />
            }
          </div>
        </Dialog>
      </>
    );
  }
);

export default ReviewerDialog;
