import { ConsumerClient2 } from "pages/home/consumer/service/ConsumerClient";
import { from } from "rxjs";
import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";

export interface IEventSubscriptionRequest {
    environment: string;
    applicationId: string | undefined;
    description: string;
    filter: IFilterRequest | null;
    service_version: string;
}

export interface IEventUpdateSubscriptionRequest {
    description: string;
    filter: IFilterRequest | null;
}

export interface IFilterRequest{
    criteria: string,
    fields: Array<IFieldsRequest>
}

export interface IFieldsRequest {
    key: string,
    value: string
}

@APIResource(ConsumerClient2, "/subscriptions")
class EventServiceImpl {
  @APIMethod("/:subscriptionId/events")
  public createEventSubscription(request: IEventSubscriptionRequest, subscriptionId: string) {
    return from(
      ConsumerClient2.post(apiMethodContext(arguments), request, {
        params: {
          subscriptionId
        }
      })
    );
  }

  @APIMethod("/:subscriptionId/events/:subscriptionTrn")
  public updateEventSubscription(request: IEventUpdateSubscriptionRequest, subscriptionId:string, subscriptionTrn: string) {
    return from(
      ConsumerClient2.put(apiMethodContext(arguments), request, {
        params: {
            subscriptionId,
            subscriptionTrn,
        },
      })
    );
  }

  @APIMethod("/:subscriptionId/events/:subscriptionTrn")
  public deleteEventSubscription(subscriptionId:string, subscriptionTrn: string){
  return from(
      ConsumerClient2.delete(apiMethodContext(arguments), {
        params: {
            subscriptionId,
            subscriptionTrn,
        },
      })
    );
  }
  
}

const EventService = new EventServiceImpl();

export default EventService;
