
/**
 * @file actions
 * @description contains list of all actions in redux state
 */
export const saveMetadata = "SAVE_METADATA";
export const authSuccess = 'AUTH_SUCCESS';
export const loadUserData = 'LOAD_USER_DATA';
export const signOut = 'SIGN_OUT';
export const loadFeatures = 'LOAD_FEATURES';
export const toggleNavigationPanel = 'TOGGLE_NAVIGATION_PANEL';
export const storeProxy = 'STORE_PROXY';
export const storeValue = 'STORE_VALUE';
export const clearForm = 'CLEAR_FORM';
export const storeUserDetails = 'STORE_USER_DETAILS';
export const clearUserDetails = 'CLEAR_USER_DETAILS';
export const storeEnvironments = 'STORE_ENVIRONMENTS';
export const storeProxyThrottlingConfig = 'STORE_PROXY_THROTTLING_CONFIG';
export const clearStore = "CLEAR_STORE";
export const storeUserRole = "STORE_USER_ROLE";
export const storeTeamDetails = "STORE_USER_TEAM_DETAILS"
export const storeTeamContext = "STORE_USER_TEAM_CONTEXT";
export const clearTeamDetails = 'CLEAR_TEAM_DETAILS';
export const saveIdentityEnvironment = 'SAVE_IDENTITY_ENVIRONMENT';
