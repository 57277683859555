import React, { PropsWithChildren, memo, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { notBlank } from '../../../libs/utils/shared/common';

interface NAWrapProps {
    value?: any;
    fallback?: ReactElement;
    showMessage?: boolean;
    message?: string;
}

function NAWrap({ children, value, fallback, showMessage, message }: PropsWithChildren<NAWrapProps>) {
    return (
        <>
            {notBlank(value) ? children : fallback ?? (showMessage ? (message? message : <FormattedMessage defaultMessage="Not available" />) : null)}
        </>
    )
}

NAWrap.propTypes = {
    value: PropTypes.any
}

export default memo(NAWrap);

