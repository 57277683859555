import React from "react";
import Footer from "legacy-components/footer/Footer";
import { Header} from "../landing/Landing";
import { createStyles, makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            fill: "#fff"
        },
        policyContent: {
            maxWidth: '1200px',
            margin: 'auto',
            marginTop: '50px'
        },
        button: {
            color: '#005f9e !important',
            border: '1px solid #005f9e !important',
            backgroundColor: 'transparent',
            marginBottom: '30px',
            '&:hover': {
                backgroundColor: "#005f9e !important",
                color: '#fff !important'
            }
        }
    })
);

const CookiePolicy = (props: any) => {
    
    const classes = useStyles();

    return (
        <section className={classes.root}>
            <Header />
            <div className={classes.policyContent}>
                <h1>Cookie Policy</h1>
                <button id="ot-sdk-btn" className={"ot-sdk-show-settings " + classes.button} >Cookies Settings</button>
                {/* OneTrust Cookies List start */}
                <div id="ot-sdk-cookie-policy" ></div>
                {/* OneTrust Cookies List end */}
            </div>
            <Footer/>
        </section>
    );
}
export default CookiePolicy;