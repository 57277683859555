import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import AuthService from '../../services/authService';
import HomeRoutes from './routes';
import VerticalNavigation from '../../legacy-components/verticalNavigation';
import ProfileService from '../../services/profileService';
import UnifiedAPIService, { UnifiedAPI } from '../../services/UnifiedAPIService';
import * as actions from "../../store/actions/actions";
import { CloudConsoleClient } from '../../libs/utils/http/client/HttpClient';
import TeamService from 'libs/resources/team/TeamService';
import Header from '../../header/Header';
import ShareService from 'libs/resources/proxy/ShareService';
import { saveState } from 'store/sessionStorage';
import store from 'store/store';
import Footer from 'legacy-components/footer/Footer';
import ConsumerClient, { ConsumerClient2 } from './consumer/service/ConsumerClient';

export const useRouteLogger = () => {
     const history = useHistory();
   
     useEffect(() => {
       const unlisten = history.listen((location, action) => {
         console.log(`Route changed to: ${location.pathname} ${location.state}`);
         console.log(`Route change action: ${action}`);
       });
       return () => {
         unlisten();
       };
     }, [history]);
   };
   

class Home extends React.Component<any, any> {

     /**
      * @function constructor
      * @param {*} props - Having properties passed by parent element
      *  * used for state initialization
      */
     constructor(props: any) {
          super(props);
          this.state = {
               breadcrumb: { title: 'home', link: '/home' },
               isShowNav: true,
               isShowFullNav: true
          };
     }

     
     UNSAFE_componentWillMount() {
          ProfileService.setToken?.(this.props.accessToken);
          UnifiedAPIService.setToken?.(this.props.accessToken);
          CloudConsoleClient.useToken?.(this.props.accessToken);
          ConsumerClient.useToken?.(this.props.accessToken);
          ConsumerClient2.useToken?.(this.props.accessToken);
          TeamService.useToken(this.props.accessToken);
          ShareService.useToken(this.props.accessToken);
          window.addEventListener('onbeforeunload', this.handleWindowClose);
          if (this.props.isAuthenticated) {
               saveState(store.getState());
          }
          UnifiedAPIService.get(UnifiedAPI.metadata)
               .then((response: any) => {
                    this.props.dispatch({ type: actions.saveMetadata, request: response.data });
               }, () => {
                    this.props.history.push("/home/register");
               });
     }

     shouldComponentUpdate(nextProps: any, prevState: any) {
          if (nextProps.accessToken !== this.props.accessToken) {
               ProfileService.setToken?.(nextProps.accessToken);
               UnifiedAPIService.setToken?.(nextProps.accessToken);
               CloudConsoleClient.useToken?.(nextProps.accessToken);
               ConsumerClient.useToken?.(nextProps.accessToken);
               TeamService.useToken(nextProps.accessToken);
               ShareService.useToken(nextProps.accessToken);
          }
          return true;
     }

     handleWindowClose = () => {
          if (this.props.isAuthenticated) {
               saveState(store.getState());
          }
     }

     toggleNav = () => {
          this.setState({
               ...this.state,
               isShowNav: !this.state.isShowNav
          })
     }
     toggleNavSize = () => {
          this.setState({
               ...this.state,
               isShowFullNav: !this.state.isShowFullNav
          })
     }
     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          return (
               <React.Fragment>
                    <Header toggleNav={this.toggleNav} toggleNavSize={this.toggleNavSize} />
                    <main>
                         <VerticalNavigation isShow={this.state.isShowNav} isShowFullSize={this.state.isShowFullNav} />
                         <section className="main-content">
                              {this.props.user?.id && <div className="home-section">
                                   <HomeRoutes />
                              </div>}
                              {/* <Button onClick={() => this.props.history.push('/home/subscriptions/123')}>Profile</Button> */}

                              <Footer />
                         </section>
                    </main>
               </React.Fragment>

          );
     }
}

/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state: any) => {
     return {
          isAuthenticated: AuthService.isAuthenticated(state),
          featuredApis: state.common.featuredApis,
          accessToken: state.auth?.user?.access_token,
          user: state.userManager,
     }
};

export default withRouter(connect(mapStateToProps)(Home));
