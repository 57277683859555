import { ApplicationDeleteSecretFormData } from "../models/application.model";
import { IdentityApplicationEnvironmentType } from "../PublisherApplicationService";
import { FC, useContext, ReactElement } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { FormApi, FORM_ERROR } from "final-form";
import { FormRenderProps, Field } from "react-final-form";
import { ValidatorBuilderContext } from "../../../../../legacy-components/form/validator/Validator";
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Button, InputAdornment } from "@material-ui/core";
import FormContainer from "../../../../../legacy-components/form/container/FormContainer";
import React from "react";
import FormInputContainer from "../../../../../legacy-components/form/container/FormInputContainer";
import FormTextInput from "../../../../../legacy-components/form/input/FormTextInput";
import ActionInliner from "../../../../../legacy-components/form/action/ActionInliner";
import { WarnButton } from "../../../../../legacy-components/form/action/WarnButton";
import IdentityApplicationService from "../IdentityApplicationService";
import { take } from "rxjs/operators";
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';

const useStyles = makeStyles(({ spacing, palette }) =>
    createStyles({
        root: {
            padding: spacing(3)
        },
        keyIcon: {
            color: palette.success.main
        }
    }),
);

interface ApplicationDeleteSecretFormProps {
    onCancel: () => void,
    onSuccess: (data: ApplicationDeleteSecretFormData) => void
    applicationName: string,
    env: IdentityApplicationEnvironmentType,
    formContent?: ReactElement,
    secretId: string;
}

const ApplicationDeleteSecretForm: FC<ApplicationDeleteSecretFormProps> = ({ onCancel, onSuccess, applicationName, env, formContent, secretId }) => {
    const intl = useIntl();
    const styles = useStyles();

    const onSubmit = () => (data: ApplicationDeleteSecretFormData, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve();
            } else {
                const envFactory = IdentityApplicationService.environments[env];

                envFactory
                    .getSecretValue(applicationName, secretId)
                    .pipe(take(1))
                    .subscribe(value => {
                        const secretValue = value.secret?.slice(-5);

                        if (data.secretValue === secretValue) {
                            IdentityApplicationService.environments[env]?.deleteSecret(secretId, applicationName)
                                .then(
                                    () => resolve(),
                                    error => resolve({
                                        [FORM_ERROR]: error
                                    }));
                        } else {
                            resolve({
                                secretValue: intl.formatMessage({
                                    defaultMessage: "Secret value didn't match"
                                })
                            })
                        }
                    }, error => {
                        resolve({
                            [FORM_ERROR]: error
                        })
                    });
            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        const validator = useContext(ValidatorBuilderContext);


        return (
            <div className={styles.root}>
                {formContent}

                <FormInputContainer>
                    <Field
                        name="secretValue"
                        type="text"
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Secret"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "#####"
                        })}
                        validate={validator.from({
                            required: true
                        })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <VpnKeyOutlinedIcon className={styles.keyIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormInputContainer>

                <ActionInliner padding="inset-top">
                    <Button onClick={onCancel} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Cancel" /></Button>

                    <WarnButton disabled={formRenderProps.submitting || formRenderProps.pristine} type="submit" variant="contained" color="primary" disableElevation>
                        <FormattedMessage defaultMessage="Delete" />
                    </WarnButton>
                </ActionInliner>
            </div>);
    }

    return <FormContainer previewErrors={true} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} />;
}

ApplicationDeleteSecretForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    applicationName: PropTypes.string.isRequired,
    env: PropTypes.oneOf<IdentityApplicationEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired
}

export default ApplicationDeleteSecretForm;