import React, { useState, useEffect, useCallback } from 'react';
import { ListItem, ListItemText, List, ListItemSecondaryAction, Divider, Switch, FormHelperText } from '@material-ui/core';
import { FormSelectionListProps, useFieldSelectionStyles, FormSelectionListItemOption } from './FormSelection';
import { useForm } from 'react-final-form';
import useIsMounted from '../action/UseIsMounted';
import GenericCheckbox from './GenericCheckbox';
import { useExtendedFieldState } from './FormViewControls';

const ListOptionItem = ({ onToggle, selectControl, selectedItems, option: { title, helperText, value }, disabled }: {
    onToggle: (value: string) => void,
    option: FormSelectionListItemOption,
    selectedItems: string[],
    selectControl: FormSelectionListProps["selectConrol"],
    disabled?: boolean
}) => {

    const toggleHandler = useCallback(() => onToggle(value), [onToggle, value]);

    return (
        <ListItem disabled={disabled} dense button onClick={toggleHandler}>
            <ListItemText primary={title} secondary={helperText} />
            <ListItemSecondaryAction>
                {selectControl === "switch" ?
                    <Switch
                        edge="end"
                        onChange={toggleHandler}
                        disabled={disabled}
                        checked={selectedItems.some(item => item === value)}
                    /> :
                    <GenericCheckbox
                        edge="end"
                        onChange={toggleHandler}
                        checked={selectedItems.some(item => item === value)}
                        color="primary"
                        disabled={disabled}
                    />
                }
            </ListItemSecondaryAction>
        </ListItem>
    );
}

const FormSelectionList = ({ name, fields, options, selectControl, meta, helperText, disabled }: FormSelectionListProps) => {
    const fieldSelectionStyle = useFieldSelectionStyles();
    const form = useForm();
    const [selectedItems, setSelectedItems] = useState<string[]>(fields.value ?? []);
    const isMounted = useIsMounted();
    const { canShowError, fieldHelperText } = useExtendedFieldState(meta, helperText);

    const toggle = useCallback((value: string) => {
        const index = selectedItems.findIndex(item => item === value);

        if (isMounted) {
            if (index >= 0) {
                fields.remove(index);
            } else {
                fields.push(value);
            }

            form.mutators?.setFieldTouched?.(name, true);
        }
    }, [fields, form.mutators, isMounted, name, selectedItems]);

    useEffect(() => {
        setSelectedItems(fields.value ?? []);
    }, [fields.value])

    return (
        <>
            <List>
                {options.map(option => {
                    return (
                        <React.Fragment key={option.value}>
                            <ListOptionItem disabled={disabled} selectControl={selectControl} selectedItems={selectedItems} onToggle={toggle} option={option} />
                            {(options?.length > 1) && <Divider className={fieldSelectionStyle.divider} component="li" />}
                        </React.Fragment>
                    );
                })}
            </List>
            {fieldHelperText && <FormHelperText children={fieldHelperText} error={canShowError} />}
        </>);
};

export default FormSelectionList;
