import React, { memo, useCallback, useState, useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types';
import { IconButton, Tooltip, IconButtonTypeMap, CircularProgress } from '@material-ui/core'
import CopyIconOutline from '../../icons/CopyIconOutline'
import { FormattedMessage } from 'react-intl';
import { SnackNotificationContext } from '../../notifications/GenericSnackNotification';
import { notBlank } from '../../../libs/utils/shared/common';
import { AsyncActionInstance } from './AsyncAction';

interface CopyToClipboardProps {
    text: string;
    edge?: IconButtonTypeMap["props"]["edge"]
    size?: IconButtonTypeMap["props"]["size"]
    className?: string;
}

interface CopyToClipboardAsyncProps {
    instance: AsyncActionInstance<string>;
    edge?: IconButtonTypeMap["props"]["edge"]
    size?: IconButtonTypeMap["props"]["size"]
    className?: string;
}

function useCopyToClipboard(): [boolean, (value: string) => Promise<boolean>] {
    const [disabled] = useState(typeof navigator.clipboard?.writeText !== "function");

    const onCopy = useCallback((value) => {
        return new Promise<boolean>((resolve) => {
            if (!disabled && navigator.clipboard?.writeText) {
                navigator.clipboard.writeText(value).then(() => resolve(true), () => resolve(false))
            } else {
                resolve(false);
            }
        });
    }, [disabled]);

    return [disabled, onCopy];
}

function CopyToClipboard({ text, edge, size, className }: CopyToClipboardProps) {
    const notification = useContext(SnackNotificationContext);
    const [copyDisabled, copy] = useCopyToClipboard();
    const [disabled, setDisabled] = useState(true);

    useEffect(() => setDisabled(copyDisabled || !notBlank(text)), [copyDisabled, text])

    const onCopy = useCallback(() => copy(text).then(success => {
        notification.show({
            id: "copy-to-clipboard",
            message: success ? <FormattedMessage defaultMessage="Copied to Clipboard" /> : <FormattedMessage defaultMessage="Failed to copy the text" />,
            timeout: 4000
        })
    }), [copy, text, notification])

    return (
        <Tooltip enterDelay={1000} title={<FormattedMessage defaultMessage="Copy to Clipboard" />}>
            <span className={className}>
                <IconButton size={size} onClick={onCopy} disabled={disabled} edge={edge}>
                    <CopyIconOutline />
                </IconButton>
            </span>
        </Tooltip>
    )
}

function _AsyncCopyToClipboard({ instance, edge, size, className }: CopyToClipboardAsyncProps) {
    const notification = useContext(SnackNotificationContext);
    const [copyDisabled, copy] = useCopyToClipboard();
    const [disabled, setDisabled] = useState(true);
    const [state, { subscribe }] = useMemo(() => instance, [instance]);

    useEffect(() => setDisabled(copyDisabled || state.loading), [copyDisabled, state.loading]);

    useEffect(() => {
        if (state.status === "loaded") {
            if (notBlank(state.value)) {
                copy(state.value ?? "").then(success => {
                    notification.show({
                        id: "copy-to-clipboard",
                        message: success ? <FormattedMessage defaultMessage="Copied to Clipboard" /> : <FormattedMessage defaultMessage="Failed to copy the text" />,
                        timeout: 4000
                    })
                })
            }
        } else if (state.status === "error") {
            notification.show({
                id: "copy-to-clipboard",
                message: <FormattedMessage defaultMessage="Failed to copy the text" />,
                timeout: 4000
            })
        }

    }, [copy, state, notification])

    const onCopy = useCallback(() => subscribe(), [subscribe])

    return (
        <Tooltip enterDelay={1000} title={<FormattedMessage defaultMessage="Copy to Clipboard" />}>
            <span className={className}>
                <IconButton size={size} onClick={onCopy} disabled={disabled} edge={edge}>
                    {state.loading ? <CircularProgress size="16px" /> : <CopyIconOutline />}
                </IconButton>
            </span>
        </Tooltip>
    )
}

CopyToClipboard.propTypes = {
    edge: PropTypes.oneOf<IconButtonTypeMap["props"]["edge"]>([false, "end", "start"]),
    size: PropTypes.oneOf<IconButtonTypeMap["props"]["size"]>(["medium", "small"])
}

export default memo(CopyToClipboard);

export const AsyncCopyToClipboard = memo(_AsyncCopyToClipboard)

