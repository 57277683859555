import React from 'react';

import { Route, Redirect, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';


import CertificateView from './CertificateView';


/**
 * @class CertificateRoutes
 * @description For storing internal page redirection inside API Summary Module
 */
class CertificateRoutes extends React.Component<any, any> {

    /**
     * @function render
     * @description repaints the component on each change of state and props value
     */
    render() {
        return (
            <Switch>
                <Route exact path="/home/certificates" render={() => (<Redirect to="/home/certificates/list" />)} />
                <Route path="/home/certificates/list" render={() => (
                    <CertificateView />
                )} />

            </Switch>
        );
    }
}

export default withRouter(CertificateRoutes);