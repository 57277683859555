import React, { FC, useContext, useState } from 'react'
import { FormRenderProps, Field } from 'react-final-form';
import { Button, makeStyles, createStyles } from '@material-ui/core';
import { FormApi, FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import FormInputContainer from 'legacy-components/form/container/FormInputContainer';
import FormContainer from 'legacy-components/form/container/FormContainer';
import { ValidatorBuilderContext } from 'legacy-components/form/validator/Validator';
import ActionInliner from 'legacy-components/form/action/ActionInliner';
import FormTextInput from 'legacy-components/form/input/FormTextInput';
import UnifiedAPIService, { UnifiedAPI } from 'services/UnifiedAPIService';
import FormAlert from 'legacy-components/form/container/FormAlert';
import { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import { sanitize } from 'dompurify';

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            padding: spacing(3)
        },
    }),
);

interface ResourceApplicationFormProps {
    onCancel?: () => void,
    onSuccess?: (resourceName: string) => void
    prefillValues?: any,
    initialValues?: any,
    action: string
    apiName: string;
    gatewayName: string;
}

const ResourceApplicationForm: FC<ResourceApplicationFormProps> = ({ onCancel, onSuccess, action, prefillValues, initialValues, apiName, gatewayName }) => {
    const intl = useIntl();
    const styles = useStyles();
    const [dataFields] = useState(["__formResponse"]);

    const [message, setMessage] = useState<any>(null);

    const onSubmit = () => (data: any, form: FormApi) => {
        return new Promise((resolve) => {
            const formState = form.getState();

            if (formState.pristine) {
                resolve(null);
            } else {
                UnifiedAPIService.post(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.create_resource_server, {
                        apiName: apiName,
                        gatewayName: gatewayName,
                        resourceServerName: data.resourceServerName
                    }),
                    {
                        scope: data.scopeName,
                        description: sanitize(data.description)
                    }
                ).then(() => {
                    setMessage({
                        severity: "success",
                        detail: "Scope Prefix is created"
                    });
                    setTimeout(() => {
                        onSuccess && onSuccess(data.resourceServerName);
                    }, 3000);

                    resolve(null);
                }, error => {
                    resolve({
                        [FORM_ERROR]: error.response?.data?.message ?? "Unknown error occured while creating Scope Prefix"
                    });
                });
            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>) => {
        const validator = useContext(ValidatorBuilderContext);

        return (
            <>
                {
                    message &&
                    <FormAlert severity={message.severity}>
                        <div>{message.detail}</div>
                    </FormAlert>
                }
                <div className={styles.root}>
                    <FormInputContainer>
                        <Alert severity="info">Scope Prefix will be converted to Lowercase</Alert>
                        <br/>
                        <Field
                            name="resourceServerName"
                            type="text"
                            component={FormTextInput}
                            label={intl.formatMessage({
                                defaultMessage: "Scope Prefix"
                            })}
                            placeholder={intl.formatMessage({
                                defaultMessage: "Scope Prefix"
                            })}
                            validate={validator.from({
                                required: true
                            })}
                        />
                    </FormInputContainer>

                    <ActionInliner padding="inset-top">
                        <Button
                            onClick={onCancel}
                            disabled={formRenderProps.submitting}
                            type="button"
                            variant="outlined" disableElevation>
                            <FormattedMessage defaultMessage="Cancel" />
                        </Button>

                        {action === "CREATE" && (
                            <Button disabled={formRenderProps.submitting || formRenderProps.pristine}
                                type="submit" variant="contained" color="primary" disableElevation>
                                <FormattedMessage defaultMessage="Create Scope Prefix" />
                            </Button>)
                        }
                        {action === "EDIT" && (
                            <Button disabled={formRenderProps.submitting || formRenderProps.pristine}
                                type="submit" variant="contained" color="primary" disableElevation>
                                <FormattedMessage defaultMessage="Change" />
                            </Button>)
                        }
                    </ActionInliner>
                </div>
            </>
        );
    }
    return <FormContainer previewErrors={true} prefillValues={prefillValues} dataFields={dataFields}
        FormProps={{ onSubmit, initialValues }} children={FormComponents} />;
}

export default ResourceApplicationForm;