import React from 'react';

import { Route, Redirect, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';


import { ResourceAbility } from 'libs/security/authorization';

import UserList from './UserList';
import TeamGrid from '../sector/teams/TeamGrid';

const fallBackdiv = <div className="message info">You do not have permission to access the page. Please contact your Team owner.</div>

/**
 * @class TeamRoutes
 * @description For storing internal page redirection inside API Summary Module
 */
class TeamRoutes extends React.Component<any, any> {

    /**
     * @function render
     * @description repaints the component on each change of state and props value
     */
    render() {
        return (
            <Switch>
                
                <Route path="/home/teams/:sector/list" render={() => (
                    <ResourceAbility can="read" resource={({ TeamMembers }) => TeamMembers.list} fallBack={fallBackdiv} >
                        <TeamGrid />
                    </ResourceAbility>
                )} />
                <Route path="/home/teams/:teamName/users" render={() => (
                    <ResourceAbility can="read" resource={({ TeamMembers }) => TeamMembers.list} fallBack={fallBackdiv} >
                        <UserList />
                    </ResourceAbility>

                )} />
               <Route path="*"  render={()=><Redirect to ="/home" />}/>

            </Switch>
        );
    }
}

export default withRouter(TeamRoutes);