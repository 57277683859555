import React, { memo } from "react";
import {
  SvgIconProps,
  SvgIcon,
} from "@material-ui/core";

function ServiceIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="36" height="33" viewBox="0 0 36 33" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2344 13.6879L11.1714 5.85724L17.9761 1.9285L24.7229 5.90029L24.7898 13.7302L18.0424 9.75728L11.2344 13.6879Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4017 27.1038L3.33866 19.2731L10.1445 15.3438L10.2108 23.1725L3.4017 27.1038Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6871 15.2644L32.4339 19.2362L32.5008 27.0661L25.7534 23.0932L25.6871 15.2644Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2422 22.5785L11.1792 14.7479L17.985 10.8185L24.7308 14.7909L24.7981 22.6191L18.0513 18.6473L11.2422 22.5785Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8089 22.619L18.0621 18.6472L11.253 22.5785L18.1656 26.4531L24.8089 22.619Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.969 27.1425L10.2222 23.1707L3.41313 27.1019L10.3257 30.9766L16.969 27.1425Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4892 27.0732L25.7418 23.1004L18.9337 27.031L25.8453 30.9062L32.4892 27.0732Z"
        fill="inherit"
      />
    </SvgIcon>
  );
}

export default memo(ServiceIcon);
