

import { AgGridReact } from "ag-grid-react";
import { AsyncActionState } from "modus/components/form/async-actions/AsyncAction.type";
import React from "react";
import { IdentityApplication } from "../services/ApplicationService";
import { Box, Link, Typography } from "@material-ui/core";
import ApplicationIcon from "legacy-components/icons/ApplicationIcon";
import ServiceApplicationIcon from "legacy-components/icons/ServiceApplicationIcon";
import { Date, Email } from "modus/components/list/table/TableUtils";
import { ApplicationRemover } from "../pages/application-summary-page/ApplicationRemover";
import { TableContainer } from "modus/components/list/table/TableContainer";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import Loader from "modus/components/misc/Loader";
import { ErrorMessage, resolveAPIErrorMessage } from "modus/components/error-message/ErrorMessage";

interface AppListViewProps {
    state: AsyncActionState<IdentityApplication[]| null>;
    applicationList?: IdentityApplication[]|null;
    refresh?: ()=> void;
}



const Type = ({ value }: { value: any }) => {
    return (
        <Box key={value}     style={{height: '100%',
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center'}} >
            {value?.name === "application" ? <ApplicationIcon style={{color:'#0E416C'}}/>: <ServiceApplicationIcon style={{color:'#0E416C'}} />}
        </Box>
        
    )
}


export const AppTitleLink: React.FC<{ formattedValue?: any , value?: any }> =
  React.memo(({  value, formattedValue }) => {
    const history = useHistory();
    const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment );
    const onClick = () => {
        history.push(`/home/applications/${value ?? formattedValue}/gateway/${identityEnvironment}/summary`);
    }
    return (
      <Box display={"flex"}  alignItems={"center"} height="100%">
        <Link onClick={onClick}>
        <Typography variant="body1" style={{color: "#0E416C", fontSize:"14px", fontWeight: 600}}>
          { value ?? formattedValue}
        </Typography>
        </Link>
      </Box>
    );
  });

export const AppListView:React.FC<AppListViewProps> = ({state, refresh, applicationList}) => {

    const AppRemover = ({ data }: { data: any }) => {
        return (
            <Box key={data}     style={{height: '100%',
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center'}} >
                <ApplicationRemover application={data} iconOnly onSuccess={refresh}/>
            </Box>
            
        )
    }
    return (
        <Box>
            {state?.value && applicationList && (
                <TableContainer>
                <AgGridReact
                    rowData={applicationList}
                    frameworkComponents={{Type,AppTitleLink, Date, AppRemover, Email}}
                    rowHeight={48}
                    headerHeight={ 48}
                    domLayout={'autoHeight'}
                    columnDefs={[
                        {
                            headerName: "Type",
                            field: "applicationType",
                            width: 100,
                            cellRenderer: "Type"
                        },
                        {
                            headerName: "Name",
                            field: "applicationName",
                            cellRenderer: "AppTitleLink",
                            flex: 1,
                        },
                        {
                            headerName: "Created Date",
                            field: "created",
                            flex: 0.7,
                            cellRenderer: "Date"
                        },
                        {
                            headerName: "Created By",
                            field: "createdBy",
                            cellRenderer: "Email",
                            flex: 1.5,
                        },{
                            headerName: "Actions",
                            width: 150,
                            field: "applicationName",
                            cellRenderer:"AppRemover"
                        }
                    ]}
                />
                </TableContainer>
            )}
            {state?.loading && (
                <Loader showBackDrop/>
            )}
            {
                (state?.value && !state?.loading && (state?.value?.length == 0  || applicationList?.length === 0))
                && 
                (
                    <ErrorMessage message={"No Applications found"}/>
                )
            }
            {
                state?.error  && (
                    <ErrorMessage message={resolveAPIErrorMessage(state?.error)}/>
                )
            }
        
        </Box>
    );
}