import React, { FC, useContext, useState } from 'react'
import FormContainer, { FormContainerOptions, FormContainerSubmitHandler } from '../../../../legacy-components/form/container/FormContainer';
import FormInputContainer from '../../../../legacy-components/form/container/FormInputContainer';
import { Field, FormRenderProps } from 'react-final-form';
import FormTextInput from '../../../../legacy-components/form/input/FormTextInput';
import { Button, Typography } from '@material-ui/core';
import { ValidatorBuilderContext } from '../../../../legacy-components/form/validator/Validator';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { FORM_ERROR } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import UnifiedAPIService, { UnifiedAPI } from '../../../../services/UnifiedAPIService';
import { ProductAboutFormData } from '../models/products.model';
import PropTypes from "prop-types";
import { OnChange } from 'react-final-form-listeners';
import { sanitizeText } from './FileXSSRemover';

interface ProductAboutFormProps {
    stepperId: string
}

const ProductAboutForm: FC<ProductAboutFormProps> = ({ stepperId }) => {
    const validator = useContext(ValidatorBuilderContext);
    const [dataFields] = useState(["__productInfo"]);

    const onSubmit: FormContainerSubmitHandler<ProductAboutFormData> = ({ stepperOptions }) => {
        return (data, form) => {
            return new Promise((resolve) => {
                const formState = form.getState();

                if (formState.pristine) {
                    resolve();
                } else {
                    if (stepperOptions?.initilizeContent?.values?.__productInfo?.name) {
                        UnifiedAPIService.put(
                            UnifiedAPI.parseRequestURL(UnifiedAPI.edit_product_api, {
                                id: stepperOptions.initilizeContent.values.__productInfo.name
                            }), {
                            displayName: data.displayName ?? undefined,
                            longDescription: sanitizeText(data.longDescription) ?? undefined
                        }).then(() => resolve(), error => {
                            resolve({
                                [FORM_ERROR]:  error.response?.data?.message?? "Try different Product Name"
                            });
                        });
                    } else {
                        UnifiedAPIService.post(UnifiedAPI.product_api, {
                            name: data.name,
                            displayName: sanitizeText(data.displayName) ?? undefined,
                            shortDescription: sanitizeText(data.shortDescription) ?? undefined,
                            longDescription: sanitizeText(data.longDescription) ?? undefined
                        }).then(result => {
                            form.mutators.setValue("__productInfo", {
                                id: result?.data?.id,
                                name: result?.data?.name
                            });

                            resolve();
                        }, error => {
                            resolve({
                                [FORM_ERROR]: error.response?.data?.message?? "Try different Product Name"
                            });
                        });
                    }
                }
            })
        }
    }

    const [remainingCount,setRemainingCount] = useState(140);
    const onShortDescriptionChange = (shortDescription : string) => {
        if (shortDescription.length <= 140)
            setRemainingCount(140-shortDescription.length);
        else 
            setRemainingCount(0);
    } 
    const FormComponents = (formRenderProps: FormRenderProps<ProductAboutFormData, {}>, containerOptions: FormContainerOptions) => {
        const intl = useIntl();
        const formState = formRenderProps.form.getState();       

        return (<>
            <FormInputContainer inline={true} padding="inset">
                <Field
                    name="name"
                    type="text"
                    disabled={!!formState.values.__productInfo?.id}
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Name"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Name"
                    })}
                    helperText={intl.formatMessage({
                        defaultMessage: "Name cannot be changed once created"
                    })}
                    validate={validator.from({
                        required: true,
                        minLength: 3
                    })}
                />

                <Field
                    name="displayName"
                    type="text"
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Display Name"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Display Name"
                    })}
                    validate={validator.from({
                        required: true,
                        minLength: 3
                    })}
                />

            </FormInputContainer>       
            <FormInputContainer padding="inset">
            <Typography variant = "body2">Remaining characters : {remainingCount}</Typography>
            <br></br>
                <Field
                    name="shortDescription"
                    type="text"
                    multiline
                    rows={2}
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Short Description"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Short Description"
                    })}
                    validate={validator.from({
                        maxLength: 140
                    })}
                />
                <OnChange name="shortDescription">
                    {(value: any) => onShortDescriptionChange(value)}
                </OnChange>
            </FormInputContainer>
           
            <FormInputContainer padding="inset">
                <Field
                    name="longDescription"
                    type="text"
                    multiline
                    rows={2}
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Long Description"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Long Description"
                    })}
                    validate={validator.from({
                        maxLength: 1200
                    })}
                />
            </FormInputContainer>
            <ActionInliner padding="inset">
                {containerOptions.stepperOptions.navigateBack && <Button onClick={containerOptions.stepperOptions.navigateBack} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Back" /></Button>}

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation>
                    <FormattedMessage defaultMessage="Continue" />
                </Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer previewErrors={true} stepperId={stepperId} dataFields={dataFields} FormProps={{ onSubmit }} children={FormComponents} />;
}

ProductAboutForm.propTypes = {
    stepperId: PropTypes.string.isRequired
}

export default ProductAboutForm;