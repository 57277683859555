import { Box } from "@material-ui/core";
import GenericPageTitle from "modus/components/generic-page-title/GenericPageTitle";
import React, { useEffect } from "react";
import { ApplicationCreationPage } from "../application-creation-page/ApplicationCreationPage";
import { useAsyncAction } from "modus/components/form/async-actions/AsyncAction";
import ApplicationService, { PublisherApplicationService } from "pages/home/consumer/service/ApplicationService";
import { AppListView } from "../../components/AppListView";
import { useSelector } from "react-redux";
import { ApplicationSearch, filterApplicationsByText } from "../../components/ApplicationSearch";
import { IdentityApplication } from "../../services/ApplicationService";



export const ApplicationDiscoveryPage:React.FC = () => {
    const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment);

    const { state, subscribe} = useAsyncAction(() => PublisherApplicationService.getList(identityEnvironment));
    useEffect(() => {
        if(!identityEnvironment || !subscribe) {
            return;
        }
        subscribe();
    },[subscribe, identityEnvironment]);

    const [filteredApplications, setfilteredApplications] = React.useState<IdentityApplication[]>();

    const filterApplications = (searchText?: string) => {
        if(state?.value && state?.value?.length > 0) {
            if(!searchText || searchText?.trim() === "") {
                setfilteredApplications(state?.value);
                return;
            }
            const filteredApplications = filterApplicationsByText(state?.value, searchText);
            setfilteredApplications(filteredApplications);
        }
    }

    useEffect(() => {
        if(!state?.value) {
            return;
        }
        filterApplications("");
    },[state?.value]);
    return (
        <Box padding="20px" className="app-discovery-page">
            <GenericPageTitle
                title={"Applications"}
                noSkeleton
                controls={<Box display={"flex"}>
                    <ApplicationSearch onSearch={filterApplications}/>
                    <Box ml="15px" />
                    <ApplicationCreationPage onSuccess={() => subscribe()}/>
                        </Box>}
            />
            <Box mt="30px"/>
            <AppListView state={state} applicationList = {filteredApplications} refresh={subscribe}/>
        </Box>
    );
}