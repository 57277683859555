import React, {  useCallback, FC, PropsWithChildren, useEffect } from 'react';
import { Dialog, DialogTitle, makeStyles, createStyles, Divider } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ProxyDeployFormData } from '../../models/proxy.model';
import { useCompactDialog } from '../../../../../legacy-components/dialog/UseCompactDialog';

const useStyles = makeStyles(() => {
    return createStyles({
        header: {
            position: "relative"
        },
        dialog: {
            overflowX: "hidden"
        }
    })
});

export interface ResourceServerDialogRef {
    open(data?: ProxyDeployFormData, prefill?: boolean): void
}

const ResourceServerFormDialog: FC<PropsWithChildren<any>> = ({ children, show, onClose, onSubmit }) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();
    const handleClose = useCallback(() => {
        setOpen(false);
        onClose();
    }, [setOpen, onClose]);
    
    useEffect(() => {
        setOpen(show);
    },[show, setOpen]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            classes={{
                paper: style.dialog
            }}>

            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Scope Configuration" />
                </DialogTitle>
                <Divider />
            </div>
            <div>
                {children}
            </div>
        </Dialog>
    );
};

ResourceServerFormDialog.propTypes = {
}

export default ResourceServerFormDialog;

