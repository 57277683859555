import { CircularProgress, createStyles, IconButton, IconButtonTypeMap, makeStyles, Theme, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { AsyncActionInstance, AsyncActionState } from "legacy-components/form/action/AsyncAction";
import CopyIconOutline from "legacy-components/icons/CopyIconOutline";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import { notBlank } from "libs/utils/shared/common";
import React, { memo, useRef } from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";


interface CopyToClipboardProps {
    text?: string;
    edge?: IconButtonTypeMap["props"]["edge"]
    size?: IconButtonTypeMap["props"]["size"]
    className?: string;
    state?: AsyncActionState<any>;
    subscribe?: (isStreaming?:boolean)=> void;
}

interface CopyToClipboardAsyncProps {
    instance: AsyncActionInstance<string>;
    edge?: IconButtonTypeMap["props"]["edge"]
    size?: IconButtonTypeMap["props"]["size"]
    className?: string;
}

function useCopyToClipboard(): [boolean, (value: string) => Promise<boolean>] {
    const [disabled] = useState(typeof navigator.clipboard?.writeText !== "function");

    const onCopy = useCallback((value) => {
        return new Promise<boolean>((resolve) => {
            if (!disabled && navigator.clipboard?.writeText) {
                navigator.clipboard.writeText(value).then(() => resolve(true), () => resolve(false))
            } else {
                resolve(false);
            }
        });
    }, [disabled]);

    return [disabled, onCopy];
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            borderColor: '#0063A3',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '4px',
        }
    }));
function CopyToClipboard({ text, edge, size, state,subscribe, ...props }: CopyToClipboardProps) {
    const notification = useContext(SnackNotificationContext);
    const [copyDisabled, copy] = useCopyToClipboard();
    const classes = useStyles();
    const [copyMarked, setCopyMarked] = useState<boolean>(false);

    const copyText = useCallback(() =>{
        if(!text) {
            return;
        }
        copy(text).then(success => {
            notification.show({
                id: "copy-to-clipboard",
                message: success ? <FormattedMessage defaultMessage="Copied to Clipboard" /> : <FormattedMessage defaultMessage="Failed to copy the text" />,
                timeout: 4000
            })
        });
    },[text]);

    useEffect(() => {
        if(copyMarked && state?.value) {
            copyText();
            setCopyMarked(false);
        }
    },[copyMarked,state?.value, text, copyText, setCopyMarked]);

    const onCopy = useCallback(() => {
        if(!state || state.value) {
            copyText();
        } else if(state.loading) {
            setCopyMarked(true);
        } else {
            subscribe && subscribe();
            setCopyMarked(true);
        }
    }, [copyMarked, text, state, state?.value, state?.loading, copy, text, notification, subscribe, copyText])

    return (
        <Tooltip enterDelay={1000} title={<FormattedMessage defaultMessage="Copy to Clipboard" />}>
            <IconButton  size={size} onClick={onCopy} disabled={state?.loading} edge={edge} {...props} className={clsx([classes.iconButton, props?.className])}>
                {((!state || (!copyMarked || !state?.loading)) && <CopyIconOutline style={{color:'#0063A3'}}/>)}
                {(copyMarked && state?.loading) && (<CircularProgress size={"24px"} style={{color:'#0063A3'}} />)}
            </IconButton>
        </Tooltip>
    );
}

export default memo(CopyToClipboard);