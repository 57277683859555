import React, { PropsWithChildren, memo, ReactElement } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

interface HightlightWrapProps {
    icon?: ReactElement
}

const useStyles = makeStyles(({ spacing, palette, typography }) =>
    createStyles({
        root: {
            display: "inline-block",
            borderRadius: "3px",
            padding: spacing(0, 0.8),
            fontWeight: typography.fontWeightMedium,
            backgroundColor: palette.grey[100],
            position: 'relative',
            width: "100%"
        },

        icon: {
            position: 'absolute',
            width: spacing(3),
            height: spacing(3),
            left: 0,
            top: 0
        },

        content: {
            position: 'relative',
            display: 'inline-block',
            width: "100%",
        },

        wrapper: {
            position: 'relative',
            maxWidth: "100%",
            display: "inline-block",

            "&&--has-icon": {
                paddingLeft: spacing(4)
            }
        }
    }),
);

function HightlightWrap({ children, icon }: PropsWithChildren<HightlightWrapProps>) {
    const styles = useStyles();

    return (
        <span className={clsx(styles.wrapper, {
            [`${styles.wrapper}--has-icon`]: icon
        })}>
            <span className={styles.root}>
                <span className={styles.content}>{children}</span>
            </span>
            <span className={styles.icon}>{icon}</span>
        </span>
    )
}

HightlightWrap.propTypes = {

}

export default memo(HightlightWrap);

