import { makeStyles, createStyles } from "@material-ui/core";

const useDialogStyles = makeStyles(() => {
  return createStyles({
    header: {
      position: "relative",
    },
    dialog: {
      overflowX: "hidden",
    },
  });
});

export default useDialogStyles;
