import React, {
  useRef,
  useContext,
} from "react";
import { FormattedMessage } from "react-intl";
import {
  Typography,
} from "@material-ui/core";
import {
  ApplicationSummaryDialogRef,
} from "../../../../libs/resources/identity/applications/application-form/summary/ApplicationSummaryDialog";
import { IdentityApplicationViewContext } from "../../../../libs/resources/identity/applications/IdentityApplicationView";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import { ISubscriptionSummary } from "../pages/subscription-summary-page/SubscriptionSummaryPage";
import { resolveDay } from 'legacy-components/Helper';


interface ApplicationViewProps {
  subscription: ISubscriptionSummary;
}

const ApplicationView: React.FC<ApplicationViewProps> = ({ subscription }) => {
  const applicationViewContext = useContext(IdentityApplicationViewContext);
  const notificationContext = useContext(SnackNotificationContext);
  const dialogRef = useRef<ApplicationSummaryDialogRef>();

  return (
    <>
      <FormViewInliner
        gutter="gutter-bottom"
        title={<FormattedMessage defaultMessage="Application Name" />}
      >
        <Typography variant="body1">{subscription?.applicationName}</Typography>
      </FormViewInliner>

      <FormViewInliner
        gutter="gutter-bottom"
        title={<FormattedMessage defaultMessage="Client Id" />}
      >
        <Typography variant="body1">{subscription?.applicationId}</Typography>
      </FormViewInliner>

      <FormViewInliner
        gutter="gutter-bottom"
        title={<FormattedMessage defaultMessage="Created Date" />}
      >
        <Typography variant="body1">
          {resolveDay(subscription?.applicationCreatedDate)}
        </Typography>
      </FormViewInliner>

      <FormViewInliner
        gutter="gutter-bottom"
        title={<FormattedMessage defaultMessage="Created By" />}
      >
        <Typography variant="body1">
          {subscription?.applicationCreatedBy}
        </Typography>
      </FormViewInliner>
    </>
  );
};

export default ApplicationView;
